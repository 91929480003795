import {
  PERMISSION_TIER_1,
  PERMISSION_TIER_2,
  PERMISSION_TIER_3,
} from "../assets/const/status";
// sections
export const FRANCHISOR_ADD = "FRANCHISOR_ADD";

// gurantees
export const ACCESS_FULL = 2;
export const ACCESS_READ_ONLY = 1;
export const ACCESS_RESTRICTED = 0;

export const PERMISSION_TABLE = {
  FRANCHISOR_ADD: {
    TIER_1: ACCESS_FULL,
    TIER_2: ACCESS_FULL,
    TIER_3: ACCESS_RESTRICTED,
  },
  FRANCHISOR_DELETE: {
    TIER_1: ACCESS_FULL,
    TIER_2: ACCESS_FULL,
    TIER_3: ACCESS_RESTRICTED,
  },
  FRANCHISEE_DELETE: {
    TIER_1: ACCESS_FULL,
    TIER_2: ACCESS_RESTRICTED,
    TIER_3: ACCESS_RESTRICTED,
  },
  FRANCHISEE_DATA_APPROVE: {
    TIER_1: ACCESS_FULL,
    TIER_2: ACCESS_FULL,
    TIER_3: ACCESS_RESTRICTED,
  },
  USER_MANAGEMENT: {
    TIER_1: ACCESS_FULL,
    TIER_2: ACCESS_FULL,
    TIER_3: ACCESS_RESTRICTED,
  },
  INTEGRATION_MANAGEMENT: {
    TIER_1: ACCESS_FULL,
    TIER_2: ACCESS_RESTRICTED,
    TIER_3: ACCESS_RESTRICTED,
  },
  ZEE_RATING: {
    TIER_1: ACCESS_FULL,
    TIER_2: ACCESS_RESTRICTED,
    TIER_3: ACCESS_RESTRICTED,
  },
  NAICS_CLASSIFICATION: {
    TIER_1: ACCESS_FULL,
    TIER_2: ACCESS_RESTRICTED,
    TIER_3: ACCESS_RESTRICTED,
  },
  CARRIER_APPETITE: {
    TIER_1: ACCESS_FULL,
    TIER_2: ACCESS_RESTRICTED,
    TIER_3: ACCESS_RESTRICTED,
  },
  ZOR_CHECKLIST: {
    TIER_1: ACCESS_FULL,
    TIER_2: ACCESS_RESTRICTED,
    TIER_3: ACCESS_RESTRICTED,
  },
};
