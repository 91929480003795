import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Input } from "@components/";
import { put } from "@utils/axios";
import { Button } from "antd";
import "./style.scss";

export default function InputForm(props) {
  const { requirement } = props;
  const [title, setTitle] = useState(requirement.title);
  useEffect(() => {
    setTitle(requirement.title);
  }, [requirement]);
  const { getAccessTokenSilently } = useAuth0();
  async function handleSave() {
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `requirement-template/${requirement?._id}`,
        {
          title: title,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result?.data?.success) {
      }
    } catch (error) {}
  }
  return (
    <div className="ir_input_form">
      <Input
        title="Title"
        type="text"
        size="large"
        value={title}
        onChange={(evt) => setTitle(evt.target.value)}
      />
      <div className="ir_input_form-footer">
        <Button type="primary" size="large" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
}
