import {
  COVERAGES,
  COVERAGE_KEY_WORKERS_COMP,
  INSURANCE_CARRIERS,
  INSURANCE_CARRIER_KEY_HARTFORD,
  QUOTE_PROPOSAL_SOURCES,
  QUOTE_PROPOSAL_SOURCE_API,
  QUOTE_STATUSES,
  QUOTE_STATUS_BINDABLE,
} from "@assets/const/fim-integration";
import { Select } from "antd";
import "./style.scss";
import { useState } from "react";
import WorkerCompQuotePreview from "./workercomp";
import _ from "lodash";
import Input from "@components/input";
import InputSelect from "@components/input/select";

const testData = {
  [INSURANCE_CARRIER_KEY_HARTFORD]: {
    [COVERAGE_KEY_WORKERS_COMP]: {
      source: QUOTE_PROPOSAL_SOURCE_API,
      status: QUOTE_STATUS_BINDABLE,
      effectiveDate: "01/05/2022",
      expirationDate: "01/05/2023",
      policyNumber: "123456890",
      premium: 2889,
      bodilyInjuryByAccidentPerPerson: 10000000,
      bodilyInjuryByDiseasePolicyLimit: 10000000,
      bodilyInjuryByDiseasePerPerson: 10000000,
      ownerOfficers: [
        {
          name: "Neo",
          ownership: 50,
          status: "Included",
        },
        {
          name: "Morpheus",
          ownership: 50,
          status: "Excluded",
        },
      ],
      policyLevelData: {
        experienceModification: {
          enabled: true,
          value: 10,
        },
        blanketWaiverOfSubrogation: {
          enabled: false,
        },
      },
      locations: [
        {
          address: "98 Street, CA 92389",
          classCodes: [
            {
              classCode: 8832,
              classCodeDescription: "Physical Therapist",
              exposureBasis: "Payroll",
              rate: 0.28,
              currentPayroll: 84000,
            },
            {
              classCode: 8810,
              classCodeDescription: "Clerical Office Employee NOC",
              exposureBasis: "Payroll",
              rate: 0.14,
              currentPayroll: 33000,
            },
          ],
        },
        {
          address: "13 Blvd, FL 92819",
          classCodes: [
            {
              classCode: 1111,
              classCodeDescription: "Graphic Design",
              exposureBasis: "Contract",
              rate: 0.21,
              currentPayroll: 30000,
            },
            {
              classCode: 9999,
              classCodeDescription: "Manufacturing",
              exposureBasis: "Payroll",
              rate: 0.94,
              currentPayroll: 20000,
            },
          ],
        },
      ],
    },
  },
};

export default function AdminInsuranceQuoteProposal({
  initialSelectedCarrier,
  initialSelectedCoverages,
  initialSelectedStatus,
  initialSelectedSource,
  data = testData,
  readOnly = false,
}) {
  const [selectedCarrier, setSelectedCarrier] = useState(
    initialSelectedCarrier
  );
  const [selectedCoverages, setSelectedCoverages] = useState(
    _.keys(_.pickBy(initialSelectedCoverages))
  );
  const [selectedStatus, setSelectedStatus] = useState(initialSelectedStatus);

  const [selectedSource, setSelectedSource] = useState(initialSelectedSource);

  function renderQuoteProposal() {
    const previews = [];
    for (const coverage of selectedCoverages) {
      if (!data[selectedCarrier]) continue;
      switch (coverage) {
        case COVERAGE_KEY_WORKERS_COMP:
          const quoteData = data[selectedCarrier][COVERAGE_KEY_WORKERS_COMP];
          if (
            quoteData.status === selectedStatus &&
            quoteData.source === selectedSource
          )
            previews.push(
              <WorkerCompQuotePreview data={quoteData} readOnly={readOnly} />
            );
      }
    }
    return previews;
  }

  return (
    <div>
      <div className="form-group-four-container">
        <div>
          {readOnly ? (
            <Input
              title="Product"
              value={selectedCoverages
                .map((c) => COVERAGES[c].title)
                .join(", ")}
              size="large"
              readOnly
            ></Input>
          ) : (
            <InputSelect
              title="Product"
              className="selection"
              mode="multiple"
              placeholder="Select product/quote type"
              size="large"
              value={selectedCoverages}
              onChange={(coverages) => setSelectedCoverages(coverages)}
              options={_.keys(COVERAGES).map((key) => ({
                label: COVERAGES[key].title,
                value: key,
              }))}
            ></InputSelect>
          )}
        </div>
        <div>
          {readOnly ? (
            <Input
              title="Carrier"
              value={INSURANCE_CARRIERS[selectedCarrier].title}
              size="large"
              readOnly
            />
          ) : (
            <InputSelect
              size="large"
              title="Carrier"
              className="selection"
              placeholder="Select a carrier"
              value={selectedCarrier}
              disabled={readOnly}
              onChange={(carrier) => setSelectedCarrier(carrier)}
              options={_.keys(INSURANCE_CARRIERS).map((key) => ({
                label: INSURANCE_CARRIERS[key].title,
                value: key,
              }))}
            ></InputSelect>
          )}
        </div>
        <div>
          {readOnly ? (
            <Input
              title="Status"
              value={selectedStatus}
              size="large"
              readOnly
            />
          ) : (
            <InputSelect
              title="Status"
              className="selection"
              size="large"
              disabled={readOnly}
              value={selectedStatus}
              onChange={(status) => setSelectedStatus(status)}
              options={QUOTE_STATUSES.map((status) => ({
                label: status,
                value: status,
              }))}
            ></InputSelect>
          )}
        </div>
        <div>
          {readOnly ? (
            <Input
              title="Source"
              value={selectedSource}
              size="large"
              readOnly
            />
          ) : (
            <InputSelect
              title="Source"
              className="selection"
              size="large"
              disabled={readOnly}
              value={selectedSource}
              onChange={(source) => setSelectedSource(source)}
              options={QUOTE_PROPOSAL_SOURCES.map((source) => ({
                label: source,
                value: source,
              }))}
            ></InputSelect>
          )}
        </div>
      </div>
      {renderQuoteProposal()}
    </div>
  );
}
