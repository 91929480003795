import "./style.scss";

export default function QuoteStepper(props) {
  const { step, total } = props;
  return (
    <div className="quote_stepper">
      {[...Array(total)].map((e, count) => {
        if (count + 1 < step) {
          return (
            <div className="avatar-container-completed">
              <p className="avatar-title">{count + 1}</p>
            </div>
          );
        } else if (count + 1 == step) {
          return (
            <div className="avatar-container">
              <p className="avatar-title">{count + 1}</p>
            </div>
          );
        } else {
          return (
            <div className="un-selected-avatar-container">
              <p className="avatar-title">{count + 1}</p>
            </div>
          );
        }
      })}
    </div>
  );
}
