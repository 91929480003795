import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Alert } from "antd";
import "./style.scss";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildPollutionType,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { FORM_CLAIMS_MADE, FORM_OCCURRENCE } from "@assets/const/form";
import { mapFieldProLiab } from "@utils/form-recog";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { capitalize } from "lodash";
import { formatToMonthDayYear } from "@utils/date-formatter";
import { formatDollarAmount } from "@utils/dollar-formatter";

export default function ProLiabForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.pro_liab_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        pro_liab_occ: finalData.pro_liab_occ,
        pro_liab_agg: finalData.pro_liab_agg,
        pro_liab_type: finalData.pro_liab_type,
        pro_liab_policy_number: finalData.pro_liab_policy_number,
        pro_liab_effective_date: finalData.pro_liab_effective_date,
        pro_liab_expire_date: finalData.pro_liab_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];

      if (data) {
        return mapFieldProLiab(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  const proLiabSections = [
    {
      title: "Effective Date",
      valueFn: () =>
        buildEffectDate(
          franchiseeData?.finalData?.pro_liab_effective_date,
          franchisorData?.pro_liab_required
        ),
      formValue: formatToMonthDayYear(
        franchiseeData?.finalData?.pro_liab_effective_date
      ),
    },
    {
      title: "Expiration Date",
      valueFn: () =>
        buildExpireDate(
          franchiseeData?.finalData?.pro_liab_expire_date,
          franchisorData?.pro_liab_required
        ),
      formValue: formatToMonthDayYear(
        franchiseeData?.finalData?.pro_liab_expire_date
      ),
    },
    {
      title: "Each Occurrence",
      valueFn: () =>
        buildValueGT(
          franchiseeData?.finalData?.pro_liab_occ,
          franchisorData?.pro_liab_occ,
          franchisorData?.pro_liab_required
        ),
      fddRequirement: formatDollarAmount(franchisorData?.pro_liab_occ),
      formValue: formatDollarAmount(franchiseeData?.finalData?.pro_liab_occ),
    },
    {
      title: "Aggregate",
      valueFn: () =>
        buildValueGT(
          franchiseeData?.finalData?.pro_liab_agg,
          franchisorData?.pro_liab_agg,
          franchisorData?.pro_liab_required
        ),
      fddRequirement: formatDollarAmount(franchisorData?.pro_liab_agg),
      formValue: formatDollarAmount(franchiseeData?.finalData?.pro_liab_agg),
    },
    {
      title: "Professional Liability Type",
      valueFn: () =>
        buildPollutionType(
          franchiseeData?.finalData?.pro_liab_type,
          franchisorData?.pro_liab_type,
          franchisorData?.pro_liab_required
        ),
      fddRequirement: capitalize(franchisorData?.pro_liab_type),
      formValue: capitalize(franchiseeData?.finalData?.pro_liab_type),
    },
  ];

  const displayedSectionsCount = proLiabSections.filter(
    ({ title, valueFn, formValue }) => {
      const value = valueFn()?.[0]?.title;

      return (
        ((title !== "Effective Date" && title !== "Expiration Date") ||
          formValue ||
          (Array.isArray(valueFn?.()) && valueFn().length > 0)) &&
        (toggleState ? value : formValue || value)
      );
    }
  ).length;

  return (
    <div className="liability-table">
      {(() => {
        const hasInvalidExpirationDate = proLiabSections.some(
          ({ title, valueFn }) =>
            title === "Expiration Date" &&
            valueFn()?.[0]?.title === "Must expire after today's date"
        );

        const effectiveToggleState = hasInvalidExpirationDate
          ? false
          : toggleState;

        return (
          (!effectiveToggleState || displayedSectionsCount > 0) && (
            <div>
              <h2>
                Professional Liability #
                {franchiseeData?.finalData?.pro_liab_policy_number}
              </h2>
              {hasInvalidExpirationDate && (
                <div className="coverage-lapsed-error">
                  <Alert
                    message="Please Upload COI to Renew Lapsed Coverage"
                    type="error"
                    showIcon
                  ></Alert>
                </div>
              )}
              <div className="table-header">
                <span className="table-header-field">Field</span>
                <span className="table-header-fdd">Requirement</span>
                <span className="table-header-coverage">Your Coverage</span>
              </div>

              {proLiabSections.map(
                ({ title, valueFn, fddRequirement, formValue }) => {
                  const buildValue = valueFn()?.[0]?.title;

                  const isCoverageLapsed = hasInvalidExpirationDate;

                  const isExpiringSoon =
                    title === "Expiration Date" &&
                    buildValue === "Expiring in 15 days or less";

                  const displayValue = buildValue || formValue;

                  const shouldDisplayDates =
                    title === "Effective Date" || title === "Expiration Date"
                      ? !effectiveToggleState ||
                        isCoverageLapsed ||
                        isExpiringSoon ||
                        buildValue
                      : effectiveToggleState
                      ? buildValue
                      : fddRequirement && fddRequirement !== "";

                  if (!shouldDisplayDates) {
                    return null;
                  }

                  return (
                    <div key={title} className="table-row">
                      <div className="table-cell field-title">
                        <span>{title}</span>
                      </div>
                      <div className="table-cell fdd-requirement">
                        <span>{fddRequirement || ""}</span>
                      </div>
                      <div className="table-cell">
                        {isCoverageLapsed ? (
                          <span className="value-red">
                            <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                            {typeof displayValue === "boolean"
                              ? displayValue
                                ? "Selected"
                                : "Not Selected"
                              : displayValue}
                          </span>
                        ) : effectiveToggleState && isExpiringSoon ? (
                          buildValue ? (
                            <span className="value-yellow">
                              <WarningTwoTone twoToneColor={"#ffcc00"} />{" "}
                              {buildValue}
                            </span>
                          ) : null
                        ) : effectiveToggleState ? (
                          <span className="value-red">
                            <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                            {buildValue}
                          </span>
                        ) : isExpiringSoon ? (
                          <span className="value-yellow">
                            <WarningTwoTone twoToneColor={"#ffcc00"} />{" "}
                            {buildValue}
                          </span>
                        ) : buildValue ? (
                          <span className="value-red">
                            <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                            {buildValue}
                          </span>
                        ) : (
                          <span className="value-green">
                            <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                            {typeof displayValue === "boolean"
                              ? displayValue
                                ? "Selected"
                                : null
                              : displayValue}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          )
        );
      })()}
    </div>
  );
}
