import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, post, get, put } from "../../../../utils/axios";
import TemplateForm from "./template-form";
import TemplateItem from "./template-item";
import TemplateCreate from "./template-create";
import TemplateUpdate from "./template-update";
import "./style.scss";

const MODAL_CREATE = "MODAL_CREATE";
const MODAL_UPDATE = "MODAL_UPDATE";
const MODAL_NONE = "MODAL_NONE";

export default function AdminIRTemplate(props) {
  const [templateList, setTemplateList] = useState([]);
  const [activeTemplateItem, setActiveTemplateItem] = useState(null);
  const [modalInfor, setModalInfor] = useState({
    type: MODAL_NONE,
    title: "",
  });
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    handleLoadTemplateList();
  }, []);

  async function handleLoadTemplateList() {
    try {
      const token = await getAccessTokenSilently();
      const result = await get(`requirement-template`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result?.data?.success) {
        setTemplateList(result?.data?.data || []);
      }
    } catch (error) {}
  }
  function handleAdd() {
    setModalInfor({
      type: MODAL_CREATE,
      title: "Create Insurance Requirement Template",
    });
  }

  async function handleSave(title) {
    if (activeTemplateItem?._id) {
      try {
        const token = await getAccessTokenSilently();
        const result = await put(
          `requirement-template/${activeTemplateItem?._id}`,
          {
            title: title,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result?.data?.success) {
          const index = templateList.findIndex(
            (item) => item._id == result?.data?.data._id
          );
          templateList.splice(index, 1, result?.data?.data);
          setTemplateList([...templateList]);
          setActiveTemplateItem(null);
        }
      } catch (error) {}
    } else {
      try {
        const token = await getAccessTokenSilently();
        const result = await post(
          `requirement-template`,
          {
            title: title,
            completed: false,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result?.data?.success) {
          templateList.push(result?.data?.data);
          setTemplateList([...templateList]);
          setActiveTemplateItem(null);
        }
      } catch (error) {}
    }
  }

  function handleCancel() {
    setActiveTemplateItem(null);
  }

  async function handleDelete(id) {
    try {
      const token = await getAccessTokenSilently();
      const result = await deleteAPI(`requirement-template/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result?.data?.success) {
        const templateListUpdate = templateList.filter(
          (item) => item._id != id
        );
        setTemplateList(templateListUpdate);
      }
    } catch (err) {}
  }

  function handleEdit(data) {
    setActiveTemplateItem(data);
    setModalInfor({
      type: MODAL_UPDATE,
      title: "Edit Requirement Template",
    });
  }

  function handleClose() {
    setModalInfor({
      type: MODAL_NONE,
      title: "",
    });
    handleLoadTemplateList();
  }

  return (
    <div className="admin_ir">
      <div className="admin_ir-action">
        <Button onClick={handleAdd} type="primary">
          <PlusOutlined />
        </Button>
      </div>
      {templateList.map((templateItem) => {
        return (
          <TemplateItem
            data={templateItem}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        );
      })}
      {activeTemplateItem && !activeTemplateItem._id && (
        <TemplateForm
          requirement={activeTemplateItem}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}

      <Modal
        width={800}
        title={modalInfor.title}
        open={modalInfor.type != MODAL_NONE}
        onOk={() => console.log("handle ok")}
        onCancel={handleClose}
        footer={null}
      >
        {modalInfor.type === MODAL_CREATE && (
          <TemplateCreate status={modalInfor} onCancel={handleClose} />
        )}
        {modalInfor.type === MODAL_UPDATE && (
          <TemplateUpdate template={activeTemplateItem} />
        )}
      </Modal>
    </div>
  );
}
