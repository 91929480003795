import React, { useState, useEffect } from "react";
import { Table, Input, notification, Button, Modal, Popconfirm } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading, Card } from "@components";
import { useAppState } from "@context";
import { PERMISSION_TABLE, ACCESS_READ_ONLY } from "@utils/permission";
import "./style.scss";
import {
  PERMISSION_TIER_1,
  PERMISSION_TIER_2,
  PERMISSION_TIER_3,
  USER_TYPE_ADMIN,
  USER_TYPE_FRANCHISEE,
  USER_TYPE_FRANCHISOR,
} from "@assets/const/status";
import { deleteAPI, post, get } from "@utils/axios";
import UserForm from "./user-form";
import { Redirect } from "react-router-dom";
import { getHostTag } from "@utils/host";
const { Search } = Input;

const MODAL_TYPE_FORM = "MODAL_TYPE_FORM";
const MODAL_TYPE_DELETE = "MODAL_TYPE_DELETE";
const MODAL_TYPE_NONE = "MODAL_TYPE_NONE";

export default function AdminUsers(props) {
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [userList, setUserList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [userData, setUserData] = useState(null);
  const [modalType, setModalType] = useState(MODAL_TYPE_NONE);
  const { authSetting } = useAppState();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    handleFetchUser();
  }, []);

  if (
    PERMISSION_TABLE["USER_MANAGEMENT"][authSetting.userPermission] <
    ACCESS_READ_ONLY
  ) {
    return <Redirect to="/admin/overview" />;
  }

  async function handleFetchUser() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/admin-users`,
        { host: getHostTag() },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setUserList(result.data.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleNotification(
        "Failed!",
        "Users loading is failed. Please contact us."
      );
    }
  }

  async function handleRemove() {
    setIsOpen(false);
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      for (const item of checkedList) {
        await deleteAPI(`user/${item._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    handleFetchUser();
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  const rowSelection = {
    selectedRowKeys: checkedList.map((item) => item.key),
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedList(selectedRows);
    },
  };

  const onRow = (row) => ({
    onClick: () => {
      setModalType(MODAL_TYPE_FORM);
      setModalTitle("Update User");
      setIsOpen(true);
      setUserData(row);
    },
  });

  function handleAddUser() {
    setModalTitle("Add User");
    setModalType(MODAL_TYPE_FORM);
    setIsOpen(true);
    setUserData(null);
  }

  function handleCloseModal() {
    setIsOpen(false);
    setModalTitle("");
    setModalType(MODAL_TYPE_NONE);
    setUserData(null);
  }

  function renderModal() {
    return (
      <Modal
        className={
          modalType === MODAL_TYPE_FORM
            ? "custom-modal_form"
            : "custom-modal_delete"
        }
        title={modalTitle}
        open={isOpen}
        footer={null}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
      >
        {modalType === MODAL_TYPE_FORM && (
          <UserForm
            onClose={handleCloseModal}
            data={userData}
            onRefresh={handleFetchUser}
          />
        )}
      </Modal>
    );
  }

  function renderRole(type) {
    switch (type) {
      case USER_TYPE_FRANCHISEE:
        return <div className="status-container danger">Franchisee</div>;
      case USER_TYPE_FRANCHISOR:
        return <div className="status-container info">Franchisor</div>;
      case USER_TYPE_ADMIN:
        return <div className="status-container success">Admin</div>;
      default:
        return <div></div>;
    }
  }

  function renderPermission(permission) {
    switch (permission) {
      case PERMISSION_TIER_1:
        return (
          <div className="status-container success">{PERMISSION_TIER_1}</div>
        );
      case PERMISSION_TIER_2:
        return <div className="status-container info">{PERMISSION_TIER_2}</div>;
      case PERMISSION_TIER_3:
        return (
          <div className="status-container danger">{PERMISSION_TIER_3}</div>
        );
      default:
        return (
          <div className="status-container danger">{PERMISSION_TIER_3}</div>
        );
    }
  }

  function renderActive(key) {
    if (key === authSetting.user._id) {
      return <div className="status-container success">Current User</div>;
    }
    return null;
  }

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "type",
      key: "type",
      render: renderRole,
    },
    {
      title: "Permission",
      dataIndex: "permission",
      key: "permission",
      render: renderPermission,
    },
    {
      title: "",
      dataIndex: "key",
      key: "key",
      render: renderActive,
    },
  ];

  const dataSource = [];
  if (userList && userList.length > 0) {
    for (const item of userList) {
      if (
        JSON.stringify(item).toLowerCase().indexOf(searchStr.toLowerCase()) >= 0
      ) {
        dataSource.push({
          ...item,
          key: item._id,
        });
      }
    }
  }

  return (
    <div className="admin-users-container">
      <div className="action-btn-container">
        <div>
          <Search
            value={searchStr}
            onChange={(evt) => setSearchStr(evt.target.value)}
            placeholder="Users Email, Name"
            enterButton="Search Users"
          />
        </div>

        <div className="admin-users-container_toolbar">
          {PERMISSION_TABLE["FRANCHISOR_ADD"][authSetting.userPermission] >
            0 && (
            <Button onClick={handleAddUser} type="primary">
              <PlusOutlined />
            </Button>
          )}
          {PERMISSION_TABLE["FRANCHISOR_DELETE"][authSetting.userPermission] >
            0 &&
            checkedList?.length === 1 &&
            checkedList[0].key !== authSetting?.user?._id && (
              <Popconfirm
                title="Are you sure to delete this user?"
                onConfirm={handleRemove}
                onCancel={() => console.log("cancle remove")}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        onRow={onRow}
        columns={columns}
        dataSource={dataSource}
      />
      <Loading loading={loading} />
      {renderModal()}
    </div>
  );
}
