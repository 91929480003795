import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Loading, OriginalRecordForm } from "../../../components";
import { useParams } from "react-router-dom";
import FranchiseeReport from "../../admin-board/franchisee-report";
import "./style.scss";
import { get } from "../../../utils/axios";
import InsuredInfor from "../../admin-board/franchisee-detail/insured-infor";
import ComplianceList from "../../admin-board/franchisee-detail/compliance-list";
import ComplianceSection from "../../admin-board/franchisee-detail/compliance-section";
import DocumentList from "../../admin-board/franchisee-detail/document-list";
import ActivityList from "../../admin-board/franchisee-detail/activity-list";
import PortalLink from "../../admin-board/franchisee-detail/portal-link";
import InsuranceUpload from "../../admin-board/franchisee-detail/insurance-upload";
import { VIEW_SOURCE_ZOR } from "@assets/const/ui";

const { TabPane } = Tabs;

const TAB_ACCOUNT = "TAB_ACCOUNT";
const TAB_COMPLIANCE = "TAB_COMPLIANCE";
const TAB_REPORT = "TAB_REPORT";

export default function FranchiseeDetail(props) {
  // context
  let { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  // variable
  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      handleLoadFranchisee(id);
    }
  }, [id]);

  async function handleLoadFranchisee(id) {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFranchiseeData(result.data.data);
      const resultRequirement = await get(
        `requirement/${result.data.data.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFranchisorData(resultRequirement.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  function handleChangeTab() {}

  return (
    <div className="franchisee-detail-container">
      <Card title={"Franchisee"}>
        <Tabs
          type="card"
          defaultActiveKey={TAB_ACCOUNT}
          onChange={handleChangeTab}
        >
          <TabPane tab="Account" key={TAB_ACCOUNT}>
            <InsuredInfor franchiseeData={franchiseeData} />
            <Card title="Original Record">
              <OriginalRecordForm disabled={true} data={franchiseeData} />
            </Card>
          </TabPane>
          <TabPane tab="Compliance" key={TAB_COMPLIANCE}>
            <ComplianceSection franchiseeData={franchiseeData} />
            <ComplianceList
              franchiseeData={franchiseeData}
              franchisorData={franchisorData}
            />
            <DocumentList
              franchiseeData={franchiseeData}
              updateFranchiseeData={setFranchiseeData}
            />
            <ActivityList franchiseeData={franchiseeData} />
            <PortalLink franchiseeData={franchiseeData} />
            <InsuranceUpload />
          </TabPane>
          <TabPane tab="Report" key={TAB_REPORT}>
            <FranchiseeReport viewSource={VIEW_SOURCE_ZOR} />
          </TabPane>
        </Tabs>
      </Card>
      <Loading loading={loading} />
    </div>
  );
}
