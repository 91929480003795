import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export default function CustomSelect(props) {
    return (
        <div className="select-box-container">
            <h3 className="select-box-title">{props.title}</h3>
            <Select value={props.value} style={{ width: 120 }} onChange={props.handleChange}>
                {props.optionList.map(option => <Option value={option.value}>{option.label}</Option>)}
            </Select>
        </div>
    )
}