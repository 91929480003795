import React from "react";
import "./style.scss";

export const BUTTON_GROUP_TYPE_SWITCH = "switch";
export const BUTTON_GROUP_TYPE_BUTTON = "button";

export default function ButtonGroup({ type, buttonList, value, onChange }) {
  return (
    <div className="btn_group">
      {buttonList.map((item, index) => (
        <div
          key={`key-btn-group-${index}`}
          className={
            item?.disabled
              ? `btn_group-container-disabled ${
                  type === BUTTON_GROUP_TYPE_SWITCH && value === item.value
                    ? "btn_selected"
                    : ""
                }`
              : `btn_group-container ${
                  type === BUTTON_GROUP_TYPE_SWITCH && value === item.value
                    ? "btn_selected"
                    : ""
                }`
          }
          onClick={(evt) => {
            if (type === BUTTON_GROUP_TYPE_BUTTON) {
              if (!item?.disabled) {
                item.onClick(evt);
              }
            }
            if (type === BUTTON_GROUP_TYPE_SWITCH) {
              onChange(item.value);
            }
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
}
