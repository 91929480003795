import React, { useState } from "react";
import "./style.scss";
import { BlockEntity, BlockLocation, BlockPOC } from "@components/underwriting";
import Card from "@components/card";

export default function QuoteLocationBlock({ data, onChange, isSubmit }) {
  return (
    <Card className="qu_loc" title="Location List">
      <BlockLocation data={data} onChange={onChange} isSubmit={isSubmit}/>
    </Card>
  );
}
