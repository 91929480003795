import { useState, useEffect } from "react";
import "./style.scss";
import {
  Input,
  Loading,
  QuoteAction,
  QuoteHeader,
  QuoteProgressBar,
  QuoteStepper
} from "../../../components";
import { Select, Alert } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { get, post, put } from "../../../utils/axios";
import {
  GA_CONSTS,
  INSURANCE_CARRIER_KEY_GA,
  NUM_STEPS_TO_QUOTE
} from "../../../assets/const/fim-integration";
import { addMonths, format } from "date-fns";
import _ from "lodash";
import { notify } from "@utils/common";
const { Option } = Select;

const QUESTION_INPUT_TYPE = {
  generalEligibilityExprienceMod: "number"
};
const QUESTION_INPUT_VALIDATION_FUNC = {
  generalEligibilityExprienceMod: (value) =>
    parseFloat(value) >= 0.1 && parseFloat(value) <= 9.9
};
const QUESTION_INPUT_VALIDATION_ERROR_MSG = {
  generalEligibilityExprienceMod:
    "WC Experience Modification value must be between 0.1 and 9.9"
};

export default function AdminEligibility() {
  const history = useHistory();
  let { id, applicationId } = useParams();
  const { user, getAccessTokenSilently } = useAuth0();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [eligibilityResponse, setEligibilityResponse] = useState();
  const [formVal, setFormVal] = useState([]);
  const [error, setError] = useState(null);
  const [answerMap, setAnswerMap] = useState({});

  useEffect(() => {
    if (id) {
      handleLoadFranchisee();
    }
  }, [id]);

  function uniqueQuestionId(groupId, questionId) {
    return `${groupId}-${questionId}`;
  }

  async function getGAIAppetites(franchiseeData) {
    const classCodes = _.uniq(
      franchiseeData.locationList[0].codeList.map((c) => c.code)
    );
    const token = await getAccessTokenSilently();

    const appetites = await Promise.all(
      classCodes.map((code) =>
        post(
          `gai/appetite`,
          {
            product: {
              product: "WC",
              criteria: {
                classCode: code
              }
            }
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
      )
    );
    return appetites;
  }

  async function handleLoadFranchisee() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const franchiseeFromDb = result.data.data;
      setFranchiseeData(franchiseeFromDb);

      const resultRequirement = await get(
        `requirement/${result.data.data.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchisorData(resultRequirement.data.data);

      const gaiAppetites = await getGAIAppetites(franchiseeFromDb);
      let businessTypes = [];
      for (const appetite of gaiAppetites) {
        for (const classCode of appetite?.data?.data?.product?.data
          ?.classCodes || []) {
          if (
            classCode.classCode &&
            classCode.businessType &&
            classCode.states.includes(franchiseeFromDb.locationList[0].state)
          ) {
            businessTypes.push(classCode.businessType);
          }
        }
      }

      const resultEligibility = await post(
        `gai/eligibility`,
        {
          newBusiness: {
            id: applicationId
          },
          riskSelection: {
            input: {
              line: GA_CONSTS.riskSelectionLine,
              date: format(addMonths(new Date(), 3), "yyyy-MM-dd"), //format(new Date(), "yyyy-MM-dd"),
              contextData: {
                businessTypes: businessTypes.map((type) => ({
                  id: type,
                  value: type
                })),
                generalQuestionsOnly: false
              }
            },
            options: {
              questionnaireDetails: "true"
            }
          }
        },
        {
          headers: {}
        }
      );

      setEligibilityResponse(resultEligibility.data.data);
      const questionDataList = [];
      resultEligibility.data?.data?.riskSelection.data.answerSession.questionnaire.groups.forEach(
        (group) => {
          group.questions.forEach((question) => {
            const questiondata = {
              groupId: group.groupId,
              answer: "",
              questionId: question.questionId
            };

            const questionsFromDb =
              franchiseeFromDb?.greatAmericanEligibilityQuestions;
            const answeredQuestion = questionsFromDb?.find(
              (q) =>
                q.groupId === group.groupId &&
                q.questionId === question.questionId
            );

            if (answeredQuestion) {
              questiondata.answer = answeredQuestion.answer;
              setAnswerMap((prev) => ({
                ...prev,
                [uniqueQuestionId(group.groupId, question.questionId)]:
                  answeredQuestion.answer
              }));
            }
            questionDataList.push(questiondata);
          });
        }
      );
      setFormVal(questionDataList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }

  function handleChangeObjectItem(value, groupId, questionId) {
    setAnswerMap((prev) => ({
      ...prev,
      [uniqueQuestionId(groupId, questionId)]: value
    }));
    let questionIndex = 0;
    formVal.map((question, index) => {
      if (question.groupId === groupId && question.questionId === questionId) {
        questionIndex = index;
      }
    });
    const questions = formVal;
    if (questions[questionIndex]) {
      questions[questionIndex].answer = value;
      setFormVal(questions);
    }
  }

  function handleBack() {
    history.push(`/admin/franchisees/${id}/insurance-quote`);
  }

  async function handleNext() {
    for (const question of formVal) {
      if (!answerMap[uniqueQuestionId(question.groupId, question.questionId)]) {
        notify(
          "Error",
          `Please make sure you've answered every question on this page.`
        );
        return;
      }
      const validationFunc =
        QUESTION_INPUT_VALIDATION_FUNC[question.questionId];
      if (
        validationFunc &&
        !validationFunc(
          answerMap[uniqueQuestionId(question.groupId, question.questionId)]
        )
      ) {
        notify(
          "Error",
          QUESTION_INPUT_VALIDATION_ERROR_MSG[question.questionId] ||
            "Failed input validation. Please contact support.",
          "error"
        );
        return;
      }
    }

    let eligibilityDataSubmit = _.cloneDeep(eligibilityResponse);
    eligibilityDataSubmit.riskSelection.data.answerSession.questionnaire.groups.forEach(
      (group) => {
        group.questions.forEach((question) => {
          formVal.forEach((formQuestion) => {
            if (
              group.groupId === formQuestion.groupId &&
              question.questionId === formQuestion.questionId
            ) {
              question.answer = formQuestion.answer;
            }
          });
        });
      }
    );
    const submitData = {
      newBusiness: eligibilityDataSubmit.newBusiness,
      riskSelection: {
        input: {
          action: eligibilityDataSubmit.riskSelection.data.answerSession.action,
          answerSessionId:
            eligibilityDataSubmit.riskSelection.data.answerSession
              .answerSessionId,
          contextData:
            eligibilityDataSubmit.riskSelection.data.answerSession.contextData,
          date: eligibilityDataSubmit.riskSelection.data.answerSession.date,
          line: eligibilityDataSubmit.riskSelection.data.answerSession.line,
          questionnaire:
            eligibilityDataSubmit.riskSelection.data.answerSession.questionnaire
        }
      }
    };
    try {
      setError("");
      setLoading(true);
      await saveQuestions();
      const responseEligibility = await post(`gai/eligibility`, submitData, {
        headers: {}
      });

      // history.push(`/admin/pricing/${id}/${applicationId}`);

      if (responseEligibility.data.data.newBusiness.status === "ACCEPT") {
        history.push(`/admin/pricing/${id}/${applicationId}`);
      } else {
        setEligibilityResponse(responseEligibility.data.data);
        const questionDataList = [];
        responseEligibility.data.data.riskSelection.data.answerSession.questionnaire.groups.map(
          (group) => {
            group.questions.map((question) => {
              const questiondata = {
                groupId: group.groupId,
                answer: "",
                questionId: question.questionId
              };

              const questionsFromDb =
                franchiseeData?.greatAmericanEligibilityQuestions;
              const answeredQuestion = questionsFromDb?.find(
                (q) =>
                  q.groupId === group.groupId &&
                  q.questionId === question.questionId
              );

              if (answeredQuestion) {
                questiondata.answer = answeredQuestion.answer;
                setAnswerMap((prev) => ({
                  ...prev,
                  [uniqueQuestionId(group.groupId, question.questionId)]:
                    answeredQuestion.answer
                }));
              }
              questionDataList.push(questiondata);
            });
          }
        );
        setFormVal(questionDataList);
      }
    } catch (error) {
      console.log("error ---");
      setError(error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    history.push("/admin/overview");
  }

  async function saveQuestions() {
    const token = await getAccessTokenSilently();
    await put(
      `contact/${franchiseeData._id}`,
      {
        greatAmericanEligibilityQuestions: formVal
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  }

  async function handleSave() {
    try {
      setLoading(true);
      await saveQuestions();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error ---");
      setError(error.response?.data?.message);
    }
  }

  function getType(status) {
    switch (status) {
      case "DECLINE":
        return "error";
      case "UNKNOWN":
        return "warning";
      default:
        return "info";
    }
  }
  return (
    <div className="admin_eligibility">
      <QuoteProgressBar
        step={2}
        total={NUM_STEPS_TO_QUOTE[INSURANCE_CARRIER_KEY_GA]}
      ></QuoteProgressBar>
      <QuoteHeader
        franchiseeData={franchiseeData}
        requirement={franchisorData}
        user={user}
      ></QuoteHeader>
      <div className="admin_eligibility_container">
        <QuoteStepper
          step={2}
          total={NUM_STEPS_TO_QUOTE[INSURANCE_CARRIER_KEY_GA]}
        ></QuoteStepper>
        <div className="admin_eligibility_container-content">
          <div className="admin_eligibility_container-content-box">
            {error && (
              <Alert message={error} className="form-error" type="error" />
            )}
            {eligibilityResponse && (
              <Alert
                message={`Business Status ${eligibilityResponse?.newBusiness?.status}`}
                type={getType(eligibilityResponse?.newBusiness?.status)}
              />
            )}
            {eligibilityResponse?.riskSelection?.data?.answerSession?.questionnaire?.groups.map(
              (group, index) => {
                return (
                  <div className="admin_eligibility-card">
                    <div className="admin_eligibility-card-header">
                      <h1 className="admin_eligibility-card-header-title">
                        {group.label}
                      </h1>
                    </div>
                    <div className="admin_eligibility-card-content">
                      {group.questions.map((question, index) => {
                        return (
                          <div className="line-item-container">
                            {question.answerType === "INPUT" && (
                              <Input
                                value={
                                  answerMap[
                                    uniqueQuestionId(
                                      group.groupId,
                                      question.questionId
                                    )
                                  ]
                                }
                                title={question.questionText}
                                type={
                                  QUESTION_INPUT_TYPE[question.questionId] ||
                                  question.options?.[0]?.type === "INTEGER"
                                    ? "number"
                                    : "text"
                                }
                                onChange={(evt) =>
                                  handleChangeObjectItem(
                                    question.options?.[0]?.type === "INTEGER"
                                      ? parseInt(evt.target.value)
                                      : evt.target.value,
                                    group.groupId,
                                    question.questionId
                                  )
                                }
                              />
                            )}
                            {question.answerType === "SELECT" && (
                              <div className="select-box">
                                <h3 className="select-box-title">
                                  {question.questionText}
                                </h3>
                                <Select
                                  value={
                                    answerMap[
                                      uniqueQuestionId(
                                        group.groupId,
                                        question.questionId
                                      )
                                    ]
                                  }
                                  size="large"
                                  style={{ width: "100%" }}
                                  onChange={(evt) =>
                                    handleChangeObjectItem(
                                      evt,
                                      group.groupId,
                                      question.questionId
                                    )
                                  }
                                >
                                  {question.options &&
                                    question.options.map((option, index) => (
                                      <Option value={option.optionId}>
                                        {option.label}
                                      </Option>
                                    ))}
                                </Select>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <QuoteAction
            handleClose={handleClose}
            handleSave={handleSave}
            handleBack={handleBack}
            handleNext={handleNext}
          ></QuoteAction>
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}
