import React, { useState, useEffect, useMemo } from "react";
import { Input, Button, notification, Switch } from "antd";
import { Loading } from "../../../../components";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppState } from "../../../../context";
import { post, put, get } from "../../../../utils/axios";
import { PERMISSION_TABLE, ACCESS_FULL } from "../../../../utils/permission";
import { DeleteOutlined } from "@ant-design/icons";
import "./style.scss";
import { format } from "date-fns";

export default function ZorChecklist(props) {
  const { user, getAccessTokenSilently } = useAuth0();
  const { franchisorAdmin, setFranchisorAdmin, authSetting } = useAppState();
  const [loading, setLoading] = useState(false);
  const [formVal, setFormVal] = useState(franchisorAdmin.checklist || {});
  const [checkList, setCheckList] = useState([]);
  useEffect(() => {
    handleLoadCheckList();
  }, []);

  async function handleLoadCheckList() {
    try {
      const token = await getAccessTokenSilently();
      const result = await get(`check`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (result?.data?.success) {
        setCheckList(result?.data?.data || []);
      }
    } catch (error) {}
  }

  const editable = useMemo(
    () =>
      PERMISSION_TABLE["ZOR_CHECKLIST"][authSetting.userPermission] ===
      ACCESS_FULL,
    [authSetting]
  );

  function handleChange(key, value) {
    formVal[key] = {
      value: value,
      updatedBy: user?.email,
      updatedAt: new Date()
    };
    setFormVal({ ...formVal });
  }

  async function handleSave() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();

      if (franchisorAdmin?._id) {
        const result = await put(
          `requirement/${franchisorAdmin._id}`,
          { checklist: formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
        }
      } else {
        const result = await post(
          `requirement`,
          { checklist: formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
        }
      }
      setLoading(false);
    } catch (e) {
      handleNotification(
        "Failed",
        "Save form is failed. Please contact to admin directly."
      );
      setLoading(false);
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  function handleDeleteQuestion(index) {
    formVal.splice(index, 1);
    setFormVal([...formVal]);
  }

  return (
    <div className="zor_checklist">
      {checkList.map((item, index) => (
        <div className="zor_checklist-item">
          <div className="zor_checklist-item-answ">
            <Switch
              checked={formVal[item._id]?.value}
              onChange={(checked) => handleChange(item._id, checked)}
            />
            <div className="zor_checklist-item-answ-title">{item.title}</div>
          </div>
          {formVal[item._id]?.updatedBy && (
            <div className="zor_checklist-item-ques">
              <span>Last updated by </span>
              <b>{formVal[item._id]?.updatedBy}</b>
              <span> at</span>
              <b>
                {" "}
                {format(new Date(formVal[item._id]?.updatedAt), "do MMM yy")}
              </b>
            </div>
          )}
        </div>
      ))}
      <div className="zor_checklist-footer">
        <Button size="large" type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
