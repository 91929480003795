import React, { useState, useEffect } from "react";
import {
  Input,
  Loading,
  FormMask,
  Textarea,
  ScreenMask,
} from "../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../utils/axios";
import {
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  FORM_UMBRELLA_LIABILITY,
  FORM_EXCESS_LIABILITY,
  FORM_DEDUCTIBLE,
  FORM_RETENTION,
  FORM_NONE,
  SOURCE_ONBOARDING,
} from "../../../assets/const/form";
import "./style.scss";
import { useAppState } from "@context";

export default function UmbrellaForm(props) {
  const { data, btnLabel, source, onNext, readOnly = false } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;

  const [formVal, setFormVal] = useState({
    umbrella_subrogration_each_occurance:
      sourceData?.umbrella_subrogration_each_occurance || "",
    umbrella_subrogration_aggregate:
      sourceData?.umbrella_subrogration_aggregate || "",
    umbrella_subrogration_liability:
      sourceData?.umbrella_subrogration_liability || "",
    umbrella_subrogration_activity:
      sourceData?.umbrella_subrogration_activity || "",
    umbrella_subrogration_status:
      sourceData?.umbrella_subrogration_status || "",
    umbrella_subrogration_broker_note:
      sourceData?.umbrella_subrogration_broker_note || "",
    umbrella_subrogration_required:
      sourceData?.umbrella_subrogration_required || false,
  });

  useEffect(() => {
    setFormVal({
      umbrella_subrogration_each_occurance:
        sourceData?.umbrella_subrogration_each_occurance || "",
      umbrella_subrogration_aggregate:
        sourceData?.umbrella_subrogration_aggregate || "",
      umbrella_subrogration_liability:
        sourceData?.umbrella_subrogration_liability || "",
      umbrella_subrogration_activity:
        sourceData?.umbrella_subrogration_activity || "",
      umbrella_subrogration_status:
        sourceData?.umbrella_subrogration_status || "",
      umbrella_subrogration_broker_note:
        sourceData?.umbrella_subrogration_broker_note || "",
      umbrella_subrogration_required:
        sourceData?.umbrella_subrogration_required || false,
    });
  }, [sourceData]);

  useEffect(() => {
    const { handleUpdateForm } = props;
    if (handleUpdateForm) {
      handleUpdateForm(formVal);
    }
  }, [formVal]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleSubmit() {
    handleFormSubmit();
  }

  async function handleFormSubmit() {
    const { onUpdate } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="umbrella-content">
      <div className="form-header">
        <div className="line-disable">
          <Switch
            checked={formVal.umbrella_subrogration_required}
            onChange={(checked) =>
              handleChange("umbrella_subrogration_required", checked)
            }
          />
          <span className="disable-title">Required</span>
        </div>
      </div>
      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_liability",
                  evt.target.value
                )
              }
              value={formVal.umbrella_subrogration_liability}
            >
              <Radio value={FORM_UMBRELLA_LIABILITY}>UMBRELLA LIABILITY</Radio>
              <Radio value={FORM_EXCESS_LIABILITY}>EXCESS LIABILITY</Radio>
            </Radio.Group>
          </div>

          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange("umbrella_subrogration_activity", evt.target.value)
              }
              value={formVal.umbrella_subrogration_activity}
            >
              <Radio value={FORM_OCCURRENCE}>OCCURRENCE</Radio>
              <Radio value={FORM_CLAIMS_MADE}>CLAIMS-MADE</Radio>
            </Radio.Group>
          </div>

          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange("umbrella_subrogration_status", evt.target.value)
              }
              value={formVal.umbrella_subrogration_status}
            >
              <Radio value={FORM_DEDUCTIBLE}>DEDUCTIBLE</Radio>
              <Radio value={FORM_RETENTION}>RETENTION</Radio>
              <Radio value={FORM_NONE}>NONE</Radio>
            </Radio.Group>
          </div>

          <div className="line-item-container broker-report-container">
            <Textarea
              title="Notes from broker"
              type="text"
              placeholder="Please enter your text here."
              value={formVal.umbrella_subrogration_broker_note}
              rows={10}
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_broker_note",
                  evt.target.value
                )
              }
            />
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              placeholder="EACH OCCURRENCE"
              value={formVal.umbrella_subrogration_each_occurance}
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_each_occurance",
                  evt.target.value
                )
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="AGGREGATE"
              placeholder="AGGREGATE"
              value={formVal.umbrella_subrogration_aggregate}
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_aggregate",
                  evt.target.value
                )
              }
            />
          </div>
        </div>
        {!formVal.umbrella_subrogration_required && <FormMask />}
      </div>
      <div className="form-footer">
        {!readOnly && (
          <div className="button-container">
            <Button type="primary" onClick={handleSubmit}>
              {btnLabel || "Save"}
            </Button>
          </div>
        )}
      </div>
      <Loading loading={loading} />
      {readOnly && <ScreenMask />}
    </div>
  );
}
