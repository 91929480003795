import { Select } from "antd";
import React from "react";
import { Input } from "../../../../../components";
import "./style.scss";

const { Option } = Select;

export default function FormLiab(props) {
  const { formVal, onChange } = props;

  return (
    <div className="form_liab">
      <div className="form-group-four-container">
        <Input
          title="Annual Revenue"
          type="number"
          value={formVal.annualRevenue}
          onChange={(evt) => onChange("annualRevenue", evt.target.value)}
        />
        <Input
          title="Total Payroll"
          type="number"
          value={formVal.totalPayroll}
          onChange={(evt) => onChange("totalPayroll", evt.target.value)}
        />
        <Input
          title="Total Sub Costs"
          type="number"
          value={formVal.totalSubCosts}
          onChange={(evt) => onChange("totalSubCosts", evt.target.value)}
        />
        <Input
          title="Number of Losses"
          type="number"
          value={formVal.numberOfLosses}
          onChange={(evt) => onChange("numberOfLosses", evt.target.value)}
        />
      </div>
    </div>
  );
}
