import React, { useState } from "react";
import { Radio } from "antd";
import { BlockDriver, BlockVehicle } from "@components/underwriting";
import Card from "@components/card";

export default function QuoteVehicleBlock({
  vehicleData,
  driverData,
  onChangeVehicle,
  onChangeDriver
}) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="qu_vehicle">
      <h4>Do you have vehicles and drivers you'd like to add?</h4>
      <Radio.Group
        value={isVisible}
        onChange={(evt) => setIsVisible(evt?.target?.value)}
      >
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>
      {isVisible && (
        <>
          <Card className="qu_vehicle-block" title="Vehicles">
            <BlockVehicle data={vehicleData} onChange={onChangeVehicle} />
          </Card>
          <Card className="qu-vehicle-block" title="Drivers">
            <BlockDriver data={driverData} onChange={onChangeDriver} />
          </Card>
        </>
      )}
    </div>
  );
}
