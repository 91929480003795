import React, { useEffect, useState, useMemo } from "react";
import "./style.scss";
import Pdf from "react-to-pdf";
import { Breadcrumb, Button, notification, Switch, Collapse } from "antd";
import IconRikorEmail from "@assets/images/rikor-logo.svg";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { useHistory, useLocation, useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { get, post } from "@utils/axios";
import LiabilityForm from "./liability-form";
import AutoForm from "./auto-form";
import WorkersCompForm from "./workers-comp-form";
import UmbrellaForm from "./umbrella-form";
import AdditionalReport from "./additional-report";
import CrimeForm from "./crime-form";
import GarageForm from "./garage-form";
import EpliForm from "./epli-form";
import CyberForm from "./cyber-form";
import ProLiabForm from "./pro-liab-form";
import PolLiabForm from "./pol-liab-form";
import InlandMarineForm from "./im-form";
import ProInsForm from "./pro-ins-form";
import { get as getData } from "lodash";
import { Loading } from "@components";
import moment from "moment";
import {
  INSURANCE_STATUS_NONE,
  INSURANCE_STATUS_COMPLIANT,
  INSURANCE_STATUS_NON_COMPLIANT,
  INSURANCE_STATUS_NON_VERIFIED,
  STATUS_SECTION_APPROVE,
  DOC_MAX_LIMIT,
} from "@assets/const/status";
import { handleGetStatus } from "@utils/build-logic";
import { VIEW_SOURCE_ZEE, VIEW_SOURCE_UNAUTHORIZED } from "@assets/const/ui";

export default function FranchiseeReport(props) {
  const ref = React.createRef();
  const history = useHistory();
  const location = useLocation();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [docIndex, setDocIndex] = useState({
    commercial_general_liability_form_doc_index: -1,
    automotive_liability_form_doc_index: -1,
    workers_compensation_form_doc_index: -1,
    umbrella_subrogration_form_doc_index: -1,
    crime_form_doc_index: -1,
    garage_form_doc_index: -1,
    epli_form_doc_index: -1,
    cyber_form_doc_index: -1,
    pro_liab_form_doc_index: -1,
    pol_liab_form_doc_index: -1,
    im_form_doc_index: -1,
    pro_ins_form_doc_index: -1,
    additional_infor_doc_index: -1,
  });

  //CHANGES
  const [isToggled, setIsToggled] = useState(false);

  useEffect(() => {
    if (props.viewSource === VIEW_SOURCE_ZEE) {
      if (props.zeeId) {
        handleLoadFranchisee(props.zeeId);
      }
    } else if (props.viewSource === VIEW_SOURCE_UNAUTHORIZED) {
      if (props.zeeId) {
        handleLoadUnauthSee(props.zeeId);
      }
    } else {
      if (id) {
        handleLoadFranchisee(id);
      }
    }
  }, []);

  //CHANGES
  const toggleFlip = (checked) => {
    setIsToggled(checked);
  };

  const { Panel } = Collapse;

  async function handleLoadUnauthSee(zeeId) {
    try {
      setLoading(true);
      const result = await get(`contact/${zeeId}/report`, {
        headers: {},
      });
      setFranchiseeData(result.data.zee);
      setDocIndex({
        commercial_general_liability_form_doc_index:
          result.data.zee.finalData
            ?.commercial_general_liability_form_doc_index,
        automotive_liability_form_doc_index:
          result.data.zee.finalData?.automotive_liability_form_doc_index,
        workers_compensation_form_doc_index:
          result.data.zee.finalData?.workers_compensation_form_doc_index,
        umbrella_subrogration_form_doc_index:
          result.data.zee.finalData?.umbrella_subrogration_form_doc_index,
        crime_form_doc_index: result.data.zee.finalData?.crime_form_doc_index,
        garage_form_doc_index: result.data.zee.finalData?.garage_form_doc_index,
        epli_form_doc_index: result.data.zee.finalData?.epli_form_doc_index,
        cyber_form_doc_index: result.data.zee.finalData?.cyber_form_doc_index,
        pro_liab_form_doc_index:
          result.data.zee.finalData?.pro_liab_form_doc_index,
        pol_liab_form_doc_index:
          result.data.zee.finalData?.pol_liab_form_doc_index,
        im_form_doc_index: result.data.zee.finalData?.im_form_doc_index,
        pro_ins_form_doc_index:
          result.data.zee.finalData?.pro_ins_form_doc_index,
        additional_infor_doc_index:
          result.data.zee.finalData?.additional_infor_doc_index,
      });

      const rawFranchisorData = getData(result, "data.zor");

      if (rawFranchisorData) {
        setFranchisorData(rawFranchisorData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      handleNotification("Failed", "Franchisee Data loading is failed");
    }
  }

  async function handleLoadFranchisee(zeeId) {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${zeeId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFranchiseeData(result.data.data);
      setDocIndex({
        commercial_general_liability_form_doc_index:
          result.data.data.finalData
            ?.commercial_general_liability_form_doc_index,
        automotive_liability_form_doc_index:
          result.data.data.finalData?.automotive_liability_form_doc_index,
        workers_compensation_form_doc_index:
          result.data.data.finalData?.workers_compensation_form_doc_index,
        umbrella_subrogration_form_doc_index:
          result.data.data.finalData?.umbrella_subrogration_form_doc_index,
        crime_form_doc_index: result.data.data.finalData?.crime_form_doc_index,
        garage_form_doc_index:
          result.data.data.finalData?.garage_form_doc_index,
        epli_form_doc_index: result.data.data.finalData?.epli_form_doc_index,
        cyber_form_doc_index: result.data.data.finalData?.cyber_form_doc_index,
        pro_liab_form_doc_index:
          result.data.data.finalData?.pro_liab_form_doc_index,
        pol_liab_form_doc_index:
          result.data.data.finalData?.pol_liab_form_doc_index,
        im_form_doc_index: result.data.data.finalData?.im_form_doc_index,
        pro_ins_form_doc_index:
          result.data.data.finalData?.pro_ins_form_doc_index,
        additional_infor_doc_index:
          result.data.data.finalData?.additional_infor_doc_index,
      });
      const resultFranchisorData = await get(
        `requirement/${result.data.data.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const rawFranchisorData = getData(resultFranchisorData, "data.data");

      if (rawFranchisorData) {
        setFranchisorData(rawFranchisorData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      handleNotification("Failed", "Franchisee Data loading is failed");
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  function handleBackFranchisor() {
    history.push("/admin/franchisees");
  }

  function handleBackFranchiseeDetail() {
    const pathname = location.pathname.split("/report");
    history.push(pathname[0]);
  }

  function handleBackUpload() {
    history.push(`/coiportal/add/${props.zeeId}`);
  }

  function getPlatform() {
    if (franchiseeData) {
      return franchiseeData.entity_name;
      // if (franchiseeData.sourcePlatform === "franconnect") {
      //   return franchiseeData.tenantId;
      // }
    }

    return "Rikor";
  }

  async function handleDownload() {
    const issueLiability = franchisorData.commercial_general_liability_required
      ? franchiseeData?.liabilityIssue || []
      : [];
    const issueAuto = franchisorData.automotive_liability_required
      ? franchiseeData?.autoIssue || []
      : [];
    const issueWork = franchisorData.workers_compensation_required
      ? franchiseeData?.workCompIssue || []
      : [];
    const issueUmbrella = franchisorData.umbrella_subrogration_required
      ? franchiseeData?.umbrellaIssue || []
      : [];
    const issueCrime = franchisorData.crime_required
      ? franchiseeData?.crimeIssue || []
      : [];
    const issueGarage = franchisorData.garage_required
      ? franchiseeData?.garageIssue || []
      : [];
    const issueEpli = franchisorData.epli_required
      ? franchiseeData?.epliIssue || []
      : [];
    const issueCyber = franchisorData.cyber_required
      ? franchiseeData?.cyberIssue || []
      : [];

    const issueAdditional = franchiseeData?.additionalIssue || [];

    const totalIssues = [
      ...issueLiability,
      ...issueAuto,
      ...issueWork,
      ...issueUmbrella,
      ...issueCrime,
      ...issueGarage,
      ...issueEpli,
      ...issueCyber,
      ...issueAdditional,
    ];

    const liabilityStatus = handleGetStatus(
      franchiseeData?.finalData?.commercial_general_liability_form_status,
      franchiseeData?.liabilityIssue,
      franchisorData?.commercial_general_liability_required
    );
    const autoStatus = handleGetStatus(
      franchiseeData?.finalData?.automotive_liability_form_status,
      franchiseeData?.autoIssue,
      franchisorData?.automotive_liability_required
    );
    const umbrellaStatus = handleGetStatus(
      franchiseeData?.finalData?.umbrella_subrogration_form_status,
      franchiseeData?.umbrellaIssue,
      franchisorData?.umbrella_subrogration_required
    );
    const workCompStatus = handleGetStatus(
      franchiseeData?.finalData?.workers_compensation_form_status,
      franchiseeData?.workCompIssue,
      franchisorData?.workers_compensation_required
    );
    const crimeStatus = handleGetStatus(
      franchiseeData?.finalData?.crime_form_status,
      franchiseeData?.crimeIssue,
      franchisorData?.crime_required
    );
    const garageStatus = handleGetStatus(
      franchiseeData?.finalData?.garage_form_status,
      franchiseeData?.garageIssue,
      franchisorData?.garage_required
    );
    const epliStatus = handleGetStatus(
      franchiseeData?.finalData?.epli_form_status,
      franchiseeData?.epliIssue,
      franchisorData?.epli_required
    );
    const cyberStatus = handleGetStatus(
      franchiseeData?.finalData?.cyber_form_status,
      franchiseeData?.cyberIssue,
      franchisorData?.cyber_required
    );
    const report = {
      documentPrepareFor: [
        getPlatform(),
        `${franchiseeData.first_name} ${franchiseeData.last_name}`,
        moment(franchiseeData.updatedAt).format("MM/DD/YYYY"),
      ],
      documentPrepareBy: [
        "Wade Millward",
        "Rikor Insurance Consultancy",
        "wade.millward@rikor.io",
      ],
      franchisorRiskProfile: [franchisorData?.personal_infor_business_name],
      reportNumber: 1,
      summaryRecommendations:
        "Rikor uses proprietary software and algorithms to analyze your policy to best protect your business investments from risks inherent to your industry. The following issues have been summarized here, but detailed recommendations are included in this report. The representative who sent you this report will help you move forward. These recommendations will <bold> protect you and your franchisor </bold> meet your minimum insurance requirements",
      complianceAudit: [
        getPlatform(),
        `${franchiseeData.first_name} ${franchiseeData.last_name}`,
        moment(franchiseeData.updatedAt).format("MM/DD/YYYY"),
      ],
      totalPolicyIssues: totalIssues.length,
      currentStatus: franchiseeData.status,
      complianceSummaryFactors: [
        {
          name: "Liability",
          analysis: liabilityStatus,
        },
        {
          name: "Auto",
          analysis: autoStatus,
        },
        {
          name: "Workers Comp",
          analysis: workCompStatus,
        },
        {
          name: "Umbrella",
          analysis: umbrellaStatus,
        },
        {
          name: "Crime",
          analysis: crimeStatus,
        },
        {
          name: "Garage",
          analysis: garageStatus,
        },
        {
          name: "Employement Practices Liability",
          analysis: epliStatus,
        },
        {
          name: "Cyber",
          analysis: cyberStatus,
        },
      ],
      detailedReport:
        "Rikor has produced this detailed report to indicate the field values that either expose your franchise to risk or do not meet the insurance requirements of your franshisor. This analysis is not generic, these values are tailored to your franchise and the riske profiel set by the franhisor.",
      complianceDetailFactors: [],
    };

    if (issueLiability.length > 0) {
      report.complianceDetailFactors.push({
        name: "Liability",
        analysis: issueLiability.map((item) => item.title),
      });
    }

    if (issueAuto.length > 0) {
      report.complianceDetailFactors.push({
        name: "Auto",
        analysis: issueAuto.map((item) => item.title),
      });
    }

    if (issueWork.length > 0) {
      report.complianceDetailFactors.push({
        name: "Work Comp",
        analysis: issueWork.map((item) => item.title),
      });
    }

    if (issueUmbrella.length > 0) {
      report.complianceDetailFactors.push({
        name: "Umbrella",
        analysis: issueUmbrella.map((item) => item.title),
      });
    }

    if (issueCrime.length > 0) {
      report.complianceDetailFactors.push({
        name: "Crime",
        analysis: issueCrime.map((item) => item.title),
      });
    }

    if (issueGarage.length > 0) {
      report.complianceDetailFactors.push({
        name: "Garage",
        analysis: issueGarage.map((item) => item.title),
      });
    }
    if (issueEpli.length > 0) {
      report.complianceDetailFactors.push({
        name: "Employment Practices Liability",
        analysis: issueEpli.map((item) => item.title),
      });
    }
    if (issueCyber.length > 0) {
      report.complianceDetailFactors.push({
        name: "Cyber",
        analysis: issueCyber.map((item) => item.title),
      });
    }

    if (issueAdditional.length > 0) {
      report.complianceDetailFactors.push({
        name: "Additional Information",
        analysis: issueAdditional.map((item) => item.title),
      });
    }

    const token = await getAccessTokenSilently();
    post(
      `report/generate/compliance`,
      { report },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `document.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  }

  const options = {
    unit: "px",
    format: [2700, 900],
  };

  const franchiseeName = useMemo(() => {
    if (franchiseeData) {
      if (franchiseeData?.isInsureDiff) {
        return `${franchiseeData?.insuranceData?.first_name || "Franchisee"} ${
          franchiseeData?.insuranceData?.last_name || "Name"
        }`;
      }

      return `${franchiseeData.first_name || "Franchisee"} ${
        franchiseeData.last_name || "Name"
      }`;
    }

    return "Franchisee Name";
  }, [franchiseeData]);

  const certInfoData = [
    {
      title: "Certificate Holder",
      fddRequirement: [
        franchisorData?.additional_infor_business_name || "",
        franchisorData?.additional_infor_address || "",
        franchisorData?.additional_infor_city || "",
        franchisorData?.additional_infor_state || "",
      ]
        .filter((item) => item)
        .join(", "),
      formValue: franchiseeData?.finalData?.additional_infor_certificate_holder
        ? franchiseeData?.certificateIssue?.some(
            (issue) => issue.title === "Certificate Holder is not matched"
          )
          ? {
              type: "error",
              message: "Certificate Holder is not matched",
            }
          : {
              type: "success",
              message:
                franchiseeData?.finalData?.additional_infor_certificate_holder,
            }
        : {
            type: "neutral",
            message: "Certificate Holder is not matched",
          },
    },
    {
      title: "Description of Operations",
      fddRequirement: franchisorData?.additional_infor_certificate || "",
      formValue: franchiseeData?.finalData
        ?.additional_infor_operation_description
        ? franchiseeData?.certificateIssue?.some(
            (issue) => issue.title === "Certificate of Insurance is not matched"
          )
          ? {
              type: "error",
              message: "Certificate of Insurance is not matched",
            }
          : {
              type: "success",
              message:
                franchiseeData?.finalData
                  ?.additional_infor_operation_description,
            }
        : {
            type: "neutral",
            message: "Certificate of Insurance is not matched",
          },
    },
  ];

  return (
    <div className="franchisee-report-container">
      <div className="content-container">
        <div className="card-container">
          <div className="card-content">
            <h2 className="content-header">Insured Info</h2>
            <div className="insured-info-section">
              <div>
                {franchiseeData.insuranceData?.entity_name ||
                  franchiseeData?.entity_name}
              </div>
              <div>
                {franchiseeData.insuranceData?.dba || franchiseeData?.dba}
              </div>
              <div>
                {franchiseeData.insuranceData?.first_name ||
                  franchiseeData?.first_name}{" "}
                {franchiseeData.insuranceData?.last_name ||
                  franchiseeData?.last_name}
              </div>
              <div>
                {franchiseeData.insuranceData?.email || franchiseeData?.email}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-container" ref={ref}>
        <div className="card-container">
          <div className="card-content">
            <div className="content-header">
              <h2>Please process the insurance request below:</h2>
            </div>

            <div className="toggle-switch">
              <Switch checked={isToggled} onChange={toggleFlip} />
              <span className="italic-text">
                Only show non-compliant and expiring requirements
              </span>
            </div>

            <div>
              {franchisorData?.commercial_general_liability_required && (
                <div className="section">
                  <div className="detail-content">
                    <LiabilityForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={
                        docIndex.commercial_general_liability_form_doc_index
                      }
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.automotive_liability_required && (
                <div className="section">
                  <div className="detail-content">
                    <AutoForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.automotive_liability_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.workers_compensation_required && (
                <div className="section">
                  <div className="detail-content">
                    <WorkersCompForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.workers_compensation_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.umbrella_subrogration_required && (
                <div className="section">
                  <div className="detail-content">
                    <UmbrellaForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.umbrella_subrogration_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.crime_required && (
                <div className="section">
                  <div className="detail-content">
                    <CrimeForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.crime_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.garage_required && (
                <div className="section">
                  <div className="detail-content">
                    <GarageForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.garage_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.epli_required && (
                <div className="section">
                  <div className="detail-content">
                    <EpliForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.epli_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.cyber_required && (
                <div className="section">
                  <div className="detail-content">
                    <CyberForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.cyber_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.pro_liab_required && (
                <div className="section">
                  <div className="detail-content">
                    <ProLiabForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.pro_liab_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.pol_liab_required && (
                <div className="section">
                  <div className="detail-content">
                    <PolLiabForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.pol_liab_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.im_required && (
                <div className="section">
                  <div className="detail-content">
                    <InlandMarineForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.im_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}

              {franchisorData?.pro_ins_required && (
                <div className="section">
                  <div className="detail-content">
                    <ProInsForm
                      franchiseeData={franchiseeData}
                      franchisorData={franchisorData}
                      docIndex={docIndex.pro_ins_form_doc_index}
                      toggleState={isToggled}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Loading loading={loading} />
      </div>

      <div className="content-container">
        <div className="card-container">
          <div className="card-content">
            <h2 className="content-header">Certificate Information</h2>
            <div className="liability-table">
              <div className="table-header">
                <span className="table-header-field">Title</span>
                <span className="table-header-fdd">Requirement</span>
                <span className="table-header-coverage">Your Coverage</span>
              </div>
              {certInfoData.map((row, index) => (
                <div key={index} className="table-row">
                  <div className="table-cell field-title">
                    <span>{row.title}</span>
                  </div>

                  <div className="table-cell fdd-requirement">
                    <span>{row.fddRequirement}</span>
                  </div>
                  
                  <div className="table-cell">
                    {row.formValue.type === "error" ? (
                      <span className="value-red">
                        <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                        {row.formValue.message}
                      </span>
                    ) : row.formValue.type === "success" ? (
                      <span className="value-green">
                        <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                        {row.formValue.message}
                      </span>
                    ) : (
                      <span className="value-red">
                        <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                        {row.formValue.message}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
