import React from "react";
import { Switch, Select } from "antd";
import { Input } from "../../../../../components";
import { CloseOutlined } from "@ant-design/icons";
import { ButtonGroup } from "@components";
import "./style.scss";
import { BUTTON_GROUP_TYPE_SWITCH } from "@components/button-group";
import { ROLE_LIST } from "@assets/const/ui";

const SCHEDULE_OPTIONS = [
  {
    label: "1d",
    value: "1d"
  },
  {
    label: "3d",
    value: "3d"
  },
  {
    label: "5d",
    value: "5d"
  },
  {
    label: "15d",
    value: "15d"
  },
  {
    label: "30d",
    value: "30d"
  }
];

export default function ContactItem(props) {
  const { data, onChange, onClose } = props;

  function handleClose() {
    onClose();
  }
  return (
    <div className="contact_item">
      <div className="form-group-one-two-container">
        <div className="">
          <Input
            title="First Name*"
            type="text"
            placeholder="First Name*"
            value={data.firstName}
            onChange={(evt) => onChange("firstName", evt.target.value)}
          ></Input>
          <Input
            title="Last Name*"
            type="text"
            placeholder="Last Name*"
            value={data.lastName}
            onChange={(evt) => onChange("lastName", evt.target.value)}
          ></Input>
          <Input
            title="Email*"
            type="text"
            placeholder="Email*"
            value={data.email}
            onChange={(evt) => onChange("email", evt.target.value)}
          ></Input>
          <Input
            title="Telephone*"
            type="text"
            placeholder="Telephone*"
            value={data.telephone}
            onChange={(evt) => onChange("telephone", evt.target.value)}
          ></Input>
        </div>
        <div className="contact_item-wrapper">
          <div className="contact_item-wrapper-activity">
            <label className="contact_item-wrapper-activity-label">
              Contact Activity
            </label>
            <div className="contact_item-wrapper-activity-item">
              <span className="contact_item-wrapper-activity-item-label">
                Primary
              </span>
              <Switch
                checked={data.isPrimary}
                onChange={(check) => onChange("isPrimary", check)}
              />
            </div>
            <div className="contact_item-wrapper-activity-item">
              <span className="contact_item-wrapper-activity-item-label">
                Active
              </span>
              <Switch
                checked={data.isActive}
                onChange={(check) => onChange("isActive", check)}
              />
            </div>
            <div className="contact_item-wrapper-activity-item">
              <span className="contact_item-wrapper-activity-item-label">
                Role
              </span>
              <Select
                style={{ width: "120px" }}
                value={data.role}
                onChange={(val) => onChange("role", val)}
                options={ROLE_LIST}
              />
            </div>
          </div>

          <div className="contact_item-wrapper-report">
            <label className="contact_item-wrapper-report-label">Reports</label>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.isReport}
                  onChange={(check) => onChange("isReport", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Monthly Executive Summary - Insurance Report
                </span>
              </div>

              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.reportSchedule}
                onChange={(check) => onChange("reportSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.isBindPolicy}
                  onChange={(check) => onChange("isBindPolicy", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Recently Insured By Rikor Report Report
                </span>
              </div>

              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.bindPolicySchedule}
                onChange={(check) => onChange("bindPolicySchedule", check)}
              />
            </div>
            {/* <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              Canceled Policies Notification
            </span>
            <Switch
              checked={data.isCancelPolicy}
              onChange={(check) => onChange("isCancelPolicy", check)}
            />
          </div> */}
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.isLapseCoverage}
                  onChange={(check) => onChange("isLapseCoverage", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Franchisee Lapse in Coverage Report
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.lapseCoverageSchedule}
                onChange={(check) => onChange("lapseCoverageSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.isInactiveZee}
                  onChange={(check) => onChange("isInactiveZee", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Inactive Franchisee Report
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.inactiveZeeSchedule}
                onChange={(check) => onChange("inactiveZeeSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.isNetPromoter}
                  onChange={(check) => onChange("isNetPromoter", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Non-Compliant Franchisee Report
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.netPromoterSchedule}
                onChange={(check) => onChange("netPromoterSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.pocConfirm}
                  onChange={(check) => onChange("pocConfirm", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  POC Confirmation Request
                </span>
              </div>

              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.pocConfirmSchedule}
                onChange={(check) => onChange("pocConfirmSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.newLeadReport}
                  onChange={(check) => onChange("newLeadReport", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  New Lead Report
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.newLeadSchedule}
                onChange={(check) => onChange("newLeadSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.coiUploadReport}
                  onChange={(check) => onChange("coiUploadReport", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  COI Upload Report
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.coiUploadSchedule}
                onChange={(check) => onChange("coiUploadSchedule", check)}
              />
            </div>
            <div className="contact_item-wrapper-report-item">
              <div className="contact_item-wrapper-report-item-tool">
                <Switch
                  checked={data.consultIntroEmail}
                  onChange={(check) => onChange("consultIntroEmail", check)}
                />
                <span className="contact_item-wrapper-report-item-tool-label">
                  Consultant Introduction Email
                </span>
              </div>
              <ButtonGroup
                type={BUTTON_GROUP_TYPE_SWITCH}
                buttonList={SCHEDULE_OPTIONS}
                value={data.consultIntroSchedule}
                onChange={(check) => onChange("consultIntroSchedule", check)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="contact_item-action">
        <CloseOutlined
          className="contact_item-action-icon"
          onClick={handleClose}
        />
      </div>
    </div>
  );
}
