export const ZEE_RATING_BASIS = [
  {
    title: "Average Annual Revenue",
    value: "",
  },
  {
    title: "Average Annual Labor Payroll (8380)",
    value: "",
  },
  {
    title: "Average Annual Labor Payroll (8742)",
    value: "",
  },
  {
    title: "Average Annual Labor Payroll (8810)",
    value: "",
  },
  {
    title: "BPP",
    value: "",
  },
  {
    title: "Business Interruption",
    value: "",
  },
  {
    title: "Tenant Improvements",
    value: "",
  },
  {
    title:
      "Garagekeepers (verify types of vehicles zee works on, high value may need higher limits)",
    value: "",
  },
  {
    title: "Insurance Payments",
    value: "",
  },
  {
    title: "Blanket Additional Insured (enhancement)",
    value: "",
  },
  {
    title: "Blanket Waiver ",
    value: "",
  },
  {
    title: "Hired/Non Owned Auto (if no commercial vehicles)",
    value: "",
  },
];
