import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Table } from "antd";
import IconDoc from "../../../../assets/images/icon-document.png";
import moment from "moment";
import {
  COVERAGE_KEY_AUTO,
  COVERAGE_KEY_LIABILITY,
  COVERAGE_KEY_PROPERTY,
  COVERAGE_KEY_UMBRELLA,
  COVERAGE_KEY_WORKERS_COMP,
} from "../../../../assets/const/fim-integration";

const DOCUMENT_STATUS_ARCHIVED = "archived";
const DOCUMENT_STATUS_ACTIVE = "active";
const PROPOSAL_STATUS_COMPLETED = "Completed";
const PROPOSAL_STATUS_IN_PROCESS = "In Progress";

export default function QuoteProposal(props) {
  const { franchiseeData } = props;
  const history = useHistory();
  const { id } = useParams();
  const [quoteList, setQuoteList] = useState([]);

  useEffect(() => {
    handleSetQuoteList(franchiseeData?.quoteList || []);
  }, [franchiseeData?.quoteList]);

  function handleSetQuoteList(quoteData) {
    let quoteListMap = {};
    quoteData.forEach((item) => {
      var proposalId = item.quote_id || item.business_id;
      if (quoteListMap[proposalId]) {
        quoteListMap[proposalId]["coverage"] += `, ${getCoverage(
          item.coverage
        )}`;
      } else {
        quoteListMap[proposalId] = {
          proposalId,
          date: moment(item.save_date).format("LLL"),
          preparedBy: item.prepared_by,
          status: item.status,
          integration: item.integration,
          coverage: getCoverage(item.coverage),
        };
      }
    });
    setQuoteList(Object.values(quoteListMap));
  }

  function getCoverage(coverage) {
    switch (coverage) {
      case COVERAGE_KEY_LIABILITY:
        return "Liability";
      case COVERAGE_KEY_AUTO:
        return "Auto";
      case COVERAGE_KEY_UMBRELLA:
        return "Umbrella";
      case COVERAGE_KEY_WORKERS_COMP:
        return "Work Comp";
      case COVERAGE_KEY_PROPERTY:
        return "Property";
      default:
        return "Liability";
    }
  }

  const columnsQuote = [
    {
      title: "Quote proposal ID",
      dataIndex: "proposalId",
      key: "proposalId",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Prepared By ",
      dataIndex: "preparedBy",
      key: "preparedBy",
    },
    {
      title: "Integration",
      dataIndex: "integration",
      key: "integration",
    },
    {
      title: "Coverage",
      dataIndex: "coverage",
      key: "coverage",
    },
    // {
    //   title: "File",
    //   dataIndex: "preparedBy",
    //   key: "preparedBy",
    //   render: (filePath) => {
    //     return (
    //       <a
    //         className="file-item-container"
    //         onClick={(evt) => handleMarketplaceFlow()}
    //       >
    //         <img src={IconDoc} />
    //       </a>
    //     );
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderCompliantQuoteStatus,
    },
  ];

  function renderCompliantQuoteStatus(status) {
    // eslint-disable-next-line default-case
    switch (status) {
      case PROPOSAL_STATUS_COMPLETED:
        return <div className="status-container success">{status}</div>;
      case PROPOSAL_STATUS_IN_PROCESS:
        return <div className="status-container info">{status}</div>;
      default:
        return <div className="status-container danger">{status}</div>;
    }
  }

  function handleNavDetail(quoteId) {
    history.push(`/admin/franchisees/${id}/insurance-quote/${quoteId}`);
  }

  const onRow = (row) => ({
    onClick: () => {
      handleNavDetail(row.proposalId);
    },
  });

  return (
    <div className="card-container">
      <div className="card-header">
        <h3>Compliant Quote Proposals</h3>
      </div>
      <div className="card-content">
        <Table dataSource={quoteList} onRow={onRow} columns={columnsQuote} />
      </div>
    </div>
  );
}
