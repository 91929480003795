import React, { useState } from "react";
import { useAppState } from "@context";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, post, get, put } from "@utils/axios";
import { getTemplateParam } from "@utils/template";
import Input from "@components/input";
import { Switch, Button, Modal } from "antd";

export default function TemplateCreate({ onClose, onLoad }) {
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const [templateTitle, setTemplateTitle] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  async function handleSave() {
    const param = getTemplateParam(franchisorAdmin);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `requirement-template`,
        {
          title: templateTitle,
          ...param,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result?.data?.success) {
        onLoad();
      }
    } catch (error) {}
    onClose();
  }

  return (
    <div className="modal_template_create">
      <Input
        title="Title"
        value={templateTitle}
        onChange={(evt) => setTemplateTitle(evt.target.value)}
      ></Input>

      <div className="modal_template_create-footer">
        <Button size="large" type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
}
