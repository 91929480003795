import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  Input,
  Loading,
  FormMask,
  Textarea,
  ScreenMask,
} from "../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../utils/axios";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
  SOURCE_ONBOARDING,
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
} from "../../../assets/const/form";
import { useAppState } from "@context/index";

export default function ProLiabForm(props) {
  const { data, btnLabel, source, onNext, readOnly = false } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;

  const [formVal, setFormVal] = useState({
    pro_liab_required: sourceData?.pro_liab_required || false,
    pro_liab_occ: sourceData?.pro_liab_occ || "",
    pro_liab_agg: sourceData?.pro_liab_agg || "",
    pro_liab_type: sourceData?.pro_liab_type || "",
    pro_liab_broker_note: sourceData?.pro_liab_broker_note || "",
  });

  useEffect(() => {
    setFormVal({
      pro_liab_required: sourceData?.pro_liab_required || false,
      pro_liab_occ: sourceData?.pro_liab_occ || "",
      pro_liab_agg: sourceData?.pro_liab_agg || "",
      pro_liab_type: sourceData?.pro_liab_type || "",
      pro_liab_broker_note: sourceData?.pro_liab_broker_note || "",
    });
  }, [sourceData]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleSubmit() {
    handleFormSubmit();
  }

  async function handleFormSubmit() {
    const { onUpdate } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="pro-liab-content">
      <div className="form-header">
        <div className="line-disable">
          <Switch
            checked={formVal.pro_liab_required}
            onChange={(checked) => handleChange("pro_liab_required", checked)}
          />
          <span className="disable-title">Required</span>
        </div>
      </div>
      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <h5 className="radio-title">PROFESSIONAL LIABILITY TYPE</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("pro_liab_type", evt.target.value)
              }
              value={formVal.pro_liab_type}
            >
              <Radio value={FORM_OCCURRENCE}>Occurrence</Radio>
              <Radio value={FORM_CLAIMS_MADE}>Claims Made</Radio>
            </Radio.Group>
          </div>
          <div className="line-item-container broker-report-container">
            <Textarea
              title="Notes from broker"
              type="text"
              placeholder="Please enter your text here."
              value={formVal.pro_liab_broker_note}
              rows={10}
              onChange={(evt) =>
                handleChange("pro_liab_broker_note", evt.target.value)
              }
            />
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              type="number"
              placeholder="EACH OCCURRENCE"
              value={formVal.pro_liab_occ}
              onChange={(evt) => handleChange("pro_liab_occ", evt.target.value)}
            />
          </div>
          <div className="line-item-container">
            <Input
              title="AGGREGATE"
              type="number"
              placeholder="AGGREGATE"
              value={formVal.pro_liab_agg}
              onChange={(evt) => handleChange("pro_liab_agg", evt.target.value)}
            />
          </div>
        </div>
        {!formVal.pro_liab_required && <FormMask />}
      </div>
      <div className="form-footer">
        {!readOnly && (
          <div className="button-container">
            <Button type="primary" onClick={handleSubmit}>
              {btnLabel || "Save"}
            </Button>
          </div>
        )}
      </div>
      <Loading loading={loading} />
      {readOnly && <ScreenMask />}
    </div>
  );
}
