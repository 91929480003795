import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Table, notification } from "antd";
import ImageUploadFolder from "../../../../assets/images/upload-folder.png";
import { Loading, Alert } from "../../../../components";
import Papa from "papaparse";
import { CheckOutlined, HolderOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { post, get, put } from "../../../../utils/axios";
import {
  Tree,
  getBackendOptions,
  MultiBackend
} from "@minoru/react-dnd-treeview";
import { DndProvider } from "react-dnd";
import { getHostTag } from "../../../../utils/host";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";

const VIEW_MODE_UPLOAD = "VIEW_MODE_UPLOAD";
const VIEW_MODE_PREVIEW = "VIEW_MODE_PREVIEW";
const LEVEL_BROKER = "LEVEL_BROKER";
const LEVEL_FRANCHISOR = "LEVEL_FRANCHISOR";
const LEVEL_FRANCHISEE = "LEVEL_FRANCHISEE";

export default function AdminAssignments(props) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    handleLoadData();
  }, []);

  async function handleLoadUser() {
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/admin-users`,
        { host: getHostTag() },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.status === 200) {
        return (result.data.data || []).filter((item) => item.is_consult);
      }
    } catch (error) {
      return [];
    }
  }

  async function handleLoadFranchisor() {
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/franchisor`,
        { host: getHostTag() },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.status === 200) {
        return result.data.requirements || [];
      }
    } catch (err) {
      return [];
    }
  }

  async function handleLoadFranchisee() {
    try {
      let params = { host: getHostTag() };
      const token = await getAccessTokenSilently();
      const result = await post(`admin/franchisee`, params, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (result.status === 200) {
        return result.data.data || [];
      }
    } catch (error) {
      return [];
    }
  }

  async function handleLoadData() {
    setLoading(true);
    const brokerList = await handleLoadUser();
    const franchisorList = await handleLoadFranchisor();
    const franchiseeList = await handleLoadFranchisee();

    handleMakeTree(brokerList, franchisorList, franchiseeList);
    setLoading(false);
  }

  function handleMakeTree(brokerData, franchisorData, franchiseeData) {
    const treeData = [];
    for (let index = 0; index < brokerData.length; index++) {
      treeData.push({
        id: brokerData[index]._id,
        parent: 0,
        droppable: true,
        text: `${brokerData[index].first_name} ${brokerData[index].last_name}`,
        level: LEVEL_BROKER
      });
    }
    treeData.push({
      id: "undefined-consult-id",
      parent: 0,
      droppable: true,
      text: `Undefined`,
      level: LEVEL_BROKER
    });

    for (let index = 0; index < franchisorData.length; index++) {
      treeData.push({
        id: franchisorData[index]._id,
        parent: franchisorData[index].consultantId || "undefined-consult-id",
        droppable: true,
        text: franchisorData[index].dba,
        level: LEVEL_FRANCHISOR
      });
    }

    for (let index = 0; index < franchiseeData.length; index++) {
      const entityName = franchiseeData[index].isInsureDiff
        ? franchiseeData[index].insuranceData?.entity_name || ""
        : franchiseeData[index].entity_name || "";

      const firstName = franchiseeData[index].isInsureDiff
        ? franchiseeData[index].insuranceData?.first_name || ""
        : franchiseeData[index].first_name || "";
      const lastName = franchiseeData[index].isInsureDiff
        ? franchiseeData[index].insuranceData?.last_name || ""
        : franchiseeData[index].last_name || "";

      treeData.push({
        id: franchiseeData[index]._id,
        parent:
          franchiseeData[index].requirementId || "undefined-franchisor-id",
        droppable: false,
        text: `${entityName}, ${firstName} ${lastName}`,
        level: LEVEL_FRANCHISEE
      });
    }
    setTreeData(treeData);
  }

  async function handleDrop(
    newTreeData,
    {
      dragSourceId,
      dropTargetId,
      dragSource,
      dropTarget,
      destinationIndex,
      relativeIndex,
      monitor
    }
  ) {
    if (
      dragSource.level === LEVEL_FRANCHISEE &&
      dropTarget.level === LEVEL_BROKER
    ) {
      notification.error({
        message: "Assign Error!",
        description: "Franchisee cannot be assigned directly to a broker"
      });
      return;
    }

    if (
      dragSource.level === LEVEL_FRANCHISOR &&
      dropTarget.level === LEVEL_FRANCHISOR
    ) {
      notification.error({
        message: "Assign Error!",
        description: "Franchisor cannot be assigned directly to a franchisor"
      });
      return;
    }

    if (dragSource.level === LEVEL_FRANCHISEE) {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        await put(
          `contact/${dragSource.id}`,
          {
            requirementId: dropTarget.id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setTreeData(newTreeData);
      } catch (error) {
        notification.error({
          message: "Assign Error!",
          description: "Unknown Error Occured!"
        });
      }
      setLoading(false);
      return;
    }

    if (dragSource.level === LEVEL_FRANCHISOR) {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        await put(
          `requirement/${dragSource.id}`,
          {
            consultantId:
              dropTarget.id != "undefined-consult-id" ? dropTarget.id : null
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setTreeData(newTreeData);
        await handleSyncAC(dragSource.id);
      } catch (error) {
        notification.error({
          message: "Assign Error!",
          description: "Unknown Error Occured!"
        });
      }
      setLoading(false);
      return;
    }
  }

  async function handleSyncAC(id) {
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/active-campaign/sync-franchisor/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {}
  }

  function handleCanDrag(node) {
    if (node.level === LEVEL_BROKER) {
      return false;
    }
    return true;
  }

  return (
    <div className="admin_assignments">
      {!loading && (
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <Tree
            tree={treeData}
            rootId={0}
            onDrop={handleDrop}
            canDrag={handleCanDrag}
            render={(node, { depth, isOpen, onToggle }) => (
              <div
                style={{
                  marginLeft: depth * 10,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 0px",
                  background: "#f3f3f3",
                  borderRadius: "10px",
                  margin: "5px",
                  paddingLeft: node.droppable ? "10px" : "0px"
                }}
              >
                {node.droppable && (
                  <div onClick={onToggle} style={{ marginRight: "10px" }}>
                    {isOpen ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
                  </div>
                )}
                {!node.droppable && (
                  <HolderOutlined style={{ padding: "10px" }} />
                )}
                {node.text}
              </div>
            )}
          />
        </DndProvider>
      )}
      <Loading loading={loading} />
    </div>
  );
}
