import React, { useEffect } from "react";
import { Button, Card, Table } from "antd";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { get, post } from "../../../utils/axios";

const formatAddress = ({
  street_address_1,
  street_address_2,
  city,
  state,
  zipcode,
}) =>
  [street_address_1, street_address_2, city, state, zipcode]
    .filter((x) => x)
    .join(", ");

const columns = [
  {
    title: "Affiliate",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Address",
    key: "address",
    render: (_, row) => formatAddress(row),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Franchise Intros",
    key: "intros",
    render: (_, row) => row.franchises.length,
  },
  {
    title: "Active Franchises",
    key: "activeFranchises",
    render: (_, row) => row.franchises.filter(({ active }) => active).length,
  },
  {
    title: "Franchisee Accounts",
    key: "franchisees",
    render: (_, row) =>
      row.franchises.map((f) => f.franchisees).reduce((x, y) => x + y, 0),
  },
  {
    title: "Affiliate Marketing Revenue",
    dataIndex: "revenue",
    key: "revenue",
    render: (value) =>
      Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value),
  },
  {
    title: "Status",
    dataIndex: "active",
    key: "active",
    render: (value) => (
      <div className={`status-container ${value ? "success" : "info"}`}>
        {value ? "Active" : "Inactive"}
      </div>
    ),
  },
];

export default function Overview() {
  const history = useHistory();
  const [, setLoading] = React.useState(false);
  const [affiliates, setAffiliates] = React.useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchAffiliates();
  }, []);

  async function fetchAffiliates() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await get(`affiliates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAffiliates(result.data.data);
    } catch (err) {
      console.error("error fetching affiliates");
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const onRow = (row) => ({
    onClick: () => {
      history.push(`/affiliates/${row._id}`, { affiliate: { ...row } });
    },
  });

  return (
    <div className="affiliates-container">
      <Card
        title="Affiliate Marketers"
        extra={
          <Link to="/affiliates/new">
            <Button type="primary">
              <PlusOutlined />
            </Button>
          </Link>
        }
      >
        <Table columns={columns} dataSource={affiliates} onRow={onRow} />
      </Card>
    </div>
  );
}
