import { useAuth0 } from "@auth0/auth0-react";
import { post, put } from "../../../../utils/axios";
import {
  COVERAGE_KEY_WORKERS_COMP,
  INSURANCE_CARRIER_TITLE_AMTRUST,
} from "../../../../assets/const/fim-integration";
import { getAbbrev } from "../../../../assets/const/location";
import moment from "moment";
import { prepareBaseQuoteParams } from "@utils/insurance-quote";

const defaultEmployeeLiability = {
  injury: 1000000,
  policy: 1000000,
  employee: 1000000,
};

export const useAmTrust = ({ franchiseeData, franchisorData }) => {
  const { user, getAccessTokenSilently } = useAuth0();

  async function requestQuote(formVals, selectedCoverages) {
    if (selectedCoverages[COVERAGE_KEY_WORKERS_COMP]) {
      return requestWCQuote(formVals);
    }
    return {};
  }

  async function requestWCQuote(formVals) {
    const token = await getAccessTokenSilently();
    const yearInBusiness = !franchiseeData.detail?.openingDate
      ? 0
      : new Date(Date.now()).getFullYear() -
        new Date(franchiseeData.detail.openingDate).getFullYear();

    const body = {
      Quote: {
        EffectiveDate: formVals.effectiveDate[COVERAGE_KEY_WORKERS_COMP].value,
        Fein: (
          franchiseeData?.tax_id ||
          franchiseeData?.detail?.fimEntity?.fimTtTaxpayer ||
          formVals.profileVal?.tax_id
        ).replace(/[^0-9]/g, ""),
        PrimaryAddress: {
          Line1: franchiseeData.address,
          Line2: "",
          City: franchiseeData.city,
          State: getAbbrev(franchiseeData.state),
          Zip: franchiseeData.zip,
        },
        MailingAddress: {
          Line1: franchiseeData.address,
          Line2: "",
          City: franchiseeData.city,
          State: getAbbrev(franchiseeData.state),
          Zip: franchiseeData.zip,
        },
        BusinessName: franchiseeData.entity_name,
        DBA: franchiseeData.dba || "",
        ContactInformation: {
          FirstName: franchiseeData.first_name,
          LastName: franchiseeData.last_name,
          Email: franchiseeData.email,
          Phone: franchiseeData.telephone,
        },
        YearsInBusiness: yearInBusiness,
        IsNonProfit: false,
        // For now, we only quote for one location.
        ClassCodes: formVals.locationVal[0].codeList.map((c) => ({
          ClassCode: c.code,
          Payroll: c.exposure,
          State: formVals.locationVal[0].state,
          FullTimeEmployees: c.employeeCount,
        })),
        // RatingZip: "", // Todo - clarify what this means.
        LiabilityLimits: `${
          (formVals?.employeeLiability?.injury ||
            defaultEmployeeLiability.injury) / 1000
        }/${
          (formVals?.employeeLiability?.policy ||
            defaultEmployeeLiability.policy) / 1000
        }/${
          (formVals?.employeeLiability?.employee ||
            defaultEmployeeLiability.employee) / 1000
        }`,
      },
      LoadQuestions: false,
      ExperienceModifier: {
        State: getAbbrev(formVals.locationVal[0].state),
        Modifiers: {
          Empct: formVals.underwritingInfo.modifier,
        },
      },
    };

    const result = await post("amtrust/wc-quote", body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 120000,
    });
    return result.data.data?.Data;
  }

  async function saveQuote(info, quoteResult) {
    const token = await getAccessTokenSilently();
    let quoteList = info.franchiseeData?.quoteList || [];
    let request = prepareBaseQuoteParams({
      token,
      ...info,
    });

    for (const [coverage, result] of Object.entries(quoteResult)) {
      quoteList.push({
        save_date: new Date().toISOString(),
        prepared_by: user.email,
        status: result["Eligibility"]["Eligibility"],
        quote_id: result["AccountInformation"]["QuoteId"],
        coverage,
        response_detail: result,
        request_detail: {
          request,
          effectiveDate: request.effectiveDt[coverage],
        },
        integration: INSURANCE_CARRIER_TITLE_AMTRUST,
      });
    }

    return await put(
      `contact/${info.franchiseeData._id}`,
      {
        quoteList,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  function constructWCQuoteProposal(quoteResult, quoteSource, formData) {
    const premium =
      quoteResult["PremiumDetails"]["PriceIndication"] +
      quoteResult["PremiumDetails"]["StateTaxes"];

    const codeToDescriptionMap = {};
    for (let codeData of formData.franchisorData?.naics_classes || []) {
      codeToDescriptionMap[codeData.code] = codeData.description || "";
    }

    return {
      source: quoteSource,
      status: quoteResult["Eligibility"]["Eligibility"],
      effectiveDate: moment(quoteResult["EffectiveDate"]).format("MM/DD/YYYY"),
      expirationDate: moment(quoteResult["EffectiveDate"])
        .add(1, "year")
        .format("MM/DD/YYYY"),
      policyNumber: quoteResult["AccountInformation"]["QuoteId"],
      premium,
      bodilyInjuryByAccidentPerPerson:
        quoteResult["PremiumDetails"]["LimitPerAccident"],
      bodilyInjuryByDiseasePolicyLimit:
        quoteResult["PremiumDetails"]["BodilyInjuryFromDiseasePolicyLimit"],
      bodilyInjuryByDiseasePerPerson:
        quoteResult["PremiumDetails"][
          "BodilyInjuryFromDiseasePerEmployeeLimit"
        ],
      ownerOfficers: formData.officeVal.map((officer) => ({
        name: officer.full_name,
        ownership: officer.owner_ship,
        payroll: officer.payroll,
        status: officer.name_type ? "Included" : "Excluded",
      })),
      policyLevelData: {
        experienceModification: {
          enabled: Boolean(formData.underwritingInfo.modifier),
          value: formData.underwritingInfo.modifier,
        },
        blanketWaiverOfSubrogation: {
          enabled: formData.underwritingInfo.subrogation,
        },
      },
      locations: formData.locationVal.map((location) => ({
        address: [location.address, location.city, location.state, location.zip]
          .filter(Boolean)
          .join(", "),
        classCodes: location.codeList.map((classCode) => ({
          classCode: classCode.code,
          classCodeDescription: codeToDescriptionMap[classCode.code],
          exposureBasis: "Payroll",
          estimatedExposure: classCode.exposure,
          // rate: 0.21,
        })),
      })),
    };
  }

  return {
    requestQuote,
    saveQuote,
    constructWCQuoteProposal,
  };
};
