import React from "react";
import Input from "../input";
import { usePlacesWidget } from "react-google-autocomplete";
import { getAddressItem } from "../../utils/address";

export default function AddressInput(props) {
  const { value, title, placeholder, onChange, onSelect } = props;
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place) => {
      let zipCode = "",
        state = "",
        city = "";
      if (place.address_components) {
        zipCode = getAddressItem(
          place.address_components,
          "postal_code",
          "short_name"
        );
        state = getAddressItem(
          place.address_components,
          "administrative_area_level_1",
          "short_name"
        );
        city = getAddressItem(
          place.address_components,
          "locality",
          "long_name"
        );
        const street = getAddressItem(
          place.address_components,
          "street_number",
          "long_name"
        );
        const route = getAddressItem(
          place.address_components,
          "route",
          "long_name"
        );
        onSelect &&
          onSelect({
            city: city,
            state: state,
            zip: zipCode,
            address: `${street} ${route}`,
          });
      }
    },
  });
  return (
    <Input
      title={title}
      placeholder={placeholder}
      value={value}
      ref={ref}
      required
      inputType="TYPE_INPUT_ADDRESS"
      onChange={(evt) => onChange(evt.target.value)}
    />
  );
}
