import React from "react";
import { Input } from 'antd'
import "./style.scss";

const { TextArea } = Input;

export default function Textarea(props) {
  return (
    <div className="custom-input-container">
      <p className="input-title">{props.title}</p>
      <TextArea
        rows={props.rows}
        className="custom-input ant-input"
        {...props}
      />
    </div>
  );
}
