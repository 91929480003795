import React from "react";
import { Button, Checkbox, Row, Col, Breadcrumb, Card } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Input } from "../../../components";
import { post, put } from "../../../utils/axios";
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";

export default function FranchiseForm() {
  const {
    state: { affiliate, franchise },
  } = useLocation();
  const editing = !!franchise;
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState(
    _.assign(
      {
        name: "",
        phone: "",
        email: "",
        franchisees: 0,
        totalPremium: 0,
        active: true,
      },
      franchise || {}
    )
  );

  const handleChange = (field) => (e) =>
    setValues((prev) => ({ ...prev, [field]: e.target.value }));

  const handleCheckbox = (field) => (e) =>
    setValues((prev) => ({ ...prev, [field]: e.target.checked }));

  async function _create(token) {
    return await post(
      `affiliates/${affiliate._id}/franchises`,
      {
        ...values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  async function _update(token) {
    return await put(
      `affiliates/${affiliate._id}/franchises/${franchise._id}`,
      {
        ...values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  async function save() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const _save = editing ? _update : _create;
      await _save(token);
      history.push(`/affiliates/${affiliate._id}`);
    } catch (err) {
      console.error("error saving franchise");
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="affiliates-container">
      <Breadcrumb className="breadcrumbs">
        <Breadcrumb.Item>
          <Link to="/affiliates/overview">Affiliates</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/affiliates/${affiliate._id}`}>
            {affiliate?.name || "Detail"}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{editing ? "Edit" : "New"} Franchise</Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title={
          editing
            ? franchise?.name
              ? `Edit ${franchise?.name}`
              : "Edit Franchise"
            : "New Franchise"
        }
        extra={
          <Button type="primary" onClick={save} disabled={loading}>
            Save
          </Button>
        }
      >
        <Row justify="space-evenly">
          <Col span={8}>
            <Input
              title="Name"
              type="text"
              value={values.name}
              onChange={handleChange("name")}
              disabled={loading}
            />
            <Input
              title="Phone"
              type="text"
              value={values.phone}
              onChange={handleChange("phone")}
              disabled={loading}
            />
            <Input
              title="Email"
              type="text"
              value={values.email}
              onChange={handleChange("email")}
              disabled={loading}
            />
          </Col>
          <Col span={8}>
            <Input
              title="Franchisees"
              type="number"
              value={values.franchisees}
              step="1"
              onChange={handleChange("franchisees")}
              disabled={loading}
            />
            <Input
              title="Total Premium"
              type="number"
              value={values.totalPremium}
              step="1"
              onChange={handleChange("totalPremium")}
              disabled={loading}
            />
            <Checkbox
              checked={values.active}
              onChange={handleCheckbox("active")}
            >
              Active
            </Checkbox>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
