import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import {
  COVERAGE_KEY_LIABILITY,
  COVERAGE_KEY_PROPERTY,
  COVERAGE_KEY_WORKERS_COMP,
  HARTFORD_QUOTE_BOPTYPECODE_LIABONLY,
  HARTFORD_QUOTE_BOPTYPECODE_PROPLIAB,
  HARTFORD_QUOTE_BOPTYPECODE_PROPONLY,
  INSURANCE_CARRIER_TITLE_HARTFORD,
} from "@assets/const/fim-integration";
import { post, put } from "@utils/axios";
import { convertStatus, prepareBaseQuoteParams } from "@utils/insurance-quote";
import { useFormValidate } from "./useFormValidate";
import { addMonths, format } from "date-fns";
import { STATUS_SECTION_APPROVE } from "@assets/const/status";
import { DOC_MAX_LIMIT } from "@assets/const/status";
import moment from "moment";
import { getValidCode } from "@utils/common";

export const useHartford = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { validate: baseValidate } = useFormValidate();

  async function quoteWorkersComp(req, franchisee) {
    let baseQuoteParams = prepareBaseQuoteParams({
      ...req,
      effectiveDate: req.effectiveDate[COVERAGE_KEY_WORKERS_COMP].value,
    });

    baseQuoteParams = {
      ...baseQuoteParams,
      locations: baseQuoteParams.locations.map((item, idx) => {
        const original = req.locationVal[idx];
        return {
          ...item,
          workCompRateClassInfo: (original.codeList || []).map((codeItem) => ({
            numEmployees: codeItem.employeeCount,
            ratingClassificationCd: getValidCode(codeItem.code),
            // ratingClassificationDescCd: "02", // optional
            exposure: codeItem.exposure,
          })),
          owners: (req.officeVal || []).map((ownerItem) => ({
            ownerName: ownerItem.full_name || "",
            ownerPayroll: ownerItem.payroll || "",
            ratingClassificationCd: getValidCode(ownerItem.class_code),
            // ratingClassificationDescCd: "",
            ownerIncluded: !ownerItem.name_type,
            ownershipPercentage: ownerItem.owner_ship || "",
          })),
        };
      }),
      spectrumClassCode:
        req?.locationVal[0]?.liabInfo?.riskCodes?.spectrumClassCode ||
        franchisee.locationList[0]?.liabInfo?.riskCodes?.spectrumClassCode ||
        "",
    };

    const result = await post(`hartford/wc-quote`, baseQuoteParams, {
      headers: {
        Authorization: `Bearer ${req.token}`,
      },
    });

    return result?.data?.data;
  }

  // The Bop API endpoint is a single endpoint used for both property and liability calls.
  // If both Property and Liability coverages are selected, we use the bopTypeCode PropLiab,
  // and store the quote result as both Property and Liability quotes.
  async function quotePropLiab(req, franchisee, bopTypeCode) {
    var effectiveDate = "";
    if (bopTypeCode === HARTFORD_QUOTE_BOPTYPECODE_PROPLIAB) {
      effectiveDate =
        req.effectiveDate[COVERAGE_KEY_PROPERTY]?.value ||
        req.effectiveDate[COVERAGE_KEY_LIABILITY]?.value;
    } else if (bopTypeCode === HARTFORD_QUOTE_BOPTYPECODE_PROPONLY) {
      effectiveDate = req.effectiveDate[COVERAGE_KEY_PROPERTY]?.value;
    } else {
      effectiveDate = req.effectiveDate[COVERAGE_KEY_LIABILITY]?.value;
    }

    let param = prepareBaseQuoteParams({
      ...req,
      effectiveDate,
    });
    // Currently we can only quote for 1 building so we take the first one.
    const propInfo = req?.locationVal[0]?.propInfo;

    // Add data required for BOP quotes
    // TODO: add fields for populating data below instead of hardcoding them
    param = {
      ...param,
      BOPTypeCode: bopTypeCode,
      // TODO: there are a _lot_ of optional fields that we don't include with
      // this quote. It would be a good idea to review what data we're sending
      // to make sure we get the most accurate quote for clients.
      numEmployees: 3,
      numberOfOwners: 1,
      totalArea: propInfo?.totalArea || "",
      yearBuilt: propInfo?.yearBuilt || 2020,
      constructionTypeCode: propInfo?.constructionTypeCode || "F",
      numberOfStories: propInfo?.numberOfStories || 1,
      // includeBuildingCoverage: !_.isUndefined(propInfo?.includeBuildingCoverage)
      //   ? propInfo?.includeBuildingCoverage
      //   : false,
      includeBuildingCoverage: false,
      bppLimit: propInfo?.bppLimit || "",
      spectrumClassCode:
        req?.locationVal[0]?.liabInfo?.riskCodes?.spectrumClassCode ||
        franchisee.locationList[0]?.liabInfo?.riskCodes?.spectrumClassCode ||
        "",
    };

    const result = await post(`hartford/bop-quote`, param, {
      headers: {
        Authorization: `Bearer ${req.token}`,
      },
    });

    return result?.data?.data;
  }

  async function requestQuote(info, franchisee, selectedCoverages) {
    const token = await getAccessTokenSilently();
    const request = {
      token,
      ...info,
    };

    // TODO: make parallel queries for all quotes.
    var quoteResultData = {};

    // Property and Liability quotation.
    if (
      selectedCoverages[COVERAGE_KEY_LIABILITY] &&
      selectedCoverages[COVERAGE_KEY_PROPERTY]
    ) {
      const bopCallResult = await quotePropLiab(
        request,
        franchisee,
        HARTFORD_QUOTE_BOPTYPECODE_PROPLIAB
      );
      quoteResultData[COVERAGE_KEY_PROPERTY] = bopCallResult;
      quoteResultData[COVERAGE_KEY_LIABILITY] = bopCallResult;
    } else if (selectedCoverages[COVERAGE_KEY_LIABILITY]) {
      const bopCallResult = await quotePropLiab(
        request,
        franchisee,
        HARTFORD_QUOTE_BOPTYPECODE_LIABONLY
      );
      quoteResultData[COVERAGE_KEY_LIABILITY] = bopCallResult;
    } else if (selectedCoverages[COVERAGE_KEY_PROPERTY]) {
      const bopCallResult = await quotePropLiab(
        request,
        franchisee,
        HARTFORD_QUOTE_BOPTYPECODE_PROPONLY
      );
      quoteResultData[COVERAGE_KEY_PROPERTY] = bopCallResult;
    }

    // Workers Compensation quotation.
    if (selectedCoverages[COVERAGE_KEY_WORKERS_COMP]) {
      quoteResultData[COVERAGE_KEY_WORKERS_COMP] = await quoteWorkersComp(
        request,
        franchisee
      );
    }

    return quoteResultData;
  }

  async function saveQuote(info, quoteResult) {
    const token = await getAccessTokenSilently();
    let quoteList = info.franchiseeData?.quoteList || [];
    let request = prepareBaseQuoteParams({
      token,
      ...info,
    });

    var bopTypeCode = "";
    if (quoteResult.li && quoteResult.prop) {
      bopTypeCode = HARTFORD_QUOTE_BOPTYPECODE_PROPLIAB;
    } else if (quoteResult.li) {
      bopTypeCode = HARTFORD_QUOTE_BOPTYPECODE_LIABONLY;
    } else if (quoteResult.prop) {
      bopTypeCode = HARTFORD_QUOTE_BOPTYPECODE_PROPONLY;
    }

    // Add data required for BOP quotes
    // TODO: add fields for populating data below instead of hardcoding them
    request = {
      ...request,
      BOPTypeCode: bopTypeCode,
      // TODO: there are a _lot_ of optional fields that we don't include with
      // this quote. It would be a good idea to review what data we're sending
      // to make sure we get the most accurate quote for clients.
      constructionTypeCode: "F",
      includeBuildingCoverage: true,
      numEmployees: 3,
      numberOfOwners: 1,
      numberOfStories: 1,
      yearBuilt: 2020,
    };

    Object.entries(quoteResult).forEach((resultItem) => {
      quoteList.push({
        save_date: new Date().toISOString(),
        prepared_by: user.email,
        step: "",
        status: convertStatus(resultItem[1].streamlinedQuoteStatus),
        business_id: "", // GA Only,
        quote_id: resultItem[1].quoteIdentifier, // Hartford Only
        coverage: resultItem[0],
        response_detail: resultItem[1],
        request_detail: {
          request,
          effectiveDate: request.effectiveDt[resultItem[0]],
        },
        integration: INSURANCE_CARRIER_TITLE_HARTFORD,
      });
    });

    return await put(
      `contact/${info.franchiseeData._id}`,
      {
        quoteList: quoteList,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  async function acceptQuote(id, franchiseeData, quoteResult) {
    const token = await getAccessTokenSilently();
    const finalData = franchiseeData?.finalData || {};
    const result = await put(
      `contact/${id}`,
      {
        finalData: {
          ...finalData,
          commercial_general_liability_form_status: STATUS_SECTION_APPROVE,
          commercial_general_liability_form_doc_index: DOC_MAX_LIMIT,
          commercial_general_liability_each_occurance: (
            quoteResult[COVERAGE_KEY_LIABILITY]?.coverage || []
          )
            .find((item) => item.description === "Business Liability Limit")
            ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
            .limitAmount,
          commercial_general_liability_damage_rented_premises: (
            quoteResult[COVERAGE_KEY_LIABILITY]?.coverage || []
          )
            .find(
              (item) => item.description === "Damage to Premises Rented to You"
            )
            ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
            .limitAmount,
          commercial_general_liability_medical_expenses: (
            quoteResult[COVERAGE_KEY_LIABILITY]?.coverage || []
          )
            .find((item) => item.description === "Medical Expenses")
            ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
            .limitAmount,
          commercial_general_liability_personal_adv_injury: (
            quoteResult[COVERAGE_KEY_LIABILITY]?.coverage || []
          )
            .find(
              (item) =>
                item.description ===
                "Personal and Advertising Injury Coverage Limit"
            )
            ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
            .limitAmount,
          commercial_general_liability_general_aggregate: (
            quoteResult[COVERAGE_KEY_LIABILITY]?.coverage || []
          )
            .find((item) => item.description === "General Aggregate Limit")
            ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
            .limitAmount,
          commercial_general_liability_comprop_acg: (
            quoteResult[COVERAGE_KEY_LIABILITY]?.coverage || []
          )
            .find(
              (item) =>
                item.description ===
                "Products/Completed Operations Aggregate Limit"
            )
            ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
            .limitAmount,
          commercial_general_liability_policy_number:
            quoteResult[COVERAGE_KEY_LIABILITY]?.policyNumber,
          commercial_general_liability_additional_insurance:
            (quoteResult[COVERAGE_KEY_LIABILITY]?.coverage || [])
              .find(
                (item) => item.description === "Additional Insured by Contract"
              )
              ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
              .limitAppliestoCode === "Included",
          commercial_general_liability_effective_date: format(
            new Date(),
            "yyyy-MM-dd"
          ),
          commercial_general_liability_expire_date: format(
            addMonths(new Date(), 12),
            "yyyy-MM-dd"
          ),
          workers_compensation_form_status: STATUS_SECTION_APPROVE,
          workers_compensation_form_doc_index: DOC_MAX_LIMIT,
          workers_compensation_policy_number:
            quoteResult[COVERAGE_KEY_WORKERS_COMP]?.policyNumber,
          workers_compensation_el_each_accident:
            (quoteResult[COVERAGE_KEY_WORKERS_COMP]?.coverage || []).length > 0
              ? (
                  (quoteResult[COVERAGE_KEY_WORKERS_COMP]?.coverage || [])[0]
                    .limit || []
                ).find((item) => item.limitAppliestoCode === "PerAcc")
                  ?.limitAmount
              : "",
          workers_compensation_el_disease_policy_limit:
            (quoteResult[COVERAGE_KEY_WORKERS_COMP]?.coverage || []).length > 0
              ? (
                  (quoteResult[COVERAGE_KEY_WORKERS_COMP]?.coverage || [])[0]
                    .limit || []
                ).find((item) => item.limitAppliestoCode === "DisPol")
                  ?.limitAmount
              : "",
          workers_compensation_other_el_disease_each_employer:
            (quoteResult[COVERAGE_KEY_WORKERS_COMP]?.coverage || []).length > 0
              ? (
                  (quoteResult[COVERAGE_KEY_WORKERS_COMP]?.coverage || [])[0]
                    .limit || []
                ).find((item) => item.limitAppliestoCode === "DisEachEmpl")
                  ?.limitAmount
              : "",
          workers_compensation_effective_date: format(new Date(), "yyyy-MM-dd"),
          workers_compensation_expire_date: format(
            addMonths(new Date(), 12),
            "yyyy-MM-dd"
          ),
          // all five must be approved in order to proceed to a compliant
          // status, but server side we unset the following three since we're
          // not handling any quotes for them right now.
          automotive_liability_form_status: STATUS_SECTION_APPROVE,
          automotive_liability_form_doc_index: DOC_MAX_LIMIT,
          umbrella_subrogration_form_status: STATUS_SECTION_APPROVE,
          umbrella_subrogration_form_doc_index: DOC_MAX_LIMIT,
          additional_infor_form_status: STATUS_SECTION_APPROVE,
          additional_infor_doc_index: DOC_MAX_LIMIT,

          // Provide coverage details
        },
        liabilityIssue: [],
        autoIssue: [],
        umbrellaIssue: [],
        workCompIssue: [],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  function validate(formData, selectedCoverages) {
    // Because Hartford uses a single endpoint for Property and Liability quotes, the effective date must be the same if both
    // Property and Liability are selected.
    if (
      selectedCoverages[COVERAGE_KEY_PROPERTY] &&
      selectedCoverages[COVERAGE_KEY_LIABILITY]
    ) {
      const error =
        formData.effectiveDate[COVERAGE_KEY_PROPERTY]?.value !=
        formData.effectiveDate[COVERAGE_KEY_LIABILITY]?.value
          ? "Property and liability effective date must be the same for Hartford quotes."
          : "";

      let newEffectiveDate = _.cloneDeep(formData.effectiveDate);
      newEffectiveDate = {
        ...newEffectiveDate,
        [COVERAGE_KEY_PROPERTY]: newEffectiveDate[COVERAGE_KEY_PROPERTY]
          ? {
              ...newEffectiveDate[COVERAGE_KEY_PROPERTY],
              error,
            }
          : { error },
      };
      return baseValidate(
        {
          ...formData,
          effectiveDate: newEffectiveDate,
        },
        selectedCoverages
      );
    }
    return baseValidate(formData, selectedCoverages);
  }

  function constructWCQuoteProposal(quoteResult, quoteSource, formData) {
    const codeToDescriptionMap = {};
    for (let codeData of formData.franchisorData?.naics_classes || []) {
      codeToDescriptionMap[codeData.code] = codeData.description || "";
    }

    let bodilyInjuryByAccidentPerPerson = "";
    let bodilyInjuryByDiseasePerPerson = "";
    let bodilyInjuryByDiseasePolicyLimit = "";
    for (const coverage of quoteResult?.coverage || []) {
      if (coverage.coverageCode === "INEL") {
        for (const limit of coverage.limit) {
          switch (limit.limitAppliestoCode) {
            case "PerAcc":
              bodilyInjuryByAccidentPerPerson = limit.limitAmount;
            case "DisPol":
              bodilyInjuryByDiseasePolicyLimit = limit.limitAmount;
            case "DisEachEmpl":
              bodilyInjuryByDiseasePerPerson = limit.limitAmount;
          }
        }
      }
    }

    return {
      source: quoteSource,
      status: quoteResult.streamlinedQuoteStatus,
      effectiveDate: moment(quoteResult.effectiveDt).format("MM/DD/YYYY"),
      expirationDate: quoteResult.expirationDt
        ? moment(quoteResult.expirationDt).format("MM/DD/YYYY")
        : moment(quoteResult.effectiveDt).add(1, "year").format("MM/DD/YYYY"),
      policyNumber: quoteResult.policyNumber,
      premium: quoteResult.totalPremium,
      bodilyInjuryByAccidentPerPerson,
      bodilyInjuryByDiseasePolicyLimit,
      bodilyInjuryByDiseasePerPerson,
      ownerOfficers: formData.officeVal.map((officer) => ({
        name: officer.full_name,
        ownership: officer.owner_ship,
        payroll: officer.payroll,
        status: officer.name_type ? "Excluded" : "Included",
      })),
      policyLevelData: {
        experienceModification: {
          enabled: Boolean(formData.underwritingInfo.modifier),
          value: formData.underwritingInfo.modifier,
        },
        blanketWaiverOfSubrogation: {
          enabled: formData.underwritingInfo.subrogation,
        },
      },
      locations: formData.locationVal.map((location) => ({
        address: [location.address, location.city, location.state, location.zip]
          .filter(Boolean)
          .join(", "),
        classCodes: location?.codeList?.map((classCode) => ({
          classCode: classCode.code,
          classCodeDescription: codeToDescriptionMap[classCode.code],
          exposureBasis: "Payroll",
          // rate: 0.21,
          estimatedExposure: classCode.exposure,
        })),
      })),
    };
  }

  return {
    acceptQuote,
    requestQuote,
    saveQuote,
    validate,
    constructWCQuoteProposal,
  };
};
