import { useState, useEffect } from "react";
import { Loading, QuoteAction, QuoteHeader } from "../../../components";
import HartfordPreview from "./components/hartford-preview";
import "./style.scss";
import { get } from "../../../utils/axios";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  COVERAGE_KEY_WORKERS_COMP,
  INSURANCE_CARRIER_KEY_AMTRUST,
  INSURANCE_CARRIER_KEY_HARTFORD,
  INSURANCE_CARRIER_TITLE_AMTRUST,
  INSURANCE_CARRIER_TITLE_GA,
  INSURANCE_CARRIER_TITLE_HARTFORD,
  QUOTE_PROPOSAL_SOURCE_API
} from "../../../assets/const/fim-integration";
import AdminInsuranceQuoteProposal from "../admin-insurance-quote-proposal";
import { useAmTrust } from "../admin-insurance-quote/hooks/useAmTrust";
import { useHartford } from "../admin-insurance-quote/hooks/useHartford";

export default function AdminInsuranceQuotePreview() {
  let { id, quoteId } = useParams();
  const history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [quoteResult, setQuoteResult] = useState();
  const [carrier, setCarrier] = useState();
  const [loading, setLoading] = useState(false);

  const { constructWCQuoteProposal: constructAmTrustWCQuoteProposal } =
    useAmTrust(franchiseeData, franchisorData);

  const { constructWCQuoteProposal: constructHFWCQuoteProposal } =
    useHartford();

  useEffect(() => {
    handleLoadData();
  }, [quoteId]);

  async function handleLoadData() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const franchiseeDataRaw = result.data.data;
      setFranchiseeData(franchiseeDataRaw);
      const resultRequirement = await get(
        `requirement/${franchiseeDataRaw.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchisorData(resultRequirement.data.data);
      const quoteList = franchiseeDataRaw.quoteList;

      const quoteItems = quoteList.filter(
        (item) => item.business_id == quoteId || item.quote_id == quoteId
      );

      if (quoteItems.length > 0) {
        const quoteResult = {};
        quoteItems.forEach(
          (item) => (quoteResult[item.coverage] = item.response_detail)
        );
        setQuoteResult(quoteResult);
        setCarrier(quoteItems[0].integration);
      }
      setLoading(false);
    } catch (error) {
      console.log("error --", error);
      setLoading(false);
    }
  }

  function handleClose() {
    history.push(`/admin/franchisees/${id}`);
  }

  function renderQuoteResultPreview() {
    const quoteProposalInput = {
      franchisorData: franchisorData,
      officeVal: franchiseeData.officeList || [],
      liabInfo: franchiseeData.locationList?.[0]?.liabInfo || {},
      underwritingInfo: franchiseeData.underwritingInformation || {},
      locationVal: franchiseeData.locationList || []
    };
    switch (carrier) {
      // "Hartford" is checked for backward compatibility; we used to call the integration "Hartford", but we recently transitioned it to
      // "The Hartford", because that's what the carrier's official name/title is.
      case "Hartford":
      case INSURANCE_CARRIER_TITLE_HARTFORD:
        if (quoteResult[COVERAGE_KEY_WORKERS_COMP]) {
          const hfWCQuoteData = constructHFWCQuoteProposal(
            quoteResult[COVERAGE_KEY_WORKERS_COMP],
            QUOTE_PROPOSAL_SOURCE_API,
            quoteProposalInput
          );
          return (
            <AdminInsuranceQuoteProposal
              data={{
                [INSURANCE_CARRIER_KEY_HARTFORD]: {
                  [COVERAGE_KEY_WORKERS_COMP]: hfWCQuoteData
                }
              }}
              initialSelectedCarrier={INSURANCE_CARRIER_KEY_HARTFORD}
              initialSelectedCoverages={{ [COVERAGE_KEY_WORKERS_COMP]: true }}
              initialSelectedStatus={hfWCQuoteData.status}
              initialSelectedSource={hfWCQuoteData.source}
              readOnly={true}
            />
          );
        }
        return <HartfordPreview {...quoteResult} />;
      case INSURANCE_CARRIER_TITLE_AMTRUST:
        const amTrustWCQuoteData = constructAmTrustWCQuoteProposal(
          quoteResult[COVERAGE_KEY_WORKERS_COMP],
          QUOTE_PROPOSAL_SOURCE_API,
          quoteProposalInput
        );

        return (
          <AdminInsuranceQuoteProposal
            data={{
              [INSURANCE_CARRIER_KEY_AMTRUST]: {
                [COVERAGE_KEY_WORKERS_COMP]: amTrustWCQuoteData
              }
            }}
            initialSelectedCarrier={INSURANCE_CARRIER_KEY_AMTRUST}
            initialSelectedCoverages={{ [COVERAGE_KEY_WORKERS_COMP]: true }}
            initialSelectedStatus={amTrustWCQuoteData.status}
            initialSelectedSource={amTrustWCQuoteData.source}
            readOnly={true}
          />
        );
      case INSURANCE_CARRIER_TITLE_GA:
        const appId = quoteResult[COVERAGE_KEY_WORKERS_COMP]?.newBusiness?.id;
        if (appId) {
          history.replace(`/admin/pricing/${id}/${appId}`);
        }
      default:
        return <></>;
    }
  }

  return (
    <div className="admin_hartford">
      <QuoteHeader
        franchiseeData={franchiseeData}
        requirement={franchisorData}
        user={user}
      ></QuoteHeader>
      <div className="admin_hartford-container">
        {renderQuoteResultPreview()}
        <QuoteAction
          handleClose={handleClose}
          visibleSave={false}
          visibleBack={false}
          visibleNext={false}
        ></QuoteAction>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
