import React from "react";
import "./style.scss";
import FranchiseeReport from "../../admin-board/franchisee-report";
import { useParams } from "react-router-dom";
import { VIEW_SOURCE_UNAUTHORIZED } from "@assets/const/ui";

export default function FranchiseeDetailsReport() {
  const param = useParams();

  return (
    <div className="zee_report">
      <div className="zee_report-con">
        <FranchiseeReport
          viewSource={VIEW_SOURCE_UNAUTHORIZED}
          zeeId={param.id}
        />
      </div>
    </div>
  );
}
