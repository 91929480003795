import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  Input,
  Loading,
  FormMask,
  Textarea,
  ScreenMask,
} from "../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../utils/axios";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
  SOURCE_ONBOARDING,
} from "../../../assets/const/form";
import { useAppState } from "@context";

export default function GarageForm(props) {
  const { data, btnLabel, source, onNext, readOnly = false } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;

  const [formVal, setFormVal] = useState({
    garage_direct: sourceData?.garage_direct || false,
    garage_comp: sourceData?.garage_comp || "",
    garage_coll: sourceData?.garage_coll || "",
    garage_required: sourceData?.garage_required || false,
    garage_broker_note: sourceData?.garage_broker_note || "",
  });

  useEffect(() => {
    setFormVal({
      garage_direct: sourceData?.garage_direct || false,
      garage_comp: sourceData?.garage_comp || "",
      garage_coll: sourceData?.garage_coll || "",
      garage_required: sourceData?.garage_required || false,
      garage_broker_note: sourceData?.garage_broker_note || "",
    });
  }, [sourceData]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleSubmit() {
    handleFormSubmit();
  }

  async function handleFormSubmit() {
    const { onUpdate } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="garage-content">
      <div className="form-header">
        <div className="line-disable">
          <Switch
            checked={formVal.garage_required}
            onChange={(checked) => handleChange("garage_required", checked)}
          />
          <span className="disable-title">Required</span>
        </div>
      </div>
      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <h5 className="radio-title">DIRECT PRIMARY</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("garage_direct", evt.target.value)
              }
              value={formVal.garage_direct}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </div>
          <div className="line-item-container broker-report-container">
            <Textarea
              title="Notes from broker"
              type="text"
              placeholder="Please enter your text here."
              value={formVal.garage_broker_note}
              rows={10}
              onChange={(evt) =>
                handleChange("garage_broker_note", evt.target.value)
              }
            />
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="COMPREHENSIVE"
              type="number"
              placeholder="COMPREHENSIVE"
              value={formVal.garage_comp}
              onChange={(evt) => handleChange("garage_comp", evt.target.value)}
            />
          </div>
          <div className="line-item-container">
            <Input
              title="COLLISSION"
              type="number"
              placeholder="COLLISSION"
              value={formVal.garage_coll}
              onChange={(evt) => handleChange("garage_coll", evt.target.value)}
            />
          </div>
        </div>
        {!formVal.garage_required && <FormMask />}
      </div>
      <div className="form-footer">
        {!readOnly && (
          <div className="button-container">
            <Button type="primary" onClick={handleSubmit}>
              {btnLabel || "Save"}
            </Button>
          </div>
        )}
      </div>
      <Loading loading={loading} />

      {readOnly && <ScreenMask />}
    </div>
  );
}
