import React, { useState, useEffect } from "react";
import {
  buildAdditionalOccurenceValue,
  buildAdditionalAggregateValue,
  buildAdditionalAutoCombinedValue,
  buildAdditionalCompAccidentValue,
  buildAdditionalCompEmployeeValue,
  buildAdditionalCompLimitValue,
  buildAdditionalPersonalValue,
  buildAdditionalProductValue,
  reportAdd
} from "../../../../utils/build-logic";
import {
  mapFieldLiability,
  mapFieldUmbrella,
  mapFieldAuto,
  mapFieldWC
} from "../../../../utils/form-recog";
import "./style.scss";
import {
  STATUS_SECTION_APPROVE,
  STATUS_SECTION_PENDING,
  DOC_MAX_LIMIT
} from "@assets/const/status";

export default function AdditionalReport(props) {
  const { franchisorData, franchiseeData, docIndex } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [franchiseeData, docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    const formValUpdate = {};
    if (
      franchiseeData?.finalData?.commercial_general_liability_form_status ===
        STATUS_SECTION_APPROVE ||
      franchiseeData?.finalData?.commercial_general_liability_form_status ===
        STATUS_SECTION_PENDING
    ) {
      const finalData = franchiseeData.finalData;
      formValUpdate["commercial_general_liability_each_occurance"] =
        finalData.commercial_general_liability_each_occurance;
      formValUpdate["commercial_general_liability_general_aggregate"] =
        finalData.commercial_general_liability_general_aggregate;
    } else {
      const dataLiability =
        docIndex.commercial_general_liability_form_doc_index >= 0 &&
        docIndex.commercial_general_liability_form_doc_index !== DOC_MAX_LIMIT
          ? mapFieldLiability(
              franchiseeData?.recogData[
                docIndex.commercial_general_liability_form_doc_index
              ]
            )
          : null;
      formValUpdate["commercial_general_liability_each_occurance"] =
        dataLiability?.commercial_general_liability_each_occurance;

      formValUpdate["commercial_general_liability_general_aggregate"] =
        dataLiability?.commercial_general_liability_general_aggregate;
    }

    if (
      franchiseeData?.finalData?.umbrella_subrogration_form_status ===
        STATUS_SECTION_APPROVE ||
      franchiseeData?.finalData?.umbrella_subrogration_form_status ===
        STATUS_SECTION_PENDING
    ) {
      const finalData = franchiseeData.finalData;

      formValUpdate["umbrella_subrogration_each_occurance"] =
        finalData.umbrella_subrogration_each_occurance;
      formValUpdate["umbrella_subrogration_aggregate"] =
        finalData.umbrella_subrogration_aggregate;
    } else {
      const dataUmbrella =
        docIndex.umbrella_subrogration_form_doc_index >= 0 &&
        docIndex.umbrella_subrogration_form_doc_index !== DOC_MAX_LIMIT
          ? mapFieldUmbrella(
              franchiseeData?.recogData[
                docIndex.umbrella_subrogration_form_doc_index
              ]
            )
          : null;

      formValUpdate["umbrella_subrogration_each_occurance"] =
        dataUmbrella?.umbrella_subrogration_each_occurance;

      formValUpdate["umbrella_subrogration_aggregate"] =
        dataUmbrella?.umbrella_subrogration_aggregate;
    }

    if (
      franchiseeData?.finalData?.automotive_liability_form_status ===
        STATUS_SECTION_APPROVE ||
      franchiseeData?.finalData?.automotive_liability_form_status ===
        STATUS_SECTION_PENDING
    ) {
      const finalData = franchiseeData.finalData;

      formValUpdate["automotive_liability_combined_single_limit"] =
        finalData.automotive_liability_combined_single_limit;
    } else {
      const dataAuto =
        docIndex.automotive_liability_form_doc_index >= 0 &&
        docIndex.automotive_liability_form_doc_index !== DOC_MAX_LIMIT
          ? mapFieldAuto(
              franchiseeData?.recogData[
                docIndex.automotive_liability_form_doc_index
              ]
            )
          : null;

      formValUpdate["automotive_liability_combined_single_limit"] =
        dataAuto?.automotive_liability_combined_single_limit;
    }

    if (
      franchiseeData?.finalData?.workers_compensation_form_status ===
        STATUS_SECTION_APPROVE ||
      franchiseeData?.finalData?.workers_compensation_form_status ===
        STATUS_SECTION_PENDING
    ) {
      const finalData = franchiseeData.finalData;

      formValUpdate["workers_compensation_el_each_accident"] =
        finalData.workers_compensation_el_each_accident;
      formValUpdate["workers_compensation_other_el_disease_each_employer"] =
        finalData.workers_compensation_other_el_disease_each_employer;
      formValUpdate["workers_compensation_el_disease_policy_limit"] =
        finalData.workers_compensation_el_disease_policy_limit;
    } else {
      const dataWorker =
        docIndex.workers_compensation_form_doc_index >= 0 &&
        docIndex.workers_compensation_form_doc_index !== DOC_MAX_LIMIT
          ? mapFieldWC(
              franchiseeData?.recogData[
                docIndex.workers_compensation_form_doc_index
              ]
            )
          : null;

      formValUpdate["workers_compensation_el_each_accident"] =
        dataWorker?.workers_compensation_el_each_accident;

      formValUpdate["workers_compensation_other_el_disease_each_employer"] =
        dataWorker?.workers_compensation_other_el_disease_each_employer;

      formValUpdate["workers_compensation_el_disease_policy_limit"] =
        dataWorker?.workers_compensation_el_disease_policy_limit;
    }

    return formValUpdate;
  }

  return (
    <div className="additional-report">
      <h3 className="additional-report-title">Additional Information</h3>
      <div className="additional-report-panel">
        {buildAdditionalOccurenceValue(formVal, franchisorData).map((item) => (
          <div className="issue-report-item">{item.title}</div>
        ))}
        {buildAdditionalAggregateValue(formVal, franchisorData).map((item) => (
          <div className="issue-report-item">{item.title}</div>
        ))}
        {/* Need to enable later */}
        {/* {buildAdditionalAutoCombinedValue(formVal, franchisorData).map(
          (item) => (
            <div className="issue-report-item">{item.title}</div>
          )
        )}
        {buildAdditionalCompAccidentValue(formVal, franchisorData).map(
          (item) => (
            <div className="issue-report-item">{item.title}</div>
          )
        )}
        {buildAdditionalCompEmployeeValue(formVal, franchisorData).map(
          (item) => (
            <div className="issue-report-item">{item.title}</div>
          )
        )}
        {buildAdditionalCompLimitValue(formVal, franchisorData).map((item) => (
          <div className="issue-report-item">{item.title}</div>
        ))} */}
        {buildAdditionalPersonalValue(formVal, franchisorData).map((item) => (
          <div className="issue-report-item">{item.title}</div>
        ))}
        {buildAdditionalProductValue(formVal, franchisorData).map((item) => (
          <div className="issue-report-item">{item.title}</div>
        ))}
      </div>
    </div>
  );
}
