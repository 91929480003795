import React, { useMemo, useState } from "react";
import "./style.scss";
import { Button } from "antd";
import iconDoc from "../../../../assets/images/icon_doc.png";
import iconDocWarn from "../../../../assets/images/icon_doc_warn.svg";
import iconPolicy from "../../../../assets/images/icon_policy.png";
import iconLegal from "../../../../assets/images/icon_legal.png";
import iconInsurance from "../../../../assets/images/icon_insurance.png";
import { DoubleRightOutlined } from "@ant-design/icons";

const STEP_RECEIPT = 0;
const STEP_POLICY = 1;
const STEP_LEGAL = 2;
const STEP_INSURANCE = 3;
const STEP_DUPLICATED = 4;
const STEP_EXPIRE = 5;

export default function InstructionForm({ onClose }) {
  const [step, setStep] = useState(STEP_RECEIPT);

  const content = useMemo(() => {
    if (step === STEP_RECEIPT) {
      return {
        title: "#1: Receipt",
        desc: "A receipt is not a Certificate of Insurance",
        img: iconDoc,
        img1: iconDocWarn,
      };
    }

    if (step === STEP_POLICY) {
      return {
        title: "#2: Policy Declaration Page",
        desc: "This is not a Certificate of Insurance",
        img: iconPolicy,
        img1: iconDocWarn,
      };
    }

    if (step === STEP_LEGAL) {
      return {
        title: "#3: The Actual Legal Policy",
        desc: "This is not a Certificate of Insurance",
        img: iconLegal,
        img1: iconDocWarn,
      };
    }
    if (step === STEP_INSURANCE) {
      return {
        title: "#4: Insurance Card",
        desc: "This is not a Certificate of Insurance",
        img: iconInsurance,
        img1: iconDocWarn,
      };
    }
    if (step === STEP_DUPLICATED) {
      return {
        title: "#5: Duplicate Upload",
        desc: "This is not a new Certificate of Insurance",
        img: iconDocWarn,
      };
    }
    if (step === STEP_EXPIRE) {
      return {
        title: "#6: Expired Policy",
        desc: "This is not a current Certificate of Insurance",
        img: iconDocWarn,
      };
    }
  }, [step]);
  return (
    <div className="inst_form">
      <div className="inst_form-title">Having Trouble?</div>
      <p className="inst_form-desc">Here are the top reasons an upload fails</p>
      <div className="inst_form-img">
        <img className="inst_form-img-main" src={content.img}></img>
        {content.img1 && (
          <img className="inst_form-img-sub" src={content.img1}></img>
        )}
      </div>
      <div className="inst_form-ti">{content.title}</div>
      <div className="inst_form-de">{content.desc}</div>

      <div className="inst_form-footer">
        <Button
          type="primary"
          size="large"
          onClick={() => {
            if (step < STEP_EXPIRE) {
              setStep(step + 1);
            } else {
              onClose();
              setStep(STEP_RECEIPT);
            }
          }}
        >
          {step < STEP_EXPIRE ? "Next" : "Close"} <DoubleRightOutlined />
        </Button>
      </div>
    </div>
  );
}
