import TemplateForm from "../template-form";
import InputForm from "../input-form";
import React from "react";

export default function TemplateUpdate(props) {
  const { template } = props;
  return (
    <div>
      <InputForm requirement={template} />
      <TemplateForm requirement={template} />
    </div>
  );
}
