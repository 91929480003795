import { Button, Switch } from "antd";
import React, { useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Input } from "../../../../../components";
import "./style.scss";

export default function CheckItem({ data, onToggle, onDelete, onEdit }) {
  return (
    <div className="check_item">
      <p>{data.title}</p>
      <div className="check_item-action">
        <EditOutlined
          className="check_item-action-edit"
          onClick={() => onEdit(data)}
        />
        <DeleteOutlined
          className="check_item-action-delete"
          onClick={() => onDelete(data._id)}
        />
      </div>
    </div>
  );
}
