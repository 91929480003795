import React from 'react';
import './style.scss';
const defaultImageUrl = "https://i.pinimg.com/originals/b8/38/e3/b838e3f2615edb2babaa8bf929061eef.jpg";

function Avatar(props) {
    const { url } = props;

    const backImageUrl = url? url: defaultImageUrl
    return (
        <div className="avatar-container" style={{backgroundImage: `url(${backImageUrl})`}}>

        </div>
    )
}

export default Avatar;