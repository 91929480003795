import React, { useState } from "react";
import { Form, Alert } from "antd";
import { Input, Loading } from "../../../../../components";
import { useAuth0 } from "@auth0/auth0-react";
import { post } from "../../../../../utils/axios";
import {
  PLATFORM_FRANCONNECT,
  PLATFORM_SERVICE_MINDER,
} from "../../../../../assets/const/fms-entity";
import "./style.scss";

export default function SigninPlatformForm({
  form,
  platform,
  requirementId,
  onNext,
}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  async function handleSubmit(values) {
    if (platform === PLATFORM_FRANCONNECT) {
      await handleFranconnectIntegrate(values);
    } else if (platform === PLATFORM_SERVICE_MINDER) {
      await handleServiceMinderIntegrate(values);
    }
  }

  async function handleServiceMinderIntegrate(values) {
    const param = {
      apiKey: values["apiKey"],
      requirementId: requirementId,
    };

    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      let result = await post(`serviceminder/auth`, param, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.status === 200) {
        onNext({
          apiKey: values["apiKey"],
          data: result?.data?.data || [],
        });
      } else {
        setError("Invalid API Key!");
      }
      setLoading(false);
    } catch (error) {
      setError("Invalid Credential");
      setLoading(false);
    }
  }

  async function handleFranconnectIntegrate(values) {
    setLoading(true);
    const param = {
      username: values["username"],
      password: values["password"],
      tenantID: values["tenantID"],
      clientId: values["clientId"],
      clientSecret: values["clientSecret"],
      requirementId: requirementId,
    };

    try {
      const token = await getAccessTokenSilently();
      let result = await post(`franconnect/auth`, param, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.status === 200) {
        let { access_token } = result.data.data;
        let resultFranchiseeList = await post(
          `franconnect/franchisee-list`,
          {
            token: access_token,
            tenantID: values["tenantID"],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (resultFranchiseeList.status === 200) {
          onNext({
            token: access_token,
            tenantID: values["tenantID"],
            data: Object.values(resultFranchiseeList.data.data),
          });
        } else {
          setError(
            "Failed to fetch Franchisee. Please contact admin directly!"
          );
        }
      } else {
        setError("Invalid Credential");
      }
      setLoading(false);
    } catch (error) {
      setError("Invalid Credential");
      setLoading(false);
    }
  }

  function handleChange() {
    setError(null);
  }

  return (
    <Form form={form} onFinish={handleSubmit}>
      {platform === "franconnect" && (
        <div className="onboarding-form_signin_platform">
          <h3 className="modal-header-title">Sign in to Franconnect</h3>
          {error && (
            <Alert message={error} className="form-error" type="error" />
          )}
          <div className="line-container">
            <Form.Item
              name="tenantID"
              rules={[{ required: true, message: "Please input Tenant ID!" }]}
            >
              <Input
                title="Tenant ID"
                type="text"
                placeholder="Please enter tenant ID"
                description="Ex: colorworldhousepainting (colorworldhousepainting.franconnect.net)"
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className="line-container">
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please input Username!" }]}
            >
              <Input
                title="Username"
                type="text"
                placeholder="Please enter your username"
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className="line-container">
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Please input Password!" }]}
            >
              <Input
                title="Password"
                type="password"
                placeholder="Please enter your password"
                onChange={handleChange}
              />
            </Form.Item>
          </div>

          <div className="line-container">
            <Form.Item name="clientId" rules={[]}>
              <Input
                title="Client ID"
                type="text"
                placeholder="Please enter your Client ID"
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className="line-container">
            <Form.Item name="clientSecret" rules={[]}>
              <Input
                title="Client Secret"
                type="password"
                placeholder="Please enter your Client Secret"
                onChange={handleChange}
              />
            </Form.Item>
          </div>

          <Loading loading={loading} />
        </div>
      )}
      {platform === "serviceminder" && (
        <div className="onboarding-form_signin_platform">
          <h3 className="modal-header-title">Sign in to Service Minder</h3>
          {error && (
            <Alert message={error} className="form-error" type="error" />
          )}
          <div className="line-container">
            <Form.Item
              name="apiKey"
              rules={[{ required: true, message: "Please input API Key!" }]}
            >
              <Input
                title="API Key"
                type="text"
                placeholder="Please enter API Key"
                description="Ex: XXXX"
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <Loading loading={loading} />
        </div>
      )}
    </Form>
  );
}
