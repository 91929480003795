const COLOR_COMPLIANT = "#70f0b8";
const COLOR_NON_COMPLIANT = "#f13f7a";
const COLOR_UN_VERIFIED = "#c5c5c5";
const COLOR_IN_PLACE = "#70f0b8";
const COLOR_NONE = "#c5c5c5";

const COLOR_PENDING = "#f6f2aa";

export {
  COLOR_COMPLIANT,
  COLOR_NON_COMPLIANT,
  COLOR_UN_VERIFIED,
  COLOR_IN_PLACE,
  COLOR_NONE,
  COLOR_PENDING,
};
