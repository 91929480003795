import React, { useState } from "react";
import { Card, Input } from "../../../../components";
import "./style.scss";

export default function ConsultantInfor(props) {
  const { consultantData } = props;
  return (
    <Card title="Consultant" className="consultant_container">
      {consultantData && (
        <div className="consultant_container-wrapper">
          <div className="form-group-two-container">
            <Input
              title="First Name"
              type="text"
              value={consultantData.first_name}
              disabled
            />
            <Input
              title="Last Name"
              type="text"
              value={consultantData.last_name}
              disabled
            />
          </div>
          <div className="form-group-two-container">
            <Input
              title="Email"
              type="text"
              value={consultantData.email}
              disabled
            />
            <Input
              title="Standard Link"
              type="text"
              value={consultantData.link}
              disabled
            />
          </div>
        </div>
      )}
      {!consultantData && (
        <div className="consultant_container-empty">No Consultant</div>
      )}
    </Card>
  );
}
