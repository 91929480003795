const ENTITY_CODE = [
  {
    value: "PT",
    label: "Partnership",
  },
  {
    value: "IN",
    label: "Individual",
  },
  {
    value: "LL",
    label: "LLC",
  },
  {
    value: "CP",
    label: "Corporation",
  },
  {
    value: "com.thehartford_LR",
    label: "com.thehartford_LR",
  },
  {
    value: "JV",
    label: "Joint Venture",
  },
  {
    value: "AS",
    label: "Association",
  },
  {
    value: "com.thehartford_PC",
    label: "com.thehartford_PC",
  },
  {
    value: "SCORP",
    label: "S Corporation",
  },
  {
    value: "NP-LL",
    label: "NP-LL",
  },
  {
    value: "NP-C",
    label: "NP-C",
  },
  {
    value: "TR",
    label: "Trust",
  },
];

export { ENTITY_CODE };
