import React, { useState, useEffect, useMemo } from "react";
import { Input, Loading } from "@components";
import { Radio, Button, notification } from "antd";
import "./style.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, put } from "@utils/axios";
import { mapFieldUmbrella } from "@utils/form-recog";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildUmbrellaSubrogrationLiability,
  buildUmbrellaSubrogrationActivity,
  buildUmbrellaSubrogrationStatus,
  reportUmbrella,
  getUmbrellaScore,
} from "@utils/build-logic";
import {
  FORM_OCCURRENCE,
  FORM_CLAIMS_MADE,
  FORM_DEDUCTIBLE,
  FORM_RETENTION,
  FORM_NONE,
  FORM_EXCESS_LIABILITY,
  FORM_UMBRELLA_LIABILITY,
} from "@assets/const/form";
import { PERMISSION_TABLE } from "@utils/permission";
import { useAppState } from "@context";
import { isEmpty } from "lodash";
import {
  DOC_MAX_LIMIT,
  INSURANCE_STATUS_NON_COMPLIANT,
  STATUS_APPROVE_UMBRELLA,
  STATUS_SECTION_APPROVE,
  STATUS_SECTION_PENDING,
} from "@assets/const/status";
import { TYPE_INPUT_DATE } from "@assets/const/ui";
import { validateDate } from "@utils/common";
import { format } from "date-fns";

export default function UmbrellaForm(props) {
  const { docIndex, franchisorData } = props;
  const { getAccessTokenSilently } = useAuth0();
  const { authSetting } = useAppState();
  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      (docIndex >= 0 &&
        docIndex ===
          franchiseeData?.finalData?.umbrella_subrogration_form_doc_index) ||
      docIndex === DOC_MAX_LIMIT
    ) {
      if (
        franchiseeData?.finalData?.umbrella_subrogration_form_status ===
          STATUS_SECTION_APPROVE ||
        franchiseeData?.finalData?.umbrella_subrogration_form_status ===
          STATUS_SECTION_PENDING
      ) {
        const finalData = franchiseeData.finalData;

        return {
          umbrella_subrogration_each_occurance:
            finalData.umbrella_subrogration_each_occurance,
          umbrella_subrogration_aggregate:
            finalData.umbrella_subrogration_aggregate,
          umbrella_subrogration_liability:
            finalData.umbrella_subrogration_liability,
          umbrella_subrogration_activity:
            finalData.umbrella_subrogration_activity,
          umbrella_subrogration_status: finalData.umbrella_subrogration_status,
          umbrella_subrogration_policy_number:
            finalData.umbrella_subrogration_policy_number,
          umbrella_subrogration_effective_date:
            finalData.umbrella_subrogration_effective_date,
          umbrella_subrogration_expire_date:
            finalData.umbrella_subrogration_expire_date,
        };
      } else {
        return getEmptyForm();
      }
    }

    if (docIndex >= 0) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldUmbrella(data);
      }
    }

    return getEmptyForm();
  }

  function getEmptyForm() {
    return {
      umbrella_subrogration_each_occurance: 0,
      umbrella_subrogration_aggregate: 0,
      umbrella_subrogration_liability: "",
      umbrella_subrogration_activity: "",
      umbrella_subrogration_status: "",
      umbrella_subrogration_policy_number: "",
      umbrella_subrogration_effective_date: "",
      umbrella_subrogration_expire_date: "",
    };
  }

  const [loading, setLoading] = useState(false);

  async function handleApprove() {
    let { franchiseeData, docIndex } = props;
    if (docIndex >= 0) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        let activities = franchiseeData.activities;
        if (activities) {
          activities.push({
            action: STATUS_APPROVE_UMBRELLA,
            date: new Date(),
          });
        } else {
          activities = [
            {
              action: STATUS_APPROVE_UMBRELLA,
              date: new Date(),
            },
          ];
        }

        let finalData = franchiseeData?.finalData
          ? franchiseeData.finalData
          : {};
        finalData = {
          ...finalData,
          ...formVal,
          umbrella_subrogration_form_status:
            !isEmpty(formVal.umbrella_subrogration_expire_date) &&
            new Date(formVal.umbrella_subrogration_expire_date) < new Date()
              ? STATUS_SECTION_PENDING
              : STATUS_SECTION_APPROVE,
          umbrella_subrogration_form_doc_index: docIndex,
          umbrella_subrogration_effective_date: validateDate(
            formVal.umbrella_subrogration_effective_date
          )
            ? format(
                new Date(formVal.umbrella_subrogration_effective_date),
                "yyyy-MM-dd"
              )
            : null,
          umbrella_subrogration_expire_date: validateDate(
            formVal.umbrella_subrogration_expire_date
          )
            ? format(
                new Date(formVal.umbrella_subrogration_expire_date),
                "yyyy-MM-dd"
              )
            : null,
        };

        const resultIssue = reportUmbrella(finalData, franchisorData);

        const result = await put(
          `contact/${franchiseeData._id}`,
          {
            finalData: finalData,
            activities: activities,
            umbrellaIssue: resultIssue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await deleteAPI(`notification/delete/${franchiseeData._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { handleUpdateForm } = props;
        handleUpdateForm(result.data.entity);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      handleNotification("Failed", "Need to choose a documentation");
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  const coverageScore = useMemo(() => {
    let finalData = props.franchiseeData?.finalData
      ? props.franchiseeData.finalData
      : {};
    finalData = {
      ...finalData,
      ...formVal,
      umbrella_subrogration_form_status:
        !isEmpty(formVal.umbrella_subrogration_expire_date) &&
        new Date(formVal.umbrella_subrogration_expire_date) < new Date()
          ? STATUS_SECTION_PENDING
          : STATUS_SECTION_APPROVE,
      umbrella_subrogration_form_doc_index: docIndex,
      umbrella_subrogration_effective_date: validateDate(
        formVal.umbrella_subrogration_effective_date
      )
        ? formVal.umbrella_subrogration_effective_date
        : null,
      umbrella_subrogration_expire_date: validateDate(
        formVal.umbrella_subrogration_expire_date
      )
        ? formVal.umbrella_subrogration_expire_date
        : null,
    };

    const resultScore = getUmbrellaScore(finalData, franchisorData);
    return resultScore;
  }, [props.franchiseeData, formVal, franchisorData]);

  return (
    <div className="umbrella-content">
      {coverageScore.visible && (
        <div className="workers-comp-content-score">
          Score: {coverageScore.score} / {coverageScore.total}
        </div>
      )}
      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_liability",
                  evt.target.value
                )
              }
              value={formVal.umbrella_subrogration_liability}
            >
              <Radio value={FORM_UMBRELLA_LIABILITY}>UMBRELLA LIABILITY</Radio>
              <Radio value={FORM_EXCESS_LIABILITY}>EXCESS LIABILITY</Radio>
            </Radio.Group>
            {buildUmbrellaSubrogrationLiability(
              formVal.umbrella_subrogration_liability,
              franchisorData.umbrella_subrogration_liability,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange("umbrella_subrogration_activity", evt.target.value)
              }
              value={formVal.umbrella_subrogration_activity}
            >
              <Radio value={FORM_OCCURRENCE}>OCCURRENCE</Radio>
              <Radio value={FORM_CLAIMS_MADE}>CLAIMS-MADE</Radio>
            </Radio.Group>
            {buildUmbrellaSubrogrationActivity(
              formVal.umbrella_subrogration_activity,
              franchisorData.umbrella_subrogration_activity,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange("umbrella_subrogration_status", evt.target.value)
              }
              value={formVal.umbrella_subrogration_status}
            >
              <Radio value={FORM_DEDUCTIBLE}>DEDUCTIBLE</Radio>
              <Radio value={FORM_RETENTION}>RETENTION</Radio>
              <Radio value={FORM_NONE}>NONE</Radio>
            </Radio.Group>
            {buildUmbrellaSubrogrationStatus(
              formVal.umbrella_subrogration_status,
              franchisorData.umbrella_subrogration_status,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="alert-container">
            <Input
              title="POLICY NUMBER"
              placeholder="POLICY NUMBER"
              value={formVal.umbrella_subrogration_policy_number}
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_policy_number",
                  evt.target.value
                )
              }
            />
            <Input
              title="EFFECTIVE DATE"
              placeholder="EFFECTIVE DATE"
              value={formVal.umbrella_subrogration_effective_date}
              inputType={TYPE_INPUT_DATE}
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_effective_date",
                  evt.target.value
                )
              }
            />
            {buildEffectDate(
              formVal.umbrella_subrogration_effective_date,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <Input
              title="EXPIRATION DATE"
              placeholder="EXPIRATION DATE"
              value={formVal.umbrella_subrogration_expire_date}
              inputType={TYPE_INPUT_DATE}
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_expire_date",
                  evt.target.value
                )
              }
            />

            {buildExpireDate(
              formVal.umbrella_subrogration_expire_date,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              placeholder="EACH OCCURRENCE"
              value={formVal.umbrella_subrogration_each_occurance}
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_each_occurance",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.umbrella_subrogration_each_occurance,
              franchisorData.umbrella_subrogration_each_occurance,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="AGGREGATE"
              placeholder="AGGREGATE"
              value={formVal.umbrella_subrogration_aggregate}
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_aggregate",
                  evt.target.value
                )
              }
            />

            {buildValueGT(
              formVal.umbrella_subrogration_aggregate,
              franchisorData.umbrella_subrogration_aggregate,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="form-footer">
        {PERMISSION_TABLE["FRANCHISEE_DATA_APPROVE"][
          authSetting.userPermission
        ] > 0 && (
          <Button type="primary" size="large" onClick={handleApprove}>
            Approve
          </Button>
        )}
      </div>

      <Loading loading={loading} />
    </div>
  );
}
