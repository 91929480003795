import React from "react";
import { Breadcrumb, Button, Card, Table, Row, Col, Space } from "antd";
import "./style.scss";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { get, deleteAPI } from "../../../utils/axios";
import { useAuth0 } from "@auth0/auth0-react";

const Address = ({
  street_address_1,
  street_address_2,
  city,
  state,
  zipcode,
}) => (
  <div>
    {street_address_1}
    {street_address_2 && (
      <>
        <br />
        {street_address_2}
      </>
    )}
    <br />
    {city}, {state} {zipcode}
  </div>
);

const Contact = ({ phone, email, contact }) =>
  contact?.firstName ||
  contact?.lastName ||
  contact?.email ||
  contact?.phone ||
  contact?.position ? (
    <div>
      {contact.firstName} {contact.lastName}
      {contact.position && (
        <>
          <br />
          {contact.position}
        </>
      )}
      <br />
      {contact.email}
      <br />
      {contact.phone}
    </div>
  ) : (
    <div>
      {email}
      <br />
      {phone}
    </div>
  );

const AffiliateDetails = ({ data }) => {
  const columns = [
    {
      title: "Affiliate Marketer",
      render: ({ name }) => name,
    },
    {
      title: "Address",
      render: (data) => <Address {...data} />,
    },
    {
      title: "DBA",
      render: ({ dba }) => dba,
    },
    {
      title: "Contact",
      render: (data) => <Contact {...data} />,
    },
  ];

  return (
    <div className="affiliate-details">
      <Row>
        {columns.map(({ title, render }, i) => (
          <Col span={6} key={i}>
            <div>
              <b>{title}</b>
            </div>
            <div>{render(data)}</div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

const AffiliateStats = ({ data }) => {
  const intros = data?.franchises?.length || 0;
  const active = data?.franchises?.filter(({ active }) => active)?.length || 0;

  const columns = [
    {
      title: "Franchise Intros",
      value: intros,
    },
    {
      title: "Active Franchises",
      value: active,
    },
    {
      title: "Franchisee Accounts",
      value:
        data?.franchises
          ?.map((f) => f.franchisees)
          ?.reduce((x, y) => x + y, 0) || 0,
    },
    {
      title: "Affiliate Marketing Fee",
      value: `${(data?.fee || 0) * 100}%`,
    },
    {
      title: "Affiliate Marketing Revenue",
      value: `${Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(data?.revenue || 0)}`,
    },
  ];

  return (
    <div className="affiliate-stats">
      <Row gutter={24} justify="center">
        {columns.map(({ title, value }, i) => (
          <Col key={i}>
            <Card className="stat-card">
              <div className="stat">{value}</div>
              <div className="label">{title}</div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default function Detail({ preview = false }) {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { state } = useLocation();
  const { id } = useParams();
  const [affiliate, setAffiliate] = React.useState(state?.affiliate || {});

  React.useEffect(() => {
    fetchAffiliate();
  }, []);

  async function fetchAffiliate() {
    try {
      const result = await get(`affiliates/${id}`);
      setAffiliate(result.data.data);
    } catch (err) {
      console.error("error fetching affiliate");
      console.error(err);
    }
  }

  async function onDelete() {
    const result = window.confirm(
      `Are you sure you want to delete ${affiliate?.name || "this affiliate?"}`
    );
    if (result) {
      try {
        const token = await getAccessTokenSilently();
        await deleteAPI(`affiliates/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        history.push("/affiliates/overview");
      } catch (err) {
        console.error("error deleting franchise");
        console.error(err);
      }
    }
  }

  function onDeleteFranchise(row) {
    return async (e) => {
      e.preventDefault();
      e.stopPropagation();
      const result = window.confirm(
        `Are you sure you want to delete ${row?.name || "this franchise?"}`
      );
      if (result) {
        try {
          const token = await getAccessTokenSilently();
          const result = await deleteAPI(
            `affiliates/${id}/franchises/${row._id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setAffiliate(result.data.data);
        } catch (err) {
          console.error("error deleting franchise");
          console.error(err);
        }
      }
    };
  }

  const columns = [
    {
      title: "Franchise",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Franchisees",
      dataIndex: "franchisees",
      key: "franchisees",
    },
    {
      title: "Total Premium",
      dataIndex: "totalPremium",
      key: "totalPremium",
      render: (value) =>
        Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value || 0),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (value) => (
        <div className={`status-container ${value ? "success" : "info"}`}>
          {value ? "Active" : "Inactive"}
        </div>
      ),
    },
  ];

  if (!preview) {
    columns.push({
      title: "Actions",
      key: "actions",
      render: (_, row) => (
        <Button type="primary" danger onClick={onDeleteFranchise(row)}>
          <DeleteOutlined />
        </Button>
      ),
    });
  }

  const onRow = (row) => ({
    onClick: () => {
      history.push(`/affiliates/${id}/franchises/${row._id}/edit`, {
        affiliate,
        franchise: { ...row },
      });
    },
  });

  return (
    <div className="affiliates-container">
      {!preview && (
        <Breadcrumb className="breadcrumbs">
          <Breadcrumb.Item>
            <Link to="/affiliates/overview">Affiliates</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{affiliate?.name || "Detail"}</Breadcrumb.Item>
        </Breadcrumb>
      )}
      <Card
        title="Affiliate Details"
        extra={
          !preview && (
            <div className="actions">
              <Space>
                <Link
                  to={`/affiliate/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button>
                    <LinkOutlined />
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `/affiliates/${id}/edit`,
                    state: { affiliate },
                  }}
                >
                  <Button type="primary">
                    <EditOutlined />
                  </Button>
                </Link>
                <Button type="primary" danger onClick={onDelete}>
                  <DeleteOutlined />
                </Button>
              </Space>
            </div>
          )
        }
      >
        <AffiliateDetails data={affiliate} />
        <AffiliateStats data={affiliate} />
      </Card>
      <Card
        title="Franchises"
        className="affiliate-franchises"
        extra={
          !preview && (
            <Link
              to={{
                pathname: `/affiliates/${id}/franchises/new`,
                state: { affiliate },
              }}
            >
              <Button type="primary">
                <PlusOutlined />
              </Button>
            </Link>
          )
        }
      >
        <Table
          columns={columns}
          dataSource={affiliate?.franchises || []}
          onRow={!preview ? onRow : () => {}}
        />
      </Card>
    </div>
  );
}
