import React, { useState, useEffect } from "react";
import "./style.scss";
import { Switch, Route, useLocation } from "react-router-dom";
import FranchiseeUpload from "./franchisee-upload";
import FranchiseeDetailsReport from "./franchisee-details-report";
import { get } from "@utils/axios";
import iconRikorLogo from "../../assets/images/rikor-logo.svg";

export default function FranchiseeSection() {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const id = pathSegments[pathSegments.length - 1];

  const [loading, setLoading] = useState(false);
  const [franchisorLogoUri, setFranchisorLogoUri] = useState("");
  const [entityData, setEntityData] = useState({});
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 992);

  useEffect(() => {
    handleLoadContact();

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function handleLoadContact() {
    setLoading(true);
    try {
      const result = await get(`contact/${id}/entity`);
      setFranchisorLogoUri(result?.data?.franchisorLogoUri || "");
      setEntityData(result?.data?.recordData);
    } catch (error) {
      console.log("load contact error", error);
    }
    setLoading(false);
  }

  return (
    <div className="franchisee-section">
      <div
        className={`franchisee-header-container ${
          isMobileView ? "center-logo" : ""
        }`}
      >
        {isMobileView ? (
          franchisorLogoUri ? (
            <img src={franchisorLogoUri} alt="Franchisor Logo" />
          ) : entityData?.entity_name ? (
            <h3>{entityData?.entity_name}</h3>
          ) : (
            <img src={iconRikorLogo} alt="Rikor Logo" />
          )
        ) : (
          <>
            <img src={iconRikorLogo} alt="Rikor Logo" />
            <h3>{entityData?.entity_name}</h3>
            {franchisorLogoUri && (
              <img src={franchisorLogoUri} alt="Franchisor Logo" />
            )}
          </>
        )}
      </div>
      <div className="franchisee-section-flex-container">
        <Switch>
          <Route path="/coiportal/add/:id">
            {isMobileView ? (
              <>
                <div className="franchisee-upload-coi">
                  <FranchiseeUpload />
                </div>
                <div className="franchisee-report">
                  <FranchiseeDetailsReport />
                </div>
              </>
            ) : (
              <>
                <div className="franchisee-report">
                  <FranchiseeDetailsReport />
                </div>
                <div className="franchisee-upload-coi">
                  <FranchiseeUpload />
                </div>
              </>
            )}
          </Route>
        </Switch>
      </div>
    </div>
  );
}
