import React, { useState } from "react";
import "./style.scss";
import { Checkbox, Button, Popconfirm } from "antd";

export default function DeleteSettingForm(props) {
  const { onClose } = props;
  const [formVal, setFormVal] = useState({
    requirement: true,
    contact: false,
  });

  function handleChange(evt) {
    setFormVal({
      ...formVal,
      [evt.target.name]: evt.target.checked,
    });
  }

  function handleDelete() {
    const { onOk } = props;
    onOk(formVal);
  }

  function handleCancel() {
    const { onClose } = props;
    onClose();
  }

  return (
    <div className="delete_modal">
      <div className="delete_modal-content">
        <div className="delete_modal-content_item">
          <Checkbox
            name="user"
            checked={formVal.requirement}
            onChange={handleChange}
            disabled
          >
            Franchisor Information
          </Checkbox>
        </div>
        <div className="delete_modal-content_item">
          <Checkbox
            name="contact"
            onChange={handleChange}
            checked={formVal.contact}
          >
            Related Franchisees
          </Checkbox>
        </div>
      </div>

      <div className="delete_modal-footer">
        <Button onClick={handleCancel}>Cancel</Button>
        <Popconfirm
          title="Are you sure to delete this franchisor?"
          onConfirm={handleDelete}
          onCancel={onClose}
          okText="Yes"
          cancelText="No"
        >
          <Button type="danger">Delete</Button>
        </Popconfirm>
      </div>
    </div>
  );
}
