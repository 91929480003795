import React from "react";
import AffiliateDetail from "../detail";
import { Header } from "../../../components";
import "./style.scss";

export default function AffiliatePreview() {
  return (
    <div className="dashboard-container">
      <Header screen="affiliate-dashboard" />
      <div className="dashboard-wrapper">
        <AffiliateDetail preview />
      </div>
    </div>
  );
}
