import React, { useState, useEffect, useMemo } from "react";
import { Input, Loading } from "@components";
import { Radio, Button, notification } from "antd";
import "./style.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, put } from "@utils/axios";
import { mapFieldPolLiab } from "@utils/form-recog";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildPollutionType,
  reportPolLiab,
  getPolLiabScore,
} from "@utils/build-logic";
import { FORM_CLAIMS_MADE, FORM_OCCURRENCE } from "@assets/const/form";
import { PERMISSION_TABLE } from "@utils/permission";
import { useAppState } from "@context";
import { isEmpty } from "lodash";
import {
  DOC_MAX_LIMIT,
  INSURANCE_STATUS_NON_COMPLIANT,
  STATUS_APPROVE_POL_LIAB,
  STATUS_SECTION_APPROVE,
  STATUS_SECTION_PENDING,
} from "@assets/const/status";
import { TYPE_INPUT_DATE } from "@assets/const/ui";
import { validateDate } from "@utils/common";
import { format } from "date-fns";

export default function PolLiabForm(props) {
  const { docIndex, franchisorData } = props;
  const { getAccessTokenSilently } = useAuth0();
  const { authSetting } = useAppState();
  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      (docIndex >= 0 &&
        docIndex === franchiseeData?.finalData?.pol_liab_form_doc_index) ||
      docIndex === DOC_MAX_LIMIT
    ) {
      if (
        franchiseeData?.finalData?.pol_liab_form_status ===
          STATUS_SECTION_APPROVE ||
        franchiseeData?.finalData?.pol_liab_form_status ===
          STATUS_SECTION_PENDING
      ) {
        const finalData = franchiseeData.finalData;

        return {
          pol_liab_occ: finalData.pol_liab_occ,
          pol_liab_agg: finalData.pol_liab_agg,
          pol_liab_type: finalData.pol_liab_type,
          pol_liab_policy_number: finalData.pol_liab_policy_number,
          pol_liab_effective_date: finalData.pol_liab_effective_date,
          pol_liab_expire_date: finalData.pol_liab_expire_date,
        };
      } else {
        return getEmptyForm();
      }
    }

    if (docIndex >= 0) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldPolLiab(data);
      }
    }

    return getEmptyForm();
  }

  function getEmptyForm() {
    return {
      pol_liab_occ: "",
      pol_liab_agg: "",
      pol_liab_type: "",
      pol_liab_policy_number: "",
      pol_liab_effective_date: "",
      pol_liab_expire_date: "",
    };
  }

  const [loading, setLoading] = useState(false);

  async function handleApprove() {
    let { franchiseeData, docIndex } = props;
    if (docIndex >= 0) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        let activities = franchiseeData.activities;
        if (activities) {
          activities.push({
            action: STATUS_APPROVE_POL_LIAB,
            date: new Date(),
          });
        } else {
          activities = [
            {
              action: STATUS_APPROVE_POL_LIAB,
              date: new Date(),
            },
          ];
        }

        let finalData = franchiseeData?.finalData
          ? franchiseeData.finalData
          : {};
        finalData = {
          ...finalData,
          ...formVal,
          pol_liab_form_status:
            !isEmpty(formVal.pol_liab_expire_date) &&
            new Date(formVal.pol_liab_expire_date) < new Date()
              ? STATUS_SECTION_PENDING
              : STATUS_SECTION_APPROVE,
          pol_liab_form_doc_index: docIndex,
          pol_liab_effective_date: validateDate(formVal.pol_liab_effective_date)
            ? format(new Date(formVal.pol_liab_effective_date), "yyyy-MM-dd")
            : null,
          pol_liab_expire_date: validateDate(formVal.pol_liab_expire_date)
            ? format(new Date(formVal.pol_liab_expire_date), "yyyy-MM-dd")
            : null,
        };

        const resultIssue = reportPolLiab(finalData, franchisorData);

        const result = await put(
          `contact/${franchiseeData._id}`,
          {
            finalData: finalData,
            activities: activities,
            polLiabIssue: resultIssue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await deleteAPI(`notification/delete/${franchiseeData._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { handleUpdateForm } = props;
        handleUpdateForm(result.data.entity);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      handleNotification("Failed", "Need to choose a documentation");
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  const coverageScore = useMemo(() => {
    let finalData = props.franchiseeData?.finalData
      ? props.franchiseeData.finalData
      : {};
    finalData = {
      ...finalData,
      ...formVal,
      pol_liab_form_status:
        !isEmpty(formVal.pol_liab_expire_date) &&
        new Date(formVal.pol_liab_expire_date) < new Date()
          ? STATUS_SECTION_PENDING
          : STATUS_SECTION_APPROVE,
      pol_liab_form_doc_index: docIndex,
      pol_liab_effective_date: validateDate(formVal.pol_liab_effective_date)
        ? formVal.pol_liab_effective_date
        : null,
      pol_liab_expire_date: validateDate(formVal.pol_liab_expire_date)
        ? formVal.pol_liab_expire_date
        : null,
    };

    const resultScore = getPolLiabScore(finalData, franchisorData);
    return resultScore;
  }, [props.franchiseeData, formVal, franchisorData]);

  return (
    <div className="pol-liab-content">
      {coverageScore.visible && (
        <div className="workers-comp-content-score">
          Score: {coverageScore.score} / {coverageScore.total}
        </div>
      )}
      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <h5 className="input-title">POLLUTION TYPE</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("pol_liab_type", evt.target.value)
              }
              value={formVal.pol_liab_type}
            >
              <Radio value={FORM_OCCURRENCE}>Occurrence</Radio>
              <Radio value={FORM_CLAIMS_MADE}>Claims Made</Radio>
            </Radio.Group>
            {buildPollutionType(
              formVal.pol_liab_type,
              franchisorData.pol_liab_type,
              franchisorData.pol_liab_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="alert-container">
            <Input
              title="POLICY NUMBER"
              placeholder="POLICY NUMBER"
              value={formVal.pol_liab_policy_number}
              onChange={(evt) =>
                handleChange("pol_liab_policy_number", evt.target.value)
              }
            />
            <Input
              title="EFFECTIVE DATE"
              placeholder="EFFECTIVE DATE"
              value={formVal.pol_liab_effective_date}
              inputType={TYPE_INPUT_DATE}
              onChange={(evt) =>
                handleChange("pol_liab_effective_date", evt.target.value)
              }
            />
            {buildEffectDate(
              formVal.pol_liab_effective_date,
              franchisorData?.pol_liab_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <Input
              title="EXPIRATION DATE"
              placeholder="EXPIRATION DATE"
              value={formVal.pol_liab_expire_date}
              inputType={TYPE_INPUT_DATE}
              onChange={(evt) =>
                handleChange("pol_liab_expire_date", evt.target.value)
              }
            />

            {buildExpireDate(
              formVal.pol_liab_expire_date,
              franchisorData.pol_liab_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              placeholder="EACH OCCURRENCE"
              value={formVal.pol_liab_occ}
              onChange={(evt) => handleChange("pol_liab_occ", evt.target.value)}
            />
            {buildValueGT(
              formVal.pol_liab_occ,
              franchisorData.pol_liab_occ,
              franchisorData?.pol_liab_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="AGGREGATE"
              placeholder="AGGREGATE"
              value={formVal.pol_liab_agg}
              onChange={(evt) => handleChange("pol_liab_agg", evt.target.value)}
            />

            {buildValueGT(
              formVal.pol_liab_agg,
              franchisorData.pol_liab_agg,
              franchisorData.pol_liab_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="form-footer">
        {PERMISSION_TABLE["FRANCHISEE_DATA_APPROVE"][
          authSetting.userPermission
        ] > 0 && (
          <Button type="primary" size="large" onClick={handleApprove}>
            Approve
          </Button>
        )}
      </div>

      <Loading loading={loading} />
    </div>
  );
}
