import React from "react";
import { Radio } from "antd";
import { Input } from "../../../../../components";
import { TYPE_INPUT_DIGIT } from "../../../../../assets/const/ui";
import "./style.scss";

export default function OfficeBlock(props) {
  const { data, disabled, onChange } = props;

  function handleChangeName(index, key, value) {
    data[index][key] = value;
    onChange([...data]);
  }

  function handleAddName() {
    data.push({
      full_name: "",
      name_type: false,
      payroll: "",
      class_code: "",
      owner_ship: ""
    });
    onChange([...data]);
  }

  function handleDeleteName() {
    if (data.length > 1) {
      data.pop();
      onChange([...data]);
    }
  }
  return (
    <div className="admin_franchisee_under-section">
      <h4 className="admin_franchisee_under-section-title">
        Owners & Officers
      </h4>

      {data.map((item, index) => (
        <div className="owners-officers-block">
          <div key={`owners-officers-item-${index}`}>
            <div className="form-group-three-container">
              <Input
                title="Full Name"
                type="text"
                value={item.full_name}
                disabled={disabled}
                required
                onChange={(evt) =>
                  handleChangeName(index, "full_name", evt.target.value)
                }
              />
              <Input
                title="Payroll"
                type="text"
                inputType={TYPE_INPUT_DIGIT}
                value={item.payroll}
                disabled={disabled}
                required
                onChange={(evt) =>
                  handleChangeName(index, "payroll", evt.target.value)
                }
              />
              <div className="form-group-two-container">
                <Input
                  title="Class Code"
                  type="text"
                  inputType={TYPE_INPUT_DIGIT}
                  value={item.class_code}
                  disabled={disabled}
                  required
                  onChange={(evt) =>
                    handleChangeName(index, "class_code", evt.target.value)
                  }
                />
                <Input
                  title="Ownership"
                  error={
                    data
                      .map((item) => parseInt(item.owner_ship || 0))
                      .reduce((prev, curr) => prev + curr) != 100
                  }
                  errorMsg="Combined ownership must be 100"
                  type="text"
                  inputType={TYPE_INPUT_DIGIT}
                  value={item.owner_ship}
                  disabled={disabled}
                  required
                  onChange={(evt) =>
                    handleChangeName(index, "owner_ship", evt.target.value)
                  }
                />
              </div>
            </div>
            <div className="admin_franchisee_under-section-footer">
              <Radio.Group
                disabled={disabled}
                onChange={(evt) =>
                  handleChangeName(index, "name_type", evt.target.value)
                }
                value={item.name_type}
              >
                <Radio value={true}>Excluded</Radio>
                <Radio value={false}>Included</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      ))}

      {!disabled && (
        <div className="admin_franchisee_under-section-footer">
          <a
            className="admin_franchisee_under-section-footer-link"
            onClick={handleAddName}
          >
            Add Name
          </a>
          <a
            className="admin_franchisee_under-section-footer-link"
            onClick={handleDeleteName}
          >
            Delete Name
          </a>
        </div>
      )}
    </div>
  );
}
