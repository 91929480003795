import React from 'react';
import './style.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined spin />;

function Loading(props) {
    const { loading } = props;
    if(loading) {
        return (
            <div className="loading-container">
                <div className="back-container"></div>
                <Spin indicator={antIcon} />
            </div>
        )
    } else {
        return null;
    }
}

export default Loading