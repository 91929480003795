import React, { useState } from "react";
import "./style.scss";
import { Tabs, Radio, Space, Button } from "antd";
import SuccessImage from "@assets/images/success.png";
import { onboardingWorkflow } from "@assets/const/onboarding";
import { post, put } from "@utils/axios";
import { useAppState } from "@context";
import { useAuth0 } from "@auth0/auth0-react";
import { REQUIREMENT_STATUS_DASHBOARD } from "@assets/const/status";
import {
  LiabilityForm,
  AutoForm,
  WorkersCompForm,
  UmbrellaForm,
  CrimeForm,
  CyberForm,
  EpliForm,
  GarageForm,
  PolLiabForm,
  ProLiabForm,
  InlandMarineForm,
  AdditionalInfoForm,
} from "@components/insurance-requirements";
import { SOURCE_ONBOARDING } from "@assets/const/form";

const { TabPane } = Tabs;
const TAB_KEY_LIABILITY = "LIABILITY";
const TAB_KEY_AUTO = "AUTO";
const TAB_KEY_WORKERS_COMP = "WORKERS_COMP";
const TAB_KEY_UMBRELLA = "UMBRELLA";
const TAB_KEY_CRIME = "CRIME";
const TAB_KEY_GARAGE = "GARAGE";
const TAB_KEY_EPLI = "EPLI";
const TAB_KEY_CYBER = "CYBER";
const TAB_KEY_PROFESSIONAL = "PROFESSIONAL";
const TAB_KEY_POLLUTION = "POLLUTION";
const TAB_KEY_INLAND_MARINE = "INLAND_MARINE";
const TAB_KEY_ADDITIONAL_INFO = "ADDITIONAL_INFO";
const tabList = [
  { title: "LIABILITY", key: TAB_KEY_LIABILITY },
  { title: "AUTO", key: TAB_KEY_AUTO },
  { title: "WORKERS COMP", key: TAB_KEY_WORKERS_COMP },
  { title: "UMBRELLA", key: TAB_KEY_UMBRELLA },
  { title: "CRIME", key: TAB_KEY_CRIME },
  { title: "GARAGE", key: TAB_KEY_GARAGE },
  { title: "EMPLOYMENT PRACTICES LIABILITY", key: TAB_KEY_EPLI },
  { title: "CYBER", key: TAB_KEY_CYBER },
  { title: "PROFESSIONAL", key: TAB_KEY_PROFESSIONAL },
  { title: "POLLUTION", key: TAB_KEY_POLLUTION },
  { title: "INLAND MARINE", key: TAB_KEY_INLAND_MARINE },
  { title: "CERTIFICATE HOLDER INFORMATION", key: TAB_KEY_ADDITIONAL_INFO },
];

export default function InsuranceRequirementsForm(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { requirement } = useAppState();
  const [activeTab, setActiveTab] = useState(tabList[0].key);
  const [modalFlag, setModalFlag] = useState(false);

  function handleActivate() {
    setModalFlag(true);
    const { handleUpdateStatus } = props;
    handleUpdateStatus(onboardingWorkflow.REQUEST_ACTIVIATION);
  }

  async function handleNavigateDashboard() {
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `requirement/${requirement?._id}`,
        { status: REQUIREMENT_STATUS_DASHBOARD },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { handleNavigate } = props;
      if (handleNavigate) {
        handleNavigate(onboardingWorkflow.ACCOUNT_INFORMATION);
      }
    } catch (e) {}
  }

  return (
    <div className="insurance-requirement-container">
      {props.source === "onboarding" && (
        <h3 className="insurance-requirement-title">
          Establish Insurance Requirements
        </h3>
      )}
      {props.source === "onboarding" && (
        <div className="popup-active">
          <div className="info-avatar">i</div>
          <div className="popup-title">FDD REQUIRED :</div>
          <div className="popup-content">
            Please refer to your FDD documents for accurate insurance
            requirements.
          </div>
        </div>
      )}

      <div className="requirement-main-container">
        <div className="card-header">
          <Tabs
            tabPosition={props.source === "onboarding" ? "bottom" : "top"}
            activeKey={activeTab}
            onChange={(evt) => setActiveTab(evt)}
          >
            {tabList.map((tab) => (
              <TabPane tab={tab.title} key={tab.key} />
            ))}
          </Tabs>
        </div>
        {activeTab === TAB_KEY_LIABILITY && (
          <LiabilityForm
            onNext={() => setActiveTab(TAB_KEY_AUTO)}
            source={SOURCE_ONBOARDING}
            btnLabel={"Save & Next"}
            readOnly={props.source === "dashboard"}
          />
        )}
        {activeTab === TAB_KEY_AUTO && (
          <AutoForm
            onNext={() => setActiveTab(TAB_KEY_WORKERS_COMP)}
            source={SOURCE_ONBOARDING}
            btnLabel={"Save & Next"}
            readOnly={props.source === "dashboard"}
          />
        )}
        {activeTab === TAB_KEY_WORKERS_COMP && (
          <WorkersCompForm
            onNext={() => setActiveTab(TAB_KEY_UMBRELLA)}
            source={SOURCE_ONBOARDING}
            btnLabel={"Save & Next"}
            readOnly={props.source === "dashboard"}
          />
        )}
        {activeTab === TAB_KEY_UMBRELLA && (
          <UmbrellaForm
            onNext={() => setActiveTab(TAB_KEY_CRIME)}
            source={SOURCE_ONBOARDING}
            btnLabel={"Save & Next"}
            readOnly={props.source === "dashboard"}
          />
        )}

        {activeTab === TAB_KEY_CRIME && (
          <CrimeForm
            onNext={() => setActiveTab(TAB_KEY_GARAGE)}
            source={SOURCE_ONBOARDING}
            btnLabel={"Save & Next"}
            readOnly={props.source === "dashboard"}
          />
        )}
        {activeTab === TAB_KEY_GARAGE && (
          <GarageForm
            onNext={() => setActiveTab(TAB_KEY_EPLI)}
            source={SOURCE_ONBOARDING}
            btnLabel={"Save & Next"}
            readOnly={props.source === "dashboard"}
          />
        )}
        {activeTab === TAB_KEY_EPLI && (
          <EpliForm
            onNext={() => setActiveTab(TAB_KEY_CYBER)}
            btnLabel={"Save & Next"}
            source={SOURCE_ONBOARDING}
            readOnly={props.source === "dashboard"}
          />
        )}
        {activeTab === TAB_KEY_CYBER && (
          <CyberForm
            onNext={() => setActiveTab(TAB_KEY_ADDITIONAL_INFO)}
            btnLabel={"Save & Next"}
            source={SOURCE_ONBOARDING}
            readOnly={props.source === "dashboard"}
          />
        )}

        {activeTab === TAB_KEY_PROFESSIONAL && (
          <ProLiabForm
            onNext={() => setActiveTab(TAB_KEY_POLLUTION)}
            btnLabel={"Save & Next"}
            source={SOURCE_ONBOARDING}
            readOnly={props.source === "dashboard"}
          />
        )}
        {activeTab === TAB_KEY_POLLUTION && (
          <PolLiabForm
            onNext={() => setActiveTab(TAB_KEY_INLAND_MARINE)}
            btnLabel={"Save & Next"}
            source={SOURCE_ONBOARDING}
            readOnly={props.source === "dashboard"}
          />
        )}
        {activeTab === TAB_KEY_INLAND_MARINE && (
          <InlandMarineForm
            onNext={() => setActiveTab(TAB_KEY_ADDITIONAL_INFO)}
            btnLabel={"Save & Next"}
            source={SOURCE_ONBOARDING}
            readOnly={props.source === "dashboard"}
          />
        )}

        {activeTab === TAB_KEY_ADDITIONAL_INFO && (
          <AdditionalInfoForm
            onNext={handleActivate}
            source={SOURCE_ONBOARDING}
            btnLabel="Activate"
            readOnly={props.source === "dashboard"}
          />
        )}

        {modalFlag && (
          <div className="modal-container">
            <div className="modal-back-container"></div>
            <div className="modal-content-card">
              <div className="modal-header">
                <img src={SuccessImage} />
                <h3 className="modal-header-title">Congratulations!</h3>
                <p className="modal-header-desc">
                  You’ve successfully completed all the steps, and your
                  <br /> request has been sent to your franchisees.{" "}
                </p>
              </div>
              <div className="modal-content">
                <h3 className="modal-content-title">What’s next ?</h3>
                <ul>
                  <li>
                    Rikor will reach out to each franchisee to verify the
                    insurance requirements you just created.
                  </li>
                  <li>
                    No need to worry about anything else, we’ll follow up
                    automatically.{" "}
                  </li>
                </ul>
              </div>
              <div className="modal-footer">
                <Button
                  type="primary"
                  className="navigate-btn"
                  onClick={handleNavigateDashboard}
                >
                  Complete Onboarding
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
