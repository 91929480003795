import React, { useState, useEffect } from "react";
import {
  Input,
  Loading,
  FormMask,
  Textarea,
  ScreenMask,
} from "../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../utils/axios";
import "./style.scss";
import { useAppState } from "@context";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
  SOURCE_ONBOARDING,
} from "../../../assets/const/form";

export default function AutoForm(props) {
  const { data, btnLabel, source, onNext, readOnly = false } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;

  const [formVal, setFormVal] = useState({
    automotive_liability_any_auto:
      sourceData?.automotive_liability_any_auto || false,
    automotive_liability_all_owned_autos:
      sourceData?.automotive_liability_all_owned_autos || false,
    automotive_liability_category_hired_autos:
      sourceData?.automotive_liability_category_hired_autos || false,
    automotive_liability_category_scheduled_autos:
      sourceData?.automotive_liability_category_scheduled_autos || false,
    automotive_liability_category_non_owned_autos:
      sourceData?.automotive_liability_category_non_owned_autos || false,
    automotive_policy_form_additional_insurance:
      sourceData?.automotive_policy_form_additional_insurance || false,
    automotive_policy_form_subrogratrion_waived:
      sourceData?.automotive_policy_form_subrogratrion_waived || false,
    automotive_liability_broker_note:
      sourceData?.automotive_liability_broker_note || "",
    automotive_liability_combined_single_limit:
      sourceData?.automotive_liability_combined_single_limit || "",
    automotive_liability_bodily_injury_pp:
      sourceData?.automotive_liability_bodily_injury_pp || "",
    automotive_liability_policy_number:
      sourceData?.automotive_liability_policy_number || "",
    automotive_liability_effective_date:
      sourceData?.automotive_liability_effective_date || "",
    automotive_liability_expire_date:
      sourceData?.automotive_liability_expire_date || "",
    automotive_liability_required:
      sourceData?.automotive_liability_required || false,
  });

  useEffect(() => {
    setFormVal({
      automotive_liability_any_auto:
        sourceData?.automotive_liability_any_auto || false,
      automotive_liability_all_owned_autos:
        sourceData?.automotive_liability_all_owned_autos || false,
      automotive_liability_category_hired_autos:
        sourceData?.automotive_liability_category_hired_autos || false,
      automotive_liability_category_scheduled_autos:
        sourceData?.automotive_liability_category_scheduled_autos || false,
      automotive_liability_category_non_owned_autos:
        sourceData?.automotive_liability_category_non_owned_autos || false,
      automotive_policy_form_additional_insurance:
        sourceData?.automotive_policy_form_additional_insurance || false,
      automotive_policy_form_subrogratrion_waived:
        sourceData?.automotive_policy_form_subrogratrion_waived || false,
      automotive_liability_broker_note:
        sourceData?.automotive_liability_broker_note || "",
      automotive_liability_combined_single_limit:
        sourceData?.automotive_liability_combined_single_limit || "",
      automotive_liability_bodily_injury_pp:
        sourceData?.automotive_liability_bodily_injury_pp || "",
      automotive_liability_policy_number:
        sourceData?.automotive_liability_policy_number || "",
      automotive_liability_effective_date:
        sourceData?.automotive_liability_effective_date || "",
      automotive_liability_expire_date:
        sourceData?.automotive_liability_expire_date || "",
      automotive_liability_required:
        sourceData?.automotive_liability_required || false,
    });
  }, [sourceData]);

  useEffect(() => {
    const { handleUpdateForm } = props;
    if (handleUpdateForm) {
      handleUpdateForm(formVal);
    }
  }, [formVal]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleSubmit() {
    handleFormSubmit();
  }

  async function handleFormSubmit() {
    const { onUpdate } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="auto-content">
      <div className="form-header">
        <div className="line-disable">
          <Switch
            checked={formVal.automotive_liability_required}
            onChange={(checked) =>
              handleChange("automotive_liability_required", checked)
            }
          />
          <span className="disable-title">Required</span>
        </div>
      </div>
      <div className="form-main">
        <div className="panel-left">
          <div className="checkbox-group-container">
            <h5 className="checkbox-group-title">AUTOMOBILE LIABLITY</h5>
            <div className="checkbox-group-content">
              <Checkbox
                checked={formVal.automotive_liability_any_auto}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_any_auto",
                    evt.target.checked
                  )
                }
              >
                Any Auto
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_all_owned_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_all_owned_autos",
                    evt.target.checked
                  )
                }
              >
                All Owned Autos
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_category_hired_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_category_hired_autos",
                    evt.target.checked
                  )
                }
              >
                Hired Autos
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_category_scheduled_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_category_scheduled_autos",
                    evt.target.checked
                  )
                }
              >
                Scheduled Autos
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_category_non_owned_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_category_non_owned_autos",
                    evt.target.checked
                  )
                }
              >
                Non-Owned Autos
              </Checkbox>
            </div>
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={formVal.automotive_policy_form_additional_insurance}
              onChange={(evt) =>
                handleChange(
                  "automotive_policy_form_additional_insurance",
                  evt.target.checked
                )
              }
            >
              Additional Insured
            </Checkbox>
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={formVal.automotive_policy_form_subrogratrion_waived}
              onChange={(evt) =>
                handleChange(
                  "automotive_policy_form_subrogratrion_waived",
                  evt.target.checked
                )
              }
            >
              Waiver Of Subrogation
            </Checkbox>
          </div>

          {/* <div>
            <h4>POLICY NUMBER: {formVal.automotive_liability_policy_number}</h4>
            <h4>
              EFFECTIVE DATE: {formVal.automotive_liability_effective_date}
            </h4>
            <h4>EXPIRATION DATE: {formVal.automotive_liability_expire_date}</h4>
          </div> */}
          <div className="line-item-container broker-report-container">
            <Textarea
              title="Notes from broker"
              type="text"
              placeholder="Please enter your text here."
              value={formVal.automotive_liability_broker_note}
              rows={10}
              onChange={(evt) =>
                handleChange(
                  "automotive_liability_broker_note",
                  evt.target.value
                )
              }
            />
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="COMBINED SINGLE LIMIT"
              subTitle="( Ea occurrence )"
              placeholder="COMBINED SINGLE LIMIT ( Ea occurrence)"
              value={formVal.automotive_liability_combined_single_limit}
              onChange={(evt) =>
                handleChange(
                  "automotive_liability_combined_single_limit",
                  evt.target.value
                )
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="Maximum Required Deductible"
              placeholder="Maximum Required Deductible"
              value={formVal.automotive_liability_bodily_injury_pp}
              onChange={(evt) =>
                handleChange(
                  "automotive_liability_bodily_injury_pp",
                  evt.target.value
                )
              }
            />
          </div>
        </div>
        {!formVal.automotive_liability_required && <FormMask />}
      </div>

      <div className="form-footer">
        {!readOnly && (
          <div className="button-container">
            <Button type="primary" onClick={handleSubmit}>
              {btnLabel || "Save"}
            </Button>
          </div>
        )}
      </div>
      <Loading loading={loading} />
      {readOnly && <ScreenMask />}
    </div>
  );
}
