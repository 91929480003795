import React, { useEffect, useState, useMemo } from "react";
import { Card, Loading, Textarea } from "../../../../components";
import { Button, notification, Input, AutoComplete, Select } from "antd";
import { useAppState } from "../../../../context";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../../utils/axios";
import axios from "axios";
import "./style.scss";
import ClassCode from "./class-code";
import CarrierAppetite from "./carrier-appetite";
import { get } from "../../../../utils/axios";
import { PERMISSION_TABLE, ACCESS_FULL } from "../../../../utils/permission";

const { Option } = Select;

const INIT_CODE = [
  {
    eleType: "NAICS",
    code: "",
    description: "",
    note: ""
  },
  {
    eleType: "SIC",
    code: "",
    description: "",
    note: ""
  },
  {
    eleType: "WC",
    code: "",
    description: "",
    note: ""
  }
];

const INIT_CARRIER = [
  {
    coverage: "Pkg, Garagekeepers",
    options: [
      {
        carrier: "Travelers",
        appetite: true,
        notes: "Traveler Desc"
      },
      {
        carrier: "Nationwide",
        appetite: true,
        notes: "Nationwide Desc"
      },
      {
        carrier: "Hartford",
        appetite: false,
        notes: "Hartford Desc"
      },
      {
        carrier: "CNA",
        appetite: false,
        notes: "CNA Desc"
      },
      {
        carrier: "Coterie",
        appetite: false,
        notes: "Coterie Desc"
      },
      {
        carrier: "Markel",
        appetite: false,
        notes: "Markel Desc"
      }
    ]
  },
  {
    coverage: "WC",
    options: [
      {
        carrier: "Great American",
        appetite: true,
        notes: "Great American Desc"
      },
      {
        carrier: "CNA",
        appetite: true,
        notes: "CNA Desc"
      },
      {
        carrier: "Nationwide",
        appetite: false,
        notes: "Nationwide Desc"
      },
      {
        carrier: "Markel",
        appetite: false,
        notes: "Markel Desc"
      },
      {
        carrier: "Hartford",
        appetite: false,
        notes: "Hartford Desc"
      },
      {
        carrier: "Pie",
        appetite: false,
        notes: "Pie Desc"
      },
      {
        carrier: "AmTrust",
        appetite: false,
        notes: "AmTrust Desc"
      },
      {
        carrier: "Travelers",
        appetite: false,
        notes: "Travelers Desc"
      },
      {
        carrier: "Employers",
        appetite: false,
        notes: "Employers Desc"
      }
    ]
  },
  {
    coverage: "Auto",
    options: [
      {
        carrier: "CNA",
        appetite: true,
        notes: "CNA Desc"
      },
      {
        carrier: "Nationwide",
        appetite: true,
        notes: "Nationwide Desc"
      },
      {
        carrier: "Hartford",
        appetite: false,
        notes: "Hartford Desc"
      },
      {
        carrier: "Travelers",
        appetite: false,
        notes: "Travelers Desc"
      }
    ]
  }
];

export default function InsuranceBundle({ franchisorData }) {
  const {
    franchisorAdmin,
    setFranchisorAdmin,
    codeList,
    setCodeList,
    authSetting
  } = useAppState();
  const { getAccessTokenSilently } = useAuth0();

  const [codeFormVal, setCodeFormVal] = useState(
    franchisorAdmin?.naics_classes ? franchisorAdmin.naics_classes : INIT_CODE
  );
  const [carrierFormVal, setCarrierFormVal] = useState(
    franchisorAdmin?.carrier_appetite
      ? franchisorAdmin.carrier_appetite
      : INIT_CARRIER
  );
  const [operationFormVal, setOperationFormVal] = useState({
    descOperation: "",
    disclaimer: ""
  });
  const [loading, setLoading] = useState(false);
  const [codeOptions, setCodeOptions] = useState([]);
  const [activeFranchisorIndex, setActiveFranchisorIndex] = useState(-1);

  useEffect(() => {
    if (!codeList) {
      handleLoadCodes();
    }
  }, []);

  const a = "";

  async function handleLoadCodes() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await get("admin/code", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const codeListData = result?.data?.data || [];
      setCodeList(codeListData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function handleChange(index, key, value) {
    codeFormVal[index][key] = value;
    setCodeFormVal([...codeFormVal]);
  }

  function handleChangeFranchisor(data) {
    setActiveFranchisorIndex(data);

    setCarrierFormVal(franchisorData[data].carrier_appetite || []);
  }

  function handleChangeCarrierOption(sIndex, oIndex, key, value) {
    carrierFormVal[sIndex].options[oIndex][key] = value;
    setCarrierFormVal([...carrierFormVal]);
  }

  function handleChangeCarrier(sIndex, key, value) {
    carrierFormVal[sIndex][key] = value;
    setCarrierFormVal([...carrierFormVal]);
  }

  function handleAddOption(index) {
    carrierFormVal[index].options.push({
      carrier: "",
      appetite: false,
      notes: ""
    });
    setCarrierFormVal([...carrierFormVal]);
  }

  function handleDeleteOption(index, optIndex) {
    carrierFormVal[index].options.splice(optIndex, 1);
    setCarrierFormVal([...carrierFormVal]);
  }

  function handleAddCarrier() {
    carrierFormVal.push({
      coverage: "",
      options: [
        {
          carrier: "",
          appetite: false,
          notes: ""
        }
      ]
    });
    setCarrierFormVal([...carrierFormVal]);
  }

  function handleDeleteCarrier(index) {
    carrierFormVal.splice(index, 1);
    setCarrierFormVal([...carrierFormVal]);
  }

  function handleAddCode() {
    codeFormVal.push({
      eleType: "NAICS",
      code: "",
      description: "",
      note: "",
      state: "NCCI"
    });
    setCodeFormVal([...codeFormVal]);
  }

  function handleDeleteCode(index) {
    codeFormVal.splice(index, 1);
    setCodeFormVal([...codeFormVal]);
  }

  async function handleSaveCode() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `requirement/${franchisorAdmin._id}`,
        { naics_classes: codeFormVal },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.data.success) {
        const requirement = result.data.data;
        setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
      }
      setLoading(false);
    } catch (error) {
      console.log("save code error - ", error);
      setLoading(false);
    }
  }

  async function handleSaveCarrier() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `requirement/${franchisorAdmin._id}`,
        { carrier_appetite: carrierFormVal },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.data.success) {
        const requirement = result.data.data;
        setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
      }
      setLoading(false);
    } catch (error) {
      console.log("save code error - ", error);
      setLoading(false);
    }
  }

  async function handleSaveOperation() {
    // setLoading(true);
    // try {
    //   const token = await getAccessTokenSilently();
    //   const result = await put(
    //     `requirement/${franchisorAdmin._id}`,
    //     { carrier_appetite: carrierFormVal },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   if (result.data.success) {
    //     const requirement = result.data.data;
    //     setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   console.log("save code error - ", error);
    //   setLoading(false);
    // }
  }

  function handleSelect(index, value) {
    const codeData = value.split(" - ");
    codeFormVal[index].code = codeData[0];
    codeFormVal[index].description = codeData[1];

    setCodeFormVal([...codeFormVal]);
  }

  function handleChangeOperation(key, value) {
    setOperationFormVal({
      ...operationFormVal,
      [key]: value
    });
  }

  const editable = useMemo(
    () =>
      PERMISSION_TABLE["NAICS_CLASSIFICATION"][authSetting.userPermission] ===
      ACCESS_FULL,
    [authSetting]
  );

  return (
    <div className="insurance-bundle-container">
      <Card title="NAICS CLASSIFICATIONS">
        <div className="insurance-bundle-container-section">
          <div className="insurance-bundle-container-section-header">
            <label className="insurance-bundle-container-section-header-label">
              Type
            </label>
            <label className="insurance-bundle-container-section-header-label">
              Code
            </label>
            <label className="insurance-bundle-container-section-header-label">
              Description
            </label>
            <label className="insurance-bundle-container-section-header-label">
              Notes
            </label>
          </div>
          {codeFormVal.map((item, index) => (
            <ClassCode
              typeVal={item.eleType}
              codeVal={item.code}
              descVal={item.description}
              noteVal={item.note}
              stateVal={item.state}
              setTypeVal={(val) => handleChange(index, "eleType", val)}
              setStateVal={(val) => handleChange(index, "state", val)}
              setNoteVal={(val) =>
                handleChange(index, "note", val.target.value)
              }
              setCodeVal={(val) => handleChange(index, "code", val)}
              setDescVal={(val) => handleChange(index, "description", val)}
              setSelect={(val) => handleSelect(index, val)}
              dataVal={item}
              onChange={(key, val) => handleChange(index, key, val)}
              onDelete={() => handleDeleteCode(index)}
            />
          ))}
        </div>
        <div className="insurance-bundle-container-footer">
          {editable && (
            <Button
              size="large"
              className="insurance-bundle-container-footer-add"
              type="default"
              onClick={() => handleAddCode()}
            >
              Add Code
            </Button>
          )}
          <Button size="large" type="primary" onClick={handleSaveCode}>
            Save
          </Button>
        </div>
      </Card>
      <Card title="DESCRIPTION OF OPERATIONS">
        <Textarea
          title="Description of Operations"
          type="text"
          placeholder="Please enter your text here."
          value={operationFormVal.descOperation}
          rows={10}
          onChange={(evt) =>
            handleChangeOperation("descOperation", evt.target.value)
          }
        />
        <Textarea
          title="Disclaimer"
          type="text"
          placeholder="Please enter your text here."
          value={operationFormVal.disclaimer}
          rows={4}
          onChange={(evt) =>
            handleChangeOperation("disclaimer", evt.target.value)
          }
        />
        <div className="footer-container">
          <Button type="primary" onClick={handleSaveOperation}>
            Save
          </Button>
        </div>
      </Card>
      <Card
        title="CARRIER APPETITE"
        extra={() => (
          <Select
            defaultValue={"Copy Carrier Appetite…"}
            style={{ width: 420 }}
            value={
              activeFranchisorIndex < 0
                ? "Copy Carrier Appetite…"
                : activeFranchisorIndex
            }
            onChange={handleChangeFranchisor}
          >
            {franchisorData &&
              franchisorData.map((item, index) => (
                <Option value={index}>
                  {item.personal_infor_business_name}
                </Option>
              ))}
          </Select>
        )}
      >
        {carrierFormVal.map((carrierItem, index) => (
          <CarrierAppetite
            coverage={carrierItem.coverage}
            carrierList={carrierItem.options}
            label={index === 0}
            onChangeOption={(oIndex, key, val) =>
              handleChangeCarrierOption(index, oIndex, key, val)
            }
            onChangeCarrier={(key, value) =>
              handleChangeCarrier(index, key, value)
            }
            editable={editable}
            onAddOption={() => handleAddOption(index)}
            onDeleteCarrier={() => handleDeleteCarrier(index)}
            onDeleteOption={(optionIndex) =>
              handleDeleteOption(index, optionIndex)
            }
          />
        ))}
      </Card>

      <div className="footer-container">
        {editable && (
          <Button
            size="large"
            className="insurance-bundle-container-footer-add"
            type="default"
            onClick={() => handleAddCarrier()}
          >
            Add Carrier
          </Button>
        )}
        <Button type="primary" onClick={handleSaveCarrier}>
          Save
        </Button>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
