import { Button } from "antd";
import React, { useState } from "react";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Input } from "../../../../../components";
import "./style.scss";

export default function CheckForm({ data, onSave, onCancel }) {
  const [title, setTitle] = useState(data?.title || "");

  return (
    <div className="check_form">
      <Input value={title} onChange={(evt) => setTitle(evt.target.value)} />
      <div className="check_form-action">
        <SaveOutlined
          className="check_form-action-save"
          onClick={() => onSave(title)}
        />
        <CloseCircleOutlined
          onClick={onCancel}
          className="check_form-action-cancel"
        />
      </div>
    </div>
  );
}
