import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Alert } from "antd";
import "./style.scss";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
} from "../../../../assets/const/form";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildGarageDirectPrimary,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldGarage } from "@utils/form-recog";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { formatToMonthDayYear } from "@utils/date-formatter";
import { formatDollarAmount } from "@utils/dollar-formatter";

export default function GarageForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.garage_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        garage_comp: finalData.garage_comp,
        garage_coll: finalData.garage_coll,
        garage_direct: finalData.garage_direct,
        garage_policy_number: finalData.garage_policy_number,
        garage_effective_date: finalData.garage_effective_date,
        garage_expire_date: finalData.garage_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldGarage(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

const garageSections = [
  {
    title: "Effective Date",
    valueFn: () =>
      buildEffectDate(
        franchiseeData?.finalData?.garage_effective_date,
        franchisorData?.garage_required
      ),
    formValue: formatToMonthDayYear(
      franchiseeData?.finalData?.garage_effective_date
    ),
  },
  {
    title: "Expiration Date",
    valueFn: () =>
      buildExpireDate(
        franchiseeData?.finalData?.garage_expire_date,
        franchisorData?.garage_required
      ),
    formValue: formatToMonthDayYear(
      franchiseeData?.finalData?.garage_expire_date
    ),
  },
  {
    title: "Garage Comp",
    valueFn: () =>
      buildValueGT(
        franchiseeData?.finalData?.garage_comp,
        franchisorData?.garage_comp,
        franchisorData?.garage_required
      ),
    fddRequirement: formatDollarAmount(franchisorData?.garage_comp),
    formValue: formatDollarAmount(franchiseeData?.finalData?.garage_comp),
  },
  {
    title: "Garage Coll",
    valueFn: () =>
      buildValueGT(
        franchiseeData?.finalData?.garage_coll,
        franchisorData?.garage_coll,
        franchisorData?.garage_required
      ),
    fddRequirement: formatDollarAmount(franchisorData?.garage_coll),
    formValue: formatDollarAmount(franchiseeData?.finalData?.garage_coll),
  },
  {
    title: "Garage Direct Primary",
    valueFn: () =>
      buildGarageDirectPrimary(
        franchiseeData?.finalData?.garage_direct,
        franchisorData?.garage_direct,
        franchisorData?.garage_required
      ),
    fddRequirement: franchisorData?.garage_direct ? "Yes" : "No",
    formValue: franchiseeData?.finalData?.garage_direct ? "Yes" : "No",
  },
];

const displayedSectionsCount = garageSections.filter(
  ({ title, valueFn, formValue }) => {
    const value = valueFn()?.[0]?.title;

    return (
      ((title !== "Effective Date" && title !== "Expiration Date") ||
        formValue ||
        (Array.isArray(valueFn?.()) && valueFn().length > 0)) &&
      (toggleState ? value : formValue || value)
    );
  }
).length;

  return (
    <div className="liability-table">
      {(() => {
        const hasInvalidExpirationDate = garageSections.some(
          ({ title, valueFn }) =>
            title === "Expiration Date" &&
            valueFn()?.[0]?.title === "Must expire after today's date"
        );

        const effectiveToggleState = hasInvalidExpirationDate
          ? false
          : toggleState;

        return (
          (!effectiveToggleState || displayedSectionsCount > 0) && (
            <div>
              <h2>Garage #{franchiseeData?.finalData?.garage_policy_number}</h2>
              {hasInvalidExpirationDate && (
                <div className="coverage-lapsed-error">
                  <Alert
                    message="Please Upload COI to Renew Lapsed Coverage"
                    type="error"
                    showIcon
                  ></Alert>
                </div>
              )}
              <div className="table-header">
                <span className="table-header-field">Field</span>
                <span className="table-header-fdd">Requirement</span>
                <span className="table-header-coverage">Your Coverage</span>
              </div>

              {garageSections.map(
                ({ title, valueFn, fddRequirement, formValue }) => {
                  const buildValue = valueFn()?.[0]?.title;

                  const isCoverageLapsed = hasInvalidExpirationDate;

                  const isExpiringSoon =
                    title === "Expiration Date" &&
                    buildValue === "Expiring in 15 days or less";

                  const displayValue = buildValue || formValue;

                  const shouldDisplayDates =
                    title === "Effective Date" || title === "Expiration Date"
                      ? !effectiveToggleState ||
                        isCoverageLapsed ||
                        isExpiringSoon ||
                        buildValue
                      : effectiveToggleState
                      ? buildValue
                      : fddRequirement && fddRequirement !== "";

                  if (!shouldDisplayDates) {
                    return null;
                  }

                  return (
                    <div key={title} className="table-row">
                      <div className="table-cell field-title">
                        <span>{title}</span>
                      </div>
                      <div className="table-cell fdd-requirement">
                        <span>{fddRequirement || ""}</span>
                      </div>
                      <div className="table-cell">
                        {isCoverageLapsed ? (
                          <span className="value-red">
                            <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                            {typeof displayValue === "boolean"
                              ? displayValue
                                ? "Selected"
                                : "Not Selected"
                              : displayValue}
                          </span>
                        ) : effectiveToggleState && isExpiringSoon ? (
                          buildValue ? (
                            <span className="value-yellow">
                              <WarningTwoTone twoToneColor={"#ffcc00"} />{" "}
                              {buildValue}
                            </span>
                          ) : null
                        ) : effectiveToggleState ? (
                          <span className="value-red">
                            <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                            {buildValue}
                          </span>
                        ) : isExpiringSoon ? (
                          <span className="value-yellow">
                            <WarningTwoTone twoToneColor={"#ffcc00"} />{" "}
                            {buildValue}
                          </span>
                        ) : buildValue ? (
                          <span className="value-red">
                            <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                            {buildValue}
                          </span>
                        ) : (
                          <span className="value-green">
                            <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                            {typeof displayValue === "boolean"
                              ? displayValue
                                ? "Selected"
                                : null
                              : displayValue}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          )
        );
      })()}
    </div>
  );
}
