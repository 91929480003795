import React from "react";
import "./style.scss";

export default function DeniedAccess() {
  return (
    <div className="denied_access">
      <div className="denied_access-panel">
        <h4 className="denied_access-panel-title">Access Denied!</h4>
        <p className="denied_access-panel-desc">
          Your permission is not allowed access this domain. Please contact
          admin!
        </p>
      </div>
    </div>
  );
}
