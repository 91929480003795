import React from "react";
import _ from "lodash";
import { Input, Card } from "@components";
import { Button, Select } from "antd";
import { CONSTRUCTION_TYPE_CODES } from "@assets/const/hartford";
import { DeleteOutlined, ProfileOutlined } from "@ant-design/icons";
import "./style.scss";

export default function BlockProperty({
  data,
  locationList,
  onChange,
  disabled
}) {
  function handleChangePropertyItem(index, key, value) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index][key] = value;
    onChange((prev) => newFormVal);
  }

  // function handleAddNewProperty(index) {
  //   const newFormVal = _.cloneDeep(data);
  //   newFormVal.push({
  //     locationIndex: null,
  //     totalArea: "",
  //     yearBuilt: "",
  //     constructionTypeCode: "",
  //     numberOfStories: "",
  //     includeBuildingCoverage: null,
  //     bppLimit: "",
  //     tenantImprovement: ""
  //   });
  //   onChange((prev) => newFormVal);
  // }

   function handleAddNewProperty() {
     const newFormVal = [
       ...data,
       {
         locationIndex: null,
         totalArea: "",
         yearBuilt: "",
         constructionTypeCode: "",
         numberOfStories: "",
         includeBuildingCoverage: null,
         bppLimit: "",
         tenantImprovement: "",
       },
     ];
     onChange(() => newFormVal);
   }

  function handleDeleteProperty(index) {
    if (data.length === 0) return;
    const newFormVal = _.cloneDeep(data);
    newFormVal.splice(index, 1);
    onChange((prev) => newFormVal);
  }

  function renderPropertyInfo(index, property) {
    return (
      <div key={`property-${index}`} className="block_pro-item">
        <div>
          <div className="block_pro-item-loc">
            <p className="input-title">
              Select A Physical Location (No Billing/Mailing Address)
            </p>
            <div className="property-con-item-loc-select">
              <Select
                size="large"
                style={{ minWidth: "300px" }}
                options={locationList}
                value={property.locationIndex}
                onSelect={(evt) =>
                  handleChangePropertyItem(index, "locationIndex", evt)
                }
              ></Select>
            </div>
          </div>
          <div className="form-group-three-container">
            <Input
              title="Total Square Feet"
              type="text"
              value={property.totalArea}
              required
              onChange={(evt) =>
                handleChangePropertyItem(index, "totalArea", evt.target.value)
              }
            />

            <Input
              title="Year Built"
              type="text"
              value={property.yearBuilt}
              inputType="TYPE_INPUT_YEAR"
              required
              onChange={(evt) =>
                handleChangePropertyItem(index, "yearBuilt", evt.target.value)
              }
            />
            <div className="custom-input-container">
              <p className="input-title">Construction Type Code</p>
              <Select
                options={CONSTRUCTION_TYPE_CODES}
                value={property?.constructionTypeCode}
                size="large"
                className="construction-type-select"
                onChange={(evt) =>
                  handleChangePropertyItem(index, "constructionTypeCode", evt)
                }
              ></Select>
            </div>
          </div>
          <div className="form-group-three-container">
            <Input
              title="Number of Stories"
              type="number"
              value={property.numberOfStories}
              required
              onChange={(evt) =>
                handleChangePropertyItem(
                  index,
                  "numberOfStories",
                  evt.target.value
                )
              }
            />
            <Input
              title="Equipment, Stock, Inventory, Furniture Value"
              value={property.bppLimit}
              required
              onChange={(value) =>
                handleChangePropertyItem(index, "bppLimit", value)
              }
              size="large"
              inputType="TYPE_INPUT_CURRENCY"
              style={{ width: "100%" }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
            />
            <Input
              title="Tenant Improvements"
              value={property.tenantImprovement}
              required
              onChange={(value) =>
                handleChangePropertyItem(index, "tenantImprovement", value)
              }
              size="large"
              inputType="TYPE_INPUT_CURRENCY"
              style={{ width: "100%" }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
            />
          </div>
        </div>
        <div className="block_pro-item-action">
          {!disabled && (
            <Button
              className="block_pro-item-action-btn"
              onClick={() => handleDeleteProperty(index)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="block_pro">
      {data.length === 0 && handleAddNewProperty()}
      {data.map((property, index) => renderPropertyInfo(index, property))}
      {!disabled && (
        <div className="admin_franchisee_under-section-footer">
          <Button
            className="admin_franchisee_under-section-footer-link"
            onClick={handleAddNewProperty}
          >
            <ProfileOutlined />
            Add Property
          </Button>
        </div>
      )}
    </div>
  );
}
