import React from "react";
import { Button, Checkbox, Row, Col, Breadcrumb, Card } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Input } from "../../../components";
import { post, put } from "../../../utils/axios";
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";

export default function AffiliateForm() {
  const location = useLocation();
  const affiliate = location?.state?.affiliate;
  const editing = !!affiliate;
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState(
    _.assign(
      {
        name: "",
        dba: "",
        phone: "",
        street_address_1: "",
        street_address_2: "",
        city: "",
        state: "",
        zipcode: "",
        email: "",
        fee: 0,
        revenue: 0,
        active: true,
        contact: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          position: "",
        },
      },
      affiliate || {}
    )
  );

  const handleChange = (field) => (e) =>
    setValues((prev) => ({ ...prev, [field]: e.target.value }));

  const handleCheckbox = (field) => (e) =>
    setValues((prev) => ({ ...prev, [field]: e.target.checked }));

  const handleContactChange = (field) => (e) =>
    setValues((prev) => ({
      ...prev,
      contact: {
        ...prev.contact,
        [field]: e.target.value,
      },
    }));

  async function _create(token) {
    return await post(
      `affiliates`,
      {
        ...values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  async function _update(token) {
    return await put(
      `affiliates/${affiliate._id}`,
      {
        ...values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  async function save() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const _save = editing ? _update : _create;
      const result = await _save(token);
      history.push(`/affiliates/${result.data.data._id}`);
    } catch (err) {
      console.error("error saving affiliate");
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="affiliates-container">
      <Breadcrumb className="breadcrumbs">
        <Breadcrumb.Item>
          <Link to="/affiliates/overview">Affiliates</Link>
        </Breadcrumb.Item>
        {editing && (
          <Breadcrumb.Item>
            <Link to={`/affiliates/${affiliate._id}`}>
              {affiliate?.name || "Detail"}
            </Link>
          </Breadcrumb.Item>
        )}
        <Breadcrumb.Item>{editing ? "Edit" : "New"} Affiliate</Breadcrumb.Item>
      </Breadcrumb>
      <Card
        title={
          editing
            ? affiliate?.name
              ? `Edit ${affiliate?.name}`
              : "Edit Affiliate"
            : "New Affiliate"
        }
        extra={
          <Button type="primary" onClick={save} disabled={loading}>
            Save
          </Button>
        }
      >
        <Row justify="space-evenly">
          <Col xs={16} md={8}>
            <Input
              title="Name"
              type="text"
              value={values.name}
              onChange={handleChange("name")}
              disabled={loading}
            />
            <Input
              title="DBA"
              type="text"
              value={values.dba}
              onChange={handleChange("dba")}
              disabled={loading}
            />
            <Input
              title="Phone"
              type="text"
              value={values.phone}
              onChange={handleChange("phone")}
              disabled={loading}
            />
            <Input
              title="Email"
              type="text"
              value={values.email}
              onChange={handleChange("email")}
              disabled={loading}
            />
            <Input
              title="Affiliate Marketing Fee"
              type="number"
              description="Please enter a decimal number (e.g. for 5%, enter 0.5)"
              value={values.fee}
              onChange={handleChange("fee")}
              disabled={loading}
            />
            <Input
              title="Affiliate Marketing Revenue"
              type="number"
              value={values.revenue}
              onChange={handleChange("revenue")}
              disabled={loading}
            />
          </Col>
          <Col xs={16} md={8}>
            <Input
              title="Street Address 1"
              type="text"
              value={values.street_address_1}
              onChange={handleChange("street_address_1")}
              disabled={loading}
            />
            <Input
              title="Street Address 2"
              type="text"
              value={values.street_address_2}
              onChange={handleChange("street_address_2")}
              disabled={loading}
            />
            <Input
              title="City"
              type="text"
              value={values.city}
              onChange={handleChange("city")}
              disabled={loading}
            />
            <Input
              title="State"
              type="text"
              value={values.state}
              onChange={handleChange("state")}
              disabled={loading}
            />
            <Input
              title="Zipcode"
              type="text"
              value={values.zipcode}
              onChange={handleChange("zipcode")}
              disabled={loading}
            />
            <Checkbox
              checked={values.active}
              onChange={handleCheckbox("active")}
            >
              Active
            </Checkbox>
          </Col>
        </Row>

        <Row justify="space-evenly">
          <Col xs={16} md={8}>
            <h2 style={{ marginTop: 30 }}>Contact Person</h2>
            <Input
              title="First Name"
              type="text"
              value={values.contact.firstName}
              onChange={handleContactChange("firstName")}
              disabled={loading}
            />
            <Input
              title="Last Name"
              type="text"
              value={values.contact.lastName}
              onChange={handleContactChange("lastName")}
              disabled={loading}
            />
            <Input
              title="Email"
              type="text"
              value={values.contact.email}
              onChange={handleContactChange("email")}
              disabled={loading}
            />
            <Input
              title="Phone"
              type="text"
              value={values.contact.phone}
              onChange={handleContactChange("phone")}
              disabled={loading}
            />
            <Input
              title="Position"
              type="text"
              value={values.contact.position}
              onChange={handleContactChange("position")}
              disabled={loading}
            />
          </Col>
          <Col xs={16} md={8}></Col>
        </Row>
      </Card>
    </div>
  );
}
