import { DatePicker } from "antd";
import "./style.scss";
import { useState } from "react";
import dayjs from "dayjs";
import { addDays, addYears } from "date-fns";
import { APP_DATE_FORMAT } from "@assets/const/form";

const EXPIRE_BEFORE_EFFECTIVE_ERR_MSG =
  "Effective date must start before expiration date.";

export default function FormEffectiveExpireDates({
  effectiveDate,
  expirationDate,
  onEffectiveDateChange,
  onExpirationDateChange,
}) {
  const [error, setError] = useState("");

  function handleEffectiveDateChange(_, dateString) {
    let err = "";
    const effective = new Date(dateString.replace(/-/g, "/"));
    if (expirationDate) {
      const expiration = new Date(expirationDate.replace(/-/g, "/"));
      err = effective > expiration ? EXPIRE_BEFORE_EFFECTIVE_ERR_MSG : "";
      setError(err);
    }

    const autoFillExpiration = addDays(addYears(effective, 1), 1);
    onEffectiveDateChange(dateString, err);
    onExpirationDateChange(
      dayjs(autoFillExpiration, "YYYY-MM-DD").format(),
      err
    );
  }

  function handleExpirationDateChange(_, dateString) {
    const expiration = new Date(dateString.replace(/-/g, "/"));
    let err = "";
    if (effectiveDate) {
      const effective = new Date(effectiveDate.replace(/-/g, "/"));
      err = effective > expiration ? EXPIRE_BEFORE_EFFECTIVE_ERR_MSG : "";
      setError(err);
    }
    onExpirationDateChange(dateString, err);
  }

  return (
    <div>
      {error ? (
        <p className="input-title">
          <span className="input-required">
            {EXPIRE_BEFORE_EFFECTIVE_ERR_MSG}
          </span>
        </p>
      ) : (
        <></>
      )}

      <div className="effective-expire-form form-group-two-container">
        <div className="form-group-two-container">
          <div className="line-item-container">
            <p className="input-title">
              Effective Date <span className="input-required">*</span>
            </p>
            <DatePicker
              format={APP_DATE_FORMAT}
              value={effectiveDate ? dayjs(effectiveDate) : undefined}
              status={error ? "error" : ""}
              style={{ width: "100%" }}
              size="large"
              onChange={handleEffectiveDateChange}
            ></DatePicker>
          </div>
          <div className="line-item-container">
            <p className="input-title">
              Expiration Date <span className="input-required">*</span>
            </p>
            <DatePicker
              format={APP_DATE_FORMAT}
              value={expirationDate ? dayjs(expirationDate) : undefined}
              status={error ? "error" : ""}
              style={{ width: "100%" }}
              size="large"
              onChange={handleExpirationDateChange}
            ></DatePicker>
          </div>
        </div>
      </div>
    </div>
  );
}
