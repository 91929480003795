import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  Input,
  Loading,
  FormMask,
  Textarea,
  ScreenMask,
} from "../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../utils/axios";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
  SOURCE_ONBOARDING,
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  FORM_TYPE_SPECIAL,
  FORM_TYPE_BROAD,
  FORM_TYPE_BASIC,
  FORM_LOSS_RECOVERY_TYPE_COST,
  FORM_LOSS_RECOVERY_TYPE_CASH,
} from "../../../assets/const/form";
import { useAppState } from "@context/index";

export default function ProInsForm(props) {
  const { data, btnLabel, source, onNext, readOnly = false } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;

  const [formVal, setFormVal] = useState({
    pro_ins_required: sourceData?.pro_ins_required || false,
    pro_ins_is_bus_per_pro: sourceData?.pro_ins_is_bus_per_pro || false,
    pro_ins_bus_per_pro: sourceData?.pro_ins_bus_per_pro || "",
    pro_ins_is_ten_imp: sourceData?.pro_ins_is_ten_imp || false,
    pro_ins_ten_imp: sourceData?.pro_ins_ten_imp || "",
    pro_ins_is_bui_amo: sourceData?.pro_ins_is_bui_amo || false,
    pro_ins_bui_amo: sourceData?.pro_ins_bui_amo || "",
    pro_ins_is_bus_int: sourceData?.pro_ins_is_bus_int || false,
    pro_ins_bus_int: sourceData?.pro_ins_bus_int || "",
    pro_ins_is_act_los: sourceData?.pro_ins_is_act_los || false,
    pro_ins_act_los: sourceData?.pro_ins_act_los || "",
    pro_ins_is_mon_lim: sourceData?.pro_ins_is_mon_lim || false,
    pro_ins_mon_lim: sourceData?.pro_ins_mon_lim || "",
    pro_ins_is_co_ins: sourceData?.pro_ins_is_co_ins || false,
    pro_ins_co_ins: sourceData?.pro_ins_co_ins || "",
    pro_ins_is_agg_val: sourceData?.pro_ins_is_agg_val || false,
    pro_ins_agg_val: sourceData?.pro_ins_agg_val || "",
    pro_ins_form_type: sourceData?.pro_ins_form_type || "",
    pro_ins_los_rec_type: sourceData?.pro_ins_los_rec_type || "",
    pro_ins_broker_note: sourceData?.pro_ins_broker_note || "",
  });

  useEffect(() => {
    setFormVal({
      pro_ins_required: sourceData?.pro_ins_required || false,
      pro_ins_is_bus_per_pro: sourceData?.pro_ins_is_bus_per_pro || false,
      pro_ins_bus_per_pro: sourceData?.pro_ins_bus_per_pro || "",
      pro_ins_is_ten_imp: sourceData?.pro_ins_is_ten_imp || false,
      pro_ins_ten_imp: sourceData?.pro_ins_ten_imp || "",
      pro_ins_is_bui_amo: sourceData?.pro_ins_is_bui_amo || false,
      pro_ins_bui_amo: sourceData?.pro_ins_bui_amo || "",
      pro_ins_is_bus_int: sourceData?.pro_ins_is_bus_int || false,
      pro_ins_bus_int: sourceData?.pro_ins_bus_int || "",
      pro_ins_is_act_los: sourceData?.pro_ins_is_act_los || false,
      pro_ins_act_los: sourceData?.pro_ins_act_los || "",
      pro_ins_is_mon_lim: sourceData?.pro_ins_is_mon_lim || false,
      pro_ins_mon_lim: sourceData?.pro_ins_mon_lim || "",
      pro_ins_is_co_ins: sourceData?.pro_ins_is_co_ins || false,
      pro_ins_co_ins: sourceData?.pro_ins_co_ins || "",
      pro_ins_is_agg_val: sourceData?.pro_ins_is_agg_val || false,
      pro_ins_agg_val: sourceData?.pro_ins_agg_val || "",
      pro_ins_form_type: sourceData?.pro_ins_form_type || "",
      pro_ins_los_rec_type: sourceData?.pro_ins_los_rec_type || "",
      pro_ins_broker_note: sourceData?.pro_ins_broker_note || "",
    });
  }, [sourceData]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleSubmit() {
    handleFormSubmit();
  }

  async function handleFormSubmit() {
    const { onUpdate } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="pro-ins-content">
      <div className="form-header">
        <div className="line-disable">
          <Switch
            checked={formVal.pro_ins_required}
            onChange={(checked) => handleChange("pro_ins_required", checked)}
          />
          <span className="disable-title">Required</span>
        </div>
      </div>
      <div className="form-main">
        <div className="panel-left">
          <div className="form-group-two-container checkbox-container">
            <div>
              <h5 className="checkbox-title">Included Coverages</h5>
              <div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_bus_per_pro}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_bus_per_pro", evt.target.checked)
                    }
                  >
                    Business Personal Property
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_ten_imp}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_ten_imp", evt.target.checked)
                    }
                  >
                    Tenant Improvement
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_bui_amo}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_bui_amo", evt.target.checked)
                    }
                  >
                    Building
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_bus_int}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_bus_int", evt.target.checked)
                    }
                  >
                    Business Interruption
                  </Checkbox>
                </div>
              </div>
            </div>
            <div>
              <h5 className="checkbox-title">Business Interruption</h5>
              <div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_act_los}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_act_los", evt.target.checked)
                    }
                  >
                    Actual Loss Sustained
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_mon_lim}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_mon_lim", evt.target.checked)
                    }
                  >
                    Monthly Limitation
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_co_ins}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_co_ins", evt.target.checked)
                    }
                  >
                    Co-Insurance
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_agg_val}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_agg_val", evt.target.checked)
                    }
                  >
                    Agreed Value
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
          <div className="radio-container">
            <h5 className="radio-title">FORM TYPE</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("pro_ins_form_type", evt.target.value)
              }
              value={formVal.pro_ins_form_type}
            >
              <Radio value={FORM_TYPE_SPECIAL}>Special</Radio>
              <Radio value={FORM_TYPE_BROAD}>Broad</Radio>
              <Radio value={FORM_TYPE_BASIC}>Basic</Radio>
            </Radio.Group>
          </div>
          <div className="radio-container">
            <h5 className="radio-title">LOSS RECOVERY TYPE</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("pro_ins_los_rec_type", evt.target.value)
              }
              value={formVal.pro_ins_los_rec_type}
            >
              <Radio value={FORM_LOSS_RECOVERY_TYPE_COST}>
                Replacement Cost
              </Radio>
              <Radio value={FORM_LOSS_RECOVERY_TYPE_CASH}>
                Actual Cash Value
              </Radio>
            </Radio.Group>
          </div>
          <div className="line-item-container broker-report-container">
            <Textarea
              title="Notes from broker"
              type="text"
              placeholder="Please enter your text here."
              value={formVal.pro_ins_broker_note}
              rows={10}
              onChange={(evt) =>
                handleChange("pro_ins_broker_note", evt.target.value)
              }
            />
          </div>
        </div>
        <div className="panel-right">
          {formVal.pro_ins_is_bus_per_pro && (
            <div className="line-item-container">
              <Input
                title="BUSINESS PERSONAL PROPERTY"
                type="number"
                placeholder="BUSINESS PERSONAL PROPERTY"
                value={formVal.pro_ins_bus_per_pro}
                onChange={(evt) =>
                  handleChange("pro_ins_bus_per_pro", evt.target.value)
                }
              />
            </div>
          )}
          {formVal.pro_ins_is_ten_imp && (
            <div className="line-item-container">
              <Input
                title="TENANT IMPROVEMENTS"
                type="number"
                placeholder="TENANT IMPROVEMENTS"
                value={formVal.pro_ins_ten_imp}
                onChange={(evt) =>
                  handleChange("pro_ins_ten_imp", evt.target.value)
                }
              />
            </div>
          )}

          {formVal.pro_ins_is_bui_amo && (
            <div className="line-item-container">
              <Input
                title="BUILDING AMOUNT"
                type="number"
                placeholder="BUILDING AMOUNT"
                value={formVal.pro_ins_bui_amo}
                onChange={(evt) =>
                  handleChange("pro_ins_bui_amo", evt.target.value)
                }
              />
            </div>
          )}

          {formVal.pro_ins_is_bus_int && (
            <div className="line-item-container">
              <Input
                title="BUSINESS INTERRUPTION"
                type="number"
                placeholder="BUSINESS INTERRUPTION"
                value={formVal.pro_ins_bus_int}
                onChange={(evt) =>
                  handleChange("pro_ins_bus_int", evt.target.value)
                }
              />
            </div>
          )}
          {formVal.pro_ins_is_act_los && (
            <div className="line-item-container">
              <Input
                title="ACTUAL LOSS SUSTAINED"
                type="number"
                placeholder="ACTUAL LOSS SUSTAINED"
                value={formVal.pro_ins_act_los}
                onChange={(evt) =>
                  handleChange("pro_ins_act_los", evt.target.value)
                }
              />
            </div>
          )}
          {formVal.pro_ins_is_mon_lim && (
            <div className="line-item-container">
              <Input
                title="MONTHLY LIMITATION"
                type="number"
                placeholder="MONTHLY LIMITATION"
                value={formVal.pro_ins_mon_lim}
                onChange={(evt) =>
                  handleChange("pro_ins_mon_lim", evt.target.value)
                }
              />
            </div>
          )}
          {formVal.pro_ins_is_co_ins && (
            <div className="line-item-container">
              <Input
                title="CO-INSURANCE"
                type="number"
                placeholder="CO-INSURANCE"
                value={formVal.pro_ins_co_ins}
                onChange={(evt) =>
                  handleChange("pro_ins_co_ins", evt.target.value)
                }
              />
            </div>
          )}

          {formVal.pro_ins_is_agg_val && (
            <div className="line-item-container">
              <Input
                title="AGREED VALUE"
                type="number"
                placeholder="AGREED VALUE"
                value={formVal.pro_ins_agg_val}
                onChange={(evt) =>
                  handleChange("pro_ins_agg_val", evt.target.value)
                }
              />
            </div>
          )}
        </div>
        {!formVal.pro_ins_required && <FormMask />}
      </div>
      <div className="form-footer">
        {!readOnly && (
          <div className="button-container">
            <Button type="primary" onClick={handleSubmit}>
              {btnLabel || "Save"}
            </Button>
          </div>
        )}
      </div>
      <Loading loading={loading} />
      {readOnly && <ScreenMask />}
    </div>
  );
}
