import React, { useMemo, useState } from "react";
import { Radio } from "antd";
import { BlockEquipment, BlockUnEquipment } from "@components/underwriting";
import Card from "@components/card";

export default function QuoteEquipmentBlock({
  equipData,
  onChangeEquipData,
  unEquipData,
  onChangeUnEquipData,
  locData
}) {
  const [isEquip, setIsEquip] = useState(false);

  const locationList = useMemo(() => {
    return (locData || []).map((item, index) => ({
      value: index,
      label: `${item.address}, ${item.city}, ${item.state}, ${item.zip}`
    }));
  }, [locData]);

  return (
    <div className="qu_equip">
      <h4>Do you have a scheduled equipment, unscheduled equipment?</h4>
      <Radio.Group
        value={isEquip}
        onChange={(evt) => setIsEquip(evt?.target?.value)}
      >
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>
      {isEquip && (
        <>
          <Card className="qu_equip-block" title="Scheduled Equipment">
            <BlockEquipment
              locationList={locationList}
              data={equipData}
              onChange={onChangeEquipData}
            />
          </Card>
          <Card className="qu-equip-block" title="Unscheduled Equipment">
            <BlockUnEquipment
              locationList={locationList}
              data={unEquipData}
              onChange={onChangeUnEquipData}
            />
          </Card>
        </>
      )}
    </div>
  );
}
