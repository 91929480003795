import TemplateForm from "../template-form";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { Input } from "@components/";
import { useAuth0 } from "@auth0/auth0-react";
import { post } from "@utils/axios";
import "./style.scss";

const STEP_TITLE = "STEP_TITLE";
const STEP_CONTENT = "STEP_CONTENT";
export default function TemplateCreate({ status, onCancel }) {
  const [step, setStep] = useState(STEP_TITLE);
  const [title, setTitle] = useState("");
  const [requirement, setRequirement] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // Reset Modal
    setStep(STEP_TITLE);
    setTitle("");
  }, [status]);

  async function handleCreate() {
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `requirement-template`,
        {
          title: title,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result?.data?.success) {
        setRequirement(result?.data?.data);
        setStep(STEP_CONTENT);
      }
    } catch (error) {}
  }
  return (
    <div className="template_create">
      {step === STEP_TITLE && (
        <div className="template_create-wrapper">
          <Input
            title="Title"
            type="text"
            size="large"
            value={title}
            onChange={(evt) => setTitle(evt.target.value)}
          />

          <div className="template_create-wrapper-footer">
            <Button
              size="large"
              type="primary"
              className="template_create-wrapper-footer-create"
              onClick={handleCreate}
            >
              Create
            </Button>
            <Button size="large" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      {step === STEP_CONTENT && <TemplateForm requirement={requirement} />}
    </div>
  );
}
