function getAddressItem(addressComponents, type, field = "short_name") {
  for (const addressItem of addressComponents) {
    for (const typeItem of addressItem.types) {
      if (typeItem === type) {
        return addressItem[field];
      }
    }
  }

  return "";
}

export { getAddressItem };
