import React, { useEffect, useState } from "react";
import { DatePicker, Statistic } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import "./style.scss";
import { Card } from "../../../components";
import { Doughnut, Bar, defaults } from "react-chartjs-2";
import moment from "moment";
import { post } from "../../../utils/axios";
import { useAppState } from "../../../context";
import { getHostTag } from "../../../utils/host";
import { Scatter } from "@ant-design/charts";
import { Treemap, Pie } from "@ant-design/plots";
import { APP_DATE_FORMAT } from "@assets/const/form";

const { RangePicker } = DatePicker;

export default function AdminOverview() {
  const [loading, setLoading] = useState(false);
  const [loadingScore, setLoadScore] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { franchisorAdmin } = useAppState();
  const [reportScore, setReportScore] = useState([]);
  const [reportBrand, setReportBrand] = useState({
    countFranchisor: 0,
    countFranchisee: 0,
    brandList: []
  });
  const [report, setReport] = useState({
    countFranchisor: 0,
    countFranchisee: 0,
    countCoi: 0,
    countExpired: 0,
    countCompliant: 0,
    countNonCompliant: 0,
    countPending: 0,
    countUnderDevelopment: 0
  });
  const [dateRange, setDateRange] = useState({
    startDay: "2020-01-01",
    endDay: moment().add(1, "day").format("YYYY-MM-DD")
  });

  useEffect(() => {
    handleFetchReport();
    handleFetchReportScore();
    handleFetchReportBrand();
  }, [dateRange, franchisorAdmin]);

  async function handleFetchReportScore() {
    setLoadScore(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/report-score`,
        {
          startDay: dateRange.startDay,
          endDay: dateRange.endDay,
          franchisorId: franchisorAdmin?._id,
          host: getHostTag()
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (result?.data?.data) {
        setReportScore(result?.data?.data);
      }
      setLoadScore(false);
    } catch (error) {
      setLoadScore(false);
    }
  }

  async function handleFetchReportBrand() {
    setLoadScore(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/report-brand`,
        {
          franchisorId: franchisorAdmin?._id,
          host: getHostTag()
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result?.data?.requirements) {
        setReportBrand({
          countFranchisor: result?.data?.countFranchisor || 0,
          countFranchisee: result?.data?.countFranchisee || 0,
          countCustomer: result?.data?.countCustomer || 0,
          brandList: result?.data?.requirements.map((item) => {
            return {
              name: item.personal_infor_business_name,
              value: item.franchisees
            };
          })
        });
      }
      setLoadScore(false);
    } catch (error) {
      setLoadScore(false);
    }
  }

  async function handleFetchReport() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/report`,
        {
          startDay: dateRange.startDay,
          endDay: dateRange.endDay,
          franchisorId: franchisorAdmin?._id,
          host: getHostTag()
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.status === 200) {
        const data = result.data;
        setReport({
          countFranchisor: data.franchisor[0]?.total[0]?.count || 0,
          countFranchisee: data.franchisee[0]?.total[0]?.count || 0,
          countCoi: data.franchisee[0]?.coi[0]?.count || 0,
          countExpired: data.expired[0]?.count || 0,
          countCompliant: data.franchisee[0]?.compliant[0]?.count || 0,
          countNonCompliant: data.franchisee[0]?.nonCompliant[0]?.count || 0,
          countPending: data.franchisee[0]?.pending[0]?.count || 0,
          countUnderDevelopment:
            data.franchisee[0]?.underDevelopment[0]?.count || 0
        });
      }
    } catch (err) {}
  }

  function handleDateFilter(date) {
    setDateRange({
      startDay: date[0].format("YYYY-MM-DD"),
      endDay: date[1].format("YYYY-MM-DD")
    });
  }

  function renderFilter() {
    return (
      <RangePicker
        format={APP_DATE_FORMAT}
        onChange={handleDateFilter}
        defaultValue={[moment(dateRange.startDay), moment(dateRange.endDay)]}
      />
    );
  }

  const requestData = [
    {
      type: "COI Document Uploaded",
      value: report.countCoi,
      backgroundColor: "#888"
    },
    {
      type: "Pending Document Upload",
      value: reportBrand?.countFranchisee - report.countCoi,
      backgroundColor: "#503678"
    }
  ];

  const analysisConfig = {
    appendPadding: 30,
    data: reportScore,
    xField: "count",
    yField: "score",
    colorField: "franchisorName",
    size: 5,
    shape: "circle",
    pointStyle: {
      fillOpacity: 1
    },
    yAxis: {
      nice: true,
      line: {
        style: {
          stroke: "#aaa"
        }
      }
    },
    xAxis: {
      grid: {
        line: {
          style: {
            stroke: "#eee"
          }
        }
      },
      line: {
        style: {
          stroke: "#aaa"
        }
      }
    },
    label: {}
  };

  const growthData = {
    name: "Undefined",
    children: reportBrand.brandList
  };
  const growthConfig = {
    data: growthData,
    colorField: "name"
  };

  const requesConfig = {
    appendPadding: 10,
    data: requestData,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 10
      }
    },
    interactions: [
      {
        type: "element-selected"
      },
      {
        type: "element-active"
      }
    ],
    legend: {
      position: "bottom"
    },
    color: ["#c93e86", "#4c3a71"],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "overflow",
          textOverflow: "ellipsis"
        },
        content: ""
      }
    }
  };

  return (
    <div className="admin_overview">
      <Card title="Franchise Analysis">
        <Scatter {...analysisConfig} height={800} />
      </Card>
      <Card title="Franchisee Growth">
        <div className="admin_overview_report">
          <Statistic
            title="Total Franchisors"
            value={reportBrand?.countFranchisor}
          />
          <Statistic
            title="Total Franchisees"
            value={reportBrand?.countFranchisee}
          />
          <Statistic
            title="Total Rikor Customers"
            value={reportBrand?.countCustomer}
          />
        </div>
        <Treemap {...growthConfig} height={800} />
      </Card>
      <Card title="COI Verification" extra={renderFilter}>
        <div className="admin_overview_wrapper">
          <Pie {...requesConfig} />
        </div>
      </Card>
    </div>
  );
}
