import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CheckOutlined,
  StopOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Table, Button, Modal, Input, Tooltip, notification } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { Loading, OriginalRecordForm } from "../../../components";
import { useParams, useHistory, useLocation } from "react-router-dom";
import "./style.scss";
import { post, get, put, postDoc } from "@utils/axios";
import iconRikorLogo from "../../../assets/images/rikor-logo.svg";
import paperSend from "../../../assets/images/paper.png";
import LocationBlock from "../../admin-board/franchisee-detail/under-writing/location-block";
import _, { result } from "lodash";
import UploadForm from "./upload-form";
import moment from "moment";
import FranchiseeReport from "../../admin-board/franchisee-report";
import PortalLink from "../../admin-board/franchisee-detail/portal-link";

export default function FranchiseeUpload() {
  const param = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [calendarLink, setCalendarLink] = useState(null);
  const [compliance, setCompliance] = useState([]);
  const [franchisorLogoUri, setFranchisorLogoUri] = useState("");
  const [location, setLocation] = useState(0);
  const [recipientName, setRecipientName] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 992);

  // Modal state and functions
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    handleLoadContact();
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function handleLoadContact() {
    setLoading(true);
    try {
      const result = await get(`contact/${param.id}/entity`);
      setFranchisorLogoUri(result?.data?.franchisorLogoUri || "");
      setEntityData(result?.data?.recordData);
      setLocationList(result?.data?.locationList);
      setCalendarLink(result?.data?.link);
      setCompliance(result?.data?.compliance);
    } catch (error) {
      console.log("load contact error", error);
    }
    setLoading(false);
  }

  function handleCopyLink(link) {
    navigator.clipboard.writeText(link);
    notification.open({
      message: "Public Portal Link was copied",
      description: "",
      duration: 3,
    });
  }

  const locationURL = useLocation();
  const portalURL = `${window.location.origin}${locationURL.pathname}`;

  async function handleSendEmail() {
    if (!recipientName || !recipientEmail) {
      alert("Please provide both name and email.");
      return;
    }

    try {
      setLoading(true);
      console.log("in try");
      const response = await fetch(`/send-portal-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ recipientName, recipientEmail, portalURL }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to send email");
      }

      alert("Request successful: " + data.msg);
    } catch (error) {
      console.error("Error sending request:", error);
      alert("Error sending request.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="franchisee-upload">
      <div className="franchisee-upload-flex-container">
        <div className="franchisee-upload-coi">
          <div className="card card-section">
            <div className="card-content">
              <UploadForm franchiseeId={param.id} setLoading={setLoading} />
              {/* <div className="forward-request-link" onClick={showModal}>
              <Button type="primary" onClick={showModal}>
                <img src={paperSend}></img> Forward Request
              </Button>
            </div>
            <p>
              This will be sent to your agent once to be processed. If
              they fail to process within 3 days another request will be
              sent to you. If you have different agents for different
              coverages, please forward accordingly.
            </p> */}
            </div>
            <div className="card-footer"></div>
          </div>
        </div>
      </div>

      {/* Modal for Forward Request */}
      <Modal
        title="Forward Request"
        open={isModalOpen}
        onOk={handleSendEmail}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={600}
      >
        <p>
          This request can be forwarded to third parties such as your insurance
          agent or broker.
          <br />
          Please specify a recipient using the fields below:
        </p>
        <div style={{ marginTop: "16px" }}>
          <div style={{ marginBottom: "12px" }}>
            <label htmlFor="recipientName">Recipient Name</label>
            <input
              id="recipientName"
              type="text"
              placeholder="Enter recipient's name"
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
              style={{
                display: "block",
                width: "90%",
                padding: "8px",
                marginTop: "4px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
              }}
            />
          </div>
          <div style={{ marginBottom: "12px" }}>
            <label htmlFor="recipientEmail">Recipient Email</label>
            <input
              id="recipientEmail"
              type="email"
              placeholder="Enter recipient's email"
              value={recipientEmail}
              onChange={(e) => setRecipientEmail(e.target.value)}
              style={{
                display: "block",
                width: "90%",
                padding: "8px",
                marginTop: "4px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
              }}
            />
          </div>
          <div style={{ marginTop: "12px" }}>
            <label>Portal Link</label>
            <Input
              id="portalLink"
              size="large"
              disabled
              value={portalURL}
              style={{ width: "95%" }}
              suffix={
                <Tooltip title="Copy">
                  <CopyOutlined
                    style={{ color: "", cursor: "pointer" }}
                    onClick={() => handleCopyLink(portalURL)}
                  />
                </Tooltip>
              }
            />
          </div>
        </div>
      </Modal>

      <Loading loading={loading} />
    </div>
  );
}
