import React, { useEffect, useState } from "react";
import { Form, Alert, Button, Input as AntInput } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "@components";
import "./style.scss";
import { post, put } from "@utils/axios";
import { useAppState } from "@context";
import { USER_TYPE_FRANCHISOR } from "@assets/const/status";
import InputLabel from "@components/input-label";

export default function UserForm(props) {
  const [form] = Form.useForm();
  const { franchisorAdmin } = useAppState();
  const [error, setError] = useState(null);

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.data) {
      form.setFieldsValue({
        email: props.data?.email,
        password: "",
        passwordRepeat: ""
      });
    } else {
      form.resetFields();
    }
  }, [props.data]);

  async function handleSubmit(values) {
    setLoading(true);
    const token = await getAccessTokenSilently();
    if (props.data) {
      await put(`user/${props.data._id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const { onClose, onRefresh } = props;
      setLoading(false);
      onRefresh();
      onClose();
    } else {
      try {
        await post(
          `admin/user/create`,
          {
            email: values["email"],
            password: values["password"],
            name: values["name"],
            type: USER_TYPE_FRANCHISOR,
            requirementId: franchisorAdmin._id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const { onClose, onRefresh } = props;
        setLoading(false);
        onRefresh();
        onClose();
      } catch (error) {
        setError(error?.response?.data?.message);
        setLoading(false);
      }
    }
  }

  function handleCancel() {
    const { onClose } = props;
    form.resetFields();
    onClose();
  }

  function validatePassword(rule, value, callback) {
    if (!value.match(`(?=.*[a-z])`)) {
      return callback("Need to add lower case");
    }
    if (!value.match(`(?=.*[A-Z])`)) {
      return callback("Need to add upper case");
    }
    if (!value.match(`(?=.*[0-9])`)) {
      return callback("Need to add number");
    }
    if (!value.match(`(?=.*[!@#$&*])`)) {
      return callback("Need to add Special characters");
    }
    if (value.length < 8) {
      return callback("Need to at least 8 letters");
    }
    callback();
  }

  return (
    <Form form={form} className="user_form" onFinish={handleSubmit}>
      {error && (
        <div className="user_form-error">
          <Alert message={error} type="error" showIcon />
        </div>
      )}
      <InputLabel label={"Name"} />
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: "Name is required!"
          }
        ]}
      >
        <AntInput size="large" placeholder="Name" />
      </Form.Item>
      <InputLabel label={"Email"} />
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please input valid email"
          }
        ]}
      >
        <AntInput size="large" placeholder="Email" />
      </Form.Item>
      <InputLabel label={"Password"} />
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input password"
          },
          { validator: validatePassword }
        ]}
      >
        <AntInput.Password size="large" placeholder="Password" />
      </Form.Item>
      <InputLabel label={"Password Repeat"} />
      <Form.Item
        name="passwordRepeat"
        rules={[
          {
            required: true,
            message: "Please confirm your password!"
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            }
          })
        ]}
      >
        <AntInput.Password size="large" placeholder="Password Repeat" />
      </Form.Item>

      <Loading loading={loading} />
      <div className="user_form-footer">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );
}
