const CONSTRUCTION_TYPE_CODES = [
  {
    value: "F",
    label: "Frame",
  },
  {
    value: "JM",
    label: "Joisted Masonary",
  },
  {
    value: "NC",
    label: "Non-Combustible",
  },
  {
    value: "MNC",
    label: "Masonry Non-Combustible",
  },
  {
    value: "R",
    label: "Flame Resistant",
  },
];

export { CONSTRUCTION_TYPE_CODES };
