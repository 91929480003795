import React from 'react';
import { Splash } from '../../components';

function Main() {
    return (
        <div style={{width: '100%', height: '100%'}}>
            <Splash />
        </div>
    )
}

export default Main;
