import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Alert } from "antd";
import "./style.scss";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildValueLT,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldIM } from "@utils/form-recog";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { formatToMonthDayYear } from "@utils/date-formatter";
import { formatDollarAmount } from "@utils/dollar-formatter";


export default function InlandMarineForm(props) {
  const { docIndex, franchisorData, franchiseeData, toggleState } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (franchiseeData?.finalData?.im_form_status === STATUS_SECTION_APPROVE) {
      const finalData = franchiseeData.finalData;

      return {
        im_limit: finalData.im_limit,
        im_limit_per_item: finalData.im_limit_per_item,
        im_business_interuption: finalData.im_business_interuption,
        im_policy_number: finalData.im_policy_number,
        im_effective_date: finalData.im_effective_date,
        im_expire_date: finalData.im_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex][0]?.fields;
      if (data) {
        return mapFieldIM(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

   const imSections = [
     {
       title: "Effective Date",
       valueFn: () =>
         buildEffectDate(
           franchiseeData?.finalData?.im_effective_date,
           franchisorData?.im_required
         ),
       formValue: formatToMonthDayYear(
         franchiseeData?.finalData?.im_effective_date
       ),
     },
     {
       title: "Expiration Date",
       valueFn: () =>
         buildExpireDate(
           franchiseeData?.finalData?.im_expire_date,
           franchisorData?.im_required
         ),
       formValue: formatToMonthDayYear(
         franchiseeData?.finalData?.im_expire_date
       ),
     },
     {
       title: "Limit",
       valueFn: () =>
         buildValueGT(
           franchiseeData?.finalData?.im_limit,
           franchisorData?.im_limit,
           franchisorData?.im_required
         ),
       fddRequirement: formatDollarAmount(franchisorData?.im_limit),
       formValue: formatDollarAmount(franchiseeData?.finalData?.im_limit),
     },
     {
       title: "Limit Per Item",
       valueFn: () =>
         buildValueGT(
           franchiseeData?.finalData?.im_limit_per_item,
           franchisorData?.im_limit_per_item,
           franchisorData?.im_required
         ),
       fddRequirement: formatDollarAmount(franchisorData?.im_limit_per_item),
       formValue: formatDollarAmount(franchiseeData?.finalData?.im_limit_per_item),
     },
     {
       title: "Business Interruption",
       valueFn: () =>
         buildValueLT(
           franchiseeData?.finalData?.im_business_interuption,
           franchisorData?.im_business_interuption,
           franchisorData?.im_required
         ),
       fddRequirement: formatDollarAmount(franchisorData?.im_business_interuption),
       formValue: formatDollarAmount(franchiseeData?.finalData?.im_business_interuption),
     },
   ];

   const displayedSectionsCount = imSections.filter(
     ({ title, valueFn, formValue }) => {
       const value = valueFn()?.[0]?.title;

       return (
         ((title !== "Effective Date" && title !== "Expiration Date") ||
           formValue ||
           (Array.isArray(valueFn?.()) && valueFn().length > 0)) &&
         (toggleState ? value : formValue || value)
       );
     }
   ).length;

 return (
   <div className="liability-table">
     {(() => {
       const hasInvalidExpirationDate = imSections.some(
         ({ title, valueFn }) =>
           title === "Expiration Date" &&
           valueFn()?.[0]?.title === "Must expire after today's date"
       );

       const effectiveToggleState = hasInvalidExpirationDate
         ? false
         : toggleState;

       return (
         (!effectiveToggleState || displayedSectionsCount > 0) && (
           <div>
             <h2>
               Inland Marine #{franchiseeData?.finalData?.im_policy_number}
             </h2>
             {hasInvalidExpirationDate && (
               <div className="coverage-lapsed-error">
                 <Alert
                   message="Please Upload COI to Renew Lapsed Coverage"
                   type="error"
                   showIcon
                 ></Alert>
               </div>
             )}
             <div className="table-header">
               <span className="table-header-field">Field</span>
               <span className="table-header-fdd">Requirement</span>
               <span className="table-header-coverage">Your Coverage</span>
             </div>

             {imSections.map(
               ({ title, valueFn, fddRequirement, formValue }) => {
                 const buildValue = valueFn()?.[0]?.title;

                 const isCoverageLapsed = hasInvalidExpirationDate;

                 const isExpiringSoon =
                   title === "Expiration Date" &&
                   buildValue === "Expiring in 15 days or less";

                 const displayValue = buildValue || formValue;

                 const shouldDisplayDates =
                   title === "Effective Date" || title === "Expiration Date"
                     ? !effectiveToggleState ||
                       isCoverageLapsed ||
                       isExpiringSoon ||
                       buildValue
                     : effectiveToggleState
                     ? buildValue
                     : fddRequirement && fddRequirement !== "";

                 if (!shouldDisplayDates) {
                   return null;
                 }

                 return (
                   <div key={title} className="table-row">
                     <div className="table-cell field-title">
                       <span>{title}</span>
                     </div>
                     <div className="table-cell fdd-requirement">
                       <span>{fddRequirement || ""}</span>
                     </div>
                     <div className="table-cell">
                       {isCoverageLapsed ? (
                         <span className="value-red">
                           <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                           {typeof displayValue === "boolean"
                             ? displayValue
                               ? "Selected"
                               : "Not Selected"
                             : displayValue}
                         </span>
                       ) : effectiveToggleState && isExpiringSoon ? (
                         buildValue ? (
                           <span className="value-yellow">
                             <WarningTwoTone twoToneColor={"#ffcc00"} />{" "}
                             {buildValue}
                           </span>
                         ) : null
                       ) : effectiveToggleState ? (
                         <span className="value-red">
                           <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                           {buildValue}
                         </span>
                       ) : isExpiringSoon ? (
                         <span className="value-yellow">
                           <WarningTwoTone twoToneColor={"#ffcc00"} />{" "}
                           {buildValue}
                         </span>
                       ) : buildValue ? (
                         <span className="value-red">
                           <WarningTwoTone twoToneColor={"#ff0000"} />{" "}
                           {buildValue}
                         </span>
                       ) : (
                         <span className="value-green">
                           <CheckCircleTwoTone twoToneColor={"#46b946"} />{" "}
                           {typeof displayValue === "boolean"
                             ? displayValue
                               ? "Selected"
                               : null
                             : displayValue}
                         </span>
                       )}
                     </div>
                   </div>
                 );
               }
             )}
           </div>
         )
       );
     })()}
   </div>
 );
}
