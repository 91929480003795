import React from "react";
import { Input, AddressInput } from "@components";
import { DeleteOutlined, HomeOutlined } from "@ant-design/icons";
import _ from "lodash";
import "./style.scss";
import { Button, Checkbox } from "antd";

export default function BlockLocation({
  data,
  onChange,
  disabled,
  zeeData,
  isSubmit,
}) {
  function handleChangeLocation(index, key, value) {
    onChange((prev) => {
      prev[index][key] = value;

      return [...prev];
    });
  }

  function handleChangeLocationAddress(index, address) {
    onChange((prev) => {
      prev[index]["address"] = address.address;
      prev[index]["city"] = address.city;
      prev[index]["state"] = address.state;
      prev[index]["zip"] = address.zip;

      return [...prev];
    });
  }

  const originalAddress = zeeData?.address;
  //extracts street address
  function extractStreetAddress(fullAddress) {
    if (!fullAddress) return "";
    const match = fullAddress.match(/^[^,]+/);
    return match ? match[0].trim() : "";
  }

  function firstTimeNewLoc() {
    onChange(() => {
      return [
        {
          address: extractStreetAddress(originalAddress),
          city: zeeData?.city,
          state: zeeData?.state,
          zip: zeeData?.zip,
        },
      ];
    });
  }

  function handleAddNewLoc(index) {
    onChange((prev) => [
      ...prev,
      {
        address: "",
        city: "",
        state: "",
        zip: "",
      },
    ]);
  }

  function handleDeleteLoc(index) {
    onChange((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  }

 function renderLocInfo(index, locationItem) {
   const hasAddressError =
     (locationItem.address || "").trim() === "" && isSubmit;
   const hasCityError = (locationItem.city || "").trim() === "" && isSubmit;
   const hasStateError = (locationItem.state || "").trim() === "" && isSubmit;
   const hasZipError = (locationItem.zip || "").trim() === "" && isSubmit;
   const hasCheckboxError =
     !locationItem.isMailing &&
     !locationItem.isBilling &&
     !locationItem.isPhysical &&
     isSubmit;

   return (
     <div className="block_loc-item" key={`loc-item-${index}`}>
       <div className="block_loc-item-wrapper">
         <div className="form-group-three-container">
           <div
             className={`custom-input-container ${
               hasAddressError ? "error" : ""
             }`}
           >
             <AddressInput
               title="Location"
               type="text"
               value={locationItem.address}
               onChange={(value) =>
                 handleChangeLocation(index, "address", value)
               }
               onSelect={(address) =>
                 handleChangeLocationAddress(index, address)
               }
             />
             {hasAddressError && (
               <div className="error-log">
                 <span>Address is required</span>
               </div>
             )}
           </div>
           <div
             className={`custom-input-container ${hasCityError ? "error" : ""}`}
           >
             <Input
               title="City"
               type="text"
               value={locationItem.city}
               required
               onChange={(evt) =>
                 handleChangeLocation(index, "city", evt.target.value)
               }
             />
             {hasCityError && (
               <div className="error-log">
                 <span>City is required</span>
               </div>
             )}
           </div>
           <div className="form-group-two-container">
             <div
               className={`custom-input-container ${
                 hasStateError ? "error" : ""
               }`}
             >
               <Input
                 title="State"
                 type="text"
                 value={locationItem.state}
                 required
                 onChange={(evt) =>
                   handleChangeLocation(index, "state", evt.target.value)
                 }
               />
               {hasStateError && (
                 <div className="error-log">
                   <span>State is required</span>
                 </div>
               )}
             </div>
             <div
               className={`custom-input-container ${
                 hasZipError ? "error" : ""
               }`}
             >
               <Input
                 title="Zip"
                 type="text"
                 value={locationItem.zip}
                 required
                 onChange={(evt) =>
                   handleChangeLocation(index, "zip", evt.target.value)
                 }
               />
               {hasZipError && (
                 <div className="error-log">
                   <span>Zip is required</span>
                 </div>
               )}
             </div>
           </div>
         </div>
         <div
           className={`block_loc-item-wrapper-option ${
             hasCheckboxError ? "error" : ""
           }`}
         >
           <Checkbox
             checked={locationItem.isMailing}
             onChange={(evt) =>
               handleChangeLocation(index, "isMailing", evt.target.checked)
             }
           >
             Mailing
           </Checkbox>
           <Checkbox
             checked={locationItem.isBilling}
             onChange={(evt) =>
               handleChangeLocation(index, "isBilling", evt.target.checked)
             }
           >
             Billing
           </Checkbox>
           <Checkbox
             checked={locationItem.isPhysical}
             onChange={(evt) =>
               handleChangeLocation(index, "isPhysical", evt.target.checked)
             }
           >
             Physical
           </Checkbox>
           {hasCheckboxError && (
             <div className="error-log">
               <span>Select at least one</span>
             </div>
           )}
         </div>
       </div>
       <div className="block_loc-item-action">
         {!disabled && (
           <Button
             className="block_loc-item-action-btn"
             onClick={() => handleDeleteLoc(index)}
           >
             <DeleteOutlined />
           </Button>
         )}
       </div>
     </div>
   );
 }

  return (
    <div className="block_loc">
      {data.length === 0 && firstTimeNewLoc()}
      {data.map((locItem, index) => renderLocInfo(index, locItem))}
      {!disabled && (
        <div className="block_loc-footer">
          <Button className="block_loc-footer-link" onClick={handleAddNewLoc}>
            <HomeOutlined />
            Add Address
          </Button>
        </div>
      )}
    </div>
  );
}
