import React, { useState } from "react";
import "./style.scss";
import { Card } from "@components";
import PersonalInfor from "../../admin-board/franchisee-overview/personal-infor";
import InsuranceRequirements from "../../admin-board/franchisee-overview/insurance-requirements";
import MembersInfor from "./members-infor";
import { Tabs } from "antd";
import { useAppState } from "@context";
import { VIEW_SOURCE_ZOR } from "@assets/const/ui";
const { TabPane } = Tabs;

const TAB_ACCOUNT = "TAB_ACCOUNT";
const TAB_INSURANCE_REQUIREMENTS = "TAB_INSURANCE_REQUIREMENTS";
const TAB_MEMBERS = "TAB_MEMBERS";

export default function Settings() {
  const { franchisorAdmin } = useAppState();

  const title = franchisorAdmin
    ? franchisorAdmin?.personal_infor_business_name
    : "All Franchisees";

  return (
    <div className="zor_setting">
      <Card title={title}>
        <Tabs type="card" defaultActiveKey={TAB_ACCOUNT}>
          <TabPane tab="Account Information" key={TAB_ACCOUNT}>
            <PersonalInfor viewSource={VIEW_SOURCE_ZOR} />
          </TabPane>
          <TabPane tab="Requirements" key={TAB_INSURANCE_REQUIREMENTS}>
            <InsuranceRequirements />
          </TabPane>
          <TabPane tab="Members" key={TAB_MEMBERS}>
            <MembersInfor />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}
