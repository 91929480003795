import React, { useState } from "react";
import "./style.scss";
import { Button, notification, Alert } from "antd";
import ReactJson from "react-json-view";
import { post } from "@utils/axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "@components";

export default function MergeFranchisee(props) {
  const { left, right, onClose } = props;
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  async function handleMergeRight() {
    setLoading(true);
    const token = await getAccessTokenSilently();
    try {
      await post(
        `contact/merge`,
        {
          fromId: left._id,
          toId: right._id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setLoading(false);
      onClose();
    } catch (error) {
      notification.error({
        message: "Failed",
        description: "Mergin Failed. Please contact admin directly!",
        duration: 5
      });
      setLoading(false);
    }
  }

  async function handleMergeLeft() {
    setLoading(true);
    const token = await getAccessTokenSilently();
    try {
      await post(
        `contact/merge`,
        {
          fromId: right._id,
          toId: left._id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setLoading(false);
      onClose();
    } catch (error) {
      notification.error({
        message: "Failed",
        description: "Mergin Failed. Please contact admin directly!",
        duration: 5
      });
      setLoading(false);
    }
  }

  return (
    <div className="merge_zee">
      {right.requirementId !== left.requirementId && (
        <Alert
          message="Be careful. Two Franchisees are associated with different franchisors"
          type="error"
        />
      )}
      <div className="merge_zee-con">
        <div className="merge_zee-con-left">
          <ReactJson
            src={left}
            theme="monokai"
            displayDataTypes={false}
            enableClipboard={false}
          />
        </div>

        <div className="merge_zee-con-tool">
          <Button onClick={handleMergeRight}> {`>>`} </Button>
          <Button onClick={handleMergeLeft}> {`<<`} </Button>
        </div>

        <div className="merge_zee-con-right">
          <ReactJson
            src={right}
            theme="monokai"
            displayDataTypes={false}
            enableClipboard={false}
          />
        </div>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
