import React, { useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  RightCircleOutlined,
  DownCircleOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import "./style.scss";
import { get, deleteAPI } from "../../../../utils/axios";
import { useAuth0 } from "@auth0/auth0-react";
import { format } from "date-fns";

export default function LogItem(props) {
  const { data, setDetail, onLogList } = props;
  const { getAccessTokenSilently } = useAuth0();

  async function handleDetail() {
    try {
      const token = await getAccessTokenSilently();
      const dataObj = await get(
        `admin/logs/${data.path}?logType=${props.section}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDetail(dataObj.data.data);
    } catch (error) {}
  }

  async function handleDownload() {
    try {
      const token = await getAccessTokenSilently();
      const dataObj = await get(
        `admin/logs/${data.path}?logType=${props.section}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([JSON.stringify(dataObj.data.data)])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `document.txt`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {}
  }

  function handleClose() {
    setDetail(null);
  }

  async function handleDelete() {
    try {
      const token = await getAccessTokenSilently();
      const result = await deleteAPI(
        `admin/logs/${data.path}?logType=${props.section}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const logData = result?.data?.data || [];
      const logList = logData.map((item) => ({
        label: format(new Date(item.split(".txt")[0]), "LLL dd yyyy hh:mm a"),
        path: item,
      }));
      onLogList(logList);
    } catch (error) {}
  }

  return (
    <div className="admin_log_item">
      <div className="admin_log_item-main">
        <span className="admin_log_item-main-title">{data.label}</span>
        <div className="admin_log_item-main-action">
          <RightCircleOutlined
            className="admin_log_item-main-action-edit"
            onClick={handleDetail}
          />
          <CloudDownloadOutlined
            className="admin_log_item-main-action-download"
            onClick={handleDownload}
          />
          <DeleteOutlined
            className="admin_log_item-main-action-delete"
            onClick={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}
