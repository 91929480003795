import {
  CheckCircleFilled,
  IdcardFilled,
  PhoneFilled,
  EditFilled,
  FilePdfFilled,
  MedicineBoxFilled,
  CreditCardFilled,
  CalendarOutlined
} from "@ant-design/icons";
import "./style.scss";
import { useState, useEffect } from "react";

const STEP_LIST = [
  {
    title: "Step 1",
    desc: "Complete the Form",
    icon: () => <IdcardFilled />
  },
  {
    title: "Step 2",
    desc: "Book a Discovery Call",
    icon: () => <PhoneFilled />
  },
  {
    title: "Step 3",
    desc: "Provide additional Info",
    icon: () => <EditFilled />
  },
  {
    title: "Step 4",
    desc: "Review your Proposal",
    icon: () => <FilePdfFilled />
  },
  {
    title: "Step 5",
    desc: "E-Sign Insurance Package",
    icon: () => <MedicineBoxFilled />
  },
  {
    title: "Step 6",
    desc: "Submit Payment",
    icon: () => <CreditCardFilled />
  }
];

export default function SuccessPage({ calDiscoverLink }) {
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="quote_success">
      <div className="quote_success-alert">
        <i>
          <b>Success!</b>
        </i>{" "}
        Your quote has been submitted. 91% of customers receive a quote in 14
        calender days. Please check your email for updates and communication.
      </div>
      <div className="quote_success-step">
        {STEP_LIST.map((stepItem, index) => (
          <div
            className={`quote_success-step-item ${
              index < activeStep
                ? "passed"
                : index === activeStep
                ? "active"
                : "pending"
            }`}
          >
            <div className="quote_success-step-item-header">
              {index < activeStep && (
                <CheckCircleFilled style={{ color: "#63a103", fontSize: 24 }} />
              )}
              {index === activeStep && (
                <div className="dot" style={{ background: "#02a7f0" }}></div>
              )}
              {index > activeStep && (
                <div className="dot" style={{ background: "#d7d7d7" }}></div>
              )}
            </div>
            <div className="quote_success-step-item-content">
              {stepItem.icon()}
              <h5 className="quote_success-step-item-content-title">
                {stepItem.title}
              </h5>
              <p className="quote_success-step-item-content-desc">
                {stepItem.desc}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="quote_success-title">
        NEXT STEP: BOOK A ‘DISCOVERY CALL’ NOW
      </div>
      <div className="quote_success-desc">
        Schedule a ‘
        <i>
          <b>Discovery Call</b>
        </i>
        ’ or click the button below. Post ‘
        <i>
          <b>Discovery Call</b>
        </i>
        ’ you may need to provide additional missing information (
        <i>
          <b>Missing driver, missing VIN, Physical Location, etc</b>
        </i>
        ). If you’ve already booked a ‘Discovery Call’ then you don’t need to
        book another.
      </div>
      {calDiscoverLink && (
        <a className="quote_success-cal" href={calDiscoverLink} target="_blank">
          <CalendarOutlined /> Book a discovery call now to get a quote {">>"}
        </a>
      )}
      <div className="quote_success-intro">
        <div className="quote_success-intro-title">
          BEST WAY TO COMMUNICATE WITH YOUR CONSULTANT
        </div>
        <div className="quote_success-intro-desc">
          You’ll receive all communication moving forward via email. Rikor sends
          periodic updates and requests via automated systems. It’s important to
          check your inbox and spam. The emails will always include instructions
          and calls to action. These are sent on behalf of your Franchise, Rikor
          is the Preferred Broker.
        </div>
      </div>
      {/* <div className="quote_success-intro">
        <div className="quote_success-intro-title">CUSTOMER TESTIMONIAL</div>
      </div> */}

      <div className="quote_success-intro">
        <div className="quote_success-intro-title">STILL GOT QUESTIONS?</div>
        <div className="quote_success-intro-sub">
          How do I get quick support?
        </div>
        <div className="quote_success-intro-desc">
          The best way to support you is sending an email or scheduling a call
          or text to your consultant directly. You’ll recieve an email with your
          consultants Calendy link (Calender), allowing you to book time
          together.
        </div>
        <br />
        <div className="quote_success-intro-sub">
          What is the process for paying for my policies?
        </div>
        <div className="quote_success-intro-desc">
          When putting the policies in place, we will gather payment information
          from you. Depending on the policy, we will:
          <ul>
            <li>Set you up on installments using the information provided.</li>
            <li>Pay the policy in full.</li>
            <li>Establish a financing plan.</li>
          </ul>
        </div>

        <div className="quote_success-intro-sub">
          What guidelines should I use when looking at driving records for
          hiring new drivers?
        </div>
        <div className="quote_success-intro-desc">
          <ul>
            <li>No drivers younger than 18 years old.</li>
            <li>
              For drivers 18-21 years old, perfectly clean driving record.
            </li>
            <li>
              For drivers 21-25 years old, no more 1 minor violations in the
              last 3 years.
            </li>
            <li>For drivers 25+ years old.</li>
            <li>
              No more than 3 minor violations in the last 3 years (minor
              speeding, traffic violation, etc.).{" "}
            </li>
            <li>
              No major violations in the last 3 years (DUI, DWI, Speeding 20+
              over the speed limit, reckless driving, etc.).
            </li>
            <li>No suspended licenses. </li>
            <li>
              Please note that it is your responsibility to pull driving records
              before submitting driver requests to us.
            </li>
          </ul>
        </div>
        <div className="quote_success-intro-sub">
          Where do I go to get certificates of insurance? What is the processing
          timeline?
        </div>
        <div className="quote_success-intro-desc">
          Send any requests to certs@rikor.io Please allow up to 24 hours
          (weekdays) for certificates to be processed. Often, certificates will
          be back to you within 2 hours.
        </div>

        <div className="quote_success-intro-sub">
          What information is needed when requesting certificates for specific
          3rd parties?
        </div>
        <div className="quote_success-intro-desc">
          Please include in your request the full name of the entity, the
          mailing address, and any specific insurance requirements (additional
          insured, waiver of subrogation, etc.)
        </div>

        <div className="quote_success-intro-sub">
          I have a potential claim. What do I do?
        </div>
        <div className="quote_success-intro-desc">
          Go to https://www.protectmyfranchise.com/faq and complete the
          requested information. An adjuster will reach out to you for further
          information within 24 hours. If medical treatment is necessary, seek
          help as needed and save all receipts.
        </div>

        <div className="quote_success-intro-sub">
          I need to make a change to my policy. How do I do that?
        </div>
        <div className="quote_success-intro-desc">
          Send any requests to endorsements@rikor.io with the necessary
          information. Vehicle: Year, Make, Model, and VIN number. Driver: Full
          name, date of birth, drivers license number and state.
        </div>
        <br />
        <br />
        <div className="quote_success-intro-desc">
          Rikor agents warn franchisee’s from ‘shopping’ quotes from local
          brokers who promise “super low prices.” This effort is redundant
          because Rikor Agents shop your price with all the available markets.
          The only way to lower price is to manipulate exposure, classifications
          or reduce coverage. Rikor will always quote based on the FDD
          requirements which act as a guide to protecting your investment and
          the entire brand.
        </div>
        <br />
        <div className="quote_success-intro-title">THE RIKOR SHIELD™</div>
        <div className="quote_success-intro-desc">
          Rikor Consultants are committed to providing all franchisee’s with
          “The Rikor Shield™. This is a commitment to exceptional service you
          can only expect to see from Rikor. After all, policy support year
          round is often overlooked by franchisees. Policy service can be a life
          saver in many situations. The following list is the exceptional
          lengths our agents go for our franchisees:
          <ul>
            <li>1-Day certificate turnarounds</li>
            <li>1-Day change requests</li>
            <li>Mid-Year Policy Reviews</li>
            <li>90-Day Renewal Process</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
