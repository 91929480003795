import { format } from "date-fns";
import { getAbbrev } from "../assets/const/location";

const RIKOR_PRODUCER_CODE = "34471962";

export function getPhontNum(phoneStr) {
  let phoneNum = phoneStr.match(/\d/g);
  phoneNum = phoneNum.join("");
  return phoneNum;
}

export function filterEmptyValue(value) {
  if (value == "") {
    return undefined;
  }

  return value;
}

export function convertStatus(status) {
  switch (status.toLowerCase()) {
    case "eligible":
      return "Bindable";
    case "ineligible":
      return "Not Yet Bindable";
    default:
      return status;
  }
}

// function args should match all quote functions
export function prepareBaseQuoteParams({
  token,
  formCode,
  profileVal,
  franchiseeData,
  locationVal,
  officeVal,
  effectiveDate,
}) {
  return {
    SICCode: filterEmptyValue(formCode.SICCode),
    NAICS: filterEmptyValue(formCode.NAICS),
    spectrumClassCode: filterEmptyValue(formCode.spectrumClassCode),
    sourceSystem: "Rikor Insurance Marketplace",
    sourceSystemType: filterEmptyValue(formCode.sourceSystemType),
    agentName: filterEmptyValue(formCode.agentName),
    legalEntityCd: filterEmptyValue(profileVal.entity_type),
    producerCode: RIKOR_PRODUCER_CODE,
    commercialName: filterEmptyValue(profileVal.entity_name),
    doingBusinessAs: filterEmptyValue(profileVal.dba),
    communications: {
      contactName: filterEmptyValue(
        `${profileVal.first_name} ${profileVal.last_name}`
      ),
      phoneNumber: getPhontNum(profileVal.telephone),
      emailAddr: filterEmptyValue(profileVal.email),
    },
    mailingAddress: {
      addr1: filterEmptyValue(profileVal.address),
      city: filterEmptyValue(profileVal.city),
      stateProvCd: filterEmptyValue(getAbbrev(profileVal.state)),
      postalCode: filterEmptyValue(profileVal.zip),
    },
    FEIN: filterEmptyValue(profileVal.tax_id),
    effectiveDt: filterEmptyValue(effectiveDate),
    businessStartYear: filterEmptyValue(
      format(new Date(profileVal.opening_date || "2019-01-01"), "yyyy")
    ),
    locations: (locationVal || []).map((item, itemIndex) => ({
      addr: {
        addr1: filterEmptyValue(item.address),
        city: filterEmptyValue(item.city),
        stateProvCd: filterEmptyValue(getAbbrev(item.state)),
        postalCode: filterEmptyValue(item.zip),
      },
    })),
    totalEmployeePayroll: locationVal
      ? locationVal[0]?.liabInfo?.totalPayroll || ""
      : null,
  };
}
