import React, { useState } from "react";
import { Menu, Dropdown, Layout, theme, Avatar as AntAvatar } from "antd";
import logoRikor from "@assets/images/logo.svg";
import affiliateLogo from "@assets/images/affiliateLogo.svg";
import { DownOutlined, CloseOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from "../avatar";
import { useAppState } from "@context";
import { userRole } from "@assets/const/auth";
import { onboardingWorkflow } from "@assets/const/onboarding";
import { useHistory } from "react-router-dom";
import { APP_VERSION } from "@assets/const/version";
import ReleaseNote from "@components/modals/release-notes";
import "./style.scss";

const { Header: AntHeader } = Layout;

const screenMode = {
  SCREEN_SIGN_IN: "signin",
  SCREEN_SIGN_UP: "signup",
  SCREEN_ONBOARDING_START: onboardingWorkflow.ONBOARDING_START,
  SCREEN_SYNC_FRANCHISEES: onboardingWorkflow.SYNC_FRANCHISEES,
  SCREEN_BOARD_PLATFORMS: onboardingWorkflow.BOARD_PLATFORMS,
  SCREEN_LIST_FRANCHISEE: onboardingWorkflow.LIST_FRANCHISEE,
  SCREEN_BOARD_UPLOAD: onboardingWorkflow.BOARD_UPLOAD,
  SCREEN_DASHBOARD: "dashboard",
  SCREEN_ADMIN: "admin",
  SCREEN_AFFILIATE_DASHBOARD: "affiliate-dashboard",
  INSURANCE_REQUIREMENT: onboardingWorkflow.INSURANCE_REQUIREMENT,
  SIGN_IN_FRANCHISEE: onboardingWorkflow.SIGN_IN_FRANCHISEE,
  PREVIEW_IMPORT_CONTACTS: onboardingWorkflow.PREVIEW_IMPORT_CONTACTS,
  REQUEST_ACTIVIATION: onboardingWorkflow.REQUEST_ACTIVIATION,
  FRANCHISEE_SECTION: "franchisee-section",
  ACCOUNT_INFORMATION: onboardingWorkflow.ACCOUNT_INFORMATION
};

const getLogoImage = () => {
  return logoRikor;
};

function Header(props) {
  const { screen } = props;
  const { user, logout } = useAuth0();
  const { franchisorAdmin, setFranchisorAdmin, authSetting } = useAppState();
  const [isReleaseNote, setIsReleaseNote] = useState(false);
  const history = useHistory();
  const logoImage = getLogoImage();

  function handleLogout() {
    logout({ logoutParams: { returnTo: `${window.location.origin}/sign-in` } });
  }

  function handleDeselectFranchisor() {
    setFranchisorAdmin(null);
  }

  function handleReleaseNote() {
    setIsReleaseNote(true);
  }

  const {
    token: { colorBgContainer, borderRadiusLG }
  } = theme.useToken();

  function renderMainMenu() {
    return (
      <Menu>
        <Menu.Item>
          <a href="#" onClick={handleLogout}>
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );
  }

  function renderMain() {
    switch (screen) {
      case screenMode.SCREEN_ONBOARDING_START:
        return (
          <div className="header-container">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.SCREEN_SYNC_FRANCHISEES:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );

      case screenMode.SCREEN_ADMIN:
        return (
          <AntHeader
            className="header"
            theme="light"
            style={{
              padding: 0,
              background: colorBgContainer
            }}
          >
            <div className="header-wrapper">
              <div>
                {franchisorAdmin && (
                  <div className="header-wrapper-container">
                    <span className="header-wrapper-container-title">
                      {franchisorAdmin?.personal_infor_business_name}
                    </span>

                    <div
                      className="action-btn"
                      onClick={handleDeselectFranchisor}
                    >
                      <CloseOutlined />
                    </div>
                  </div>
                )}
              </div>
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="header-wrapper-avatar"
                  onClick={(e) => e.preventDefault()}
                >
                  <AntAvatar src={user?.picture} size="large" />
                  <div>
                    {user?.nickname} <DownOutlined />
                  </div>
                </a>
              </Dropdown>
            </div>
          </AntHeader>
        );

      case screenMode.SCREEN_AFFILIATE_DASHBOARD:
        return (
          <div className="header-container bg-white">
            <div className="header-wrapper">
              <div className="header-wrapper_logo">
                <img src={affiliateLogo} className="logo-image" />
                <span
                  className="header-wrapper_logo-version"
                  onClick={handleReleaseNote}
                >
                  v.{APP_VERSION}
                </span>
              </div>
            </div>

            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.SIGN_IN_FRANCHISEE:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.PREVIEW_IMPORT_CONTACTS:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.REQUEST_ACTIVIATION:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.ACCOUNT_INFORMATION:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.SCREEN_BOARD_PLATFORMS:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.SCREEN_BOARD_UPLOAD:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.SCREEN_LIST_FRANCHISEE:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.INSURANCE_REQUIREMENT:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.BOARD_UPLOAD:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
            <div className="action-container">
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link avatar-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar url={user?.picture} />
                  {user?.nickname} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </div>
        );
      case screenMode.FRANCHISEE_SECTION:
        return (
          <div className="header-container bg-white">
            <img src={logoImage} className="logo-image" />
          </div>
        );
      case screenMode.SCREEN_DASHBOARD:
        return (
          <AntHeader
            className="header"
            theme="light"
            style={{
              padding: 0,
              background: colorBgContainer
            }}
          >
            <div className="header-wrapper">
              <div></div>
              <Dropdown overlay={renderMainMenu} trigger={["click"]}>
                <a
                  className="header-wrapper-avatar"
                  onClick={(e) => e.preventDefault()}
                >
                  <AntAvatar src={user?.picture} size="large" />
                  <div>
                    {user?.nickname} <DownOutlined />
                  </div>
                </a>
              </Dropdown>
            </div>
          </AntHeader>
        );
      default:
        return <div></div>;
    }
  }

  return (
    <div>
      {renderMain()}
      <ReleaseNote
        isOpen={isReleaseNote}
        onClose={() => setIsReleaseNote(false)}
      />
    </div>
  );
}

export default Header;
