import React from "react";
import {
  COVERAGE_KEY_AUTO,
  COVERAGE_KEY_LIABILITY,
  COVERAGE_KEY_PROPERTY,
  COVERAGE_KEY_UMBRELLA,
  COVERAGE_KEY_WORKERS_COMP,
} from "../../../../../assets/const/fim-integration";
import "./style.scss";
import PropertyDetails from "./components/property-details";
import LiabilityDetails from "./components/liability-details";
import WorkCompDetails from "./components/workercomp-details";

function renderLiabPropDetails(liab, prop) {
  if (liab && prop) {
    // Liab and prop quote results are the same when quoted as LiabProp. So they can be
    // consolidated into a single preview called BOP.
    return <PropertyDetails {...liab} title="Business Owner Policy" />;
  }
  if (liab) {
    return <LiabilityDetails {...liab} />;
  }
  if (prop) {
    return <PropertyDetails {...prop} />;
  }
  return <></>;
}

export default function HartfordPreview(props) {
  const liability = props[COVERAGE_KEY_LIABILITY];
  const property = props[COVERAGE_KEY_PROPERTY];
  const auto = props[COVERAGE_KEY_AUTO];
  const workComp = props[COVERAGE_KEY_WORKERS_COMP];
  const umbrella = props[COVERAGE_KEY_UMBRELLA];

  return (
    <div className="hartford_preview">
      <h1>Hartford Quote</h1>
      {renderLiabPropDetails(liability, property)}
      {workComp && <WorkCompDetails {...workComp} />}
    </div>
  );
}
