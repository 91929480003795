import React, { useState } from "react";
import { AutoComplete, Input, Select } from "antd";
import "./style.scss";
import { useAppState } from "../../../../context";

const FILED_MAP = {
  NAICS: {
    code: "naics",
    desc: "naics_desc"
  },
  WC: {
    code: "wc_ncci",
    desc: "ncci_desc"
  },
  SIC: {
    code: "sic",
    desc: "ge_desc"
  }
};

export default function ClassCodeInput({
  typeVal,
  codeVal,
  descVal,
  setCodeVal,
  setDescVal,
  setSelect
}) {
  const [codeOptions, setCodeOptions] = useState([]);
  const [descOptions, setDescOptions] = useState([]);
  const { codeList } = useAppState();

  function handleSearchCode(searchStr) {
    const findCodes = codeList
      .filter((item) =>
        (item[FILED_MAP[typeVal].code] || "")
          .toLowerCase()
          .includes(searchStr.toLowerCase())
      )
      .splice(0, 5);

    const filteredCodes = findCodes.map((item) => ({
      value: `${item[FILED_MAP[typeVal].code]} - ${
        item[FILED_MAP[typeVal].desc]
      }`
    }));

    setCodeOptions([...filteredCodes]);
  }

  function handleSelectCode(codeStr) {
    setSelect(codeStr);
  }

  function handleSearchDesc(searchStr) {
    const findCodes = codeList
      .filter((item) =>
        (item[FILED_MAP[typeVal].desc] || "")
          .toLowerCase()
          .includes(searchStr.toLowerCase())
      )
      .splice(0, 5);

    const filteredCodes = findCodes.map((item) => ({
      value: `${item[FILED_MAP[typeVal].code]} - ${
        item[FILED_MAP[typeVal].desc]
      }`
    }));
    setDescOptions([...filteredCodes]);
  }

  return (
    <div className="class_code_input">
      <AutoComplete
        size="large"
        value={codeVal}
        options={codeOptions}
        style={{ width: "100%" }}
        onSelect={handleSelectCode}
        onSearch={handleSearchCode}
        onChange={setCodeVal}
        placeholder="input here"
      />
      <AutoComplete
        size="large"
        value={descVal}
        options={descOptions}
        style={{ width: "100%" }}
        onSelect={handleSelectCode}
        onSearch={handleSearchDesc}
        onChange={setDescVal}
        placeholder="input here"
      />
    </div>
  );
}
