const { getAddressItem } = require("./address");

const extractLocationData = (addressComponents) => {
  let zip = "";
  let state = "";
  let city = "";
  let address = "";
  if (addressComponents) {
    zip = getAddressItem(addressComponents, "postal_code", "short_name");
    state = getAddressItem(
      addressComponents,
      "administrative_area_level_1",
      "short_name"
    );
    city = getAddressItem(addressComponents, "locality", "long_name");
    const street = getAddressItem(
      addressComponents,
      "street_number",
      "long_name"
    );
    const route = getAddressItem(addressComponents, "route", "long_name");
    address = `${street} ${route}`;
  }
  return { zip, state, city, address };
};

export { extractLocationData };
