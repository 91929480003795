import React from "react";
import "../style.scss";
import InputWrapper from "../wrapper";

const InputText = React.forwardRef(
  ({ disableValidation, register = () => {}, rhfKey, ...props }, ref) => {
    return (
      <InputWrapper {...props}>
        <input
          {...{
            type: "text",
            ref,
            ...props,
            className: `${
              props.className || ""
            } custom-input ant-input ant-input-outlined custom-antd-input`,
            ...register(
              rhfKey,
              disableValidation ? { validate: () => true } : {}
            )
          }}
        />
      </InputWrapper>
    );
  }
);

export default InputText;
