import { Card } from "antd";
import { convertStatus } from "../../../../../../../utils/insurance-quote";
import { Input } from "../../../../../../../components";

export default function WorkCompDetails(props) {
  return (
    <Card className="hartford_preview-detail" title="Workers Compensation">
      <div className="form-group-container">
        <Input
          title="Quote Identifier"
          type="text"
          value={props.quoteIdentifier}
          disabled
        />
        <Input
          title="Streamlined Quote Status"
          type="text"
          value={convertStatus(props.streamlinedQuoteStatus || "")}
          disabled
        />
      </div>
      <div className="form-group-container">
        <div className="form-group-container">
          <Input
            title="Policy Number"
            type="text"
            value={props.policyNumber}
            disabled
          />
          <Input
            title="Total Premium"
            type="text"
            value={props.totalPremium}
            disabled
          />
        </div>
        <div className="form-group-container">
          <Input
            title="Issuance EligibleInd"
            type="text"
            value={props.issuanceEligibleInd}
            disabled
          />
          <Input title="eMod" type="text" value={props.eMod} disabled />
        </div>
      </div>
      {props.quoteAccessURL && (
        <div>
          <div>
            <b>Quote Access URL</b>
          </div>
          <div className="quote-access-url-container">
            <a href={props.quoteAccessURL} target="_blank">
              {props.quoteAccessURL}
            </a>
          </div>
        </div>
      )}
      <div>
        {(props.coverage || []).map((coverageItem) => (
          <div className="">
            <Input
              title="Coverage Code"
              type="text"
              value={coverageItem.coverageCode}
              disabled
            />
            {(coverageItem.limit || []).map((limitItem) => (
              <div className="form-group-container">
                <Input
                  title="Limit Amount"
                  type="text"
                  value={limitItem.limitAmount}
                  disabled
                />
                <Input
                  title="Limit Appliesto Code"
                  type="text"
                  value={limitItem.limitAppliestoCode}
                  disabled
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Card>
  );
}
