import React, { useEffect, useState } from "react";
import { Card, Input, Loading, ScreenMask } from "@components";
import { Button, Switch, Select, Radio } from "antd";
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import "./style.scss";
import { getPrimaryContact } from "@utils/common";
import POCCreate from "@components/modals/poc-create";
import { PREFERRED_CONTACT_METHOD_LIST, ROLE_LIST } from "@assets/const/ui";

export default function POCContact(props) {
  const { franchiseeData, setFranchiseeData } = props;
  const { getAccessTokenSilently, user } = useAuth0();
  const [contactList, setContactList] = useState([]);
  const [logPOCContacts, setLogPOCContacts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setContactList(handleGetFormVal());
    setLogPOCContacts(franchiseeData?.log_poc_contacts || []);
  }, [franchiseeData]);

  function handleChange(index, key, value) {
    contactList[index][key] = value;
    setContactList([...contactList]);
  }

  function handleGetFormVal() {
    const pocContacts = JSON.parse(
      JSON.stringify(franchiseeData?.poc_contacts || [])
    );
    const primaryContact = getPrimaryContact(franchiseeData);
    if (
      primaryContact?.email &&
      !pocContacts.find(
        (contactItem) => contactItem.email === primaryContact.email
      )
    ) {
      pocContacts.unshift(primaryContact);
    }
    return pocContacts;
  }

  async function handleSave() {
    setLoading(true);
    logPOCContacts.push({
      updatedBy: user.email,
      updatedAt: new Date(),
      data: contactList
    });
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          poc_contacts: contactList,
          log_poc_contacts: logPOCContacts
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setLoading(false);
      setLogPOCContacts([...logPOCContacts]);
    } catch (error) {
      setLoading(false);
    }
  }

  function handleAdd() {
    setIsOpen(true);
  }

  function handleDelete(index) {
    contactList.splice(index, 1);
    setContactList([...contactList]);
  }

  const renderContactItem = (contactItem, index, disabled) => {
    return (
      <div className="poc_contacts_item">
        {!disabled && (
          <div
            className="poc_contacts_item-action"
            onClick={() => handleDelete(index)}
          >
            <DeleteOutlined />
          </div>
        )}
        <div className="poc_contacts_item-wrapper">
          <div>
            <Input
              title="First Name*"
              type="text"
              placeholder="First Name*"
              value={contactItem.firstName}
              onChange={(evt) =>
                handleChange(index, "firstName", evt.target.value)
              }
            ></Input>
            <Input
              title="Last Name*"
              type="text"
              placeholder="Last Name*"
              value={contactItem.lastName}
              onChange={(evt) =>
                handleChange(index, "lastName", evt.target.value)
              }
            ></Input>
            <Input
              title="Email*"
              type="text"
              placeholder="Email*"
              value={contactItem.email}
              onChange={(evt) => handleChange(index, "email", evt.target.value)}
            ></Input>

            <Input
              title="Cell Phone*"
              type="text"
              placeholder="Cell Phone*"
              value={contactItem.telephone}
              onChange={(evt) =>
                handleChange(index, "telephone", evt.target.value)
              }
            ></Input>
            <Input
              title="Direct Line*"
              type="text"
              placeholder="Direct Line*"
              value={contactItem.directLine}
              onChange={(evt) =>
                handleChange(index, "directLine", evt.target.value)
              }
            ></Input>
          </div>
          <div className="poc_contacts_item-wrapper-con">
            <div className="poc_contacts_item-wrapper-con-item">
              <span className="poc_contacts_item-wrapper-con-item-title">
                Primary
              </span>
              <Switch
                checked={contactItem.isPrimary}
                onChange={(checked) =>
                  handleChange(index, "isPrimary", checked)
                }
              />
            </div>
            <div className="poc_contacts_item-wrapper-con-item">
              <span className="poc_contacts_item-wrapper-con-item-title">
                Active
              </span>
              <Switch
                checked={contactItem.isActive}
                onChange={(checked) => handleChange(index, "isActive", checked)}
              />
            </div>
            <div className="poc_contacts_item-wrapper-con-item">
              <span className="poc_contacts_item-wrapper-con-item-title">
                Policy Status Verification
              </span>
              <Switch
                checked={!contactItem.disableRMSInsVer}
                onChange={(checked) =>
                  handleChange(index, "disableRMSInsVer", !checked)
                }
              />
            </div>
            <div className="poc_contacts_item-wrapper-con-item">
              <span className="poc_contacts_item-wrapper-con-item-title">
                Compliance Status Verification
              </span>
              <Switch
                checked={contactItem.enableRMSCompliance}
                onChange={(checked) =>
                  handleChange(index, "enableRMSCompliance", checked)
                }
              />
            </div>
            <div className="poc_contacts_item-wrapper-con-item">
              <span className="poc_contacts_item-wrapper-con-item-title">
                Role
              </span>
              <Select
                style={{ width: "120px" }}
                value={contactItem.role}
                onChange={(val) => handleChange(index, "role", val)}
                options={ROLE_LIST}
              />
            </div>
            <div className="poc_contacts_item-wrapper-con-item">
              <span className="poc_contacts_item-wrapper-con-item-title">
                Preferred Contact Method
              </span>
              <Radio.Group
                onChange={(evt) =>
                  handleChange(index, "preferredMethod", evt.target.value)
                }
                value={contactItem.preferredMethod}
              >
                {PREFERRED_CONTACT_METHOD_LIST.map((item) => (
                  <Radio value={item.value}>{item.label}</Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
        </div>
        {disabled && <ScreenMask />}
      </div>
    );
  };

  return (
    <Card
      title="POC Contacts"
      extra={() => (
        <Button onClick={handleAdd} type="primary">
          <UserAddOutlined /> Add Contact
        </Button>
      )}
    >
      <div className="poc_contacts">
        {contactList.map((contactItem, index) => {
          return renderContactItem(contactItem, index);
        })}
        <div className="poc_contacts-footer">
          <Button size="large" type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
        <Loading loading={loading} />
      </div>
      <POCCreate
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
    </Card>
  );
}
