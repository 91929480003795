import Card from "@components/card";
import React, { useMemo } from "react";
import { Card as AntCard, Table, notification } from "antd";
import "./style.scss";
import { format } from "date-fns";
import { SEC_NAMING, DOC_NAMING, TEMPLATE_NAMING } from "@assets/const/ui";

export default function ReportBlock({ franchiseeData }) {
  const emailReportColumn = [
    {
      title: "Date Sent",
      dataIndex: "requestAt",
      key: "requestAt",
      render: (field, record) => <div>{format(field, "PPP, HH:mm")}</div>
    },
    {
      title: "Recipient",
      dataIndex: "requestTo",
      key: "requestTo"
    },
    {
      title: "Sender / User",
      dataIndex: "requestBy",
      key: "requestBy"
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      key: "receipt"
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document"
    },
    {
      title: "Request Link",
      dataIndex: "url",
      key: "url",
      render: (record) => (
        <a
          onClick={() => {
            navigator.clipboard.writeText(record);
            notification.open({
              message: "Request Url was copied",
              description: "",
              duration: 3
            });
          }}
        >
          Link
        </a>
      )
    },
    {
      title: "Template",
      dataIndex: "template",
      key: "template"
    }
  ];
  const emailReportData = useMemo(() => {
    const logRequest = franchiseeData?.logRequest || [];
    return logRequest
      .map((item) => ({
        requestAt: new Date(item.requestAt),
        requestTo: item.requestTo,
        requestBy: item.requestBy,
        receipt: (item.sec || []).map((item) => SEC_NAMING[item]).join(","),
        document: (item.doc || []).map((item) => DOC_NAMING[item]).join(","),
        url: item.url,
        template: TEMPLATE_NAMING[item.template]
      }))
      .sort((a, b) => (a.requestAt > b.requestAt ? -1 : 1));
  }, [franchiseeData]);

  const versionReportColumn = [
    {
      title: "Date Saved",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (field, record) => <div>{format(field, "PPP, HH:mm")}</div>
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy"
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      render: (text) => <div>{text.join(", ")}</div>
    }
  ];
  const versionReportData = useMemo(() => {
    const logInsuranceData = (franchiseeData?.logInsuranceData || []).map(
      (item) => ({
        updatedAt: new Date(item.updatedAt),
        updatedBy: item.updatedBy,
        section: ["Entity"]
      })
    );
    const logOfficeList = (franchiseeData?.logOfficeList || []).map((item) => ({
      updatedAt: new Date(item.updatedAt),
      updatedBy: item.updatedBy,
      section: ["Owner/Office"]
    }));
    const log_poc_contacts = (franchiseeData?.log_poc_contacts || []).map(
      (item) => ({
        updatedAt: new Date(item.updatedAt),
        updatedBy: item.updatedBy,
        section: ["POC"]
      })
    );
    const logBuildOutInfo = (franchiseeData?.logBuildOutInfo || []).map(
      (item) => ({
        updatedAt: new Date(item.updatedAt),
        updatedBy: item.updatedBy,
        section: ["Build Out"]
      })
    );
    const logVehicleList = (franchiseeData?.logVehicleList || []).map(
      (item) => ({
        updatedAt: new Date(item.updatedAt),
        updatedBy: item.updatedBy,
        section: ["Vehicle"]
      })
    );
    const logCommonLocList = (franchiseeData?.logCommonLocList || []).map(
      (item) => ({
        updatedAt: new Date(item.updatedAt),
        updatedBy: item.updatedBy,
        section: ["Common Location"]
      })
    );
    const logPayrollList = (franchiseeData?.logPayrollList || []).map(
      (item) => ({
        updatedAt: new Date(item.updatedAt),
        updatedBy: item.updatedBy,
        section: ["Payroll"]
      })
    );
    const logSubcontractorList = (
      franchiseeData?.logSubcontractorList || []
    ).map((item) => ({
      updatedAt: new Date(item.updatedAt),
      updatedBy: item.updatedBy,
      section: ["Subcontractor"]
    }));
    const logEquipmentList = (franchiseeData?.logEquipmentList || []).map(
      (item) => ({
        updatedAt: new Date(item.updatedAt),
        updatedBy: item.updatedBy,
        section: ["Scheduled Equipment"]
      })
    );
    const logUnScheEquipList = (franchiseeData?.logUnScheEquipList || []).map(
      (item) => ({
        updatedAt: new Date(item.updatedAt),
        updatedBy: item.updatedBy,
        section: ["Unscheduled Equipment"]
      })
    );
    const logDriverList = (franchiseeData?.logDriverList || []).map((item) => ({
      updatedAt: new Date(item.updatedAt),
      updatedBy: item.updatedBy,
      section: ["Driver"]
    }));
    const totalLogs = [
      ...logInsuranceData,
      ...logOfficeList,
      ...log_poc_contacts,
      ...logBuildOutInfo,
      ...logVehicleList,
      ...logCommonLocList,
      ...logPayrollList,
      ...logSubcontractorList,
      ...logEquipmentList,
      ...logUnScheEquipList,
      ...logDriverList
    ];
    let logData = [];
    totalLogs
      .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
      .forEach((item) => {
        const matchedIndex = logData.findIndex((logDataItem) => {
          if (
            format(item.updatedAt, "MM/dd/yyyy") ===
              format(logDataItem.updatedAt, "MM/dd/yyyy") &&
            logDataItem.updatedBy === item.updatedBy
          ) {
            return true;
          }
          return false;
        });
        if (matchedIndex >= 0) {
          logData[matchedIndex].section = logData[matchedIndex].section.concat(
            item.section
          );
        } else {
          logData.push(item);
        }
      });
    return logData;
  }, [franchiseeData]);

  return (
    <Card className="se_re" title="Send/Receive Report">
      <AntCard className="se_re-con" title="Email Send Reciepts">
        <Table
          columns={emailReportColumn}
          dataSource={emailReportData}
          pagination={{ pageSize: 5 }}
        ></Table>
      </AntCard>
      <AntCard className="se_re-con" title="Version Report">
        <Table
          columns={versionReportColumn}
          dataSource={versionReportData}
          pagination={{ pageSize: 5 }}
        ></Table>
      </AntCard>
    </Card>
  );
}
