import { post } from "@utils/axios";
import { format } from "date-fns";

export async function handleFetchReportList(
  zorAdmin,
  setLogMainData,
  setLogPartData,
  getAccessTokenSilently
) {
  try {
    const token = await getAccessTokenSilently();
    const result = await post(
      `reports/list/${zorAdmin?._id}`,
      {
        type: "REPORT_TYPE_FRANCHISOR_OVERVIEW",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (result.status === 200) {
      const data = result.data.data;
      const reportsData = {};
      const reportsMainList = [];
      const reportsPartList = [];
      data.forEach((item) => {
        reportsData[format(new Date(item.createdAt), "yyyy-MM")] = item.detail;
      });
      Object.entries(reportsData).forEach(([date, detail]) => {
        reportsMainList.push(
          { coverage: "Franchisees", date, value: detail.countFranchisee },
          {
            coverage: "Rikor Customers",
            date,
            value: detail.countRikorCustomer,
          }
        );
        reportsPartList.push(
          { coverage: "Lapse in Coverage", date, value: detail.countExpired },
          { coverage: "Non-compliant", date, value: detail.countNonCompliant },
          { coverage: "Not-Verified", date, value: detail.countPending }
        );
      });
      setLogMainData(reportsMainList);
      setLogPartData(reportsPartList);
    }
  } catch (error) {
    console.error("Error fetching report list:", error);
  }
}
