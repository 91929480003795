import React, { useEffect, useState } from "react";
import "./style.scss";
import { Card, Loading } from "@components";
import { Empty, Card as AntCard } from "antd";
import {
  ArrowRightOutlined,
  RollbackOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useAppState } from "@context";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, post } from "@utils/axios";
import { getHostTag } from "@utils/host";
import { DOC_NAMING } from "@assets/const/ui";

export default function AdminNotification(props) {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { notifications, setNotifications } = useAppState();
  const [notificationList, setNotificationList] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    handleGetNotification();
  }, [notifications]);

  function handleNavDetail(item) {
    history.push(`/admin${item.url}`);
  }

  async function handleGetNotification() {
    try {
      setLoading(true);
      const result = {};
      const idList = [];
      const notificationUpdate = [...notifications];
      notificationUpdate.reverse().forEach((item) => {
        idList.push(item._id);
        const date = moment(item.updatedAt).format("YYYY-MM-DD");
        if (result[date]) {
          result[date].push({
            ...item,
            time: moment(item.updatedAt).format("hh:mm a")
          });
        } else {
          result[date] = [
            { ...item, time: moment(item.updatedAt).format("hh:mm a") }
          ];
        }
      });

      localStorage.setItem("notifications", idList);

      setNotificationList(Object.entries(result));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function handleLoadNotification() {
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `notification/query`,
        { requirementId: "admin", host: getHostTag() },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const data = result?.data?.data || [];
      setNotifications(data);
    } catch (error) {
      console.log("notification get error ---", error);
    }
  }

  async function handleRemove(id) {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await deleteAPI(`notification/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      await handleLoadNotification();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("handle remove error ---", error);
    }
  }

  function renderNotificationItem(notificationItem) {
    return (
      <AntCard
        style={{ width: "100%" }}
        size="small"
        title={
          notificationItem?.note
            ? `${notificationItem.title}${DOC_NAMING[notificationItem.note]}`
            : notificationItem.title
        }
        extra={
          <a onClick={() => handleRemove(notificationItem._id)}>
            <CloseCircleOutlined />
          </a>
        }
      >
        <div className="admin_noti-item">
          <div>
            <div>{notificationItem.time}</div>
            <div>{notificationItem.body}</div>
          </div>
          <div
            className="admin_noti-item-action"
            onClick={() => handleNavDetail(notificationItem)}
          >
            View Detail <ArrowRightOutlined />
          </div>
        </div>
      </AntCard>
    );
  }

  function handleBack() {
    history.goBack();
  }

  return (
    <div className="admin-notification-container">
      <Card
        title="Notification"
        extra={() => (
          <a onClick={handleBack}>
            <RollbackOutlined />
          </a>
        )}
      >
        {notificationList.map((dateItem, index) => (
          <div className="date-container">
            <div>{dateItem[0]}</div>
            <div className="admin_noti">
              {dateItem[1].map((notificationItem) =>
                renderNotificationItem(notificationItem)
              )}
            </div>
          </div>
        ))}
        {notificationList.length === 0 && <Empty />}
      </Card>
      <Loading loading={loading} />
    </div>
  );
}
