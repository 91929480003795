import React from "react";
import { Card } from "../../../../components";
import { Table, notification } from "antd";
import {
  INSURANCE_STATUS_NONE,
  INSURANCE_STATUS_COMPLIANT,
  INSURANCE_STATUS_IN_PLACE,
  INSURANCE_STATUS_NON_COMPLIANT,
  INSURANCE_STATUS_NON_VERIFIED,
  STATUS_SECTION_APPROVE
} from "../../../../assets/const/status";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { post, postDoc } from "../../../../utils/axios";
import IconDoc from "../../../../assets/images/icon-document.png";
import { handleGetComplianceStatus } from "@utils/build-logic";
import { getItemRenewalPolicyStatus } from "@utils/common";

export default function ComplianceList(props) {
  const { franchiseeData, franchisorData } = props;
  const { getAccessTokenSilently } = useAuth0();

  async function handleDownload(evt, filePath, fileInfor) {
    evt.stopPropagation();
    evt.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const fileNameArr = filePath.split("/");
      const fileName = fileNameArr[fileNameArr.length - 1];
      const extensionArr = fileName.split(".");
      const extension = extensionArr[extensionArr.length - 1];
      postDoc(
        `download`,
        {
          fileName: fileName,
          envMode: process.env.REACT_APP_DOC_UPLOAD_MODE
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${franchiseeData.last_name}-${
            franchiseeData.first_name
          }-COI-${moment(fileInfor.upload_at).format("DD-MM-YY")}.${extension}`
        );
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      handleNotification(
        "Failed",
        "Document downloading is failed. Please contact admin directly."
      );
      console.log("error", err);
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  const dataSourceCompliance = [];

  if (franchisorData?.commercial_general_liability_required) {
    dataSourceCompliance.push({
      name: "Liability",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.commercial_general_liability_expire_date,
        franchisorData?.commercial_general_liability_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.commercial_general_liability_form_status,
        franchiseeData?.liabilityIssue
      ),
      filePath:
        franchiseeData?.finalData?.commercial_general_liability_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData
                .commercial_general_liability_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.commercial_general_liability_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData
                .commercial_general_liability_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.liabilityIssue || []).length,
      expirationDate:
        franchiseeData?.finalData?.commercial_general_liability_expire_date
    });
  }

  if (franchisorData?.automotive_liability_required) {
    dataSourceCompliance.push({
      name: "Auto",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.automotive_liability_expire_date,
        franchisorData?.automotive_liability_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.automotive_liability_form_status,
        franchiseeData?.autoIssue
      ),
      filePath:
        franchiseeData?.finalData?.automotive_liability_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.automotive_liability_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.automotive_liability_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.automotive_liability_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.autoIssue || []).length,
      expirationDate:
        franchiseeData?.finalData?.automotive_liability_expire_date
    });
  }

  if (franchisorData?.workers_compensation_required) {
    dataSourceCompliance.push({
      name: "Workers Compensation",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.workers_compensation_expire_date,
        franchisorData?.workers_compensation_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.workers_compensation_form_status,
        franchiseeData?.workCompIssue
      ),
      filePath:
        franchiseeData?.finalData?.workers_compensation_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.workers_compensation_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.workers_compensation_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.workers_compensation_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.workCompIssue || []).length,
      expirationDate:
        franchiseeData?.finalData?.workers_compensation_expire_date
    });
  }

  if (franchisorData?.umbrella_subrogration_required) {
    dataSourceCompliance.push({
      name: "Umbrella",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.umbrella_subrogration_expire_date,
        franchisorData?.umbrella_subrogration_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.umbrella_subrogration_form_status,
        franchiseeData?.umbrellaIssue
      ),
      filePath:
        franchiseeData?.finalData?.umbrella_subrogration_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.umbrella_subrogration_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.umbrella_subrogration_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.umbrella_subrogration_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.umbrellaIssue || []).length,
      expirationDate:
        franchiseeData?.finalData?.umbrella_subrogration_expire_date
    });
  }

  if (franchisorData?.crime_required) {
    dataSourceCompliance.push({
      name: "Crime",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.crime_expire_date,
        franchisorData?.crime_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.crime_form_status,
        franchiseeData?.crimeIssue
      ),
      filePath:
        franchiseeData?.finalData?.crime_form_status === STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.crime_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.crime_form_status === STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.crime_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.crimeIssue || []).length,
      expirationDate: franchiseeData?.finalData?.crime_expire_date
    });
  }

  if (franchisorData?.garage_required) {
    dataSourceCompliance.push({
      name: "Garage",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.garage_expire_date,
        franchisorData?.garage_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.garage_form_status,
        franchiseeData?.garageIssue
      ),
      filePath:
        franchiseeData?.finalData?.garage_form_status === STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.garage_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.garage_form_status === STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.garage_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.garageIssue || []).length,
      expirationDate: franchiseeData?.finalData?.garage_expire_date
    });
  }

  if (franchisorData?.epli_required) {
    dataSourceCompliance.push({
      name: "Employment Practices Liability",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.epli_expire_date,
        franchisorData?.epli_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.epli_form_status,
        franchiseeData?.epliIssue
      ),
      filePath:
        franchiseeData?.finalData?.epli_form_status === STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.epli_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.epli_form_status === STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.epli_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.epliIssue || []).length,
      expirationDate: franchiseeData?.finalData?.epli_expire_date
    });
  }

  if (franchisorData?.cyber_required) {
    dataSourceCompliance.push({
      name: "Cyber",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.cyber_expire_date,
        franchisorData?.cyber_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.cyber_form_status,
        franchiseeData?.cyberIssue
      ),
      filePath:
        franchiseeData?.finalData?.cyber_form_status === STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.cyber_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.cyber_form_status === STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.cyber_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.cyberIssue || []).length,
      expirationDate: franchiseeData?.finalData?.cyber_expire_date
    });
  }

  if (franchisorData?.pro_liab_required) {
    dataSourceCompliance.push({
      name: "Professional Liability",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.pro_liab_expire_date,
        franchisorData?.pro_liab_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.pro_liab_form_status,
        franchiseeData?.proLiabIssue
      ),
      filePath:
        franchiseeData?.finalData?.pro_liab_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.pro_liab_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.pro_liab_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.pro_liab_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.proLiabIssue || []).length,
      expirationDate: franchiseeData?.finalData?.pro_liab_expire_date
    });
  }

  if (franchisorData?.pol_liab_required) {
    dataSourceCompliance.push({
      name: "Pollution Liability",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.pol_liab_expire_date,
        franchisorData?.pol_liab_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.pol_liab_form_status,
        franchiseeData?.polLiabIssue
      ),
      filePath:
        franchiseeData?.finalData?.pol_liab_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.pol_liab_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.pol_liab_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.pol_liab_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.polLiabIssue || []).length,
      expirationDate: franchiseeData?.finalData?.pol_liab_expire_date
    });
  }

  if (franchisorData?.im_required) {
    dataSourceCompliance.push({
      name: "Inland Marine",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.im_expire_date,
        franchisorData?.im_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.im_form_status,
        franchiseeData?.imIssue
      ),
      filePath:
        franchiseeData?.finalData?.im_form_status === STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.im_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.im_form_status === STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.im_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.imIssue || []).length,
      expirationDate: franchiseeData?.finalData?.im_expire_date
    });
  }

  if (franchisorData?.pro_ins_required) {
    dataSourceCompliance.push({
      name: "Property Insurance",
      policyStatus: getItemRenewalPolicyStatus(
        franchiseeData?.finalData?.pro_ins_expire_date,
        franchisorData?.pro_ins_required
      ),
      complianceStatus: handleGetComplianceStatus(
        franchiseeData?.finalData?.pro_ins_form_status,
        franchiseeData?.proInsIssue
      ),
      filePath:
        franchiseeData?.finalData?.pro_ins_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.pro_ins_form_doc_index
            ]?.filePath
          : null,
      fileInfor:
        franchiseeData?.finalData?.pro_ins_form_status ===
        STATUS_SECTION_APPROVE
          ? franchiseeData.fileUpload[
              franchiseeData.finalData.pro_ins_form_doc_index
            ]
          : null,
      analysis: (franchiseeData?.proInsIssue || []).length,
      expirationDate: franchiseeData?.finalData?.pro_ins_expire_date
    });
  }

  dataSourceCompliance.push({
    name: "Additional Information",
    complianceStatus: handleGetComplianceStatus(
      franchiseeData?.finalData?.additional_infor_form_status,
      franchiseeData?.additionalIssue
    ),
    filePath:
      franchiseeData?.finalData?.additional_infor_form_status ===
      STATUS_SECTION_APPROVE
        ? franchiseeData.fileUpload[
            franchiseeData.finalData.additional_infor_doc_index
          ]?.filePath
        : null,
    fileInfor:
      franchiseeData?.finalData?.additional_infor_form_status ===
      STATUS_SECTION_APPROVE
        ? franchiseeData.fileUpload[
            franchiseeData.finalData.additional_infor_doc_index
          ]
        : null,
    analysis: (franchiseeData?.additionalIssue || []).length,
    expirationDate: null
  });

  const columnsCompliance = [
    {
      title: "Required",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Policy Status",
      dataIndex: "policyStatus",
      key: "policyStatus"
    },
    {
      title: "Compliance Status",
      dataIndex: "complianceStatus",
      key: "complianceStatus"
    },
    {
      title: "Expiration Date",
      dataIndex: "expirationDate",
      key: "expirationDate",
      render: (expirationDate) => (
        <span>
          {expirationDate ? moment(expirationDate).format("MM/DD/YYYY") : ""}
        </span>
      )
    },
    {
      title: "File",
      dataIndex: "filePath",
      key: "filePath",
      render: (filePath, fileInfor) => {
        if (filePath) {
          return (
            <a
              className="file-item-container"
              href={filePath}
              onClick={(evt) => handleDownload(evt, filePath, fileInfor)}
            >
              <img src={IconDoc} />
            </a>
          );
        } else {
          return null;
        }
      }
    },
    {
      title: "Analysis",
      dataIndex: "analysis",
      key: "analysis"
    }
  ];
  return (
    <Card title="Compliance">
      <Table dataSource={dataSourceCompliance} columns={columnsCompliance} />
    </Card>
  );
}
