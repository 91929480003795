import React, { useEffect, useState } from "react";
import { Switch, Button, Modal } from "antd";
import InsuranceRequirementsTable from "./insurance-requirements-table";
import InsuranceRequirementsForm from "./insurance-requirements-form";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, post, get, put } from "@utils/axios";
import { getTemplateParam } from "@utils/template";
import "./style.scss";
import { format } from "date-fns";
import { useAppState } from "@context";
import TemplateCreate from "./template-create";

const MODAL_TYPE_SELECT = "MODAL_TYPE_SELECT";
const MODAL_TYPE_CREATE = "MODAL_TYPE_CREATE";
const MODAL_TYPE_NONE = "MODAL_TYPE_NONE";

export default function InsuranceRequirements() {
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const [isTable, setIsTable] = useState(false);
  const [modalInfor, setModalInfor] = useState({
    type: MODAL_TYPE_NONE,
    title: "",
  });
  const { getAccessTokenSilently } = useAuth0();
  const [templateList, setTemplateList] = useState([]);

  useEffect(() => {
    handleLoadTemplate();
  }, []);

  async function handleLoadTemplate() {
    try {
      const token = await getAccessTokenSilently();
      const result = await get(`requirement-template`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result?.data?.success) {
        setTemplateList(result?.data?.data || []);
      }
    } catch (error) {}
  }

  function handleSelectTemplate() {
    setModalInfor({
      type: MODAL_TYPE_SELECT,
      title: "Select Template",
    });
  }

  function handleSelect(selected) {
    const item = getTemplateParam(selected);

    setFranchisorAdmin({
      ...franchisorAdmin,
      ...item,
    });

    setModalInfor({
      type: MODAL_TYPE_NONE,
      title: "",
    });
  }

  function handleSaveTemplate() {
    setModalInfor({
      type: MODAL_TYPE_CREATE,
      title: "Save Template As",
    });
  }

  function handleClose() {
    setModalInfor({
      type: MODAL_TYPE_NONE,
      title: "",
    });
  }

  return (
    <div className="admin_insurance_requirement">
      <div className="admin_insurance_requirement-header">
        <div className="admin_insurance_requirement-header-action">
          {isTable && (
            <>
              <Button
                size="large"
                className="section_table-header-toolbar-select"
                onClick={handleSelectTemplate}
              >
                Select Template
              </Button>

              <Button size="large" onClick={handleSaveTemplate}>
                Save As Template
              </Button>
            </>
          )}

          <label>Form View</label>
          <Switch checked={isTable} onChange={() => setIsTable(!isTable)} />
          <label>Table View</label>
        </div>
      </div>
      {!isTable && <InsuranceRequirementsForm />}
      {isTable && <InsuranceRequirementsTable />}
      <Modal
        title={modalInfor.title}
        open={modalInfor.type !== MODAL_TYPE_NONE}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        {modalInfor.type === MODAL_TYPE_SELECT && (
          <div className="modal_template_select">
            {templateList.map((item) => (
              <div
                className="modal_template_select-item"
                onClick={() => handleSelect(item)}
              >
                <div className="modal_template_select-item-title">
                  {item.title}
                </div>
                <div className="modal_template_select-item-date">
                  {format(new Date(item.createdAt), "dd MMM yyyy")}
                </div>
              </div>
            ))}
          </div>
        )}
        {modalInfor.type === MODAL_TYPE_CREATE && (
          <TemplateCreate onLoad={handleLoadTemplate} onClose={handleClose} />
        )}
      </Modal>
    </div>
  );
}
