import React, { useEffect, useMemo } from "react";
import { Input } from "@components";
import "./style.scss";
import { buildLocationVerify } from "@utils/build-logic";
import { DOC_MAX_LIMIT } from "@assets/const/status";
import {
  mapFieldCertHolderAddress,
  mapFieldInsuredInfo,
} from "@utils/form-recog";

export default function LocationForm(props) {
  const { locationList = [], docIndex = -1, franchiseeData } = props;

  const verifyStatus = useMemo(() => {
    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      const certInformation = data ? mapFieldCertHolderAddress(data) : "";
      const insuredInformation = data ? mapFieldInsuredInfo(data) : "";
      return locationList.map((item) => {
        const insuredVerify = buildLocationVerify(
          insuredInformation,
          `${item.address} ${item.city} ${item.state} ${item.zip}`
        );
        const certVerify = buildLocationVerify(
          certInformation,
          `${item.address} ${item.city} ${item.state} ${item.zip}`
        );

        return !insuredVerify?.error || !certVerify.error;
      });
    } else {
      return new Array(locationList.length).fill(false);
    }
  }, [docIndex, locationList, franchiseeData]);

  return (
    <div className="location">
      <h3 className="location-title">Location</h3>
      <div className="location_form">
        {docIndex < 0 && (
          <div className="location_form-error">Need to approve Liability</div>
        )}
        {locationList.map((item, index) => (
          <>
            <div className="location_form-item">
              <Input
                className="input_visible"
                title="Location"
                placeholder="Location"
                value={item?.address}
                disabled
              />
              <Input
                className="input_visible"
                title="City"
                placeholder="City"
                value={item?.city}
                disabled
              />
              <Input
                className="input_visible"
                title="State"
                placeholder="State"
                value={item?.state}
                disabled
              />
              <Input
                className="input_visible"
                title="Zip"
                placeholder="Zip"
                value={item?.zip}
                disabled
              />
            </div>
            {verifyStatus[index] && (
              <div className="location_form-success">
                Location is matched on COI
              </div>
            )}
            {!verifyStatus[index] && (
              <div className="location_form-error">Location missing on COI</div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
