import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignIn from "../containers/sign-in";
import SignUp from "../containers/sign-up";
import DeniedAccess from "../containers/denied-access";
import Onboarding from "../containers/onboarding";
import Dashboard from "../containers/dashboard";
import FranChiseeBoard from "../containers/franchisee-board";
import FranchiseeSection from "../containers/franchisee-section";
import AdminBoard from "../containers/admin-board";
import AffiliateDashboard from "../containers/affiliate";
import AffiliatePreview from "../containers/affiliate/preview";
import Main from "../containers/main";
import AuthContainer from "../containers/auth";
import Quote from "../containers/quote";
import PrivacyPolicy from "../containers/privacy-policy";
import TermsAndConditions from "../containers/terms-and-conditions";

function Layout() {
  return (
    <Router>
      <Switch>
        <Route path="/sign-in" component={AuthContainer(SignIn)} />
        <Route path="/sign-up" component={AuthContainer(SignUp)} />
        <Route path="/onboarding" component={AuthContainer(Onboarding)} />
        <Route path="/dashboard" component={AuthContainer(Dashboard)} />
        <Route path="/board" component={AuthContainer(FranChiseeBoard)} />
        <Route path="/admin" component={AuthContainer(AdminBoard)} />
        <Route path="/coiportal/" component={FranchiseeSection} />
        <Route path="/affiliate/:id" exact component={AffiliatePreview} />
        <Route path="/quote" component={Quote} />
        <Route path="/terms" component={TermsAndConditions} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route
          path="/affiliates"
          component={AuthContainer(AffiliateDashboard)}
        />
        <Route path="/404" component={DeniedAccess} />
        <Route path="/" component={AuthContainer(Main)} />
      </Switch>
    </Router>
  );
}

export default Layout;
