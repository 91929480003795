import React, { useState } from "react";
import { Header } from "../../../components";
import { Button } from "antd";
import "./style.scss";
import noDataImage from "../../../assets/images/no-data.png";
import uploadImage from "../../../assets/images/upload.png";

function SyncFranchisee(props) {
  function handleConnect(method) {
    const { handleOnboardProcess } = props;
    handleOnboardProcess(method);
  }

  return (
    <div className="sync-franchisee-container">
      <h3 className="sync-franchisee-title">Sync Franchisees</h3>
      <div className="sync-franchisee-card">
        <div className="content-wrapper">
          <img src={uploadImage} className="content-image" />
          <h4>Connect to your platform</h4>
          <Button type="primary" onClick={() => handleConnect("platform")}>
            Connect
          </Button>

          <p>
            Don't have a platform?{" "}
            <a href="#" onClick={() => handleConnect("upload")}>
              Upload CSV.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SyncFranchisee;
