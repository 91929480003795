export default function TermsAndConditions() {
  return (
    <div style={{ padding: 28 }}>
      <h1>TERMS AND CONDITIONS FOR INSURANCE COMPLIANCE MONITORING SOFTWARE</h1>
      <p>By Rikor Insurance Consulting</p>

      <h2>1. Introduction:</h2>
      <p>
        Welcome to Rikor Insurance Consulting's Insurance Compliance Monitoring
        Software (Risk Management System (RMS)). By accessing or using our
        software (RMS), you agree to abide by these Terms and Conditions. Please
        read them carefully.
      </p>

      <h2>2. Eligibility:</h2>
      <h3>a. Specific Users:</h3>
      <p>
        The Insurance Compliance Monitoring Software provided by Rikor Insurance
        Consulting is exclusively available to franchisors and franchisees, as
        well as individuals or entities explicitly elected by them to manage
        their respective accounts.
      </p>

      <h3>b. Verification:</h3>
      <p>
        Rikor Insurance Consulting reserves the right to verify the eligibility
        of users. Any unauthorized access or use by individuals or entities not
        meeting the above criteria may result in immediate termination of access
        and potential legal action.
      </p>

      <h3>c. Account Management:</h3>
      <p>
        Franchisors and franchisees who elect an individual or entity to manage
        their account must do so at their own discretion. Rikor Insurance
        Consulting is not liable for any actions or breaches caused by these
        elected account managers, and any responsibility or consequence arising
        from their actions remains with the respective franchisor or franchisee
        whom permission was obtained from.
      </p>

      <h2>3. License Grant:</h2>
      <h3>a. Grant of License:</h3>
      <p>
        Upon accessing or downloading the Insurance Compliance Monitoring
        Software, Rikor Insurance Consulting grants the user a personal,
        non-exclusive, non-transferable, and revocable license to use the
        software solely for its intended purpose, subject to these Terms and
        Conditions. This license does not imply any rights to future updates,
        upgrades, or any other enhancements to the software, though such may be
        provided at Rikor Insurance Consulting's discretion.
      </p>

      <h3>b. Scope of Use:</h3>
      <p>
        The software is licensed for individual and organizational use.
        Redistribution, resale, rental, or any other form of transfer of the
        software or its license to third parties is strictly prohibited without
        explicit written consent from Rikor Insurance Consulting.
      </p>

      <h3>c. Restrictions:</h3>
      <p>Users shall not:</p>
      <ul>
        <li>
          i. Reverse engineer, decompile, or disassemble the software or attempt
          to gain unauthorized access to any part of the software or its
          associated source code.
        </li>
        <li>
          ii. Modify, translate, or create derivative works based on the
          software.
        </li>
        <li>
          iii. Circumvent, disable, or otherwise interfere with security-related
          features or digital rights management functions of the software.
        </li>
      </ul>

      <h3>d. Termination of License:</h3>
      <p>
        Rikor Insurance Consulting reserves the right to terminate the license
        at any time if the user fails to comply with any term(s) set out in
        these Terms and Conditions. Upon such termination, the user shall cease
        all use and must delete or destroy all copies of the software.
      </p>

      <h2>4. User Responsibilities:</h2>
      <p>
        Users are responsible for ensuring data input accuracy, maintaining the
        confidentiality of their software account details, and ensuring the
        software is used within legal and intended guidelines.
      </p>

      <h2>5. Payments:</h2>
      <p>
        Users agree to timely pay any fees associated with the software.
        Specific payment terms, accepted methods, fees, and the refund policy
        will be detailed upon purchase or subscription.
      </p>

      <h3>a. No Charges:</h3>
      <p>
        The Insurance Compliance Monitoring Software provided by Rikor Insurance
        Consulting is offered to users free of charge. There are no hidden fees,
        subscription charges, or any other form of payment required to access or
        use the basic version of the software.
      </p>

      <h3>b. Optional Premium Features or Services:</h3>
      <p>
        (Include this section only if there are premium versions or features
        available for purchase) While the core features of the Insurance
        Compliance Monitoring Software are free, Rikor Insurance Consulting may
        offer additional premium features or services at a cost. Any such
        features or services, and their associated costs, will be clearly
        communicated to users, and any purchases will be subject to additional
        terms and conditions.
      </p>

      <h3>c. No Refund Obligations:</h3>
      <p>
        Since the Insurance Compliance Monitoring Software is offered free of
        charge, Rikor Insurance Consulting does not have any obligations to
        offer refunds or credits to users.
      </p>

      <h3>d. Price Changes:</h3>
      <p>
        Rikor Insurance Consulting reserves the right to introduce charges for
        the current free services or change the price of any premium feature or
        service at its discretion. Users will be provided with adequate notice
        of any such changes.
      </p>

      <h2>6. Intellectual Property:</h2>
      <p>
        All rights to the Insurance Compliance Monitoring Software, including
        but not limited to graphics, user interface, and algorithms, belong to
        Rikor Insurance Consulting and are protected by intellectual property
        laws.
      </p>

      <h3>a. Ownership:</h3>
      <p>
        All intellectual property rights in and to the Insurance Compliance
        Monitoring Software, including but not limited to source code, graphics,
        icons, sound effects, user interface, algorithms, and documentation
        (collectively referred to as the "Software"), are owned by or licensed
        to Rikor Insurance Consulting. This includes all derivatives, changes,
        or improvements made to the Software.
      </p>

      <h3>b. Protection:</h3>
      <p>
        The Software is protected under intellectual property laws, including
        but not limited to copyright, trademark, and trade secret laws of the
        relevant jurisdiction and international treaties. Any unauthorized
        reproduction, modification, distribution, or usage of the Software or
        any of its components may result in severe penalties under applicable
        law.
      </p>

      <h3>c. Restrictions:</h3>
      <p>Users are not permitted to:</p>
      <ul>
        <li>
          a. Reverse engineer, decompile, or disassemble the Software or attempt
          to gain unauthorized access to its source code.
        </li>
        <li>
          b. Remove, alter, or obscure any copyright, trademark, or other IP
          notices from any part of the Software.
        </li>
        <li>
          c. Lease, license, sublicense, sell, resell, transfer, assign, or
          distribute the Software to any third party without explicit written
          permission from Rikor Insurance Consulting.
        </li>
        <li>d. Modify or create derivative works based upon the Software.</li>
      </ul>

      <h3>d. Feedback & Improvements:</h3>
      <p>
        If a user provides feedback, comments, or suggestions for improvements
        to the Software (collectively referred to as "Feedback"), such Feedback
        will be the sole property of Rikor Insurance Consulting. Rikor Insurance
        Consulting may use, incorporate, modify, and distribute such Feedback
        without any obligations to credit or compensate the user.
      </p>

      <h3>e. Infringement:</h3>
      <p>
        Rikor Insurance Consulting will defend the Software against any claim by
        a third party that the Software infringes or violates any valid patent,
        copyright, or trademark of that third party, provided users:
      </p>
      <ul>
        <li>
          a. Promptly notify Rikor Insurance Consulting in writing of the claim.
        </li>
        <li>
          b. Cooperate with Rikor Insurance Consulting in the defense of the
          claim.
        </li>
        <li>
          c. Allow Rikor Insurance Consulting to control the defense and
          settlement of the claim.
        </li>
      </ul>

      <h2>7. Updates & Support:</h2>
      <p>
        From time to time, we may issue software updates. These updates may be
        automatic or manual, and users are expected to maintain their software
        versions to benefit from the latest features and security patches. Basic
        support will be provided, with details specified in the purchase
        agreement.
      </p>

      <h2>8. Termination:</h2>
      <p>
        Rikor Insurance Consulting reserves the right to terminate or suspend
        any user's access to the software without prior notice for violations of
        these Terms and Conditions or any other valid reason.
      </p>

      <h2>9. Limitation of Liability:</h2>
      <p>
        Rikor Insurance Consulting shall not be responsible for any damages or
        losses resulting from the use or inability to use our software or any
        data breaches caused by third-party intrusions.
      </p>

      <h3>a. No Liability for Unauthorized Access:</h3>
      <p>
        Rikor Insurance Consulting employs reasonable security measures to
        protect the data and information processed by the Insurance Compliance
        Monitoring Software. However, no system, platform, or security measure
        is entirely immune from vulnerabilities or potential breaches. Rikor
        Insurance Consulting shall not be liable for any unauthorized access,
        hacking, or third-party intrusions, or any resulting damages or losses
        stemming from such activities.
      </p>

      <h3>b. User's Responsibility:</h3>
      <p>
        It is the user's responsibility to maintain the security of their own
        systems, networks, and access credentials. Users are advised to
        routinely update their passwords, employ multifactor authentication
        where available, and ensure their systems have adequate security
        protections.
      </p>

      <h3>c. Notification of Breach:</h3>
      <p>
        In the event of a known security breach, Rikor Insurance Consulting will
        notify affected users as required by applicable law and take reasonable
        steps to mitigate the effects and prevent future breaches.
      </p>

      <h2>10. Governing Law:</h2>
      <p>
        These Terms and Conditions, and any disputes arising out of or related
        to them or the Insurance Compliance Monitoring Software, shall be
        governed by and construed in accordance with the laws of the State of
        Utah, without giving effect to its conflict of law principles. Parties
        specifically consent to the personal jurisdiction of the state and
        federal courts located in the State of Utah.
      </p>

      <h2>11. Dispute Resolution:</h2>
      <p>
        Disputes arising from these Terms and Conditions or the software will be
        resolved in the jurisdiction's courts where Rikor Insurance Consulting
        is based.
      </p>

      <h3>a. Jurisdiction and Venue:</h3>
      <p>
        All disputes, claims, or controversies arising out of or in connection
        with these Terms and Conditions, or the breach thereof, or any use of
        the Insurance Compliance Monitoring Software, shall be resolved
        exclusively in the state or federal courts located within the State of
        Utah. The parties agree to the personal jurisdiction and venue of these
        courts and waive any objection to inconvenient forum.
      </p>

      <h3>b. Alternative Dispute Resolution (optional but recommended):</h3>
      <p>
        Before initiating any formal legal proceedings, the parties agree to
        attempt to resolve any dispute amicably through negotiation or, if
        agreed upon, through mediation. If after [e.g., "30 days"] such efforts
        do not resolve the dispute, either party may initiate legal action.
      </p>

      <h2>12. Changes to Terms:</h2>
      <p>
        Rikor Insurance Consulting reserves the right to modify these Terms and
        Conditions at any time. Users will be notified of significant changes.
      </p>

      <h2>13. Contact:</h2>
      <p>
        For questions or concerns regarding these Terms and Conditions, please
        contact Rikor Insurance Consulting at{" "}
        <a href="mailto:hello@rikor.io">hello@rikor.io</a>.
      </p>
    </div>
  );
}
