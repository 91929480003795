import React, { useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Table, Tag, Space, Button, Form } from "antd";
import { Loading } from "../../../../../components";
import { post } from "../../../../../utils/axios";
import "./style.scss";
import {
  PLATFORM_FRANCONNECT,
  PLATFORM_SERVICE_MINDER,
} from "../../../../../assets/const/fms-entity";
import { getHostTag } from "../../../../../utils/host";

export default function OnboardFranchiseeForm({
  form,
  platform,
  franchiseeData,
  requirementId,
  onNext,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const data = useMemo(() => {
    if (platform === PLATFORM_FRANCONNECT) {
      const dataRaw = JSON.parse(JSON.stringify(franchiseeData.data));
      if (dataRaw && dataRaw.length > 0) {
        for (let index = 0; index < dataRaw.length; index++) {
          dataRaw[index].key = dataRaw[index].franchisee_no;
        }
        return dataRaw;
      } else {
        return [];
      }
    } else if (platform === PLATFORM_SERVICE_MINDER) {
      const dataRaw = JSON.parse(JSON.stringify(franchiseeData.data));
      if (dataRaw && dataRaw.length > 0) {
        for (let index = 0; index < dataRaw.length; index++) {
          dataRaw[index].key = dataRaw[index].Email;
        }
        return dataRaw;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [franchiseeData?.data, platform]);

  const [selectedRowKeys, setSelectedRowKeys] = useState(
    data.map((item) => item.key)
  );
  const [loading, setLoading] = useState(false);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function onSelectChange(selectedRowKeysUpdate) {
    setSelectedRowKeys(selectedRowKeysUpdate);
  }
  function handleSelectCancel() {
    setSelectedRowKeys([]);
  }

  async function handleImport() {
    if (platform === PLATFORM_FRANCONNECT) {
      await handleImportFranconnect();
    } else if (platform === PLATFORM_SERVICE_MINDER) {
      await handleImportServiceMinder();
    }
  }

  async function handleImportServiceMinder() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const selectedKeyData = selectedRowKeys.join(",");
      const sendData = (franchiseeData.data || []).filter((item) =>
        selectedKeyData.includes(item.Email)
      );

      let resultFranchiseeDetail = await post(
        `serviceminder/franchisee-detail`,
        {
          franchiseeData: sendData,
          requirementId: requirementId,
          host: getHostTag(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      onNext();
    } catch (e) {
      setLoading(false);
    }
  }

  async function handleImportFranconnect() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const franchiseeNo = selectedRowKeys.join(",");
      let resultFranchiseeDetail = await post(
        `franconnect/franchisee-detail`,
        {
          token: franchiseeData.token,
          tenantID: franchiseeData.tenantID,
          requirementId: requirementId,
          franchiseeNo: franchiseeNo,
          host: getHostTag(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      onNext();
    } catch (e) {
      setLoading(false);
    }
  }

  const columns = useMemo(() => {
    switch (platform) {
      case PLATFORM_FRANCONNECT:
        return [
          {
            title: "Franchisee Name",
            dataIndex: "franchisee_name",
            key: "franchisee_name",
          },
          {
            title: "Franchisee No",
            dataIndex: "franchisee_no",
            key: "franchisee_no",
          },
          {
            title: "Saved",
            dataIndex: "is_store",
            key: "is_store",
          },
        ];
      case PLATFORM_SERVICE_MINDER:
        return [
          {
            title: "Franchisee Name",
            dataIndex: "PublicName",
            key: "PublicName",
          },
          {
            title: "Email",
            dataIndex: "Email",
            key: "Email",
          },
          {
            title: "Website",
            dataIndex: "Website",
            key: "Website",
          },
        ];
      default:
        return [];
    }
  }, [platform]);

  return (
    <Form
      form={form}
      className="onboarding-form_onboard_franchisee"
      onFinish={handleImport}
    >
      <div className="onboarding-form_onboard_franchisee-wrapper">
        <h4>Franchisees</h4>
        <div className="onboarding-form_onboard_franchisee-wrapper_table">
          <div className="onboarding-form_onboard_franchisee-wrapper_table-header">
            {selectedRowKeys.length} items selected
            <a className="btn-cancel" onClick={handleSelectCancel}>
              Cancel
            </a>
          </div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
        </div>
      </div>
      <Loading loading={loading} />
    </Form>
  );
}
