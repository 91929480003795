import React, { useState } from "react";
import { Tabs, Radio, Space, Button } from "antd";
import { useAppState } from "../../../../../context";
import {
  LiabilityForm,
  AutoForm,
  WorkersCompForm,
  UmbrellaForm,
  AdditionalInfoForm,
  CrimeForm,
  GarageForm,
  CyberForm,
  EpliForm,
  PolLiabForm,
  ProLiabForm,
  InlandMarineForm,
  ProInsForm,
} from "@components/insurance-requirements";

const { TabPane } = Tabs;

const TAB_KEY_LIABILITY = "LIABILITY";
const TAB_KEY_AUTO = "AUTO";
const TAB_KEY_WORKERS_COMP = "WORKERS_COMP";
const TAB_KEY_UMBRELLA = "UMBRELLA";
const TAB_KEY_ADDITIONAL_INFO = "ADDITIONAL_INFO";
const TAB_KEY_CRIME = "CRIME";
const TAB_KEY_GARAGE = "GARAGE";
const TAB_KEY_EPLI = "EPLI";
const TAB_KEY_CYBER = "CYBER";
const TAB_KEY_PROFESSIONAL = "PROFESSIONAL";
const TAB_KEY_POLLUTION = "POLLUTION";
const TAB_KEY_INLAND_MARINE = "INLAND_MARINE";
const TAB_KEY_PRO_INS = "PROPERTY_INSURANCE";

const tabList = [
  { title: "Liab", key: TAB_KEY_LIABILITY },
  { title: "Auto", key: TAB_KEY_AUTO },
  { title: "WC", key: TAB_KEY_WORKERS_COMP },
  { title: "Umb", key: TAB_KEY_UMBRELLA },
  { title: "Crime", key: TAB_KEY_CRIME },
  {
    title: "Garage",
    key: TAB_KEY_GARAGE,
  },
  {
    title: "EPLI",
    key: TAB_KEY_EPLI,
  },
  {
    title: "Cyber",
    key: TAB_KEY_CYBER,
  },
  {
    title: "Professional",
    key: TAB_KEY_PROFESSIONAL,
  },
  {
    title: "Pollution",
    key: TAB_KEY_POLLUTION,
  },
  {
    title: "Inland Marine",
    key: TAB_KEY_INLAND_MARINE,
  },
  {
    title: "Property Insurance",
    key: TAB_KEY_PRO_INS,
  },
  { title: "Cert", key: TAB_KEY_ADDITIONAL_INFO },
];

export default function InsuranceRequirementsForm(props) {
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const [activeTab, setActiveTab] = useState(TAB_KEY_LIABILITY);

  function handleActivate() {}

  function handleUpdateForm(updateFormVal) {
    setFranchisorAdmin({ ...franchisorAdmin, ...updateFormVal });
  }
  return (
    <div className="requirement-main-container">
      <div className="card-header">
        <Tabs
          type="card"
          tabPosition={props.source === "onboarding" ? "bottom" : "top"}
          activeKey={activeTab}
          onChange={(evt) => setActiveTab(evt)}
        >
          {tabList.map((tab) => (
            <TabPane tab={tab.title} key={tab.key} />
          ))}
        </Tabs>
      </div>
      {activeTab === TAB_KEY_LIABILITY && (
        <LiabilityForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_AUTO)}
        />
      )}
      {activeTab === TAB_KEY_AUTO && (
        <AutoForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_WORKERS_COMP)}
        />
      )}
      {activeTab === TAB_KEY_WORKERS_COMP && (
        <WorkersCompForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_UMBRELLA)}
        />
      )}
      {activeTab === TAB_KEY_UMBRELLA && (
        <UmbrellaForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_CRIME)}
        />
      )}
      {activeTab === TAB_KEY_CRIME && (
        <CrimeForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_GARAGE)}
        />
      )}
      {activeTab === TAB_KEY_GARAGE && (
        <GarageForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_EPLI)}
        />
      )}
      {activeTab === TAB_KEY_EPLI && (
        <EpliForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_CYBER)}
        />
      )}
      {activeTab === TAB_KEY_CYBER && (
        <CyberForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_PROFESSIONAL)}
        />
      )}
      {activeTab === TAB_KEY_PROFESSIONAL && (
        <ProLiabForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_POLLUTION)}
        />
      )}
      {activeTab === TAB_KEY_POLLUTION && (
        <PolLiabForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_INLAND_MARINE)}
        />
      )}
      {activeTab === TAB_KEY_INLAND_MARINE && (
        <InlandMarineForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_PRO_INS)}
        />
      )}
      {activeTab === TAB_KEY_PRO_INS && (
        <ProInsForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          onNext={() => setActiveTab(TAB_KEY_ADDITIONAL_INFO)}
        />
      )}

      {activeTab === TAB_KEY_ADDITIONAL_INFO && (
        <AdditionalInfoForm
          data={franchisorAdmin}
          onUpdate={handleUpdateForm}
          handleActivate={handleActivate}
          source={props.source}
        />
      )}
    </div>
  );
}
