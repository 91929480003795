import Card from "@components/card";
import React, { useEffect, useMemo, useState } from "react";
import { Select, Button, notification } from "antd";
import "./style.scss";
import { format } from "date-fns";
import FormProfile from "../../../admin-insurance-quote/components/form-profile";
import ReactJson from "react-json-view";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { FORM_EDIT, FORM_VIEW } from "@assets/const/ui";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import Loading from "@components/loading";
import ScreenMask from "@components/screen-mask";
import { BlockEntity } from "@components/underwriting";

const { Option } = Select;

export default function EntityBlock({ franchiseeData, setFranchiseeData }) {
  const [showDiff, setShowDiff] = useState(false);
  const [version, setVersion] = useState(0);
  const [formVal, setFormVal] = useState({});
  const [viewMode, setViewMode] = useState(FORM_VIEW);
  const [activeFormVal, setActiveFormVal] = useState({});
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();

  const logData = useMemo(() => {
    const logInsuranceData = franchiseeData?.logInsuranceData || [];
    return logInsuranceData.sort((itemA, itemB) =>
      new Date(itemA.updatedAt) > new Date(itemB.updatedAt) ? -1 : 1
    );
  }, [franchiseeData]);

  const versionList = useMemo(() => {
    const logInsuranceData = franchiseeData?.logInsuranceData || [];
    return logInsuranceData
      .sort((itemA, itemB) =>
        new Date(itemA.updatedAt) > new Date(itemB.updatedAt) ? -1 : 1
      )
      .map((item) => {
        return {
          updatedAt: format(new Date(item.updatedAt), "MM/dd/yyyy hh:mm a"),
          updatedBy: item.updatedBy
        };
      });
  }, [franchiseeData]);

  useEffect(() => {
    setFormVal(handleGetFormVal());
    setActiveFormVal(handleGetFormVal());
  }, [franchiseeData]);

  function handleSwitchVersion(versionIndex) {
    setVersion(versionIndex);
    setFormVal(logData[versionIndex].data);
  }

  function handleGetFormVal() {
    // if (franchiseeData?.isInsureDiff) {
    //   return {
    //     ...franchiseeData.insuranceData
    //   };
    // }
    // return {
    //   first_name: franchiseeData?.first_name || "",
    //   last_name: franchiseeData?.last_name,
    //   entity_name: franchiseeData?.entity_name,
    //   entity_type: franchiseeData?.entity_type ?? "CP",
    //   address: franchiseeData?.address,
    //   email: franchiseeData?.email,
    //   city: franchiseeData?.city,
    //   state: franchiseeData?.state,
    //   zip: franchiseeData?.zip,
    //   telephone: franchiseeData?.telephone,
    //   opening_date:
    //     franchiseeData?.opening_date || franchiseeData?.detail?.openingDate,
    //   tax_id:
    //     franchiseeData?.tax_id ||
    //     franchiseeData?.detail?.fimEntity?.fimTtTaxpayer,
    //   dba: franchiseeData?.dba,
    //   centerName: franchiseeData?.centerName,
    //   annualRevenue: franchiseeData?.annualRevenue
    // };
    return franchiseeData?.insuranceData || [];
  }

  async function handleSaveForm() {
    setLoading(true);
    try {
      const logInsuranceData = franchiseeData?.logInsuranceData || [];

      logInsuranceData.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: formVal
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          insuranceData: formVal,
          logInsuranceData
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result?.data?.entity);
      setViewMode(FORM_VIEW);
      notification.success({
        message: "Success",
        description: "Business Entity Information is successfully updated!",
        duration: 5
      });
    } catch (error) {
      notification.error({
        message: "Failed",
        description:
          "Saving Business Entity Information failed! Please contact admin directly.",
        duration: 5
      });
    }

    setLoading(false);
  }

  function renderVersion() {
    return (
      <div className="entity_block-action">
        {showDiff ? (
          <div
            className="entity_block-action-btn"
            onClick={() => setShowDiff(false)}
          >
            <EyeInvisibleOutlined />
          </div>
        ) : (
          <div
            className="entity_block-action-btn"
            onClick={() => setShowDiff(true)}
          >
            <EyeOutlined />
          </div>
        )}
        <Select
          defaultValue={"Please Select a Option"}
          style={{ width: 420 }}
          size="large"
          value={versionList?.length > 0 ? version : null}
          onChange={handleSwitchVersion}
        >
          {versionList.map((item, index) => (
            <Option value={index}>
              {`${item.updatedAt} - ${item.updatedBy}`}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  function renderEditBtn() {
    return (
      <div className="driver-title-extra">
        {viewMode === FORM_VIEW && (
          <Button
            type="default"
            size="large"
            icon={<EditOutlined />}
            onClick={() => setViewMode(FORM_EDIT)}
          >
            Edit
          </Button>
        )}
        {viewMode === FORM_EDIT && (
          <>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              size="large"
              onClick={handleSaveForm}
            >
              Save
            </Button>
            <Button
              type="default"
              size="large"
              icon={<CloseOutlined />}
              onClick={() => setViewMode(FORM_VIEW)}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <Card
      className="entity_block"
      title="Business Entity"
      extra={() => renderVersion()}
      titleExtra={() => renderEditBtn()}
    >
      <div className="entity_block-con">
        <BlockEntity data={formVal} onChange={setFormVal} />
        {viewMode === FORM_VIEW && <ScreenMask />}
      </div>
      {showDiff && (
        <div className="entity_block-diff">
          <div className="entity_block-diff-left">
            <label className="entity_block-diff-right-label">
              {version >= 0
                ? `${versionList[version].updatedAt} - ${versionList[version].updatedBy}`
                : "Current Version"}
            </label>

            <ReactJson
              src={formVal}
              theme="monokai"
              displayDataTypes={false}
              enableClipboard={false}
            />
          </div>
          <div className="entity_block-diff-right">
            <label className="entity_block-diff-right-label">
              Current Version
            </label>
            <ReactJson
              src={activeFormVal}
              theme="monokai"
              displayDataTypes={false}
              enableClipboard={false}
            />
          </div>
        </div>
      )}
      <Loading loading={loading} />
    </Card>
  );
}
