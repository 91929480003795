import React, { useMemo, useState } from "react";
import { Card, Loading } from "../../../../components";
import { Table, notification, Popconfirm } from "antd";
import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { get, post, postDoc, put } from "../../../../utils/axios";
import { DeleteOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  DOCUMENT_STATUS_ARCHIVED,
  DOCUMENT_STATUS_ACTIVE,
  DOCUMENT_STATUS_REJECT
} from "../../../../assets/const/status";
import IconDoc from "../../../../assets/images/icon-document.png";
import "./style.scss";
import { DOC_KEY_COI, DOC_KEY_COI_EOI, DOC_NAMING } from "@assets/const/ui";

export default function DocumentList(props) {
  const { franchiseeData, updateFranchiseeData } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  function renderStatus(status) {
    // eslint-disable-next-line default-case
    switch (status) {
      case DOCUMENT_STATUS_ACTIVE:
        return <div className="status-container success">{status}</div>;
      case DOCUMENT_STATUS_ARCHIVED:
        return <div className="status-container danger">{status}</div>;
      case DOCUMENT_STATUS_REJECT:
        return <div className="status-container danger">{status}</div>;
      default:
        return (
          <div className="status-container success">
            {DOCUMENT_STATUS_ACTIVE}
          </div>
        );
    }
  }

  async function handleDownload(evt, filePath, item) {
    evt.stopPropagation();
    evt.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const fileNameArr = filePath.split("/");
      const fileName = fileNameArr[fileNameArr.length - 1];
      const extensionArr = fileName.split(".");
      const extension = extensionArr[extensionArr.length - 1];
      postDoc(
        `download`,
        {
          fileName: fileName,
          envMode: process.env.REACT_APP_DOC_UPLOAD_MODE
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item.document}.${extension}`);
        document.body.appendChild(link);
        link.click();
      });
    } catch (err) {
      handleNotification(
        "Failed",
        "Document downloading is failed. Please contact admin directly."
      );
      console.log("error", err);
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  async function handleRemoveDoc(filePath) {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const documentList = [...(franchiseeData.fileUpload || [])];
      const docIndex = documentList.findIndex(
        (item) => item.filePath === filePath
      );
      const updatedItem = documentList[docIndex];
      updatedItem["status"] = DOCUMENT_STATUS_ARCHIVED;
      documentList.splice(docIndex, 1, updatedItem);
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          fileUpload: documentList
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      updateFranchiseeData(result.data.entity);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function handleRejectDoc(filePath) {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const documentList = [...(franchiseeData.fileUpload || [])];
      const docIndex = documentList.findIndex(
        (item) => item.filePath === filePath
      );
      const updatedItem = documentList[docIndex];
      updatedItem["status"] = DOCUMENT_STATUS_REJECT;
      documentList.splice(docIndex, 1, updatedItem);
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          fileUpload: documentList
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      updateFranchiseeData(result.data.entity);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const columnsDocument = [
    {
      title: "Document Name",
      dataIndex: "document",
      key: "document"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus
    },
    {
      title: "File",
      dataIndex: "filePath",
      key: "filePath",
      render: (filePath, item) => {
        return (
          <a
            className="file-item-container"
            href={filePath}
            onClick={(evt) => handleDownload(evt, filePath, item)}
          >
            <img src={IconDoc} />
          </a>
        );
      }
    },
    {
      title: "Document Type",
      dataIndex: "docType",
      key: "docType"
    },
    {
      title: "Uploaded",
      dataIndex: "uploaded",
      key: "uploaded"
    },
    {
      title: "Archived",
      dataIndex: "archived",
      key: "archived"
    },
    {
      title: "Action",
      dataIndex: "deleteKey",
      key: "deleteKey",
      render: (data, record, index) => {
        return (
          <>
            <Popconfirm
              title="Are you sure to delete this document?"
              onConfirm={() => handleRemoveDoc(record.filePath)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined className="icon-remove" />
            </Popconfirm>
            <Popconfirm
              title="Are you sure to reject this document?"
              onConfirm={() => handleRejectDoc(record.filePath)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <CloseCircleOutlined className="icon-reject" />
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const docSource = useMemo(() => {
    const documentList = franchiseeData.fileUpload || [];

    const coiData = documentList
      .filter((item) => !item.docType || item.docType === DOC_KEY_COI)
      .sort((documentA, documentB) => {
        if (documentA.upload_at < documentB.upload_at) return 1;
        else return -1;
      })
      .map((item) => ({
        document: `${franchiseeData.last_name}-${
          franchiseeData.first_name
        }-COI-${moment(item.upload_at).format("DD-MM-YYYY")}`,
        filePath: item.filePath,
        status: item.status,
        uploaded: moment(item.upload_at).format("L LT"),
        archived: item.archived_at
          ? moment(item.archived_at).format("LLLL")
          : "",
        deleteKey: item._id,
        docType: DOC_NAMING[item.docType]
      }));

    const copiData = documentList
      .filter((item) => item.docType === DOC_KEY_COI_EOI)
      .sort((documentA, documentB) => {
        if (documentA.upload_at < documentB.upload_at) return 1;
        else return -1;
      })
      .map((item) => ({
        document: `${franchiseeData.last_name}-${
          franchiseeData.first_name
        }-COI-${moment(item.upload_at).format("DD-MM-YYYY")}`,
        filePath: item.filePath,
        status: item.status,
        uploaded: moment(item.upload_at).format("L LT"),
        archived: item.archived_at
          ? moment(item.archived_at).format("LLLL")
          : "",
        deleteKey: item._id,
        docType: DOC_NAMING[item.docType]
      }));

    const otherData = documentList
      .filter(
        (item) =>
          item.docType !== DOC_KEY_COI && item.docType !== DOC_KEY_COI_EOI
      )
      .sort((documentA, documentB) => {
        if (documentA.upload_at < documentB.upload_at) return 1;
        else return -1;
      })
      .map((item) => ({
        document: `${franchiseeData.last_name}-${
          franchiseeData.first_name
        }-COI-${moment(item.upload_at).format("DD-MM-YYYY")}`,
        filePath: item.filePath,
        status: item.status,
        uploaded: moment(item.upload_at).format("L LT"),
        archived: item.archived_at
          ? moment(item.archived_at).format("LLLL")
          : "",
        deleteKey: item._id,
        docType: DOC_NAMING[item.docType]
      }));

    return {
      coiData,
      copiData,
      otherData
    };
  }, [franchiseeData?.fileUpload]);
  return (
    <Card title="Documents" className="doc_con">
      <h1 className="doc_con-title">Certificate of Insurance(COI)</h1>
      <Table dataSource={docSource?.coiData} columns={columnsDocument} />
      <h1 className="doc_con-title">Certificate of Property Insurance(COI)</h1>
      <Table dataSource={docSource?.copiData} columns={columnsDocument} />
      <h1 className="doc_con-title">Other Document</h1>
      <Table dataSource={docSource?.otherData} columns={columnsDocument} />
      <Loading loading={loading} />
    </Card>
  );
}
