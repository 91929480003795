import React from 'react';
import './style.scss';
import { CheckOutlined } from '@ant-design/icons';

export default function Stepper(props) {
    const config = {
        options: [
            {
                key: 'sync_franchisee',
                value: 'Sync Franchisees',
                options: [
                    {
                        key: 'select_platform',
                        value: 'Select Platform'
                    },
                    {
                        key: 'log_in',
                        value: 'Log in'
                    },
                    {
                        key: 'select_franchisees',
                        value: 'Select Franchisees'
                    },
                    {
                        key: 'preview_import',
                        value: 'Preview & Import'
                    }
                ]
            },
            {
                key: 'establish_insurance_requirement',
                value: 'Establish Insurance Requirement'
            },
            {
                key: 'request_activation',
                value: 'Request Activation'
            }
        ],
        mainOptionIndex: 2,
        subOptionIndex: 4
    }

    const { options } = config;
    const { mainOptionIndex, subOptionIndex } = props;

    function renderTap(index) {
        return <div className={index <= mainOptionIndex? "tap-container select": "tap-container"}>{index < mainOptionIndex? <CheckOutlined />: index + 1}</div>
    }

    function renderSubTap(index) {
        return <div className={index <= subOptionIndex? "sub-tap-container select": "sub-tap-container"}><CheckOutlined /></div>
    }

    function renderSubOptionItem(option, index) {
        return <div className="option-item-container">
            {renderSubTap(index)}
            <h3>{option.value}</h3>
        </div>
    }

    function renderStepItem(option, index) {
        return <div className="stepper-item-container">
            <div className="step-item-container">
                {renderTap(index)}
                <h3>{option.value}</h3>
            </div>
            {option.options && index === mainOptionIndex && <div className="sub-options-container">
                {option.options.map((subOption, subIndex) => renderSubOptionItem(subOption, subIndex))}
            </div>}
            <div className={index < mainOptionIndex? "action-bar select": "action-bar"}></div>
        </div>
    }

    return (
        <div className="stepper-container">
            {options.map((option, index) => renderStepItem(option, index))}
        </div>
    )
}