import Layout from "./layout";
import { AppStateProvider } from "./context";
import "./App.less";
import { useAuth0 } from "@auth0/auth0-react";
import { Splash } from "./components";

import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";

// dayjs is used by antd DatePicker component. And it's required that we manually install
// these plugins at application start.
dayjs.extend(weekday);
dayjs.extend(localeData);

function App() {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Splash />;
  }

  return (
    <AppStateProvider>
      <Layout />
    </AppStateProvider>
  );
}

export default App;
