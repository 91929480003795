const APP_VERSION = "3.5.0";

const RELEASE_NOTES = {
  "1.0.0": {
    date: "2022-03-24",
    content: [
      "Activation Workflow Updated",
      "Update Franconnect Form - Expanded Franconnect Form",
      "Status is Verified",
      "Updated Zoho Integration API",
      "Franconnect Sync Module is Updated",
      "Super Admin Dashboard is Created",
      "Auto Logic is Updated",
      "Umbrella Logic is Updated",
      "Workers Compensation Logic is Updated",
      "Re-Approve Functionality is Added",
      "Admin-In Progress Screen is Updated",
      "Status 'None' is Updated with 'Document Missing'",
      "Franchisor List Page is Updated",
      "Franchisee Uploading is Updated",
      "Data Validation Loading Time is Improved",
      "Cypress is Configured for E2E Testing",
    ],
  },
  "1.1.0": {
    date: "2022-04-29",
    content: [
      "Active Campaign Integrated",
      "Admin Dashboard Updated",
      "Account Page Updated",
      "Franchisor Dashboard Updated",
      "Franchisor Account Infor Page Updated",
      "Upload URL Section Added",
      "Header Updated",
      "UnderWriting Added",
      "Document Delete Enabled",
      "Multiple Document Upload Enabled",
      "Document Approve Updated",
    ],
  },
  "1.1.1": {
    date: "2022-05-04",
    content: [
      "Franchisee Upload Page Updated",
      "Franchisee Upload COI Page Mobile Responsive Fixed",
      "All UI Elements Updated",
    ],
  },
  "1.2.0": {
    date: "2022-05-21",
    content: [
      "API Performance Updated",
      "Acive Campaign Sync Service Deployed",
      "Requirement Update Service Deployed",
      "Umbrella Logic Updated",
      "API Documentation Updated",
      "Franchisor Onboarding Issue Fixed",
      "Onboarding Account Information Form Updated",
      "Admin & Franchisor Dashboard Updated",
      "FIM Market Place Integrated",
      "Documentation Report Fixed",
      "Franchisee Detail Updated",
      "UI Issues Resolved",
    ],
  },
  "1.2.1": {
    date: "2022-06-08",
    content: [
      "Reports Type Issue Fixed",
      "UnderWriting is Updated",
      "Zee Basis Question Added",
    ],
  },
  "1.3.0": {
    date: "2022-07-01",
    content: [
      "FIM Great American Integration",
      "FIM Hartford Integration",
      "Franchisee Approve Workflow Updated",
      "Auto Liability Logic Updated",
      "Admin Risk Profile Updated",
      "Underwriting Questions Added",
      "Zee Rating Basis Added",
      "Insurance Requirements Updated",
      "Document Reject Functionality",
      "Notification Page Updated",
      "Naming Documentation",
      "Ordering Franchisee Report",
      "Admin Dashboard Layout Updated",
      "Approve Workflow Updated",
      "Contact ASAP Added",
    ],
  },
  "1.3.1": {
    date: "2022-07-10",
    content: [
      "FIM Application Location Saving",
      "Admin Expiration Data Missing Fixed",
      "Expired Franchisee Coverage Status Updated",
      "Streamlined Quote Status Updated",
      "Removed Blank Data in FIM Hartford Request",
    ],
  },
  "1.3.2": {
    date: "2022-07-14",
    content: [
      "NASIC Code Auto-Population Fix",
      "Prevent Invalid Date Format Saving",
      "Franchisor Dashboard Issue Fixed",
    ],
  },
  "1.4.0": {
    date: "2022-07-27",
    content: [
      "Hartford Form Saving",
      "Hartford Test Case Done",
      "Effective Date Added",
      "Service Minder Integration Done",
    ],
  },
  "1.4.1": {
    date: "2022-08-03",
    content: [
      "Hartford Required Form Fields",
      "Clean Hartford Test Data",
      "Add Entity Name to Franchisee Schema & Clean Codebase",
      "Data Migration Script Added",
      "Fraconnect Data Integration Validation Added",
      "Dev Server AC Integration Disabled",
    ],
  },
  "1.4.2": {
    date: "2022-08-23",
    content: [
      "API System Refactored",
      "POC Contact is added to Franchisor",
      "POC Contact is added to Franchisee",
      "Rikor Customer Flag is added",
      "Inactive Zee Flag is added",
      "Franchisee Profile Layout is updated",
      "Risk Profile Delete is added",
      "Underwriting Delete is added",
      "Log System is updated",
      "Franconnect Log System is added",
      "Great American UI is updated",
    ],
  },
  "1.4.3": {
    date: "2022-09-12",
    content: [
      "Hartford Validations are added",
      "PDF Report Updated with correct colors",
      "Admin Expired Page updated",
      "Hartford Form Codes Selection",
      "Backend Codebase are cleaned",
      "Service Minder Integration Updated",
    ],
  },
  "1.4.4": {
    date: "2022-09-13",
    content: [
      "Hartford Field Type Validation Added",
      "Office & Location Validation Added",
    ],
  },
  "1.4.5": {
    date: "2022-09-16",
    content: [
      "Hartford Data Type Validation Updated",
      "Spectrum Code Required",
      "Document Name Updated in Franchisee Summary",
      "Related Franchisee Headers Updated",
    ],
  },
  "1.5.0": {
    date: "2022-11-16",
    content: [
      "Added DBA To Franchisor Account Detail",
      "Rikor Customer Moved To Top",
      "HomeFrontBrands Domain Setup",
      "Added 'Entity Name' To Franchisee",
      "VeteranServiceBrands Domain Setup",
      "Searching Functionality Updated",
      "Updated Logos For Brands",
      "AuthorityBrands Domain Setup",
      "HorsePowerBrands Domain Setup",
      "Filtering Data To Multiple Brands",
      "Service Minder Added To Integration Page",
      "Franchisee POC Updated",
      "Data Struction Refactored",
      "POC Contact Form Contact Form Updated",
    ],
  },
  "1.6.0": {
    date: "2022-12-12",
    content: [
      "Umbrella Logic Updated",
      "Validation Insurance Missing Fixed",
      "Logging System Added",
      "Is Different Section Enabled",
      "Hartford BOP Quote API Updated",
      "Franchisor ZEE Updated",
      "Assigning Franchisor To Domain Added",
      "Assigning Franchisee To Domain Added",
    ],
  },
  "1.6.1": {
    date: "2022-12-13",
    content: ["BOP Form Added To Hartford Appetite"],
  },
  "1.6.2": {
    date: "2022-12-22",
    content: [
      "Umbrella Requirement Updated",
      "Franchisee Layout Updates",
      "Multiple POC Contacts",
    ],
  },
  "1.6.3": {
    date: "2023-01-02",
    content: [
      "Thrivemorebrands Domain Created",
      "Corporate Phone Number Removed",
      "Franchisee POC Contact Form Updated",
      "Franchisor Real-Time Notification POC Contact Form Updated",
      "Franchisor View Franchisee Manual Add Form Updated",
      "Admin View Franchisee Manual Add Form Updated",
      "Added Logo URL to Franchisor Account",
      "Additional Infor Updated",
      "Settings User Add/Update Form Updated",
    ],
  },
  "1.6.4": {
    date: "2023-01-11",
    content: [
      "Hartford BOP Functionality Updated",
      "Franchisee Layout Updated",
      "Franchisor Requirements Updated",
      "Admin User Form Updated",
      "Admin Setting Assignments Created",
    ],
  },
  "1.7.0": {
    date: "2023-01-20",
    content: [
      "Assignment Update",
      "New Lead added to Franchisee",
      "Franchisee Detail Layout Updated",
      "Hartford Construction Type Code Updated",
      "Franchisor Logo Added to Franchisee Detail",
      "Consultant Section Added",
    ],
  },
  "1.8.0": {
    date: "2023-02-14",
    content: [
      "Franchisee Upload Auto Saving",
      "Rikor Customer Number Added",
      "Franchisee UnderWriting Updated",
      "Certificate Holder Information Verification",
      "Active Campaign Sync Updated",
    ],
  },
  "1.8.1": {
    date: "2023-02-21",
    content: [
      "Active Campaign Sync Module Updated",
      "Recaptcha Added to Franchisee Upload COI Screen",
      "Login Form Updated",
    ],
  },
  "1.9.0": {
    date: "2023-03-30",
    content: [
      "Employer Count Issue Resolved",
      "FIM Updated",
      "New Document Server Setup",
      "Documents Upload/Download Module Updated",
      "Fixed Franchisor Onboarding Issue",
      "Fixed Dashboard Breaking",
      "Disabled AC Sync on other domains",
      "Fixed App Build Issue upon React-DND-TreeView Package Updated",
      "Active Campaign Sync Module Updated",
      "Added ability for disabling AC Sync to franchisor",
    ],
  },
  "1.9.1": {
    date: "2023-04-07",
    content: [
      "Admin Franchisor Onboarding Form Updated",
      "Franchisor View Adding User Form Updated",
      "Franchisor Setting Page Refresh Issue Fixed",
      "Document Uploading Issue Resolved",
      "DonutNV Franchisee Status Issue Fixed",
      "Insured Information Date Picker Issue Fixed",
      "Opening Date Sync To Active Campaign",
    ],
  },
  "1.9.1": {
    date: "2023-04-07",
    content: [
      "Admin Franchisor Onboarding Form Updated",
      "Franchisor View Adding User Form Updated",
      "Franchisor Setting Page Refresh Issue Fixed",
      "Document Uploading Issue Resolved",
      "DonutNV Franchisee Status Issue Fixed",
      "Insured Information Date Picker Issue Fixed",
      "Opening Date Sync To Active Campaign",
    ],
  },
  "1.9.2": {
    date: "2023-04-07",
    content: [
      "Auth0 Logo Updated",
      "Access Permission Stuff Added",
      "New Domain Homerunfranchises Added",
    ],
  },
  "1.9.3": {
    date: "2023-04-27",
    content: [
      "Franchisee Assign Host Updated",
      "Auto Verification Logic Updated",
      "Admin Checklist Added",
      "Production Report Fixed",
      "Requirement Section Disable Logic Updated",
      "Certificated Holder Information Verification Updated",
      "Certificate of Insurance Verification Updated",
      "Requirements Information Table Updated",
    ],
  },
  "2.0.0": {
    date: "2023-05-05",
    content: [
      "Insurance Requirement Table Created",
      "Admin Franchisor Insurance Requirement Updated",
      "Franchisor Insurance Requirement Updated",
      "Onboarding Form Updated",
      "Admin Zor Check List Created",
      "Franchisor Zor Check List Updated",
      "Franconnect Sync Module Updated",
      "Active Campaign Updated",
    ],
  },
  "2.1.0": {
    date: "2023-05-09",
    content: ["Insurance Requirement Table Updated"],
  },
  "2.1.1": {
    date: "2023-05-11",
    content: [
      "Requirement Table Create/Update/Delete Updated",
      "Expiration Report Filter Updated",
      "Underwriting Navigation Fixed",
      "Select Requirement Template",
      "Save As Requirement Template",
    ],
  },
  "2.2.0": {
    date: "2023-05-23",
    content: [
      "Cron Job For Re-verify",
      "Log System Updated",
      "Date Picker Invalid",
      "Franchisor Logo Issue",
      "Unselect Franchisor Requirements",
    ],
  },
  "2.3.0": {
    date: "2023-05-31",
    content: [
      "Active Campaign Account Sync Updated",
      "Franchisor Insurance Requirement Table Updated",
      "Franchisor/Franchisee Table Updated",
      "Quote Added",
    ],
  },
  "2.3.1": {
    date: "2023-06-05",
    content: [
      "Re-Verify Cron Job Updated",
      "Duplicated Franchisees Section Updated",
      "Account Section Updated",
    ],
  },
  "2.5.0": {
    date: "2023-06-29",
    content: [
      "Creating Franchisee Active Campaign Sync",
      "AC Sync Immediately Done Upon A Franchisee Updated",
      "Re-Approve Cron Job Updated",
      "Brand Access Issue Resolved",
      "Manual Approve & Cron Job Build Logic Matched",
      "Franchisor Report Updated",
    ],
  },
  "2.5.1": {
    date: "2023-07-17",
    content: [
      "Active Campaign Data Sync Updated",
      "Brand Logo Added to AC",
      "Campaign Mode Added to AC",
      "Insurance Verification Added to AC",
      "Do Not Contact Added",
      "Franchisor Report Updated",
      "New Franchisee Creation Form Updated",
      "Quote Page Updated",
    ],
  },
  "2.6.0": {
    date: "2023-08-02",
    content: [
      "Active Campaign Data Sync Updated",
      "Do Not Contact Updated",
      "Franchisee Form Updated",
      "Franchisor List Updated",
      "Data Migration Script Done",
      "Is Lead Updated",
      "Inactive Zee Updated",
    ],
  },
  "2.6.1": {
    date: "2023-08-10",
    content: [
      "Score Calculation Logic",
      "Unselection for Do Not Contact",
      "Franconnect Sync Module",
      "AC Interation Page Added",
      "Quote Page Updated",
    ],
  },
  "3.0.0": {
    date: "2023-11-03",
    content: [
      "New Coverages Added",
      "Franconnect Updated",
      "AC Sync Module Updated",
      "Multi-Unit Enabled",
      "Locations Updated",
    ],
  },
  "3.3.0": {
    date: "2024-04-20",
    content: [
      "New Coverages Added",
      "AC Sync Updated",
      "Upload Contact Updated",
      "Score Calculation Updated",
      "Zee View Created",
      "Zor Overview Updated",
      "New Document Type Added",
    ],
  },
  "3.4.0": {
    date: "2024-11-01",
    link: "https://rikor.atlassian.net/jira/software/c/projects/RA/issues/RA-1113?filter=doneissues&jql=project%20%3D%20%22RA%22%20AND%20statusCategory%20%3D%20%22Done%22%20AND%20sprint%20%3D%2043%20ORDER%20BY%20created%20DESC",
    content: [
      "🎉 New Features",

      "🐛 Bug Fixes",
      "Fixed issue allowing users to submit through ULP without entity name, first name, and last name",
      "Fixed issue in Active Campaign where Address, City, State, and Zip code were missing from user information",
      "COI page now autofills EPLI, Cyber, Pollution Liability, and Professional Liability when document is selected",
      "Fixed issue where the discovery call link was missing in the ULP",
      "Fixed issue where contact card information was not updating in 'Insured Information' when adjusted in the ULP",

      "🏎️ Improvements",
      "Updated language during sign-in process",
      "Updated the “Wizard” to open forms on every panel, display “Save” and “Everything Looks Good, Next” buttons, rename the “Unique” tab to “Questions,” and allow updates in the “Business Entity” and “Questions” panels",
      "Adjusted COI dropdown in COI Summary Page to show most recent document uploaded first",

      "🛑 Known Issues",
      "Sign-In flow language is handled by one template from Auth0",
      "Users still marked as 'New Lead' despite changes in customer status",
    ],
  },
  "3.5.0": {
    date: "2024-12-02",
    link: "https://rikor.atlassian.net/jira/software/c/projects/RA/issues/RA-1144?filter=doneissues&jql=project%20%3D%20%22RA%22%20AND%20statusCategory%20%3D%20%22Done%22%20AND%20sprint%20%3D%2046%20ORDER%20BY%20created%20DESC",
    content: [
      "🎉 New Features",
      "Serverless Task Execution: Moved cron jobs from Node Scheduler to AWS Lambda, enabling efficient and reliable scheduling for serverless task execution",

      "🐛 Bug Fixes",
      "Fixed an issue in email templates where all coverages were displayed instead of only the unverified ones",
      "Corrected missing original record fields in the 'Insured Information' section. This section now displays all relevant data accurately",
      "Updated franchisor view to include new insurance verification activity and display historical data in line graphs",
      "Resolved a problem with document date display where incorrect, outdated dates were shown instead of the associated, accurate dates",
      "Addressed an issue where the 'Insured Information' section in the portal page was not displaying data properly",
      "Fixed a bug that prevented COIs from downloading correctly for specific franchisees",

      "🏎️ Improvements",
      "Redesigned the portal for better clarity and ease of use, providing users with clear instructions on insurance requirements and simplifying the upload process",
      "Implemented an automated script to check the Mandrill API, ensuring emails are sent successfully. Logs this verification in the RMS under 'Logs'",

      "🛑 Known Issues",
      "The feature applying a date stamp to new status changes works for a limited number of accounts but times out when processing hundreds",
      "When setting the policy status verification in the master trigger, the default value is not updating for all franchisees as expected",
    ],
  },
};

export { APP_VERSION, RELEASE_NOTES };
