import { Input, Radio, Select } from "antd";
import _ from "lodash";
import "./style.scss";

export default function AmTrustQuoteQuestions({ quoteQuestions, onChange }) {
  function handleAnswerQuestion(index, answer) {
    const newQuoteQuestions = _.cloneDeep(quoteQuestions);
    newQuoteQuestions[index]["Answer"] = answer;
    onChange(newQuoteQuestions);
  }

  function renderBoolAnswerBox(index) {
    return (
      <Radio.Group
        onChange={(evt) => handleAnswerQuestion(index, evt.target.value)}
        value={quoteQuestions[index]["Answer"]}
      >
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    );
  }

  function renderTextAnswerBox(index) {
    return (
      <Input
        value={quoteQuestions[index]["Answer"]}
        type={"text"}
        onChange={(evt) => handleAnswerQuestion(index, evt.target.value)}
      />
    );
  }

  function renderDropdownAnswerBox(question, index) {
    return (
      <Select
        value={quoteQuestions[index]["Answer"]}
        size="large"
        style={{ width: "100%" }}
        onChange={(value) => handleAnswerQuestion(index, value)}
      >
        {question["DropdownOptions"] &&
          question["DropdownOptions"].map((option) => (
            <Select.Option value={option["Key"]}>
              {option["Text"]}
            </Select.Option>
          ))}
      </Select>
    );
  }

  function renderQuestion(question, index) {
    let answerBox;
    switch (question["QuestionType"]) {
      case "bool":
        answerBox = renderBoolAnswerBox(index);
        break;
      case "text":
        answerBox = renderTextAnswerBox(index);
        break;
      case "dropdown":
        answerBox = renderDropdownAnswerBox(question, index);
        break;
    }

    return (
      (
        <div className="amtrust-question">
          <h3>{question["Question"]}</h3>
          <div>{answerBox}</div>
        </div>
      ) || <></>
    );
  }

  return (
    <div>
      {quoteQuestions.map((question, index) => renderQuestion(question, index))}
    </div>
  );
}
