const onboardingWorkflow = {
  ONBOARDING_START: "ONBOARDING_START",
  SYNC_FRANCHISEES: "SYNC_FRANCHISEES",
  BOARD_PLATFORMS: "BOARD_PLATFORMS",
  SIGN_IN_FRANCHISEE: "SIGN_IN_FRANCHISEE",
  BOARD_UPLOAD: "BOARD_UPLOAD",
  LIST_FRANCHISEE: "LIST_FRANCHISEE",
  PREVIEW_IMPORT_CONTACTS: "PREVIEW_IMPORT_CONTACTS",
  INSURANCE_REQUIREMENT: "INSURANCE_REQUIREMENT",
  REQUEST_ACTIVIATION: "REQUEST_ACTIVIATION",
  ACCOUNT_INFORMATION: "ACCOUNT_INFORMATION",
};

export { onboardingWorkflow };
