import React, { useMemo } from "react";
import { Card } from "@components";
import { Table } from "antd";
import { format } from "date-fns";
import "./style.scss";

export default function ComplianceSection({ franchiseeData }) {
  const emailReportData = useMemo(() => {
    const emailLogs = franchiseeData?.emailLogs || [];
    return emailLogs
      .map((item) => ({
        sentAt: new Date(item.sentAt),
        to: item.to,
        coverageList: item.coverageList.join(", "),
        emailType: item.emailType
      }))
      .sort((a, b) => (a.sentAt > b.sentAt ? -1 : 1));
  }, [franchiseeData]);

  const emailReportColumn = [
    {
      title: "Date Sent",
      dataIndex: "sentAt",
      key: "sentAt",
      render: (field, record) => <div>{format(field, "PPP, HH:mm")}</div>
    },
    {
      title: "Recipient",
      dataIndex: "to",
      key: "to"
    },
    {
      title: "Email Type",
      dataIndex: "emailType",
      key: "emailType"
    },
    {
      title: "Coverage List",
      dataIndex: "coverageList",
      key: "coverageList"
    }
  ];

  return (
    <div className="com_sec">
      <Card className="se_re-con" title="Email Send Reciepts">
        <Table
          columns={emailReportColumn}
          dataSource={emailReportData}
          pagination={{ pageSize: 5 }}
        ></Table>
      </Card>
    </div>
  );
}
