import { BlockEntity, BlockLocation } from "@components/underwriting";
import { Loading } from "@components";
import React, { useEffect, useMemo, useState } from "react";
import { Button, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import "./style.scss";

export default function PanelEntityInfo({
  franchiseeData,
  setFranchiseeData,
  onNext
}) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [entityFormVal, setEntityFormVal] = useState({});
  const [locFormVal, setLocFormVal] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEntityFormVal(handleGetEntityFormVal());
    setLocFormVal(handleGetLocFormVal());
  }, [franchiseeData]);

  function handleGetEntityFormVal() {
    if (franchiseeData?.isInsureDiff) {
      return {
        ...franchiseeData.insuranceData
      };
    }

    return {
      first_name: franchiseeData?.first_name || "",
      last_name: franchiseeData?.last_name,
      entity_name: franchiseeData?.entity_name,
      entity_type: franchiseeData?.entity_type ?? "CP",
      address: franchiseeData?.address,
      email: franchiseeData?.email,
      city: franchiseeData?.city,
      state: franchiseeData?.state,
      zip: franchiseeData?.zip,
      telephone: franchiseeData?.telephone,
      opening_date:
        franchiseeData?.opening_date || franchiseeData?.detail?.openingDate,
      tax_id:
        franchiseeData?.tax_id ||
        franchiseeData?.detail?.fimEntity?.fimTtTaxpayer,
      dba: franchiseeData?.dba,
      centerName: franchiseeData?.centerName,
      annualRevenue: franchiseeData?.annualRevenue
    };
  }

  function handleGetLocFormVal() {
    return franchiseeData?.commonLocList || [];
  }

  async function handleSave() {
    setLoading(true);
    try {
      const logInsuranceData = franchiseeData?.logInsuranceData || [];
      const logCommonLocList = franchiseeData?.logCommonLocList || [];

      logInsuranceData.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: entityFormVal
      });
      logCommonLocList.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: locFormVal
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          isInsureDiff: true,
          insuranceData: entityFormVal,
          logInsuranceData,
          commonLocList: locFormVal,
          logCommonLocList
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setFranchiseeData(result?.data?.entity);
      notification.success({
        message: "Success",
        description: "Business Information is successfully updated!",
        duration: 5
      });
    } catch (error) {
      notification.error({
        message: "Failed",
        description:
          "Saving Business Information failed! Please contact admin directly.",
        duration: 5
      });
    }
    setLoading(false);
  }

  function handleNext() {
    onNext();
  }

  const isEntityFormValDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetEntityFormVal());
    const changedStr = JSON.stringify(entityFormVal);

    return originalStr != changedStr;
  }, [franchiseeData, entityFormVal]);

  const isLocFormValDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetLocFormVal());
    const changedStr = JSON.stringify(locFormVal);

    return originalStr != changedStr;
  }, [franchiseeData, locFormVal]);

  return (
    <div className="pan_entity">
      <div className="pan_entity-info">
        <h5 className="pan_entity-info-title">Business Entity</h5>
        <BlockEntity data={entityFormVal} onChange={setEntityFormVal} />
        <div className="pan_entity-info-footer"></div>
      </div>

      <div className="pan_entity-info">
        <h5 className="pan_entity-info-title">Location Information</h5>
        <BlockLocation
          data={locFormVal}
          onChange={setLocFormVal}
          zeeData={franchiseeData}
        />
        <div className="pan_driver-info-footer">
          <Button
            onClick={() => {
              handleSave();
            }}
            size="large"
            type="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              onNext();
            }}
            size="large"
            type="primary"
          >
            Everything Looks Good, Next
          </Button>
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}
