import { post } from "@utils/axios";
import { notify } from "@utils/common";
import { Button, Spin } from "antd";
import { useState } from "react";
import _ from "lodash";
import Alert from "@components/alert";
import InputText from "@components/input/text";
import Input from "@components/input";
export default function EmailVerification({
  emailVerified,
  companyEmail,
  onChange,
  onVerify,
  onReset
}) {
  const [verificationCode, setVerificationCode] = useState();
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);

  function handleResetVerification() {
    setVerificationCode(undefined);
    setVerificationCodeSent(false);
    onReset();
  }

  async function handleSendCode() {
    try {
      await post("/auth0/send-email-verification-code", {
        email: companyEmail
      });
      setVerificationCodeSent(true);
    } catch (err) {
      notify(
        "Error",
        `Failed to send email verification code to email ${companyEmail}.`
      );
    }
  }
  return (
    <>
      <div className="input-btn-line">
        <div className="email-input">
          <Input
            size="large"
            disabled={verificationCodeSent}
            title="Company Email"
            value={companyEmail}
            onChange={(evt) => onChange(evt.target.value)}
            placeholder="Verify your email and see if you are already in our system."
          />
        </div>
        {!emailVerified && !verificationCodeSent && (
          <Button
            className="email-action-btn"
            type="primary"
            onClick={handleSendCode}
          >
            Send Verification Code
          </Button>
        )}
        {(verificationCodeSent || emailVerified) && (
          <Button
            type="primary"
            danger
            onClick={handleResetVerification}
            className="email-action-btn"
          >
            Use a different email
          </Button>
        )}
      </div>
      {verificationCodeSent && !emailVerified && (
        <div className="alert-confirmation-line">
          <div className="alert-confirmation-line-wrapper">
            <Alert
              status="success"
              message={`Verification code sent to "${companyEmail}". Didn't receive our verification code email? Please check your server filters or spam/junk folder.`}
            />
          </div>
          <div className="input-btn-line">
            <div className="verification-code-input">
              <InputText
                value={verificationCode}
                placeholder="Enter the verification code."
                onChange={(evt) => {
                  setVerificationCode(evt.target.value);
                }}
              />
            </div>
            <Button
              type="primary"
              onClick={() => {
                onVerify(verificationCode);
              }}
              className="verify-code-btn"
            >
              Verify Code
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
