import React, { useState } from "react";
import { Form, Alert, Radio, Input as AntInput } from "antd";
import { Input, Loading } from "../../../../../components";
import { useAuth0 } from "@auth0/auth0-react";
import { post } from "../../../../../utils/axios";
import {
  REQUIREMENT_STATUS_ONBOARD,
  USER_TYPE_FRANCHISOR
} from "../../../../../assets/const/status";
import "./style.scss";
import { getHostTag } from "../../../../../utils/host";
import InputLabel from "../../../../../components/input-label";

const METHOD_CREATE = "METHOD_CREATE";
const METHOD_USE = "METHOD_USE";

export default function CreateUserForm({ form, onNext, onSetUser }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [method, setMethod] = useState(METHOD_CREATE);

  async function handleSubmit(values) {
    if (method === METHOD_CREATE) {
      if (values["password"] !== values["password-repeat"]) {
        setError("Password is mismatched!");
        return;
      }

      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const resultRequirement = await post(
          "requirement",
          {
            status: REQUIREMENT_STATUS_ONBOARD,
            host: getHostTag(),
            poc_contacts: [
              {
                firstName: values["firstName"],
                lastName: values["lastName"],
                email: values["email"],
                telephone: values["telephone"],
                isPrimary: true,
                isActive: true
              }
            ]
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const result = await post(
          `admin/user/create`,
          {
            email: values["email"],
            password: values["password"],
            first_name: values["firstName"],
            last_name: values["lastName"],
            type: USER_TYPE_FRANCHISOR,
            permission: "",
            host: getHostTag(),
            requirementId: resultRequirement?.data?.data?._id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setLoading(false);
        onNext(resultRequirement?.data?.data);
        onSetUser({
          email: values["email"]
        });
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
        }
      }
    } else {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const result = await post(
          `admin/user/find`,
          {
            email: values["email"],
            name: values["name"]
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setLoading(false);
        onSetUser({
          email: values["email"],
          name: values["name"]
        });
        onNext(result?.data?.data);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
        }
      }
    }
  }

  function handleChange() {
    setError(null);
  }

  function handleChangeMethod(evt) {
    setMethod(evt.target.value);
  }

  function validatePassword(rule, value, callback) {
    if (!value.match(`(?=.*[a-z])`)) {
      return callback("Need to add lower case");
    }
    if (!value.match(`(?=.*[A-Z])`)) {
      return callback("Need to add upper case");
    }
    if (!value.match(`(?=.*[0-9])`)) {
      return callback("Need to add number");
    }

    if (!value.match(`(?=.*[!@#$&*])`)) {
      return callback("Need to add Special characters");
    }

    if (value.length < 8) {
      return callback("Need to at least 8 letters");
    }

    callback();
  }

  return (
    <div className="onboarding-form_create_user">
      {error && <Alert message={error} className="form-error" type="error" />}
      <Radio.Group
        className="onboarding-form_create_user-select"
        value={method}
        onChange={handleChangeMethod}
      >
        <Radio value={METHOD_CREATE}>Create Franchisor</Radio>
        <Radio value={METHOD_USE}>Find Existing Franchisor</Radio>
      </Radio.Group>
      <Form form={form} onFinish={handleSubmit}>
        {method === METHOD_CREATE && (
          <>
            <div className="form-group-two-container">
              <div>
                <InputLabel label="First Name" />
                <Form.Item
                  name="firstName"
                  rules={[
                    { required: true, message: "First Name is required!" }
                  ]}
                >
                  <AntInput
                    size="large"
                    type="text"
                    placeholder="Please enter first name"
                    onChange={handleChange}
                  />
                </Form.Item>
              </div>
              <div>
                <InputLabel label="Last Name" />
                <Form.Item
                  name="lastName"
                  rules={[
                    { required: true, message: "Last Name is required!" }
                  ]}
                >
                  <AntInput
                    size="large"
                    type="text"
                    placeholder="Please enter last name"
                    onChange={handleChange}
                  />
                </Form.Item>
              </div>
            </div>

            <InputLabel label="Email" />
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input email!" },
                {
                  type: "email",
                  message: "Invalid Email!"
                }
              ]}
            >
              <AntInput
                size="large"
                type="text"
                placeholder="Please enter email"
                onChange={handleChange}
              />
            </Form.Item>
            <InputLabel label="Telephone" />
            <Form.Item name="telephone">
              <AntInput
                size="large"
                type="text"
                placeholder="Please enter phone"
                onChange={handleChange}
              />
            </Form.Item>
            <InputLabel label="Password" />
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input password!" },
                { validator: validatePassword }
              ]}
            >
              <AntInput.Password
                size="large"
                placeholder="Please enter password"
                onChange={handleChange}
              />
            </Form.Item>
            <InputLabel label="Password Repeat" />
            <Form.Item
              name="password-repeat"
              rules={[
                { required: true, message: "Please re-input password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  }
                })
              ]}
            >
              <AntInput.Password
                size="large"
                placeholder="Please enter password"
                onChange={handleChange}
              />
            </Form.Item>
          </>
        )}
        {method === METHOD_USE && (
          <>
            <InputLabel label="Email" />
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input email!" },
                {
                  type: "email",
                  message: "Invalid Email!"
                }
              ]}
            >
              <AntInput
                size="large"
                placeholder="Please enter email"
                onChange={handleChange}
              />
            </Form.Item>
          </>
        )}
      </Form>
      <Loading loading={loading} />
    </div>
  );
}
