import React, { useEffect, useState } from "react";
import { Collapse, Button, Modal } from "antd";
import { useAppState } from "../../../../context";
import IntegrationFranconnect from "./integration-franconnect";
import IntegrationServiceminder from "./integration-serviceminder";
import franConnectLogo from "../../../../assets/images/brand-logo/logo_franconnect.png";
import serviceMinderLogo from "../../../../assets/images/brand-logo/logo_service_minder.png";
import clientTetherLogo from "../../../../assets/images/brand-logo/logo_client_tether.png";
import "./style.scss";
import CryptoJS from "crypto-js";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "../../../../utils/axios";
import { Loading } from "../../../../components";
import IntegrationClientTether from "./integration-client-tether";

const { Panel } = Collapse;

const boardPlatforms = [
  { title: "Franconnect", url: franConnectLogo, key: "franconnect" },
  {
    title: "Service Minder",
    url: serviceMinderLogo,
    key: "serviceminder",
  },
  {
    title: "Client Tether",
    url: clientTetherLogo,
    key: "clienttether",
  },
];
export default function Integrations() {
  const { getAccessTokenSilently } = useAuth0();
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const [credentialList, setCredentialList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const credentials = getCredentialsDecrypt(franchisorAdmin?.credential);
    setCredentialList(credentials);
  }, [franchisorAdmin?.credential]);

  function getCredentialsEncrypt(credentials) {
    if (credentials) {
      const parsedCredentials = credentials.map((item) => {
        if (
          item.platform === "franconnect" ||
          item.platform === "franconnectuat"
        ) {
          return {
            username: CryptoJS.AES.encrypt(
              item.username,
              process.env.REACT_APP_USERNAME_SECRET_KEY
            ).toString(),
            password: CryptoJS.AES.encrypt(
              item.password,
              process.env.REACT_APP_PASSWORD_SECRET_KEY
            ).toString(),
            platform: item.platform,
            tenantID: CryptoJS.AES.encrypt(
              item.tenantID,
              process.env.REACT_APP_USERNAME_SECRET_KEY
            ).toString(),
            clientId: CryptoJS.AES.encrypt(
              item.clientId,
              process.env.REACT_APP_USERNAME_SECRET_KEY
            ).toString(),
            clientSecret: CryptoJS.AES.encrypt(
              item.clientSecret,
              process.env.REACT_APP_PASSWORD_SECRET_KEY
            ).toString(),
            autoSyncDisable: item.autoSyncDisable,
          };
        } else {
          return item;
        }
      });

      return parsedCredentials;
    }

    return [];
  }

  function getCredentialsDecrypt(credentials) {
    if (credentials) {
      const parsedCredentials = credentials.map((item) => {
        if (
          item.platform === "franconnect" ||
          item.platform === "franconnectuat"
        ) {
          return {
            ...item,
            username: CryptoJS.AES.decrypt(
              item.username || "",
              process.env.REACT_APP_USERNAME_SECRET_KEY
            ).toString(CryptoJS.enc.Utf8),
            password: CryptoJS.AES.decrypt(
              item.password || "",
              process.env.REACT_APP_PASSWORD_SECRET_KEY
            ).toString(CryptoJS.enc.Utf8),
            tenantID: CryptoJS.AES.decrypt(
              item.tenantID || "",
              process.env.REACT_APP_USERNAME_SECRET_KEY
            ).toString(CryptoJS.enc.Utf8),
            clientId: CryptoJS.AES.decrypt(
              item.clientId || "",
              process.env.REACT_APP_USERNAME_SECRET_KEY
            ).toString(CryptoJS.enc.Utf8),
            clientSecret: CryptoJS.AES.decrypt(
              item.clientSecret || "",
              process.env.REACT_APP_PASSWORD_SECRET_KEY
            ).toString(CryptoJS.enc.Utf8),
          };
        } else {
          return item;
        }
      });
      return parsedCredentials;
    }
    return [];
  }

  function handleAddIntegration(platform) {
    switch (platform) {
      case "franconnect":
        credentialList.push({
          username: "",
          password: "",
          platform:
            process.env.REACT_APP_APP_TYPE === "dev"
              ? "franconnectuat"
              : "franconnect",
          tenantID: "",
          clientId: "",
          clientSecret: "",
          autoSyncDisable: false,
        });
        setCredentialList([...credentialList]);
        break;
      case "serviceminder":
        credentialList.push({
          apiKey: "",
          platform: "serviceminder",
          autoSyncDisable: false,
        });
        setCredentialList([...credentialList]);
        break;
      case "clienttether":
        credentialList.push({
          accessToken: "",
          webKey: "",
          platform: "clienttether",
          autoSyncDisable: false,
        });
        setCredentialList([...credentialList]);
        break;
      default:
        break;
    }

    setIsOpen(false);
  }

  async function handleUpdate() {
    const credentialUpdate = getCredentialsEncrypt(credentialList);

    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const param = {
        credential: credentialUpdate,
      };
      if (franchisorAdmin?._id) {
        const result = await put(
          `requirement/${franchisorAdmin._id}`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
        }
      }
    } catch (error) {}
    setLoading(false);
  }

  const isEmpty = credentialList?.length > 0;

  return (
    <div className="admin_franchisee_integration">
      <div className="admin_franchisee_integration-header">
        <h3>Integrations</h3>
      </div>
      <div className="admin_franchisee_integration-main">
        {!isEmpty && (
          <div className="admin_franchisee_integration-main-empty">
            There is no integrations
          </div>
        )}
        {isEmpty &&
          credentialList.map((item, index) => {
            if (
              item.platform === "franconnect" ||
              item.platform === "franconnectuat"
            ) {
              return (
                <IntegrationFranconnect
                  data={credentialList}
                  dataIndex={index}
                  setData={setCredentialList}
                />
              );
            } else if (item.platform === "serviceminder") {
              return (
                <IntegrationServiceminder
                  data={credentialList}
                  dataIndex={index}
                  setData={setCredentialList}
                />
              );
            } else if (item.platform === "clienttether") {
              return (
                <IntegrationClientTether
                  data={credentialList}
                  dataIndex={index}
                  setData={setCredentialList}
                />
              );
            }
          })}
      </div>
      <div className="admin_franchisee_integration-footer">
        <Button
          onClick={() => setIsOpen(true)}
          className="admin_franchisee_integration-footer-add"
        >
          Add Integration
        </Button>
        <Button
          onClick={handleUpdate}
          type="primary"
          className="admin_franchisee_integration-footer-update"
        >
          Update
        </Button>
      </div>
      <Modal
        title="Select Integration"
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        footer={() => null}
      >
        <div className="admin_franchisee_integration-add">
          <h4>Select your platform</h4>
          <div className="admin_franchisee_integration-add-container">
            {boardPlatforms.map((platform) => (
              <div className="admin_franchisee_integration-add-container-wrapper">
                <div
                  className="admin_franchisee_integration-add-container-item"
                  style={{ backgroundImage: `url(${platform.url})` }}
                  onClick={() => handleAddIntegration(platform.key)}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <Loading loading={loading} />
    </div>
  );
}
