import React, { useState } from "react";
import { Input, Loading } from "../../../../../components";
import { Button, Modal, notification, Table, Switch } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import "./style.scss";
import { get, post } from "../../../../../utils/axios";
import { useAppState } from "../../../../../context";
import { useAuth0 } from "@auth0/auth0-react";
import { STATUS_PENDING } from "../../../../../assets/const/status";
import { filterValue } from "@utils/common";

export default function IntegrationClientTether(props) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const [franchiseeListData, setFranchiseeListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  function onSelectChange(selectedRowKeysUpdate) {
    setSelectedRowKeys(selectedRowKeysUpdate);
  }

  function handleRemove() {
    const { data, dataIndex, setData } = props;
    data.splice(dataIndex, 1);
    setData([...data]);
  }

  function handleChange(key, value) {
    const { data, dataIndex, setData } = props;
    data[dataIndex][key] = value;
    setData([...data]);
  }

  async function handleLoadFranchisees() {
    const { data, dataIndex, setData } = props;
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `clienttether/franchisee-list`,
        {
          accessToken: data[dataIndex].accessToken,
          webKey: data[dataIndex].webKey
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.status === 200) {
        const franchiseesList = result.data?.data;
        if (franchiseesList && franchiseesList.length > 0) {
          for (let i = 0; i < franchiseesList.length; i++) {
            franchiseesList[i].key = franchiseesList[i].account_id;
          }
        }
        const resultSelectKeys = franchiseesList.map((item) => item.key);
        setFranchiseeListData(franchiseesList);
        setSelectedRowKeys(resultSelectKeys);
        setIsOpen(true);
      } else {
        notification["error"]({
          message: "Failed",
          description:
            "Failed to fetch franchisees. Please contact admin directly!",
          duration: 3,
          style: {
            background: "#fef2f0",
            border: "1px solid #fbccc7"
          }
        });
      }
    } catch (error) {
      notification["error"]({
        message: "Failed",
        description: `Failed to load franchisees: ${error.message}`,
        duration: 3,
        style: {
          background: "#fef2f0",
          border: "1px solid #fbccc7"
        }
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleImportFranchisee() {
    const resultData = franchiseeListData.filter((item) => {
      return selectedRowKeys.find((key) => item.account_id === key);
    });

    const contactList = (resultData || [])
      .filter((item) => filterValue(item.account_email))
      .map((item) => {
        return {
          first_name: item.account_first_name,
          last_name: item.account_last_name,
          dba: "",
          entity_name: item.account_company_name,
          address: item.account_addr1,
          city: "",
          state: "",
          zip: "",
          email: item.account_email.toLowerCase(),
          telephone: item.account_phone,
          notes: "",
          requirementId: franchisorAdmin._id,
          activities: [],
          status: STATUS_PENDING,
          sourcePlatform: "clienttether",
          tenantId: "",
          detail: item,
          createdBy: user?.email
        };
      });

    try {
      setImportLoading(true);
      const token = await getAccessTokenSilently();
      const franchiseeNo = selectedRowKeys.join(",");
      let resultFranchiseeDetail = await post(
        `contact/create-list`,
        {
          contacts: contactList,
          requirementId: franchisorAdmin._id,
          method: "overwrite",
          sourcePlatform: "clienttether"
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setImportLoading(false);
      setIsOpen(false);
    } catch (e) {
      setImportLoading(false);
    }
  }
  function handleSelectCancel() {
    setSelectedRowKeys([]);
  }
  const { data, dataIndex } = props;

  const columns = [
    {
      title: "Franchisee Name",
      dataIndex: "account_company_name"
    },
    {
      title: "Email",
      dataIndex: "account_email"
    }
  ];

  return (
    <div className="admin_franchisee_integration_detail">
      {expand && (
        <div className="admin_franchisee_integration_detail-form">
          <div
            className="admin_franchisee_integration_detail-form-header"
            onClick={() => setExpand(false)}
          >
            <div className="admin_franchisee_integration_detail-form-header-title">
              {data[dataIndex].platform}
            </div>
            <DownOutlined />
          </div>
          <div className="admin_franchisee_integration_detail-form-main">
            <div className="admin_franchisee_integration_detail-form-main-wrapper">
              <div className="admin_franchisee_integration_detail-form-main-wrapper-left">
                <Input
                  title="Access Token"
                  type="text"
                  placeholder="Please enter the Access Token"
                  description="Ex: XXXX"
                  value={data[dataIndex].accessToken}
                  onChange={(evt) =>
                    handleChange("accessToken", evt.target.value)
                  }
                />
                <div>
                  <p className="input-title">Disable Auto Sync</p>
                  <Switch
                    checked={data[dataIndex].autoSyncDisable}
                    onChange={(evt) => handleChange("autoSyncDisable", evt)}
                  ></Switch>
                </div>
              </div>
              <div className="admin_franchisee_integration_detail-form-main-wrapper-left">
                <Input
                  title="Web Key"
                  type="text"
                  placeholder="Please enter the Web Key"
                  description="Ex: XXXX"
                  value={data[dataIndex].webKey}
                  onChange={(evt) => handleChange("webKey", evt.target.value)}
                />
              </div>
              <div className="admin_franchisee_integration_detail-form-main-wrapper-right"></div>
            </div>
            <div className="admin_franchisee_integration_detail-form-main-footer">
              <Button onClick={handleLoadFranchisees}>Load Franchisees</Button>
              <Button danger onClick={handleRemove}>
                Remove
              </Button>
            </div>
          </div>
        </div>
      )}
      {!expand && (
        <div
          className="admin_franchisee_integration_detail-preview"
          onClick={() => setExpand(true)}
        >
          <div className="admin_franchisee_integration_detail-preview-title">
            {data[dataIndex].platform}
          </div>
          <RightOutlined />
        </div>
      )}

      <Loading loading={loading} />
      <Modal
        title="Import Franchisees"
        open={isOpen}
        onOk={handleImportFranchisee}
        onCancel={() => setIsOpen(false)}
        className="admin_franchisee_integration_detail-modal"
        okText="Import"
      >
        <div className="admin_franchisee_integration_detail-modal-wrapper">
          <div className="admin_franchisee_integration_detail-modal-wrapper_header">
            {selectedRowKeys.length} items selected
            <a className="btn-cancel" onClick={handleSelectCancel}>
              Cancel
            </a>
          </div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={franchiseeListData}
          />
          <Loading loading={importLoading} />
        </div>
      </Modal>
    </div>
  );
}
