import React from "react";
import { Card } from "../../../../components";
import { Input, Tooltip, notification } from "antd";
import { CopyOutlined } from "@ant-design/icons";

export default function PortalLink(props) {
  const { franchiseeData } = props;
  function handleCopyLink(link) {
    navigator.clipboard.writeText(link);
    notification.open({
      message: "Public Portal Link was copied",
      description: "",
      duration: 3,
    });
  }

  const portalLink = `${window.location.origin}/coiportal/add/${franchiseeData?._id}`;

  return (
    <Card title="Portal Link">
      <Input
        size="large"
        disabled
        value={portalLink}
        suffix={
          <Tooltip title="Copy">
            <CopyOutlined
              style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
              onClick={() => handleCopyLink(portalLink)}
            />
          </Tooltip>
        }
      />
    </Card>
  );
}
