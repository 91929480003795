import React, { useState } from "react";
import { Radio } from "antd";
import { BlockBuildOut } from "@components/underwriting";
import Card from "@components/card";
import "./style.scss";

export default function QuoteBuildOutBlock({ data, onChange }) {
  const [isBuildOut, setIsBuildOut] = useState(false);

  return (
    <div className="qu_build">
      <h4>Do you have a build-out?</h4>
      <Radio.Group
        value={isBuildOut}
        onChange={(evt) => setIsBuildOut(evt?.target?.value)}
      >
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>
      {isBuildOut && (
        <Card className="qu_build_out" title="Build-Out Info">
          <BlockBuildOut data={data} onChange={onChange} />
        </Card>
      )}
    </div>
  );
}
