import { BlockPOC } from "@components/underwriting";
import { Loading } from "@components";
import React, { useEffect, useMemo, useState } from "react";
import { Button, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import { getPrimaryContact } from "@utils/common";
import "./style.scss";

export default function PanelPOC({
  franchiseeData,
  setFranchiseeData,
  onNext
}) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [formVal, setFormVal] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [franchiseeData]);

  function handleGetFormVal() {
    const pocContacts = JSON.parse(
      JSON.stringify(franchiseeData?.poc_contacts || [])
    );
    const primaryContact = getPrimaryContact(franchiseeData);
    if (
      !pocContacts.find(
        (contactItem) => contactItem.email === primaryContact.email
      )
    ) {
      pocContacts.unshift(primaryContact);
    }
    return pocContacts;
  }

  async function handleSave() {
    setLoading(true);
    try {
      const log_poc_contacts = franchiseeData?.log_poc_contacts || [];

      log_poc_contacts.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: formVal
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          poc_contacts: formVal,
          log_poc_contacts
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result?.data?.entity);
      notification.success({
        message: "Success",
        description: "POC Information is successfully updated!",
        duration: 5
      });
    } catch (error) {
      console.log("error ---", error);
      notification.error({
        message: "Failed",
        description:
          "Saving POC Information failed! Please contact admin directly.",
        duration: 5
      });
    }

    setLoading(false);
  }

  const isFormValDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetFormVal());
    const changedStr = JSON.stringify(formVal);

    return originalStr != changedStr;
  }, [formVal, franchiseeData]);

  return (
    <div className="pan_driver">
      <div className="pan_driver-info">
        <BlockPOC
          data={formVal}
          onChange={setFormVal}
          franchiseeData={franchiseeData}
          setFranchiseeData={setFranchiseeData}
        />
        <div className="pan_driver-info-footer">
          <Button
            onClick={() => {
              handleSave();
            }}
            size="large"
            type="primary"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              onNext();
            }}
            size="large"
            type="primary"
          >
            Everything Looks Good, Next
          </Button>
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}
