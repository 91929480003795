import React from "react";
import "./style.scss";
import { Input } from "../../../index";
import { maskInput } from "@utils/common";
import { TYPE_INPUT_TAX_ID } from "@assets/const/ui";

export default function OriginalRecordForm(props) {
  const {
    data: franchiseeData,
    disabled,
    onChange,
    titleOverrides = {}
  } = props;

  function handleChange(key, value) {
    onChange({
      ...franchiseeData,
      [key]: value
    });
  }
  return (
    <div className="original_record-form">
      <div className="form-group-four-container">
        <Input
          title="Entity Name"
          type="text"
          value={franchiseeData.entity_name}
          disabled={disabled}
          onChange={(evt) => handleChange("entity_name", evt.target.value)}
        />
        <Input
          title="DBA"
          type="text"
          value={franchiseeData.dba}
          disabled={disabled}
          onChange={(evt) => handleChange("dba", evt.target.value)}
        />
        <Input
          title="Opening Date"
          type="text"
          value={franchiseeData?.opening_date}
          disabled={disabled}
          onChange={(evt) => handleChange("opening_date", evt.target.value)}
        />
        <Input
          title="Tax ID"
          type="text"
          inputType={TYPE_INPUT_TAX_ID}
          value={franchiseeData?.tax_id}
          disabled={disabled}
          onChange={(evt) => handleChange("tax_id", evt.target.value)}
        />
        <Input
          title="Center Name/Location Number"
          type="text"
          value={franchiseeData.centerName}
          disabled={disabled}
          onChange={(evt) => handleChange("centerName", evt.target.value)}
        />
        <Input
          title="FMS Status"
          type="text"
          value={franchiseeData?.FMSStatus}
          disabled={disabled}
          onChange={(evt) => handleChange("FMSStatus", evt.target.value)}
        />
        <Input
          title="Estimated Annual Revenue"
          type="text"
          value={franchiseeData?.annualRevenue}
          disabled={disabled}
          onChange={(evt) => handleChange("annualRevenue", evt.target.value)}
        />
      </div>
      <div className="form-group-two-container">
        <div className="line-item-container">
          <Input
            title={titleOverrides.address || "Street Address"}
            type="text"
            value={franchiseeData?.address}
            disabled={disabled}
            onChange={(evt) => handleChange("address", evt.target.value)}
          />
        </div>
        <div className="form-group-three-container">
          <Input
            title="City"
            type="text"
            value={franchiseeData?.city}
            disabled={disabled}
            onChange={(evt) => handleChange("city", evt.target.value)}
          />
          <Input
            title="State"
            type="text"
            value={franchiseeData?.state}
            disabled={disabled}
            onChange={(evt) => handleChange("state", evt.target.value)}
          />
          <Input
            title="Zip"
            type="text"
            value={franchiseeData?.zip}
            disabled={disabled}
            onChange={(evt) => handleChange("zip", evt.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
