import React, { useState, useMemo } from "react";
import "./style.scss";
import { Table, Tag, Space, Button } from "antd";
import { Loading } from "../../../components";
import { RollbackOutlined } from "@ant-design/icons";
import { onboardingWorkflow } from "../../../assets/const/onboarding";
import { useAppState } from "../../../context";
import { post } from "../../../utils/axios";
import {
  PLATFORM_FRANCONNECT,
  PLATFORM_SERVICE_MINDER,
} from "../../../assets/const/fms-entity";
import { useAuth0 } from "@auth0/auth0-react";

export default function ListFranchisee(props) {
  const { franchiseeData, authSetting, requirement } = useAppState();
  const data = useMemo(() => {
    if (franchiseeData?.platform === PLATFORM_FRANCONNECT) {
      const dataRaw = JSON.parse(JSON.stringify(franchiseeData.data));
      if (dataRaw && dataRaw.length > 0) {
        for (let index = 0; index < dataRaw.length; index++) {
          dataRaw[index].key = dataRaw[index].franchisee_no;
        }
        return dataRaw;
      } else {
        return [];
      }
    } else if (franchiseeData?.platform === PLATFORM_SERVICE_MINDER) {
      const dataRaw = JSON.parse(JSON.stringify(franchiseeData.data));
      if (dataRaw && dataRaw.length > 0) {
        for (let index = 0; index < dataRaw.length; index++) {
          dataRaw[index].key = dataRaw[index].Email;
        }
        return dataRaw;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [franchiseeData?.data]);

  const columns = useMemo(() => {
    switch (franchiseeData?.platform) {
      case PLATFORM_FRANCONNECT:
        return [
          {
            title: "Franchisee Name",
            dataIndex: "franchisee_name",
            key: "franchisee_name",
          },
          {
            title: "Franchisee No",
            dataIndex: "franchisee_no",
            key: "franchisee_no",
          },
          {
            title: "Saved",
            dataIndex: "is_store",
            key: "is_store",
          },
        ];
      case PLATFORM_SERVICE_MINDER:
        return [
          {
            title: "Franchisee Name",
            dataIndex: "PublicName",
            key: "PublicName",
          },
          {
            title: "Email",
            dataIndex: "Email",
            key: "Email",
          },
          {
            title: "Website",
            dataIndex: "Website",
            key: "Website",
          },
        ];
      default:
        return [];
    }
  }, [franchiseeData?.platform]);

  const { getAccessTokenSilently } = useAuth0();

  const [selectedRowKeys, setSelectedRowKeys] = useState(
    data.map((item) => item.key)
  );
  const [modalFlag, setModalFlag] = useState(false);
  const [syncProgress, setSyncProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function onSelectChange(selectedRowKeysUpdate) {
    setSelectedRowKeys(selectedRowKeysUpdate);
  }

  function handleRefresh() {}

  function handleSelectCancel() {
    setSelectedRowKeys([]);
  }

  async function handleImport() {
    if (franchiseeData?.platform === PLATFORM_FRANCONNECT) {
      await handleImportFranconnect();
    } else {
      await handleImportServiceMinder();
    }
  }

  async function handleImportServiceMinder() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const selectedKeyData = selectedRowKeys.join(",");
      const sendData = (franchiseeData.data || []).filter((item) =>
        selectedKeyData.includes(item.Email)
      );

      let resultFranchiseeDetail = await post(
        `serviceminder/franchisee-detail`,
        {
          franchiseeData: sendData,
          requirementId: requirement._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      const { handleNavigate } = props;
      handleNavigate(onboardingWorkflow.INSURANCE_REQUIREMENT);
    } catch (e) {
      setLoading(false);
    }
  }

  async function handleImportFranconnect() {
    setModalFlag(false);
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const franchiseeNo = selectedRowKeys.join(",");
      let resultFranchiseeDetail = await post(
        `franconnect/franchisee-detail`,
        {
          token: franchiseeData.token,
          tenantID: franchiseeData.tenantID,
          requirementId: requirement._id,
          franchiseeNo: franchiseeNo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      const { handleNavigate } = props;
      handleNavigate(onboardingWorkflow.INSURANCE_REQUIREMENT);
    } catch (e) {
      setLoading(false);
    }
  }

  function handleCancel() {
    const { handleNavigate } = props;
    handleNavigate(onboardingWorkflow.LIST_FRANCHISEE);
    setModalFlag(false);
  }

  async function handleSavePreview() {
    const { handleNavigate } = props;
    handleNavigate(onboardingWorkflow.PREVIEW_IMPORT_CONTACTS);
    setModalFlag(true);
  }

  return (
    <div className="list-franchisee-container">
      <h3 className="list-franchisee-title">Sync Franchisees</h3>
      <div className="list-franchisee-card">
        <div className="content-wrapper">
          <h4>Franchisees</h4>
          <div className="table-container">
            <div className="table-header-container">
              <a onClick={handleRefresh}>
                Refresh <RollbackOutlined />
              </a>
              <div className="btns-container">
                {selectedRowKeys.length} items selected
                <a className="btn-cancel" onClick={handleSelectCancel}>
                  Cancel
                </a>
              </div>
            </div>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
            />
            <div className="table-footer-container">
              <Button type="primary" onClick={handleSavePreview}>
                Save & Preview
              </Button>
            </div>
          </div>
        </div>
        {modalFlag && (
          <div className="modal-container">
            <div className="modal-back-container"></div>
            <div className="modal-content-card">
              <div className="modal-content">
                <h3 className="modal-header-title">Synced Franchisees </h3>
                <h1>{selectedRowKeys.length}</h1>
                <p>Would you like to sync all of your franchisees?</p>
              </div>
              <div className="modal-footer">
                <Button className="cancel-btn" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="save-btn"
                  onClick={handleImport}
                >
                  Import
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Loading loading={loading} />
    </div>
  );
}
