import Card from "@components/card";
import Input from "@components/input";
import { Switch } from "antd";
import "./style.scss";
import moment from "moment";
import _ from "lodash";
import { toUSD } from "@utils/common";

export default function WorkerCompQuotePreview({ data, readOnly = false }) {
  return (
    <Card className="worker_comp_preview-detail" title={"Worker Compensation"}>
      <div className="header form-group-four-container">
        <Input
          title="Effective Date"
          type="text"
          value={
            data.effectiveDate
              ? moment(data.effectiveDate).format("MM/DD/YYYY")
              : ""
          }
          readOnly={readOnly}
        />
        <Input
          title="Expiration Date"
          type="text"
          value={
            data.expirationDate
              ? moment(data.expirationDate).format("MM/DD/YYYY")
              : ""
          }
          readOnly={readOnly}
        />
        <Input
          title="Policy Number"
          type="text"
          value={data.policyNumber || ""}
          readOnly={readOnly}
        />
        <Input
          title="Premium"
          type="text"
          value={toUSD(data.premium)}
          readOnly={readOnly}
        />
      </div>
      <div className="section">
        <div className="form-group-three-container-md-gap">
          <div className="coverage-type-form-group">
            <div>
              <p className="input-title">Coverage Type</p>
              <Input
                className="field-tab"
                value="Bodily injury by accident, per person"
                size="large"
                readOnly={readOnly}
              ></Input>
              <Input
                className="field-tab"
                value="Bodily injury by disease, policy limit"
                size="large"
                readOnly={readOnly}
              ></Input>
              <Input
                className="field-tab"
                value="Bodily injury by disease, per person"
                size="large"
                readOnly={readOnly}
              ></Input>
            </div>
            <div>
              <p className="input-title">Limit</p>
              <Input
                value={toUSD(data.bodilyInjuryByAccidentPerPerson)}
                size="large"
                readOnly={readOnly}
              ></Input>

              <Input
                value={toUSD(data.bodilyInjuryByDiseasePolicyLimit)}
                size="large"
                readOnly={readOnly}
              ></Input>
              <Input
                value={toUSD(data.bodilyInjuryByDiseasePerPerson)}
                size="large"
                readOnly={readOnly}
              ></Input>
            </div>
          </div>

          {(data.ownerOfficers || []).length > 0 && (
            <div className="owner-officer-form-group">
              <div>
                <p className="input-title">Owner/Officer</p>
                {(data.ownerOfficers || []).map((ownerOfficer) => (
                  <Input
                    className="field-tab"
                    value={ownerOfficer.name || ""}
                    size="large"
                    readOnly={readOnly}
                  ></Input>
                ))}
              </div>
              <div>
                <p className="input-title">%</p>
                {(data.ownerOfficers || []).map((ownerOfficer) => (
                  <Input
                    value={ownerOfficer.ownership || ""}
                    size="large"
                    readOnly={readOnly}
                  ></Input>
                ))}
              </div>
              <div>
                <p className="input-title">Payroll</p>
                {(data.ownerOfficers || []).map((ownerOfficer) => (
                  <Input
                    value={ownerOfficer.payroll || ""}
                    size="large"
                    readOnly={readOnly}
                  ></Input>
                ))}
              </div>
              <div>
                <p className="input-title">Inc/Ex</p>
                {(data.ownerOfficers || []).map((ownerOfficer) => (
                  <Input
                    value={ownerOfficer.status || ""}
                    size="large"
                    readOnly={readOnly}
                  ></Input>
                ))}
              </div>
            </div>
          )}

          <div className="policy-level-data-form-group">
            <div>
              <p className="input-title">Policy Level Data</p>
              <div className="input-group">
                <Input
                  className="field-tab"
                  value="Experience Modification"
                  size="large"
                  readOnly={readOnly}
                ></Input>
                <Switch
                  className="switch"
                  disabled={readOnly}
                  checked={
                    data.policyLevelData?.experienceModification?.enabled
                  }
                />
              </div>

              <div className="input-group">
                <Input
                  className="field-tab"
                  value="Blanket Waiver of Subrogation"
                  size="large"
                  readOnly={readOnly}
                ></Input>
                <Switch
                  className="switch"
                  disabled={readOnly}
                  checked={
                    data.policyLevelData?.blanketWaiverOfSubrogation?.enabled
                  }
                />
              </div>
            </div>
            <div>
              <p className="input-title">Value</p>
              <Input
                value={
                  data.policyLevelData?.experienceModification?.value || ""
                }
                size="large"
                readOnly={readOnly}
              ></Input>

              <Input
                value={
                  data.policyLevelData?.blanketWaiverOfSubrogation?.value || ""
                }
                size="large"
                readOnly={readOnly}
              ></Input>
            </div>
          </div>
        </div>
      </div>
      {_.keys(data.locations || []).length > 0 && (
        <div className="section">
          {(data.locations || []).map((location) => (
            <div className="location">
              <h3>{location.address}</h3>
              <div className="location-detail-form-group">
                <div>
                  <p className="input-title">Class Code</p>
                  {(location.classCodes || []).map((code) => (
                    <Input
                      className="field-tab"
                      value={`${code.classCode || ""}${
                        code.classCodeDescription
                          ? " - " + code.classCodeDescription
                          : ""
                      }`}
                      size="large"
                      readOnly={readOnly}
                    ></Input>
                  ))}
                </div>
                <div>
                  <p className="input-title">Exposure Basis</p>
                  {(location.classCodes || []).map((code) => (
                    <Input
                      value={`${code.exposureBasis || ""}`}
                      size="large"
                      readOnly={readOnly}
                    ></Input>
                  ))}
                </div>
                <div>
                  <p className="input-title">Rate</p>
                  {(location.classCodes || []).map((code) => (
                    <Input
                      value={`${code.rate || ""}`}
                      size="large"
                      readOnly={readOnly}
                    ></Input>
                  ))}
                </div>
                <div>
                  <p className="input-title">Estimated Exposure</p>
                  {(location.classCodes || []).map((code) => (
                    <Input
                      value={`${code.estimatedExposure || ""}`}
                      size="large"
                      readOnly={readOnly}
                    ></Input>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
}
