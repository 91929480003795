import React, { useState, useEffect, useMemo } from "react";
import { Input, Loading } from "@components";
import { Checkbox, Button, notification } from "antd";
import "./style.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, put } from "@utils/axios";
import { mapFieldWC } from "@utils/form-recog";
import {
  buildGeneralLiabilitySubrogrationWaved,
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  reportWorkComp,
  getWorkCompScore,
} from "@utils/build-logic";
import { PERMISSION_TABLE } from "@utils/permission";
import { useAppState } from "@context";
import { isEmpty } from "lodash";
import {
  DOC_MAX_LIMIT,
  INSURANCE_STATUS_NON_COMPLIANT,
  STATUS_SECTION_APPROVE,
  STATUS_SECTION_PENDING,
} from "@assets/const/status";
import { TYPE_INPUT_DATE } from "@assets/const/ui";
import { validateDate } from "@utils/common";
import { format } from "date-fns";

export default function WorkersCompForm(props) {
  const { docIndex, franchisorData } = props;
  const { getAccessTokenSilently } = useAuth0();
  const { authSetting } = useAppState();
  const [formVal, setFormVal] = useState(handleGetFormVal());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      (docIndex >= 0 &&
        docIndex ===
          franchiseeData?.finalData?.workers_compensation_form_doc_index) ||
      docIndex === DOC_MAX_LIMIT
    ) {
      if (
        franchiseeData?.finalData?.workers_compensation_form_status ===
          STATUS_SECTION_APPROVE ||
        franchiseeData?.finalData?.workers_compensation_form_status ===
          STATUS_SECTION_PENDING
      ) {
        const finalData = franchiseeData.finalData;

        return {
          workers_compensation_subrogration_waved:
            finalData.workers_compensation_subrogration_waved,
          workers_compensation_el_each_accident:
            finalData.workers_compensation_el_each_accident,
          workers_compensation_other_el_disease_each_employer:
            finalData.workers_compensation_other_el_disease_each_employer,
          workers_compensation_el_disease_policy_limit:
            finalData.workers_compensation_el_disease_policy_limit,
          workers_compensation_policy_number:
            finalData.workers_compensation_policy_number,
          workers_compensation_effective_date:
            finalData.workers_compensation_effective_date,
          workers_compensation_expire_date:
            finalData.workers_compensation_expire_date,
        };
      } else {
        return getEmptyForm();
      }
    }

    if (docIndex >= 0) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldWC(data);
      }
    }

    return getEmptyForm();
  }

  function getEmptyForm() {
    return {
      workers_compensation_subrogration_waved: "",
      workers_compensation_el_each_accident: 0,
      workers_compensation_other_el_disease_each_employer: 0,
      workers_compensation_el_disease_policy_limit: 0,
      workers_compensation_policy_number: "",
      workers_compensation_effective_date: "",
      workers_compensation_expire_date: "",
    };
  }

  async function handleApprove() {
    let { franchiseeData, docIndex } = props;

    if (docIndex >= 0) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        let activities = franchiseeData.activities;
        if (activities) {
          activities.push({
            action: "STATUS_APPROVE_WORKERS_COMPENSATION",
            date: new Date(),
          });
        } else {
          activities = [
            {
              action: "STATUS_APPROVE_WORKERS_COMPENSATION",
              date: new Date(),
            },
          ];
        }

        let finalData = franchiseeData?.finalData
          ? franchiseeData.finalData
          : {};
        finalData = {
          ...finalData,
          ...formVal,
          workers_compensation_form_status:
            !isEmpty(formVal.workers_compensation_expire_date) &&
            new Date(formVal.workers_compensation_expire_date) < new Date()
              ? STATUS_SECTION_PENDING
              : STATUS_SECTION_APPROVE,
          workers_compensation_form_doc_index: docIndex,
          workers_compensation_effective_date: validateDate(
            formVal.workers_compensation_effective_date
          )
            ? format(
                new Date(formVal.workers_compensation_effective_date),
                "yyyy-MM-dd"
              )
            : null,
          workers_compensation_expire_date: validateDate(
            formVal.workers_compensation_expire_date
          )
            ? format(
                new Date(formVal.workers_compensation_expire_date),
                "yyyy-MM-dd"
              )
            : null,
        };

        const resultIssue = reportWorkComp(finalData, franchisorData);

        const result = await put(
          `contact/${franchiseeData._id}`,
          {
            finalData: finalData,
            activities: activities,
            workCompIssue: resultIssue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        await deleteAPI(`notification/delete/${franchiseeData._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { handleUpdateForm } = props;
        handleUpdateForm(result.data.entity);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      handleNotification("Failed", "Need to choose a documentation");
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  const coverageScore = useMemo(() => {
    let finalData = props.franchiseeData?.finalData
      ? props.franchiseeData.finalData
      : {};
    finalData = {
      ...finalData,
      ...formVal,
      workers_compensation_form_status:
        !isEmpty(formVal.workers_compensation_expire_date) &&
        new Date(formVal.workers_compensation_expire_date) < new Date()
          ? STATUS_SECTION_PENDING
          : STATUS_SECTION_APPROVE,
      workers_compensation_form_doc_index: docIndex,
      workers_compensation_effective_date: validateDate(
        formVal.workers_compensation_effective_date
      )
        ? formVal.workers_compensation_effective_date
        : null,
      workers_compensation_expire_date: validateDate(
        formVal.workers_compensation_expire_date
      )
        ? formVal.workers_compensation_expire_date
        : null,
    };

    const resultScore = getWorkCompScore(finalData, franchisorData);
    return resultScore;
  }, [props.franchiseeData, formVal, franchisorData]);

  return (
    <div className="workers-comp-content">
      {coverageScore.visible && (
        <div className="workers-comp-content-score">
          Score: {coverageScore.score} / {coverageScore.total}
        </div>
      )}
      <div className="form-main">
        <div className="panel-left">
          <div className="checkbox-group-container">
            <h5 className="checkbox-group-title">WORKERS COMPENSATION</h5>
            <div className="checkbox-group-content">
              <Checkbox
                checked={formVal.workers_compensation_subrogration_waved}
                onChange={(evt) =>
                  handleChange(
                    "workers_compensation_subrogration_waved",
                    evt.target.checked
                  )
                }
              >
                Waiver Of Subrogation
              </Checkbox>
            </div>
            {buildGeneralLiabilitySubrogrationWaved(
              formVal.workers_compensation_subrogration_waved,
              franchisorData.workers_compensation_subrogration_waved,
              franchisorData.workers_compensation_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <div className="alert-container">
              <Input
                title="POLICY NUMBER"
                placeholder="POLICY NUMBER"
                value={formVal.workers_compensation_policy_number}
                onChange={(evt) =>
                  handleChange(
                    "workers_compensation_policy_number",
                    evt.target.value
                  )
                }
              />
              <Input
                title="EFFECTIVE DATE"
                placeholder="EFFECTIVE DATE"
                value={formVal.workers_compensation_effective_date}
                inputType={TYPE_INPUT_DATE}
                onChange={(evt) =>
                  handleChange(
                    "workers_compensation_effective_date",
                    evt.target.value
                  )
                }
              />
              {buildEffectDate(
                formVal.workers_compensation_effective_date,
                franchisorData.workers_compensation_required
              ).map((item) => (
                <div className="issue-report-item">{item.title}</div>
              ))}
              <Input
                title="EXPIRATION DATE"
                placeholder="EXPIRATION DATE"
                value={formVal.workers_compensation_expire_date}
                inputType={TYPE_INPUT_DATE}
                onChange={(evt) =>
                  handleChange(
                    "workers_compensation_expire_date",
                    evt.target.value
                  )
                }
              />
              {buildExpireDate(
                formVal.workers_compensation_expire_date,
                franchisorData.workers_compensation_required
              ).map((item) => (
                <div className="issue-report-item">{item.title}</div>
              ))}
            </div>
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH ACCIDENT"
              placeholder="EACH ACCIDENT"
              value={formVal.workers_compensation_el_each_accident}
              onChange={(evt) =>
                handleChange(
                  "workers_compensation_el_each_accident",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.workers_compensation_el_each_accident,
              franchisorData.workers_compensation_el_each_accident,
              franchisorData.workers_compensation_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="EACH EMPLOYEE"
              subTitle="( Disease )"
              placeholder="EACH EMPLOYEE"
              value={
                formVal.workers_compensation_other_el_disease_each_employer
              }
              onChange={(evt) =>
                handleChange(
                  "workers_compensation_other_el_disease_each_employer",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.workers_compensation_other_el_disease_each_employer,
              franchisorData.workers_compensation_other_el_disease_each_employer,
              franchisorData.workers_compensation_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="POLICY LIMIT "
              subTitle="( Disease )"
              placeholder="POLICY LIMIT"
              value={formVal.workers_compensation_el_disease_policy_limit}
              onChange={(evt) =>
                handleChange(
                  "workers_compensation_el_disease_policy_limit",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.workers_compensation_el_disease_policy_limit,
              franchisorData.workers_compensation_el_disease_policy_limit,
              franchisorData.workers_compensation_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="form-footer">
        {PERMISSION_TABLE["FRANCHISEE_DATA_APPROVE"][
          authSetting.userPermission
        ] > 0 && (
          <Button type="primary" size="large" onClick={handleApprove}>
            Approve
          </Button>
        )}
      </div>
      <Loading loading={loading} />
    </div>
  );
}
