import React, { useState } from "react";
import { Header, Stepper } from "../../../components";
import { Button, Progress } from "antd";
import "./style.scss";
import noDataImage from "../../../assets/images/no-data.png";
import { onboardingWorkflow } from "../../../assets/const/onboarding";

function OnboardingStep(props) {
  const [progressStatus, setProgressStatus] = useState(0);

  const { step } = props;

  function handleSync() {
    const { handleNavigate } = props;
    handleNavigate();
  }

  function handleCheckCurrentProgress() {
    switch (step) {
      case onboardingWorkflow.SYNC_FRANCHISEES:
        return {
          mainOptionIndex: 0,
          subOptionIndex: -1,
          percent: 0,
        };
      case onboardingWorkflow.BOARD_PLATFORMS:
        return {
          mainOptionIndex: 0,
          subOptionIndex: -1,
          percent: 0,
        };
      case onboardingWorkflow.SIGN_IN_FRANCHISEE:
        return {
          mainOptionIndex: 0,
          subOptionIndex: 0,
          percent: 10,
        };
      case onboardingWorkflow.LIST_FRANCHISEE:
        return {
          mainOptionIndex: 0,
          subOptionIndex: 1,
          percent: 20,
        };
      case onboardingWorkflow.PREVIEW_IMPORT_CONTACTS:
        return {
          mainOptionIndex: 0,
          subOptionIndex: 2,
          percent: 30,
        };
      case onboardingWorkflow.INSURANCE_REQUIREMENT:
        return {
          mainOptionIndex: 1,
          subOptionIndex: 2,
          percent: 60,
        };
      case onboardingWorkflow.REQUEST_ACTIVIATION:
        return {
          mainOptionIndex: 2,
          subOptionIndex: 0,
          percent: 100,
        };
      case onboardingWorkflow.BOARD_UPLOAD:
        return {
          mainOptionIndex: 0,
          subOptionIndex: 2,
          percent: 30,
        };

      default:
        return {
          mainOptionIndex: 3,
          subOptionIndex: 0,
          percent: 100,
        };
    }
  }

  let result = handleCheckCurrentProgress();

  return (
    <div className="onboarding-step-container">
      <div className="onboarding-step-wrapper">
        <Stepper
          mainOptionIndex={result.mainOptionIndex}
          subOptionIndex={result.subOptionIndex}
        />
      </div>
      <div className="onboarding-step-footer">
        <span>
          <b>{result.percent}% progress</b>
        </span>
        <Progress percent={result.percent} />
      </div>
    </div>
  );
}

export default OnboardingStep;
