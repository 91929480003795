import Card from "@components/card";
import React from "react";
import { Input, Tooltip, notification } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import "./style.scss";

export default function Integration({ data }) {
  function handleCopyLink(link) {
    navigator.clipboard.writeText(link);
    notification.open({
      message: "Link was copied",
      description: "",
      duration: 3,
    });
  }
  return (
    <div className="franchisee_integration">
      <Card title="Active Campaign">
        {data?.activeCampaign?.accountId && (
          <div className="franchisee_integration-section">
            <label className="franchisee_integration-section-label">
              Account:
            </label>
            <Input
              size="large"
              label="Account"
              disabled
              value={`${process.env.REACT_APP_AC_LINK}/app/accounts/${data?.activeCampaign?.accountId}`}
              suffix={
                <Tooltip title="Copy">
                  <CopyOutlined
                    style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
                    onClick={() =>
                      handleCopyLink(
                        `${process.env.REACT_APP_AC_LINK}/app/accounts/${data?.activeCampaign?.accountId}`
                      )
                    }
                  />
                </Tooltip>
              }
            />
          </div>
        )}
        {data?.activeCampaign?.primaryContactId && (
          <div className="franchisee_integration-section">
            <label className="franchisee_integration-section-label">
              Primary Contact:
            </label>
            <Input
              size="large"
              label="Account"
              disabled
              value={`${process.env.REACT_APP_AC_LINK}/app/contacts/${data?.activeCampaign?.primaryContactId}`}
              suffix={
                <Tooltip title="Copy">
                  <CopyOutlined
                    style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
                    onClick={() =>
                      handleCopyLink(
                        `${process.env.REACT_APP_AC_LINK}/app/contacts/${data?.activeCampaign?.primaryContactId}`
                      )
                    }
                  />
                </Tooltip>
              }
            />
          </div>
        )}
        {data?.activeCampaign?.contactIdList && (
          <div className="franchisee_integration-section">
            <label className="franchisee_integration-section-label">
              POC Contacts:
            </label>
            {Object.entries(data?.activeCampaign?.contactIdList).map((item) => (
              <div>
                {
                  <a
                    href={`${process.env.REACT_APP_AC_LINK}/app/contacts/${item[1]}`}
                    target="_blank"
                  >
                    {item[0]}
                  </a>
                }
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  );
}
