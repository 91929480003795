import React, { useState } from "react";
import "./style.scss";
import { Button, Table, notification } from "antd";
import ImageUploadFolder from "../../../../assets/images/upload-folder.png";
import { Loading, Alert } from "../../../../components";
import Papa from "papaparse";
import { CheckOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { post } from "../../../../utils/axios";

const VIEW_MODE_UPLOAD = "VIEW_MODE_UPLOAD";
const VIEW_MODE_PREVIEW = "VIEW_MODE_PREVIEW";

export default function AdminCodes(props) {
  const [viewMode, setViewMode] = useState(VIEW_MODE_UPLOAD);
  const [fileList, setFileList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  function handleUpload() {
    Papa.parse(fileList[0], {
      complete: handleUploadData,
      header: true,
    });
  }

  async function handleUploadData(result) {
    setViewMode(VIEW_MODE_PREVIEW);
    const fileData = result?.data
      ? result.data.map((item, index) => ({
          ...item,
          key: `key-item-${index}`,
        }))
      : [];
    setDataSource(fileData);
  }

  function handleFile(evt) {
    setFileList(evt.target.files);
  }

  async function handleImport() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/code/bulk`,
        {
          data: dataSource,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      notification.open({
        message: "Success",
        description: "Code Upload Success",
        duration: 3,
      });
    } catch (error) {
      setLoading(false);
      notification.open({
        message: "Fail",
        description: "Code Upload Fail",
        duration: 3,
      });
    }
  }

  function renderFiles() {
    for (let index = 0; index < fileList.length; index++) {
      return (
        <div className="file-item">
          {fileList[0].name} <CheckOutlined />
        </div>
      );
    }
  }

  const rowSelection = {};

  const columns = [
    {
      title: "General Description",
      dataIndex: "ge_desc",
      key: "ge_desc",
    },
    {
      title: "ISO Class BOP",
      dataIndex: "iso_bop",
      key: "iso_bop",
    },
    {
      title: "ISO BOP Description",
      dataIndex: "iso_bop_desc",
      key: "iso_bop_desc",
    },
    {
      title: "ISO Class CGL",
      dataIndex: "iso_cgl",
      key: "iso_cgl",
    },
    {
      title: "ISO CGL Description",
      dataIndex: "iso_cgl_desc",
      key: "iso_cgl_desc",
    },
    {
      title: "ISO Micro-BOP Description",
      dataIndex: "iso_mi_bop_desc",
      key: "iso_mi_bop_desc",
    },
    {
      title: "ISO Micro-BOP",
      dataIndex: "iso_micro_bop",
      key: "iso_micro_bop",
    },
    {
      title: "NAICS",
      dataIndex: "naics",
      key: "naics",
    },
    {
      title: "NAICS Description",
      dataIndex: "naics_desc",
      key: "naics_desc",
    },
    {
      title: "NAICS URL",
      dataIndex: "naics_url",
      key: "naics_url",
    },
    {
      title: "NCCI Desrciption",
      dataIndex: "ncci_desc",
      key: "ncci_desc",
    },
    {
      title: "SIC",
      dataIndex: "sic",
      key: "sic",
    },
    {
      title: "Workers Compensation CA",
      dataIndex: "wc_ca",
      key: "wc_ca",
    },
    {
      title: "Workers Compensation DE",
      dataIndex: "wc_de",
      key: "wc_de",
    },
    {
      title: "Workers Compensation MI",
      dataIndex: "wc_mi",
      key: "wc_mi",
    },
    {
      title: "Workers Compensation NCCI",
      dataIndex: "wc_ncci",
      key: "wc_ncci",
    },
    {
      title: "Workers Compensation NJ",
      dataIndex: "wc_nj",
      key: "wc_nj",
    },
    {
      title: "Workers Compensation NY",
      dataIndex: "wc_ny",
      key: "wc_ny",
    },
    {
      title: "Workers Compensation PA",
      dataIndex: "wc_pa",
      key: "wc_pa",
    },
    {
      title: "Workers Compensation TX",
      dataIndex: "wc_tx",
      key: "wc_tx",
    },
  ];

  return (
    <div className="admin_code">
      {viewMode === VIEW_MODE_UPLOAD && (
        <div className="admin_code_upload">
          <div className="admin_code_upload-container">
            <img src={ImageUploadFolder} />
            <p>
              Drag and Drop Your NCCI Codes CSV Here
              <br />
              or
            </p>
            {fileList.length === 0 && (
              <Button className="btn-add-file" onClick={handleUpload}>
                Add Files
              </Button>
            )}
            {renderFiles()}
            <input type="file" onChange={handleFile} />
          </div>

          <div className="admin_code_upload-footer">
            {fileList.length !== 0 && (
              <Button
                type="primary"
                className="upload-btn"
                onClick={handleUpload}
              >
                Upload
              </Button>
            )}
          </div>
        </div>
      )}
      {viewMode === VIEW_MODE_PREVIEW && (
        <div className="onboarding-form_select_platform">
          <div className="onboarding-form_select_platform-content">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={dataSource}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {},
                };
              }}
            />
          </div>
          <div className="onboarding-form_select_platform-footer">
            <Button
              className="cancel-btn"
              onClick={() => setViewMode(VIEW_MODE_UPLOAD)}
            >
              Cancel
            </Button>
            {!errorMsg && (
              <Button
                type="primary"
                className="save-btn"
                onClick={() => handleImport()}
              >
                Import
              </Button>
            )}
          </div>
          <Loading loading={loading} />
        </div>
      )}
    </div>
  );
}
