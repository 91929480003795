import React, { useState, useEffect, useMemo } from "react";
import { Input, Button, notification } from "antd";
import { Loading } from "../../../../components";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppState } from "../../../../context";
import { post, put } from "../../../../utils/axios";
import { PERMISSION_TABLE, ACCESS_FULL } from "../../../../utils/permission";
import { ZEE_RATING_BASIS } from "../../../../assets/const/zee-rating";
import { DeleteOutlined } from "@ant-design/icons";
import "./style.scss";

export default function ZeeRating(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { franchisorAdmin, setFranchisorAdmin, authSetting } = useAppState();
  const [loading, setLoading] = useState(false);
  const [formVal, setFormVal] = useState(
    franchisorAdmin.zee_rating_basis || ZEE_RATING_BASIS
  );

  const editable = useMemo(
    () =>
      PERMISSION_TABLE["ZEE_RATING"][authSetting.userPermission] ===
      ACCESS_FULL,
    [authSetting]
  );

  function handleChange(index, key, value) {
    formVal[index][key] = value;
    setFormVal([...formVal]);
  }

  function handleAdd() {
    formVal.push({
      title: "",
      value: "",
    });
    setFormVal([...formVal]);
  }

  async function handleSave() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (franchisorAdmin?._id) {
        const result = await put(
          `requirement/${franchisorAdmin._id}`,
          { zee_rating_basis: formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
        }
      } else {
        const result = await post(
          `requirement`,
          { zee_rating_basis: formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          const requirement = result.data.data;
          setFranchisorAdmin({ ...franchisorAdmin, ...requirement });
        }
      }
      setLoading(false);
    } catch (e) {
      handleNotification(
        "Failed",
        "Save form is failed. Please contact to admin directly."
      );
      setLoading(false);
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  function handleDeleteQuestion(index) {
    formVal.splice(index, 1);
    setFormVal([...formVal]);
  }

  return (
    <div className="zee_rating">
      {formVal.map((item, index) => (
        <div className="zee_rating-item">
          {!editable && (
            <div className="zee_rating-item-ques">{item.title}</div>
          )}
          {editable && (
            <Input
              size="large"
              value={item.title}
              onChange={(evt) => handleChange(index, "title", evt.target.value)}
            />
          )}
          <div className="zee_rating-item-answ">
            <Input
              size="large"
              value={item.value}
              onChange={(evt) => handleChange(index, "value", evt.target.value)}
            />
          </div>
          {editable && (
            <DeleteOutlined
              className="class_code-action-del"
              onClick={() => handleDeleteQuestion(index)}
            />
          )}
        </div>
      ))}
      <div className="zee_rating-footer">
        {editable && (
          <Button
            size="large"
            className="zee_rating-footer-add"
            type="default"
            onClick={handleAdd}
          >
            Add Question
          </Button>
        )}
        <Button size="large" type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
