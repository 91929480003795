import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumb, Button, Table, notification } from "antd";
import { Gauge, Treemap, Pie } from "@ant-design/plots";
import {
  COLOR_COMPLIANT,
  COLOR_NONE,
  COLOR_NON_COMPLIANT,
  COLOR_IN_PLACE,
  COLOR_UN_VERIFIED,
  COLOR_PENDING
} from "@assets/const/theme";
import {
  INSURANCE_STATUS_NONE,
  INSURANCE_STATUS_COMPLIANT,
  INSURANCE_STATUS_IN_PLACE,
  INSURANCE_STATUS_NON_COMPLIANT,
  INSURANCE_STATUS_NON_VERIFIED,
  STATUS_SECTION_APPROVE,
  DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER
} from "@assets/const/status";

import {
  getAutoScore,
  getCrimeScore,
  getGarageScore,
  getEpliScore,
  getCyberScore,
  getLiabilityScore,
  getUmbrellaScore,
  getWorkCompScore,
  handleGetStatus,
  getProLiabScore,
  getPolLiabScore,
  getIMScore,
  getProInsScore,
  isCoverageApproved
} from "@utils/build-logic";
import "./style.scss";

import { useHistory, useParams } from "react-router";
import IconRikorEmail from "@assets/images/rikor-logo.svg";
import Card from "@components/card";

export default function PageReport({ requirement, franchiseeList, onDetail }) {
  const history = useHistory();

  const [customerData, setCustomerData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [reportData, setReportData] = useState({
    issueCount: 0,
    totalFranchisees: 0,
    totalCompliant: 0,
    rikorCustomerCount: 0
  });
  const [graphData, setGraphData] = useState({
    liability: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    auto: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    umbrella: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    workComp: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    crime: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    garage: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    epli: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    cyber: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    proLiab: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    polLiab: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    im: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    proIns: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    }
  });

  useEffect(() => {
    if (requirement && franchiseeList) {
      handleGetFranchiseeInfor();
    }
  }, [requirement, franchiseeList]);

  function getStatusColor(status) {
    switch (status) {
      case INSURANCE_STATUS_COMPLIANT:
        return {
          fillColor: "#028701",
          backgroundColor: "#cfedd8",
          backgroundOpacity: 1,
          separation: true
        };
      case INSURANCE_STATUS_NON_COMPLIANT:
        return {
          fillColor: "#ff0000",
          backgroundColor: "#f9c6c2",
          backgroundOpacity: 1,
          separation: true
        };
      case INSURANCE_STATUS_NON_VERIFIED:
        return {
          fillColor: "#898c8d",
          backgroundColor: "#ededed",
          backgroundOpacity: 1,
          separation: true
        };
      case INSURANCE_STATUS_NONE:
        return {
          fillColor: "#ffbe00",
          backgroundColor: "#fef7e0",
          backgroundOpacity: 1,
          separation: true
        };
      case INSURANCE_STATUS_IN_PLACE:
        return {
          fillColor: "#028701",
          backgroundColor: "#cfedd8",
          backgroundOpacity: 1,
          separation: true
        };
      default:
        return {
          fillColor: "#990000",
          backgroundColor: "#f4cccc",
          backgroundOpacity: 1,
          separation: true
        };
    }
  }

  function handleGetFranchiseeInfor() {
    if (franchiseeList?.length > 0) {
      const resultCustomer = [],
        resultOther = [];
      let reportDataUpdate = {
        issueCount: 0,
        totalFranchisees: 0,
        totalCompliant: 0,
        rikorCustomerCount: 0
      };

      let graphDataUpdate = {
        liability: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        auto: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        umbrella: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        workComp: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        crime: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        garage: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        epli: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        cyber: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        proLiab: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        polLiab: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        im: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        proIns: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        }
      };
      for (const item of franchiseeList) {
        reportDataUpdate.totalFranchisees++;

        reportDataUpdate.issueCount =
          reportDataUpdate.issueCount + (item?.issueData || []).length;
        if (
          isCoverageApproved(
            item?.finalData?.commercial_general_liability_form_status,
            requirement?.commercial_general_liability_required
          ) &&
          isCoverageApproved(
            item?.finalData?.automotive_liability_form_status,
            requirement?.automotive_liability_required
          ) &&
          isCoverageApproved(
            item?.finalData?.umbrella_subrogration_form_status,
            requirement?.umbrella_subrogration_required
          ) &&
          isCoverageApproved(
            item?.finalData?.workers_compensation_form_status,
            requirement?.workers_compensation_required
          ) &&
          isCoverageApproved(
            item?.finalData?.crime_form_status,
            requirement?.crime_required
          ) &&
          isCoverageApproved(
            item?.finalData?.garage_form_status,
            requirement?.garage_required
          ) &&
          isCoverageApproved(
            item?.finalData?.epli_form_status,
            requirement?.epli_required
          ) &&
          isCoverageApproved(
            item?.finalData?.cyber_form_status,
            requirement?.cyber_required
          ) &&
          isCoverageApproved(
            item?.finalData?.pro_liab_form_status,
            requirement?.pro_liab_required
          ) &&
          isCoverageApproved(
            item?.finalData?.pol_liab_form_status,
            requirement?.pol_liab_required
          ) &&
          isCoverageApproved(
            item?.finalData?.im_form_status,
            requirement?.im_required
          ) &&
          isCoverageApproved(
            item?.finalData?.pro_ins_form_status,
            requirement?.pro_ins_required
          )
        ) {
          if (item?.issueData && item?.issueData.length === 0) {
            reportDataUpdate.totalCompliant++;
          }
        }

        const liabilityStatus = handleGetStatus(
          item?.finalData?.commercial_general_liability_form_status,
          item?.liabilityIssue,
          requirement?.commercial_general_liability_required
        );

        if (liabilityStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.liability.compliant++;
        }
        if (liabilityStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.liability.nonCompliant++;
        }

        if (liabilityStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.liability.unVerified++;
        }

        if (liabilityStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.liability.none++;
        }

        if (liabilityStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.liability.inPlace++;
        }

        const autoStatus = handleGetStatus(
          item?.finalData?.automotive_liability_form_status,
          item?.autoIssue,
          requirement?.automotive_liability_required
        );

        if (autoStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.auto.compliant++;
        }
        if (autoStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.auto.nonCompliant++;
        }

        if (autoStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.auto.unVerified++;
        }

        if (autoStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.auto.none++;
        }

        if (autoStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.auto.inPlace++;
        }

        const umbrellaStatus = handleGetStatus(
          item?.finalData?.umbrella_subrogration_form_status,
          item?.umbrellaIssue,
          requirement?.umbrella_subrogration_required
        );

        if (umbrellaStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.umbrella.compliant++;
        }
        if (umbrellaStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.umbrella.nonCompliant++;
        }

        if (umbrellaStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.umbrella.unVerified++;
        }

        if (umbrellaStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.umbrella.none++;
        }

        if (umbrellaStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.umbrella.inPlace++;
        }

        const workCompStatus = handleGetStatus(
          item?.finalData?.workers_compensation_form_status,
          item?.workCompIssue,
          requirement?.workers_compensation_required
        );

        if (workCompStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.workComp.compliant++;
        }
        if (workCompStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.workComp.nonCompliant++;
        }

        if (workCompStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.workComp.unVerified++;
        }

        if (workCompStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.workComp.none++;
        }

        if (workCompStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.workComp.inPlace++;
        }

        const crimeStatus = handleGetStatus(
          item?.finalData?.crime_form_status,
          item?.crimeIssue,
          requirement?.crime_required
        );

        if (crimeStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.crime.compliant++;
        }
        if (crimeStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.crime.nonCompliant++;
        }

        if (crimeStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.crime.unVerified++;
        }

        if (crimeStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.crime.none++;
        }

        if (crimeStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.crime.inPlace++;
        }

        const epliStatus = handleGetStatus(
          item?.finalData?.epli_form_status,
          item?.epliIssue,
          requirement?.epli_required
        );

        if (epliStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.epli.compliant++;
        }
        if (epliStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.epli.nonCompliant++;
        }

        if (epliStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.epli.unVerified++;
        }

        if (epliStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.epli.none++;
        }

        if (epliStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.epli.inPlace++;
        }

        const cyberStatus = handleGetStatus(
          item?.finalData?.cyber_form_status,
          item?.cyberIssue,
          requirement?.cyber_required
        );

        if (cyberStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.cyber.compliant++;
        }
        if (cyberStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.cyber.nonCompliant++;
        }

        if (cyberStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.cyber.unVerified++;
        }

        if (cyberStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.cyber.none++;
        }

        if (cyberStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.cyber.inPlace++;
        }

        const garageStatus = handleGetStatus(
          item?.finalData?.garage_form_status,
          item?.garageIssue,
          requirement?.garage_required
        );

        if (garageStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.garage.compliant++;
        }
        if (garageStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.garage.nonCompliant++;
        }

        if (garageStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.garage.unVerified++;
        }

        if (garageStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.garage.none++;
        }

        if (garageStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.garage.inPlace++;
        }

        const proLiabStatus = handleGetStatus(
          item?.finalData?.pro_liab_form_status,
          item?.proLiabIssue,
          requirement?.pro_liab_required
        );

        if (proLiabStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.proLiab.compliant++;
        }
        if (proLiabStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.proLiab.nonCompliant++;
        }

        if (proLiabStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.proLiab.unVerified++;
        }

        if (proLiabStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.proLiab.none++;
        }

        if (proLiabStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.proLiab.inPlace++;
        }

        const polLiabStatus = handleGetStatus(
          item?.finalData?.pol_liab_form_status,
          item?.polLiabIssue,
          requirement?.pol_liab_required
        );

        if (polLiabStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.polLiab.compliant++;
        }
        if (polLiabStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.polLiab.nonCompliant++;
        }

        if (polLiabStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.polLiab.unVerified++;
        }

        if (polLiabStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.polLiab.none++;
        }

        if (polLiabStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.polLiab.inPlace++;
        }

        const imStatus = handleGetStatus(
          item?.finalData?.im_form_status,
          item?.imIssue,
          requirement?.im_required
        );

        if (imStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.im.compliant++;
        }
        if (imStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.im.nonCompliant++;
        }

        if (imStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.im.unVerified++;
        }

        if (imStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.im.none++;
        }

        if (imStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.im.inPlace++;
        }

        const proInsStatus = handleGetStatus(
          item?.finalData?.pro_ins_form_status,
          item?.proInsIssue,
          requirement?.pro_ins_required
        );

        if (proInsStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.proIns.compliant++;
        }
        if (proInsStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.proIns.nonCompliant++;
        }

        if (proInsStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.proIns.unVerified++;
        }

        if (proInsStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.proIns.none++;
        }

        if (proInsStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.proIns.inPlace++;
        }

        try {
          const liabilityScore = getLiabilityScore(
            item.finalData || {},
            requirement
          );
          const autoScore = getAutoScore(item.finalData || {}, requirement);
          const workCompScore = getWorkCompScore(
            item.finalData || {},
            requirement
          );
          const umbrellaScore = getUmbrellaScore(
            item.finalData || {},
            requirement
          );
          const crimeScore = getCrimeScore(item.finalData || {}, requirement);
          const garageScore = getGarageScore(item.finalData || {}, requirement);
          const epliScore = getEpliScore(item.finalData || {}, requirement);
          const cyberScore = getCyberScore(item.finalData || {}, requirement);
          const proLiabScore = getProLiabScore(
            item.finalData || {},
            requirement
          );
          const polLiabScore = getPolLiabScore(
            item.finalData || {},
            requirement
          );
          const imScore = getIMScore(item.finalData || {}, requirement);
          const proInsScore = getProInsScore(item.finalData || {}, requirement);
          let coverageScore = 0,
            totalScore = 0;
          if (liabilityScore?.visible) {
            coverageScore = coverageScore + liabilityScore.score;
            totalScore = totalScore + liabilityScore.total;
          }
          if (autoScore?.visible) {
            coverageScore = coverageScore + autoScore.score;
            totalScore = totalScore + autoScore.total;
          }
          if (umbrellaScore.visible) {
            coverageScore = coverageScore + umbrellaScore.score;
            totalScore = totalScore + umbrellaScore.total;
          }
          if (workCompScore.visible) {
            coverageScore = coverageScore + workCompScore.score;
            totalScore = totalScore + workCompScore.total;
          }
          if (crimeScore.visible) {
            coverageScore = coverageScore + crimeScore.score;
            totalScore = totalScore + crimeScore.total;
          }
          if (garageScore.visible) {
            coverageScore = coverageScore + garageScore.score;
            totalScore = totalScore + garageScore.total;
          }

          if (epliScore.visible) {
            coverageScore = coverageScore + epliScore.score;
            totalScore = totalScore + epliScore.score;
          }

          if (cyberScore.visible) {
            coverageScore = coverageScore + cyberScore.score;
            totalScore = totalScore + cyberScore.score;
          }

          if (proLiabScore.visible) {
            coverageScore = coverageScore + proLiabScore.score;
            totalScore = totalScore + proLiabScore.score;
          }

          if (polLiabScore.visible) {
            coverageScore = coverageScore + polLiabScore.score;
            totalScore = totalScore + polLiabScore.score;
          }

          if (imScore.visible) {
            coverageScore = coverageScore + imScore.score;
            totalScore = totalScore + imScore.score;
          }
          if (proInsScore.visible) {
            coverageScore = coverageScore + proInsScore.score;
            totalScore = totalScore + proInsScore.score;
          }

          if (item?.doNotContact === DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER) {
            resultCustomer.push({
              id: item?._id,
              entityName:
                item?.entity_name && item?.entity_name !== ""
                  ? `${item.first_name} ${item.last_name} (${item?.entity_name})`
                  : `${item.first_name} ${item.last_name}`,
              email: item?.email || "",
              status: item?.status || "",
              firstName: item?.first_name || "",
              lastName: item?.last_name || "",
              liability: liabilityStatus,
              auto: autoStatus,
              umbrella: umbrellaStatus,
              workComp: workCompStatus,
              crime: crimeStatus,
              garage: garageStatus,
              epli: epliStatus,
              cyber: cyberStatus,
              proLiab: proLiabStatus,
              polLiab: polLiabStatus,
              im: imStatus,
              proIns: proInsStatus,
              liabilityScore: liabilityScore,
              autoScore: autoScore,
              workCompScore: workCompScore,
              umbrellaScore: umbrellaScore,
              crimeScore: crimeScore,
              garageScore: garageScore,
              epliScore: epliScore,
              cyberScore: cyberScore,
              proLiabScore: proLiabScore,
              polLiabScore: polLiabScore,
              imScore: imScore,
              proInsScore: proInsScore,
              score:
                totalScore === 0
                  ? 0
                  : Math.round((coverageScore * 100) / totalScore)
            });
            reportDataUpdate.rikorCustomerCount++;
          } else {
            resultOther.push({
              id: item?._id,
              entityName:
                item?.entity_name && item?.entity_name !== ""
                  ? `${item.first_name} ${item.last_name} (${item?.entity_name})`
                  : `${item.first_name} ${item.last_name}`,
              email: item?.email || "",
              status: item?.status || "",
              firstName: item?.first_name || "",
              lastName: item?.last_name || "",
              liability: liabilityStatus,
              auto: autoStatus,
              umbrella: umbrellaStatus,
              workComp: workCompStatus,
              crime: crimeStatus,
              garage: garageStatus,
              epli: epliStatus,
              cyber: cyberStatus,
              proLiab: proLiabStatus,
              polLiab: polLiabStatus,
              im: imStatus,
              proIns: proInsStatus,
              liabilityScore: liabilityScore,
              autoScore: autoScore,
              workCompScore: workCompScore,
              umbrellaScore: umbrellaScore,
              crimeScore: crimeScore,
              garageScore: garageScore,
              epliScore: epliScore,
              cyberScore: cyberScore,
              proLiabScore: proLiabScore,
              polLiabScore: polLiabScore,
              imScore: imScore,
              proInsScore: proInsScore,
              score:
                totalScore === 0
                  ? 0
                  : Math.round((coverageScore * 100) / totalScore)
            });
          }
        } catch (err) {
          console.log("getting error ---", err);
        }
      }
      setCustomerData(resultCustomer);
      setOtherData(resultOther);
      setReportData(reportDataUpdate);
      setGraphData(graphDataUpdate);
    }
  }

  const labels = [
    "Non-Compliant",
    "Compliant",
    "Document Missing",
    "In Place",
    "Not-Verified"
  ];

  const liabilityData = [
    {
      type: "Compliant",
      value: graphData?.liability?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.liability?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.liability?.none
    },
    {
      type: "In Place",
      value: graphData?.liability?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.liability?.unVerified
    }
  ];

  const autoData = [
    {
      type: "Compliant",
      value: graphData?.auto?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.auto?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.auto?.none
    },
    {
      type: "In Place",
      value: graphData?.auto?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.auto?.unVerified
    }
  ];

  const workCompData = [
    {
      type: "Compliant",
      value: graphData?.workComp?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.workComp?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.workComp?.none
    },
    {
      type: "In Place",
      value: graphData?.workComp?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.workComp?.unVerified
    }
  ];

  const umbrellaData = [
    {
      type: "Compliant",
      value: graphData?.umbrella?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.umbrella?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.umbrella?.none
    },
    {
      type: "In Place",
      value: graphData?.umbrella?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.umbrella?.unVerified
    }
  ];

  const crimeData = [
    {
      type: "Compliant",
      value: graphData?.crime?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.crime?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.crime?.none
    },
    {
      type: "In Place",
      value: graphData?.crime?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.crime?.unVerified
    }
  ];

  const garageData = [
    {
      type: "Compliant",
      value: graphData?.garage?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.garage?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.garage?.none
    },
    {
      type: "In Place",
      value: graphData?.garage?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.garage?.unVerified
    }
  ];

  const epliData = [
    {
      type: "Compliant",
      value: graphData?.epli?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.epli?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.epli?.none
    },
    {
      type: "In Place",
      value: graphData?.epli?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.epli?.unVerified
    }
  ];

  const cyberData = [
    {
      type: "Compliant",
      value: graphData?.cyber?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.cyber?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.cyber?.none
    },
    {
      type: "In Place",
      value: graphData?.cyber?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.cyber?.unVerified
    }
  ];

  const proLiabData = [
    {
      type: "Compliant",
      value: graphData?.proLiab?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.proLiab?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.proLiab?.none
    },
    {
      type: "In Place",
      value: graphData?.proLiab?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.proLiab?.unVerified
    }
  ];

  const polLiabData = [
    {
      type: "Compliant",
      value: graphData?.polLiab?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.polLiab?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.polLiab?.none
    },
    {
      type: "In Place",
      value: graphData?.polLiab?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.polLiab?.unVerified
    }
  ];

  const imData = [
    {
      type: "Compliant",
      value: graphData?.im?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.im?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.im?.none
    },
    {
      type: "In Place",
      value: graphData?.im?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.im?.unVerified
    }
  ];

  const proInsData = [
    {
      type: "Compliant",
      value: graphData?.proIns?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.proIns?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.proIns?.none
    },
    {
      type: "In Place",
      value: graphData?.proIns?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.proIns?.unVerified
    }
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: "Status Report"
      }
    }
  };

  const liabilitySectionVisible = useMemo(() => {
    if (requirement?.commercial_general_liability_required) {
      return true;
    }
    return false;
  }, [requirement]);

  const autoSectionVisible = useMemo(() => {
    if (requirement?.automotive_liability_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const workCompSectionVisible = useMemo(() => {
    if (requirement?.workers_compensation_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const umbrellaSectionVisible = useMemo(() => {
    if (requirement?.umbrella_subrogration_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const crimeSectionVisible = useMemo(() => {
    if (requirement?.crime_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const garageSectionVisible = useMemo(() => {
    if (requirement?.garage_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const epliSectionVisible = useMemo(() => {
    if (requirement?.epli_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const cyberSectionVisible = useMemo(() => {
    if (requirement?.cyber_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const proLiabSectionVisible = useMemo(() => {
    if (requirement?.pro_liab_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const polLiabSectionVisible = useMemo(() => {
    if (requirement?.pol_liab_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const imSectionVisible = useMemo(() => {
    if (requirement?.im_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const proInsSectionVisible = useMemo(() => {
    if (requirement?.pro_ins_required) {
      return true;
    }

    return false;
  }, [requirement]);

  const columns = [
    {
      title: "Franchisee",
      dataIndex: "entityName",
      key: "entityName"
    },
    // {
    //   title: "First Name",
    //   dataIndex: "firstName",
    //   key: "firstName",
    // },
    // {
    //   title: "Last Name",
    //   dataIndex: "lastName",
    //   key: "lastName",
    // },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },

    {
      title: "Liability",
      dataIndex: "liability",
      key: "liability",
      hidden: !liabilitySectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.liabilityScore?.visible &&
              record.liabilityScore?.total !== 0 && (
                <>
                  {Math.round(
                    (record.liabilityScore.score * 100) /
                      record.liabilityScore.total
                  )}{" "}
                  %
                </>
              )}
          </div>
        );
      }
    },
    {
      title: "Auto",
      dataIndex: "auto",
      key: "auto",
      hidden: !autoSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.autoScore?.visible && record.autoScore?.total !== 0 && (
              <>
                {Math.round(
                  (record.autoScore.score * 100) / record.autoScore.total
                )}{" "}
                %
              </>
            )}
          </div>
        );
      }
    },
    {
      title: "Work Comp",
      dataIndex: "workComp",
      key: "workComp",
      hidden: !workCompSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.workCompScore?.visible &&
              record.workCompScore?.total !== 0 && (
                <>
                  {Math.round(
                    (record.workCompScore.score * 100) /
                      record.workCompScore.total
                  )}{" "}
                  %
                </>
              )}
          </div>
        );
      }
    },
    {
      title: "Umbrella",
      dataIndex: "umbrella",
      key: "umbrella",
      hidden: !umbrellaSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.umbrellaScore?.visible &&
              record.umbrellaScore?.total !== 0 && (
                <>
                  {Math.round(
                    (record.umbrellaScore.score * 100) /
                      record.umbrellaScore.total
                  )}{" "}
                  %
                </>
              )}
          </div>
        );
      }
    },
    {
      title: "Crime",
      dataIndex: "crime",
      key: "crime",
      hidden: !crimeSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.crimeScore?.visible && record.crimeScore?.total !== 0 && (
              <>
                {Math.round(
                  (record.crimeScore.score * 100) / record.crimeScore.total
                )}{" "}
                %
              </>
            )}
          </div>
        );
      }
    },
    {
      title: "Garage",
      dataIndex: "garage",
      key: "garage",
      hidden: !garageSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.garageScore?.visible && record.garageScore?.total !== 0 && (
              <>
                {Math.round(
                  (record.garageScore.score * 100) / record.garageScore.total
                )}{" "}
                %
              </>
            )}
          </div>
        );
      }
    },
    {
      title: "Employment Practices Liability",
      dataIndex: "epli",
      key: "epli",
      hidden: !epliSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.epliScore?.visible && record.epliScore?.total !== 0 && (
              <>
                {Math.round(
                  (record.epliScore.score * 100) / record.epliScore.total
                )}{" "}
                %
              </>
            )}
          </div>
        );
      }
    },
    {
      title: "Cyber Liability",
      dataIndex: "cyber",
      key: "cyber",
      hidden: !cyberSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.cyberScore?.visible && record.cyberScore?.total !== 0 && (
              <>
                {Math.round(
                  (record.cyberScore.score * 100) / record.cyberScore.total
                )}{" "}
                %
              </>
            )}
          </div>
        );
      }
    },

    {
      title: "Professional Liability",
      dataIndex: "proLiab",
      key: "proLiab",
      hidden: !proLiabSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.proLiabScore?.visible &&
              record.proLiabScore?.total !== 0 && (
                <>
                  {Math.round(
                    (record.proLiabScore.score * 100) /
                      record.proLiabScore.total
                  )}{" "}
                  %
                </>
              )}
          </div>
        );
      }
    },

    {
      title: "Pollution Liability",
      dataIndex: "polLiab",
      key: "polLiab",
      hidden: !polLiabSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.polLiabScore?.visible &&
              record.polLiabScore?.total !== 0 && (
                <>
                  {Math.round(
                    (record.polLiabScore.score * 100) /
                      record.polLiabScore.total
                  )}{" "}
                  %
                </>
              )}
          </div>
        );
      }
    },
    {
      title: "Inland Marine Liability",
      dataIndex: "im",
      key: "im",
      hidden: !imSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.imScore?.visible && record.imScore?.total !== 0 && (
              <>
                {Math.round(
                  (record.imScore.score * 100) / record.imScore.total
                )}{" "}
                %
              </>
            )}
          </div>
        );
      }
    },
    {
      title: "Property Insurance",
      dataIndex: "proIns",
      key: "proIns",
      hidden: !proInsSectionVisible,
      render: (status, record) => {
        return (
          <div className={`status-item-${status}`}>
            {status}{" "}
            {record.proInsScore?.visible && record.proInsScore?.total !== 0 && (
              <>
                {Math.round(
                  (record.proInsScore.score * 100) / record.proInsScore.total
                )}{" "}
                %
              </>
            )}
          </div>
        );
      }
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: (val) => `${val} %`,
      sorter: (a, b) => {
        return Number(a.score) > Number(b.score) ? 1 : -1;
      }
    }
  ].filter((item) => !item?.hidden);

  const commonConfig = {
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 10
      }
    },
    color: [COLOR_NON_COMPLIANT, COLOR_COMPLIANT],
    interactions: [
      {
        type: "element-selected"
      },
      {
        type: "element-active"
      }
    ],
    legend: {
      position: "bottom"
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "overflow",
          textOverflow: "ellipsis"
        },
        content: ""
      }
    }
  };

  const liabilityConfig = {
    ...commonConfig,
    data: liabilityData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const autoConfig = {
    ...commonConfig,
    data: autoData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const workCompConfig = {
    ...commonConfig,
    data: workCompData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const umbrellaConfig = {
    ...commonConfig,
    data: umbrellaData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const crimeConfig = {
    ...commonConfig,
    data: crimeData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const garageConfig = {
    ...commonConfig,
    data: garageData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const epliConfig = {
    ...commonConfig,
    data: epliData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const cyberConfig = {
    ...commonConfig,
    data: cyberData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const proLiabConfig = {
    ...commonConfig,
    data: proLiabData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const polLiabConfig = {
    ...commonConfig,
    data: polLiabData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const imConfig = {
    ...commonConfig,
    data: imData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const proInsConfig = {
    ...commonConfig,
    data: proInsData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };
  return (
    <div className="report_con">
      <Card headerEnabled={false}>
        <div className="report-header">
          <img src={IconRikorEmail} className="report-logo" />
          <h3>Rikor Risk Report™</h3>
          <div className="report-detail">
            <div className="report-detail-item">
              <label>Document Prepared for:</label>
              <p>
                {requirement?.personal_infor_address}
                <br />
                {requirement?.personal_infor_city}{" "}
                {requirement?.personal_infor_state}{" "}
                {requirement?.personal_infor_zip}
              </p>
              <p>
                Franchisor Risk Profile:
                <br />
                {requirement?.personal_infor_business_name}
              </p>
            </div>

            <div className="report-detail-item">
              <label>Document Prepared by:</label>
              <p>
                Wade Millward
                <br />
                Rikor Insurance Consultancy
                <br />
                wade.millward@rikor.io
                <br />
              </p>
            </div>
          </div>
        </div>
      </Card>

      <Card title="SUMMARY REQUIREMENTS">
        <p>
          Rikor uses proprietary software and algorithms to analyze your
          franchisees’ policies to best protect your franchise brand from risks
          inherent to your industry. The following issues have been summarized
          here, but a detailed breakdown is included in this report.
        </p>
        <p>
          These non-compliant issues can be handled effectively through our
          digital brokerage which will give you real-time insight into the
          status of franchisee coverage.{" "}
        </p>

        <div className="chip-group-container">
          <div className="chip-item-container">
            <h3>Total Rikor “Broker of Record”</h3>
            <div className="item-report">{reportData.rikorCustomerCount}</div>
          </div>
          <div className="chip-item-container">
            <h3>Total Issues</h3>
            <div className="item-report">{reportData.issueCount}</div>
          </div>
          <div className="chip-item-container">
            <h3>Verified Compliant</h3>
            <div className="item-report">
              {reportData.totalCompliant}/{reportData.totalFranchisees}
            </div>
          </div>
        </div>
      </Card>

      <Card title="STATUS REPORT">
        <p>
          The status report has the{" "}
          <b>
            <i>Average Compliance Score</i>
          </b>{" "}
          (Average met requirements across all franchisees), the{" "}
          <b>
            <i>COI Verification Progress</i>
          </b>{" "}
          (Franchisees that have uploaded their COI VS those who have not), and
          the{" "}
          <b>
            <i>Coverage Status</i>
          </b>{" "}
          (break down of all franchisees that have proven coverage VS those who
          have not)
        </p>
        <div className="content-report">
          <div className="chart-wrapper">
            {liabilitySectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">Liability</div>
                <Pie {...liabilityConfig} />
              </div>
            )}
            {autoSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">Auto</div>
                <Pie {...autoConfig} />
              </div>
            )}
            {workCompSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">
                  Workers Compensation
                </div>
                <Pie {...workCompConfig} />
              </div>
            )}
            {umbrellaSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">Umbrella</div>
                <Pie {...umbrellaConfig} />
              </div>
            )}
            {crimeSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">Crime</div>
                <Pie {...crimeConfig} />
              </div>
            )}
            {garageSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">Garage</div>
                <Pie {...garageConfig} />
              </div>
            )}
            {epliSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">
                  Employment Practice Liability
                </div>
                <Pie {...epliConfig} />
              </div>
            )}
            {cyberSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">Cyber Liability</div>
                <Pie {...cyberConfig} />
              </div>
            )}
            {proLiabSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">
                  Professional Liability
                </div>
                <Pie {...proLiabConfig} />
              </div>
            )}
            {polLiabSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">
                  Pollution Liability
                </div>
                <Pie {...polLiabConfig} />
              </div>
            )}
            {imSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">Inland Marine</div>
                <Pie {...imConfig} />
              </div>
            )}
            {proInsSectionVisible && (
              <div className="chart-wrapper-item">
                <div className="chart-wrapper-item-title">
                  Property Insurance
                </div>
                <Pie {...proInsConfig} />
              </div>
            )}
          </div>
        </div>
      </Card>

      <Card title="HOW TO READ THE REPORT">
        <div className="report_con-read">
          <div className="report_con-read-item">
            <div className="status-item-Compliant status-item">
              Compliant 100%
            </div>
            <div className="report_con-read-item-desc">
              A green compliant status is coverage that meets all FDD
              requirements.
            </div>
          </div>

          <div className="report_con-read-item">
            <div className="status-item-Non-Compliant status-item">
              Non-Compliant 80%
            </div>
            <div className="report_con-read-item-desc">
              A red non-compliant status is coverage that doesn’t meet all FDD
              requirements.
            </div>
          </div>

          <div className="report_con-read-item">
            <div className="status-item-Not-Verified status-item">
              Not Verified 0%
            </div>
            <div className="report_con-read-item-desc">
              A grey not verified status is coverage that hasn’t been uploaded
              to the rms.
            </div>
          </div>
          <div className="report_con-read-item">
            <div className="status-item">90%</div>
            <div className="report_con-read-item-desc">
              The score is calculated based on the number of requirements met
              over the total requirements. The score on the right is the
              average.
            </div>
          </div>
        </div>
      </Card>

      <Card title="DETAILED REPORT">
        <h4>
          <b>Rikor Customers</b>
        </h4>
        <p>
          Rikor has produced this detailed report to indicate the field values
          that either expose the franchisee to risk or do not meet the insurance
          requirements of your brand. This analysis is not generic, these values
          are tailored to your unique risk profile.
        </p>

        <div className="report_con-desc">
          <div className="report_con-desc-title">Rikor Customers</div>
          <p>
            This table has consolidated all your franchisees who{" "}
            <b>
              <i>have one or more</i>
            </b>{" "}
            coverages through Rikor. Often consultants find that there is a
            coverage such as auto that is written by another agency.
          </p>
        </div>
        <Table
          columns={columns}
          dataSource={customerData}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                onDetail(record.id);
              }
            };
          }}
        />
        <h4>
          <b>Non-Rikor Customers</b>
        </h4>
        <p>
          Rikor has reached out to these Franchisees that have obtained coverage
          through other agents. These franchisees are under the same insurance
          obligations.
        </p>
        <div className="report_con-desc">
          <div className="report_con-desc-title">Non-Rikor Customers</div>
          <p>
            This table has consolidated all your franchisees who{" "}
            <b>
              <i>do not have any</i>
            </b>{" "}
            coverages through Rikor. These franchisees still need to upload
            there COI or update their insurance coverage to allow you to monitor
            risk throughout your entire brand.
          </p>
        </div>
        <Table
          columns={columns}
          dataSource={otherData}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                onDetail(record.id);
              }
            };
          }}
        />
      </Card>
    </div>
  );
}
