import React, { useEffect, useState } from "react";
import { Table, InputNumber, Input, Form, Button, Switch, Select } from "antd";
import "./style.scss";

export default function SectionTable({ initDataSource, tableLabel, onSave }) {
  const [dataSource, setDataSource] = useState(initDataSource);
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    setDataSource(initDataSource);
  }, [initDataSource]);

  const defaultColumns = [
    {
      title: "Coverage",
      dataIndex: "coverage",
      key: "coverage",
    },
    {
      title: "Current FDD",
      dataIndex: "current",
      key: "current",
      editable: true,
    },
    {
      title: "Suggested",
      dataIndex: "suggested",
      key: "suggested",
      editable: true,
    },
    {
      title: "Required",
      dataIndex: "required",
      key: "required",
      editable: true,
    },
    {
      title: "Quote",
      dataIndex: "quote",
      key: "quote",
      editable: true,
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  const handleChangeValue = (record, dataIndex, value) => {
    const index = dataSource.findIndex(
      (item) => item.coverage === record.coverage
    );
    dataSource[index][dataIndex] = value;
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }) => {
    let childNode = children;

    if (editable) {
      if (record.field_type === "String") {
        return (
          <td>
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              <Input
                type={record.response_limit === "Integer" ? "number" : "text"}
                defaultValue={record[dataIndex]}
                onChange={(evt) =>
                  handleChangeValue(record, dataIndex, evt.target.value)
                }
                size="large"
              />
            </div>
          </td>
        );
      }

      if (record.field_type === "Boolean") {
        return (
          <td>
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              <Switch
                defaultChecked={record[dataIndex]}
                onChange={(checked) =>
                  handleChangeValue(record, dataIndex, checked)
                }
              />
            </div>
          </td>
        );
      }

      if (record.field_type === "Enum") {
        return (
          <td>
            <div style={{ display: "flex", width: "100%", height: "100%" }}>
              <Select
                style={{ width: "100%" }}
                size="large"
                defaultValue={record[dataIndex]}
                options={record.options}
                onChange={(value) =>
                  handleChangeValue(record, dataIndex, value)
                }
              ></Select>
            </div>
          </td>
        );
      }
    }

    return <td {...restProps}>{childNode}</td>;
  };

  return (
    <div className="section_table">
      <div className="section_table-header">
        <div className="section_table-header-action">
          <label className="section_table-header-action-label">
            {tableLabel}
          </label>
          <div>
            <Switch
              checked={showTable}
              onClick={() => setShowTable(!showTable)}
            />
            <label className="section_table-header-action-span">
              I don't need this section
            </label>
          </div>
        </div>

        <div className="section_table-header-toolbar">
          <Button
            className="section_table-header-toolbar-select"
            size="large"
            type="primary"
            onClick={() => onSave(dataSource)}
          >
            Save
          </Button>
        </div>
      </div>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={dataSource}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
      />
    </div>
  );
}
