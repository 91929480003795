import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  Input,
  Loading,
  FormMask,
  Textarea,
  ScreenMask,
} from "../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../utils/axios";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  SOURCE_ONBOARDING,
} from "../../../assets/const/form";
import { useAppState } from "@context/index";

export default function InlandMarineForm(props) {
  const { data, btnLabel, source, onNext, readOnly = false } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;

  const [formVal, setFormVal] = useState({
    im_required: sourceData?.im_required || false,
    im_limit: sourceData?.im_limit || "",
    im_limit_per_item: sourceData?.im_limit_per_item || "",
    im_business_interuption: sourceData?.im_business_interuption || "",
    im_broker_note: sourceData?.im_broker_note || "",
  });

  useEffect(() => {
    setFormVal({
      im_required: sourceData?.im_required || false,
      im_limit: sourceData?.im_limit || "",
      im_limit_per_item: sourceData?.im_limit_per_item || "",
      im_business_interuption: sourceData?.im_business_interuption || "",
      im_broker_note: sourceData?.im_broker_note || "",
    });
  }, [sourceData]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleSubmit() {
    handleFormSubmit();
  }

  async function handleFormSubmit() {
    const { onUpdate } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="im-content">
      <div className="form-header">
        <div className="line-disable">
          <Switch
            checked={formVal.im_required}
            onChange={(checked) => handleChange("im_required", checked)}
          />
          <span className="disable-title">Required</span>
        </div>
      </div>
      <div className="form-main">
        <div className="panel-left">
          <div className="line-item-container broker-report-container">
            <Textarea
              title="Notes from broker"
              type="text"
              placeholder="Please enter your text here."
              value={formVal.im_broker_note}
              rows={10}
              onChange={(evt) =>
                handleChange("im_broker_note", evt.target.value)
              }
            />
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="LIMIT"
              type="number"
              placeholder="LIMIT"
              value={formVal.im_limit}
              onChange={(evt) => handleChange("im_limit", evt.target.value)}
            />
          </div>
          <div className="line-item-container">
            <Input
              title="LIMIT PER ITEM"
              type="number"
              placeholder="LIMIT PER ITEM"
              value={formVal.im_limit_per_item}
              onChange={(evt) =>
                handleChange("im_limit_per_item", evt.target.value)
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="BUSINESS INTERRUPTION"
              type="number"
              placeholder="BUSINESS INTERRUPTION"
              value={formVal.im_business_interuption}
              onChange={(evt) =>
                handleChange("im_business_interuption", evt.target.value)
              }
            />
          </div>
        </div>
        {!formVal.im_required && <FormMask />}
      </div>
      <div className="form-footer">
        {!readOnly && (
          <div className="button-container">
            <Button type="primary" onClick={handleSubmit}>
              {btnLabel || "Save"}
            </Button>
          </div>
        )}
      </div>
      <Loading loading={loading} />
      {readOnly && <ScreenMask />}
    </div>
  );
}
