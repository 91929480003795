import React, { useEffect, useMemo, useState } from "react";
import { Table, Input, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { post } from "@utils/axios";
import { useHistory } from "react-router-dom";
import { Card, Loading } from "@components";
import "./style.scss";
import {
  REQUIREMENT_STATUS_DASHBOARD,
  REQUIREMENT_STATUS_ONBOARD
} from "@assets/const/status";
import { getHostTag } from "@utils/host";
import { RIKOR_CUSTOMER_URL_PARAM } from "@assets/const/form";
const { Search } = Input;

export default function FranchisorReport(props) {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [franchisorList, setFranchisorList] = useState([]);
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    handleLoadFranchisor();
  }, []);

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  function handleNavDetail(id) {
    history.push({
      pathname: `report/${id}`
    });
  }

  async function handleLoadFranchisor() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/franchisor`,
        {
          host: getHostTag()
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.status === 200) {
        setFranchisorList([...result.data.requirements]);
      }
      setLoading(false);
    } catch (err) {
      handleNotification(
        "Failed",
        "Franchisor loading is failed. Please contact to admin directly"
      );
      setLoading(false);
    }
  }

  function renderStatus(status) {
    if (status) {
      switch (status.toLowerCase()) {
        case REQUIREMENT_STATUS_DASHBOARD:
          return <div className="status-container success">ACTIVATED</div>;
        case REQUIREMENT_STATUS_ONBOARD:
          return <div className="status-container info">PENDING</div>;
        default:
          return <div className="status-container success">PENDING</div>;
      }
    }
    return <div className="status-container info">PENDING</div>;
  }

  const columns = [
    {
      title: "Entity Name",
      dataIndex: "personal_infor_business_name",
      key: "personal_infor_business_name"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus,
      filters: [
        {
          text: "Activated",
          value: "dashboard"
        },
        {
          text: "Pending",
          value: "onboard"
        }
      ],
      onFilter: (value, record) => record.status === value
    }
  ];

  const dataSource = useMemo(() => {
    const listFranchisor = (franchisorList || [])
      .filter((item) => {
        if (
          JSON.stringify(item).toLowerCase().includes(searchStr.toLowerCase())
        ) {
          return true;
        }
        return false;
      })
      .map((item) => ({ ...item, key: item._id }));
    // listFranchisor.unshift({
    //   personal_infor_business_name: "Rikor Customers",
    //   status: "dashboard",
    //   key: RIKOR_CUSTOMER_URL_PARAM
    // });

    return listFranchisor;
  }, [franchisorList, searchStr]);

  return (
    <div className="admin_report">
      <Card title="Detailed Compliance Report">
        <div className="admin_report-action">
          <Search
            style={{ width: "400px" }}
            value={searchStr}
            onChange={(evt) => setSearchStr(evt.target.value)}
            placeholder="Franchisor name, email, or entity"
            enterButton="Search Franchisors"
          />
        </div>
        <div className="admin_report-content">
          <Table
            columns={columns}
            dataSource={dataSource}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleNavDetail(record.key);
                }
              };
            }}
          />
        </div>
        <Loading loading={loading} />
      </Card>
    </div>
  );
}
