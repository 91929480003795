import { useState, useEffect } from "react";
import "./style.scss";
import {
  Input,
  Loading,
  QuoteHeader,
  QuoteProgressBar,
  QuoteStepper
} from "../../../components";
import { useAppState } from "../../../context";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { get, post } from "../../../utils/axios";
import { Alert, Button, notification } from "antd";
import _ from "lodash";
import {
  INSURANCE_CARRIER_KEY_GA,
  NUM_STEPS_TO_QUOTE
} from "@assets/const/fim-integration";
import { useGreatAmerican } from "../admin-insurance-quote/hooks/useGreatAmerican";
import { extractAPIErrMsg } from "@utils/common";
import { COVERAGE_KEY_WORKERS_COMP } from "@assets/const/fim-integration";

export default function AdminPricing() {
  const history = useHistory();
  let { id, applicationId } = useParams();
  const { user, getAccessTokenSilently } = useAuth0();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataSourceActivity, setDataSourceActivity] = useState([]);
  const { franchisorAdmin } = useAppState();
  const [submitData, setSubmitData] = useState({});
  const requirement = franchisorAdmin?.requirement;
  const [pricingData, setPricingData] = useState();
  const [errors, setErrors] = useState([]);

  const { saveQuote } = useGreatAmerican({ franchiseeData });

  useEffect(() => {
    if (id) {
      handleLoadFranchisee();
    }
  }, []);

  async function handleLoadFranchisee() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFranchiseeData(result.data.data);
      setDataSourceActivity((result.data.data.activities || []).reverse());

      const resultPricing = await post(
        `gai/pricing`,
        {
          newBusiness: {
            id: applicationId
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setPricingData(resultPricing?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errMessages = error.response?.data?.message || [];
      setErrors(errMessages.map((msg) => `[${msg.category}] ${msg.message}`));
      console.log("error", error.response);
    }
  }

  function handleBack() {
    history.push(`/admin/eligibility/${id}/${applicationId}`);
  }
  async function handleNext() {
    history.push(`/admin/franchisees/${id}`);
  }

  function handleClose() {
    history.push("/admin/overview");
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const resultSubmit = await post(
        `gai/submit`,
        {
          newBusiness: {
            id: applicationId
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      setSubmitData(resultSubmit?.data?.data);
      setLoading(false);
      notification.success({
        message: "Success",
        description: "Application is successfully submitted!",
        duration: 5
      });
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Failed",
        description: "Application submission failed",
        duration: 5
      });
    }
  }

  async function handleDocuments() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      post(
        `gai/documents`,
        {
          newBusiness: {
            id: applicationId
          },
          documents: ["quoteLetter"]
        },
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/pdf"
          }
        }
      )
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const a = document.createElement("a");
          a.href = url;
          a.download = "document.pdf";
          a.click();
          window.URL.revokeObjectURL(url);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error ---", error.response);
          notification.error({
            message: "Error",
            description: `Failed to download document - ${
              extractAPIErrMsg(error) || "server error."
            }`,
            duration: 5
          });
          setLoading(false);
        });
      // setSubmitData(resultSubmit?.data?.data);
    } catch (error) {
      setLoading(false);
    }
  }

  async function handleSave() {
    try {
      setLoading(true);
      await saveQuote(applicationId, {
        [COVERAGE_KEY_WORKERS_COMP]: pricingData
      });
      notification.success({
        message: "Success",
        description: "Successfully saved quote."
      });
    } catch (error) {
      console.log("error", error);
      notification.error({
        message: "Error",
        description: `Failed to save quote - ${
          extractAPIErrMsg(error) || "server error."
        }`,
        duration: 5
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="admin_pricing">
      <QuoteProgressBar
        step={3}
        total={NUM_STEPS_TO_QUOTE[INSURANCE_CARRIER_KEY_GA]}
      ></QuoteProgressBar>
      <QuoteHeader
        franchiseeData={franchiseeData}
        requirement={requirement}
        user={user}
      ></QuoteHeader>
      <div className="pricing_body">
        <QuoteStepper
          step={3}
          total={NUM_STEPS_TO_QUOTE[INSURANCE_CARRIER_KEY_GA]}
        ></QuoteStepper>
        {!_.isEmpty(errors) && (
          <>
            <Alert
              message={
                <>
                  <i>
                    We encountered errors. If you think this is a mistake,
                    please contact us with the errors listed below. We'll attend
                    to them as soon as possible.
                  </i>
                  <ul>
                    {errors.map((err) => (
                      <li>{err}</li>
                    ))}
                  </ul>
                </>
              }
              type="error"
            />
            <Button className="back-button-on-error" onClick={handleBack}>
              Back
            </Button>
          </>
        )}
        {_.isEmpty(errors) && (
          <div className="right-content">
            <div className="workers-compensation-box">
              <div className="item-card">
                <div className="card-header">
                  <h1>Pricing</h1>
                </div>
                <div className="card-content">
                  <div className="form-group-three-container">
                    <div className="line-item-container">
                      <Input
                        title="Application Id"
                        type="text"
                        value={pricingData?.newBusiness.id}
                        disabled
                      />
                    </div>
                    <div className="line-item-container">
                      <Input
                        title="Policy Id"
                        type="text"
                        value={pricingData?.rating.data.policy.id}
                        disabled
                      />
                    </div>
                    <div className="line-item-container">
                      <Input
                        title="Status"
                        type="text"
                        value={pricingData?.newBusiness.status}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group-container">
                    <div className="line-item-container">
                      <Input
                        title="Pricing Type"
                        type="text"
                        value={pricingData?.newBusiness.pricingType}
                        disabled
                      />
                    </div>
                    <div className="line-item-container">
                      <Input
                        title="Taxes and Surcharges"
                        type="text"
                        value={
                          pricingData?.rating.data
                            .TotalTaxesAndSurchargesAPRPWithoutClaimHandlingFee
                        }
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group-container">
                    <div className="line-item-container">
                      <Input
                        title="Price Without Taxes and Surcharges"
                        type="text"
                        value={
                          pricingData?.rating.data
                            .TotalAPRPWithoutTaxesAndSurcharges
                        }
                        disabled
                      />
                    </div>
                    <div className="line-item-container">
                      <Input
                        title="Price"
                        type="text"
                        value={pricingData?.rating.data.TotalAPRP}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="form-group-container">
                    <div className="line-item-container">
                      <Input
                        title="Currency Code"
                        type="text"
                        value={pricingData?.rating.properties.currencyCode}
                        disabled
                      />
                    </div>
                    <div className="line-item-container">
                      <Input
                        title="Consumer"
                        type="text"
                        value={pricingData?.rating.data.consumerName}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-content-footer">
              <Button
                className="right-content-footer-btn"
                onClick={handleDocuments}
              >
                Documents
              </Button>
              <Button
                className="right-content-footer-btn"
                onClick={handleSubmit}
              >
                Bind
              </Button>
              <Button className="right-content-footer-btn" onClick={handleBack}>
                Back
              </Button>
              <Button className="right-content-footer-btn" onClick={handleSave}>
                Save
              </Button>
              <Button
                className="right-content-footer-btn"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </div>
        )}
      </div>

      <Loading loading={loading} />
    </div>
  );
}
