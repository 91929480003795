import _ from "lodash";

// Convert 1h, 1s, 1m, 1d format into millis.
export function convertTimeStrToMilli(timeStr) {
  const prefix = timeStr.substring(0, timeStr.length - 1);
  const suffix = timeStr[timeStr.length - 1];
  const allowedSuffix = new Set(["d", "h", "m", "s"]);

  if (!allowedSuffix.has(suffix)) {
    throw new Error(
      "suffix duration must be one of the following: d, h, m, s."
    );
  }
  if (_.isNumber(prefix)) {
    throw new Error("prefix must be a number.");
  }

  switch (suffix) {
    case "d":
      return prefix * 24 * 60 * 60 * 1000;
    case "h":
      return prefix * 60 * 60 * 1000;
    case "m":
      return prefix * 60 * 1000;
    case "s":
      return prefix * 1000;
    default:
      throw new Error(`suffix ${suffix} not allowed.`);
  }
}
