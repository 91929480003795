import React, { useEffect, useState } from "react";
import { Loading } from "@components";
import { Select, Switch } from "antd";
import "./style.scss";
import {
  DOCUMENT_STATUS_ARCHIVED,
  DOCUMENT_STATUS_REJECT
} from "@assets/const/status";
import {
  APP_DATE_FORMAT_DATEFNS,
  TITLE_PROP_INSURANCE,
  TITLE_COI,
  TITLE_COPI
} from "@assets/const/form";
import { WarningOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { format } from "date-fns";
import { deleteAPI, put } from "@utils/axios";

const { Option } = Select;

export default function CollapseWrapper(props) {
  const {
    children,
    title,
    recogData,
    fileUpload,
    docItemIndex,
    updateIndex,
    contactKey,
    contactFlag,
    franchiseeID,
    firstName,
    lastName
  } = props;

  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [contact, setContact] = useState(contactFlag);

  useEffect(() => {
    setContact(contactFlag);
  }, [contactFlag]);

  function handleChange(newValue) {
    updateIndex(newValue);
  }

  async function handleContact(checked) {
    if (franchiseeID) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const result = await put(
          `contact/${franchiseeID}`,
          {
            [contactKey]: checked
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        await deleteAPI(`notification/delete/${franchiseeID}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setLoading(false);
        setContact(checked);
      } catch (error) {
        setLoading(false);
      }
    }
  }

  return (
    <div className="collapse-section-container">
      <div className="collapse-section-container-header">
        <h3>{title}</h3>
        <div className="collapse-section-container-header-right">
          <div className="collapse-section-container-header-right-contact">
            <Switch checked={contact} onChange={handleContact} />
            <span>Contact ASAP</span>
          </div>
          {contact && (
            <WarningOutlined className="collapse-section-container-header-right-alert" />
          )}
          <Select
            defaultValue={"Please Select a Option"}
            style={{ width: 420 }}
            size="large"
            value={docItemIndex < 0 ? "Please Select a Option" : docItemIndex}
            onChange={handleChange}
          >
            {recogData &&
              fileUpload
                .slice()
                .reverse()
                .map((fileUploadItem, reverseIndex) => {
                    const index = fileUpload.length - 1 - reverseIndex;

                    const disabled =
                      fileUploadItem.status === DOCUMENT_STATUS_ARCHIVED ||
                      fileUploadItem.status === DOCUMENT_STATUS_REJECT
                        ? true
                        : false;

                    const fileType = fileUploadItem.docType !== TITLE_COPI && fileUploadItem.docType !== TITLE_COI ? "Other" : fileUploadItem.docType;

                    const docTitle = `${lastName}-${firstName}-COI-${format(new Date(fileUploadItem.upload_at), APP_DATE_FORMAT_DATEFNS)}`;

                    return (
                      <Option value={index} disabled={disabled}>
                        {docTitle} ({fileType})
                      </Option>
                    );
                })}
          </Select>
        </div>
      </div>
      {children}
      <Loading loading={loading} />
    </div>
  );
}
