import React from "react";
import "./style.scss";
import { Header, Sidebar } from "../../components";
import Overview from "./overview";
import Franchisees from "./franchisees";
import Documents from "./documents";
import Settings from "./settings";
import Report from "./report";
import { Switch, Route } from "react-router-dom";
import DashboardNotification from "./dashboard-notification";
import FranchiseeDetail from "./franchisee-detail";
import { Layout } from "antd";

const { Content } = Layout;

export default function Dashboard() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar screen="dashboard" />
      <Layout>
        <Header screen="dashboard" />
        <Content>
          <Switch>
            <Route path="/dashboard/overview" component={Overview} />
            <Route
              path="/dashboard/franchisees/:id"
              component={FranchiseeDetail}
            />
            <Route path="/dashboard/franchisees" component={Franchisees} />
            <Route path="/dashboard/documents" component={Documents} />
            <Route path="/dashboard/settings" component={Settings} />
            <Route path="/dashboard/report" component={Report} />
            <Route
              path="/dashboard/notification"
              component={DashboardNotification}
            />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}
