import {
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  FORM_POLICY,
  FORM_PROJECT,
  FORM_LOC,
  FORM_UMBRELLA_LIABILITY,
  FORM_EXCESS_LIABILITY,
  FORM_DEDUCTIBLE,
  FORM_RETENTION,
  FORM_STRING_SIMILARITY,
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
  FORM_INCLUDED,
  FORM_EXCLUDED,
  FORM_LOCATION_SIMILARITY,
  FORM_TYPE_SPECIAL,
  FORM_TYPE_BROAD,
  FORM_TYPE_BASIC,
  FORM_LOSS_RECOVERY_TYPE_COST,
  FORM_LOSS_RECOVERY_TYPE_CASH
} from "../assets/const/form";
import { compareTwoStrings } from "string-similarity";
import moment from "moment";
import {
  STATUS_SECTION_APPROVE,
  INSURANCE_STATUS_IN_PLACE,
  INSURANCE_STATUS_NONE,
  INSURANCE_STATUS_COMPLIANT,
  INSURANCE_STATUS_NON_COMPLIANT,
  INSURANCE_STATUS_NON_VERIFIED,
  INSURANCE_STATUS_PENDING
} from "@assets/const/status";
import { isEmpty } from "lodash";
import { formatDollarAmount } from "@utils/dollar-formatter";

function buildGeneralPolicy(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (value === FORM_OCCURRENCE) {
    return [];
  }

  if (value === FORM_CLAIMS_MADE) {
    if (defaultValue === FORM_OCCURRENCE) {
      return [
        {
          type: "",
          title: "Occurence box must be selected",
          date: ""
        }
      ];
    }
  }

  if (value !== FORM_OCCURRENCE && value !== FORM_CLAIMS_MADE) {
    if (defaultValue === FORM_OCCURRENCE) {
      return [
        {
          type: "",
          title: "Occurence box must be selected",
          date: ""
        }
      ];
    } else {
      return [
        {
          type: "",
          title: "Claims-made or occurrence must be selected",
          date: ""
        }
      ];
    }
  }
  return [];
}

function buildPollutionType(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (value === FORM_OCCURRENCE) {
    return [];
  }

  if (value === FORM_CLAIMS_MADE) {
    if (defaultValue === FORM_OCCURRENCE) {
      return [
        {
          type: "",
          title: "Occurence box must be selected",
          date: ""
        }
      ];
    }
  }

  return [];
}

function buildFormType(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  switch (defaultValue) {
    case FORM_TYPE_SPECIAL:
      if (value !== FORM_TYPE_SPECIAL) {
        return [
          {
            type: "",
            title: "Special must be selected",
            date: ""
          }
        ];
      }
      break;
    case FORM_TYPE_BROAD:
      if (value === FORM_TYPE_SPECIAL || value === FORM_TYPE_BROAD) {
        return [];
      }

      return [
        {
          type: "",
          title: "Special or Broad must be selected",
          date: ""
        }
      ];
    case FORM_TYPE_BASIC:
      if (
        value === FORM_TYPE_SPECIAL ||
        value === FORM_TYPE_BROAD ||
        value === FORM_TYPE_BASIC
      ) {
        return [];
      }

      return [
        {
          type: "",
          title: "Special or Broad or Basic must be selected",
          date: ""
        }
      ];
    default:
      return [];
  }
  return [];
}

function buildRecoveryType(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  switch (defaultValue) {
    case FORM_LOSS_RECOVERY_TYPE_COST:
      if (value !== FORM_LOSS_RECOVERY_TYPE_COST) {
        return [
          {
            type: "",
            title: "Replacement Cost must be selected",
            date: ""
          }
        ];
      }
      break;
    case FORM_LOSS_RECOVERY_TYPE_CASH:
      if (
        value === FORM_LOSS_RECOVERY_TYPE_COST ||
        value === FORM_LOSS_RECOVERY_TYPE_CASH
      ) {
        return [];
      }

      return [
        {
          type: "",
          title: "Replacement Cost or Actual Cache Value must be selected",
          date: ""
        }
      ];
    default:
      return [];
  }
  return [];
}

function buildGeneralAggregateLimit(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  switch (defaultValue) {
    case FORM_LOC:
      if (value !== FORM_LOC) {
        return [
          {
            type: "",
            title: "Location must be selected",
            date: ""
          }
        ];
      }
      break;
    case FORM_PROJECT:
      if (value !== FORM_PROJECT) {
        return [
          {
            type: "",
            title: "Project must be selected",
            date: ""
          }
        ];
      }
      break;
    case FORM_POLICY:
      if (value === FORM_LOC) return [];
      if (value === FORM_PROJECT) return [];
      if (value === FORM_POLICY) return [];
      return [
        {
          type: "",
          title: "Policy or Project or Location must be selected",
          date: ""
        }
      ];
    default:
      return [];
  }
  return [];
}

function buildGeneralLiabilityAdditionalInsurance(
  value,
  defaultValue,
  disable = false
) {
  if (!disable) {
    return [];
  }

  if (defaultValue && !value) {
    return [
      {
        type: "",
        title: "Additional Insured must be selected",
        date: ""
      }
    ];
  }

  return [];
}

function buildGeneralLiabilitySubrogrationWaved(
  value,
  defaultValue,
  disable = false
) {
  if (!disable) {
    return [];
  }

  if (defaultValue && !value) {
    return [
      {
        type: "",
        title: "Waiver of Subrogation must be selected",
        date: ""
      }
    ];
  }

  return [];
}

function buildEffectDate(date, disable = false) {
  if (!disable) {
    return [];
  }

  //needed if data passed in is undefined - moment defaults undefined data to today's date
  if (!date || !moment(date).isValid()) {
    return [
      {
        type: "",
        title: "Date required",
        date: "",
      },
    ];
  }

  if (moment(date).isAfter(moment())) {
    return [
      {
        type: "",
        title: "Must be effective before today's date",
        date: "",
      },
    ];
  }

  return [];
}

function buildExpireDate(date, disable = false) {
  if (!disable) {
    return [];
  }

  //needed if data passed in is undefined - moment defaults undefined data to today's date
  if (!date || !moment(date).isValid()) {
    return [
      {
        type: "",
        title: "Date required",
        date: "",
      },
    ];
  }

  if (moment(date).isBefore(moment())) {
    return [
      {
        type: "",
        title: "Must expire after today's date",
        date: "",
      },
    ];
  }

  if (moment(date).isSameOrBefore(moment().add(15, "days"))) {
    return [
      {
        type: "",
        title: "Expiring in 15 days or less",
        date: "",
      },
    ];
  }

  return [];
}


function buildValueGT(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (Number(value || 0) < Number(defaultValue)) {
    return [
      {
        type: "",
        title: `Must be equal to or greater than ${formatDollarAmount(defaultValue)}`,
        date: ""
      }
    ];
  }

  return [];
}

function buildValueLT(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (Number(value) > Number(defaultValue)) {
    return [
      {
        type: "",
        title: `Must be equal to or less than ${formatDollarAmount(defaultValue)}`,
        date: ""
      }
    ];
  }

  return [];
}

function buildAnyAuto(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (defaultValue && !value) {
    return [
      {
        type: "",
        title: `Any Auto must be selected`,
        date: ""
      }
    ];
  }

  return [];
}

function buildAllOwnedAuto(value, defaultValue, staticValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (defaultValue && !value && !staticValue) {
    return [
      {
        type: "",
        title: `All Owned Auto or Any Auto must be selected`,
        date: ""
      }
    ];
  }
  return [];
}

function buildHiredAuto(value, defaultValue, staticValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (defaultValue && !value && !staticValue) {
    return [
      {
        type: "",
        title: `Hired Auto or Any Auto or must be selected`,
        date: ""
      }
    ];
  }
  return [];
}

function buildScheduledAuto(value, defaultValue, staticValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (defaultValue && !value && !staticValue) {
    return [
      {
        type: "",
        title: `Scheduled Auto or Any Auto or All Owned Auto must be selected`,
        date: ""
      }
    ];
  }
  return [];
}

function buildNonOwnedAuto(value, defaultValue, staticValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (defaultValue && !value && !staticValue) {
    return [
      {
        type: "",
        title: `Non-owned Auto or Any Auto must be selected`,
        date: ""
      }
    ];
  }
  return [];
}

function buildUmbrellaSubrogrationLiability(
  value,
  defaultValue,
  disable = false
) {
  if (!disable) {
    return [];
  }

  if (defaultValue === FORM_UMBRELLA_LIABILITY) {
    if (value !== FORM_UMBRELLA_LIABILITY) {
      return [
        {
          type: "",
          title: `Umbrella must be selected`,
          date: ""
        }
      ];
    }

    return [];
  }

  if (defaultValue === FORM_EXCESS_LIABILITY) {
    if (value !== FORM_UMBRELLA_LIABILITY && value !== FORM_EXCESS_LIABILITY) {
      return [
        {
          type: "",
          title: `Excess or Umbrella must be selected`,
          date: ""
        }
      ];
    }
  }

  return [];
}

function buildUmbrellaSubrogrationActivity(
  value,
  defaultValue,
  disable = false
) {
  if (!disable) {
    return [];
  }

  if (defaultValue === FORM_OCCURRENCE) {
    if (value !== FORM_OCCURRENCE) {
      return [
        {
          type: "",
          title: `Occurrence must be selected`,
          date: ""
        }
      ];
    }

    return [];
  }

  if (defaultValue === FORM_CLAIMS_MADE) {
    if (value !== FORM_OCCURRENCE && value !== FORM_CLAIMS_MADE) {
      return [
        {
          type: "",
          title: `Claims-made or occurrence must be selected`,
          date: ""
        }
      ];
    }
  }

  return [];
}

function buildUmbrellaSubrogrationStatus(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (defaultValue === FORM_DEDUCTIBLE) {
    if (value !== FORM_DEDUCTIBLE) {
      return [
        {
          type: "",
          title: `Deductible must be selected`,
          date: ""
        }
      ];
    }

    return [];
  }

  if (defaultValue === FORM_RETENTION) {
    if (value !== FORM_RETENTION) {
      return [
        {
          type: "",
          title: `Retention must be selected`,
          date: ""
        }
      ];
    }

    return [];
  }

  return [];
}

function buildAdditionalOccurenceValue(value, defaultValue) {
  if (
    !defaultValue?.commercial_general_liability_required ||
    !defaultValue?.umbrella_subrogration_required
  ) {
    return [];
  }

  const result = [];
  if (
    Number(value.commercial_general_liability_each_occurance) +
      Number(value.umbrella_subrogration_each_occurance) <
    Number(defaultValue.commercial_general_liability_each_occurance) +
      Number(defaultValue.umbrella_subrogration_each_occurance)
  ) {
    result.push({
      type: "",
      title: `Liability Occurrence and Umbrella Occurrence must be equal to or greater than (${defaultValue.commercial_general_liability_each_occurance} + ${defaultValue.umbrella_subrogration_each_occurance} Requirements)`,
      date: ""
    });
  }

  return result;
}

function buildAdditionalAggregateValue(value, defaultValue) {
  if (
    !defaultValue?.commercial_general_liability_required ||
    !defaultValue?.umbrella_subrogration_required
  ) {
    return [];
  }

  const result = [];

  if (
    Number(value.commercial_general_liability_general_aggregate) +
      Number(value.umbrella_subrogration_aggregate) <
    Number(defaultValue.commercial_general_liability_general_aggregate) +
      Number(defaultValue.umbrella_subrogration_aggregate)
  ) {
    result.push({
      type: "",
      title: `Liability Aggregate and Umbrella Aggregate must be equal to or greater than (${defaultValue.commercial_general_liability_general_aggregate} + ${defaultValue.umbrella_subrogration_aggregate} Requirements)`,
      date: ""
    });
  }

  return result;
}

function buildAdditionalPersonalValue(value, defaultValue) {
  if (
    !defaultValue?.commercial_general_liability_required ||
    !defaultValue?.umbrella_subrogration_required
  ) {
    return [];
  }

  const result = [];

  if (
    Number(value.commercial_general_liability_personal_adv_injury) +
      Number(value.umbrella_subrogration_each_occurance) <
    Number(defaultValue.commercial_general_liability_personal_adv_injury) +
      Number(defaultValue.umbrella_subrogration_each_occurance)
  ) {
    result.push({
      type: "",
      title: `Personal & Adv must be equal to or greater than (${defaultValue.commercial_general_liability_personal_adv_injury} + ${defaultValue.umbrella_subrogration_each_occurance} Requirements)`,
      date: ""
    });
  }

  return result;
}

function buildAdditionalProductValue(value, defaultValue) {
  if (
    !defaultValue?.commercial_general_liability_required ||
    !defaultValue?.umbrella_subrogration_required
  ) {
    return [];
  }

  const result = [];

  if (
    Number(value.commercial_general_liability_comprop_acg) +
      Number(value.umbrella_subrogration_aggregate) <
    Number(defaultValue.commercial_general_liability_comprop_acg) +
      Number(defaultValue.umbrella_subrogration_aggregate)
  ) {
    result.push({
      type: "",
      title: `Personal & Adv must be equal to or greater than (${defaultValue.commercial_general_liability_comprop_acg} + ${defaultValue.umbrella_subrogration_aggregate} Requirements)`,
      date: ""
    });
  }

  return result;
}

function buildAdditionalAutoCombinedValue(value, defaultValue) {
  if (
    !defaultValue?.umbrella_subrogration_required ||
    !defaultValue?.automotive_liability_required
  ) {
    return [];
  }

  const result = [];
  if (
    Number(value.automotive_liability_combined_single_limit) <
    Number(defaultValue.automotive_liability_combined_single_limit) +
      Number(defaultValue.umbrella_subrogration_each_occurance)
  ) {
    result.push({
      type: "",
      title: `Auto Combined Single Limit must be equal to or greater than (${defaultValue.automotive_liability_combined_single_limit} + ${defaultValue.umbrella_subrogration_each_occurance} Requirements)`,
      date: ""
    });
  }

  if (
    Number(value.automotive_liability_combined_single_limit) +
      Number(value.umbrella_subrogration_each_occurance) <
    Number(defaultValue.automotive_liability_combined_single_limit) +
      Number(defaultValue.umbrella_subrogration_each_occurance)
  ) {
    result.push({
      type: "",
      title: `Auto Combined Single Limit and Umbrella Occurrence must be equal to or greater than (${defaultValue.automotive_liability_combined_single_limit} + ${defaultValue.umbrella_subrogration_each_occurance} Requirements)`,
      date: ""
    });
  }

  return result;
}

function buildAdditionalCompAccidentValue(value, defaultValue) {
  if (
    !defaultValue?.umbrella_subrogration_required ||
    !defaultValue?.workers_compensation_required
  ) {
    return [];
  }

  const result = [];
  if (
    Number(value.workers_compensation_el_each_accident) <
    Number(defaultValue.workers_compensation_el_each_accident) +
      Number(defaultValue.umbrella_subrogration_each_occurance)
  ) {
    result.push({
      type: "",
      title: `Accident must be equal to or greater than (${defaultValue.workers_compensation_el_each_accident} + ${defaultValue.umbrella_subrogration_each_occurance} Requirements)`,
      date: ""
    });
  }

  if (
    Number(value.workers_compensation_el_each_accident) +
      Number(value.umbrella_subrogration_each_occurance) <
    Number(defaultValue.workers_compensation_el_each_accident) +
      Number(defaultValue.umbrella_subrogration_each_occurance)
  ) {
    result.push({
      type: "",
      title: `Accident and Umbrella Occurrence must be equal to or greater than (${defaultValue.workers_compensation_el_each_accident} + ${defaultValue.umbrella_subrogration_each_occurance} Requirements)`,
      date: ""
    });
  }

  return result;
}

function buildAdditionalCompEmployeeValue(value, defaultValue) {
  if (
    !defaultValue?.umbrella_subrogration_required ||
    !defaultValue?.workers_compensation_required
  ) {
    return [];
  }

  const result = [];
  if (
    Number(value.workers_compensation_other_el_disease_each_employer) <
    Number(defaultValue.workers_compensation_other_el_disease_each_employer) +
      Number(defaultValue.umbrella_subrogration_each_occurance)
  ) {
    result.push({
      type: "",
      title: `Employee must be equal to or greater than (${defaultValue.workers_compensation_other_el_disease_each_employer} + ${defaultValue.umbrella_subrogration_each_occurance} Requirements)`,
      date: ""
    });
  }

  if (
    Number(value.workers_compensation_other_el_disease_each_employer) +
      Number(value.umbrella_subrogration_each_occurance) <
    Number(defaultValue.workers_compensation_other_el_disease_each_employer) +
      Number(defaultValue.umbrella_subrogration_each_occurance)
  ) {
    result.push({
      type: "",
      title: `Employee and Umbrella Occurrence must be equal to or greater than (${defaultValue.workers_compensation_other_el_disease_each_employer} + ${defaultValue.umbrella_subrogration_each_occurance} Requirements)`,
      date: ""
    });
  }

  return result;
}

function buildAdditionalCompLimitValue(value, defaultValue) {
  if (
    !defaultValue?.umbrella_subrogration_required ||
    !defaultValue?.workers_compensation_required
  ) {
    return [];
  }

  const result = [];
  if (
    Number(value.workers_compensation_el_disease_policy_limit) <
    Number(defaultValue.workers_compensation_el_disease_policy_limit) +
      Number(defaultValue.umbrella_subrogration_each_occurance)
  ) {
    result.push({
      type: "",
      title: `Limit must be equal to or greater than (${defaultValue.workers_compensation_el_disease_policy_limit} + ${defaultValue.umbrella_subrogration_each_occurance} Requirements)`,
      date: ""
    });
  }

  if (
    Number(value.workers_compensation_el_disease_policy_limit) +
      Number(value.umbrella_subrogration_each_occurance) <
    Number(defaultValue.workers_compensation_el_disease_policy_limit) +
      Number(defaultValue.umbrella_subrogration_each_occurance)
  ) {
    result.push({
      type: "",
      title: `Limit and Umbrella Occurrence must be equal to or greater than (${defaultValue.workers_compensation_el_disease_policy_limit} + ${defaultValue.umbrella_subrogration_each_occurance} Requirements)`,
      date: ""
    });
  }

  return result;
}

function getLiabilityTotal(requirementData) {
  let count = 0;

  if (!requirementData?.commercial_general_liability_required) {
    return -1;
  }

  if (requirementData?.commercial_general_liability_each_occurance > 0) {
    count++;
  }

  if (
    requirementData?.commercial_general_liability_damage_rented_premises > 0
  ) {
    count++;
  }

  if (requirementData?.commercial_general_liability_medical_expenses > 0) {
    count++;
  }

  if (requirementData?.commercial_general_liability_personal_adv_injury > 0) {
    count++;
  }

  if (requirementData?.commercial_general_liability_general_aggregate > 0) {
    count++;
  }

  if (requirementData?.commercial_general_liability_comprop_acg > 0) {
    count++;
  }

  if (!isEmpty(requirementData?.commercial_general_policy)) {
    count++;
  }

  if (!isEmpty(requirementData?.commercial_general_aggregate_limit)) {
    count++;
  }

  if (requirementData?.commercial_general_liability_additional_insurance) {
    count++;
  }

  if (requirementData?.commercial_general_liability_subrogration_waved) {
    count++;
  }

  return count;
}

function getAutoTotal(requirementData) {
  let count = 0;

  if (!requirementData?.automotive_liability_required) {
    return -1;
  }

  if (requirementData?.automotive_liability_combined_single_limit > 0) {
    count++;
  }

  if (requirementData?.automotive_liability_bodily_injury_pp > 0) {
    count++;
  }

  if (requirementData?.automotive_liability_any_auto) {
    count++;
  }

  if (requirementData?.automotive_liability_all_owned_autos) {
    count++;
  }

  if (requirementData?.automotive_liability_category_hired_autos) {
    count++;
  }

  if (requirementData?.automotive_liability_category_scheduled_autos) {
    count++;
  }

  if (requirementData?.automotive_liability_category_non_owned_autos) {
    count++;
  }

  if (requirementData?.automotive_policy_form_additional_insurance) {
    count++;
  }

  if (requirementData?.automotive_policy_form_subrogratrion_waived) {
    count++;
  }
  return count;
}

function getWorkCompTotal(requirementData) {
  let count = 0;

  if (!requirementData?.workers_compensation_required) {
    return -1;
  }

  if (requirementData?.workers_compensation_subrogration_waved) {
    count++;
  }

  if (requirementData?.workers_compensation_el_each_accident > 0) {
    count++;
  }

  if (
    requirementData?.workers_compensation_other_el_disease_each_employer > 0
  ) {
    count++;
  }

  if (requirementData?.workers_compensation_el_disease_policy_limit > 0) {
    count++;
  }

  return count;
}

function getUmbrellaTotal(requirementData) {
  let count = 0;

  if (!requirementData?.umbrella_subrogration_required) {
    return -1;
  }

  if (!isEmpty(requirementData?.umbrella_subrogration_liability)) {
    count++;
  }

  if (!isEmpty(requirementData?.umbrella_subrogration_activity)) {
    count++;
  }

  if (!isEmpty(requirementData?.umbrella_subrogration_status)) {
    count++;
  }

  if (requirementData?.umbrella_subrogration_each_occurance > 0) {
    count++;
  }

  if (requirementData?.umbrella_subrogration_aggregate > 0) {
    count++;
  }

  return count;
}

function getLiabilityScore(finalData, defaultData) {
  const totalScore = getLiabilityTotal(defaultData);
  const issueLiabilityExpireDate = buildExpireDate(
    finalData.commercial_general_liability_expire_date,
    defaultData.commercial_general_liability_required
  );
  if (issueLiabilityExpireDate.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportLiability(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

function reportLiability(finalData, defaultData) {
  const issueLiabilityGeneralPolicy = buildGeneralPolicy(
    finalData.commercial_general_policy,
    defaultData.commercial_general_policy,
    defaultData.commercial_general_liability_required
  );

  const issueLiabilityAggregateLimit = buildGeneralAggregateLimit(
    finalData.commercial_general_aggregate_limit,
    defaultData.commercial_general_aggregate_limit,
    defaultData.commercial_general_liability_required
  );

  const issueLiabilityAdditionalInsurance =
    buildGeneralLiabilityAdditionalInsurance(
      finalData.commercial_general_liability_additional_insurance,
      defaultData.commercial_general_liability_additional_insurance,
      defaultData.commercial_general_liability_required
    );

  const issueLiabilitySubrogrationWaved =
    buildGeneralLiabilitySubrogrationWaved(
      finalData.commercial_general_liability_subrogration_waved,
      defaultData.commercial_general_liability_subrogration_waved,
      defaultData.commercial_general_liability_required
    );

  const issueLiabilityEffectDate = buildEffectDate(
    finalData.commercial_general_liability_effective_date,
    defaultData.commercial_general_liability_required
  );

  const issueLiabilityExpireDate = buildExpireDate(
    finalData.commercial_general_liability_expire_date,
    defaultData.commercial_general_liability_required
  );

  const issueLiabilityOccurance = buildValueGT(
    finalData.commercial_general_liability_each_occurance,
    defaultData.commercial_general_liability_each_occurance,
    defaultData.commercial_general_liability_required
  );

  const issueLiabilityDamageRent = buildValueGT(
    finalData.commercial_general_liability_damage_rented_premises,
    defaultData.commercial_general_liability_damage_rented_premises,
    defaultData.commercial_general_liability_required
  );

  const issueLiabilityMedicalExpense = buildValueGT(
    finalData.commercial_general_liability_medical_expenses,
    defaultData.commercial_general_liability_medical_expenses,
    defaultData.commercial_general_liability_required
  );

  const issueLiabilityPersonalAdv = buildValueGT(
    finalData.commercial_general_liability_personal_adv_injury,
    defaultData.commercial_general_liability_personal_adv_injury,
    defaultData.commercial_general_liability_required
  );

  const issueLiabilityAggregate = buildValueGT(
    finalData.commercial_general_liability_general_aggregate,
    defaultData.commercial_general_liability_general_aggregate,
    defaultData.commercial_general_liability_required
  );

  const issueLiabilityComp = buildValueGT(
    finalData.commercial_general_liability_comprop_acg,
    defaultData.commercial_general_liability_comprop_acg,
    defaultData.commercial_general_liability_required
  );

  const issueList = [
    ...issueLiabilityGeneralPolicy,
    ...issueLiabilityAggregateLimit,
    ...issueLiabilityAdditionalInsurance,
    ...issueLiabilitySubrogrationWaved,
    ...issueLiabilityEffectDate,
    ...issueLiabilityExpireDate,
    ...issueLiabilityOccurance,
    ...issueLiabilityDamageRent,
    ...issueLiabilityMedicalExpense,
    ...issueLiabilityPersonalAdv,
    ...issueLiabilityAggregate,
    ...issueLiabilityComp
  ];

  return issueList;
}

function getAutoScore(finalData, defaultData) {
  const totalScore = getAutoTotal(defaultData);
  const issueAutoExpire = buildExpireDate(
    finalData.automotive_liability_expire_date,
    defaultData.automotive_liability_required
  );
  if (issueAutoExpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportAuto(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

function reportAuto(formVal, franchisorData) {
  const issueAutoAny = buildAnyAuto(
    formVal.automotive_liability_any_auto,
    franchisorData.automotive_liability_any_auto,
    franchisorData.automotive_liability_required
  );

  const issueAutoAll = buildAllOwnedAuto(
    formVal.automotive_liability_all_owned_autos,
    franchisorData.automotive_liability_all_owned_autos,
    formVal.automotive_liability_any_auto ||
      (formVal.automotive_liability_category_non_owned_autos &&
        formVal.automotive_liability_category_hired_autos) ||
      (formVal.automotive_liability_category_scheduled_autos &&
        formVal.automotive_liability_category_hired_autos) ||
      (formVal.automotive_liability_category_non_owned_autos &&
        formVal.automotive_liability_category_scheduled_autos),
    franchisorData.automotive_liability_required
  );

  const issueAutoHired = buildHiredAuto(
    formVal.automotive_liability_category_hired_autos,
    franchisorData.automotive_liability_category_hired_autos,
    formVal.automotive_liability_any_auto,
    franchisorData.automotive_liability_required
  );

  const issueAutoScheduled = buildScheduledAuto(
    formVal.automotive_liability_category_scheduled_autos,
    franchisorData.automotive_liability_category_scheduled_autos,
    formVal.automotive_liability_any_auto,
    franchisorData.automotive_liability_required ||
      (formVal.automotive_liability_category_hired_autos &&
        formVal.automotive_liability_category_non_owned_autos)
  );

  const issueAutoNonOwned = buildNonOwnedAuto(
    formVal.automotive_liability_category_non_owned_autos,
    franchisorData.automotive_liability_category_non_owned_autos,
    formVal.automotive_liability_any_auto,
    franchisorData.automotive_liability_required
  );

  const issueAutoAdditionalInsurance = buildGeneralLiabilityAdditionalInsurance(
    formVal.automotive_policy_form_additional_insurance,
    franchisorData.automotive_policy_form_additional_insurance,
    franchisorData.automotive_liability_required
  );

  const issueAutoSubrogratrionWaived = buildGeneralLiabilitySubrogrationWaved(
    formVal.automotive_policy_form_subrogratrion_waived,
    franchisorData.automotive_policy_form_subrogratrion_waived,
    franchisorData.automotive_liability_required
  );

  const issueAutoEffect = buildEffectDate(
    formVal.automotive_liability_effective_date,
    franchisorData.automotive_liability_required
  );

  const issueAutoExpire = buildExpireDate(
    formVal.automotive_liability_expire_date,
    franchisorData.automotive_liability_required
  );

  const issueAutoSingleLimit = buildValueGT(
    formVal.automotive_liability_combined_single_limit,
    franchisorData.automotive_liability_combined_single_limit,
    franchisorData.automotive_liability_required
  );

  const issueAutoInjury = buildValueLT(
    formVal.automotive_liability_bodily_injury_pp,
    franchisorData.automotive_liability_bodily_injury_pp,
    franchisorData.automotive_liability_required
  );

  const issueList = [
    ...issueAutoAny,
    ...issueAutoAll,
    ...issueAutoHired,
    ...issueAutoScheduled,
    ...issueAutoNonOwned,
    ...issueAutoAdditionalInsurance,
    ...issueAutoSubrogratrionWaived,
    ...issueAutoEffect,
    ...issueAutoExpire,
    ...issueAutoSingleLimit,
    ...issueAutoInjury
  ];

  return issueList;
}

function getUmbrellaScore(finalData, defaultData) {
  const totalScore = getUmbrellaTotal(defaultData);
  const issueUmbrellaExpire = buildExpireDate(
    finalData.umbrella_subrogration_expire_date,
    defaultData.umbrella_subrogration_required
  );
  if (issueUmbrellaExpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportUmbrella(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

function reportUmbrella(formVal, franchisorData) {
  const issueUmbrellasubrogration = buildUmbrellaSubrogrationLiability(
    formVal.umbrella_subrogration_liability,
    franchisorData.umbrella_subrogration_liability,
    franchisorData.umbrella_subrogration_required
  );

  const issueUmbrellaActivity = buildUmbrellaSubrogrationActivity(
    formVal.umbrella_subrogration_activity,
    franchisorData.umbrella_subrogration_activity,
    franchisorData.umbrella_subrogration_required
  );

  const issueUmbrellaStatus = buildUmbrellaSubrogrationStatus(
    formVal.umbrella_subrogration_status,
    franchisorData.umbrella_subrogration_status,
    franchisorData.umbrella_subrogration_required
  );

  const issueUmbrellaDate = buildEffectDate(
    formVal.umbrella_subrogration_effective_date,
    franchisorData.umbrella_subrogration_required
  );

  const issueUmbrellaExpire = buildExpireDate(
    formVal.umbrella_subrogration_expire_date,
    franchisorData.umbrella_subrogration_required
  );

  const issueUmbrellaOccurance = buildValueGT(
    formVal.umbrella_subrogration_each_occurance,
    franchisorData.umbrella_subrogration_each_occurance,
    franchisorData.umbrella_subrogration_required
  );

  const issueUmbrellaAggregate = buildValueGT(
    formVal.umbrella_subrogration_aggregate,
    franchisorData.umbrella_subrogration_aggregate,
    franchisorData.umbrella_subrogration_required
  );

  const issueList = [
    ...issueUmbrellasubrogration,
    ...issueUmbrellaActivity,
    ...issueUmbrellaStatus,
    ...issueUmbrellaDate,
    ...issueUmbrellaExpire,
    ...issueUmbrellaOccurance,
    ...issueUmbrellaAggregate
  ];

  return issueList;
}

function getWorkCompScore(finalData, defaultData) {
  const totalScore = getWorkCompTotal(defaultData);
  const issueWorkExpire = buildExpireDate(
    finalData.workers_compensation_expire_date,
    defaultData.workers_compensation_required
  );
  if (issueWorkExpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportWorkComp(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

function reportWorkComp(formVal, franchisorData) {
  const issueWorkWaved = buildGeneralLiabilitySubrogrationWaved(
    formVal.workers_compensation_subrogration_waved,
    franchisorData.workers_compensation_subrogration_waved,
    franchisorData.workers_compensation_required
  );

  const issueWorkEffect = buildEffectDate(
    formVal.workers_compensation_effective_date,
    franchisorData.workers_compensation_required
  );

  const issueWorkExpire = buildExpireDate(
    formVal.workers_compensation_expire_date,
    franchisorData.workers_compensation_required
  );

  const issueWorkAccident = buildValueGT(
    formVal.workers_compensation_el_each_accident,
    franchisorData.workers_compensation_el_each_accident,
    franchisorData.workers_compensation_required
  );

  const issueWorkEmployer = buildValueGT(
    formVal.workers_compensation_other_el_disease_each_employer,
    franchisorData.workers_compensation_other_el_disease_each_employer,
    franchisorData.workers_compensation_required
  );

  const issueWorkLimit = buildValueGT(
    formVal.workers_compensation_el_disease_policy_limit,
    franchisorData.workers_compensation_el_disease_policy_limit,
    franchisorData.workers_compensation_required
  );

  const issueList = [
    ...issueWorkWaved,
    ...issueWorkEffect,
    ...issueWorkExpire,
    ...issueWorkAccident,
    ...issueWorkEmployer,
    ...issueWorkLimit
  ];

  return issueList;
}

function reportAdd(formVal, franchisorData) {
  const issueAddOcc = buildAdditionalOccurenceValue(formVal, franchisorData);

  const issueAddAgg = buildAdditionalAggregateValue(formVal, franchisorData);

  const issueAddPer = buildAdditionalPersonalValue(formVal, franchisorData);

  const issueAddPro = buildAdditionalProductValue(formVal, franchisorData);

  // Need to enable later

  // const issueAddAuto = buildAdditionalAutoCombinedValue(
  //   formVal,
  //   franchisorData
  // );

  // const issueAddComp = buildAdditionalCompAccidentValue(
  //   formVal,
  //   franchisorData
  // );

  // const issueAddEmp = buildAdditionalCompEmployeeValue(formVal, franchisorData);

  // const issueAddLimit = buildAdditionalCompLimitValue(formVal, franchisorData);

  return [
    ...issueAddOcc,
    ...issueAddAgg,
    ...issueAddPer,
    ...issueAddPro
    // ...issueAddAuto,
    // ...issueAddComp,
    // ...issueAddEmp,
    // ...issueAddLimit,
  ];
}

function buildCertificateValid(first, second) {
  const similarity = compareTwoStrings(
    first.toLowerCase(),
    second.toLowerCase()
  );
  if (similarity > FORM_STRING_SIMILARITY) {
    return {
      similarity: (similarity * 100).toFixed(2),
      error: false
    };
  } else {
    return {
      similarity: (similarity * 100).toFixed(2),
      error: true,
      msg: "Certificate Holder is not matched"
    };
  }
}

function buildInsuranceCertValid(first, second) {
  const similarity = compareTwoStrings(
    first.toLowerCase(),
    second.toLowerCase()
  );
  if (similarity > FORM_STRING_SIMILARITY) {
    return {
      similarity: (similarity * 100).toFixed(2),
      error: false
    };
  } else {
    return {
      similarity: (similarity * 100).toFixed(2),
      error: true,
      msg: "Certificate of Insurance is not matched"
    };
  }
}

function buildLocationVerify(first, second) {
  const similarity = compareTwoStrings(
    first.toLowerCase(),
    second.toLowerCase()
  );
  if (similarity > FORM_LOCATION_SIMILARITY) {
    return {
      similarity: (similarity * 100).toFixed(2),
      error: false
    };
  } else {
    return {
      similarity: (similarity * 100).toFixed(2),
      error: true
    };
  }
}

function getCrimeTotal(requirementData) {
  let count = 0;

  if (!requirementData?.crime_required) {
    return -1;
  }

  if (!isEmpty(requirementData?.crime_form_type)) {
    count++;
  }

  if (requirementData?.crime_each_claim > 0) {
    count++;
  }

  if (requirementData?.crime_third_party > 0) {
    count++;
  }

  return count;
}

function reportCrime(formVal, franchisorData) {
  const issueCrimeForm = buildCrimeForm(
    formVal.crime_form_type,
    franchisorData.crime_form_type,
    franchisorData?.crime_required
  );

  const issueCrimeEffectiveDate = buildEffectDate(
    formVal.crime_effective_date,
    franchisorData?.crime_required
  );

  const issueCrimeExpireDate = buildExpireDate(
    formVal.crime_expire_date,
    franchisorData?.crime_required
  );

  const issueCrimeEachClaim = buildValueGT(
    formVal.crime_each_claim,
    franchisorData.crime_each_claim,
    franchisorData?.crime_required
  );

  const issueCrimeThirdParty = buildValueGT(
    formVal.crime_third_party,
    franchisorData.crime_third_party,
    franchisorData?.crime_required
  );

  const issueList = [
    ...issueCrimeForm,
    ...issueCrimeEffectiveDate,
    ...issueCrimeExpireDate,
    ...issueCrimeEachClaim,
    ...issueCrimeThirdParty
  ];

  return issueList;
}

function buildCrimeForm(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (defaultValue === FORM_LOSS_DISCOVERED) {
    if (value !== FORM_LOSS_DISCOVERED) {
      return [
        {
          type: "",
          title: `Loss Discovered must be selected`,
          date: ""
        }
      ];
    }

    return [];
  }

  if (defaultValue === FORM_LOSS_SUSTAINED) {
    if (value !== FORM_LOSS_DISCOVERED && value !== FORM_LOSS_SUSTAINED) {
      return [
        {
          type: "",
          title: `Loss Discovered or Loss Sustained must be selected`,
          date: ""
        }
      ];
    }
  }

  return [];
}

function getCrimeScore(finalData, defaultData) {
  const totalScore = getCrimeTotal(defaultData);
  const issueCrimExpire = buildExpireDate(
    finalData.crime_expire_date,
    defaultData?.crime_required
  );
  if (issueCrimExpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportCrime(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

/// Garage Section

function buildGarageDirectPrimary(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (defaultValue) {
    if (!value) {
      return [
        {
          type: "",
          title: `Yes must be selected`,
          date: ""
        }
      ];
    }

    return [];
  }

  return [];
}

function getGarageTotal(requirementData) {
  let count = 0;

  if (!requirementData?.garage_required) {
    return -1;
  }

  if (requirementData?.garage_direct) {
    count++;
  }

  if (requirementData?.garage_comp > 0) {
    count++;
  }

  if (requirementData?.garage_coll > 0) {
    count++;
  }

  return count;
}

function reportGarage(formVal, franchisorData) {
  const issueGarageDirect = buildGarageDirectPrimary(
    formVal.garage_direct,
    franchisorData.garage_direct,
    franchisorData?.garage_required
  );

  const issueGarageEffectiveDate = buildEffectDate(
    formVal.garage_effective_date,
    franchisorData?.garage_required
  );

  const issueGarageExpireDate = buildExpireDate(
    formVal.garage_expire_date,
    franchisorData?.garage_required
  );

  const issueGarageComp = buildValueGT(
    formVal.garage_comp,
    franchisorData.garage_comp,
    franchisorData?.garage_required
  );

  const issueGarageColl = buildValueGT(
    formVal.garage_coll,
    franchisorData.garage_coll,
    franchisorData?.garage_required
  );

  const issueList = [
    ...issueGarageDirect,
    ...issueGarageEffectiveDate,
    ...issueGarageExpireDate,
    ...issueGarageComp,
    ...issueGarageColl
  ];

  return issueList;
}

function getGarageScore(finalData, defaultData) {
  const totalScore = getGarageTotal(defaultData);
  const issueGaragexpire = buildExpireDate(
    finalData.garage_expire_date,
    defaultData?.garage_required
  );
  if (issueGaragexpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportGarage(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

// Epli Section

function getEpliTotal(requirementData) {
  let count = 0;

  if (!requirementData?.epli_required) {
    return -1;
  }

  if (!isEmpty(requirementData?.epli_third_party)) {
    count++;
  }

  if (requirementData?.epli_occ > 0) {
    count++;
  }

  if (requirementData?.epli_agg > 0) {
    count++;
  }

  if (requirementData?.epli_wage > 0) {
    count++;
  }

  if (requirementData?.epli_mx > 0) {
    count++;
  }

  return count;
}

function reportEpli(formVal, franchisorData) {
  const issueEpliThirdParty = buildEpliThirdParty(
    formVal.epli_third_party,
    franchisorData.epli_third_party,
    franchisorData?.epli_required
  );

  const issueEpliEffectiveDate = buildEffectDate(
    formVal.epli_effective_date,
    franchisorData?.epli_required
  );

  const issueEpliExpireDate = buildExpireDate(
    formVal.epli_expire_date,
    franchisorData?.epli_required
  );

  const issueEpliOCC = buildValueGT(
    formVal.epli_occ,
    franchisorData.epli_occ,
    franchisorData?.epli_required
  );

  const issueEpliAGG = buildValueGT(
    formVal.epli_agg,
    franchisorData.epli_agg,
    franchisorData?.epli_required
  );

  const issueEpliWAGE = buildValueGT(
    formVal.epli_wage,
    franchisorData.epli_wage,
    franchisorData?.epli_required
  );

  const issueEpliMX = buildValueLT(
    formVal.epli_mx,
    franchisorData.epli_mx,
    franchisorData?.epli_required
  );

  const issueList = [
    ...issueEpliThirdParty,
    ...issueEpliEffectiveDate,
    ...issueEpliExpireDate,
    ...issueEpliOCC,
    ...issueEpliAGG,
    ...issueEpliWAGE,
    ...issueEpliMX
  ];

  return issueList;
}

function buildEpliThirdParty(value, defaultValue, disable = false) {
  if (!disable) {
    return [];
  }

  if (defaultValue === FORM_INCLUDED) {
    if (value !== FORM_INCLUDED) {
      return [
        {
          type: "",
          title: `Included must be selected`,
          date: ""
        }
      ];
    }

    return [];
  }

  if (defaultValue === FORM_EXCLUDED) {
    if (value !== FORM_INCLUDED && value !== FORM_EXCLUDED) {
      return [
        {
          type: "",
          title: `Loss Discovered or Loss Sustained must be selected`,
          date: ""
        }
      ];
    }
  }

  return [];
}

function getEpliScore(finalData, defaultData) {
  const totalScore = getEpliTotal(defaultData);
  const issueEpliExpire = buildExpireDate(
    finalData.epli_expire_date,
    defaultData?.epli_required
  );
  if (issueEpliExpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportEpli(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

// Cyber Section

function getCyberTotal(requirementData) {
  let count = 0;

  if (!requirementData?.cyber_required) {
    return -1;
  }

  if (requirementData?.cyber_occ > 0) {
    count++;
  }

  if (requirementData?.cyber_agg > 0) {
    count++;
  }

  if (requirementData?.cyber_ded > 0) {
    count++;
  }

  return count;
}

function reportCyber(formVal, franchisorData) {
  const issueCyberEffectiveDate = buildEffectDate(
    formVal.cyber_effective_date,
    franchisorData?.cyber_required
  );

  const issueCyberExpireDate = buildExpireDate(
    formVal.cyber_expire_date,
    franchisorData?.cyber_required
  );

  const issueCyberOCC = buildValueGT(
    formVal.cyber_occ,
    franchisorData.cyber_occ,
    franchisorData?.cyber_required
  );

  const issueCyberAGG = buildValueGT(
    formVal.cyber_agg,
    franchisorData.cyber_agg,
    franchisorData?.cyber_required
  );

  const issueCyberDED = buildValueLT(
    formVal.cyber_ded,
    franchisorData.cyber_ded,
    franchisorData?.cyber_required
  );

  const issueList = [
    ...issueCyberEffectiveDate,
    ...issueCyberExpireDate,
    ...issueCyberOCC,
    ...issueCyberAGG,
    ...issueCyberDED
  ];

  return issueList;
}

function getCyberScore(finalData, defaultData) {
  const totalScore = getCyberTotal(defaultData);
  const issueCyberExpire = buildExpireDate(
    finalData.cyber_expire_date,
    defaultData?.cyber_required
  );
  if (issueCyberExpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportCyber(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

// Professional Liability

function getProLiabTotal(requirementData) {
  let count = 0;

  if (!requirementData?.pro_liab_required) {
    return -1;
  }

  if (requirementData?.pro_liab_occ > 0) {
    count++;
  }

  if (requirementData?.pro_liab_agg > 0) {
    count++;
  }

  // if (!isEmpty(requirementData?.pro_liab_type)) {
  //   count++;
  // }

  return count;
}

function reportProLiab(formVal, franchisorData) {
  const issueProLiabEffectiveDate = buildEffectDate(
    formVal.pro_liab_effective_date,
    franchisorData?.pro_liab_required
  );

  const issueProLiabExpireDate = buildExpireDate(
    formVal.pro_liab_expire_date,
    franchisorData.pro_liab_required
  );

  const issueProLiabOCC = buildValueGT(
    formVal.pro_liab_occ,
    franchisorData.pro_liab_occ,
    franchisorData?.pro_liab_required
  );

  const issueProLiabAGG = buildValueGT(
    formVal.pro_liab_agg,
    franchisorData.pro_liab_agg,
    franchisorData.pro_liab_required
  );

  const issueProLiabType = buildPollutionType(
    formVal.pro_liab_type,
    franchisorData.pro_liab_type,
    franchisorData.pro_liab_required
  );

  const issueList = [
    ...issueProLiabEffectiveDate,
    ...issueProLiabExpireDate,
    ...issueProLiabOCC,
    ...issueProLiabAGG,
    ...issueProLiabType
  ];

  return issueList;
}

function getProLiabScore(finalData, defaultData) {
  const totalScore = getProLiabTotal(defaultData);
  const issueProLiabExpire = buildExpireDate(
    finalData.pro_liab_expire_date,
    defaultData.pro_liab_required
  );
  if (issueProLiabExpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportProLiab(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

// Pollution Liability

function getPolLiabTotal(requirementData) {
  let count = 0;

  if (!requirementData?.pol_liab_required) {
    return -1;
  }

  if (requirementData?.pol_liab_occ > 0) {
    count++;
  }

  if (requirementData?.pol_liab_agg > 0) {
    count++;
  }

  // if (!isEmpty(requirementData?.pol_liab_type)) {
  //   count++;
  // }

  return count;
}

function reportPolLiab(formVal, franchisorData) {
  const issuePolLiabEffectiveDate = buildEffectDate(
    formVal.pol_liab_effective_date,
    franchisorData?.pol_liab_required
  );

  const issuePolLiabExpireDate = buildExpireDate(
    formVal.pol_liab_expire_date,
    franchisorData.pol_liab_required
  );

  const issuePolLiabOCC = buildValueGT(
    formVal.pol_liab_occ,
    franchisorData.pol_liab_occ,
    franchisorData?.pol_liab_required
  );

  const issuePolLiabAGG = buildValueGT(
    formVal.pol_liab_agg,
    franchisorData.pol_liab_agg,
    franchisorData.pol_liab_required
  );

  const issuePolLiabType = buildPollutionType(
    formVal.pol_liab_type,
    franchisorData.pol_liab_type,
    franchisorData.pol_liab_required
  );

  const issueList = [
    ...issuePolLiabEffectiveDate,
    ...issuePolLiabExpireDate,
    ...issuePolLiabOCC,
    ...issuePolLiabAGG,
    ...issuePolLiabType
  ];

  return issueList;
}

function getPolLiabScore(finalData, defaultData) {
  const totalScore = getPolLiabTotal(defaultData);
  const issuePolLiabExpire = buildExpireDate(
    finalData.pol_liab_expire_date,
    defaultData.pol_liab_required
  );
  if (issuePolLiabExpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportPolLiab(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

// Inland Marine

function getIMTotal(requirementData) {
  let count = 0;

  if (!requirementData?.im_required) {
    return -1;
  }

  if (requirementData?.im_limit > 0) {
    count++;
  }

  if (requirementData?.im_limit_per_item > 0) {
    count++;
  }

  if (requirementData?.im_business_interuption > 0) {
    count++;
  }

  return count;
}

function getProInsTotal(requirementData) {
  let count = 0;

  if (!requirementData?.pro_ins_required) {
    return -1;
  }

  if (requirementData?.pro_ins_bus_per_pro > 0) {
    count++;
  }

  if (requirementData?.pro_ins_ten_imp > 0) {
    count++;
  }

  if (requirementData?.pro_ins_bui_amo > 0) {
    count++;
  }

  if (requirementData?.pro_ins_bus_int > 0) {
    count++;
  }

  if (requirementData?.pro_ins_act_los > 0) {
    count++;
  }

  if (requirementData?.pro_ins_mon_lim > 0) {
    count++;
  }

  if (requirementData?.pro_ins_co_ins > 0) {
    count++;
  }

  if (requirementData?.pro_ins_agg_val > 0) {
    count++;
  }

  if (requirementData?.pro_ins_form_type) {
    count++;
  }

  if (requirementData?.pro_ins_los_rec_type) {
    count++;
  }

  return count;
}

function reportProIns(formVal, franchisorData) {
  const issueFormType = buildFormType(
    formVal.pro_ins_form_type,
    franchisorData.pro_ins_form_type,
    franchisorData.pro_ins_required
  );
  const issueRecoveryType = buildRecoveryType(
    formVal.pro_ins_los_rec_type,
    franchisorData.pro_ins_los_rec_type,
    franchisorData.pro_ins_required
  );
  const issuePersonalProperty = buildValueGT(
    formVal.pro_ins_bus_per_pro,
    franchisorData.pro_ins_bus_per_pro,
    franchisorData?.pro_ins_required
  );
  const issueTenantImprovement = buildValueGT(
    formVal.pro_ins_ten_imp,
    franchisorData.pro_ins_ten_imp,
    franchisorData?.pro_ins_required
  );
  const issueBuildingAmount = buildValueGT(
    formVal.pro_ins_bui_amo,
    franchisorData.pro_ins_bui_amo,
    franchisorData?.pro_ins_required
  );
  const issueBusinessInteruption = buildValueGT(
    formVal.pro_ins_bus_int,
    franchisorData.pro_ins_bus_int,
    franchisorData?.pro_ins_required
  );
  const issueLossSustained = buildValueGT(
    formVal.pro_ins_act_los,
    franchisorData.pro_ins_act_los,
    franchisorData?.pro_ins_required
  );
  const issueMonthlyLimit = buildValueGT(
    formVal.pro_ins_mon_lim,
    franchisorData.pro_ins_mon_lim,
    franchisorData?.pro_ins_required
  );
  const issueCoInsurance = buildValueGT(
    formVal.pro_ins_co_ins,
    franchisorData.pro_ins_co_ins,
    franchisorData?.pro_ins_required
  );
  const issueAgreedValue = buildValueGT(
    formVal.pro_ins_agg_val,
    franchisorData.pro_ins_agg_val,
    franchisorData?.pro_ins_required
  );

  const issueEffectiveDate = buildEffectDate(
    formVal.pro_ins_effective_date,
    franchisorData?.pro_ins_required
  );
  const issueExpireDate = buildExpireDate(
    formVal.pro_ins_expire_date,
    franchisorData.pro_ins_required
  );

  const issueList = [
    ...issueFormType,
    ...issueRecoveryType,
    ...issuePersonalProperty,
    ...issueTenantImprovement,
    ...issueBuildingAmount,
    ...issueBusinessInteruption,
    ...issueLossSustained,
    ...issueMonthlyLimit,
    ...issueCoInsurance,
    ...issueAgreedValue,
    ...issueEffectiveDate,
    ...issueExpireDate
  ];

  return issueList;
}

function reportIM(formVal, franchisorData) {
  const issueIMEffectiveDate = buildEffectDate(
    formVal.im_effective_date,
    franchisorData?.im_required
  );

  const issueIMExpireDate = buildExpireDate(
    formVal.im_expire_date,
    franchisorData?.im_required
  );

  const issueIMLimit = buildValueGT(
    formVal.im_limit,
    franchisorData.im_limit,
    franchisorData?.im_required
  );

  const issueIMLimitPerItem = buildValueGT(
    formVal.im_limit_per_item,
    franchisorData.im_limit_per_item,
    franchisorData?.im_required
  );

  const issueIMBusinessInteruption = buildValueLT(
    formVal.im_business_interuption,
    franchisorData.im_business_interuption,
    franchisorData?.im_required
  );

  const issueList = [
    ...issueIMEffectiveDate,
    ...issueIMExpireDate,
    ...issueIMLimit,
    ...issueIMLimitPerItem,
    ...issueIMBusinessInteruption
  ];

  return issueList;
}

function getIMScore(finalData, defaultData) {
  const totalScore = getIMTotal(defaultData);
  const issueIMExpire = buildExpireDate(
    finalData.im_expire_date,
    defaultData?.im_required
  );
  if (issueIMExpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportIM(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

function getProInsScore(finalData, defaultData) {
  const totalScore = getProInsTotal(defaultData);
  const issueProInsExpire = buildExpireDate(
    finalData.pro_ins_expire_date,
    defaultData?.pro_ins_required
  );
  if (issueProInsExpire.length > 0) {
    return {
      visible: totalScore >= 0 ? true : false,
      score: 0,
      total: totalScore >= 0 ? totalScore : 0
    };
  }

  const totalIssue = reportProIns(finalData, defaultData);
  return {
    visible: totalScore >= 0 ? true : false,
    score: totalScore - totalIssue.length,
    total: totalScore >= 0 ? totalScore : 0
  };
}

function handleGetStatus(status, issueData, enabled) {
  if (!enabled) {
    if (status === STATUS_SECTION_APPROVE) {
      return INSURANCE_STATUS_IN_PLACE;
    }
    return INSURANCE_STATUS_NONE;
  }
  if (status === STATUS_SECTION_APPROVE) {
    if (issueData.length === 0) {
      return INSURANCE_STATUS_COMPLIANT;
    } else {
      return INSURANCE_STATUS_NON_COMPLIANT;
    }
  } else {
    return INSURANCE_STATUS_NON_VERIFIED;
  }
}

function handleGetComplianceStatus(status, issueData) {
  if (status === STATUS_SECTION_APPROVE) {
    if (issueData.length === 0) {
      return INSURANCE_STATUS_COMPLIANT;
    } else {
      return INSURANCE_STATUS_NON_COMPLIANT;
    }
  } else {
    return INSURANCE_STATUS_PENDING;
  }
}

function isCoverageApproved(coverageStatus, required) {
  if (!required) {
    return true;
  }

  if (coverageStatus === STATUS_SECTION_APPROVE) {
    return true;
  }

  return false;
}

export {
  buildGeneralPolicy,
  buildGeneralAggregateLimit,
  buildGeneralLiabilityAdditionalInsurance,
  buildGeneralLiabilitySubrogrationWaved,
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildValueLT,
  buildAnyAuto,
  buildAllOwnedAuto,
  buildHiredAuto,
  buildScheduledAuto,
  buildNonOwnedAuto,
  buildUmbrellaSubrogrationLiability,
  buildUmbrellaSubrogrationActivity,
  buildUmbrellaSubrogrationStatus,
  buildCrimeForm,
  buildGarageDirectPrimary,
  buildEpliThirdParty,
  buildAdditionalOccurenceValue,
  buildAdditionalAggregateValue,
  buildAdditionalAutoCombinedValue,
  buildAdditionalCompAccidentValue,
  buildAdditionalCompEmployeeValue,
  buildAdditionalCompLimitValue,
  buildAdditionalPersonalValue,
  buildAdditionalProductValue,
  buildCertificateValid,
  buildInsuranceCertValid,
  buildPollutionType,
  buildFormType,
  buildRecoveryType,
  reportLiability,
  reportAuto,
  reportUmbrella,
  reportWorkComp,
  reportAdd,
  reportCrime,
  reportGarage,
  reportEpli,
  reportCyber,
  reportProLiab,
  reportPolLiab,
  reportIM,
  reportProIns,
  getLiabilityScore,
  getAutoScore,
  getUmbrellaScore,
  getWorkCompScore,
  getCrimeScore,
  getGarageScore,
  getEpliScore,
  getCyberScore,
  getProLiabScore,
  getPolLiabScore,
  getIMScore,
  getProInsScore,
  handleGetStatus,
  handleGetComplianceStatus,
  buildLocationVerify,
  isCoverageApproved
};
