import React from "react";
import "./style.scss";
import { Button } from "antd";

export default function QuoteAction(props) {
  const {
    visibleClose = true,
    visibleSave = true,
    visibleBack = true,
    visibleNext = true,
    handleClose,
    handleSave,
    handleBack,
    handleNext,
    handleAccept,
    nextDisable = false,
    nextLabel = null,
  } = props;
  return (
    <div className="quote_action">
      {visibleNext && (
        <Button
          type="submit"
          className="quote_action-btn "
          onClick={handleNext}
          disabled={nextDisable}
        >
          {nextLabel || "Next"}
        </Button>
      )}
      {visibleBack && (
        <Button className="quote_action-btn back-btn" onClick={handleBack}>
          Back
        </Button>
      )}
      {visibleSave && (
        <Button
          type="primary"
          className="save-btn quote_action-btn"
          onClick={handleSave}
        >
          Save
        </Button>
      )}
      {handleAccept && (
        <Button
          type="primary"
          className="save-btn quote_action-btn"
          onClick={handleAccept}
        >
          Accept Coverage
        </Button>
      )}
      {visibleClose && (
        <Button className="close-btn quote_action-btn" onClick={handleClose}>
          Close
        </Button>
      )}
    </div>
  );
}
