import { useAuth0 } from "@auth0/auth0-react";
import {
  GA_CONSTS,
  INSURANCE_CARRIER_TITLE_GA
} from "../../../../assets/const/fim-integration";
import { getAbbrev } from "../../../../assets/const/location";
import { addMonths, addYears, format } from "date-fns";
import { post, put } from "../../../../utils/axios";

export const useGreatAmerican = ({ franchiseeData, franchisorData }) => {
  const { user, getAccessTokenSilently } = useAuth0();

  async function saveQuote(appId, quoteResult) {
    const token = await getAccessTokenSilently();
    let quoteList = franchiseeData?.quoteList || [];

    for (const [coverage, result] of Object.entries(quoteResult)) {
      quoteList.push({
        save_date: new Date().toISOString(),
        prepared_by: user.email,
        status: result.newBusiness.status,
        quote_id: result.rating.data.policy.id,
        coverage,
        response_detail: result,
        request_detail: {
          appId
        },
        integration: INSURANCE_CARRIER_TITLE_GA
      });
    }

    return await put(
      `contact/${franchiseeData._id}`,
      {
        quoteList
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  }

  async function getAppId(
    naicsCode,
    profileVal,
    effectiveDate,
    expirationDate,
    employeeLiability
  ) {
    const token = await getAccessTokenSilently();

    const riskList = [
      {
        objectType: "OPSRISK",
        coverages: [
          {
            eachEmployee: parseInt(employeeLiability.injury) || 1000000,
            diseaseAggregate: parseInt(employeeLiability.policy) || 1000000,
            diseaseEmployee: parseInt(employeeLiability.employee) || 1000000,
            objectType: "empLiab"
          }
        ]
      },
      ...(franchiseeData.locationList || []).map((item, index) => ({
        objectType: "LOCATION",
        locationNumber: index + 1,
        addressLine1: item.address,
        county: item.state,
        city: item.city,
        stateOrProvinceAbbreviation: getAbbrev(item.state),
        countryISOCode: GA_CONSTS.defaultCountry,
        postalCode: item.zip,
        locationEmployeeCount: (item.codeList || []).reduce(
          (sum, arr) => Number(sum) + Number(arr.employeeCount),
          0
        ),
        coverages: (item.codeList || []).map((codeItem) => ({
          objectType: "classCode",
          classCode: codeItem.code,
          estimatedExposure: codeItem.exposure
        }))
      }))
    ];

    const applicationData = {
      application: {
        input: {
          submission: {
            insuredName: `${profileVal.first_name} ${profileVal?.last_name}`,
            insuredStreetAddress: profileVal?.address,
            insuredCity: profileVal?.city,
            insuredStateCode: getAbbrev(profileVal?.state),
            insuredZipCode: profileVal?.zip,
            insuredCountryCode: GA_CONSTS.defaultCountry,
            producerCode: GA_CONSTS.producerCode,
            fein: profileVal?.tax_id,
            naicsCode,
            quotes: [
              {
                policyEffectiveDate:
                  format(new Date(effectiveDate), "yyyy-MM-dd") ||
                  format(addMonths(new Date(), 1), "yyyy-MM-dd"),
                policyExpirationDate:
                  format(new Date(expirationDate), "yyyy-MM-dd") ||
                  format(addYears(addMonths(new Date(), 1), 1), "yyyy-MM-dd"),
                products: [
                  {
                    objectType: GA_CONSTS.productType,
                    risks: riskList
                  }
                ]
              }
            ]
          }
        }
      }
    };
    const result = await post(`gai/application`, applicationData, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      timeout: 45000
    });
    return result.data?.data?.newBusiness?.id;
  }

  return {
    getAppId,
    saveQuote
  };
};
