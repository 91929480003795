import "./style.scss";

export default function QuoteProgressBar(props) {
  const { step, total = 5 } = props;
  const stepWidth = `${100 / total}%`;

  return (
    <div className="quote_progress_bar">
      {[...Array(total)].map((e, i) => {
        if (i + 1 < step) {
          return (
            <div
              className="progress-bar-status-completed"
              style={{ width: stepWidth }}
            ></div>
          );
        } else if (i + 1 == step) {
          return (
            <div
              className="progress-bar-status-active"
              style={{ width: stepWidth }}
            ></div>
          );
        } else {
          return (
            <div
              className="progress-bar-status"
              style={{ width: stepWidth }}
            ></div>
          );
        }
      })}
      ;
    </div>
  );
}
