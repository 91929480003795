import React from "react";
import "./style.scss";
import {Radio} from "antd";

export default function ProductItem(props) {
  const {title, logo, value} = props;
  return (
    <div className="product-item-container">
      <div className="card-header">
          <div className="avatar-container">
              <p className="avatar-title"><img className="product-logo-img" src={logo} /></p>
          </div>
        <h3 className="product-item-title">{title}</h3>
          <Radio value={value}></Radio>
      </div>
    </div>
  );
}
