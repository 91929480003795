const FORM_CLAIMS_MADE = "claims-made";
const FORM_OCCURRENCE = "occurrence";
const FORM_POLICY = "policy";
const FORM_PROJECT = "project";
const FORM_LOC = "loc";
const FORM_UMBRELLA_LIABILITY = "umbrella-liability";
const FORM_EXCESS_LIABILITY = "excess-liability";
const FORM_DEDUCTIBLE = "deductible";
const FORM_RETENTION = "retention";
const FORM_NONE = null;
const FORM_STRING_SIMILARITY = 0.8;
const FORM_LOCATION_SIMILARITY = 0.5;
const FORM_LOSS_DISCOVERED = "loss-discovered";
const FORM_LOSS_SUSTAINED = "loss-sustained";
const FORM_INCLUDED = "included";
const FORM_EXCLUDED = "excluded";
const FORM_TYPE_SPECIAL = "special";
const FORM_TYPE_BROAD = "broad";
const FORM_TYPE_BASIC = "basic";
const FORM_LOSS_RECOVERY_TYPE_COST = "replacement_cost";
const FORM_LOSS_RECOVERY_TYPE_CASH = "actual_cash_value";
const SOURCE_ONBOARDING = "SOURCE_ONBOARDING";
const SOURCE_DASHBOARD = "SOURCE_DASHBOARD";
const RIKOR_CUSTOMER_URL_PARAM = "rikor_customer";

const TITLE_PROP_INSURANCE = "PROPERTY INSURANCE";
const TITLE_COI = "COI";
const TITLE_COPI = "COPI/EOI";

const TITLE_PRO_LIAB = [
  "Pollution/Professional Liability",
  "Prof. Liab",
  "E&O",
  "PL",
  "med mal",
  "medmal",
  "Med Mal",
  "MedMal",
  "Medical Malpracitce"
];

const TITLE_POL_LIAB = [
  "Pollution/Professional Liability",
  "CPL",
  "Poll Liab",
  "Pollution Liability",
  "Pollution"
];

const TITLE_IM = [
  "Inland Marine Equipment Floater",
  "Floater",
  "IM",
  "Inland Marine"
];

const TITLE_CRIME = ["Crime Coverage"];

const TITLE_GARAGE = ["GarageKeepers"];

const TITLE_EPLI = [
  "Employment Practices Liability",
  "EPLI",
  "Employment Practices",
  "Employment Practices Liability Insurance",
  "Employment P"
];

const TITLE_CYBER = ["Cyber Coverage", "Data Breach", "Cyber"];
const ERROR_TYPE_CONTINUE = "Duplicated";
const ERROR_TYPE_QUIT = "Denied";

const APP_DATE_FORMAT = "MM/DD/YYYY";
const APP_DATE_FORMAT_DATEFNS = "MM/dd/yyyy";

export {
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  FORM_POLICY,
  FORM_PROJECT,
  FORM_LOC,
  FORM_UMBRELLA_LIABILITY,
  FORM_EXCESS_LIABILITY,
  FORM_DEDUCTIBLE,
  FORM_RETENTION,
  FORM_NONE,
  FORM_STRING_SIMILARITY,
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
  FORM_INCLUDED,
  FORM_EXCLUDED,
  SOURCE_ONBOARDING,
  SOURCE_DASHBOARD,
  FORM_LOCATION_SIMILARITY,
  TITLE_PRO_LIAB,
  TITLE_POL_LIAB,
  TITLE_IM,
  TITLE_CRIME,
  TITLE_GARAGE,
  TITLE_EPLI,
  TITLE_CYBER,
  TITLE_COI,
  TITLE_COPI,
  TITLE_PROP_INSURANCE,
  ERROR_TYPE_CONTINUE,
  ERROR_TYPE_QUIT,
  FORM_TYPE_SPECIAL,
  FORM_TYPE_BROAD,
  FORM_TYPE_BASIC,
  FORM_LOSS_RECOVERY_TYPE_CASH,
  FORM_LOSS_RECOVERY_TYPE_COST,
  RIKOR_CUSTOMER_URL_PARAM,
  APP_DATE_FORMAT,
  APP_DATE_FORMAT_DATEFNS,
};
