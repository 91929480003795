import {
  COVERAGE_KEY_AUTO,
  COVERAGE_KEY_CRIME,
  COVERAGE_KEY_CYBER,
  COVERAGE_KEY_EPLI,
  COVERAGE_KEY_GARAGE,
  COVERAGE_KEY_IM,
  COVERAGE_KEY_LIABILITY,
  COVERAGE_KEY_POL_LIAB,
  COVERAGE_KEY_PRO_LIAB
} from "@assets/const/fim-integration";
import {
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  FORM_POLICY,
  FORM_PROJECT,
  FORM_LOC,
  FORM_NONE,
  FORM_UMBRELLA_LIABILITY,
  FORM_EXCESS_LIABILITY,
  FORM_DEDUCTIBLE,
  FORM_RETENTION,
  TITLE_PRO_LIAB,
  TITLE_POL_LIAB,
  TITLE_IM,
  TITLE_CRIME,
  TITLE_GARAGE,
  TITLE_EPLI,
  TITLE_CYBER,
} from "../assets/const/form";

// update this when new recog version is released
const currentRecogVersion = "2.0";

function getFormData(recogData, key) {
  let formArr = [];
  const formObj = {};

  if (recogData?.data?.tb_liability_data) {
    formArr = (recogData?.data?.tb_liability_data?.values || []).find(
      (item) =>
        getTitleCoverage(
          item?.properties?.insurance_type?.value,
          key
        )
    );
    const formValueList = getNumberArray(
      formArr?.properties?.limit_value?.value
    );

    return { formArr, formValueList };
  } else {
    const keys = recogData?.data ? Object.keys(recogData?.data) : [];
    const formKeys = keys.filter((item) => item.includes(key));
    formKeys.forEach((key) => {
      formObj[key] = recogData?.data[key];
    });
    return formObj;
  }
}

function getGeneralLiability(data) {
  if (!data) {
    return "";
  }

  if (
    data["COMMERCIAL GENERAL LIABILITY/0/OCCUR"]?.value?.toUpperCase() === "X"
  ) {
    return FORM_OCCURRENCE;
  }

  if (
    data["COMMERCIAL GENERAL LIABILITY/0/CLAIMS-MADE"]?.value?.toUpperCase() ===
    "X"
  ) {
    return FORM_CLAIMS_MADE;
  }

  return "";
}

function getGeneralAggregate(data) {
  if (!data) {
    return "";
  }

  if (
    data["COMMERCIAL GENERAL LIABILITY/0/POLICY"]?.value?.toUpperCase() === "X"
  ) {
    return FORM_POLICY;
  }

  if (
    data["COMMERCIAL GENERAL LIABILITY/0/LOC"]?.value?.toUpperCase() === "X"
  ) {
    return FORM_LOC;
  }

  if (
    data["COMMERCIAL GENERAL LIABILITY/0/PRO-JECT"]?.value?.toUpperCase() ===
    "X"
  ) {
    return FORM_PROJECT;
  }

  return "";
}

// Liabity Converting V2
function getLiabPolicy(data) {
  if (data?.commercial_general_policy_occur?.value === "selected") {
    return FORM_OCCURRENCE;
  }

  if (data?.commercial_general_policy_claims_made?.value === "selected") {
    return FORM_CLAIMS_MADE;
  }

  return "";
}

function getLiabLimit(data) {
  if (data?.commercial_general_aggregate_limit_policy?.value === "selected") {
    return FORM_POLICY;
  }

  if (data?.commercial_general_aggregate_limit_loc?.value === "selected") {
    return FORM_LOC;
  }

  if (data?.commercial_general_aggregate_limit_project?.value === "selected") {
    return FORM_PROJECT;
  }
  return FORM_NONE;
}

function getUmbrellaLiability(data) {
  if (getBooleanField(data?.umbrella_subrogration_liability_excess?.value)) {
    return FORM_UMBRELLA_LIABILITY;
  }

  if (getBooleanField(data?.umbrella_subrogration_liability_umbrella?.value)) {
    return FORM_EXCESS_LIABILITY;
  }

  return FORM_NONE;
}

function getUmbrellaActivity(data) {
  if (getBooleanField(data?.umbrella_subrogration_activity_occur?.value)) {
    return FORM_OCCURRENCE;
  }
  if (getBooleanField(data?.umbrella_subrogration_activity_claims?.value)) {
    return FORM_CLAIMS_MADE;
  }
  return FORM_NONE;
}

function getUmbrellaStatus(data) {
  if (getBooleanField(data?.umbrella_subrogration_status_ded?.value)) {
    return FORM_DEDUCTIBLE;
  }

  if (getBooleanField(data?.umbrella_subrogration_status_ret?.value)) {
    return FORM_RETENTION;
  }
  return FORM_NONE;
}

function getNumberField(data) {
  if (data) {
    // var numberPattern = /\d+\.*/g;
    return Number(data.replace(/[^0-9.-]+/g, ""));
  }

  return "";
}

function getNumberArray(data) {
  if (data) {
    const dataArr = data.split(/ |\//);
    return dataArr
      .filter((str) => str !== "")
      .map((item) => getNumberField(item));
  }

  return [];
}

function getBooleanField(data) {
  if (data && data === "selected") {
    return true;
  }

  return false;
}

function getCheckField(data) {
  if (data === "Y") {
    return true;
  }

  return false;
}

function mapFieldLiability(recogData) {
  if (recogData?.version === currentRecogVersion) {
    return {
      commercial_general_policy: getLiabPolicy(recogData.data),
      commercial_general_liability_additional_insurance: getCheckField(
        recogData.data?.commercial_general_liability_additional_insurance?.value
      ),
      commercial_general_liability_subrogration_waved: getCheckField(
        recogData.data?.commercial_general_liability_subrogration_waved?.value
      ),
      commercial_general_liability_each_occurance: getNumberField(
        recogData.data?.commercial_general_liability_each_occurance?.value
      ),
      commercial_general_liability_damage_rented_premises: getNumberField(
        recogData.data?.commercial_general_liability_damage_rented_premises
          ?.value
      ),
      commercial_general_liability_medical_expenses: getNumberField(
        recogData?.data?.commercial_general_liability_medical_expenses?.value
      ),
      commercial_general_liability_personal_adv_injury: getNumberField(
        recogData?.data?.commercial_general_liability_personal_adv_injury?.value
      ),
      commercial_general_liability_general_aggregate: getNumberField(
        recogData?.data?.commercial_general_liability_general_aggregate?.value
      ),
      commercial_general_liability_comprop_acg: getNumberField(
        recogData?.data?.commercial_general_liability_comprop_acg?.value
      ),
      commercial_general_aggregate_limit: getLiabLimit(recogData?.data),
      commercial_general_liability_policy_number:
        recogData?.data?.commercial_general_liability_policy_number?.value ||
        "",
      commercial_general_liability_effective_date:
        recogData?.data?.commercial_general_liability_effective_date?.value ||
        "",
      commercial_general_liability_expire_date:
        recogData?.data?.commercial_general_liability_expire_date?.value || ""
    };
  } else {
    const data = recogData[0]?.fields;
    return {
      commercial_general_policy: getGeneralLiability(data),
      commercial_general_aggregate_limit: getGeneralAggregate(data),
      commercial_general_liability_additional_insurance:
        data && data["POLICY DETAILS/0/ADDL INSD"]?.value?.toUpperCase() === "X"
          ? true
          : false,
      commercial_general_liability_subrogration_waved:
        data && data["POLICY DETAILS/0/SUBR WVD"]?.value?.toUpperCase() === "X"
          ? true
          : false,
      commercial_general_liability_each_occurance: data
        ? getNumberField(data["POLICY DETAILS/0/EACH OCCURRENCE"]?.value)
        : "",
      commercial_general_liability_damage_rented_premises: data
        ? getNumberField(
            data["POLICY DETAILS/0/DAMAGE TO RENTED PREMISES (Ea occurence)"]
              ?.value
          )
        : "",
      commercial_general_liability_medical_expenses: data
        ? getNumberField(
            data["POLICY DETAILS/0/MED EXP (Any one person)"]?.value
          )
        : "",
      commercial_general_liability_personal_adv_injury: data
        ? getNumberField(data["POLICY DETAILS/0/PERSONAL & ADV INJURY"]?.value)
        : "",
      commercial_general_liability_general_aggregate: data
        ? getNumberField(data["POLICY DETAILS/0/GENERAL AGGREGATE"]?.value)
        : "",
      commercial_general_liability_comprop_acg: data
        ? getNumberField(data["POLICY DETAILS/0/PRODUCTS-COMP~1OP AGG"]?.value)
        : "",
      commercial_general_liability_policy_number: data
        ? data["POLICY DETAILS/0/POLICY NUMBER"]?.value
        : "",
      commercial_general_liability_effective_date: data
        ? data["POLICY DETAILS/0/POLICY EFF"]?.value
        : "",
      commercial_general_liability_expire_date: data
        ? data["POLICY DETAILS/0/POLICY EXP"]?.value
        : ""
    };
  }
}

function mapFieldAuto(recogData) {
  if (recogData?.version === currentRecogVersion) {
    return {
      automotive_liability_any_auto: getBooleanField(
        recogData?.data?.automotive_liability_any_auto?.value
      ),
      automotive_liability_all_owned_autos: getBooleanField(
        recogData?.data?.automotive_liability_all_owned_autos?.value
      ),
      automotive_liability_category_hired_autos: getBooleanField(
        recogData?.data?.automotive_liability_category_hired_autos?.value
      ),
      automotive_liability_category_scheduled_autos: getBooleanField(
        recogData?.data?.automotive_liability_category_scheduled_autos?.value
      ),
      automotive_liability_category_non_owned_autos: getBooleanField(
        recogData?.data?.automotive_liability_category_non_owned_autos?.value
      ),
      automotive_liability_bodily_injury_pp: null, // Need to check
      automotive_policy_form_additional_insurance: getCheckField(
        recogData?.data?.automotive_policy_form_additional_insurance?.value
      ),
      automotive_policy_form_subrogratrion_waived: getCheckField(
        recogData?.data?.automotive_policy_form_subrogratrion_waived?.value
      ),
      automotive_liability_combined_single_limit: getNumberField(
        recogData?.data?.automotive_liability_combined_single_limit?.value
      ),
      automotive_liability_policy_number:
        recogData?.data?.automotive_liability_policy_number?.value || "",
      automotive_liability_effective_date:
        recogData?.data?.automotive_liability_effective_date?.value || "",
      automotive_liability_expire_date:
        recogData?.data?.automotive_liability_expire_date?.value || ""
    };
  } else {
    const data = recogData[0]?.fields;
    return {
      automotive_liability_any_auto:
        data &&
        data["AUTOMOBILE LIABILITY/0/ANY AUTO ONLY"]?.value?.toUpperCase() ===
          "X"
          ? true
          : false,
      automotive_liability_all_owned_autos:
        data &&
        data["AUTOMOBILE LIABILITY/0/ALL OWNED AUTOS"]?.value?.toUpperCase() ===
          "X"
          ? true
          : false,
      automotive_liability_category_hired_autos:
        data &&
        data[
          "AUTOMOBILE LIABILITY/0/HIRED AUTOS ONLY"
        ]?.value?.toUpperCase() === "X"
          ? true
          : false,
      automotive_liability_category_scheduled_autos:
        data &&
        data["AUTOMOBILE LIABILITY/0/SCHEDULED AUTOS"]?.value?.toUpperCase() ===
          "X"
          ? true
          : false,
      automotive_liability_category_non_owned_autos:
        data &&
        data[
          "AUTOMOBILE LIABILITY/0/NON-OWNED AUTOS ONLY"
        ]?.value?.toUpperCase() === "X"
          ? true
          : false,
      automotive_policy_form_additional_insurance:
        data && data["POLICY DETAILS/1/ADDL INSD"]?.value?.toUpperCase() === "X"
          ? true
          : false,
      automotive_policy_form_subrogratrion_waived:
        data && data["POLICY DETAILS/1/SUBR WVD"]?.value?.toUpperCase() === "X"
          ? true
          : false,
      automotive_liability_combined_single_limit: data
        ? getNumberField(
            data["POLICY DETAILS/1/COMBINED SINGLE LIMIT (Ea accident)"]?.value
          )
        : "",
      automotive_liability_bodily_injury_pp: data
        ? getNumberField(
            data["POLICY DETAILS/1/BODILY INJURY (Per person)"]?.value
          )
        : "",
      automotive_liability_policy_number: data
        ? data["POLICY DETAILS/1/POLICY NUMBER"]?.value
        : "",
      automotive_liability_effective_date: data
        ? data["POLICY DETAILS/1/POLICY EFF"]?.value
        : "",
      automotive_liability_expire_date: data
        ? data["POLICY DETAILS/1/POLICY EXP"]?.value
        : ""
    };
  }
}

function mapFieldWC(recogData) {
  if (recogData?.version === currentRecogVersion) {
    return {
      workers_compensation_subrogration_waved: getCheckField(
        recogData?.data?.workers_compensation_subrogration_waved?.value
      ),
      workers_compensation_el_each_accident: getNumberField(
        recogData?.data?.workers_compensation_el_each_accident?.value
      ),
      workers_compensation_other_el_disease_each_employer: getNumberField(
        recogData?.data?.workers_compensation_other_el_disease_each_employer
          ?.value
      ),
      workers_compensation_el_disease_policy_limit: getNumberField(
        recogData?.data?.workers_compensation_el_disease_policy_limit?.value
      ),
      workers_compensation_policy_number:
        recogData?.data?.workers_compensation_policy_number?.value || "",
      workers_compensation_effective_date:
        recogData?.data?.workers_compensation_effective_date?.value || "",
      workers_compensation_expire_date:
        recogData?.data?.workers_compensation_expire_date?.value || ""
    };
  } else {
    const data = recogData[0]?.fields;
    return {
      workers_compensation_subrogration_waved: false,
      workers_compensation_el_each_accident: data
        ? getNumberField(data["POLICY DETAILS/3/E.L. EACH ACCIDENT"]?.value)
        : "",
      workers_compensation_other_el_disease_each_employer: data
        ? getNumberField(
            data["POLICY DETAILS/3/E.L. DISEASE-EA EMPLOYEE"]?.value
          )
        : "",
      workers_compensation_el_disease_policy_limit: data
        ? getNumberField(
            data["POLICY DETAILS/3/E.L. DISEASE-POLICY LIMIT"]?.value
          )
        : "",
      workers_compensation_policy_number: data
        ? data["POLICY DETAILS/3/POLICY NUMBER"]?.value ||
          data["POLICY DETAILS/4/POLICY NUMBER"]?.value
        : "",
      workers_compensation_effective_date: data
        ? data["POLICY DETAILS/3/POLICY EFF"]?.value ||
          data["POLICY DETAILS/4/POLICY EFF"]?.value
        : "",
      workers_compensation_expire_date: data
        ? data["POLICY DETAILS/3/POLICY EXP"]?.value ||
          data["POLICY DETAILS/4/POLICY EXP"]?.value
        : ""
    };
  }
}

function mapFieldUmbrella(recogData) {
  if (recogData?.version === currentRecogVersion) {
    return {
      umbrella_subrogration_each_occurance: getNumberField(
        recogData?.data?.umbrella_subrogration_each_occurance?.value
      ),
      umbrella_subrogration_aggregate: getNumberField(
        recogData?.data?.umbrella_subrogration_aggregate?.value
      ),
      umbrella_subrogration_liability: getUmbrellaLiability(recogData?.data),
      umbrella_subrogration_activity: getUmbrellaActivity(recogData?.data),
      umbrella_subrogration_status: getUmbrellaStatus(recogData?.data),
      umbrella_subrogration_policy_number:
        recogData?.data?.umbrella_subrogration_policy_number?.value || "",
      umbrella_subrogration_effective_date:
        recogData?.data?.umbrella_subrogration_effective_date?.value || "",
      umbrella_subrogration_expire_date:
        recogData?.data?.umbrella_subrogration_expire_date?.value || ""
    };
  } else {
    const data = recogData[0]?.fields;
    return {
      umbrella_subrogration_each_occurance: data
        ? getNumberField(data["POLICY DETAILS/2/EACH OCCURENCE 1"]?.value)
        : "",
      umbrella_subrogration_aggregate: data
        ? getNumberField(data["POLICY DETAILS/2/AGGREGATE"]?.value)
        : "",
      umbrella_subrogration_liability: "",
      umbrella_subrogration_activity: "",
      umbrella_subrogration_status: "",
      umbrella_subrogration_policy_number: data
        ? data["POLICY DETAILS/2/POLICY NUMBER"]?.value
        : "",
      umbrella_subrogration_effective_date: data
        ? data["POLICY DETAILS/2/POLICY EFF"]?.value
        : "",
      umbrella_subrogration_expire_date: data
        ? data["POLICY DETAILS/2/POLICY EXP"]?.value
        : ""
    };
  }
}

function mapFieldCrime(recogData) {
  if (recogData?.version === currentRecogVersion) {
    const formData = getFormData(recogData, COVERAGE_KEY_CRIME);
    const { formArr, formValueList } = formData;
    const crimeClaim = getNumberField(formData?.crime_each_claim?.value);

    return {
      crime_each_claim: formValueList && formValueList.length > 0 ? formValueList[0] : crimeClaim || "",
      crime_third_party: "",
      crime_form_type: "",
      crime_policy_number: formArr?.properties?.policy_number?.value || formData?.crime_policy_number?.value || "",
      crime_effective_date: formArr?.properties?.effective_date?.value || formData?.crime_effective_date?.value || "",
      crime_expire_date: formArr?.properties?.exp_date?.value || formData?.crime_expire_date?.value || ""
    };
  } else {
    return {
      crime_each_claim: "",
      crime_third_party: "",
      crime_form_type: "",
      crime_policy_number: "",
      crime_effective_date: "",
      crime_expire_date: ""
    };
  }
}

// TODO: impliment when azure grabs collision data
// function garageLogic(combinedData) {
//   const garageData = {}
//   console.log("combinedData", combinedData)

//   if (combinedData?.garage_field_label?.value === "Garage Keepers - Comprehensive") {
//     garageData["garage_comp"] = combinedData?.garage_comp_coll_limit?.value || ""
//   }

//   if (combinedData?.im_field_label?.value === "Garage Keepers = Collision") {
//     garageData["garage_coll"] = combinedData?.garage_comp_coll_limit?.value || ""
//   }


// }

function mapFieldGarage(recogData) {
  if (recogData?.version === currentRecogVersion) {
    const formData = getFormData(recogData, COVERAGE_KEY_GARAGE + "_");
    const { formArr, formValueList } = formData;

    const garageCompColl = getNumberArray(formData?.garage_comp_coll_limit?.value);

    return {
      garage_comp: formValueList && formValueList.length > 0 ? formValueList[0] : garageCompColl || "",
      garage_coll: formValueList && formValueList.length > 0 ? formValueList[0] : garageCompColl || "",
      garage_direct: "",
      garage_policy_number: formArr?.properties?.policy_number?.value || formData?.garage_policy_number?.value || "",
      garage_effective_date:
        formArr?.properties?.effective_date?.value || formData?.garage_effective_date?.value || "",
      garage_expire_date: formArr?.properties?.exp_date?.value || formData?.garage_expire_date?.value || ""
    };
  } else {
    return {
      garage_comp: "",
      garage_coll: "",
      garage_direct: "",
      garage_policy_number: "",
      garage_effective_date: "",
      garage_expire_date: ""
    };
  }
}

function mapFieldEPLI(recogData) {
  if (recogData?.version === currentRecogVersion) {
    const formData = getFormData(recogData, COVERAGE_KEY_EPLI);
    const { formArr, formValueList } = formData;
    const epliOcc = getNumberField(formData?.epli_occ?.value);
    const epliAgg = getNumberField(formData?.epli_agg?.value);

    return {
      epli_occ: formValueList && formValueList.length > 0 ? formValueList[0] : epliOcc || "",
      epli_agg: formValueList && formValueList.length > 1 ? formValueList[1] : epliAgg || "",
      epli_third_party: "",
      epli_wage: "",
      epli_mx: "",
      epli_policy_number: formArr?.properties?.policy_number?.value || formData?.epli_policy_number?.value || "",
      epli_effective_date: formArr?.properties?.effective_date?.value || formData?.epli_effective_date?.value || "",
      epli_expire_date: formArr?.properties?.exp_date?.value || formData?.epli_expire_date?.value || ""
    };
  } else {
    return {
      epli_occ: "",
      epli_agg: "",
      epli_third_party: "",
      epli_wage: "",
      epli_mx: "",
      epli_policy_number: "",
      epli_effective_date: "",
      epli_expire_date: ""
    };
  }
}

function mapFieldCyber(recogData) {
  if (recogData?.version === currentRecogVersion) {
    const formData = getFormData(recogData, COVERAGE_KEY_CYBER);
    const { formArr, formValueList } = formData;
    const cyberOcc = getNumberField(formData?.cyber_occ?.value);
    const cyberAgg = getNumberField(formData?.cyber_agg?.value);
    const cyberDed = getNumberField(formData?.cyber_ded?.value);

    return {
      cyber_occ: formValueList && formValueList.length > 0 ? formValueList[0] : cyberOcc || "",
      cyber_agg: cyberAgg || "",
      cyber_ded: cyberDed || "",
      cyber_policy_number: formArr?.properties?.policy_number?.value || formData?.cyber_policy_number?.value || "",
      cyber_effective_date: formArr?.properties?.effective_date?.value || formData?.cyber_effective_date?.value || "",
      cyber_expire_date: formArr?.properties?.exp_date?.value || formData?.cyber_expire_date?.value || ""
    };
  } else {
    return {
      cyber_occ: "",
      cyber_agg: "",
      cyber_ded: "",
      cyber_policy_number: "",
      cyber_effective_date: "",
      cyber_expire_date: ""
    };
  }
}

function getTitleCoverage(title, coverageKey) {
  if (!title || title === "") {
    return false;
  }

  let titleList = [];
  switch (coverageKey) {
    case COVERAGE_KEY_PRO_LIAB:
      titleList = TITLE_PRO_LIAB;
      break;
    case COVERAGE_KEY_POL_LIAB:
      titleList = TITLE_POL_LIAB;
      break;
    case COVERAGE_KEY_IM:
      titleList = TITLE_IM;
      break;
    case COVERAGE_KEY_CRIME:
      titleList = TITLE_CRIME;
      break;
    case COVERAGE_KEY_GARAGE:
      titleList = TITLE_GARAGE;
      break;
    case COVERAGE_KEY_EPLI:
      titleList = TITLE_EPLI;
      break;
    case COVERAGE_KEY_CYBER:
      titleList = TITLE_CYBER;
      break;
  }

  const matchedTitle = titleList.find((titleItem) =>
    title.toLowerCase().includes(titleItem.toLowerCase())
  );

  if (matchedTitle) {
    return true;
  }

  return false;
}

function mapFieldProLiab(recogData) {
  if (recogData?.version === currentRecogVersion) {
    // format to get correct keys from recogData
    const formatedKey = COVERAGE_KEY_PRO_LIAB.split("_")[0] + "_";
    const formData = getFormData(recogData, formatedKey);
    const { formArr, formValueList } = formData;
    const proLiabOcc = getNumberField(formData?.pol_pro_liab_occ?.value);
    const proLiabAgg = getNumberField(formData?.pol_pro_liab_agg?.value);

    return {
      pro_liab_occ: formValueList && formValueList.length > 0 ? formValueList[0] : proLiabOcc || "",
      pro_liab_agg: formValueList && formValueList.length > 0 ? formValueList[0] : proLiabAgg || "",
      pro_liab_type: "",
      pro_liab_policy_number:
      formArr?.properties?.policy_number?.value || formData?.pol_pro_policy_number?.value || "",
      pro_liab_effective_date:
      formArr?.properties?.effective_date?.value || formData?.pol_pro_effective_date?.value || "",
      pro_liab_expire_date: formArr?.properties?.exp_date?.value || formData?.pol_pro_expiration_date?.value || ""
    };
  } else {
    return {
      pro_liab_occ: "",
      pro_liab_agg: "",
      pro_liab_type: "",
      pro_liab_policy_number: "",
      pro_liab_effective_date: "",
      pro_liab_expire_date: ""
    };
  }
}

function mapFieldPolLiab(recogData) {
  if (recogData?.version === currentRecogVersion) {
    const formatedKey = COVERAGE_KEY_POL_LIAB.split("_")[0] + "_";
    const formData = getFormData(recogData, formatedKey);
    const { formArr, formValueList } = formData;
    const polLiabOcc = getNumberField(formData?.pol_pro_liab_occ?.value);
    const polLiabAgg = getNumberField(formData?.pol_pro_liab_agg?.value);

    return {
      pol_liab_occ: formValueList && formValueList.length > 0 ? formValueList[0] : polLiabOcc || "",
      pol_liab_agg: formValueList && formValueList.length > 1 ? formValueList[1] : polLiabAgg || "",
      pol_liab_type: "",
      pol_liab_policy_number:
      formArr?.properties?.policy_number?.value || formData?.pol_pro_policy_number?.value || "",
      pol_liab_effective_date:
      formArr?.properties?.effective_date?.value || formData?.pol_pro_effective_date?.value || "",
      pol_liab_expire_date: formArr?.properties?.exp_date?.value || formData?.pol_pro_expiration_date?.value || ""
    };
  } else {
    return {
      pol_liab_occ: "",
      pol_liab_agg: "",
      pol_liab_type: "",
      pol_liab_policy_number: "",
      pol_liab_effective_date: "",
      pol_liab_expire_date: ""
    };
  }
}

function mapFieldIM(recogData) {

  if (recogData?.version === currentRecogVersion) {
    const formData = getFormData(recogData, COVERAGE_KEY_IM + "_");
    const { formArr, formValueList } = formData;
    const imLimit = getNumberField(formData?.im_limit?.value);

    return {
      im_limit: formValueList && formValueList.length > 0 ? formValueList[0] : imLimit || "",
      im_limit_per_item: "",
      im_business_interuption: formValueList && formValueList.length > 1 ? formValueList[1] : formData?.im_business_interuption?.value || "",
      im_policy_number: formArr?.properties?.policy_number?.value || formData?.im_policy_number?.value || "",
      im_effective_date: formArr?.properties?.effective_date?.value || formData?.im_effective_date?.value || "",
      im_expire_date: formArr?.properties?.exp_date?.value || formData?.im_expire_date?.value || ""
    };
  } else {
    return {
      im_limit: "",
      im_limit_per_item: "",
      im_business_interuption: "",
      im_policy_number: "",
      im_effective_date: "",
      im_expire_date: ""
    };
  }
}

function mapFieldProIns(recogData) {
  return {
    pro_ins_is_bus_per_pro: false,
    pro_ins_bus_per_pro: "",
    pro_ins_is_ten_imp: false,
    pro_ins_ten_imp: "",
    pro_ins_is_bui_amo: false,
    pro_ins_bui_amo: "",
    pro_ins_is_bus_int: false,
    pro_ins_bus_int: "",
    pro_ins_is_act_los: false,
    pro_ins_act_los: "",
    pro_ins_is_mon_lim: false,
    pro_ins_mon_lim: "",
    pro_ins_is_co_ins: false,
    pro_ins_co_ins: "",
    pro_ins_is_agg_val: false,
    pro_ins_agg_val: "",
    pro_ins_form_type: "",
    pro_ins_los_rec_type: "",
    pro_ins_policy_number: "",
    pro_ins_effective_date: "",
    pro_ins_expire_date: ""
  };
}

function mapFieldAdditionalInfo(recogData) {
  if (recogData?.version === currentRecogVersion) {
    return {
      additional_infor_certificate_holder:
        (recogData?.data?.additional_infor_certificate_holder?.value || "") +
        " " +
        (recogData?.data?.additional_infor_operation_description?.value || ""),
      additional_infor_operation_description:
        recogData?.data?.description_of_operations?.value || ""
    };
  } else {
    const data = recogData[0]?.fields;
    return {
      additional_infor_certificate_holder: data
        ? data["CERTIFICATE HOLDER"]?.value
        : "",
      additional_infor_operation_description:
        data && data["DESCRIPTION OF OPERATIONS"]
          ? data["DESCRIPTION OF OPERATIONS"].valueData.text
          : ""
    };
  }
}

function mapFieldCertHolderAddress(recogData) {
  if (recogData?.version === currentRecogVersion) {
    return recogData?.data?.additional_infor_operation_description?.value || "";
  } else {
    const data = recogData[0]?.fields;
    return data && data["DESCRIPTION OF OPERATIONS"]
      ? data["DESCRIPTION OF OPERATIONS"].valueData.text
      : "";
  }
}

function mapFieldInsuredInfo(recogData) {
  if (recogData?.version === currentRecogVersion) {
    return recogData?.data?.insured_info?.value || "";
  } else {
    const data = recogData[0]?.fields;
    return data && data["INSURED"] ? data["INSURED"].valueData.text : "";
  }
}

export {
  getGeneralLiability,
  getGeneralAggregate,
  mapFieldLiability,
  mapFieldAuto,
  mapFieldWC,
  mapFieldUmbrella,
  mapFieldCrime,
  mapFieldGarage,
  mapFieldEPLI,
  mapFieldCyber,
  mapFieldProLiab,
  mapFieldPolLiab,
  mapFieldIM,
  mapFieldProIns,
  mapFieldAdditionalInfo,
  mapFieldCertHolderAddress,
  mapFieldInsuredInfo
};
