import React, { useState } from "react";
import { Input, Loading } from "../../../../../components";
import { Button, Modal, notification, Select, Switch, Table } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import "./style.scss";
import { post } from "../../../../../utils/axios";
import { useAppState } from "../../../../../context";
import { useAuth0 } from "@auth0/auth0-react";
import { Option } from "antd/lib/mentions";
import { FRANCONNECT_FRANCHISEE_STATUS_ACTIVE } from "../../../../../assets/const/fim-integration";

export default function IntegrationFranconnect(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const [franchiseeListData, setFranchiseeListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isPlatform, setIsPlatform] = useState(false);
  const [franchisors, setFranchisors] = useState([]);
  const [selectedFranchisorName, setSelectedFranchisorName] = useState("");

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function onSelectChange(selectedRowKeysUpdate) {
    setSelectedRowKeys(selectedRowKeysUpdate);
  }

  function handleRemove() {
    const { data, dataIndex, setData } = props;
    data.splice(dataIndex, 1);
    setData([...data]);
  }

  async function handleTest() {
    const { data, dataIndex } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const param = {
        username: data[dataIndex].username,
        password: data[dataIndex].password,
        tenantID: data[dataIndex].tenantID,
        clientId: data[dataIndex].clientId,
        clientSecret: data[dataIndex].clientSecret,
        requirementId: franchisorAdmin._id,
      };
      if (franchisorAdmin?._id) {
        const result = await post(
          `franconnect/auth`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          notification["success"]({
            message: "Integration Success",
            description: "Integration is tested successfully",
            style: {
              background: "#f6ffed",
              border: "1px solid #b7eb8f",
            },
          });
        }
      }
    } catch (error) {
      notification["error"]({
        message: "Integration Test Fail",
        description: "Integration Test is failed",
        style: {
          background: "#fef2f0",
          border: "1px solid #fbccc7",
        },
      });
    }
    setLoading(false);
  }

  async function getFranconnectAuthToken(apiToken) {
    const { data, dataIndex } = props;
    let result = await post(
      `franconnect/auth`,
      {
        username: data[dataIndex].username,
        password: data[dataIndex].password,
        tenantID: data[dataIndex].tenantID,
        clientId: data[dataIndex].clientId,
        clientSecret: data[dataIndex].clientSecret,
        requirementId: franchisorAdmin._id,
      },
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    if (result.status !== 200) {
      throw new Error(
        "Failed to get Franconnect auth token: received a non-200 status"
      );
    }
    return result.data.data;
  }

  async function getFranconnectFranchisors(apiToken, franconnectAccessToken) {
    const { data, dataIndex } = props;
    let result = await post(
      `franconnect/franchisors`,
      {
        token: franconnectAccessToken,
        tenantID: data[dataIndex].tenantID,
      },
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    if (result.status !== 200) {
      throw new Error(
        "Failed to get Franconnect franchisors: received a non-200 status"
      );
    }
    return result.data.data;
  }

  async function getFranconnectFranchiseesForSelectedFranchisor(
    apiToken,
    franconnectAccessToken
  ) {
    const { data, dataIndex } = props;
    let result = await post(
      `franconnect/franchisees`,
      {
        token: franconnectAccessToken,
        tenantID: data[dataIndex].tenantID,
        franchisorName: selectedFranchisorName,
      },
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    if (result.status !== 200) {
      throw new Error(
        `Failed to get Franconnect franchisees for franchisor ${selectedFranchisorName}: received a non-200 status`
      );
    }
    return result.data.data;
  }

  async function handleLoadFranchisors() {
    setLoading(true);
    try {
      const apiToken = await getAccessTokenSilently();
      const { access_token } = await getFranconnectAuthToken(apiToken);
      const franchisors = await getFranconnectFranchisors(
        apiToken,
        access_token
      );
      if (franchisors.length > 0) {
        setSelectedFranchisorName(franchisors[0].brandName);
      }
      setFranchisors(franchisors);
    } catch (error) {
      notification["error"]({
        message: "Failed to load franchisors",
        description: error.message,
        duration: 3,
        style: {
          background: "#fef2f0",
          border: "1px solid #fbccc7",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleLoadFranchiseesForSelectedFranchisor() {
    setLoading(true);
    try {
      const apiToken = await getAccessTokenSilently();
      const { access_token } = await getFranconnectAuthToken(apiToken);
      const franchisees = await getFranconnectFranchiseesForSelectedFranchisor(
        apiToken,
        access_token
      );
      const activeFranchisees = franchisees
        .filter(
          (f) => f.franchiseeStatus === FRANCONNECT_FRANCHISEE_STATUS_ACTIVE
        )
        .map((f) => ({
          key: f.referenceId,
          franchisee_no: f.referenceId,
          franchisee_name: f.franchiseeName,
          is_store: f.storeTypeId ? "Y" : "N",
        }));
      setFranchiseeListData(activeFranchisees);
      setSelectedRowKeys(activeFranchisees.map((f) => f.key));
      setAccessToken(access_token);
      setIsOpen(true);
    } catch (error) {
      notification["error"]({
        message: "Failed to load franchisees",
        description: error.message,
        duration: 3,
        style: {
          background: "#fef2f0",
          border: "1px solid #fbccc7",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleLoadFranchisees() {
    const { data, dataIndex, setData } = props;
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      let result = await post(
        `franconnect/auth`,
        {
          username: data[dataIndex].username,
          password: data[dataIndex].password,
          tenantID: data[dataIndex].tenantID,
          clientId: data[dataIndex].clientId,
          clientSecret: data[dataIndex].clientSecret,
          requirementId: franchisorAdmin._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        let { access_token } = result.data.data;
        let resultFranchiseeList = await post(
          `franconnect/franchisee-list`,
          {
            token: access_token,
            tenantID: data[dataIndex].tenantID,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (resultFranchiseeList.status === 200) {
          const resultData = JSON.parse(
            JSON.stringify(Object.values(resultFranchiseeList.data.data))
          );
          if (resultData && resultData.length > 0) {
            for (let index = 0; index < resultData.length; index++) {
              resultData[index].key = resultData[index].franchisee_no;
            }
          }
          const resultSelectKeys = resultData.map((item) => item.key);
          setFranchiseeListData(resultData);
          setSelectedRowKeys(resultSelectKeys);
          setIsOpen(true);
          setAccessToken(access_token);
        } else {
          notification["error"]({
            message: "Failed",
            description:
              "Failed to fetch Franchisee. Please contact admin directly!",
            duration: 3,
            style: {
              background: "#fef2f0",
              border: "1px solid #fbccc7",
            },
          });
        }
      } else {
        notification["error"]({
          message: "Failed",
          description: "Invalid Credential",
          duration: 3,
          style: {
            background: "#fef2f0",
            border: "1px solid #fbccc7",
          },
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification["error"]({
        message: "Failed",
        description: "Invalid Credential",
        duration: 3,
        style: {
          background: "#fef2f0",
          border: "1px solid #fbccc7",
        },
      });
    }
  }

  function handleChange(key, value) {
    const { data, dataIndex, setData } = props;
    data[dataIndex][key] = value;
    setData([...data]);
  }

  async function handleImportFranchisee() {
    const { data, dataIndex } = props;
    setImportLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const franchiseeNo = selectedRowKeys.join(",");
      let resultFranchiseeDetail = await post(
        `franconnect/franchisee-detail`,
        {
          token: accessToken,
          tenantID: data[dataIndex].tenantID,
          requirementId: franchisorAdmin._id,
          franchiseeNo: franchiseeNo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setImportLoading(false);
      setIsOpen(false);
      // onNext();
    } catch (e) {
      setImportLoading(false);
    }
  }
  function handleSelectCancel() {
    setSelectedRowKeys([]);
  }

  const { data, dataIndex } = props;

  const columns = [
    {
      title: "Franchisee Name",
      dataIndex: "franchisee_name",
    },
    {
      title: "Franchisee No",
      dataIndex: "franchisee_no",
    },
    {
      title: "Saved",
      dataIndex: "is_store",
    },
  ];

  return (
    <div className="admin_franchisee_integration_detail">
      {expand && (
        <div className="admin_franchisee_integration_detail-form">
          <div
            className="admin_franchisee_integration_detail-form-header"
            onClick={() => setExpand(false)}
          >
            <div className="admin_franchisee_integration_detail-form-header-title">
              {data[dataIndex].platform}
            </div>
            <DownOutlined />
          </div>
          <div className="admin_franchisee_integration_detail-form-main">
            <div className="admin_franchisee_integration_detail-form-main-wrapper">
              <div className="admin_franchisee_integration_detail-form-main-wrapper-left">
                <Input
                  title="Tenant ID"
                  type="text"
                  value={data[dataIndex].tenantID}
                  onChange={(evt) => handleChange("tenantID", evt.target.value)}
                />
                <Input
                  title="User Name"
                  type="text"
                  value={data[dataIndex].username}
                  onChange={(evt) => handleChange("username", evt.target.value)}
                />
                <Input
                  title="Password"
                  type="password"
                  autocomplete="new-password"
                  onChange={(evt) => handleChange("password", evt.target.value)}
                />
                <div>
                  <p className="input-title">Disable Auto Sync</p>
                  <Switch
                    checked={data[dataIndex].autoSyncDisable}
                    onChange={(evt) => handleChange("autoSyncDisable", evt)}
                  ></Switch>
                </div>
              </div>
              <div className="admin_franchisee_integration_detail-form-main-wrapper-right">
                <Input
                  title="Client ID"
                  type="text"
                  value={data[dataIndex].clientId}
                  onChange={(evt) => handleChange("clientId", evt.target.value)}
                />
                <Input
                  title="Client Secret"
                  type="password"
                  autocomplete="new-password"
                  onChange={(evt) =>
                    handleChange("clientSecret", evt.target.value)
                  }
                />
                <div className="franchisor-selection-container">
                  <div className="is-platform-toggle-container">
                    <p className="input-title">Is Platform</p>
                    <Switch
                      checked={isPlatform}
                      onChange={(checked) => {
                        setIsPlatform(checked);
                      }}
                    />
                  </div>
                  {isPlatform && franchisors.length > 0 && (
                    <div style={{ width: "100%" }}>
                      <p className="input-title">Select Franchisor</p>
                      <Select
                        style={{ width: "100%" }}
                        value={selectedFranchisorName}
                        size="large"
                        onChange={(selected) => {
                          setSelectedFranchisorName(selected);
                        }}
                      >
                        {franchisors.map((f) => (
                          <Option value={f.brandName}>{f.brandName}</Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="admin_franchisee_integration_detail-form-main-footer">
              <Button onClick={handleTest}>Test</Button>
              {isPlatform && (
                <Button onClick={handleLoadFranchisors}>
                  Load Franchisors
                </Button>
              )}
              <Button
                onClick={
                  isPlatform && selectedFranchisorName
                    ? handleLoadFranchiseesForSelectedFranchisor
                    : handleLoadFranchisees
                }
                disabled={isPlatform && !selectedFranchisorName}
              >
                Import Franchisees
              </Button>
              <Button danger onClick={handleRemove}>
                Remove
              </Button>
            </div>
          </div>
        </div>
      )}
      {!expand && (
        <div
          className="admin_franchisee_integration_detail-preview"
          onClick={() => setExpand(true)}
        >
          <div className="admin_franchisee_integration_detail-preview-title">
            {data[dataIndex].platform}
          </div>
          <RightOutlined />
        </div>
      )}

      <Loading loading={loading} />
      <Modal
        title="Import Franchisees"
        open={isOpen}
        onOk={handleImportFranchisee}
        onCancel={() => setIsOpen(false)}
        className="admin_franchisee_integration_detail-modal"
        okText="Import"
      >
        <div className="admin_franchisee_integration_detail-modal-wrapper">
          <div className="admin_franchisee_integration_detail-modal-wrapper_header">
            {selectedRowKeys.length} items selected
            <a className="btn-cancel" onClick={handleSelectCancel}>
              Cancel
            </a>
          </div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={franchiseeListData}
          />
          <Loading loading={importLoading} />
        </div>
      </Modal>
    </div>
  );
}
