import React from "react";
import {
  COVERAGE_KEY_AUTO,
  COVERAGE_KEY_LIABILITY,
  COVERAGE_KEY_UMBRELLA,
  COVERAGE_KEY_WORKERS_COMP,
} from "../../../../assets/const/fim-integration";
import { Card, Input } from "../../../../components";
import "./style.scss";

function convertStatus(status) {
  switch (status.toLowerCase()) {
    case "eligible":
      return "Bindable";
    case "ineligible":
      return "Not Yet Bindable";
    default:
      return status;
  }
}

function LiabilityDetails(props) {
  return (
    <Card className="hartford_preview-detail" title="Liability">
      <div className="form-group-container">
        <Input
          title="Quote Identifier"
          type="text"
          value={props.quoteIdentifier}
          disabled
        />
        <Input
          title="Streamlined Quote Status"
          type="text"
          value={convertStatus(props.streamlinedQuoteStatus || "")}
          disabled
        />
      </div>
      <div className="form-group-container">
        <div className="form-group-container">
          <Input
            title="Policy Number"
            type="text"
            value={props.policyNumber}
            disabled
          />
          <Input
            title="Total Premium"
            type="text"
            value={props.totalPremium}
            disabled
          />
        </div>
        <div className="form-group-container">
          <Input
            title="BOPTypeCode"
            type="text"
            value={props.BOPTypeCode}
            disabled
          />
        </div>
      </div>
      <div>
        <Input
          title="Quote Access URL"
          type="text"
          value={props.quoteAccessURL}
          disabled
        />
      </div>
      <div>
        <label>
          <b>Coverage</b>
        </label>
        {(props.coverage || []).map((coverageItem) => (
          <div className="">
            <Input
              title="Coverage Code"
              type="text"
              value={coverageItem.coverageCode}
              disabled
            />
            {(coverageItem.limit || []).map((limitItem) => (
              <div className="form-group-container">
                <Input
                  title="Limit Amount"
                  type="text"
                  value={limitItem.limitAmount}
                  disabled
                />
                <Input
                  title="Limit Appliesto Code"
                  type="text"
                  value={limitItem.limitAppliestoCode}
                  disabled
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Card>
  );
}

function AutoDetails(props) {
  return (
    <Card className="hartford_preview-detail" title="Auto">
      <p>🔨 under construction 🔨</p>
    </Card>
  );
}

function WorkCompDetails(props) {
  return (
    <Card className="hartford_preview-detail" title="Workers Compensation">
      <div className="form-group-container">
        <Input
          title="Quote Identifier"
          type="text"
          value={props.quoteIdentifier}
          disabled
        />
        <Input
          title="Streamlined Quote Status"
          type="text"
          value={convertStatus(props.streamlinedQuoteStatus || "")}
          disabled
        />
      </div>
      <div className="form-group-container">
        <div className="form-group-container">
          <Input
            title="Policy Number"
            type="text"
            value={props.policyNumber}
            disabled
          />
          <Input
            title="Total Premium"
            type="text"
            value={props.totalPremium}
            disabled
          />
        </div>
        <div className="form-group-container">
          <Input
            title="Issuance EligibleInd"
            type="text"
            value={props.issuanceEligibleInd}
            disabled
          />
          <Input title="eMod" type="text" value={props.eMod} disabled />
        </div>
      </div>
      <div>
        <Input
          title="Quote Access URL"
          type="text"
          value={props.quoteAccessURL}
          disabled
        />
      </div>
      <div>
        <label>
          <b>Coverage</b>
        </label>
        {(props.coverage || []).map((coverageItem) => (
          <div className="">
            <Input
              title="Coverage Code"
              type="text"
              value={coverageItem.coverageCode}
              disabled
            />
            {(coverageItem.limit || []).map((limitItem) => (
              <div className="form-group-container">
                <Input
                  title="Limit Amount"
                  type="text"
                  value={limitItem.limitAmount}
                  disabled
                />
                <Input
                  title="Limit Appliesto Code"
                  type="text"
                  value={limitItem.limitAppliestoCode}
                  disabled
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Card>
  );
}

function UmbrellaDetails(props) {
  return (
    <Card className="hartford_preview-detail" title="Umbrella">
      <p>🔨 under construction 🔨</p>
    </Card>
  );
}

export default function HartfordPreview(props) {
  const liability = props[COVERAGE_KEY_LIABILITY];
  const auto = props[COVERAGE_KEY_AUTO];
  const workComp = props[COVERAGE_KEY_WORKERS_COMP];
  const umbrella = props[COVERAGE_KEY_UMBRELLA];

  return (
    <div className="hartford_preview">
      <h1>Hartford Quote</h1>
      {liability && <LiabilityDetails {...liability} />}
      {auto && <AutoDetails {...auto} />}
      {workComp && <WorkCompDetails {...workComp} />}
      {umbrella && <UmbrellaDetails {...umbrella} />}
    </div>
  );
}
