export default function PrivacyPolicy() {
  return (
    <div style={{ padding: 28 }}>
      <h1>1. Introduction:</h1>
      <p>
        Welcome to Rikor Insurance Consulting. At the heart of our commitment to
        serving our clients is our dedication to transparency and safeguarding
        the data you entrust with us. This privacy policy serves as a testament
        to our efforts in maintaining and securing personal and business data.
      </p>

      <p>
        Rikor Insurance Consulting specializes in providing cutting-edge
        solutions in the insurance industry. Our flagship product, the Risk
        Management Software (RMS), is designed to streamline insurance-related
        processes, offering a more efficient and reliable way of managing risks.
        As we pave the way for innovative insurance solutions, we remain
        steadfast in ensuring that our client's data privacy is never
        compromised.
      </p>

      <p>
        In this privacy policy, we lay out in detail the ways we collect, use,
        and protect your data while you interact with us and use our RMS
        platform. We invite you to read through, understand, and reach out if
        you have any concerns or questions. Your trust is invaluable to us, and
        we strive every day to deserve it.
      </p>

      <h2>2. Information Collection:</h2>
      <h3>a. Types of Data Collected:</h3>
      <ul>
        <li>
          At Rikor Insurance Consulting, our Risk Management Software (RMS) is
          designed to gather comprehensive data pertinent to commercial
          businesses and their owners. This may encompass but is not limited to,
          financial records, business operations, assets and liabilities,
          ownership structures, and other relevant business metrics.
        </li>
      </ul>

      <h3>b. Methods of Data Collection:</h3>
      <ul>
        <li>
          Directly from Users: During your use of the RMS, you may be prompted
          to input various pieces of information to provide a holistic view of
          your business landscape. This data is crucial in ensuring we can
          tailor our services and insights to your unique requirements.
        </li>
        <li>
          From Third-Party Software Vendors: We also integrate with select
          third-party software vendors to streamline the data collection
          process. This collaboration aids us in capturing a full-fledged view
          of your business, ensuring no stone is left unturned when it comes to
          assessing your insurance needs.
        </li>
      </ul>

      <h3>c. Purpose of Data Collection:</h3>
      <ul>
        <li>
          Insurance Policy Underwriting: Collecting detailed and accurate
          information is pivotal for the proper underwriting of insurance
          policies. It assists us in determining the risks associated with
          insuring your business and in subsequently calculating appropriate
          premium rates.
        </li>
        <li>
          Gap Analysis: Our commitment goes beyond just insuring your business.
          We strive to empower our users with insights that pinpoint areas where
          they might be uninsured or under-insured. By meticulously analyzing
          the data provided, we can highlight potential vulnerabilities and
          guide you towards making informed decisions for optimal coverage.
        </li>
      </ul>

      <h2>3. Information Use:</h2>
      <h3>a. Purpose of Data Utilization:</h3>
      <ul>
        <li>
          Managing Risk:
          <p>
            Our primary use of the collected data is centered around risk
            management. We deploy sophisticated algorithms and industry insights
            to constantly monitor insurance policies, ensuring that our clients
            remain protected and aware of their coverage at all times.
          </p>
        </li>
        <li>
          Monitoring Insurance:
          <p>
            Leveraging our Risk Management Software (RMS), we proactively track
            the status and specifics of our clients' insurance policies. This
            meticulous tracking allows us to identify and alert clients to
            imminent policy expirations or changes that might affect their
            coverage.
          </p>
        </li>
        <li>
          Identifying Exposure:
          <p>
            Our commitment to safeguarding your interests goes beyond mere
            monitoring. By analyzing the collected data, RMS pinpoints instances
            where clients may face unintentional exposure due to lapses or gaps
            in their insurance coverage. Such proactive identification aids in
            preventing potential setbacks and ensuring continuous protection.
          </p>
        </li>
      </ul>

      <h3>b. Legal Basis for Processing:</h3>
      <ul>
        <li>
          The foundation of our data utilization process is rooted in the core
          purpose of Rikor Insurance Consulting: to substantially reduce risk
          for our clientele. By harnessing the power of technology, we've
          devised a system to monitor insurance policies at scale, providing our
          customers with peace of mind. This endeavor not only aligns with but
          also reinforces our organizational objectives, making it the legal and
          essential bedrock for our operations.
        </li>
      </ul>

      <h2>4. Information Sharing & Disclosure:</h2>
      <h3>a. Internal Sharing:</h3>
      <ul>
        <li>
          Brokers and Consultants: Our dedicated team of brokers and consultants
          have access to the collected data. This access allows them to perform
          in-depth risk analyses, ensuring that the insurance solutions
          recommended to our clients are both pertinent and robust. Their
          expertise combined with the insights from the data helps in
          custom-tailoring insurance strategies that align with individual
          client needs.
        </li>
        <li>
          Carriers: As part of our process in offering comprehensive insurance
          solutions, data is shared with insurance carriers. This ensures they
          have the requisite information to adequately assess the associated
          risks of insuring a particular business or entity.
        </li>
      </ul>

      <h3>b. Purpose of Sharing with Carriers:</h3>
      <ul>
        <li>
          Risk Assessment: Our affiliated insurance carriers utilize the data to
          gain a comprehensive understanding of the risk profile associated with
          each client. This is paramount in ensuring the accuracy and
          reliability of their risk assessment procedures.
        </li>
        <li>
          Quotation: Based on the evaluated risk parameters, carriers can
          determine appropriate premium rates and provide accurate quotes for
          insurance coverage. This transparency and precision play a crucial
          role in establishing trust and clarity in our client relationships.
        </li>
      </ul>

      <h3>c. Commitment to Privacy:</h3>
      <ul>
        <li>
          We take our responsibility of safeguarding your data seriously. While
          sharing is intrinsic to the services we provide, it's done with utmost
          care and always in line with the objective of enhancing our offerings
          to you. Only authorized personnel and entities with a legitimate
          business purpose can access the data, and they are bound by strict
          confidentiality obligations.
        </li>
      </ul>

      <h2>5. Cookies & Tracking:</h2>
      <h3>a. What are Cookies?:</h3>
      <ul>
        <li>
          Cookies are small text files that are stored on your device (computer,
          smartphone, tablet, etc.) when you visit certain web pages. They are
          used to remember information about your visit and ensure a consistent
          and efficient user experience. Cookies can be session-based (expiring
          when you close your browser) or persistent (remaining on your device
          for a set period or until you delete them).
        </li>
      </ul>

      <h3>b. How We Use Cookies:</h3>
      <ul>
        <li>
          Performance and Analytics: We use cookies to analyze how users
          interact with our website, which helps us improve its functionality
          and performance.
        </li>
        <li>
          Preferences: Cookies help us remember your settings and preferences,
          like language choice or login details, for a more personalized
          experience.
        </li>
        <li>
          Security: We use cookies to support and enhance security features on
          our platform, ensuring the safety of user data.
        </li>
      </ul>

      <h3>c. Web Beacons and Other Tracking Technologies:</h3>
      <ul>
        <li>
          Beyond cookies, we may also use web beacons, pixel tags, and other
          tracking technologies. These help us better understand user behavior,
          inform us about which parts of our website people have visited, and
          facilitate and measure the effectiveness of advertisements and web
          searches.
        </li>
      </ul>

      <h3>d. Third-Party Cookies:</h3>
      <ul>
        <li>
          Some cookies we use come from third-party providers, such as Google
          Analytics. These cookies provide us with insights about website
          performance and user interactions and help us improve our services.
        </li>
      </ul>

      <h3>e. Managing Cookies:</h3>
      <ul>
        <li>
          Most web browsers allow you to manage cookies. You can set your
          browser to block cookies or alert you when they are being used.
          However, if you disable cookies, certain parts of our website might
          not function as intended. For more information on managing cookies,
          please refer to your browser's help documentation.
        </li>
      </ul>

      <h3>f. Changes to Our Cookie Use:</h3>
      <ul>
        <li>
          We may update our use of cookies, web beacons, and similar
          technologies from time to time. When we do, we'll update this section
          to reflect any changes in our practices.
        </li>
      </ul>

      <h2>6. Data Retention:</h2>
      <p>
        At Rikor Insurance Consulting, our approach to data retention is rooted
        in a balance between maintaining essential information to serve our
        clients and upholding our commitment to data privacy and protection.
      </p>

      <h3>1. General Retention Period:</h3>
      <ul>
        <li>
          While we endeavor to retain client data for a period not exceeding
          five years, this is an intention rather than a commitment. This means
          that data might be stored for shorter or longer durations based on our
          operational, legal, or client-specific requirements.
        </li>
      </ul>

      <h3>2. Criteria for Data Retention and Deletion:</h3>
      <ul>
        <li>
          The specific criteria governing the duration for which we retain data
          remains fluid. Decisions surrounding data retention or deletion are
          made at our discretion, based on a combination of business needs,
          legal requirements, and the evolving landscape of data management best
          practices.
        </li>
        <li>
          It's worth noting that we reserve the right to retain or delete data
          based on our internal evaluations or upon specific client requests.
          Clients are encouraged to reach out should they have particular data
          management needs or concerns.
        </li>
      </ul>

      <h3>3. No Set Timeline for Deletion:</h3>
      <ul>
        <li>
          Rikor does not commit to erasing data at any predetermined timeframe.
          This flexibility ensures that we can adapt to the dynamic needs of our
          clientele and the insurance industry, always with an emphasis on
          delivering excellence in our services.
        </li>
      </ul>

      <h3>4. Client's Discretion:</h3>
      <ul>
        <li>
          We acknowledge and respect the rights of our clients regarding their
          data. Clients can request access to their data, seek modifications, or
          even request deletions in line with applicable regulations and our
          policies.
        </li>
      </ul>

      <p>
        While the exact durations and criteria may vary, our core principle
        remains constant: to handle every piece of data with the utmost care and
        responsibility, ensuring it serves its intended purpose and nothing
        more.
      </p>

      <h2>7. Security Measures:</h2>
      <p>
        At Rikor Insurance Consulting, we prioritize the protection and security
        of our client's data. To achieve this, we leverage state-of-the-art
        technologies and services renowned for their security measures.
      </p>

      <h3>1. Amazon Web Services (AWS):</h3>
      <ul>
        <li>
          a. Infrastructure: AWS offers a robust, scalable, and secure
          infrastructure platform. Their global network of data centers is
          designed to prevent unauthorized access and safeguard customer data.
        </li>
        <li>
          b. Encryption: AWS deploys a multi-layered encryption mechanism. Data
          is encrypted in transit using protocols such as TLS (Transport Layer
          Security). For data at rest, AWS uses advanced algorithms to ensure
          that data remains protected on all their storage devices and services.
        </li>
        <li>
          c. Access Control: AWS offers fine-grained access controls that allow
          us to define who can access our data and what actions they can carry
          out. Using tools like AWS Identity and Access Management (IAM), we
          ensure only authorized personnel can access specific data.
        </li>
        <li>
          d. Regular Audits: AWS data centers and services undergo regular
          third-party security audits. These audits verify the efficacy of their
          security measures and ensure compliance with industry standards.
        </li>
      </ul>

      <h3>2. Auth0:</h3>
      <ul>
        <li>
          a. Authentication: Auth0 offers a secure, extensible authentication
          and authorization platform. It provides a variety of protocols and
          methods to ensure users are who they say they are.
        </li>
        <li>
          b. Single Sign-On (SSO): Using SSO, users can securely log in once and
          gain access to various applications without needing to
          re-authenticate.
        </li>
        <li>
          c. Multi-Factor Authentication (MFA): Auth0's MFA adds an additional
          layer of security, requiring users to provide two or more verification
          factors to gain access.
        </li>
        <li>
          d. Breached Password Detection: Auth0 continuously monitors for
          exposed usernames and passwords from known breaches and can alert
          users and block suspicious attempts.
        </li>
        <li>
          e. End-to-End Security: Auth0 follows security best practices
          throughout its service, from consistent patching of vulnerabilities to
          employing high-level encryption standards.
        </li>
      </ul>

      <p>
        By integrating these advanced technologies, we can offer a security
        framework that not only safeguards our client's data but also ensures a
        seamless and secure user experience. We remain committed to continuously
        monitoring and adapting our security measures in line with technological
        advancements and industry standards.
      </p>

      <h2>8. User Rights:</h2>
      <p>
        At Rikor Insurance Consulting, we acknowledge and respect the rights of
        our users concerning their data. Our philosophy aligns with prevailing
        software norms, ensuring users can access, modify, and delete their
        data.
      </p>

      <h3>1. Access:</h3>
      <ul>
        <li>
          a. Users have the right to request access to their personal and
          business data stored within our systems. This ensures transparency and
          lets users understand the scope of data we hold pertaining to them.
        </li>
      </ul>

      <h3>2. Modification:</h3>
      <ul>
        <li>
          a. We believe in empowering our users to have control over their data.
          Users can modify or rectify their data whenever necessary. However,
          certain circumstantial limitations may arise, not from deliberate
          constraints imposed by us but due to the inherent boundaries of the
          software.
        </li>
      </ul>

      <h3>3. Deletion:</h3>
      <ul>
        <li>
          a. Users retain the right to request the deletion of their data. While
          we strive to honor such requests, there might be scenarios where
          complete data deletion could compromise the integrity and reliability
          of the overall dataset. In such cases, we may retain select data
          points essential for maintaining data accuracy.
        </li>
      </ul>

      <h3>4. Data Integrity and Dependability:</h3>
      <ul>
        <li>
          a. The primary constraint on modifying or deleting data arises from
          our commitment to data integrity. We want to ensure that the data
          remains a true reflection of our client's business, thereby making it
          a reliable and useful asset. This fidelity to data accuracy ensures
          that our risk assessments and services align closely with the client's
          actual business landscape.
        </li>
      </ul>

      <h3>5. No Deliberate Limitations:</h3>
      <ul>
        <li>
          a. It's worth emphasizing that we do not place deliberate limitations
          on a user's right to modify their data. Any limitations in place are
          solely to maintain the trustworthiness and accuracy of the data,
          ensuring our services remain top-notch and relevant to our clients.
        </li>
      </ul>

      <p>
        We encourage our users to reach out with any concerns or queries about
        their rights and our practices. Your trust is paramount to us, and we
        remain steadfast in upholding your rights to the fullest extent
        possible.
      </p>

      <h2>9. International Data Transfers:</h2>
      <p>
        At Rikor Insurance Consulting, our primary commitment is to the security
        and integrity of our user data, especially in the broader context of
        international data movements.
      </p>

      <h3>1. No Active Data Transfers:</h3>
      <ul>
        <li>
          a. Currently, we do not engage in active transfers of user data beyond
          the borders of North America. Our primary data storage and processing
          facilities are situated within the United States, ensuring that data
          is managed under a consistent and stringent standard of protection.
        </li>
      </ul>

      <h3>2. Browser-Based Accessibility:</h3>
      <ul>
        <li>
          a. Our software, being browser-based, allows for global accessibility.
          This means that users or authorized personnel could access their data
          from any location worldwide with an internet connection. While this
          does provide global reach, it could also lead to passive international
          data transfer due to user actions.
        </li>
      </ul>

      <h3>3. Robust Privacy Safeguards:</h3>
      <ul>
        <li>
          a. Despite our software's global reach, we've put in place privacy
          measures that align with the recognized standards for data protection
          within North America. Our adherence to these standards ensures that,
          regardless of where the data might be accessed, the protection level
          remains consistent and rigorous.
        </li>
      </ul>

      <h3>4. Standard Contractual Clauses:</h3>
      <ul>
        <li>
          a. As part of our commitment to data security, we abide by standard
          contractual clauses that uphold North American standards of data
          protection. These clauses serve as a testament to our dedication in
          ensuring any potential international data handling meets the high
          protection levels that our users expect.
        </li>
      </ul>

      <h3>5. User Responsibility:</h3>
      <ul>
        <li>
          a. Users who access their data from regions outside of North America
          should be cognizant of their local data protection regulations and
          ensure compliance. While we extend comprehensive tools and measures
          for data security, the onus of adhering to regional norms when
          accessing data falls upon the user.
        </li>
      </ul>

      <p>
        In an age where data accessibility knows few boundaries, Rikor Insurance
        Consulting stands firm in its resolve to protect and maintain the
        sanctity of its user data, regardless of geography.
      </p>

      <h2>10. Children’s Privacy:</h2>
      <ul>
        <li>
          a. Rikor Insurance Consulting's software is specifically designed for
          business applications and is not intended for use by anyone under the
          age of 18. The nature and function of our platform make it unsuitable
          and illogical for children.
        </li>
        <li>
          b. We do not knowingly collect or solicit personal data from children
          or allow such individuals to register for our software. Should we
          become aware that a child has entered data into our system, we will
          promptly investigate the situation. Our dedicated team will undertake
          the necessary steps to address and resolve the incident, which
          includes the removal of such data.
        </li>
        <li>
          c. Parents or guardians who believe that their child has provided us
          with personal information are urged to contact us immediately. We are
          committed to working collaboratively to ensure the privacy and
          security of every user, especially minors.
        </li>
      </ul>

      <h2>11. Changes to the Privacy Policy:</h2>
      <p>
        At Rikor Insurance Consulting, we continually strive to improve our
        services and adapt to an evolving digital landscape. As such, we reserve
        the right to update this Privacy Policy at our discretion.
      </p>

      <h3>Notification of Changes:</h3>
      <ul>
        <li>
          a. All users will be notified of any changes to the Privacy Policy
          upon their next login to our platform. It's essential to periodically
          review this policy to stay informed about how we're protecting your
          data.
        </li>
      </ul>

      <h3>Significant Changes:</h3>
      <ul>
        <li>
          a. For more substantial policy alterations or those that significantly
          affect user rights and data usage, we will prominently highlight and
          communicate these changes. We are committed to ensuring our users are
          fully informed and can make educated decisions regarding the continued
          use of our platform.
        </li>
      </ul>

      <p>
        We recommend our users always to stay updated with our Privacy Policy,
        ensuring a thorough understanding of how we manage and protect your
        data.
      </p>

      <h2>12. Contact Information:</h2>
      <p>
        At Rikor Insurance Consulting, we value transparency and strive to
        provide comprehensive information within our Privacy Policy. To ensure
        that your privacy-related queries are addressed effectively, we
        encourage all users to thoroughly review our Privacy Policy, which is
        designed to be clear and informative.
      </p>

      <p>
        Should you have any concerns or require clarification on specific points
        within the policy, we kindly request that you first consult the Privacy
        Policy itself. We believe that most questions will be resolved through a
        careful reading of the provided information.
      </p>

      <p>
        However, if you require further assistance or have concerns not
        addressed in this Privacy Policy, you may reach out to us at:
      </p>

      <p>
        Rikor Insurance Consulting
        <br />
        123 Privacy Lane
        <br />
        Confidential City, CA 98765
        <br />
        privacy@rikorinsurance.com
        <br />
        (123) 456-7890
      </p>

      <p>
        Our dedicated team is here to assist you with any privacy-related
        inquiries or concerns you may have.
      </p>

      <p>Last Updated: September 10, 2023</p>
    </div>
  );
}
