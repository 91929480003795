import React, { useState } from "react";
import { Table, InputNumber, Input, Form } from "antd";
import { SectionTable } from "../../../../../components/insurance-requirements";
import {
  FORM_CLAIMS_MADE,
  FORM_DEDUCTIBLE,
  FORM_EXCESS_LIABILITY,
  FORM_LOC,
  FORM_NONE,
  FORM_OCCURRENCE,
  FORM_POLICY,
  FORM_PROJECT,
  FORM_RETENTION,
  FORM_UMBRELLA_LIABILITY,
} from "../../../../../assets/const/form";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppState } from "../../../../../context";
import { put, post } from "../../../../../utils/axios";

export default function InsuranceRequirementsTable(props) {
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  function handleSave(values, key) {
    const formVal = {};
    values.forEach((item) => {
      formVal[item.key] = item.required;
      formVal[`${item.key}_current`] = item.current;
      formVal[`${item.key}_suggested`] = item.suggested;
      formVal[`${item.key}_quote`] = item.quote;
    });
    handleFormSubmit(formVal);
  }

  async function handleFormSubmit(formVal) {
    try {
      const token = await getAccessTokenSilently();
      if (franchisorAdmin?._id) {
        const result = await put(
          `requirement/${franchisorAdmin._id}`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          setFranchisorAdmin(result.data.data);
        }
      }
    } catch (e) {}
  }

  return (
    <div>
      <SectionTable
        initDataSource={[
          {
            coverage: "Each Occurrence",
            field_type: "String",
            response_limit: "Integer",
            required:
              franchisorAdmin.commercial_general_liability_each_occurance,
            current:
              franchisorAdmin.commercial_general_liability_each_occurance_current,
            suggested:
              franchisorAdmin.commercial_general_liability_each_occurance_suggested,
            quote:
              franchisorAdmin.commercial_general_liability_each_occurance_quote,
            key: "commercial_general_liability_each_occurance",
          },
          {
            coverage: "Damage to Rented Premises",
            field_type: "String",
            response_limit: "Integer",
            required:
              franchisorAdmin.commercial_general_liability_damage_rented_premises,
            current:
              franchisorAdmin.commercial_general_liability_damage_rented_premises_current,
            suggested:
              franchisorAdmin.commercial_general_liability_damage_rented_premises_suggested,
            quote:
              franchisorAdmin.commercial_general_liability_damage_rented_premises_quote,
            key: "commercial_general_liability_damage_rented_premises",
          },
          {
            coverage: "Medical Expenses",
            field_type: "String",
            response_limit: "Integer",
            required:
              franchisorAdmin.commercial_general_liability_medical_expenses,
            current:
              franchisorAdmin.commercial_general_liability_medical_expenses_current,
            suggested:
              franchisorAdmin.commercial_general_liability_medical_expenses_suggested,
            quote:
              franchisorAdmin.commercial_general_liability_medical_expenses_quote,
            key: "commercial_general_liability_medical_expenses",
          },
          {
            coverage: "Personal & Advertising Injury",
            field_type: "String",
            response_limit: "Integer",
            required:
              franchisorAdmin.commercial_general_liability_personal_adv_injury,
            current:
              franchisorAdmin.commercial_general_liability_personal_adv_injury_current,
            suggested:
              franchisorAdmin.commercial_general_liability_personal_adv_injury_suggested,
            quote:
              franchisorAdmin.commercial_general_liability_personal_adv_injury_quote,
            key: "commercial_general_liability_personal_adv_injury",
          },
          {
            coverage: "Products / Completed Operations Aggregate",
            field_type: "String",
            response_limit: "Integer",
            required: franchisorAdmin.commercial_general_liability_comprop_acg,
            current:
              franchisorAdmin.commercial_general_liability_comprop_acg_current,
            suggested:
              franchisorAdmin.commercial_general_liability_comprop_acg_suggested,
            quote:
              franchisorAdmin.commercial_general_liability_comprop_acg_quote,
            key: "commercial_general_liability_comprop_acg",
          },
          {
            coverage: "General Aggregate",
            field_type: "String",
            response_limit: "Integer",
            required:
              franchisorAdmin.commercial_general_liability_general_aggregate,
            current:
              franchisorAdmin.commercial_general_liability_general_aggregate_current,
            suggested:
              franchisorAdmin.commercial_general_liability_general_aggregate_suggested,
            quote:
              franchisorAdmin.commercial_general_liability_general_aggregate_quote,
            key: "commercial_general_liability_general_aggregate",
          },
          {
            coverage: "Commecial General Liability",
            field_type: "Enum",
            response_limit: "Select",
            required: franchisorAdmin.commercial_general_policy,
            current: franchisorAdmin.commercial_general_policy_current,
            suggested: franchisorAdmin.commercial_general_policy_suggested,
            quote: franchisorAdmin.commercial_general_policy_quote,
            key: "commercial_general_policy",
            options: [
              {
                value: FORM_OCCURRENCE,
                label: "Occurrence",
              },
              {
                value: FORM_CLAIMS_MADE,
                label: "Claims Made",
              },
            ],
          },
          {
            coverage: "Gen”L Aggregate Limit Applies Per",
            field_type: "Enum",
            response_limit: "Select",
            required: franchisorAdmin.commercial_general_aggregate_limit,
            current: franchisorAdmin.commercial_general_aggregate_limit_current,
            suggested:
              franchisorAdmin.commercial_general_aggregate_limit_suggested,
            quote: franchisorAdmin.commercial_general_aggregate_limit_quote,
            key: "commercial_general_aggregate_limit",
            options: [
              {
                value: FORM_POLICY,
                label: "Policy",
              },
              {
                value: FORM_PROJECT,
                label: "Project",
              },
              {
                value: FORM_LOC,
                label: "Loc",
              },
            ],
          },
          {
            coverage: "Additional Insured",
            field_type: "Boolean",
            response_limit: "True",
            required:
              franchisorAdmin.commercial_general_liability_additional_insurance,
            current:
              franchisorAdmin.commercial_general_liability_additional_insurance_current,
            suggested:
              franchisorAdmin.commercial_general_liability_additional_insurance_suggested,
            quote:
              franchisorAdmin.commercial_general_liability_additional_insurance_quote,
            key: "commercial_general_liability_additional_insurance",
          },
          {
            coverage: "Waiver of Subrogation",
            field_type: "Boolean",
            response_limit: "True",
            required:
              franchisorAdmin.commercial_general_liability_subrogration_waved,
            current:
              franchisorAdmin.commercial_general_liability_subrogration_waved_current,
            suggested:
              franchisorAdmin.commercial_general_liability_subrogration_waved_suggested,
            quote:
              franchisorAdmin.commercial_general_liability_subrogration_waved_quote,
            key: "commercial_general_liability_subrogration_waved",
          },
        ]}
        tableLabel="General Liability"
        onSave={handleSave}
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Combined Single Limit",
            field_type: "String",
            response_limit: "Integer",
            required:
              franchisorAdmin.automotive_liability_combined_single_limit,
            current:
              franchisorAdmin.automotive_liability_combined_single_limit_current,
            suggested:
              franchisorAdmin.automotive_liability_combined_single_limit_suggested,
            quote:
              franchisorAdmin.automotive_liability_combined_single_limit_quote,
            key: "automotive_liability_combined_single_limit",
          },
          {
            coverage: "Maximum Required Deductible",
            field_type: "String",
            response_limit: "Integer",
            required: franchisorAdmin.automotive_liability_bodily_injury_pp,
            current:
              franchisorAdmin.automotive_liability_bodily_injury_pp_current,
            suggested:
              franchisorAdmin.automotive_liability_bodily_injury_pp_suggested,
            quote: franchisorAdmin.automotive_liability_bodily_injury_pp_quote,
            key: "automotive_liability_bodily_injury_pp",
          },
          {
            coverage: "Any Auto",
            field_type: "Boolean",
            response_limit: "True",
            required: franchisorAdmin.automotive_liability_any_auto,
            current: franchisorAdmin.automotive_liability_any_auto_current,
            suggested: franchisorAdmin.automotive_liability_any_auto_suggested,
            quote: franchisorAdmin.automotive_liability_any_auto_quote,
            key: "automotive_liability_any_auto",
          },
          {
            coverage: "Owned Auto",
            field_type: "Boolean",
            response_limit: "True",
            required: franchisorAdmin.automotive_liability_all_owned_autos,
            current:
              franchisorAdmin.automotive_liability_all_owned_autos_current,
            suggested:
              franchisorAdmin.automotive_liability_all_owned_autos_suggested,
            quote: franchisorAdmin.automotive_liability_all_owned_autos_quote,
            key: "automotive_liability_all_owned_autos",
          },
          {
            coverage: "Hired Auto",
            field_type: "Boolean",
            response_limit: "True",
            required: franchisorAdmin.automotive_liability_category_hired_autos,
            current:
              franchisorAdmin.automotive_liability_category_hired_autos_current,
            suggested:
              franchisorAdmin.automotive_liability_category_hired_autos_suggested,
            quote:
              franchisorAdmin.automotive_liability_category_hired_autos_quote,
            key: "automotive_liability_category_hired_autos",
          },
          {
            coverage: "Scheduled Autos",
            field_type: "Boolean",
            response_limit: "True",
            required:
              franchisorAdmin.automotive_liability_category_scheduled_autos,
            current:
              franchisorAdmin.automotive_liability_category_scheduled_autos_current,
            suggested:
              franchisorAdmin.automotive_liability_category_scheduled_autos_suggested,
            quote:
              franchisorAdmin.automotive_liability_category_scheduled_autos_quote,
            key: "automotive_liability_category_scheduled_autos",
          },
          {
            coverage: "Non-Owned Auto",
            field_type: "Boolean",
            response_limit: "True",
            required:
              franchisorAdmin.automotive_liability_category_non_owned_autos,
            current:
              franchisorAdmin.automotive_liability_category_non_owned_autos_current,
            suggested:
              franchisorAdmin.automotive_liability_category_non_owned_autos_suggested,
            quote:
              franchisorAdmin.automotive_liability_category_non_owned_autos_quote,
            key: "automotive_liability_category_non_owned_autos",
          },
          {
            coverage: "Additional Insured",
            field_type: "Boolean",
            response_limit: "True",
            required:
              franchisorAdmin.automotive_policy_form_additional_insurance,
            current:
              franchisorAdmin.automotive_policy_form_additional_insurance_current,
            suggested:
              franchisorAdmin.automotive_policy_form_additional_insurance_suggested,
            quote:
              franchisorAdmin.automotive_policy_form_additional_insurance_quote,
            key: "automotive_policy_form_additional_insurance",
          },
          {
            coverage: "Waiver of Subrogation",
            field_type: "Boolean",
            response_limit: "True",
            required:
              franchisorAdmin.automotive_policy_form_subrogratrion_waived,
            current:
              franchisorAdmin.automotive_policy_form_subrogratrion_waived_current,
            suggested:
              franchisorAdmin.automotive_policy_form_subrogratrion_waived_suggested,
            quote:
              franchisorAdmin.automotive_policy_form_subrogratrion_waived_quote,
            key: "automotive_policy_form_subrogratrion_waived",
          },
        ]}
        tableLabel="FRANCHISEE OWNED COMMERCIAL AUTO"
        onSave={handleSave}
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Each Accident",
            field_type: "String",
            response_limit: "Integer",
            required: franchisorAdmin.workers_compensation_el_each_accident,
            current:
              franchisorAdmin.workers_compensation_el_each_accident_current,
            suggested:
              franchisorAdmin.workers_compensation_el_each_accident_suggested,
            quote: franchisorAdmin.workers_compensation_el_each_accident_quote,
            key: "workers_compensation_el_each_accident",
          },
          {
            coverage: "Each Employee",
            field_type: "String",
            response_limit: "Integer",
            required:
              franchisorAdmin.workers_compensation_other_el_disease_each_employer,
            current:
              franchisorAdmin.workers_compensation_other_el_disease_each_employer_current,
            suggested:
              franchisorAdmin.workers_compensation_other_el_disease_each_employer_suggested,
            quote:
              franchisorAdmin.workers_compensation_other_el_disease_each_employer_quote,
            key: "workers_compensation_other_el_disease_each_employer",
          },
          {
            coverage: "Policy Limit",
            field_type: "String",
            response_limit: "Integer",
            required:
              franchisorAdmin.workers_compensation_el_disease_policy_limit,
            current:
              franchisorAdmin.workers_compensation_el_disease_policy_limit_current,
            suggested:
              franchisorAdmin.workers_compensation_el_disease_policy_limit_suggested,
            quote:
              franchisorAdmin.workers_compensation_el_disease_policy_limit_quote,
            key: "workers_compensation_el_disease_policy_limit",
          },
          {
            coverage: "Waiver of Subrogation",
            field_type: "Boolean",
            response_limit: "True",
            required: franchisorAdmin.workers_compensation_subrogration_waved,
            current:
              franchisorAdmin.workers_compensation_subrogration_waved_current,
            suggested:
              franchisorAdmin.workers_compensation_subrogration_waved_suggested,
            quote:
              franchisorAdmin.workers_compensation_subrogration_waved_quote,
            key: "workers_compensation_subrogration_waved",
          },
        ]}
        tableLabel="WORKERS COMPENSATION & EMPLOYERS LIABILITY"
        onSave={handleSave}
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Each Occurrence",
            field_type: "String",
            response_limit: "Integer",
            required: franchisorAdmin.umbrella_subrogration_each_occurance,
            current:
              franchisorAdmin.umbrella_subrogration_each_occurance_current,
            suggested:
              franchisorAdmin.umbrella_subrogration_each_occurance_suggested,
            quote: franchisorAdmin.umbrella_subrogration_each_occurance_quote,
            key: "umbrella_subrogration_each_occurance",
          },
          {
            coverage: "Aggregate",
            field_type: "String",
            response_limit: "Integer",
            required: franchisorAdmin.umbrella_subrogration_aggregate,
            current: franchisorAdmin.umbrella_subrogration_aggregate_current,
            suggested:
              franchisorAdmin.umbrella_subrogration_aggregate_suggested,
            quote: franchisorAdmin.umbrella_subrogration_aggregate_quote,
            key: "umbrella_subrogration_aggregate",
          },
          {
            coverage: "Liability",
            field_type: "Enum",
            response_limit: "Select",
            required: franchisorAdmin.umbrella_subrogration_liability,
            current: franchisorAdmin.umbrella_subrogration_liability_current,
            suggested:
              franchisorAdmin.umbrella_subrogration_liability_suggested,
            quote: franchisorAdmin.umbrella_subrogration_liability_quote,
            key: "umbrella_subrogration_liability",
            options: [
              {
                value: FORM_UMBRELLA_LIABILITY,
                label: "Umbrella Liability",
              },
              {
                value: FORM_EXCESS_LIABILITY,
                label: "Excess Liability",
              },
            ],
          },
          {
            coverage: "Activity",
            field_type: "Enum",
            response_limit: "Select",
            required: franchisorAdmin.umbrella_subrogration_activity,
            current: franchisorAdmin.umbrella_subrogration_activity_current,
            suggested: franchisorAdmin.umbrella_subrogration_activity_suggested,
            quote: franchisorAdmin.umbrella_subrogration_activity_quote,
            key: "umbrella_subrogration_activity",
            options: [
              {
                value: FORM_OCCURRENCE,
                label: "Occurrence",
              },
              {
                value: FORM_CLAIMS_MADE,
                label: "Claims-Made",
              },
            ],
          },
          {
            coverage: "Status",
            field_type: "Enum",
            response_limit: "Select",
            required: franchisorAdmin.umbrella_subrogration_status,
            current: franchisorAdmin.umbrella_subrogration_status_current,
            suggested: franchisorAdmin.umbrella_subrogration_status_suggested,
            quote: franchisorAdmin.umbrella_subrogration_status_quote,
            key: "umbrella_subrogration_status",
            options: [
              {
                value: FORM_DEDUCTIBLE,
                label: "Deductible",
              },
              {
                value: FORM_RETENTION,
                label: "Retention",
              },
              {
                value: FORM_NONE,
                label: "None",
              },
            ],
          },
        ]}
        tableLabel="PROPERTY / BUSINESS INTERRUPTION"
        onSave={handleSave}
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Each Claim",
          },
          {
            coverage: "3rd Party Crime",
          },
          {
            coverage: "Form Type",
          },
        ]}
        tableLabel="CRIME"
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Each Occurrence",
          },
          {
            coverage: "Aggregate",
          },
        ]}
        tableLabel="CYBER LIABILITY"
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Occurrence",
          },
          {
            coverage: "Aggregate",
          },
          {
            coverage: "3rd Party Liability",
          },
          {
            coverage: "Wage & Hour",
          },
          {
            coverage: "Max Deductible",
          },
        ]}
        tableLabel="EMPLOYMENT PRACTICES LIABILITY"
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Bailee’s Limit",
          },
        ]}
        tableLabel="BAILEE’S COVERAGE"
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Trailer Limit",
          },
        ]}
        tableLabel="TRAILER COVERAGE"
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Each Occurrence",
          },
          {
            coverage: "Aggregate",
          },
          {
            coverage: "Policy Type",
          },
        ]}
        tableLabel="PROFESSIONAL LIABILITY"
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Each Occurrence",
          },
          {
            coverage: "Aggregate",
          },
          {
            coverage: "Policy Type",
          },
        ]}
        tableLabel="MEDICAL MALPRACTICE"
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "1 Year",
          },
          {
            coverage: "2 Year",
          },
          {
            coverage: "3 Year",
          },
        ]}
        tableLabel="TAIL COVERAGE"
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Each Occurrence",
          },
          {
            coverage: "Aggregate",
          },
        ]}
        tableLabel="SEXUAL ABUSE & MOLESTATION"
      />
      <SectionTable
        initDataSource={[
          {
            coverage: "Principal Sum",
          },
          {
            coverage: "Loss must occur within",
          },
          {
            coverage: "Aggregate",
          },
        ]}
        tableLabel="ACCIDENT INSURANCE"
      />
    </div>
  );
}
