import React from "react";
import "./style.scss";
import imageLogo from "../../assets/images/logo.svg";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined spin />;

export default function Splash() {
  return (
    <div className="splash-screen-container">
      <div className="main-content">
        <Spin indicator={antIcon} />
        <img src={imageLogo} />
      </div>
      <div className="splash-back-container"></div>
      <div className="splash-loading-container"></div>
    </div>
  );
}
