import React from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import "./style.scss";

export default function TemplateItem({ data, onToggle, onDelete, onEdit }) {
  return (
    <div className="template_item">
      <p>{data.title}</p>
      <div className="template_item-action">
        <EditOutlined
          className="template_item-action-edit"
          onClick={() => onEdit(data)}
        />
        <DeleteOutlined
          className="template_item-action-delete"
          onClick={() => onDelete(data._id)}
        />
      </div>
    </div>
  );
}
