import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  Input,
  Loading,
  FormMask,
  Textarea,
  ScreenMask,
} from "../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppState } from "@context";
import { put, post } from "../../../utils/axios";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
  SOURCE_ONBOARDING,
} from "../../../assets/const/form";

export default function CrimeForm(props) {
  const { data, btnLabel, source, onNext, readOnly = false } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;

  const [formVal, setFormVal] = useState({
    crime_form_type: sourceData?.crime_form_type || "",
    crime_each_claim: sourceData?.crime_each_claim || "",
    crime_third_party: sourceData?.crime_third_party || "",
    crime_required: sourceData?.crime_required || false,
    crime_broker_note: sourceData?.crime_broker_note || "",
  });

  useEffect(() => {
    setFormVal({
      crime_form_type: sourceData?.crime_form_type || "",
      crime_each_claim: sourceData?.crime_each_claim || "",
      crime_third_party: sourceData?.crime_third_party || "",
      crime_required: sourceData?.crime_required || false,
      crime_broker_note: sourceData?.crime_broker_note || "",
    });
  }, [sourceData]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleSubmit() {
    handleFormSubmit();
  }

  async function handleFormSubmit() {
    const { onUpdate } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="crime-content">
      <div className="form-header">
        <div className="line-disable">
          <Switch
            checked={formVal.crime_required}
            onChange={(checked) => handleChange("crime_required", checked)}
          />
          <span className="disable-title">Required</span>
        </div>
      </div>
      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <h5 className="radio-title">Crime Form</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("crime_form_type", evt.target.value)
              }
              value={formVal.crime_form_type}
            >
              <Radio value={FORM_LOSS_DISCOVERED}>Loss Discovered</Radio>
              <Radio value={FORM_LOSS_SUSTAINED}>Loss Sustained </Radio>
            </Radio.Group>
          </div>
          <div className="line-item-container broker-report-container">
            <Textarea
              title="Notes from broker"
              type="text"
              placeholder="Please enter your text here."
              value={formVal.crime_broker_note}
              rows={10}
              onChange={(evt) =>
                handleChange("crime_broker_note", evt.target.value)
              }
            />
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH CLAIM"
              type="number"
              placeholder="EACH CLAIM"
              value={formVal.crime_each_claim}
              onChange={(evt) =>
                handleChange("crime_each_claim", evt.target.value)
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="3RD PARTY"
              type="number"
              placeholder="3RD PARTY"
              value={formVal.crime_third_party}
              onChange={(evt) =>
                handleChange("crime_third_party", evt.target.value)
              }
            />
          </div>
        </div>
        {!formVal.crime_required && <FormMask />}
      </div>
      <div className="form-footer">
        {!readOnly && (
          <div className="button-container">
            <Button type="primary" onClick={handleSubmit}>
              {btnLabel ? btnLabel : "Save"}
            </Button>
          </div>
        )}
      </div>
      <Loading loading={loading} />

      {readOnly && <ScreenMask />}
    </div>
  );
}
