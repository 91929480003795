import gaiLogo from "../images/logo-great-american.png";
import hartfordLogo from "../images/hartford-logo.png";

export const GA_CONSTS = {
  productType: "WorkersCompensation",
  producerCode: "108494",
  riskSelectionLine: "WC",
  defaultCountry: "US",
};

// Identifiers for different types of code.
export const CODE_TYPE_SPECTRUM_CLASS = "SPECTRUM-CLASS";
export const CODE_TYPE_NAICS = "NAICS";
export const CODE_TYPE_WC = "WC";
export const CODE_TYPE_SIC = "SIC";

// Identifiers for different types of coverage.
export const COVERAGE_KEY_BOP = "bo";
export const COVERAGE_KEY_PROPERTY = "prop";
export const COVERAGE_KEY_LIABILITY = "li";
export const COVERAGE_KEY_AUTO = "au";
export const COVERAGE_KEY_WORKERS_COMP = "wc";
export const COVERAGE_KEY_UMBRELLA = "um";
export const COVERAGE_KEY_CRIME = "crime";
export const COVERAGE_KEY_GARAGE = "garage";
export const COVERAGE_KEY_EPLI = "epli";
export const COVERAGE_KEY_CYBER = "cyber";
export const COVERAGE_KEY_PRO_LIAB = "pro_liab";
export const COVERAGE_KEY_POL_LIAB = "pol_liab";
export const COVERAGE_KEY_IM = "im";

// Human readable titles for different types of coverage.
export const COVERAGE_TITLE_PROPERTY = "Property";
export const COVERAGE_TITLE_LIABILITY = "Liability";
export const COVERAGE_TITLE_AUTO = "Auto";
export const COVERAGE_TITLE_WORKERS_COMP = "Workers Compensation";
export const COVERAGE_TITLE_UMBRELLA = "Umbrella";

export const COVERAGES_OLD = [
  {
    title: "Property",
    id: COVERAGE_KEY_BOP,
  },
  {
    title: "Liability",
    id: COVERAGE_KEY_LIABILITY,
  },
  {
    title: "Auto",
    id: COVERAGE_KEY_AUTO,
  },
  {
    title: "Workers Compensation",
    id: COVERAGE_KEY_WORKERS_COMP,
  },
];

// A map of coverages RMS can quote.
export const COVERAGES = {
  [COVERAGE_KEY_WORKERS_COMP]: {
    title: COVERAGE_TITLE_WORKERS_COMP,
    disabled: () => false,
  },
  [COVERAGE_KEY_PROPERTY]: {
    title: COVERAGE_TITLE_PROPERTY,
    disabled: (carrier) =>
      carrier === INSURANCE_CARRIER_KEY_GA ||
      carrier === INSURANCE_CARRIER_KEY_AMTRUST,
  },
  [COVERAGE_KEY_LIABILITY]: {
    title: COVERAGE_TITLE_LIABILITY,
    disabled: (carrier) =>
      carrier === INSURANCE_CARRIER_KEY_GA ||
      carrier === INSURANCE_CARRIER_KEY_AMTRUST,
  },
};

// Identifiers for different types of insurance carriers.
export const INSURANCE_CARRIER_KEY_HARTFORD = "hartford";
export const INSURANCE_CARRIER_KEY_GA = "greatamerican";
export const INSURANCE_CARRIER_KEY_AMTRUST = "amtrust";

// Human readable titles for different types of coverage.
export const INSURANCE_CARRIER_TITLE_HARTFORD = "The Hartford";
export const INSURANCE_CARRIER_TITLE_GA = "Great American";
export const INSURANCE_CARRIER_TITLE_AMTRUST = "AmTrust";

// A map of insurance carriers that provide us with APIs to run quotes on different coverages.
export const INSURANCE_CARRIERS = {
  [INSURANCE_CARRIER_KEY_HARTFORD]: {
    title: INSURANCE_CARRIER_TITLE_HARTFORD,
    logo: hartfordLogo,
    default: true,
  },
  [INSURANCE_CARRIER_KEY_GA]: {
    title: INSURANCE_CARRIER_TITLE_GA,
    logo: gaiLogo,
  },
  [INSURANCE_CARRIER_KEY_AMTRUST]: {
    title: INSURANCE_CARRIER_TITLE_AMTRUST,
  },
};

export const NUM_STEPS_TO_QUOTE = {
  [INSURANCE_CARRIER_KEY_AMTRUST]: 3,
  [INSURANCE_CARRIER_KEY_GA]: 3,
  [INSURANCE_CARRIER_KEY_HARTFORD]: 2,
};

// HartFord has a single "bop" endpoint for quoting both property and liability, and they
// requires us to express the coverage combination using "bopTypeCode"s.
export const HARTFORD_QUOTE_BOPTYPECODE_PROPONLY = "PropOnly";
export const HARTFORD_QUOTE_BOPTYPECODE_LIABONLY = "LiabOnly";
export const HARTFORD_QUOTE_BOPTYPECODE_PROPLIAB = "PropLiab";

// Franconnect franchisee statuses
export const FRANCONNECT_FRANCHISEE_STATUS_ACTIVE = "Active";

export const QUOTE_STATUS_BINDABLE = "Bindable";
export const QUOTE_STATUS_NOT_IN_MARKET = "Not in market";
export const QUOTE_STATUS_BOUND = "Bound";
export const QUOTE_STATUS_RENEW = "Renew";
export const QUOTE_STATUS_QUOTE = "Quote";
export const QUOTE_STATUS_ACTIVE = "Active";

export const QUOTE_STATUSES = [
  QUOTE_STATUS_BINDABLE,
  QUOTE_STATUS_NOT_IN_MARKET,
  QUOTE_STATUS_BOUND,
  QUOTE_STATUS_RENEW,
  QUOTE_STATUS_QUOTE,
  QUOTE_STATUS_ACTIVE,
];

export const QUOTE_PROPOSAL_SOURCE_MANUAL = "Manual";
export const QUOTE_PROPOSAL_SOURCE_API = "API";
export const QUOTE_PROPOSAL_SOURCES = [
  QUOTE_PROPOSAL_SOURCE_MANUAL,
  QUOTE_PROPOSAL_SOURCE_API,
];
