import AddFranchisee from "./add-franchisee";
import { Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { notify } from "@utils/common";
import "./style.scss";
import rikorLogo from "../../assets/images/rikor-logo.svg";
import { useEffect, useState } from "react";
import { post } from "@utils/axios";
import iconChrome from "@assets/images/icon_chrome.svg";
import iconDesktop from "@assets/images/icon_desktop.svg";

const TAB_UPLOAD = "upload";
const TAB_ADD = "add";

export default function Quote() {
  const [franchisors, setFranchisors] = useState([]);

  async function handleLoadFranchisors() {
    try {
      const result = await post(`universal-quote/list-franchisors`);
      if (result.status === 200) {
        setFranchisors(result.data.data);
      }
    } catch (err) {
      console.log(err);
      notify("Error", "Failed to load Franchisors.", "error");
    }
  }

  useEffect(() => {
    handleLoadFranchisors();
  }, []);

  return (
    <div className="universal-quote-page">
      <div className="universal-quote-container">
        <div>
          <img height={85} src={rikorLogo} />
          <h3 className="universal-quote-container-title">
            Rikor is your preferred franchise insurance vendor, answer the
            questions below to begin!
          </h3>
          <div className="universal-quote-container-tip">
            <img
              className="universal-quote-container-tip-img"
              src={iconChrome}
            />
            +
            <img
              className="universal-quote-container-tip-img"
              src={iconDesktop}
            />
            <span>
              This form is optimized to run on a{" "}
              <i>
                <b>Chrome Browser</b>
              </i>{" "}
              with a{" "}
              <i>
                <b>Desktop</b>
              </i>{" "}
              or{" "}
              <i>
                <b>Laptop</b>
              </i>{" "}
              computer! Other browsers and mobile device may work as well
              depending on signal strength.
            </span>
          </div>
          {franchisors.length > 0 && (
            <Tabs defaultActiveKey={TAB_ADD}>
              <TabPane tab="My Application" key={TAB_ADD}>
                <AddFranchisee franchisors={franchisors} />
              </TabPane>
            </Tabs>
          )}
        </div>
      </div>
    </div>
  );
}
