import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Loading } from "../../../../components";
import { Button, Table, notification } from "antd";
import { post, get, put } from "../../../../utils/axios";
import { useHistory } from "react-router-dom";
import "./style.scss";

export default function AdminFranchisees() {
  const [emailSource, setEmailSource] = useState([]);
  const [phoneSource, setPhoneSource] = useState([]);
  const [entityNameSource, setEntityNameSource] = useState([]);

  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const history = useHistory();

  useEffect(() => {
    handleLoadEmail();
    handleLoadPhone();
    handleLoadEntityName();
  }, []);

  async function handleLoadReport(option) {
    const token = await getAccessTokenSilently();
    const result = await post(
      `admin/report-contact-dup`,
      {
        option: option,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  }

  async function handleLoadEmail() {
    try {
      setLoading(true);
      const result = await handleLoadReport("email");
      setLoading(false);
      setEmailSource(result?.data?.data || []);
    } catch (error) {
      setLoading(false);
      console.log("error load email", error);
    }
  }
  async function handleLoadEntityName() {
    try {
      setLoading(true);
      const result = await handleLoadReport("entity_name");
      setLoading(false);
      setEntityNameSource(result?.data?.data || []);
    } catch (error) {
      setLoading(false);
      console.log("error load entity", error);
    }
  }
  async function handleLoadPhone() {
    try {
      setLoading(true);
      const result = await handleLoadReport("telephone");
      setLoading(false);
      setPhoneSource(result?.data?.data || []);
    } catch (error) {
      setLoading(false);
      console.log("error load phone", error);
    }
  }

  const rowSelection = {};

  const emailColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Duplication Count",
      dataIndex: "count",
      key: "count",
    },
  ];
  const phoneColumns = [
    {
      title: "Phone",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Duplication Count",
      dataIndex: "count",
      key: "count",
    },
  ];
  const entityNameColumns = [
    {
      title: "Entity Name",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Duplication Count",
      dataIndex: "count",
      key: "count",
    },
  ];

  function handleNavigate(record) {
    history.push(`/admin/franchisees?searchStr=${record.email}`);
  }

  return (
    <div className="admin_duplication_franchisee">
      <Card
        title="Duplicate Email Report"
        extra={() => (
          <div>
            <Button onClick={handleLoadEmail}>Sync</Button>
          </div>
        )}
      >
        <Table
          columns={emailColumns}
          dataSource={emailSource}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleNavigate(record);
              },
            };
          }}
        />
      </Card>

      <Card
        title="Duplicate Phone Report"
        extra={() => (
          <div>
            <Button onClick={handleLoadPhone}>Sync</Button>
          </div>
        )}
      >
        <Table
          rowSelection={rowSelection}
          columns={phoneColumns}
          dataSource={phoneSource}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleNavigate(record);
              },
            };
          }}
        />
      </Card>

      <Card
        title="Duplicate Entity Name Report"
        extra={() => (
          <div>
            <Button onClick={handleLoadEntityName}>Sync</Button>
          </div>
        )}
      >
        <Table
          rowSelection={rowSelection}
          columns={entityNameColumns}
          dataSource={entityNameSource}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                handleNavigate(record);
              },
            };
          }}
        />
      </Card>

      <Loading loading={loading} />
    </div>
  );
}
