import React from "react";
import "./style.scss";
import LogSection from "./log-section";

export default function LogReport() {
  return (
    <div className="admin_log">
      <LogSection title="Re-verify" section="re-verify" />
      <LogSection title="Franconnect" section="franconnect" />
      <LogSection title="Active Campaign" section="active-campaign" />
      <LogSection title="Hartford" section="hartford" />
      <LogSection title="Great American" section="great-american" />
    </div>
  );
}
