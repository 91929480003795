import { Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import {
  CODE_TYPE_NAICS,
  CODE_TYPE_SIC,
  CODE_TYPE_SPECTRUM_CLASS,
} from "../../../../../assets/const/fim-integration";
import { Card, Input, SpectrumCode } from "../../../../../components";
import "./style.scss";

const { Option } = Select;

export default function FormCode(props) {
  const { formVal, codeData, onChange } = props;

  const spectrumCodeList = useMemo(
    () =>
      (codeData || [])
        .filter((item) => item.eleType === CODE_TYPE_SPECTRUM_CLASS)
        .map((item) => {
          return {
            code: item.childCode?.spectrumCode
              ? item.childCode.spectrumCode
              : item.parentCode?.spectrumCode,
            desc: item.childCode?.className
              ? item.childCode.className
              : item.parentCode?.className,
            question: item.parentCode?.question,
          };
        }),
    [codeData]
  );
  const sicCodeList = useMemo(
    () =>
      (codeData || []).filter((item) => item.eleType === CODE_TYPE_SIC) || [],
    [codeData]
  );
  const nacisCodeList = useMemo(
    () =>
      (codeData || []).filter((item) => item.eleType === CODE_TYPE_NAICS) || [],
    [codeData]
  );
  const [selectedSpec, setSelectedSpec] = useState({});

  useEffect(() => {
    if (spectrumCodeList.length > 0) {
      handleChangeSpec(spectrumCodeList[0].code);
    }
  }, []);

  function handleChangeSpec(code) {
    const selected = spectrumCodeList.find((item) => item.code === code);
    setSelectedSpec(selected);
    onChange("spectrumClassCode", code);
  }

  return (
    <div className="form_code">
      <div className="form-group-three-container">
        <div>
          <p className="input-title">Spectrum Class Code</p>
          <span>{selectedSpec.question}</span>
          <Select
            style={{ width: "100%" }}
            value={selectedSpec.code}
            onChange={handleChangeSpec}
            size="large"
          >
            {spectrumCodeList.map((item) => (
              <Option value={item.code}>
                {item.code} - {item.desc}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <p className="input-title">SICCode ( Risk Profile )</p>
          <Select
            style={{ width: "100%" }}
            onChange={(value) => onChange("SICCode", value)}
            value={formVal.SICCode}
            size="large"
          >
            {sicCodeList.map((item) => (
              <Option value={item.code}>{item.code}</Option>
            ))}
          </Select>
        </div>
        <div>
          <p className="input-title">NAICS ( Risk Profile )</p>
          <Select
            style={{ width: "100%" }}
            onChange={(value) => onChange("NAICS", value)}
            value={formVal.NAICS}
            size="large"
          >
            {nacisCodeList.map((item) => (
              <Option value={item.code}>{item.code}</Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
