import { Select } from "antd";
import React, { useState } from "react";
import {
  CODE_TYPE_NAICS,
  CODE_TYPE_SIC,
  CODE_TYPE_SPECTRUM_CLASS,
} from "../../../../assets/const/fim-integration";
import { Card, Input, SpectrumCode } from "../../../../components";
import "./style.scss";

const { Option } = Select;

export default function BlockCode(props) {
  const { formVal, codeData, onChange } = props;
  const [selectedSpec, setSelectedSpec] = useState("");

  const spectrumCodeList = (codeData || [])
    .filter((item) => item.eleType === CODE_TYPE_SPECTRUM_CLASS)
    .map((item) => {
      return {
        code: item.childCode?.spectrumCode
          ? item.childCode.spectrumCode
          : item.parentCode?.spectrumCode,
        desc: item.childCode?.className
          ? item.childCode.className
          : item.parentCode?.className,
        question: item.parentCode?.question,
      };
    });
  const sicCodeList =
    (codeData || []).filter((item) => item.eleType === CODE_TYPE_SIC) || [];
  const nacisCodeList =
    (codeData || []).filter((item) => item.eleType === CODE_TYPE_NAICS) || [];

  function handleChangeSpec(code) {
    const selected = spectrumCodeList.find((item) => item.code === code);
    setSelectedSpec(selected);
    onChange("spectrumClassCode", code);
  }

  return (
    <Card className="hartford_code" title="Code">
      <div>
        <p className="input-title">Spectrum Class Code</p>
        <span>{selectedSpec.question}</span>
      </div>
      <div className="form-group-container">
        <Select
          style={{ width: "100%" }}
          onChange={handleChangeSpec}
          size="large"
        >
          {spectrumCodeList.map((item) => (
            <Option value={item.code}>
              {item.code} - {item.desc}
            </Option>
          ))}
        </Select>
      </div>
      <div className="form-group-container">
        <div className="form-group-container">
          <div>
            <p className="input-title">SICCode ( Risk Profile )</p>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => onChange("SICCode", value)}
              value={formVal.SICCode}
              size="large"
            >
              {sicCodeList.map((item) => (
                <Option value={item.code}>{item.code}</Option>
              ))}
            </Select>
          </div>
          <div>
            <p className="input-title">NAICS ( Risk Profile )</p>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => onChange("NAICS", value)}
              value={formVal.NAICS}
              size="large"
            >
              {nacisCodeList.map((item) => (
                <Option value={item.code}>{item.code}</Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="form-group-container">
          <Input
            title="Agent Name"
            type="text"
            value={formVal.agentName}
            onChange={(evt) => onChange("agentName", evt.target.value)}
          />
          <Input
            title="Number of Losses"
            type="text"
            value={formVal.numLosses}
            onChange={(evt) => onChange("numLosses", evt.target.value)}
          />
        </div>
      </div>
    </Card>
  );
}
