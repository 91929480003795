import React, { useState } from "react";
import { UploadFranchiseeForm } from "@components";
import "./style.scss";
import { VIEW_MODE_UPLOAD_FORM } from "@assets/const/ui";

const VIEW_MODE_NORMAL = "VIEW_MODE_NORMAL";
const VIEW_MODE_UPLOAD = "VIEW_MODE_UPLOAD";

export default function MethodForm(props) {
  const { boardPlatforms, handleSignInFranchisee, handleSkip } = props;
  const [viewMode, setViewMode] = useState(VIEW_MODE_NORMAL);
  const [uploadViewMode, setUploadViewMode] = useState(VIEW_MODE_UPLOAD_FORM);

  return (
    <>
      {viewMode === VIEW_MODE_NORMAL && (
        <div className="onboarding-form_select_platform">
          <h4>Select your platform</h4>
          <div className="platforms-container">
            {boardPlatforms.map((platform) => (
              <div
                className="platform-item"
                style={{ backgroundImage: `url(${platform.url})` }}
                onClick={() => handleSignInFranchisee(platform.key)}
              ></div>
            ))}
          </div>{" "}
          <p>
            Don't have a platform?{" "}
            <a href="#" onClick={() => setViewMode(VIEW_MODE_UPLOAD)}>
              Upload CSV.
            </a>
          </p>
          <div>
            <a onClick={handleSkip}>Or Skip</a>
          </div>
        </div>
      )}
      {viewMode === VIEW_MODE_UPLOAD && (
        <UploadFranchiseeForm
          viewMode={uploadViewMode}
          setViewMode={setUploadViewMode}
          requirementId={props.requirementId}
          handleNavigate={() => {
            const { handleSkip } = props;
            handleSkip();
          }}
        />
      )}
    </>
  );
}
