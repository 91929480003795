import React, { useState } from "react";
import { Input } from "@components";
import { Button } from "antd";

import "./style.scss";

function SignUp() {
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRe, setPasswordRe] = useState("");

  function handleSignUp() {}

  return (
    <div className="signup-page-container">
      <div className="form-container">
        <div className="form-wrapper">
          <h3 className="title-container">Create Account</h3>
          <div className="line-container">
            <Input
              title="Company Name"
              type="text"
              placeholder="Please enter your name"
              value={companyName}
              onChange={(evt) => setCompanyName(evt.target.value)}
            />
          </div>
          <div className="line-container">
            <Input
              title="Email"
              type="email"
              placeholder="Please enter your email"
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </div>
          <div className="line-container">
            <Input
              title="Password"
              type="password"
              placeholder="Please create a password"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </div>
          <div className="line-container">
            <Input
              title="Password"
              type="password"
              placeholder="Please re-enter the password"
              value={passwordRe}
              onChange={(evt) => setPasswordRe(evt.target.value)}
            />
          </div>
          <div className="line-container">
            <Button type="primary" onClick={handleSignUp}>
              Sign up
            </Button>
          </div>
          <div className="footer-sub-container">
            <span>
              By clicking Sign Up, you agree to our <a href="#">Terms</a>,{" "}
              <a href="#">Data Policy</a> and <a href="#">Cookie Policy</a>.
            </span>
          </div>
          <div className="footer-container">
            <span>
              Already have account? <a href="/sign-in">Sign In</a>.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
