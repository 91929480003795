import { Card, Checkbox, Radio } from "antd";
import {
  COVERAGES,
  INSURANCE_CARRIERS,
} from "../../../../../assets/const/fim-integration";
import "./style.scss";

export function CoverageCarrierSelection({
  disabledCarriers,
  selectedCarrier,
  selectedCoverages,
  setSelectedCarrier,
  setSelectedCoverages,
}) {
  return (
    <div class="coverage-carrier-selection">
      <Card title="Coverage & Carrier Selection">
        <div style={{ marginBottom: 20 }}>
          <p className="input-title">Carriers</p>
          <Radio.Group value={selectedCarrier}>
            {Object.keys(INSURANCE_CARRIERS).map((carrierKey) => (
              <Radio
                disabled={
                  disabledCarriers ? disabledCarriers[carrierKey] : false
                }
                value={carrierKey}
                onChange={() => {
                  setSelectedCarrier(carrierKey);
                  setSelectedCoverages({});
                }}
              >
                {INSURANCE_CARRIERS[carrierKey].title}
              </Radio>
            ))}
          </Radio.Group>
        </div>
        <div>
          <p className="input-title">Coverages</p>
          <div>
            {Object.keys(COVERAGES).map((coverageKey) => (
              <Checkbox
                disabled={COVERAGES[coverageKey].disabled(selectedCarrier)}
                checked={selectedCoverages[coverageKey]}
                onChange={(e) => {
                  setSelectedCoverages((prev) => ({
                    ...prev,
                    [coverageKey]: e.target.checked,
                  }));
                }}
              >
                {COVERAGES[coverageKey].title}
              </Checkbox>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
}
