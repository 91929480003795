import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { get } from "../../../../utils/axios";
import { Loading, Card } from "../../../../components";
import "./style.scss";
import { format } from "date-fns";
import LogItem from "../log-item";
import LogDetail from "../log-detail";

export default function LogSection(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [logList, setLogList] = useState([]);
  const [itemDetail, setItemDetail] = useState(null);

  useEffect(() => {
    handleLoadLog();
  }, []);

  async function handleLoadLog() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`admin/logs?logType=${props.section}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const logData = result?.data?.data || [];
      const logList = logData.map((item) => ({
        label: format(new Date(item.split(".txt")[0]), "LLL dd yyyy hh:mm a"),
        path: item,
      }));
      setLogList(logList);
      setLoading(false);
    } catch (error) {
      console.log("error  ---", error);
      setLoading(false);
    }
  }
  return (
    <div className="admin_log_container">
      <Card
        title={props.title}
        extra={() => (
          <div>
            <Button type="primary" onClick={handleLoadLog}>
              Refresh
            </Button>
          </div>
        )}
      >
        <div className="admin_log_container-main">
          <div className="admin_log_container-main-list">
            {logList.map((item) => (
              <LogItem
                data={item}
                setDetail={setItemDetail}
                onLogList={setLogList}
                section={props.section}
              />
            ))}
          </div>
          {itemDetail && (
            <div className="admin_log_container-main-detail">
              <LogDetail data={itemDetail} />
            </div>
          )}
        </div>
      </Card>
      <Loading loading={loading} />
    </div>
  );
}
