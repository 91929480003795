import React, { useEffect, useState, useMemo } from "react";
import "./style.scss";
import { EyeOutlined } from "@ant-design/icons";
import { Alert, Breadcrumb, Steps } from "antd";
import PanelEntityInfo from "./panel-entity-info";
import { useAppState } from "@context/index";
import { useAuth0 } from "@auth0/auth0-react";
import { get } from "@utils/axios";
import { Loading } from "@components";
import { useLocation } from "react-router-dom";
import PanelDriverInfo from "./panel-driver-info";
import PanelVehicleInfo from "./panel-vehicle-info";
import PanelBuildOut from "./panel-build-out";
import PanelPayroll from "./panel-payroll";
import PanelEquipment from "./panel-equipment";
import PanelProperty from "./panel-property";
import PanelDocument from "./panel-document";
import PanelPOC from "./panel-poc";
import PanelQuestion from "./panel-question";
import { post } from "@utils/axios";
import {
  SECTION_KEY_BUILD_OUT,
  SECTION_KEY_DOCUMENT,
  SECTION_KEY_DRIVERS,
  SECTION_KEY_ENTITY,
  SECTION_KEY_EQUIPMENT,
  SECTION_KEY_PAYROLL,
  SECTION_KEY_POC,
  SECTION_KEY_PROPERTY,
  SECTION_KEY_SUBCONTRACTOR,
  SECTION_KEY_UNIQUE,
  SECTION_KEY_VEHICLES,
  SECTION_LABEL_DOCUMENT,
  SEC_NAMING,
  SEC_ZEE_NAMING
} from "@assets/const/ui";
import PanelSubContractor from "./panel-sub-contractor";

export default function FranchiseeSchedule() {
  const { authSetting, franchisorAdmin } = useAppState();
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const paramQuery = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [franchiseeData, setFranchiseeData] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [sectionList, setSectionList] = useState([
    {
      title: SECTION_LABEL_DOCUMENT,
      value: SECTION_KEY_DOCUMENT
    }
  ]);

  useEffect(() => {
    if (paramQuery.get("sec")) {
      const sectionData = paramQuery
        .get("sec")
        .split(",")
        .map((item) => ({ title: SEC_ZEE_NAMING[item], value: item }));
      sectionData.push({
        title: SECTION_LABEL_DOCUMENT,
        value: SECTION_KEY_DOCUMENT
      });
      setSectionList(sectionData);
    }
  }, [location]);

  useEffect(() => {
    if (authSetting?.user?.contactId) {
      handleLoadFranchisee();
    }
  }, [authSetting]);

  async function handleLoadFranchisee() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${authSetting?.user?.contactId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFranchiseeData(result.data.data || {});

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }

  async function handleSet(data, key) {
    setFranchiseeData(data);
    if (activeStep < sectionList.length - 1) {
      setActiveStep(activeStep + 1);
    }
  }

  async function handleNext() {
    if (activeStep < sectionList.length - 1) {
      setActiveStep(activeStep + 1);
    }
  }

  async function handleComplete() {
    const scheduleLink = `${window.location.origin}/admin/franchisees/${franchiseeData?._id}`;
    const token = await getAccessTokenSilently();

    const resultRequest = await post(
      `reports/email-request`,
      {
        template: "contact_support",
        firstName: "Schedule Information",
        url: scheduleLink,
        email: ""
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  }

  const franchiseeName = useMemo(() => {
    if (franchiseeData) {
      if (franchiseeData?.isInsureDiff) {
        return `${franchiseeData?.insuranceData?.first_name || "Franchisee"} ${
          franchiseeData?.insuranceData?.last_name || "Name"
        }`;
      }

      return `${franchiseeData.first_name || "Franchisee"} ${
        franchiseeData.last_name || "Name"
      }`;
    }

    return "Franchisee Name";
  }, [franchiseeData]);

  return (
    <div className="zee_sche">
      <div className="zee_sche-header">
        <div>
          {franchisorAdmin?.personal_infor_logo && (
            <img
              src={franchisorAdmin?.personal_infor_logo}
              className="zee_sche-header-logo"
            />
          )}
        </div>
      </div>
      <div className="zee_sche-sub-header">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => {}}>
            {franchisorAdmin?.personal_infor_business_name || "Franchisor"}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{franchiseeName}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="zee_sche-content">
        <div className="zee_sche-content-header">
          <Steps
            size="small"
            current={activeStep}
            onChange={(val) => setActiveStep(val)}
            items={sectionList}
          />
        </div>
        {sectionList[activeStep]?.value === SECTION_KEY_ENTITY && (
          <PanelEntityInfo
            franchiseeData={franchiseeData}
            setFranchiseeData={(data) => handleSet(data, SECTION_KEY_ENTITY)}
            onNext={handleNext}
          />
        )}
        {sectionList[activeStep]?.value === SECTION_KEY_POC && (
          <PanelPOC
            franchiseeData={franchiseeData}
            setFranchiseeData={(data) => handleSet(data, SECTION_KEY_POC)}
            onNext={handleNext}
          />
        )}
        {sectionList[activeStep]?.value === SECTION_KEY_UNIQUE && (
          <PanelQuestion
            franchiseeData={franchiseeData}
            setFranchiseeData={(data) => handleSet(data, SECTION_KEY_UNIQUE)}
            onNext={handleNext}
          />
        )}
        {sectionList[activeStep]?.value === SECTION_KEY_DRIVERS && (
          <PanelDriverInfo
            franchiseeData={franchiseeData}
            setFranchiseeData={(data) => handleSet(data, SECTION_KEY_DRIVERS)}
            onNext={handleNext}
          />
        )}
        {sectionList[activeStep]?.value === SECTION_KEY_VEHICLES && (
          <PanelVehicleInfo
            franchiseeData={franchiseeData}
            setFranchiseeData={(data) => handleSet(data, SECTION_KEY_VEHICLES)}
            onNext={handleNext}
          />
        )}
        {sectionList[activeStep]?.value === SECTION_KEY_BUILD_OUT && (
          <PanelBuildOut
            franchiseeData={franchiseeData}
            setFranchiseeData={(data) => handleSet(data, SECTION_KEY_BUILD_OUT)}
            onNext={handleNext}
          />
        )}
        {sectionList[activeStep]?.value === SECTION_KEY_PAYROLL && (
          <PanelPayroll
            franchiseeData={franchiseeData}
            franchisorData={franchisorAdmin}
            setFranchiseeData={(data) => handleSet(data, SECTION_KEY_PAYROLL)}
            onNext={handleNext}
          />
        )}
        {sectionList[activeStep]?.value === SECTION_KEY_SUBCONTRACTOR && (
          <PanelSubContractor
            franchiseeData={franchiseeData}
            franchisorData={franchisorAdmin}
            setFranchiseeData={(data) =>
              handleSet(data, SECTION_KEY_SUBCONTRACTOR)
            }
            onNext={handleNext}
          />
        )}
        {sectionList[activeStep]?.value === SECTION_KEY_PROPERTY && (
          <PanelProperty
            franchiseeData={franchiseeData}
            setFranchiseeData={(data) => handleSet(data, SECTION_KEY_PROPERTY)}
            onNext={handleNext}
          />
        )}
        {sectionList[activeStep]?.value === SECTION_KEY_EQUIPMENT && (
          <PanelEquipment
            franchiseeData={franchiseeData}
            setFranchiseeData={(data) => handleSet(data, SECTION_KEY_EQUIPMENT)}
            onNext={handleNext}
          />
        )}
        {sectionList[activeStep]?.value === SECTION_KEY_DOCUMENT && (
          <PanelDocument
            franchiseeData={franchiseeData}
            onComplete={handleComplete}
          />
        )}
      </div>
      <Loading loading={loading} />
    </div>
  );
}
