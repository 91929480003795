const ACCESS_HOST_LIST = ["app.rikor.io", "dev.app.rikor.io", "localhost"];
const OTHER_HOST_LIST = [
  {
    value: "app.rikor.io",
    label: "Main App",
  },
  {
    value: "app.authoritybrands.rikor.io",
    label: "Authoritybrands App",
  },
  {
    value: "app.homefrontbrands.rikor.io",
    label: "Homefrontbrands App",
  },
  {
    value: "app.horsepowerbrands.rikor.io",
    label: "Horsepowerbrands App",
  },
  {
    value: "app.veteranservicebrands.rikor.io",
    label: "Veteranservicebrands App",
  },
  {
    value: "app.thrivemorebrands.rikor.io",
    label: "Thrivemorebrands App",
  },
  {
    value: "app.homerunfranchises.rikor.io",
    label: "Homerunfranchises App",
  },
  {
    value: "app.resibrands.rikor.io",
    label: "Resibrands App",
  },
  {
    value: "app.fivestarfranchising.rikor.io",
    label: "Five Star Franchising App",
  },
];

const getHostTag = () => {
  const hostName = window.location.hostname;
  const matchedItem = ACCESS_HOST_LIST.find(
    (hostItem) => hostItem === hostName
  );
  if (matchedItem) {
    return undefined;
  }

  return hostName;
};

export { getHostTag, OTHER_HOST_LIST };
