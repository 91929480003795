import React, { useState, useEffect } from "react";
import { Breadcrumb, notification } from "antd";
import { useHistory, useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading } from "@components";
import { post } from "@utils/axios";
import "./style.scss";
import PageReport from "@pages/report";
import { RIKOR_CUSTOMER_URL_PARAM } from "@assets/const/form";

export default function FranchisorReportDetail(props) {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [franchisorDetail, setFranchisorDetail] = useState(null);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      if (id === RIKOR_CUSTOMER_URL_PARAM) {
      } else {
        handleLoadFranchisor();
      }
    }
  }, []);

  async function handleLoadFranchisor() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/franchisor/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.status === 200) {
        setFranchisorDetail(result.data);
      }
      setLoading(false);
    } catch (err) {
      handleNotification(
        "Failed",
        "Franchisor loading is failed. Please contact to admin directly"
      );
      setLoading(false);
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  function handleBackList() {
    history.push("/admin/report");
  }

  return (
    <div className="zor_report">
      <div className="header-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleBackList}>
            Franchisors
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {franchisorDetail?.requirement?.personal_infor_business_name}
          </Breadcrumb.Item>
        </Breadcrumb>

        <div className="action-container"></div>
      </div>
      <div className="zor_report-wrapper">
        <PageReport
          requirement={franchisorDetail?.requirement}
          franchiseeList={franchisorDetail?.franchiseeList}
          onDetail={(id) => history.push(`/admin/franchisees/${id}`)}
        />
        <Loading loading={loading} />
      </div>
    </div>
  );
}
