import React, { useState, useEffect } from "react";
import {
  Input,
  Loading,
  QuoteAction,
  QuoteHeader,
  QuoteProgressBar,
  QuoteStepper,
  Card,
} from "../../../components";
import HartfordPreview from "../admin-hartford-appetite/hartford-preview";
import "./style.scss";
import { get, post } from "../../../utils/axios";
import { useHistory, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function AdminHartfordPreview() {
  let { id, quoteId } = useParams();
  const history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [successResult, setSuccessResult] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleLoadData();
  }, [quoteId]);

  async function handleLoadData() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const franchiseeDataRaw = result.data.data;
      setFranchiseeData(franchiseeDataRaw);
      const resultRequirement = await get(
        `requirement/${franchiseeDataRaw.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFranchisorData(resultRequirement.data.data);
      const quoteList = franchiseeDataRaw.quoteList;
      const quoteItem = quoteList.find(
        (item) => item.business_id === quoteId || item.quote_id === quoteId
      );
      if (quoteItem) {
        setSuccessResult({
          [quoteItem.coverage]: quoteItem.response_detail,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log("error --", error);
      setLoading(false);
    }
  }

  function handleClose() {
    history.push(`/admin/franchisees/${id}`);
  }

  return (
    <div className="admin_hartford">
      <QuoteProgressBar step={1}></QuoteProgressBar>
      <QuoteHeader
        franchiseeData={franchiseeData}
        requirement={franchisorData}
        user={user}
      ></QuoteHeader>
      <div className="admin_hartford-container">
        <HartfordPreview {...successResult} />
        <QuoteAction
          handleClose={handleClose}
          visibleSave={false}
          visibleBack={false}
          visibleNext={false}
        ></QuoteAction>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
