import React, { useEffect, useState } from "react";
import UnderWritingQuestion from "../../../admin-board/franchisee-overview/underwriting-question";
import { useAppState } from "@context/index";
import { buildCustomQuestions } from "../../../admin-board/franchisee-detail/under-writing";
import { Button, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import "./style.scss";

export default function PanelQuestion({ franchiseeData, onNext, setFranchiseeData }) {
  const [customQuestions, setCustomQuestions] = useState([]);
  const { authSetting, franchisorAdmin } = useAppState();

  //changes
  const { getAccessTokenSilently, user } = useAuth0();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (franchiseeData) {
      setCustomQuestions(
        buildCustomQuestions(
          franchisorAdmin.custom_questions,
          franchiseeData.customQuestions
        )
      );
    }
  }, [franchiseeData]);

   async function handleSave() {
     setLoading(true);
     try {
       // Dynamically create the customQuestions array based on franchisorAdmin.custom_questions
       const customQuestionsRes = [];
       for (const ele of franchisorAdmin.custom_questions) {
         customQuestionsRes.push({
           section: ele.section,
           questions: ele.questions.map((question) => ({
             title: question.title,
             value: question.value,
             eleType: question.eleType,
           })),
         });
       }
       const token = await getAccessTokenSilently();
       const result = await put(
         `contact/${franchiseeData._id}`,
         {
           customQuestions: customQuestionsRes,
         },
         {
           headers: {
             Authorization: `Bearer ${token}`,
           },
         }
       );
       setFranchiseeData(result?.data?.entity);
       notification.success({
         message: "Success",
         description: "Driver Information is successfully updated!",
         duration: 5,
       });
     } catch (error) {
       notification.error({
         message: "Failed",
         description:
           "Saving Driver Information failed! Please contact admin directly.",
         duration: 5,
       });
     }
     setLoading(false);
   }

  return (
    <div>
      <UnderWritingQuestion
        onChange={setCustomQuestions}
        isCustom={true}
        customQuestions={customQuestions}
        hideSaveButton={true}
        allowChangeQuestions={false}
      />
      <div className="question-footer">
        {/* <Button
          onClick={() => {
            onNext();
          }}
          size="large"
          type="primary"
        >
          Everything Looks Good, Next
        </Button> */}
        <Button
          onClick={() => {
            handleSave();
          }}
          size="large"
          type="primary"
        >
          Save
        </Button>
        <Button
          onClick={() => {
            onNext();
          }}
          size="large"
          type="primary"
        >
          Everything Looks Good, Next
        </Button>
      </div>
    </div>
  );
}
