import React from "react";
import "./style.scss";
import { Header } from "../../components";
import { Switch, Route, Redirect } from "react-router-dom";
import Overview from "./overview";
import Detail from "./detail";
import AffiliateForm from "./affiliateForm";
import FranchiseForm from "./franchiseForm";

export default function Dashboard() {
  return (
    <div className="dashboard-container">
      <Header screen="affiliate-dashboard" />
      <div className="dashboard-wrapper">
        <Switch>
          <Route path="/affiliates/overview" component={Overview} />
          <Route path="/affiliates/new" exact component={AffiliateForm} />
          <Route path="/affiliates/:id" exact component={Detail} />
          <Route path="/affiliates/:id/edit" component={AffiliateForm} />
          <Route
            path="/affiliates/:id/franchises/new"
            component={FranchiseForm}
          />
          <Route
            path="/affiliates/:affiliateId/franchises/:id/edit"
            component={FranchiseForm}
          />
          <Redirect to="/affiliates/overview" />
        </Switch>
      </div>
    </div>
  );
}
