import React, { useState } from "react";
import { Header } from "../../components";
import "./style.scss";
import { onboardingWorkflow } from "../../assets/const/onboarding";
import OnboardingStart from "./onboarding-start";
import OnboardingStep from "./onboarding-step";
import SyncFranchisee from "./sync-franchisee";
import OnboardPlatforms from "./board-platforms";
import ListFranchisee from "./list-franchisee";
import UploadFranchisee from "./upload-franchisee";
import InsuranceRequirements from "./insurance-requirements";
import { useHistory } from "react-router-dom";
import AccountInfoForm from "./account-info-form";

function Onboarding() {
  let history = useHistory();
  const [onboardingStep, setOnboardingStep] = useState(
    onboardingWorkflow.ONBOARDING_START
  );

  function handleNavigate() {
    history.push("/dashboard/overview");
  }

  function handleOnboardProcess(method) {
    switch (method) {
      case "platform":
        setOnboardingStep(onboardingWorkflow.BOARD_PLATFORMS);
        break;
      case "upload":
        setOnboardingStep(onboardingWorkflow.BOARD_UPLOAD);
    }
  }

  return (
    <div className="onboarding-container">
      <Header screen={onboardingStep} />
      {onboardingStep === onboardingWorkflow.ONBOARDING_START && (
        <OnboardingStart
          handleNavigate={() =>
            setOnboardingStep(onboardingWorkflow.SYNC_FRANCHISEES)
          }
        />
      )}
      {onboardingStep !== onboardingWorkflow.ONBOARDING_START && (
        <div className="onboarding-main">
          <OnboardingStep step={onboardingStep} />
          {onboardingStep === onboardingWorkflow.SYNC_FRANCHISEES && (
            <SyncFranchisee handleOnboardProcess={handleOnboardProcess} />
          )}
          {onboardingStep === onboardingWorkflow.BOARD_UPLOAD && (
            <UploadFranchisee
              handleOnboardProcess={handleOnboardProcess}
              handleNavigate={() =>
                setOnboardingStep(onboardingWorkflow.INSURANCE_REQUIREMENT)
              }
            />
          )}
          {(onboardingStep === onboardingWorkflow.BOARD_PLATFORMS ||
            onboardingStep === onboardingWorkflow.SIGN_IN_FRANCHISEE) && (
            <OnboardPlatforms handleNavigate={setOnboardingStep} />
          )}
          {(onboardingStep === onboardingWorkflow.LIST_FRANCHISEE ||
            onboardingStep === onboardingWorkflow.PREVIEW_IMPORT_CONTACTS) && (
            <ListFranchisee handleNavigate={setOnboardingStep} />
          )}
          {(onboardingStep === onboardingWorkflow.INSURANCE_REQUIREMENT ||
            onboardingStep === onboardingWorkflow.REQUEST_ACTIVIATION) && (
            <InsuranceRequirements
              source="onboarding"
              handleUpdateStatus={setOnboardingStep}
              handleNavigate={setOnboardingStep}
            />
          )}
          {onboardingStep === onboardingWorkflow.ACCOUNT_INFORMATION && (
            <AccountInfoForm handleNavigate={handleNavigate} />
          )}
        </div>
      )}
    </div>
  );
}

export default Onboarding;
