import React, { useState, useMemo, useEffect } from "react";
import { format } from "date-fns";
import { Card } from "@components";
import _ from "lodash";
import { Button, notification } from "antd";
import { Select } from "antd";
import ReactJson from "react-json-view";
import { FORM_EDIT, FORM_VIEW } from "@assets/const/ui";
import Loading from "@components/loading";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import ScreenMask from "@components/screen-mask";
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined
} from "@ant-design/icons";

import "./style.scss";
import { BlockLocation } from "@components/underwriting";

const { Option } = Select;

export default function CommonLocForm({ franchiseeData, setFranchiseeData }) {
  const [showDiff, setShowDiff] = useState(false);
  const [version, setVersion] = useState(0);
  const [formVal, setFormVal] = useState([]);
  const [activeFormVal, setActiveFormVal] = useState({});
  const [viewMode, setViewMode] = useState(FORM_VIEW);
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();

  const logData = useMemo(() => {
    const logCommonLocList = franchiseeData?.logCommonLocList || [];
    return logCommonLocList.sort((itemA, itemB) =>
      new Date(itemA.updatedAt) > new Date(itemB.updatedAt) ? -1 : 1
    );
  }, [franchiseeData]);

  const versionList = useMemo(() => {
    const logCommonLocList = franchiseeData?.logCommonLocList || [];
    return logCommonLocList
      .sort((itemA, itemB) =>
        new Date(itemA.updatedAt) > new Date(itemB.updatedAt) ? -1 : 1
      )
      .map((item) => {
        return {
          updatedAt: format(new Date(item.updatedAt), "MM/dd/yyyy hh:mm a"),
          updatedBy: item.updatedBy
        };
      });
  }, [franchiseeData]);

  function handleGetFormVal() {
    return franchiseeData?.commonLocList || [];
  }

  useEffect(() => {
    setFormVal(handleGetFormVal());
    setActiveFormVal(handleGetFormVal());
  }, [franchiseeData]);

  function handleSwitchVersion(versionIndex) {
    setVersion(versionIndex);
    setFormVal(logData[versionIndex].data);
  }

  function renderVersion() {
    return (
      <div className="loc-action">
        {showDiff ? (
          <div className="loc-action-btn" onClick={() => setShowDiff(false)}>
            <EyeInvisibleOutlined />
          </div>
        ) : (
          <div className="loc-action-btn" onClick={() => setShowDiff(true)}>
            <EyeOutlined />
          </div>
        )}
        <Select
          defaultValue={"Please Select a Option"}
          style={{ width: 420 }}
          size="large"
          value={versionList?.length > 0 ? version : null}
          onChange={handleSwitchVersion}
        >
          {versionList.map((item, index) => (
            <Option value={index}>
              {`${item.updatedAt} - ${item.updatedBy}`}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  async function handleSaveForm() {
    setLoading(true);
    try {
      const logCommonLocList = franchiseeData?.logCommonLocList || [];

      logCommonLocList.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: formVal
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          commonLocList: formVal,
          logCommonLocList
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result?.data?.entity);
      setViewMode(FORM_VIEW);
      notification.success({
        message: "Success",
        description: "Locations are successfully updated!",
        duration: 5
      });
    } catch (error) {
      notification.error({
        message: "Failed",
        description: "Saving Location failed! Please contact admin directly.",
        duration: 5
      });
    }

    setLoading(false);
  }

  function renderEditBtn() {
    return (
      <div className="loc-title-extra">
        {viewMode === FORM_VIEW && (
          <Button
            type="default"
            size="large"
            icon={<EditOutlined />}
            onClick={() => setViewMode(FORM_EDIT)}
          >
            Edit
          </Button>
        )}
        {viewMode === FORM_EDIT && (
          <>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              size="large"
              onClick={handleSaveForm}
            >
              Save
            </Button>
            <Button
              type="default"
              size="large"
              icon={<CloseOutlined />}
              onClick={() => setViewMode(FORM_VIEW)}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <Card
      className="loc"
      title="Location List"
      extra={() => renderVersion()}
      titleExtra={() => renderEditBtn()}
    >
      <div className="loc-con">
        <BlockLocation
          data={formVal}
          onChange={setFormVal}
          disabled={viewMode === FORM_VIEW}
        />
        {viewMode === FORM_VIEW && <ScreenMask />}
      </div>
      {showDiff && (
        <div className="loc-diff">
          <div className="poc_block-diff-left">
            <label className="poc_block-diff-right-label">
              {version >= 0
                ? `${versionList[version].updatedAt} - ${versionList[version].updatedBy}`
                : "Current Version"}
            </label>

            <ReactJson
              src={formVal}
              theme="monokai"
              displayDataTypes={false}
              enableClipboard={false}
            />
          </div>
          <div className="poc_block-diff-right">
            <label className="poc_block-diff-right-label">
              Current Version
            </label>
            <ReactJson
              src={activeFormVal}
              theme="monokai"
              displayDataTypes={false}
              enableClipboard={false}
            />
          </div>
        </div>
      )}
      <Loading loading={loading} />
    </Card>
  );
}
