import React, { useState } from "react";
import { userRole } from "../assets/const/auth";

const AppStateContext = React.createContext({
  authSetting: {
    isAuth: false,
    token: "",
    refreshToken: "",
    userRole: userRole.ROLE_FRANCHISOR,
    user: null,
    userPermission: "",
  },
  // Franchisor Level
  requirement: {},
  contacts: [],
  franchiseeData: {
    token: "",
    tenantID: "",
    data: [],
  },
  // Admin Level
  franchisorList: [],
  franchiseeList: [],
  franchisorAdmin: {},
});

const AppStateProvider = ({ children }) => {
  const [authSetting, setAuthSetting] = useState({
    isAuth: false,
    token: "",
    refreshToken: "",
    userRole: userRole.ROLE_FRANCHISOR,
    user: null,
  });
  const [franchiseeData, setFranchiseeData] = useState({
    token: "",
    tenantID: "",
    data: [],
    platform: "",
    apiKey: "",
  });
  const [franchisorList, setFranchisorList] = useState([]);
  const [franchiseeList, setFranchiseeList] = useState([]);
  const [franchisorAdmin, setFranchisorAdmin] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [codeList, setCodeList] = useState(null);

  return (
    <AppStateContext.Provider
      value={{
        authSetting,
        setAuthSetting,
        franchiseeData,
        setFranchiseeData,
        franchisorList,
        setFranchisorList,
        franchiseeList,
        setFranchiseeList,
        franchisorAdmin,
        setFranchisorAdmin,
        notifications,
        setNotifications,
        codeList,
        setCodeList,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

const useAppState = () => React.useContext(AppStateContext);

export { AppStateProvider, useAppState };
