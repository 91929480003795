import React from "react";
import { Input } from "@components";
import { Button, Radio } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { APP_DATE_FORMAT } from "@assets/const/form";
import { DeleteOutlined } from "@ant-design/icons";
import "./style.scss";
import CodeInput from "@components/code-input";

export default function BlockOffice({ data, codeList, onChange, disabled }) {
  function handleChangeOfficeInfo(index, key, value) {
    onChange((prev) => {
      prev[index][key] = value;

      return [...prev];
    });
  }

  function handleChangeOfficeInfoCode(index, value) {
    const codeItem = codeList.find((item) => item.code === value);

    onChange((prev) => {
      prev[index]["class_code"] = value;
      prev[index]["desc"] = codeItem?.description;

      return [...prev];
    });
  }

  // function handleAddNewOffice(index) {
  //   onChange((prev) => {
  //     prev.push({
  //       full_name: "",
  //       name_type: null,
  //       payroll: "",
  //       class_code: "",
  //       owner_ship: "",
  //       desc: ""
  //     });

  //     return [...prev];
  //   });
  // }

  function firstTimeNewOffice() {
    onChange((prev) => [
      {
        full_name: "",
        name_type: null,
        paryoll: "",
        class_code: "",
        owner_ship: "",
        desc: "",
      },
    ]);
  }

  //spreads instead of pushes to prevent unwanted state changes - causing two forms to populate
  function handleAddNewOffice() {
    onChange((prev) => [
      ...prev,
      {
        full_name: "",
        name_type: null,
        payroll: "",
        class_code: "",
        owner_ship: "",
        desc: "",
      },
    ]);
  }

  function handleDeleteOffice(index) {
    if (data.length === 0) return;

    onChange((prev) => {
      prev.splice(index, 1);

      return [...prev];
    });
  }

  function renderOfficeInfo(index, office) {
    return (
      <div key={`office-${index}`} className="block_off-item">
        <div>
          <div className="block_off-item-wrap">
            <CodeInput
              options={codeList}
              value={office.class_code}
              onChange={(evt) => handleChangeOfficeInfoCode(index, evt)}
            />
            {/* <Input
              title="Class Code"
              type="text"
              value={office.class_code}
              required
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "class_code", evt.target.value)
              }
            />
            <Input
              title="Description"
              type="text"
              value={office.desc}
              required
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "desc", evt.target.value)
              }
            /> */}

            <Input
              title="Full Name"
              type="text"
              value={office.full_name}
              required
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "full_name", evt.target.value)
              }
            />

            <Input
              title="Payroll"
              type="text"
              value={office.payroll}
              required
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "payroll", evt.target.value)
              }
            />
            <Input
              title="Ownership %"
              type="text"
              value={office.owner_ship}
              required
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "owner_ship", evt.target.value)
              }
            />
          </div>
          <div className="block_off-item-footer">
            <Radio.Group
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "name_type", evt.target.value)
              }
              value={office.name_type}
            >
              <Radio value={true}>Excluded</Radio>
              <Radio value={false}>Included</Radio>
            </Radio.Group>
          </div>
        </div>

        <div className="block_off-item-action">
          {!disabled && (
            <Button
              className="block_off-item-action-btn"
              onClick={() => handleDeleteOffice(index)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="block_off">
      {data.length === 0 && firstTimeNewOffice()}
      {data.map((office, index) => renderOfficeInfo(index, office))}
      {!disabled && (
        <div className="admin_franchisee_under-section-footer">
          <Button
            className="admin_franchisee_under-section-footer-link"
            onClick={handleAddNewOffice}
          >
            Add Owner
          </Button>
        </div>
      )}
    </div>
  );
}
