import React from "react";
import "./style.scss";
import FranchisorOverview from "./franchisor-overview";
import FranchiseeOverview from "./franchisee-overview";
import { Header, Sidebar } from "../../components";
import { Switch, Route } from "react-router-dom";
import AdminOverview from "./admin-overview";
import FranchiseeDetail from "./franchisee-detail";
import FranchiseeSummary from "./franchisee-summary";
import FranchiseeReport from "./franchisee-report";
import FranchisorReport from "./franchisor-report";
import LogReport from "./log-report";
import FranchisorReportDetail from "./franchisor-report-detail";
import AdminNotification from "./admin-notification";
import AdminProducts from "./admin-products";
import AdminAppetite from "./admin-appetite";
import AdminEligibility from "./admin-eligibility";
import AdminPricing from "./admin-princing";
import AdminHartfordAppetite from "./admin-hartford-appetite";
import AdminHartfordPreview from "./admin-hartford-preview";
import AdminSetting from "./admin-setting";
import AdminInsuranceQuote from "./admin-insurance-quote";
import AdminInsuranceQuotePreview from "./admin-insurance-quote-preview";
import { VIEW_SOURCE_ADMIN } from "@assets/const/ui";
import { Layout } from "antd";

const { Content } = Layout;

export default function AdminBoard() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar screen="admin" />
      <Layout>
        <Header screen="admin" />
        <Content>
          <Switch>
            <Route path="/admin/overview" component={AdminOverview} />
            <Route
              path="/admin/franchisors"
              exact
              component={FranchisorOverview}
            />
            <Route
              path="/admin/franchisees"
              component={FranchiseeOverview}
              exact
            />
            <Route path="/admin/notification" component={AdminNotification} />
            <Route
              path="/admin/franchisees/:id/summary"
              component={FranchiseeSummary}
            />
            <Route
              path="/admin/franchisees/:id/report"
              render={(props) => (
                <FranchiseeReport {...props} viewSource={VIEW_SOURCE_ADMIN} />
              )}
            />
            <Route
              path="/admin/franchisees/:id/insurance-quote/:quoteId"
              component={AdminInsuranceQuotePreview}
            />
            <Route
              path="/admin/franchisees/:id/insurance-quote"
              component={AdminInsuranceQuote}
            />
            <Route path="/admin/logs" component={LogReport} />
            <Route path="/admin/franchisees/:id" component={FranchiseeDetail} />
            <Route
              path="/admin/report/:id"
              component={FranchisorReportDetail}
            />
            <Route path="/admin/report" component={FranchisorReport} />
            <Route path="/admin/settings" component={AdminSetting} />
            <Route path="/admin/products/:id" component={AdminProducts} />
            <Route path="/admin/appetite/:id" component={AdminAppetite} />
            <Route
              path="/admin/eligibility/:id/:applicationId"
              component={AdminEligibility}
            />
            <Route
              path="/admin/pricing/:id/:applicationId"
              component={AdminPricing}
            />

            {/** This route is deprecated */}
            <Route
              path="/admin/hartford/appetite/:id/:quoteId"
              component={AdminHartfordPreview}
            />

            {/** This route is deprecated */}
            <Route
              path="/admin/hartford/appetite/:id"
              component={AdminHartfordAppetite}
            />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
}
