import { Alert } from "antd";
import { useState, useEffect } from "react";
import formatDuration from "format-duration";

export default function CountDown({ initialValueInMillis, onCountDownEnd }) {
  const [expiresIn, setExpiresIn] = useState(initialValueInMillis);

  useEffect(() => {
    if (expiresIn <= 0) {
      onCountDownEnd();
    }
  }, [expiresIn]);

  useEffect(() => {
    const timer = setInterval(() => {
      setExpiresIn((prev) => prev - 1000);
      if (expiresIn <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Alert
      type={expiresIn <= 0 ? "error" : "info"}
      showIcon
      message={
        expiresIn <= 0
          ? "Your session has expired."
          : `Your session will expire in ${formatDuration(expiresIn)}`
      }
    />
  );
}
