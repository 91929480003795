export function formatDollarAmount(amount) {
  if (amount === undefined || amount === null) {
    return "";
  }

  if (typeof amount === "number") {
    return `$${amount.toLocaleString()}`;
  } else if (
    typeof amount === "string" &&
    amount.trim() !== "" && 
    !isNaN(amount)
  ) {
    return `$${parseInt(amount, 10).toLocaleString()}`;
  } else {
    return "";
  }
}
