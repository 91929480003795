import { isEmpty } from "lodash";
import { isValid } from "date-fns";
import {
  COVERAGES,
  COVERAGE_KEY_LIABILITY,
  COVERAGE_KEY_PROPERTY,
  COVERAGE_KEY_WORKERS_COMP,
} from "../../../../assets/const/fim-integration";
import { notify, validateSpecialInputFields } from "../../../../utils/common";

export const useFormValidate = () => {
  return {
    validate: (formVal, selectedCoverages) => {
      let invalidInputErr = validateSpecialInputFields(
        formVal?.profileVal || {}
      );
      if (invalidInputErr) {
        notify("Invalid Input", invalidInputErr);
        return false;
      }

      if (isEmpty(selectedCoverages)) {
        notify(
          "Missing coverage selection",
          "A coverage must be selected to proceed!"
        );
        return false;
      }
      /** Fields that require validation regardless of the coverage selection. */
      if (isEmpty(formVal?.profileVal?.first_name)) {
        notify("Missing Field", "First Name is required!");
        return false;
      }
      if (isEmpty(formVal?.profileVal?.last_name)) {
        notify("Missing Field", "Last Name is required!");
        return false;
      }
      if (isEmpty(formVal?.profileVal?.email)) {
        notify("Missing Field", "Email is required!");
        return false;
      }
      if (isEmpty(formVal?.profileVal?.entity_name)) {
        notify("Missing Field", "Entity Name is required!");
        return false;
      }
      if (isEmpty(formVal?.profileVal?.entity_type)) {
        notify("Missing Field", "Entity Type is required!");
        return false;
      }
      if (isEmpty(formVal?.profileVal?.dba)) {
        notify("Missing Field", "DBA is required!");
        return false;
      }
      if (isEmpty(formVal?.profileVal?.address)) {
        notify("Missing Field", "Address is required!");
        return false;
      }
      if (isEmpty(formVal?.profileVal?.city)) {
        notify("Missing Field", "City is required!");
        return false;
      }
      if (isEmpty(formVal?.profileVal?.state)) {
        notify("Missing Field", "State is required!");
        return false;
      }
      if (isEmpty(formVal?.profileVal?.zip)) {
        notify("Missing Field", "Zip is required!");
        return false;
      }
      if (isEmpty(formVal?.profileVal?.telephone)) {
        notify("Missing Field", "Telephone is required!");
        return false;
      }

      if (isEmpty(formVal?.profileVal?.opening_date)) {
        notify("Missing Field", "Opening Date is required!");
        return false;
      }

      if (!isValid(new Date(formVal?.profileVal?.opening_date))) {
        notify("Missing Field", "Opening Date is invalid!");
        return false;
      }

      if (isEmpty(formVal?.locationVal)) {
        notify("Missing Field", "Location is Required");
        return false;
      }
      for (const locationItem of formVal.locationVal) {
        if (isEmpty(locationItem.address)) {
          notify("Missing Field", "Address is Required");
          return false;
        }
        if (isEmpty(locationItem.city)) {
          notify("Missing Field", "City is Required");
          return false;
        }
        if (isEmpty(locationItem.state)) {
          notify("Missing Field", "State is Required");
          return false;
        }
        if (isEmpty(locationItem.zip)) {
          notify("Missing Field", "Zip is Required");
          return false;
        }

        if (selectedCoverages[COVERAGE_KEY_WORKERS_COMP]) {
          // Validate per-location WorkerComp fields.
          if (isEmpty(locationItem.codeList)) {
            notify("Missing Field", "Code is Required");
            return false;
          }
          for (const codeItem of locationItem.codeList) {
            if (isEmpty(codeItem.code)) {
              notify("Missing Field", "Code is Required");
              return false;
            }
            if (isEmpty(codeItem.exposure)) {
              notify("Missing Field", "exposure is Required");
              return false;
            }
            if (isEmpty(codeItem.employeeCount)) {
              notify("Missing Field", "Employee Count is Required");
              return false;
            }
          }
        }

        if (selectedCoverages[COVERAGE_KEY_LIABILITY]) {
          // Validate per-location LiabInfo fields.
          if (isEmpty(locationItem?.liabInfo?.riskCodes?.spectrumClassCode)) {
            notify("Missing Field", "Spectrum Class Code is Required");
            return false;
          }

          if (isEmpty(locationItem?.liabInfo?.annualRevenue)) {
            notify("Missing Field", "Annual Revenue is Required");
            return false;
          }
          if (isEmpty(locationItem?.liabInfo?.totalPayroll)) {
            notify("Missing Field", "Total Payroll is Required");
            return false;
          }
          if (isEmpty(locationItem?.liabInfo?.totalSubCosts)) {
            notify("Missing Field", "Total Sub Costs is Required");
            return false;
          }
          if (isEmpty(locationItem?.liabInfo?.numberOfLosses)) {
            notify("Missing Field", "Number of losses is Required");
            return false;
          }
        }

        if (selectedCoverages[COVERAGE_KEY_PROPERTY]) {
          // Validate per-location PropInfo fields.
          if (isEmpty(locationItem?.propInfo?.totalArea)) {
            notify("Missing Field", "Total Area is Required");
            return false;
          }

          if (isEmpty(locationItem?.propInfo?.yearBuilt)) {
            notify("Missing Field", "Year Built is Required");
            return false;
          }
          if (isEmpty(locationItem?.propInfo?.bppLimit)) {
            notify("Missing Field", "BPP Limit is Required");
            return false;
          }
          if (isEmpty(locationItem?.propInfo?.constructionTypeCode)) {
            notify("Missing Field", "Construction Type Code is Required");
            return false;
          }
          if (isEmpty(locationItem?.propInfo?.numberOfStories)) {
            notify("Missing Field", "Number of stories is Required");
            return false;
          }
        }
      }

      for (const coverageKey in formVal.effectiveDate) {
        const err = formVal.effectiveDate[coverageKey]?.error;
        if (err) {
          notify(
            `Invalid effective date for coverage "${COVERAGES[coverageKey]?.title}"`,
            err
          );
          return false;
        }
      }

      for (const coverageKey in formVal.expirationDate) {
        const err = formVal.expirationDate[coverageKey]?.error;
        if (err) {
          notify(
            `Invalid expiration date for coverage "${COVERAGES[coverageKey]?.title}"`,
            err
          );
          return false;
        }
      }

      /** Fields that require validation if Property is selected. */
      if (selectedCoverages[COVERAGE_KEY_PROPERTY]) {
        if (!formVal.effectiveDate[COVERAGE_KEY_PROPERTY]) {
          notify(
            "Missing Field",
            `Effective date field required for ${COVERAGES[COVERAGE_KEY_PROPERTY]?.title} coverage.`
          );
          return false;
        }
        if (!formVal.expirationDate[COVERAGE_KEY_PROPERTY]) {
          notify(
            "Missing Field",
            `Expiration date field required for ${COVERAGES[COVERAGE_KEY_PROPERTY]?.title} coverage.`
          );
          return false;
        }
      }

      /** Fields that require validation if Workers Compensation is selected. */
      if (selectedCoverages[COVERAGE_KEY_WORKERS_COMP]) {
        if (!formVal.effectiveDate[COVERAGE_KEY_WORKERS_COMP]) {
          notify(
            "Missing Field",
            `Effective date field required for ${COVERAGES[COVERAGE_KEY_WORKERS_COMP]?.title} coverage.`
          );
          return false;
        }
        if (!formVal.expirationDate[COVERAGE_KEY_WORKERS_COMP]) {
          notify(
            "Missing Field",
            `Expiration date field required for ${COVERAGES[COVERAGE_KEY_WORKERS_COMP]?.title} coverage.`
          );
          return false;
        }

        if (!formVal.underwritingInfo?.modifier) {
          notify("Missing Field", `Experience Modifier is required.`);
          return false;
        } else {
          if (
            formVal.underwritingInfo.modifier > 2.0 ||
            formVal.underwritingInfo.modifier < 0.4
          ) {
            notify(
              "Invalid Field",
              `Experience Modifier must be between 0.40 and 2.00`
            );
            return false;
          }
        }

        if (isEmpty(formVal?.officeVal)) {
          notify("Missing Field", "Office is Required");
          return false;
        }
        for (const officeItem of formVal.officeVal) {
          if (isEmpty(officeItem.full_name)) {
            notify("Missing Field", "Full Name is Required");
            return false;
          }
          if (isEmpty(officeItem.payroll)) {
            notify("Missing Field", "Payroll is Required");
            return false;
          }
          if (isEmpty(officeItem.class_code)) {
            notify("Missing Field", "Class Code is Required");
            return false;
          }
          if (isEmpty(officeItem.owner_ship)) {
            notify("Missing Field", "Ownership is Required");
            return false;
          }
        }
      }

      /** Fields that require validation if Liability is selected.  */
      if (selectedCoverages[COVERAGE_KEY_LIABILITY]) {
        if (!formVal.effectiveDate[COVERAGE_KEY_LIABILITY]) {
          notify(
            "Missing Field",
            `Effective date field required for ${COVERAGES[COVERAGE_KEY_LIABILITY]?.title} coverage.`
          );
          return false;
        }
        if (!formVal.expirationDate[COVERAGE_KEY_LIABILITY]) {
          notify(
            "Missing Field",
            `Expiration date field required for ${COVERAGES[COVERAGE_KEY_LIABILITY]?.title} coverage.`
          );
          return false;
        }
      }

      return true;
    },
  };
};
