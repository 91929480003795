import React from "react";
import { Input, Switch, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { PERMISSION_TABLE, ACCESS_FULL } from "../../../../../utils/permission";
import "./style.scss";

export default function CarrierAppetite(props) {
  const {
    coverage,
    carrierList,
    label = false,
    editable,
    onChangeOption,
    onChangeCarrier,
    onAddOption,
    onDeleteCarrier,
    onDeleteOption,
  } = props;

  return (
    <div className="carrier_appetite">
      <div className="carrier_appetite-main">
        {label && <label>Coverage</label>}
        {editable && (
          <Input
            size="large"
            value={coverage}
            onChange={(evt) => onChangeCarrier("coverage", evt.target.value)}
          />
        )}
        {!editable && <h3>{coverage}</h3>}
        {editable && <a onClick={onDeleteCarrier}>Delete Carrier</a>}
      </div>
      <div className="carrier_appetite-form">
        <div className="carrier_appetite-form-title">
          {label && (
            <label className="carrier_appetite-form-title-label">Carrier</label>
          )}
          {label && (
            <label className="carrier_appetite-form-title-label">
              Appetite
            </label>
          )}
          {label && (
            <label className="carrier_appetite-form-title-label">Notes</label>
          )}
        </div>
        <div className="carrier_appetite-form-value">
          {carrierList.map((item, index) => (
            <div className="carrier_appetite-form-value-item">
              {!editable && <div>{item.carrier}</div>}
              {editable && (
                <Input
                  size="large"
                  value={item.carrier}
                  onChange={(evt) =>
                    onChangeOption(index, "carrier", evt.target.value)
                  }
                />
              )}
              <div>
                <Switch
                  checked={item.appetite}
                  onChange={(checked) =>
                    onChangeOption(index, "appetite", checked)
                  }
                />
              </div>
              <Input
                size="large"
                value={item.notes}
                onChange={(evt) =>
                  onChangeOption(index, "notes", evt.target.value)
                }
              />
              {editable && (
                <div className="class_code-action">
                  <DeleteOutlined
                    className="class_code-action-del"
                    onClick={() => onDeleteOption(index)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="carrier_appetite-footer">
        {editable && (
          <>
            <Button
              size="large"
              className="carrier_appetite-footer-add"
              type="default"
              onClick={onAddOption}
            >
              Add Option
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
