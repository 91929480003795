import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  Input,
  Loading,
  FormMask,
  Textarea,
  ScreenMask,
} from "../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../utils/axios";
import {
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  FORM_POLICY,
  FORM_PROJECT,
  FORM_LOC,
  FORM_NONE,
  SOURCE_ONBOARDING,
} from "../../../assets/const/form";

import { useAppState } from "@context";

export default function LiabilityForm(props) {
  const { data, btnLabel, source, onNext, readOnly = false } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;

  const [formVal, setFormVal] = useState({
    commercial_general_policy: sourceData?.commercial_general_policy || "",
    commercial_general_aggregate_limit:
      sourceData?.commercial_general_aggregate_limit || "",
    commercial_general_liability_additional_insurance:
      sourceData?.commercial_general_liability_additional_insurance || false,
    commercial_general_liability_subrogration_waved:
      sourceData?.commercial_general_liability_subrogration_waved || false,
    commercial_general_liability_broker_note:
      sourceData?.commercial_general_liability_broker_note || "",
    commercial_general_liability_each_occurance:
      sourceData?.commercial_general_liability_each_occurance || "",
    commercial_general_liability_damage_rented_premises:
      sourceData?.commercial_general_liability_damage_rented_premises || "",
    commercial_general_liability_medical_expenses:
      sourceData?.commercial_general_liability_medical_expenses || "",
    commercial_general_liability_personal_adv_injury:
      sourceData?.commercial_general_liability_personal_adv_injury || "",
    commercial_general_liability_general_aggregate:
      sourceData?.commercial_general_liability_general_aggregate || "",
    commercial_general_liability_comprop_acg:
      sourceData?.commercial_general_liability_comprop_acg || "",
    commercial_general_liability_required:
      sourceData?.commercial_general_liability_required || false,
  });

  useEffect(() => {
    setFormVal({
      commercial_general_policy: sourceData?.commercial_general_policy || "",
      commercial_general_aggregate_limit:
        sourceData?.commercial_general_aggregate_limit || "",
      commercial_general_liability_additional_insurance:
        sourceData?.commercial_general_liability_additional_insurance || false,
      commercial_general_liability_subrogration_waved:
        sourceData?.commercial_general_liability_subrogration_waved || false,
      commercial_general_liability_broker_note:
        sourceData?.commercial_general_liability_broker_note || "",
      commercial_general_liability_each_occurance:
        sourceData?.commercial_general_liability_each_occurance || "",
      commercial_general_liability_damage_rented_premises:
        sourceData?.commercial_general_liability_damage_rented_premises || "",
      commercial_general_liability_medical_expenses:
        sourceData?.commercial_general_liability_medical_expenses || "",
      commercial_general_liability_personal_adv_injury:
        sourceData?.commercial_general_liability_personal_adv_injury || "",
      commercial_general_liability_general_aggregate:
        sourceData?.commercial_general_liability_general_aggregate || "",
      commercial_general_liability_comprop_acg:
        sourceData?.commercial_general_liability_comprop_acg || "",
      commercial_general_liability_required:
        sourceData?.commercial_general_liability_required || false,
    });
  }, [sourceData]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleSubmit() {
    handleFormSubmit();
  }

  async function handleFormSubmit() {
    const { onUpdate } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="liability-content">
      <div className="form-header">
        <div className="line-disable">
          <Switch
            checked={formVal.commercial_general_liability_required}
            onChange={(checked) =>
              handleChange("commercial_general_liability_required", checked)
            }
          />
          <span className="disable-title">Required</span>
        </div>
      </div>
      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <h5 className="radio-title">Commecial General Liability</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("commercial_general_policy", evt.target.value)
              }
              value={formVal.commercial_general_policy}
            >
              <Radio value={FORM_OCCURRENCE}>Occurrence</Radio>
              <Radio value={FORM_CLAIMS_MADE}>Claims Made</Radio>
            </Radio.Group>
          </div>
          <div className="radio-container">
            <h5 className="radio-title">GEN”L AGGREGATE LIMIT APPLIES PER:</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange(
                  "commercial_general_aggregate_limit",
                  evt.target.value
                )
              }
              value={formVal.commercial_general_aggregate_limit}
            >
              <Radio value={FORM_POLICY}>Policy</Radio>
              <Radio value={FORM_PROJECT}>Project</Radio>
              <Radio value={FORM_LOC}>Loc</Radio>
              <Radio value={FORM_NONE}>None</Radio>
            </Radio.Group>
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={
                formVal.commercial_general_liability_additional_insurance
              }
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_additional_insurance",
                  evt.target.checked
                )
              }
            >
              Additional Insured
            </Checkbox>
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={formVal.commercial_general_liability_subrogration_waved}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_subrogration_waved",
                  evt.target.checked
                )
              }
            >
              Waiver Of Subrogation
            </Checkbox>
          </div>
          <div className="line-item-container broker-report-container">
            <Textarea
              title="Notes from broker"
              type="text"
              placeholder="Please enter your text here."
              value={formVal.commercial_general_liability_broker_note}
              rows={10}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_broker_note",
                  evt.target.value
                )
              }
            />
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              type="number"
              placeholder="EACH OCCURRENCE"
              value={formVal.commercial_general_liability_each_occurance}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_each_occurance",
                  evt.target.value
                )
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="DAMAGE TO RENTED PERMISES"
              type="number"
              placeholder="DAMAGE TO RENTED PERMISES"
              value={
                formVal.commercial_general_liability_damage_rented_premises
              }
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_damage_rented_premises",
                  evt.target.value
                )
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="MED EXP"
              subTitle="( Any one person )"
              type="number"
              placeholder="MED EXP"
              value={formVal.commercial_general_liability_medical_expenses}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_medical_expenses",
                  evt.target.value
                )
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="PERSONAL & ADV INJURY"
              type="number"
              placeholder="PERSONAL & ADV INJURY"
              value={formVal.commercial_general_liability_personal_adv_injury}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_personal_adv_injury",
                  evt.target.value
                )
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="GENERAL AGGREGATE"
              type="number"
              placeholder="GENERAL AGGREGATE"
              value={formVal.commercial_general_liability_general_aggregate}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_general_aggregate",
                  evt.target.value
                )
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="PRODUCTS - COMP / OP AGG"
              type="number"
              placeholder="PRODUCTS - COMP / OP AGG"
              value={formVal.commercial_general_liability_comprop_acg}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_comprop_acg",
                  evt.target.value
                )
              }
            />
          </div>
        </div>
        {!formVal.commercial_general_liability_required && <FormMask />}
      </div>
      <div className="form-footer">
        {!readOnly && (
          <div className="button-container">
            <Button type="primary" onClick={handleSubmit}>
              {btnLabel || "Save"}
            </Button>
          </div>
        )}
      </div>
      {readOnly && <ScreenMask />}
      <Loading loading={loading} />
    </div>
  );
}
