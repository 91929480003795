import React, { useState } from "react";
import { Switch, Button } from "antd";
import InsuranceRequirementsTable from "./insurance-requirements-table";
import InsuranceRequirementsForm from "./insurance-requirements-form";
import "./style.scss";

export default function InsuranceRequirements(props) {
  const [isTable, setIsTable] = useState(false);

  function handleSelectTemplate() {}

  function handleSaveTemplate() {}

  return (
    <div className="onboard_insurance_requirement">
      <div className="onboard_insurance_requirement-header">
        <div className="onboard_insurance_requirement-header-action">
          {isTable && (
            <>
              <Button
                size="large"
                className="section_table-header-toolbar-select"
                onClick={handleSelectTemplate}
              >
                Select Template
              </Button>

              <Button size="large" onClick={handleSaveTemplate}>
                Save As Template
              </Button>
            </>
          )}

          <label>Form View</label>
          <Switch checked={isTable} onChange={() => setIsTable(!isTable)} />
          <label>Table View</label>
        </div>
      </div>
      {!isTable && (
        <InsuranceRequirementsForm
          source={props.source}
          handleNavigate={props.handleNavigate}
        />
      )}
      {isTable && (
        <div className="onboard_insurance_requirement-container">
          <InsuranceRequirementsTable />
        </div>
      )}
    </div>
  );
}
