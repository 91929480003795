import { Card } from "antd";
import { convertStatus } from "../../../../../../../utils/insurance-quote";
import { Input } from "../../../../../../../components";

export default function PropertyDetails(props) {
  return (
    <Card className="hartford_preview-detail" title={props.title ?? "Property"}>
      <div className="form-group-container">
        <Input
          title="Quote Identifier"
          type="text"
          value={props.quoteIdentifier}
          disabled
        />
        <Input
          title="Streamlined Quote Status"
          type="text"
          value={convertStatus(props.streamlinedQuoteStatus || "")}
          disabled
        />
      </div>
      <div className="form-group-container">
        <div className="form-group-container">
          <Input
            title="Policy Number"
            type="text"
            value={props.policyNumber}
            disabled
          />
          <Input
            title="Total Premium"
            type="text"
            value={props.totalPremium}
            disabled
          />
        </div>
        <div className="form-group-container">
          <Input
            title="BOPTypeCode"
            type="text"
            value={props.BOPTypeCode}
            disabled
          />
        </div>
      </div>
      {props.quoteAccessURL && (
        <div>
          <div>
            <b>Quote Access URL</b>
          </div>
          <div className="quote-access-url-container">
            <a href={props.quoteAccessURL} target="_blank">
              {props.quoteAccessURL}
            </a>
          </div>
        </div>
      )}
    </Card>
  );
}
