import React, { useState, useEffect } from "react";
import "./style.scss";
import { Input, Loading } from "@components";
import { Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { post, put } from "@utils/axios";
import { usePlacesWidget } from "react-google-autocomplete";
import { getAddressItem } from "@utils/address";
import { useAppState } from "@context";

export default function AccountInfoForm({ handleNavigate }) {
  const { getAccessTokenSilently } = useAuth0();
  const { authSetting, franchisorAdmin, setFranchisorAdmin } = useAppState();
  const [loading, setLoading] = useState(false);

  const [formVal, setFormVal] = useState({
    personal_infor_business_name: franchisorAdmin.personal_infor_business_name
  });

  const [addressForm, setAddressForm] = useState({
    personal_infor_address: franchisorAdmin.personal_infor_address,
    personal_infor_city: franchisorAdmin.personal_infor_city,
    personal_infor_state: franchisorAdmin.personal_infor_state,
    personal_infor_zip: franchisorAdmin.personal_infor_zip
  });

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"]
    },
    onPlaceSelected: (place) => {
      let zipCode = "",
        state = "",
        city = "";
      if (place.address_components) {
        zipCode = getAddressItem(
          place.address_components,
          "postal_code",
          "short_name"
        );
        state = getAddressItem(
          place.address_components,
          "administrative_area_level_1",
          "short_name"
        );
        city = getAddressItem(
          place.address_components,
          "locality",
          "long_name"
        );
        const street = getAddressItem(
          place.address_components,
          "street_number",
          "long_name"
        );
        const route = getAddressItem(
          place.address_components,
          "route",
          "long_name"
        );
        setAddressForm({
          ...addressForm,
          personal_infor_city: city,
          personal_infor_state: state,
          personal_infor_zip: zipCode,
          personal_infor_address: `${street} ${route}`
        });
      }
    }
  });

  useEffect(() => {
    setFormVal({
      personal_infor_business_name: franchisorAdmin.personal_infor_business_name
    });
    setAddressForm({
      personal_infor_address: franchisorAdmin.personal_infor_address,
      personal_infor_city: franchisorAdmin.personal_infor_city,
      personal_infor_state: franchisorAdmin.personal_infor_state,
      personal_infor_zip: franchisorAdmin.personal_infor_zip
    });
  }, [franchisorAdmin, authSetting]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value
    });
  }

  function handleChangeAddress(key, value) {
    setAddressForm({
      ...addressForm,
      [key]: value
    });
  }

  async function handleFormSubmit() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const param = {
        ...formVal,
        ...addressForm
      };
      if (franchisorAdmin._id) {
        const result = await put(
          `requirement/${franchisorAdmin._id}`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          setFranchisorAdmin(result.data.data);
        }
      } else {
        const result = await post(
          `requirement`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          setFranchisorAdmin(result.data.data);
        }
      }
      setLoading(false);
      handleNavigate();
    } catch (e) {
      setLoading(false);
    }
  }

  function handleSave() {
    handleFormSubmit();
  }

  return (
    <div className="onboarding_form_account">
      <div className="onboarding_form_account_header">
        <h3 className="title">Account Information</h3>
      </div>
      <div className="onboarding_form_account_main">
        <div className="panel-left">
          <div className="line-item-container">
            <Input
              title="Entity NAME"
              type="text"
              placeholder="Entity NAME"
              value={formVal.personal_infor_business_name}
              onChange={(evt) =>
                handleChange("personal_infor_business_name", evt.target.value)
              }
            />
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="ADDRESS*"
              type="text"
              placeholder="ADDRESS"
              ref={ref}
              inputType="TYPE_INPUT_ADDRESS"
              value={addressForm.personal_infor_address}
              onChange={(evt) =>
                handleChangeAddress("personal_infor_address", evt.target.value)
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="CITY*"
              type="text"
              placeholder="CITY"
              value={addressForm.personal_infor_city}
              onChange={(evt) =>
                handleChangeAddress("personal_infor_city", evt.target.value)
              }
            />
          </div>
          <div className="group-item-container">
            <Input
              title="STATE*"
              type="text"
              placeholder="STATE"
              value={addressForm.personal_infor_state}
              onChange={(evt) =>
                handleChangeAddress("personal_infor_state", evt.target.value)
              }
            />
            <Input
              title="ZIP*"
              type="text"
              placeholder="ZIP"
              value={addressForm.personal_infor_zip}
              onChange={(evt) =>
                handleChangeAddress("personal_infor_zip", evt.target.value)
              }
            />
          </div>
        </div>
      </div>
      <div className="onboarding_form_account_footer">
        <div className="button-container">
          <Button type="primary" onClick={handleSave}>
            Save & Activate
          </Button>
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}
