import { useEffect } from "react";
import {
  COVERAGE_KEY_LIABILITY,
  COVERAGE_KEY_PROPERTY,
  COVERAGE_KEY_WORKERS_COMP,
} from "../../../../../assets/const/fim-integration";
import { TYPE_INPUT_DIGIT } from "../../../../../assets/const/ui";
import { AddressInput, Input } from "../../../../../components";
import "./style.scss";
import BopInforBlock from "../bop-infor-block";
import FormLiab from "../../../admin-insurance-quote/components/form-liab/form-liab";
import FormCode from "../../../admin-insurance-quote/components/form-code";
import { Select } from "antd";

export default function LocationBlock(props) {
  const {
    franchiseeData,
    codeData, // required for Liab and WC LocationBlock
    formCode, // required for Liab LocationBlock
    data,
    disabled,
    onChange,
    coverageType,
    allowEmptyLocations,
  } = props;

  useEffect(() => {
    // Trigger initial change to backfill data into the new empty "per-location" data schema for the first location.
    // because before this, we only allowed 1 location per coverage data.
    const locationItem = data[0];
    if (locationItem?.propInfo === undefined) {
      for (const [key, value] of Object.entries(
        franchiseeData?.bopInfor || {}
      )) {
        handleChangePropInfo(0, key, value);
      }
    }
    if (locationItem?.liabInfo === undefined) {
      for (const [key, value] of Object.entries(
        franchiseeData?.liabInfo || {}
      )) {
        handleChangeLiabInfo(0, key, value);
      }
    }
    if (locationItem?.liabInfo?.riskCodes === undefined) {
      for (const [key, value] of Object.entries(formCode || {})) {
        handleChangeLiabRiskCodes(0, key, value);
      }
    }
  }, []);

  function handleChangeLocation(index, key, value) {
    data[index][key] = value;
    onChange([...data]);
  }

  function handleChangeCode(index, codeItemIndex, key, value) {
    // Code is a WorkerComp related input field.
    data[index].codeList[codeItemIndex][key] = value;
    onChange([...data]);
  }

  function handleChangeLiabInfo(index, key, value) {
    if (!data[index]?.liabInfo) {
      data[index].liabInfo = {};
    }
    data[index].liabInfo[key] = value;
    onChange([...data]);
  }

  function handleChangeLiabRiskCodes(index, key, value) {
    if (!data[index]) {
      data[index] = {};
    }
    if (!data[index].liabInfo) {
      data[index].liabInfo = {};
    }
    if (!data[index].liabInfo.riskCodes) {
      data[index].liabInfo.riskCodes = {};
    }
    data[index].liabInfo.riskCodes[key] = value;
    onChange([...data]);
  }

  function handleChangePropInfo(index, key, value) {
    if (!data[index]?.propInfo) {
      data[index].propInfo = {};
    }
    data[index].propInfo[key] = value;
    onChange([...data]);
  }

  function handleChangeLocationAddress(index, address) {
    data[index]["address"] = address.address;
    data[index]["city"] = address.city;
    data[index]["state"] = address.state;
    data[index]["zip"] = address.zip;

    onChange([...data]);
  }

  function handleAddLocation() {
    data.push({
      address: "",
      city: "",
      state: "",
      zip: "",
      codeList: [
        {
          code: "",
          exposure: "",
          employeeCount: "",
        },
      ],
      propInfo: {},
      liabInfo: {},
    });
    onChange([...data]);
  }

  function handleAddCode(index) {
    if (data[index].codeList) {
      data[index].codeList.push({
        code: "",
        exposure: "",
        employeeCount: "",
      });
    } else {
      data[index].codeList = [
        {
          code: "",
          exposure: "",
          employeeCount: "",
        },
      ];
    }

    onChange([...data]);
  }

  function handleDeleteCode(index) {
    if (data[index].codeList && data[index].codeList.length > 1) {
      data[index].codeList.pop();
      onChange([...data]);
    }
  }

  function handleDeleteLocation() {
    if (data.length > 1 || allowEmptyLocations) {
      data.pop();
      onChange([...data]);
    }
  }

  function renderCoverageSpecificInputFields(locationItem, index) {
    switch (coverageType) {
      case COVERAGE_KEY_WORKERS_COMP:
        return renderWorkerCompInputFields(locationItem, index);
      case COVERAGE_KEY_PROPERTY:
        return renderPropertyInputFields(locationItem, index);
      case COVERAGE_KEY_LIABILITY:
        return renderLiabilityInputFields(locationItem, index);
    }
  }

  function renderWorkerCompInputFields(locationItem, index) {
    return (
      <div>
        {locationItem.codeList &&
          coverageType == COVERAGE_KEY_WORKERS_COMP &&
          locationItem.codeList.map((codeItem, codeItemIndex) => (
            <div
              key={`code-item-${index}`}
              className="form-group-three-container"
            >
              <div>
                <p className="input-title">
                  Class Code & Description
                  <span className="input-required">*</span>
                </p>
                <Select
                  value={codeItem.code}
                  size="large"
                  style={{ width: "100%" }}
                  onChange={(value) =>
                    handleChangeCode(index, codeItemIndex, "code", value)
                  }
                >
                  {codeData
                    .filter((c) => c.eleType === "WC")
                    .map((item) => (
                      <Select.Option value={item.code}>
                        {item.code} ({item.description})
                      </Select.Option>
                    ))}
                </Select>
              </div>
              <Input
                title="Estimated Exposure"
                type="text"
                inputType={TYPE_INPUT_DIGIT}
                value={codeItem.exposure}
                disabled={disabled}
                required
                onChange={(evt) =>
                  handleChangeCode(
                    index,
                    codeItemIndex,
                    "exposure",
                    evt.target.value
                  )
                }
              />
              <Input
                title="Employee Count"
                type="text"
                inputType={TYPE_INPUT_DIGIT}
                value={codeItem.employeeCount}
                disabled={disabled}
                required
                onChange={(evt) =>
                  handleChangeCode(
                    index,
                    codeItemIndex,
                    "employeeCount",
                    evt.target.value
                  )
                }
              />
            </div>
          ))}

        {!disabled && coverageType == COVERAGE_KEY_WORKERS_COMP && (
          <div>
            <a
              className="admin_franchisee_under-section-footer-link"
              onClick={() => handleAddCode(index)}
            >
              Add Code
            </a>
            <a
              className="admin_franchisee_under-section-footer-link"
              onClick={() => handleDeleteCode(index)}
            >
              Delete Code
            </a>
          </div>
        )}
      </div>
    );
  }

  function renderPropertyInputFields(locationItem, index) {
    return (
      <BopInforBlock
        data={locationItem.propInfo || franchiseeData.bopInfor || {}}
        onChange={(key, value) => {
          handleChangePropInfo(index, key, value);
        }}
      />
    );
  }

  function renderLiabilityInputFields(locationItem, index) {
    return (
      <>
        <FormLiab
          formVal={locationItem?.liabInfo || franchiseeData.liabInfo || {}}
          onChange={(key, value) => {
            handleChangeLiabInfo(index, key, value);
          }}
        />
        <FormCode
          formVal={locationItem?.liabInfo?.riskCodes || formCode || {}}
          codeData={codeData}
          onChange={(key, value) => {
            handleChangeLiabRiskCodes(index, key, value);
          }}
        />
      </>
    );
  }

  return (
    <div className="admin_franchisee_under-section">
      {data.map((locationItem, index) => (
        <div className="location-block" key={`location-item-${index}`}>
          <div className="form-group-three-container">
            <AddressInput
              title="Location"
              type="text"
              value={locationItem.address}
              disabled={disabled}
              onChange={(value) =>
                handleChangeLocation(index, "address", value)
              }
              onSelect={(address) =>
                handleChangeLocationAddress(index, address)
              }
            />
            <div>
              <Input
                title="City"
                type="text"
                value={locationItem.city}
                disabled={disabled}
                required
                onChange={(evt) =>
                  handleChangeLocation(index, "city", evt.target.value)
                }
              />
            </div>
            <div className="form-group-two-container">
              <Input
                title="State"
                type="text"
                value={locationItem.state}
                disabled={disabled}
                required
                onChange={(evt) =>
                  handleChangeLocation(index, "state", evt.target.value)
                }
              />
              <Input
                title="Zip"
                type="text"
                value={locationItem.zip}
                disabled={disabled}
                required
                onChange={(evt) =>
                  handleChangeLocation(index, "zip", evt.target.value)
                }
              />
            </div>
          </div>
          {renderCoverageSpecificInputFields(locationItem, index)}
        </div>
      ))}
      {!disabled && (
        <div className="admin_franchisee_under-section-footer">
          <a
            className="admin_franchisee_under-section-footer-link"
            onClick={handleAddLocation}
          >
            Add Location
          </a>
          <a
            className="admin_franchisee_under-section-footer-link"
            onClick={handleDeleteLocation}
          >
            Delete Location
          </a>
        </div>
      )}
    </div>
  );
}
