import React from "react";
import "./style.scss";
import imageLogo from "../../assets/images/fim-logo.png";
import { COVERAGES } from "../../assets/const/fim-integration";

export default function QuoteHeader(props) {
  const { franchiseeData, requirement, user, expirationDate } = props;
  let today = new Date();
  let currentDate =
    today.getDate() +
    "/" +
    parseInt(today.getMonth() + 1) +
    "/" +
    today.getFullYear();

  function constructAddressCityString(address, city) {
    if (address && city) {
      return `${address}, ${city}, `;
    }
    return address || city || "";
  }

  function constructStateZipCodeString(state, zipCode) {
    if (state && zipCode) {
      return `${state} - ${zipCode}`;
    }
    return state || zipCode || "";
  }

  return (
    <div className="quote_header">
      <div className="product-header-logo-box">
        <img className="product-logo-img" src={imageLogo} />
      </div>
      <div className="product-header-box">
        <h4>Compliant Quote Proposal™</h4>
        <h5>{franchiseeData.entity_name}</h5>
        <p>
          Franchisor Risk Profile:<br></br>
          {requirement ? requirement.personal_infor_business_name : ""}
          {constructAddressCityString(
            requirement?.personal_infor_address,
            requirement?.personal_infor_city
          )}
          <br></br>
          {constructStateZipCodeString(
            requirement?.personal_infor_state,
            requirement?.personal_infor_zip
          )}
        </p>
      </div>
      <div className="product-header-box">
        <h4>Document Prepared for:</h4>
        <p>
          {franchiseeData.entity_name} <br />
          {constructAddressCityString(
            franchiseeData?.detail?.address,
            franchiseeData?.detail?.city
          )}
          <br />
          {constructStateZipCodeString(
            franchiseeData?.detail?.state,
            franchiseeData?.detail?.zipcode
          )}
        </p>
      </div>
      <div className="product-header-box">
        <h4>Document Prepared by:</h4>
        <p>
          {user.nickname}
          <br />
          {user.email}
          <br />
          Rikor Insurance Consultancy
        </p>
      </div>
      <div className="product-header-box">
        <h4>Timestamp:</h4>
        <p>
          Created on: {currentDate}
          <br />
          {Object.keys(expirationDate || []).map((key) => (
            <div>
              {COVERAGES[key].title} Expiration Date:{" "}
              {expirationDate[key].value}
            </div>
          ))}
        </p>
      </div>
    </div>
  );
}
