const BUILDING_SECURITY_PROTOCOLS = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "locks",
    label: "Locks",
  },
  {
    value: "video-surveillance",
    label: "Video Surveillance",
  },
  {
    value: "fencing",
    label: "Fencing",
  },
  {
    value: "security",
    label: "Security",
  },
  {
    value: "alarm",
    label: "Alarm",
  },
];

const DEV_ORIGIN = [
  "http://localhost:3000",
  "https://dev.app.rikor.io",
  "https://app.rikor.io",
];

export { BUILDING_SECURITY_PROTOCOLS, DEV_ORIGIN };
