import { useMemo } from "react";

export function useTotalLogMainData(logMainData, report) {
  return useMemo(() => {
    const updatedData = [...logMainData];
    updatedData.push(
      {
        coverage: "Franchisees",
        date: "Today",
        value: report.countFranchisee,
      },
      {
        coverage: "Rikor Customers",
        date: "Today",
        value: report.countRikorCustomer,
      }
    );
    return updatedData;
  }, [logMainData, report]);
}

export function useTotalLogPartData(logPartData, report) {
  return useMemo(() => {
    const updatedData = [...logPartData];
    updatedData.push(
      {
        coverage: "Lapse in Coverage",
        date: "Today",
        value: report.countExpired,
      },
      {
        coverage: "Non-compliant",
        date: "Today",
        value: report.countNonCompliant,
      },
      {
        coverage: "Not-Verified",
        date: "Today",
        value: report.countPending,
      }
    );
    return updatedData;
  }, [logPartData, report]);
}

export function getLogConfig(data) {
  return {
    data,
    xField: "date",
    yField: "value",
    seriesField: "coverage",
    yAxis: {
      label: {
        formatter: (v) => `${v}`,
      },
    },
    legend: {
      position: "top",
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };
}
