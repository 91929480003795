import React, { useEffect, useState } from "react";
import { Table, Input, notification, Button, Modal, Popconfirm } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, post, get, put } from "../../../../utils/axios";
import CheckForm from "./check-form";
import CheckItem from "./check-item";
import "./style.scss";

export default function AdminZorCheck(props) {
  const [checkList, setCheckList] = useState([]);
  const [activeCheckItem, setActiveCheckItem] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    handleLoadCheckList();
  }, []);

  async function handleLoadCheckList() {
    try {
      const token = await getAccessTokenSilently();
      const result = await get(`check`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result?.data?.success) {
        setCheckList(result?.data?.data || []);
      }
    } catch (error) {}
  }
  function handleAdd() {
    setActiveCheckItem({
      _id: null,
    });
  }

  async function handleSave(title) {
    if (activeCheckItem?._id) {
      try {
        const token = await getAccessTokenSilently();
        const result = await put(
          `check/${activeCheckItem?._id}`,
          {
            title: title,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result?.data?.success) {
          const index = checkList.findIndex(
            (item) => item._id == result?.data?.data._id
          );
          checkList.splice(index, 1, result?.data?.data);
          setCheckList([...checkList]);
          setActiveCheckItem(null);
        }
      } catch (error) {}
    } else {
      try {
        const token = await getAccessTokenSilently();
        const result = await post(
          `check`,
          {
            title: title,
            completed: false,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result?.data?.success) {
          checkList.push(result?.data?.data);
          setCheckList([...checkList]);
          setActiveCheckItem(null);
        }
      } catch (error) {}
    }
  }

  function handleCancel() {
    setActiveCheckItem(null);
  }

  async function handleToggle(value, id) {
    if (id) {
      try {
        const token = await getAccessTokenSilently();
        const result = await put(
          `check/${id}`,
          {
            completed: value,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result?.data?.success) {
          const index = checkList.findIndex(
            (item) => item._id == result?.data?.data._id
          );
          checkList.splice(index, 1, result?.data?.data);
          setCheckList([...checkList]);
          setActiveCheckItem(null);
        }
      } catch (error) {}
    }
  }

  async function handleDelete(id) {
    try {
      const token = await getAccessTokenSilently();
      const result = await deleteAPI(`check/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result?.data?.success) {
        const checkListUpdate = checkList.filter((item) => item._id != id);
        setCheckList(checkListUpdate);
      }
    } catch (err) {}
  }

  function handleEdit(data) {
    setActiveCheckItem(data);
  }

  return (
    <div className="admin_zorcheck">
      <div className="admin_zorcheck-action">
        <Button onClick={handleAdd} type="primary">
          <PlusOutlined />
        </Button>
      </div>
      {checkList.map((checkItem) => {
        if (checkItem._id == activeCheckItem?._id) {
          return (
            <CheckForm
              data={activeCheckItem}
              onSave={handleSave}
              onCancel={handleCancel}
            />
          );
        } else {
          return (
            <CheckItem
              data={checkItem}
              onToggle={handleToggle}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          );
        }
      })}
      {activeCheckItem && !activeCheckItem._id && (
        <CheckForm
          data={activeCheckItem}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
}
