import "./style.scss";

export default function InputWrapper({
  error,
  title,
  subtitle,
  children,
  description,
  required,
  unwrapped,
}) {
  return unwrapped ? (
    <>{children}</>
  ) : (
    <div
      className={
        error ? "custom-input-container error" : "custom-input-container"
      }
    >
      {title ? (
        <p className="input-title">
          {title} <span className="input-sub-title">{subtitle}</span>
          {required && <span className="input-required">*</span>}
        </p>
      ) : (
        <></>
      )}
      {children}
      <p className="desc-text">{description}</p>
      {error && (
        <div className="error-log">
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}
