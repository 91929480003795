import {format, parseISO} from "date-fns"

export function formatToMonthDayYear(dateStr) {
  if (!dateStr || typeof dateStr !== "string") return "";
  try {
    return format(parseISO(dateStr), "MM-dd-yyyy");
  } catch (error) {
    console.error("Invalid date string provided:", dateStr, error);
    return "";
  }
}
