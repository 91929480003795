import React from "react";
import "./style.scss";

export default function Card(props) {
  const {
    children,
    title,
    extra,
    titleExtra,
    headerEnabled = true,
    className = ""
  } = props;

  return (
    <div className={`card-container ${className}`}>
      {headerEnabled && (
        <div className="card-header">
          <div className="card-header-title">
            <h3>{title}</h3>
            {titleExtra && titleExtra()}
          </div>

          {extra && extra()}
        </div>
      )}
      <div className="card-content">{children}</div>
    </div>
  );
}
