import React, { useState } from "react";
import { AutoComplete, Input, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import "./style.scss";
import { useAppState } from "../../../../../context";
import { SpectrumCode } from "../../../../../components";
import {
  CODE_TYPE_NAICS,
  CODE_TYPE_SIC,
  CODE_TYPE_WC,
  CODE_TYPE_SPECTRUM_CLASS,
} from "../../../../../assets/const/fim-integration";
const { Option } = Select;
const TYPE_OPTIONS = [
  {
    value: CODE_TYPE_NAICS,
    label: "NAICS",
  },
  {
    value: CODE_TYPE_WC,
    label: "WC",
  },
  {
    value: CODE_TYPE_SIC,
    label: "SIC",
  },
  {
    value: CODE_TYPE_SPECTRUM_CLASS,
    label: "SPECTRUM CLASS",
  },
];

const STATE_OPTIONS = [
  {
    value: "NCCI",
    label: "NCCI",
  },
  {
    value: "CA",
    label: "CA",
  },
  {
    value: "DE",
    label: "DE",
  },
  {
    value: "MI",
    label: "MI",
  },
  {
    value: "NJ",
    label: "NJ",
  },
  {
    value: "NY",
    label: "NY",
  },
  {
    value: "PA",
    label: "PA",
  },
  {
    value: "TX",
    label: "TX",
  },
];

const FILED_MAP = {
  NAICS: {
    code: "naics",
    desc: "naics_desc",
  },
  WC: {
    code: "wc_ncci",
    desc: "ncci_desc",
  },
  SIC: {
    code: "sic",
    desc: "ge_desc",
  },
};

export default function ClassCode({
  typeVal,
  codeVal,
  descVal,
  noteVal,
  stateVal = "NCCI",
  setStateVal,
  setTypeVal,
  setCodeVal,
  setDescVal,
  setNoteVal,
  setSelect,
  dataVal,
  onChange,
  onDelete,
}) {
  const [codeOptions, setCodeOptions] = useState([]);
  const [descOptions, setDescOptions] = useState([]);
  const { codeList } = useAppState();

  function handleSearchCode(searchStr) {
    const codeField =
      typeVal === CODE_TYPE_WC
        ? `wc_${stateVal.toLowerCase()}`
        : FILED_MAP[typeVal].code;
    const descField = FILED_MAP[typeVal].desc;

    const findCodes = codeList
      .filter((item) =>
        (item[codeField] || "").toLowerCase().includes(searchStr.toLowerCase())
      )
      .splice(0, 10);

    const filteredCodes = findCodes.map((item) => ({
      value: `${item[codeField]} - ${item[descField]}`,
    }));
    setCodeOptions([...filteredCodes]);
  }

  function handleSelectCode(codeStr) {
    setSelect(codeStr);
  }

  function handleSearchDesc(searchStr) {
    const codeField =
      typeVal === CODE_TYPE_WC
        ? `wc_${stateVal.toLowerCase()}`
        : FILED_MAP[typeVal].code;
    const descField = FILED_MAP[typeVal].desc;

    const findCodes = codeList
      .filter((item) =>
        (item[descField] || "").toLowerCase().includes(searchStr.toLowerCase())
      )
      .splice(0, 10);

    const filteredCodes = findCodes.map((item) => ({
      value: `${item[codeField]} - ${item[descField]}`,
    }));
    setDescOptions([...filteredCodes]);
  }

  return (
    <div
      className={
        typeVal === CODE_TYPE_SPECTRUM_CLASS
          ? "class_code spectrum_code"
          : "class_code"
      }
    >
      <Select
        value={typeVal}
        size="large"
        onChange={setTypeVal}
        style={{ width: "100%" }}
      >
        {TYPE_OPTIONS.map((itemOption) => (
          <Option
            key={`key-option-${itemOption.value}`}
            value={itemOption.value}
          >
            {itemOption.label}
          </Option>
        ))}
      </Select>
      {typeVal === CODE_TYPE_WC && (
        <div className="class_code-sub">
          <Select
            value={stateVal}
            size="large"
            onChange={setStateVal}
            style={{ width: "100%" }}
          >
            {STATE_OPTIONS.map((itemOption) => (
              <Option
                key={`key-state-option-${itemOption.value}`}
                value={itemOption.value}
              >
                {itemOption.label}
              </Option>
            ))}
          </Select>
          <AutoComplete
            size="large"
            value={codeVal}
            options={codeOptions}
            style={{ width: "100%" }}
            onSelect={handleSelectCode}
            onSearch={handleSearchCode}
            onChange={setCodeVal}
            placeholder="input here"
          />
        </div>
      )}
      {(typeVal === CODE_TYPE_NAICS || typeVal === CODE_TYPE_SIC) && (
        <AutoComplete
          size="large"
          value={codeVal}
          options={codeOptions}
          style={{ width: "100%" }}
          onSelect={handleSelectCode}
          onSearch={handleSearchCode}
          onChange={setCodeVal}
          placeholder="input here"
        />
      )}

      {(typeVal === CODE_TYPE_NAICS ||
        typeVal === CODE_TYPE_SIC ||
        typeVal === CODE_TYPE_WC) && (
        <AutoComplete
          size="large"
          value={descVal}
          options={descOptions}
          style={{ width: "100%" }}
          onSelect={handleSelectCode}
          onSearch={handleSearchDesc}
          onChange={setDescVal}
          placeholder="input here"
        />
      )}
      {typeVal === CODE_TYPE_SPECTRUM_CLASS && (
        <SpectrumCode
          titleFlag={false}
          optionList={dataVal.optionList}
          parentCode={dataVal.parentCode}
          childCode={dataVal.childCode}
          onChange={(key, val) => onChange(key, val)}
        />
      )}
      {(typeVal === CODE_TYPE_NAICS ||
        typeVal === CODE_TYPE_SIC ||
        typeVal === CODE_TYPE_WC) && (
        <Input value={noteVal} onChange={setNoteVal} />
      )}
      <div className="class_code-action">
        <DeleteOutlined className="class_code-action-del" onClick={onDelete} />
      </div>
    </div>
  );
}
