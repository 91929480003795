import React, { useEffect, useState } from "react";
import { Steps, Divider, Button, Form, Popconfirm } from "antd";
import "./style.scss";
import franConnectLogo from "../../../../assets/images/brand-logo/logo_franconnect.png";
import serviceMinderLogo from "../../../../assets/images/brand-logo/logo_service_minder.png";
import { Input, Loading } from "../../../../components";
import CreateUserForm from "./create-user-form";
import SigninPlatformForm from "./signin-platform-form";
import OnboardFranchiseeForm from "./onboard-franchisee-form";
import RequirementForm from "./requirement-form";
import AccountInfoForm from "./account-info-form";
import MethodForm from "./method-form";
import { useAuth0 } from "@auth0/auth0-react";
import { post, put } from "../../../../utils/axios";
import { REQUIREMENT_STATUS_DASHBOARD } from "../../../../assets/const/status";

const { Step } = Steps;

const ONBOARDING_STEP_CREATE_USER = 0;
const ONBOARDING_STEP_SELECT_PLATFORM = 1;
const ONBOARDING_STEP_SIGNIN_PLATFORM = 2;
const ONBOARDING_STEP_ONBOARD_FRANCHISEE = 3;
const ONBOARDING_STEP_REQUIREMENT = 4;
const ONBOARDING_STEP_ACCOUNT_INFO = 5;

const boardPlatforms = [
  { title: "Franconnect", url: franConnectLogo, key: "franconnect" },
  {
    title: "Service Minder",
    url: serviceMinderLogo,
    key: "serviceminder"
  }
];

export default function OnboardingForm(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [currentStep, setCurrentStep] = useState(ONBOARDING_STEP_CREATE_USER); // ONBOARDING_STEP_CREATE_USER
  const [franchisorData, setFranchisorData] = useState(null);
  const [franchiseeData, setFranchiseeData] = useState(null);
  const [userInfor, setUserInfor] = useState(null);
  const [integrationKey, setIntegrationKey] = useState("");

  const [createUserForm] = Form.useForm();
  const [signinPlatformForm] = Form.useForm();
  const [onboardFranchiseeForm] = Form.useForm();

  function handleOk() {
    const { onClose, onRefresh } = props;
    if (currentStep === ONBOARDING_STEP_CREATE_USER) {
      createUserForm.submit();
      return;
    }

    if (currentStep === ONBOARDING_STEP_SELECT_PLATFORM) {
      return;
    }

    if (currentStep === ONBOARDING_STEP_SIGNIN_PLATFORM) {
      signinPlatformForm.submit();
      return;
    }

    if (currentStep === ONBOARDING_STEP_ONBOARD_FRANCHISEE) {
      onboardFranchiseeForm.submit();
      return;
    }

    if (currentStep === ONBOARDING_STEP_REQUIREMENT) {
      setCurrentStep(ONBOARDING_STEP_ACCOUNT_INFO);
    }
  }

  function handleUpdateForm(updateFormVal) {
    setFranchisorData(updateFormVal);
  }

  async function handleUpdateUserStatus() {
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `requirement/${franchisorData?._id}`,
        { status: REQUIREMENT_STATUS_DASHBOARD },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {}
  }

  function handleSignInFranchisee(key_platform) {
    setCurrentStep(ONBOARDING_STEP_SIGNIN_PLATFORM);
    setIntegrationKey(key_platform);
  }

  function handleCancel() {
    const { onClose } = props;

    if (currentStep === ONBOARDING_STEP_CREATE_USER) {
      createUserForm.resetFields();
      onClose();
      return;
    }

    if (currentStep === ONBOARDING_STEP_SELECT_PLATFORM) {
      setCurrentStep(ONBOARDING_STEP_CREATE_USER);
      return;
    }

    if (currentStep === ONBOARDING_STEP_SIGNIN_PLATFORM) {
      signinPlatformForm.resetFields();
      setCurrentStep(ONBOARDING_STEP_SELECT_PLATFORM);
      return;
    }

    if (currentStep === ONBOARDING_STEP_ONBOARD_FRANCHISEE) {
      onboardFranchiseeForm.resetFields();
      setCurrentStep(ONBOARDING_STEP_SIGNIN_PLATFORM);
      return;
    }

    if (currentStep === ONBOARDING_STEP_REQUIREMENT) {
      setCurrentStep(ONBOARDING_STEP_ONBOARD_FRANCHISEE);
      return;
    }

    if (currentStep === ONBOARDING_STEP_ACCOUNT_INFO) {
      setCurrentStep(ONBOARDING_STEP_REQUIREMENT);
      return;
    }
  }

  function handleNext(data) {
    if (currentStep === ONBOARDING_STEP_CREATE_USER) {
      setFranchisorData(data);
      setCurrentStep(ONBOARDING_STEP_SELECT_PLATFORM);
      return;
    }

    if (currentStep === ONBOARDING_STEP_SIGNIN_PLATFORM) {
      setFranchiseeData(data);
      setCurrentStep(ONBOARDING_STEP_ONBOARD_FRANCHISEE);
      return;
    }

    if (currentStep === ONBOARDING_STEP_ONBOARD_FRANCHISEE) {
      setCurrentStep(ONBOARDING_STEP_REQUIREMENT);
      return;
    }

    if (currentStep === ONBOARDING_STEP_REQUIREMENT) {
      setCurrentStep(ONBOARDING_STEP_ACCOUNT_INFO);
      return;
    }
  }

  function handleSkip() {
    setCurrentStep(ONBOARDING_STEP_REQUIREMENT);
  }

  function handleConfirmActivate() {
    const { onClose, onRefresh } = props;
    if (currentStep === ONBOARDING_STEP_ACCOUNT_INFO) {
      handleUpdateUserStatus();
      onClose();
      onRefresh();
      createUserForm.resetFields();
      signinPlatformForm.resetFields();
      onboardFranchiseeForm.resetFields();
      setCurrentStep(ONBOARDING_STEP_CREATE_USER);
    }
  }

  function handleCancelActivate() {
    const { onClose, onRefresh } = props;
    onClose();
    onRefresh();
    createUserForm.resetFields();
    signinPlatformForm.resetFields();
    onboardFranchiseeForm.resetFields();
    setCurrentStep(ONBOARDING_STEP_CREATE_USER);
  }

  function renderFooter() {
    const cancelTitle =
      currentStep === ONBOARDING_STEP_CREATE_USER ? "Cancel" : "Back";
    const okTitle =
      currentStep === ONBOARDING_STEP_ACCOUNT_INFO ? "Ok" : "Next";

    if (currentStep === ONBOARDING_STEP_ACCOUNT_INFO) {
      return (
        <div className="onboarding-form__footer">
          <Button onClick={handleCancel}>{cancelTitle}</Button>
          <Popconfirm
            title="Do you want to activate this franchisor now?"
            onConfirm={handleConfirmActivate}
            onCancel={handleCancelActivate}
            okText="Yes"
            cancelText="No"
          >
            <Button className="onboarding-form__footer_btn-ok" type="primary">
              {okTitle}
            </Button>
          </Popconfirm>
        </div>
      );
    }
    return (
      <div className="onboarding-form__footer">
        <Button onClick={handleCancel}>{cancelTitle}</Button>
        <Button
          className="onboarding-form__footer_btn-ok"
          type="primary"
          onClick={handleOk}
        >
          {okTitle}
        </Button>
      </div>
    );
  }
  return (
    <div className="onboarding-form">
      <Steps progressDot current={currentStep}>
        <Step title="Creating User" />
        <Step title="Select Platform" />
        <Step title="Sign In to Platform" />
        <Step title="Onboard Franchisee" />
        <Step title="Requirement" />
        <Step title="Account Information" />
      </Steps>
      <Divider />
      {currentStep === ONBOARDING_STEP_CREATE_USER && (
        <CreateUserForm
          form={createUserForm}
          onNext={handleNext}
          onSetUser={setUserInfor}
        />
      )}
      {currentStep === ONBOARDING_STEP_SELECT_PLATFORM && (
        <MethodForm
          boardPlatforms={boardPlatforms}
          handleSignInFranchisee={handleSignInFranchisee}
          handleSkip={handleSkip}
          requirementId={franchisorData?._id}
        />
      )}

      {currentStep === ONBOARDING_STEP_SIGNIN_PLATFORM && (
        <SigninPlatformForm
          form={signinPlatformForm}
          requirementId={franchisorData?._id}
          onNext={handleNext}
          platform={integrationKey}
        />
      )}

      {currentStep === ONBOARDING_STEP_ONBOARD_FRANCHISEE && (
        <OnboardFranchiseeForm
          form={onboardFranchiseeForm}
          platform={integrationKey}
          franchiseeData={franchiseeData}
          requirementId={franchisorData?._id}
          onNext={handleNext}
        />
      )}

      {currentStep === ONBOARDING_STEP_REQUIREMENT && (
        <RequirementForm
          requirementData={franchisorData}
          handleUpdateForm={handleUpdateForm}
        />
      )}

      {currentStep === ONBOARDING_STEP_ACCOUNT_INFO && (
        <AccountInfoForm requirement={franchisorData} userInfor={userInfor} />
      )}

      <Divider />
      {renderFooter()}
    </div>
  );
}
