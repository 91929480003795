import React, { useState, useEffect, useMemo } from "react";
import { Input, Loading } from "@components";
import { Radio, Button, notification, Checkbox } from "antd";
import "./style.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteAPI, put } from "@utils/axios";
import { mapFieldProIns } from "@utils/form-recog";
import {
  buildEffectDate,
  buildExpireDate,
  buildFormType,
  buildRecoveryType,
  buildValueGT,
  buildValueLT,
  getProInsScore,
  reportProIns
} from "@utils/build-logic";
import {
  FORM_TYPE_SPECIAL,
  FORM_TYPE_BROAD,
  FORM_TYPE_BASIC,
  FORM_LOSS_RECOVERY_TYPE_COST,
  FORM_LOSS_RECOVERY_TYPE_CASH
} from "@assets/const/form";
import { PERMISSION_TABLE } from "@utils/permission";
import { useAppState } from "@context";
import { isEmpty } from "lodash";
import {
  DOC_MAX_LIMIT,
  STATUS_APPROVE_PRO_INS,
  STATUS_SECTION_APPROVE,
  STATUS_SECTION_PENDING
} from "@assets/const/status";
import { TYPE_INPUT_DATE } from "@assets/const/ui";
import { validateDate } from "@utils/common";
import { format } from "date-fns";

export default function ProInsForm(props) {
  const { docIndex, franchisorData } = props;
  const { getAccessTokenSilently } = useAuth0();
  const { authSetting } = useAppState();
  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      (docIndex >= 0 &&
        docIndex === franchiseeData?.finalData?.pro_ins_form_doc_index) ||
      docIndex === DOC_MAX_LIMIT
    ) {
      if (
        franchiseeData?.finalData?.pro_ins_form_status ===
          STATUS_SECTION_APPROVE ||
        franchiseeData?.finalData?.pro_ins_form_status ===
          STATUS_SECTION_PENDING
      ) {
        const finalData = franchiseeData.finalData;

        return {
          pro_ins_is_bus_per_pro: finalData.pro_ins_is_bus_per_pro,
          pro_ins_bus_per_pro: finalData.pro_ins_bus_per_pro,
          pro_ins_is_ten_imp: finalData.pro_ins_is_ten_imp,
          pro_ins_ten_imp: finalData.pro_ins_ten_imp,
          pro_ins_is_bui_amo: finalData.pro_ins_is_bui_amo,
          pro_ins_bui_amo: finalData.pro_ins_bui_amo,
          pro_ins_is_bus_int: finalData.pro_ins_is_bus_int,
          pro_ins_bus_int: finalData.pro_ins_bus_int,
          pro_ins_is_act_los: finalData.pro_ins_is_act_los,
          pro_ins_act_los: finalData.pro_ins_act_los,
          pro_ins_is_mon_lim: finalData.pro_ins_is_mon_lim,
          pro_ins_mon_lim: finalData.pro_ins_mon_lim,
          pro_ins_is_co_ins: finalData.pro_ins_is_co_ins,
          pro_ins_co_ins: finalData.pro_ins_co_ins,
          pro_ins_is_agg_val: finalData.pro_ins_is_agg_val,
          pro_ins_agg_val: finalData.pro_ins_agg_val,
          pro_ins_form_type: finalData.pro_ins_form_type,
          pro_ins_los_rec_type: finalData.pro_ins_los_rec_type,
          pro_ins_policy_number: finalData.pro_ins_policy_number,
          pro_ins_effective_date: finalData.pro_ins_effective_date,
          pro_ins_expire_date: finalData.pro_ins_expire_date
        };
      } else {
        return getEmptyForm();
      }
    }

    if (docIndex >= 0) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldProIns(data);
      }
    }

    return getEmptyForm();
  }

  function getEmptyForm() {
    return {
      pro_ins_is_bus_per_pro: false,
      pro_ins_bus_per_pro: "",
      pro_ins_is_ten_imp: false,
      pro_ins_ten_imp: "",
      pro_ins_is_bui_amo: false,
      pro_ins_bui_amo: "",
      pro_ins_is_bus_int: false,
      pro_ins_bus_int: "",
      pro_ins_is_act_los: false,
      pro_ins_act_los: "",
      pro_ins_is_mon_lim: false,
      pro_ins_mon_lim: "",
      pro_ins_is_co_ins: false,
      pro_ins_co_ins: "",
      pro_ins_is_agg_val: false,
      pro_ins_agg_val: "",
      pro_ins_form_type: "",
      pro_ins_los_rec_type: "",
      pro_ins_policy_number: "",
      pro_ins_effective_date: "",
      pro_ins_expire_date: ""
    };
  }

  const [loading, setLoading] = useState(false);

  async function handleApprove() {
    let { franchiseeData, docIndex } = props;
    if (docIndex >= 0) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        let activities = franchiseeData.activities;
        if (activities) {
          activities.push({
            action: STATUS_APPROVE_PRO_INS,
            date: new Date()
          });
        } else {
          activities = [
            {
              action: STATUS_APPROVE_PRO_INS,
              date: new Date()
            }
          ];
        }

        let finalData = franchiseeData?.finalData
          ? franchiseeData.finalData
          : {};
        finalData = {
          ...finalData,
          ...formVal,
          pro_ins_form_status:
            !isEmpty(formVal.pro_ins_expire_date) &&
            new Date(formVal.pro_ins_expire_date) < new Date()
              ? STATUS_SECTION_PENDING
              : STATUS_SECTION_APPROVE,
          pro_ins_form_doc_index: docIndex,
          pro_ins_effective_date: validateDate(formVal.pro_ins_effective_date)
            ? format(new Date(formVal.pro_ins_effective_date), "yyyy-MM-dd")
            : null,
          pro_ins_expire_date: validateDate(formVal.pro_ins_expire_date)
            ? format(new Date(formVal.pro_ins_expire_date), "yyyy-MM-dd")
            : null
        };

        const resultIssue = reportProIns(finalData, franchisorData);

        const result = await put(
          `contact/${franchiseeData._id}`,
          {
            finalData: finalData,
            activities: activities,
            proInsIssue: resultIssue
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        await deleteAPI(`notification/delete/${franchiseeData._id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const { handleUpdateForm } = props;
        handleUpdateForm(result.data.entity);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      handleNotification("Failed", "Need to choose a documentation");
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value
    });
  }

  const coverageScore = useMemo(() => {
    let finalData = props.franchiseeData?.finalData
      ? props.franchiseeData.finalData
      : {};
    finalData = {
      ...finalData,
      ...formVal,
      pro_ins_form_status:
        !isEmpty(formVal.pro_ins_expire_date) &&
        new Date(formVal.pro_ins_expire_date) < new Date()
          ? STATUS_SECTION_PENDING
          : STATUS_SECTION_APPROVE,
      pro_ins_form_doc_index: docIndex,
      pro_ins_effective_date: validateDate(formVal.pro_ins_effective_date)
        ? formVal.pro_ins_effective_date
        : null,
      pro_ins_expire_date: validateDate(formVal.pro_ins_expire_date)
        ? formVal.pro_ins_expire_date
        : null
    };

    const resultScore = getProInsScore(finalData, franchisorData);
    return resultScore;
  }, [props.franchiseeData, formVal, franchisorData]);

  return (
    <div className="pro_ins-content">
      {coverageScore.visible && (
        <div className="workers-comp-content-score">
          Score: {coverageScore.score} / {coverageScore.total}
        </div>
      )}
      <div className="form-main">
        <div className="panel-left">
          <div className="form-group-two-container checkbox-container">
            {/* <div>
              <h5 className="checkbox-title">Included Coverages</h5>
              <div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_bus_per_pro}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_bus_per_pro", evt.target.checked)
                    }
                  >
                    Business Personal Property
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_ten_imp}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_ten_imp", evt.target.checked)
                    }
                  >
                    Tenant Improvement
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_bui_amo}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_bui_amo", evt.target.checked)
                    }
                  >
                    Building
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_bus_int}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_bus_int", evt.target.checked)
                    }
                  >
                    Business Interruption
                  </Checkbox>
                </div>
              </div>
            </div>
            <div>
              <h5 className="checkbox-title">Business Interruption</h5>
              <div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_act_los}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_act_los", evt.target.checked)
                    }
                  >
                    Actual Loss Sustained
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_mon_lim}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_mon_lim", evt.target.checked)
                    }
                  >
                    Monthly Limitation
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_co_ins}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_co_ins", evt.target.checked)
                    }
                  >
                    Co-Insurance
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_agg_val}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_agg_val", evt.target.checked)
                    }
                  >
                    Agreed Value
                  </Checkbox>
                </div>
              </div>
            </div> */}
          </div>
          <div className="radio-container">
            <h5 className="radio-title">FORM TYPE</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("pro_ins_form_type", evt.target.value)
              }
              value={formVal.pro_ins_form_type}
            >
              <Radio value={FORM_TYPE_SPECIAL}>Special</Radio>
              <Radio value={FORM_TYPE_BROAD}>Broad</Radio>
              <Radio value={FORM_TYPE_BASIC}>Basic</Radio>
            </Radio.Group>
            {buildFormType(
              formVal.pro_ins_form_type,
              franchisorData.pro_ins_form_type,
              franchisorData.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="radio-container">
            <h5 className="radio-title">LOSS RECOVERY TYPE</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("pro_ins_los_rec_type", evt.target.value)
              }
              value={formVal.pro_ins_los_rec_type}
            >
              <Radio value={FORM_LOSS_RECOVERY_TYPE_COST}>
                Replacement Cost
              </Radio>
              <Radio value={FORM_LOSS_RECOVERY_TYPE_CASH}>
                Actual Cash Value
              </Radio>
            </Radio.Group>
            {buildRecoveryType(
              formVal.pro_ins_los_rec_type,
              franchisorData.pro_ins_los_rec_type,
              franchisorData.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="alert-container">
            <Input
              title="POLICY NUMBER"
              placeholder="POLICY NUMBER"
              value={formVal.pro_ins_policy_number}
              onChange={(evt) =>
                handleChange("pro_ins_policy_number", evt.target.value)
              }
            />
            <Input
              title="EFFECTIVE DATE"
              placeholder="EFFECTIVE DATE"
              value={formVal.pro_ins_effective_date}
              inputType={TYPE_INPUT_DATE}
              onChange={(evt) =>
                handleChange("pro_ins_effective_date", evt.target.value)
              }
            />
            {buildEffectDate(
              formVal.pro_ins_effective_date,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <Input
              title="EXPIRATION DATE"
              placeholder="EXPIRATION DATE"
              value={formVal.pro_ins_expire_date}
              inputType={TYPE_INPUT_DATE}
              onChange={(evt) =>
                handleChange("pro_ins_expire_date", evt.target.value)
              }
            />

            {buildExpireDate(
              formVal.pro_ins_expire_date,
              franchisorData.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          {/* {formVal.pro_ins_is_bus_per_pro && ( */}
          <div className="line-item-container">
            <Input
              title="BUSINESS PERSONAL PROPERTY"
              type="number"
              placeholder="BUSINESS PERSONAL PROPERTY"
              value={formVal.pro_ins_bus_per_pro}
              onChange={(evt) =>
                handleChange("pro_ins_bus_per_pro", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_bus_per_pro,
              franchisorData.pro_ins_bus_per_pro,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          {/* )} */}
          {/* {formVal.pro_ins_is_ten_imp && ( */}
          <div className="line-item-container">
            <Input
              title="TENANT IMPROVEMENTS"
              type="number"
              placeholder="TENANT IMPROVEMENTS"
              value={formVal.pro_ins_ten_imp}
              onChange={(evt) =>
                handleChange("pro_ins_ten_imp", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_ten_imp,
              franchisorData.pro_ins_ten_imp,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}

          {formVal.pro_ins_is_bui_amo && ( */}
          <div className="line-item-container">
            <Input
              title="BUILDING AMOUNT"
              type="number"
              placeholder="BUILDING AMOUNT"
              value={formVal.pro_ins_bui_amo}
              onChange={(evt) =>
                handleChange("pro_ins_bui_amo", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_bui_amo,
              franchisorData.pro_ins_bui_amo,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}

          {formVal.pro_ins_is_bus_int && ( */}
          <div className="line-item-container">
            <Input
              title="BUSINESS INTERRUPTION"
              type="number"
              placeholder="BUSINESS INTERRUPTION"
              value={formVal.pro_ins_bus_int}
              onChange={(evt) =>
                handleChange("pro_ins_bus_int", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_bus_int,
              franchisorData.pro_ins_bus_int,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}
          {formVal.pro_ins_is_act_los && ( */}
          <div className="line-item-container">
            <Input
              title="ACTUAL LOSS SUSTAINED"
              type="number"
              placeholder="ACTUAL LOSS SUSTAINED"
              value={formVal.pro_ins_act_los}
              onChange={(evt) =>
                handleChange("pro_ins_act_los", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_act_los,
              franchisorData.pro_ins_act_los,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}
          {formVal.pro_ins_is_mon_lim && ( */}
          <div className="line-item-container">
            <Input
              title="MONTHLY LIMITATION"
              type="number"
              placeholder="MONTHLY LIMITATION"
              value={formVal.pro_ins_mon_lim}
              onChange={(evt) =>
                handleChange("pro_ins_mon_lim", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_mon_lim,
              franchisorData.pro_ins_mon_lim,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}
          {formVal.pro_ins_is_co_ins && ( */}
          <div className="line-item-container">
            <Input
              title="CO-INSURANCE"
              type="number"
              placeholder="CO-INSURANCE"
              value={formVal.pro_ins_co_ins}
              onChange={(evt) =>
                handleChange("pro_ins_co_ins", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_co_ins,
              franchisorData.pro_ins_co_ins,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}

          {formVal.pro_ins_is_agg_val && ( */}
          <div className="line-item-container">
            <Input
              title="AGREED VALUE"
              type="number"
              placeholder="AGREED VALUE"
              value={formVal.pro_ins_agg_val}
              onChange={(evt) =>
                handleChange("pro_ins_agg_val", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_agg_val,
              franchisorData.pro_ins_agg_val,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )} */}
        </div>
      </div>
      <div className="form-footer">
        {PERMISSION_TABLE["FRANCHISEE_DATA_APPROVE"][
          authSetting.userPermission
        ] > 0 && (
          <Button type="primary" size="large" onClick={handleApprove}>
            Approve
          </Button>
        )}
      </div>

      <Loading loading={loading} />
    </div>
  );
}
