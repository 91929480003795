import "./style.scss";
import { Input } from "../../../../../components";
import { Select } from "antd";
import { CONSTRUCTION_TYPE_CODES } from "../../../../../assets/const/hartford";

export default function BopInforBlock(props) {
  const { data, disabled, onChange } = props;

  return (
    <div className="admin_franchisee_under-section">
      {/* <h4 className="admin_franchisee_under-section-title">
        Building Information
      </h4> */}
      <div className="form-group-three-container">
        <Input
          title="Total Square Feet"
          type="text"
          value={data?.totalArea}
          onChange={(evt) => onChange("totalArea", evt.target.value)}
        />
        <Input
          title="Year Built"
          type="text"
          value={data?.yearBuilt}
          onChange={(evt) => onChange("yearBuilt", evt.target.value)}
        />
        <div className="custom-input-container">
          <p className="input-title">Construction Type Code</p>
          <Select
            options={CONSTRUCTION_TYPE_CODES}
            value={data?.constructionTypeCode}
            size="large"
            className="construction-type-select"
            onChange={(evt) => onChange("constructionTypeCode", evt)}
          ></Select>
        </div>
      </div>
      <div className="form-group-three-container">
        <Input
          title="Number Of Stories"
          type="text"
          value={data?.numberOfStories}
          onChange={(evt) => onChange("numberOfStories", evt.target.value)}
        />
        <Input
          title="Equipment, Stock, Inventory and Furniture Value"
          type="text"
          value={data?.bppLimit}
          onChange={(evt) => onChange("bppLimit", evt.target.value)}
        />
        <Input
          title="Tenant Improvements"
          type="text"
          value={data?.tenantImprovement}
          onChange={(evt) => onChange("tenantImprovement", evt.target.value)}
        />
        {/* <div className="custom-input-container">
          <p className="input-title">Include Building Coverage</p>
          <div style={{ display: "flex" }}>
            <Switch
              style={{ marginRight: 14 }}
              checked={data?.includeBuildingCoverage}
              onChange={(value) => onChange("includeBuildingCoverage", value)}
            />
            {data?.includeBuildingCoverage && (
              <Input
                type="text"
                value={data.buildingCoverage}
                onChange={(evt) =>
                  onChange("buildingCoverage", evt.target.value)
                }
              />
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}
