import React from "react";
import { Modal, Button } from "antd";
import { format } from "date-fns";
import { APP_VERSION, RELEASE_NOTES } from "@assets/const/version";
import { useAppState } from "@context";
import "./style.scss";

export default function ReleaseNote({ isOpen, onClose }) {
  const { authSetting } = useAppState();

  return (
    <Modal
      open={isOpen}
      title="Release Notes"
      onOk={() => onClose(false)}
      onCancel={() => onClose(false)}
      footer={[
        <Button key="submit" type="primary" onClick={() => onClose(false)}>
          Close
        </Button>,
      ]}
    >
      <div className="release">
        {Object.entries(RELEASE_NOTES)
          .reverse()
          .map((item, mainIndex) => (
            <div key={`key-note-${mainIndex}`} className="release_note">
              <div className="release_note_header">
                <span className="release_note_header-version">
                  Version: {item[0]}
                </span>
                <span className="release_note_header-date">
                  {format(
                    new Date(RELEASE_NOTES[item[0]].date + "T00:00:00"),
                    "LLL dd yyyy"
                  )}
                </span>
              </div>
              <div>
                {authSetting?.user?.type === "adminUser" &&
                  RELEASE_NOTES?.[item[0]].link && (
                    <a
                      href={RELEASE_NOTES[item[0]].link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="admin-link"
                    >
                      Resolved Ticket Details
                    </a>
                  )}
              </div>
              <ul className="release_note_content">
                {RELEASE_NOTES[item[0]].content.map(
                  (contentItem, itemIndex) => (
                    <li
                      key={`key-note-item-${mainIndex}-${itemIndex}`}
                      className={
                        contentItem.startsWith("🎉") ||
                        contentItem.startsWith("🐛") ||
                        contentItem.startsWith("🏎️") ||
                        contentItem.startsWith("🛑")
                          ? "release_note_section-title"
                          : "release_note_item"
                      }
                    >
                      {contentItem.startsWith("🎉") ||
                      contentItem.startsWith("🐛") ||
                      contentItem.startsWith("🏎️") ||
                      contentItem.startsWith("🛑")
                        ? contentItem
                        : `• ${contentItem}`}
                    </li>
                  )
                )}
              </ul>
            </div>
          ))}
      </div>
    </Modal>
  );
}
