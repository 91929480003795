import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  ProductItem,
  QuoteProgressBar,
  QuoteStepper,
  QuoteHeader,
  QuoteAction
} from "../../../components";
import { useAppState } from "../../../context";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { get, post } from "../../../utils/axios";
import gaiLogo from "../../../assets/images/logo-great-american.png";
import hartfordLogo from "../../../assets/images/hartford-logo.png";
import { Radio } from "antd";
import {
  COVERAGES,
  COVERAGES_OLD
} from "../../../assets/const/fim-integration";

// This component is deprecated.
export default function AdminProducts() {
  const history = useHistory();
  const location = useLocation();
  let { id } = useParams();
  const elements = [
    { title: "Great American", logo: gaiLogo, value: "GAI" },
    { title: "The Hartford", logo: hartfordLogo, value: "THE_HARTFORD" }
  ];

  const { user, getAccessTokenSilently } = useAuth0();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataSourceActivity, setDataSourceActivity] = useState([]);

  const [formVal, setFormVal] = useState({
    product: "GAI"
  });

  // TODO: We should make sure we have some coverages or else bail out, but not
  // sure if we can hardcode the franchisee detail view since other places might
  // redirect here. For now, just proceed with an empty set of coverages since
  // we're not actually doing anything of consequence.
  const coverages = COVERAGES_OLD.filter((c) =>
    (location?.state?.coverages || []).includes(c.id)
  );

  useEffect(() => {
    if (id) {
      handleLoadFranchisee();
    }
  }, []);

  async function handleLoadFranchisee() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setFranchiseeData(result.data.data);
      setDataSourceActivity((result.data.data.activities || []).reverse());
      const resultRequirement = await get(
        `requirement/${result.data.data.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchisorData(resultRequirement.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }

  function handleBack() {
    history.push(`/admin/franchisees/${id}`, { ...location?.state });
  }

  function handleNext() {
    if (formVal.product === "GAI") {
      history.push(`/admin/appetite/${id}`, { ...location?.state });
    } else {
      history.push(`/admin/hartford/appetite/${id}`, { ...location?.state });
    }
  }

  function handleClose() {
    history.push("/admin/overview");
  }

  function handleSave() {}

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value
    });
  }
  return (
    <div className="admin_products">
      <QuoteProgressBar step={0}></QuoteProgressBar>
      <QuoteHeader
        franchiseeData={franchiseeData}
        requirement={franchisorData}
        user={user}
      ></QuoteHeader>
      <div className="products_container">
        <QuoteStepper step={0}></QuoteStepper>
        <div className="right-content">
          <h1>Select Carrier</h1>
          <p>Generating quotes for the following coverages:</p>
          <ul>
            {coverages.map(({ id, title }) => (
              <li key={id}>{title}</li>
            ))}
          </ul>
          <br />
          <div className="workers-compensation-box">
            <Radio.Group
              onChange={(evt) => handleChange("product", evt.target.value)}
              value={formVal.product}
            >
              {elements.map((value, index) => {
                return (
                  <ProductItem
                    title={value.title}
                    logo={value.logo}
                    value={value.value}
                  ></ProductItem>
                );
              })}
            </Radio.Group>
          </div>
          <QuoteAction
            visibleClose={false}
            visibleSave={false}
            handleClose={handleClose}
            handleSave={handleSave}
            handleBack={handleBack}
            handleNext={handleNext}
          ></QuoteAction>
        </div>
      </div>
    </div>
  );
}
