import React from "react";
import { Input, AddressInput } from "@components";
import { Button, Select } from "antd";
import _, { first } from "lodash";
import { TYPE_INPUT_VIN } from "@assets/const/ui";
import { DeleteOutlined, CarOutlined } from "@ant-design/icons";
import "./style.scss";

const SELECT_OPTIONS = [
  {
    value: true,
    label: "Yes"
  },
  {
    value: false,
    label: "No"
  }
];

export default function BlockVehicle({ data, onChange, disabled }) {
  // function handleChangeVehicleInfo(index, key, value) {
  //   onChange((prev) => {
  //     prev[index][key] = value;

  //     return [...prev];
  //   });
  // }

  function handleChangeVehicleInfo(index, key, value) {
    onChange((prev) => {
      // Create a shallow copy of the previous array
      const prevVehicleCopy = [...prev];
      // Create a shallow copy of the specific object at the given index
      const updatedVehicle = { ...prevVehicleCopy[index] };
      // Update the specific key with the new value in the copied object
      updatedVehicle[key] = value;
      // Replace the object at the index with the updated object
      prevVehicleCopy[index] = updatedVehicle;
      // Return the new array to update the state
      return prevVehicleCopy;
    });
  }

  function handleChangeLocationAddress(index, address) {
    onChange((prev) => {
      prev[index]["garageAddress"] = address.address;
      prev[index]["garageCity"] = address.city;
      prev[index]["garageState"] = address.state;
      prev[index]["garageZip"] = address.zip;

      return [...prev];
    });
  }

  function firstTimeNewVehicleForm() {
    onChange(() => {
      return [
        {
          year: "",
          make: "",
          model: "",
          vin: "",
          value: null,
          equipment: null,
          parkHome: null,
          trackDevice: null,
          garageAddress: "",
          garageCity: "",
          garageState: "",
          garageZip: "",
        },
      ];
    });
  }

  function handleAddNewVehicle() {
    onChange((prev) => {
      return [
      ...prev,
      {
        year: "",
        make: "",
        model: "",
        garageAddress: "",
        garageCity: "",
        garageState: "",
        garageZip: "",
      },
    ]});
  }

  function handleDeleteVehicle(index) {
    onChange((prev) => {
      prev.splice(index, 1);
      return [...prev];
    });
  }

  function renderVehicleInfo(vehicleIndex, vehicle) {
    return (
      <div key={`vehicle-${vehicleIndex}`} className="block_ve-item">
        <div className="block_ve-item-wrapper">
          <Input
            title="Year"
            type="text"
            value={vehicle.year ?? ""}
            inputType="TYPE_INPUT_YEAR"
            required
            onChange={(evt) =>
              handleChangeVehicleInfo(vehicleIndex, "year", evt.target.value)
            }
          />
          <Input
            title="Make"
            type="text"
            value={vehicle.make ?? ""}
            required
            onChange={(evt) =>
              handleChangeVehicleInfo(vehicleIndex, "make", evt.target.value)
            }
          />
          <Input
            title="Model"
            type="text"
            value={vehicle.model ?? ""}
            required
            onChange={(evt) =>
              handleChangeVehicleInfo(vehicleIndex, "model", evt.target.value)
            }
          />
          <Input
            title="Vehicle Value"
            value={vehicle.value ?? ""}
            required
            onChange={(val) =>
              handleChangeVehicleInfo(vehicleIndex, "value", val)
            }
            size="large"
            inputType="TYPE_INPUT_CURRENCY"
            style={{ width: "100%" }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
          />
          <Input
            title="Attached Equipment Value"
            value={vehicle.equipment ?? ""}
            required
            onChange={(val) =>
              handleChangeVehicleInfo(vehicleIndex, "equipment", val)
            }
            size="large"
            inputType="TYPE_INPUT_CURRENCY"
            style={{ width: "100%" }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
          />
          <Input
            size="large"
            title="VIN"
            inputType={TYPE_INPUT_VIN}
            value={vehicle.vin ?? ""}
            required
            onChange={(evt) =>
              handleChangeVehicleInfo(
                vehicleIndex,
                "vin",
                evt.target.value.toUpperCase()
              )
            }
          />
          <div className="form-group-three-container">
            <AddressInput
              title="Garage Location"
              type="text"
              value={vehicle.garageAddress ?? ""}
              onChange={(value) =>
                handleChangeVehicleInfo(vehicleIndex, "garageAddress", value)
              }
              onSelect={(address) =>
                handleChangeLocationAddress(vehicleIndex, address)
              }
            />
            <div>
              <Input
                title="City"
                type="text"
                value={vehicle.garageCity ?? ""}
                required
                onChange={(evt) =>
                  handleChangeVehicleInfo(
                    vehicleIndex,
                    "garageCity",
                    evt.target.value
                  )
                }
              />
            </div>
            <div className="form-group-two-container">
              <Input
                title="State"
                type="text"
                value={vehicle.garageState ?? ""}
                required
                onChange={(evt) =>
                  handleChangeVehicleInfo(
                    vehicleIndex,
                    "garageState",
                    evt.target.value
                  )
                }
              />
              <Input
                title="Zip"
                type="text"
                value={vehicle.garageZip ?? ""}
                required
                onChange={(evt) =>
                  handleChangeVehicleInfo(
                    vehicleIndex,
                    "garageZip",
                    evt.target.value
                  )
                }
              />
            </div>
          </div>
          <div className="custom-input-container">
            <p className="input-title">Parked at Home?</p>
            <Select
              style={{ minWidth: 100 }}
              size="large"
              options={SELECT_OPTIONS}
              value={vehicle.parkHome ?? ""}
              onChange={(value) =>
                handleChangeVehicleInfo(vehicleIndex, "parkHome", value)
              }
            ></Select>
          </div>
          <div className="custom-input-container">
            <p className="input-title">Tracking device installed?</p>
            <Select
              style={{ minWidth: 100 }}
              size="large"
              options={SELECT_OPTIONS}
              value={vehicle.trackDevice ?? ""}
              onChange={(value) =>
                handleChangeVehicleInfo(vehicleIndex, "trackDevice", value)
              }
            ></Select>
          </div>
        </div>
        {!disabled && (
          <Button
            className="block_ve-item-action-btn"
            onClick={() => handleDeleteVehicle(vehicleIndex)}
          >
            <DeleteOutlined />
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className="block_ve">
      {data.length === 0 && firstTimeNewVehicleForm()}
      {data.map((vehicle, index) => renderVehicleInfo(index, vehicle))}

      <div className="block_ve-footer">
        <Button
          className="block_ve-footer-link"
          onClick={handleAddNewVehicle}
          style={{
            fontWeight: "bolder",
          }}
        >
          <CarOutlined />
          Add Vehicle
        </Button>
      </div>
    </div>
  );
}
