import React, { useState } from "react";
import { Header, Input } from "../../../components";
import { Button, Progress, Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./style.scss";
import noDataImage from "../../../assets/images/no-data.png";
import uploadImage from "../../../assets/images/upload.png";
import franConnectLogo from "../../../assets/images/brand-logo/logo_franconnect.png";
import serviceMinderLogo from "../../../assets/images/brand-logo/logo_service_minder.png";
import marketingLogo from "../../../assets/images/brand-logo/logo_2.png";
import classLogo from "../../../assets/images/brand-logo/logo_3.png";
import serviceLogo from "../../../assets/images/brand-logo/logo_4.png";
import helpDeskLogo from "../../../assets/images/brand-logo/logo_5.png";
import { onboardingWorkflow } from "../../../assets/const/onboarding";
import { post } from "../../../utils/axios";
import { useAppState } from "../../../context";
import { useAuth0 } from "@auth0/auth0-react";

const boardPlatforms = [
  { title: "Franconnect", url: franConnectLogo, key: "franconnect" },
  {
    title: "Service Minder",
    url: serviceMinderLogo,
    key: "serviceminder",
  },
];

function OnboardPlatforms(props) {
  const { getAccessTokenSilently } = useAuth0();
  const { authSetting, requirement, setFranchiseeData } = useAppState();
  const [modalFlag, setModalFlag] = useState(false);
  const [integrationKey, setIntegrationKey] = useState("");
  const [syncProgress, setSyncProgress] = useState(false);
  const [formVal, setFormVal] = useState({
    username: "",
    password: "",
    tenantID: "",
    clientId: "",
    clientSecret: "",
  });
  const [serviceMinderKey, setServiceMinderKey] = useState("");

  function handleConnect() {
    const { handleNavigate } = props;
    handleNavigate();
  }

  async function handleSignIn() {
    if (integrationKey === "franconnect") {
      await handleSignInFranconnect();
    } else if (integrationKey === "serviceminder") {
      await handleSignInServiceMinder();
    }
  }

  async function handleSignInServiceMinder() {
    setSyncProgress(true);
    const param = {
      apiKey: serviceMinderKey,
      requirementId: requirement?._id,
    };
    try {
      const token = await getAccessTokenSilently();
      let result = await post(`serviceminder/auth`, param, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.status === 200) {
        setFranchiseeData({
          apiKey: serviceMinderKey,
          data: result?.data?.data || [],
          platform: "serviceminder",
        });
        setModalFlag(false);
        setSyncProgress(false);
        const { handleNavigate } = props;
        handleNavigate(onboardingWorkflow.LIST_FRANCHISEE);
      } else {
        notification["error"]({
          message: "Authentication Failed",
          description: "Please make sure you have the correct API Key",
          style: {
            background: "#fef2f0",
            border: "1px solid #fbccc7",
          },
        });
      }
      setSyncProgress(false);
    } catch (error) {
      setSyncProgress(false);
      notification["error"]({
        message: "Authentication Failed",
        description: "Please make sure you have the correct API Key",
        style: {
          background: "#fef2f0",
          border: "1px solid #fbccc7",
        },
      });
    }
  }

  async function handleSignInFranconnect() {
    setSyncProgress(true);
    try {
      const param = {
        username: formVal["username"],
        password: formVal["password"],
        tenantID: formVal["tenantID"],
        clientId: formVal["clientId"],
        clientSecret: formVal["clientSecret"],
        requirementId: requirement?._id,
      };
      const token = await getAccessTokenSilently();
      let result = await post(`franconnect/auth`, param, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.status === 200) {
        let { access_token } = result.data.data;
        let resultFranchiseeList = await post(
          `franconnect/franchisee-list`,
          {
            token: access_token,
            tenantID: formVal["tenantID"],
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (resultFranchiseeList.status === 200) {
          setFranchiseeData({
            token: access_token,
            tenantID: formVal["tenantID"],
            data: Object.values(resultFranchiseeList.data.data),
            platform: "franconnect",
          });
          setModalFlag(false);
          setSyncProgress(false);
          const { handleNavigate } = props;
          handleNavigate(onboardingWorkflow.LIST_FRANCHISEE);
        }
      } else {
        notification["error"]({
          message: "Authentication Failed",
          description:
            "Please make sure you have the correct tenantID and credentials",
          style: {
            background: "#fef2f0",
            border: "1px solid #fbccc7",
          },
        });
      }
      setSyncProgress(false);
    } catch (error) {
      notification["error"]({
        message: "Authentication Failed",
        description:
          "Please make sure you have the correct tenantID and credentials",
        style: {
          background: "#fef2f0",
          border: "1px solid #fbccc7",
        },
      });
      setSyncProgress(false);
    }
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleCancel() {
    setModalFlag(false);
  }

  function handleSignInFranchisee(key) {
    const { handleNavigate } = props;
    setModalFlag(true);
    setIntegrationKey(key);
    handleNavigate(onboardingWorkflow.SIGN_IN_FRANCHISEE);
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="board-platforms-container">
      <h3 className="board-platforms-title">Sync Franchisees</h3>
      <div className="board-platforms-card">
        <div className="content-wrapper">
          <h4>Select your platform</h4>
          <div className="platforms-container">
            {boardPlatforms.map((platform) => (
              <div
                className="platform-item"
                style={{ backgroundImage: `url(${platform.url})` }}
                onClick={() => handleSignInFranchisee(platform.key)}
              ></div>
            ))}
          </div>
          {modalFlag && (
            <div className="modal-container">
              <div className="modal-back-container"></div>
              {!syncProgress && integrationKey === "franconnect" && (
                <div className="modal-content-card">
                  <h3 className="modal-header-title">Sign in to Franconnect</h3>
                  <div className="line-container">
                    <Input
                      title="Tenant ID"
                      type="text"
                      placeholder="Please enter tenant ID"
                      value={formVal.tenantID}
                      description="Ex: colorworldhousepainting (colorworldhousepainting.franconnect.net)"
                      onChange={(evt) =>
                        handleChange("tenantID", evt.target.value)
                      }
                    />
                  </div>
                  <div className="line-container">
                    <Input
                      title="Username"
                      type="text"
                      placeholder="Please enter your username"
                      value={formVal.username}
                      onChange={(evt) =>
                        handleChange("username", evt.target.value)
                      }
                    />
                  </div>
                  <div className="line-container">
                    <Input
                      title="Password"
                      type="password"
                      placeholder="Please enter your password"
                      value={formVal.password}
                      onChange={(evt) =>
                        handleChange("password", evt.target.value)
                      }
                    />
                  </div>
                  <div className="line-container">
                    <Input
                      title="Client ID"
                      type="text"
                      placeholder="Please enter your Client ID"
                      value={formVal.clientId}
                      onChange={(evt) =>
                        handleChange("clientId", evt.target.value)
                      }
                    />
                  </div>
                  <div className="line-container">
                    <Input
                      title="Client Secret"
                      type="password"
                      placeholder="Please enter your Client Secret"
                      value={formVal.clientSecret}
                      onChange={(evt) =>
                        handleChange("clientSecret", evt.target.value)
                      }
                    />
                  </div>
                  <div className="line-container">
                    <Button
                      type="primary"
                      className="save-btn"
                      onClick={handleSignIn}
                    >
                      Sign in
                    </Button>
                    <Button className="cancel-btn" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
              {!syncProgress && integrationKey === "serviceminder" && (
                <div className="modal-content-card">
                  <h3 className="modal-header-title">
                    Sign in to Service Minder
                  </h3>
                  <div className="line-container">
                    <Input
                      title="Key"
                      type="text"
                      placeholder="Please enter KEY"
                      value={serviceMinderKey}
                      description="Ex: XXXX"
                      onChange={(evt) => setServiceMinderKey(evt.target.value)}
                    />
                  </div>
                  <div className="line-container">
                    <Button
                      type="primary"
                      className="save-btn"
                      onClick={handleSignIn}
                    >
                      Sign in
                    </Button>
                    <Button className="cancel-btn" onClick={handleCancel}>
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
              {syncProgress && (
                <div className="modal-load-card">
                  <Spin indicator={antIcon} />
                  <p>
                    Please wait
                    <br />
                    Your data is syncing...
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OnboardPlatforms;
