import React from "react";
import {
  Card,
  Input,
  Loading,
  QuoteAction,
  QuoteHeader,
  QuoteProgressBar,
  QuoteStepper,
} from "../../../../components";
import "./style.scss";
import { Select } from "antd";
import { ENTITY_CODE } from "../../../../assets/const/legal-entity";
import { maskInput } from "@utils/common";
import { TYPE_INPUT_TAX_ID } from "@assets/const/ui";
const { Option } = Select;

export default function BlockProfile(props) {
  const { data: formVal, onChange } = props;

  function handleChange(key, value) {
    onChange({
      ...formVal,
      [key]: value,
    });
  }

  return (
    <Card title="Account" className="hartford_profile">
      <div className="form-group-two-container">
        <div className="line-item-container">
          <Input
            title="First Name"
            type="text"
            required={true}
            value={formVal.first_name}
            onChange={(evt) => handleChange("first_name", evt.target.value)}
          />
        </div>
        <div className="form-group-three-container">
          <Input
            title="Entity Name"
            type="text"
            required={true}
            value={formVal.entity_name}
            onChange={(evt) => handleChange("entity_name", evt.target.value)}
          />
          <div className="">
            <p className="input-title">
              Legal Entity Code <span className="input-required">*</span>
            </p>
            <Select
              size="large"
              style={{ minWidth: "120px", width: "100%" }}
              value={formVal.legalEntityCd}
              onChange={(value) => handleChange("legalEntityCd", value)}
            >
              {ENTITY_CODE.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </div>
          <Input
            title="DBA"
            type="text"
            required
            value={formVal.dba}
            onChange={(evt) => handleChange("dba", evt.target.value)}
          />
        </div>
      </div>

      <div className="form-group-two-container">
        <div className="line-item-container">
          <Input
            title="Last Name"
            type="text"
            required
            value={formVal.last_name}
            onChange={(evt) => handleChange("last_name", evt.target.value)}
          />
        </div>
        <div className="line-item-container">
          <Input
            title="Street Address"
            type="text"
            required
            value={formVal.address}
            onChange={(evt) => handleChange("address", evt.target.value)}
          />
        </div>
      </div>

      <div className="form-group-two-container">
        <div className="line-item-container">
          <Input
            title="Email"
            type="text"
            required
            value={formVal.email}
            onChange={(evt) => handleChange("email", evt.target.value)}
          />
        </div>
        <div className="form-group-three-container">
          <Input
            title="City"
            type="text"
            required
            value={formVal.city}
            onChange={(evt) => handleChange("city", evt.target.value)}
          />
          <Input
            title="State"
            type="text"
            required
            value={formVal.state}
            onChange={(evt) => handleChange("state", evt.target.value)}
          />
          <Input
            title="Zip"
            type="text"
            required
            value={formVal.zip}
            onChange={(evt) => handleChange("zip", evt.target.value)}
          />
        </div>
      </div>

      <div className="form-group-two-container">
        <div className="form-group-two-container">
          <Input
            title="Telephone"
            type="text"
            required
            inputType="TYPE_INPUT_PHONE"
            value={formVal.telephone}
            onChange={(evt) => handleChange("telephone", evt.target.value)}
          />
          <Input
            title="Effective Date (YYYY-MM-DD)"
            type="text"
            required
            value={formVal.effective_date}
            onChange={(evt) => handleChange("effective_date", evt.target.value)}
          />
        </div>
        <div className="form-group-two-container">
          <Input
            title="Opening Date (YYYY-MM-DD)"
            type="text"
            required
            value={formVal.opening_date}
            onChange={(evt) => handleChange("opening_date", evt.target.value)}
          />
          <Input
            title="Tax ID"
            type="text"
            inputType={TYPE_INPUT_TAX_ID}
            value={formVal.tax_id}
            onChange={(evt) => handleChange("tax_id", evt.target.value)}
          />
        </div>
      </div>
    </Card>
  );
}
