import React, { useMemo, useState } from "react";
import {
  Input as AntInput,
  Tooltip,
  DatePicker,
  InputNumber,
  Button
} from "antd";
import "./style.scss";
import InputMask from "react-input-mask";
import {
  maskInput,
  validateSpecialInputFields,
  validateDate
} from "../../utils/common";
import {
  EyeFilled,
  EyeInvisibleFilled,
  WarningOutlined
} from "@ant-design/icons";
import {
  TYPE_INPUT_PRIMARY,
  TYPE_INPUT_PHONE,
  TYPE_INPUT_YEAR,
  TYPE_INPUT_ADDRESS,
  TYPE_INPUT_DATE,
  TYPE_INPUT_DIGIT,
  TYPE_INPUT_TAX_ID,
  TYPE_INPUT_VIN,
  TYPE_INPUT_CURRENCY
} from "../../assets/const/ui";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { flushSync } from "react-dom";
import { APP_DATE_FORMAT } from "@assets/const/form";

const MASK_ASTERIK = "*";

const Input = React.forwardRef((props, ref) => {
  const {
    error,
    errorMsg,
    description,
    inputType = TYPE_INPUT_PRIMARY,
    required
  } = props;

  let validDate = true;
  if (inputType === TYPE_INPUT_DATE) {
    validDate = validateDate(props.value);
  }

  const [maskField, setMaskField] = useState(true);
  const [internalErr, setInternalErr] = useState("");

  const maskedValue = useMemo(() => {
    if ([TYPE_INPUT_TAX_ID, TYPE_INPUT_VIN].includes(inputType) && maskField) {
      return maskInput(props.value);
    }
    return props.value;
  }, [props.value, maskField]);

  function onMaskInputChange(evt, inputField, defaultEmptyValue) {
    if (maskField) {
      let finalStr = "";
      if (!props.value) {
        evt.target.value = defaultEmptyValue;
      } else {
        for (let i = 0; i < evt.target.value.length; i++) {
          finalStr +=
            evt.target.value[i] !== MASK_ASTERIK
              ? evt.target.value[i]
              : props.value[i];
        }

        evt.target.value = finalStr;
      }
      setTimeout(() => {
        if (evt.nativeEvent.inputType === "deleteContentBackward") {
          const indexToRemove = evt.target.selectionStart;
          evt.target.value =
            finalStr.substring(0, indexToRemove) +
            "_" +
            finalStr.substring(indexToRemove + 1);
          props.onChange(evt);
        }
      });
    }
    if (inputType === TYPE_INPUT_VIN) {
      if (evt.target.value?.length > 17) {
        evt.target.value = evt.target.value.slice(0, 17);
      }
    }

    props.onChange(evt);
    flushSync(() => {
      setMaskField(false);
      setInternalErr(
        validateSpecialInputFields({ [inputField]: evt.target.value })
      );
    });
  }

  return (
    <div
      className={
        error || internalErr
          ? "custom-input-container error"
          : "custom-input-container"
      }
    >
      {props.title ? (
        <p className="input-title">
          {props.title}{" "}
          <span className="input-sub-title">{props.subTitle}</span>
          {props.required && <span className="input-required">*</span>}
        </p>
      ) : (
        <></>
      )}
      {inputType === TYPE_INPUT_PRIMARY && (
        <AntInput
          {...{
            ...props,
            className: `${props.className} custom-input ant-input ant-input-lg`
          }}
        />
      )}
      {inputType === TYPE_INPUT_DATE && (
        <DatePicker
          format={APP_DATE_FORMAT}
          className="custom-input"
          size="large"
          value={!isEmpty(props.value) ? dayjs(props.value) : undefined}
          style={{ width: "100%" }}
          onChange={(_, dateString) => {
            props.onChange({ target: { value: dateString } });
          }}
        ></DatePicker>
      )}
      {inputType === TYPE_INPUT_DIGIT && (
        <AntInput
          className="custom-input ant-input ant-input-lg"
          {...props}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      )}
      {inputType === TYPE_INPUT_ADDRESS && (
        <input className="custom-input input-antd" ref={ref} {...props} />
      )}
      {inputType === TYPE_INPUT_CURRENCY && <InputNumber {...props} />}
      {inputType === TYPE_INPUT_PHONE && (
        <InputMask
          className="custom-input input-antd"
          mask="(999) 999-9999"
          {...props}
        ></InputMask>
      )}
      {inputType === TYPE_INPUT_YEAR && (
        <InputMask
          className="custom-input input-antd"
          mask="9999"
          {...props}
        ></InputMask>
      )}
      {inputType === TYPE_INPUT_TAX_ID && (
        <div style={{ display: "flex" }}>
          <InputMask
            maskChar={maskField ? MASK_ASTERIK : "_"}
            className="custom-input input-antd"
            mask="99-9999999"
            disabled={props.disabled}
            {...{
              ...props,
              value: maskedValue,
              onChange: (evt) => {
                onMaskInputChange(evt, "tax_id", "_".repeat(9));
              }
            }}
          ></InputMask>
          <Button
            size="large"
            onClick={() => {
              setMaskField(!maskField);
            }}
          >
            {maskField ? <EyeInvisibleFilled /> : <EyeFilled />}
          </Button>
        </div>
      )}
      {inputType === TYPE_INPUT_VIN && (
        <InputMask
          maskChar={maskField ? MASK_ASTERIK : "_"}
          formatChars={{
            $: "[A-HJ-NPR-Z0-9]"
          }}
          className="custom-input ant-input ant-input-lg"
          mask={"$".repeat(17)}
          {...{
            ...props,
            value: maskedValue,
            onChange: (evt) => {
              onMaskInputChange(evt, "vin", "_".repeat(17));
            }
          }}
          disabled={props.disabled}
        >
          {(inputProps) => (
            <AntInput
              disabled={props.disabled}
              {...inputProps}
              suffix={
                !props.disabled &&
                (!maskField ? (
                  <EyeFilled
                    onClick={() => {
                      setMaskField(!maskField);
                    }}
                  />
                ) : (
                  <EyeInvisibleFilled
                    onClick={() => {
                      setMaskField(!maskField);
                    }}
                  />
                ))
              }
            />
          )}
        </InputMask>
      )}
      {description && <p className="desc-text">{description}</p>}
      {(error || internalErr) && (
        <div className="error-log">
          <span>{errorMsg || internalErr}</span>
        </div>
      )}
      {!validDate && inputType === TYPE_INPUT_DATE && (
        <Tooltip title="Invalid date format. This will not be saved.">
          <WarningOutlined className="custom-input-warning" />
        </Tooltip>
      )}
    </div>
  );
});

export default Input;
