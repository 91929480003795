import { Button } from "antd";
import "./style.scss";
import { InfoCircleTwoTone } from "@ant-design/icons";

function Alert(props) {
  const {
    message,
    onOk,
    onCancel,
    okLabel,
    status,
    cancelLabel,
    cancelBtnType,
  } = props;
  return (
    <div className={`alert-wrapper ${status}`}>
      <div>
        {status === "info" && <InfoCircleTwoTone style={{ marginRight: 8 }} />}
        <span>{message}</span>
      </div>

      <div className="alert-wrapper-action">
        {onOk && <Button
          type="primary"
          danger={status === "error"}
          className="save-btn"
          onClick={onOk}
        >
          {okLabel}
        </Button>}
        {onCancel && (
          <Button
            {...(cancelBtnType ? { type: cancelBtnType } : {})}
            className="cancel-btn"
            onClick={onCancel}
          >
            {cancelLabel}
          </Button>
        )}
      </div>
    </div>
  );
}

export default Alert;
