import React, { useEffect, useMemo, useState } from "react";
import { Form, Alert, Radio, Button, Checkbox, Select } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { Input, Loading } from "@components";
import "./style.scss";
import { userRole } from "@assets/const/auth";
import {
  PERMISSION_TIER_1,
  PERMISSION_TIER_2,
  PERMISSION_TIER_3,
  USER_TYPE_ADMIN,
} from "@assets/const/status";
import { post, put } from "@utils/axios";
import { OTHER_HOST_LIST } from "@utils/host";

export default function UserForm(props) {
  const [error, setError] = useState(null);
  const [formVal, setFormVal] = useState({
    email: props.data?.email,
    password: "",
    passwordRepeat: "",
    name: "",
  });
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.data) {
      setFormVal({
        first_name: props.data?.first_name || "",
        last_name: props.data?.last_name || "",
        link: props.data?.link || "",
        link_standard: props.data?.link_standard || "",
        link_presentation: props.data?.link_presentation || "",
        is_consult: props.data?.is_consult,
        email: props.data?.email,
        password: "",
        passwordRepeat: "",
        permission: props.data?.permission || PERMISSION_TIER_3,
        access_domain: props.data?.access_domain?.split(","),
      });
    } else {
      setFormVal({
        first_name: "",
        last_name: "",
        link: "",
        link_standard: "",
        link_presentation: "",
        is_consult: false,
        email: "",
        password: "",
        passwordRepeat: "",
        permission: PERMISSION_TIER_3,
        access_domain: getPreSelectAccessDomain(),
      });
    }
  }, [props.data]);

  function getPreSelectAccessDomain() {
    switch (window.location.hostname) {
      case "app.rikor.io":
      case "dev.app.rikor.io":
      case "localhost":
        return [];
      default:
        return [window.location.hostname];
    }
  }

  const accessDomainList = useMemo(() => {
    switch (window.location.hostname) {
      case "app.rikor.io":
      case "dev.app.rikor.io":
      case "localhost":
        return OTHER_HOST_LIST;
      default:
        return OTHER_HOST_LIST.filter(
          (item) => item.value === window.location.hostname
        );
    }
  }, [window.location]);

  async function handleSubmit() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (props.data) {
        await put(
          `user/${props.data._id}`,
          {
            permission: formVal.permission,
            first_name: formVal["first_name"],
            last_name: formVal["last_name"],
            link: formVal["link"],
            link: formVal["link"],
            link_standard: formVal["link_standard"],
            link_presentation: formVal["link_presentation"],
            is_consult: formVal["is_consult"],
            access_domain: (formVal.access_domain || []).join(","),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { onClose, onRefresh } = props;
        setLoading(false);
        onRefresh();
        onClose();
      } else {
        await post(
          `admin/user/create`,
          {
            email: formVal["email"],
            password: formVal["password"],
            first_name: formVal["first_name"],
            last_name: formVal["last_name"],
            link: formVal["link"],
            link_standard: formVal["link_standard"],
            link_presentation: formVal["link_presentation"],
            type: USER_TYPE_ADMIN,
            permission: formVal["permission"],
            is_consult: formVal["is_consult"],
            access_domain: (formVal.access_domain || []).join(","),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { onClose, onRefresh } = props;
        setLoading(false);
        onRefresh();
        onClose();
      }
    } catch (error) {
      console.log("error ---", error);
      setLoading(false);
      setError(error?.response?.data?.message);
    }
  }

  function handleChange(name, value) {
    setFormVal({
      ...formVal,
      [name]: value,
    });
  }

  function handleCancel() {
    const { onClose } = props;
    onClose();
  }

  return (
    <div className="user_form">
      {error && (
        <div className="user_form-error">
          <Alert message={error} type="error" showIcon />
        </div>
      )}
      <Input
        title="First Name"
        type="text"
        placeholder="Please enter your first name"
        value={formVal.first_name}
        onChange={(evt) => handleChange("first_name", evt.target.value)}
      />
      <Input
        title="Last Name"
        type="text"
        placeholder="Please enter your last name"
        value={formVal.last_name}
        onChange={(evt) => handleChange("last_name", evt.target.value)}
      />

      <Input
        title="Email"
        type="text"
        placeholder="Please enter your email"
        value={formVal.email}
        onChange={(evt) => handleChange("email", evt.target.value)}
        disabled={props.data}
      />
      {!props.data && (
        <Input
          title="Password"
          type="password"
          value={formVal.password}
          placeholder="Please enter your password"
          onChange={(evt) => handleChange("password", evt.target.value)}
        />
      )}
      {!props.data && (
        <Input
          title="Password Repeat"
          type="password"
          value={formVal.passwordRepeat}
          placeholder="Please enter your password"
          onChange={(evt) => handleChange("passwordRepeat", evt.target.value)}
        />
      )}
      <Input
        title="Calendar Discovery Link"
        type="text"
        placeholder="Please enter link"
        value={formVal.link}
        onChange={(evt) => handleChange("link", evt.target.value)}
      />

      <Input
        title="Calendar Standard Link"
        type="text"
        placeholder="Please enter link"
        value={formVal.link_standard}
        onChange={(evt) => handleChange("link_standard", evt.target.value)}
      />

      <Input
        title="Calendar Presentation Link"
        type="text"
        placeholder="Please enter link"
        value={formVal.link_presentation}
        onChange={(evt) => handleChange("link_presentation", evt.target.value)}
      />

      <div className="custom-input-container">
        <p className="input-title">Limited Access: </p>
        <Select
          mode="multiple"
          value={formVal.access_domain}
          size="large"
          style={{ width: "100%" }}
          onChange={(value) => handleChange("access_domain", value)}
          options={accessDomainList}
        />
      </div>
      <Radio.Group
        className="onboarding-form_create_user-select"
        value={formVal.permission}
        onChange={(evt) => handleChange("permission", evt.target.value)}
      >
        <Radio value={PERMISSION_TIER_1}>TIER 1</Radio>
        <Radio value={PERMISSION_TIER_2}>TIER 2</Radio>
        <Radio value={PERMISSION_TIER_3}>TIER 3</Radio>
      </Radio.Group>

      <Checkbox
        checked={formVal.is_consult}
        onChange={(evt) => handleChange("is_consult", evt.target.checked)}
      >
        Broker
      </Checkbox>
      <Loading loading={loading} />
      <div className="user_form-footer">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </div>
  );
}
