export const getTemplateParam = (data) => {
  return {
    commercial_general_policy: data.commercial_general_policy,
    commercial_general_policy_current: data.commercial_general_policy_current,
    commercial_general_policy_suggested:
      data.commercial_general_policy_suggested,
    commercial_general_policy_quote: data.commercial_general_policy_quote,

    commercial_general_liability_additional_insurance:
      data.commercial_general_liability_additional_insurance,
    commercial_general_liability_additional_insurance_current:
      data.commercial_general_liability_additional_insurance_current,
    commercial_general_liability_additional_insurance_suggested:
      data.commercial_general_liability_additional_insurance_suggested,
    commercial_general_liability_additional_insurance_quote:
      data.commercial_general_liability_additional_insurance_quote,

    commercial_general_liability_subrogration_waved:
      data.commercial_general_liability_subrogration_waved,
    commercial_general_liability_subrogration_waved_current:
      data.commercial_general_liability_subrogration_waved_current,
    commercial_general_liability_subrogration_waved_suggested:
      data.commercial_general_liability_subrogration_waved_suggested,
    commercial_general_liability_subrogration_waved_quote:
      data.commercial_general_liability_subrogration_waved_quote,

    commercial_general_liability_each_occurance:
      data.commercial_general_liability_each_occurance,
    commercial_general_liability_each_occurance_current:
      data.commercial_general_liability_each_occurance_current,
    commercial_general_liability_each_occurance_suggested:
      data.commercial_general_liability_each_occurance_suggested,
    commercial_general_liability_each_occurance_quote:
      data.commercial_general_liability_each_occurance_quote,

    commercial_general_liability_damage_rented_premises:
      data.commercial_general_liability_damage_rented_premises,
    commercial_general_liability_damage_rented_premises_current:
      data.commercial_general_liability_damage_rented_premises_current,
    commercial_general_liability_damage_rented_premises_suggested:
      data.commercial_general_liability_damage_rented_premises_suggested,
    commercial_general_liability_damage_rented_premises_quote:
      data.commercial_general_liability_damage_rented_premises_quote,

    commercial_general_liability_medical_expenses:
      data.commercial_general_liability_medical_expenses,
    commercial_general_liability_medical_expenses_current:
      data.commercial_general_liability_medical_expenses_current,
    commercial_general_liability_medical_expenses_suggested:
      data.commercial_general_liability_medical_expenses_suggested,
    commercial_general_liability_medical_expenses_quote:
      data.commercial_general_liability_medical_expenses_quote,

    commercial_general_liability_personal_adv_injury:
      data.commercial_general_liability_personal_adv_injury,
    commercial_general_liability_personal_adv_injury_current:
      data.commercial_general_liability_personal_adv_injury_current,
    commercial_general_liability_personal_adv_injury_suggested:
      data.commercial_general_liability_personal_adv_injury_suggested,
    commercial_general_liability_personal_adv_injury_quote:
      data.commercial_general_liability_personal_adv_injury_quote,

    commercial_general_liability_general_aggregate:
      data.commercial_general_liability_general_aggregate,
    commercial_general_liability_general_aggregate_current:
      data.commercial_general_liability_general_aggregate_current,
    commercial_general_liability_general_aggregate_suggested:
      data.commercial_general_liability_general_aggregate_suggested,
    commercial_general_liability_general_aggregate_quote:
      data.commercial_general_liability_general_aggregate_quote,

    commercial_general_liability_comprop_acg:
      data.commercial_general_liability_comprop_acg,
    commercial_general_liability_comprop_acg_current:
      data.commercial_general_liability_comprop_acg_current,
    commercial_general_liability_comprop_acg_suggested:
      data.commercial_general_liability_comprop_acg_suggested,
    commercial_general_liability_comprop_acg_quote:
      data.commercial_general_liability_comprop_acg_quote,

    commercial_general_aggregate_limit: data.commercial_general_aggregate_limit,
    commercial_general_aggregate_limit_current:
      data.commercial_general_aggregate_limit_current,
    commercial_general_aggregate_limit_suggested:
      data.commercial_general_aggregate_limit_suggested,
    commercial_general_aggregate_limit_quote:
      data.commercial_general_aggregate_limit_quote,

    // Auto
    automotive_liability_any_auto: data.automotive_liability_any_auto,
    automotive_liability_any_auto_current:
      data.automotive_liability_any_auto_current,
    automotive_liability_any_auto_suggested:
      data.automotive_liability_any_auto_suggested,
    automotive_liability_any_auto_quote:
      data.automotive_liability_any_auto_quote,

    automotive_liability_all_owned_autos:
      data.automotive_liability_all_owned_autos,
    automotive_liability_all_owned_autos_current:
      data.automotive_liability_all_owned_autos_current,
    automotive_liability_all_owned_autos_suggested:
      data.automotive_liability_all_owned_autos_suggested,
    automotive_liability_all_owned_autos_quote:
      data.automotive_liability_all_owned_autos_quote,

    automotive_liability_category_hired_autos:
      data.automotive_liability_category_hired_autos,
    automotive_liability_category_hired_autos_current:
      data.automotive_liability_category_hired_autos_current,
    automotive_liability_category_hired_autos_suggested:
      data.automotive_liability_category_hired_autos_suggested,
    automotive_liability_category_hired_autos_quote:
      data.automotive_liability_category_hired_autos_quote,

    automotive_liability_category_scheduled_autos:
      data.automotive_liability_category_scheduled_autos,
    automotive_liability_category_scheduled_autos_current:
      data.automotive_liability_category_scheduled_autos_current,
    automotive_liability_category_scheduled_autos_suggested:
      data.automotive_liability_category_scheduled_autos_suggested,
    automotive_liability_category_scheduled_autos_quote:
      data.automotive_liability_category_scheduled_autos_quote,

    automotive_liability_category_non_owned_autos:
      data.automotive_liability_category_non_owned_autos,
    automotive_liability_category_non_owned_autos_current:
      data.automotive_liability_category_non_owned_autos_current,
    automotive_liability_category_non_owned_autos_suggested:
      data.automotive_liability_category_non_owned_autos_suggested,
    automotive_liability_category_non_owned_autos_quote:
      data.automotive_liability_category_non_owned_autos_quote,

    automotive_policy_form_additional_insurance:
      data.automotive_policy_form_additional_insurance,
    automotive_policy_form_additional_insurance_current:
      data.automotive_policy_form_additional_insurance_current,
    automotive_policy_form_additional_insurance_suggested:
      data.automotive_policy_form_additional_insurance_suggested,
    automotive_policy_form_additional_insurance_quote:
      data.automotive_policy_form_additional_insurance_quote,

    automotive_policy_form_subrogratrion_waived:
      data.automotive_policy_form_subrogratrion_waived,
    automotive_policy_form_subrogratrion_waived_current:
      data.automotive_policy_form_subrogratrion_waived_current,
    automotive_policy_form_subrogratrion_waived_suggested:
      data.automotive_policy_form_subrogratrion_waived_suggested,
    automotive_policy_form_subrogratrion_waived_quote:
      data.automotive_policy_form_subrogratrion_waived_quote,

    automotive_liability_combined_single_limit:
      data.automotive_liability_combined_single_limit,
    automotive_liability_combined_single_limit_current:
      data.automotive_liability_combined_single_limit_current,
    automotive_liability_combined_single_limit_suggested:
      data.automotive_liability_combined_single_limit_suggested,
    automotive_liability_combined_single_limit_quote:
      data.automotive_liability_combined_single_limit_quote,

    automotive_liability_bodily_injury_pp:
      data.automotive_liability_bodily_injury_pp,
    automotive_liability_bodily_injury_pp_current:
      data.automotive_liability_bodily_injury_pp_current,
    automotive_liability_bodily_injury_pp_suggested:
      data.automotive_liability_bodily_injury_pp_suggested,
    automotive_liability_bodily_injury_pp_quote:
      data.automotive_liability_bodily_injury_pp_quote,

    // Workers Comp
    workers_compensation_subrogration_waved:
      data.workers_compensation_subrogration_waved,
    workers_compensation_subrogration_waved_current:
      data.workers_compensation_subrogration_waved_current,
    workers_compensation_subrogration_waved_suggested:
      data.workers_compensation_subrogration_waved_suggested,
    workers_compensation_subrogration_waved_quote:
      data.workers_compensation_subrogration_waved_quote,

    workers_compensation_el_each_accident:
      data.workers_compensation_el_each_accident,
    workers_compensation_el_each_accident_current:
      data.workers_compensation_el_each_accident_current,
    workers_compensation_el_each_accident_suggested:
      data.workers_compensation_el_each_accident_suggested,
    workers_compensation_el_each_accident_quote:
      data.workers_compensation_el_each_accident_quote,

    workers_compensation_other_el_disease_each_employer:
      data.workers_compensation_other_el_disease_each_employer,
    workers_compensation_other_el_disease_each_employer_current:
      data.workers_compensation_other_el_disease_each_employer_current,
    workers_compensation_other_el_disease_each_employer_suggested:
      data.workers_compensation_other_el_disease_each_employer_suggested,
    workers_compensation_other_el_disease_each_employer_quote:
      data.workers_compensation_other_el_disease_each_employer_quote,

    workers_compensation_el_disease_policy_limit:
      data.workers_compensation_el_disease_policy_limit,
    workers_compensation_el_disease_policy_limit_current:
      data.workers_compensation_el_disease_policy_limit_current,
    workers_compensation_el_disease_policy_limit_suggested:
      data.workers_compensation_el_disease_policy_limit_suggested,
    workers_compensation_el_disease_policy_limit_quote:
      data.workers_compensation_el_disease_policy_limit_quote,

    // Umbrella
    umbrella_subrogration_each_occurance:
      data.umbrella_subrogration_each_occurance,
    umbrella_subrogration_each_occurance_current:
      data.umbrella_subrogration_each_occurance_current,
    umbrella_subrogration_each_occurance_suggested:
      data.umbrella_subrogration_each_occurance_suggested,
    umbrella_subrogration_each_occurance_quote:
      data.umbrella_subrogration_each_occurance_quote,

    umbrella_subrogration_aggregate: data.umbrella_subrogration_aggregate,
    umbrella_subrogration_aggregate_current:
      data.umbrella_subrogration_aggregate_current,
    umbrella_subrogration_aggregate_suggested:
      data.umbrella_subrogration_aggregate_suggested,
    umbrella_subrogration_aggregate_quote:
      data.umbrella_subrogration_aggregate_quote,

    umbrella_subrogration_liability: data.umbrella_subrogration_liability,
    umbrella_subrogration_liability_current:
      data.umbrella_subrogration_liability_current,
    umbrella_subrogration_liability_suggested:
      data.umbrella_subrogration_liability_suggested,
    umbrella_subrogration_liability_quote:
      data.umbrella_subrogration_liability_quote,

    umbrella_subrogration_activity: data.umbrella_subrogration_activity,
    umbrella_subrogration_activity_current:
      data.umbrella_subrogration_activity_current,
    umbrella_subrogration_activity_suggested:
      data.umbrella_subrogration_activity_suggested,
    umbrella_subrogration_activity_quote:
      data.umbrella_subrogration_activity_quote,

    umbrella_subrogration_status: data.umbrella_subrogration_status,
    umbrella_subrogration_status_current:
      data.umbrella_subrogration_status_current,
    umbrella_subrogration_status_suggested:
      data.umbrella_subrogration_status_suggested,
    umbrella_subrogration_status_quote: data.umbrella_subrogration_status_quote,
  };
};
