import React, { useState, useEffect } from "react";
import "./style.scss";
import { useAppState } from "../../../../../context";
import { Input, Textarea, Loading } from "../../../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { post, put } from "../../../../../utils/axios";
import { usePlacesWidget } from "react-google-autocomplete";
import { getAddressItem } from "../../../../../utils/address";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export default function AccountInfoForm({ requirement, userInfor }) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  const [formVal, setFormVal] = useState({
    personal_infor_business_name: requirement?.personal_infor_business_name,
    personal_infor_logo: requirement?.personal_infor_logo,
    rmsInsVer: requirement?.rmsInsVer
  });

  const [addressForm, setAddressForm] = useState({
    personal_infor_address: requirement?.personal_infor_address,
    personal_infor_city: requirement?.personal_infor_city,
    personal_infor_state: requirement?.personal_infor_state,
    personal_infor_zip: requirement?.personal_infor_zip
  });

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"]
    },
    onPlaceSelected: (place) => {
      let zipCode = "",
        state = "",
        city = "";
      if (place.address_components) {
        zipCode = getAddressItem(
          place.address_components,
          "postal_code",
          "short_name"
        );
        state = getAddressItem(
          place.address_components,
          "administrative_area_level_1",
          "short_name"
        );
        city = getAddressItem(
          place.address_components,
          "locality",
          "long_name"
        );
        const street = getAddressItem(
          place.address_components,
          "street_number",
          "long_name"
        );
        const route = getAddressItem(
          place.address_components,
          "route",
          "long_name"
        );
        setAddressForm({
          ...addressForm,
          personal_infor_city: city,
          personal_infor_state: state,
          personal_infor_zip: zipCode,
          personal_infor_address: `${street} ${route}`
        });
      }
    }
  });

  useEffect(() => {
    setFormVal({
      personal_infor_business_name: requirement?.personal_infor_business_name,
      personal_infor_logo: requirement?.personal_infor_logo,
      rmsInsVer: requirement?.rmsInsVer
    });
    setAddressForm({
      personal_infor_address: requirement?.personal_infor_address,
      personal_infor_city: requirement?.personal_infor_city,
      personal_infor_state: requirement?.personal_infor_state,
      personal_infor_zip: requirement?.personal_infor_zip
    });
  }, [requirement, userInfor]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value
    });
  }

  function handleChangeAddress(key, value) {
    setAddressForm({
      ...addressForm,
      [key]: value
    });
  }

  async function handleFormSubmit() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const param = {
        ...formVal,
        ...addressForm
      };
      if (requirement._id) {
        const result = await put(
          `requirement/${requirement._id}`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          //   setRequirement(result.data.data);
        }
      } else {
        const result = await post(
          `requirement`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        if (result.data.success) {
          //   setRequirement(result.data.data);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  function handleSave() {
    handleFormSubmit();
  }

  return (
    <div className="onboarding-form_account">
      <div className="panel-left">
        <Input
          title="Entity NAME"
          type="text"
          placeholder="Entity NAME"
          value={formVal.personal_infor_business_name}
          onChange={(evt) =>
            handleChange("personal_infor_business_name", evt.target.value)
          }
        />
        <Input
          title="DBA"
          type="text"
          value={formVal.dba}
          onChange={(evt) => handleChange("dba", evt.target.value)}
        />
        <Input
          title="ADDRESS*"
          type="text"
          placeholder="ADDRESS"
          ref={ref}
          inputType="TYPE_INPUT_ADDRESS"
          value={addressForm.personal_infor_address}
          onChange={(evt) =>
            handleChangeAddress("personal_infor_address", evt.target.value)
          }
        />
        <Input
          title="CITY*"
          type="text"
          placeholder="CITY"
          value={addressForm.personal_infor_city}
          onChange={(evt) =>
            handleChangeAddress("personal_infor_city", evt.target.value)
          }
        />
        <div className="form-group-two-container">
          <Input
            title="STATE*"
            type="text"
            placeholder="STATE"
            value={addressForm.personal_infor_state}
            onChange={(evt) =>
              handleChangeAddress("personal_infor_state", evt.target.value)
            }
          />
          <Input
            title="ZIP*"
            type="text"
            placeholder="ZIP"
            value={addressForm.personal_infor_zip}
            onChange={(evt) =>
              handleChangeAddress("personal_infor_zip", evt.target.value)
            }
          />
        </div>
        <div className="button-container">
          <Button type="primary" size="large" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
      <div className="panel-right">
        <Input
          title="Logo URL"
          type="text"
          value={formVal.personal_infor_logo}
          onChange={(evt) =>
            handleChange("personal_infor_logo", evt.target.value)
          }
        />
        {formVal.personal_infor_logo && (
          <div className="image_preview-container">
            <img src={formVal.personal_infor_logo} />
          </div>
        )}

        <div className="form_sel">
          <Switch
            size="large"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={formVal.rmsInsVer}
            onChange={(value) => handleChange("rmsInsVer", value)}
          />
          <label className="form_sel-label">Policy Status Verification</label>
        </div>
        <div className="form_sel">
          <Switch
            size="large"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={formVal.enableRMSCompliance}
            onChange={(value) => handleChange("enableRMSCompliance", value)}
          />
          <label className="form_sel-label">
            Compliance Status Verification
          </label>
        </div>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
