import { Button, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading, Card } from "../../../../components";
import { OTHER_HOST_LIST } from "../../../../utils/host";
import { put } from "../../../../utils/axios";
import "./style.scss";

export default function PolicyDetails(props) {
  const { franchiseeData } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [host, setHost] = useState("");

  useEffect(() => {
    setHost(franchiseeData.host || "");
  }, [franchiseeData.host]);

  async function handleSave() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          host,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div className="policy-details-container">
      <Card title="Host">
        <Select
          value={host}
          size="large"
          style={{ width: 400 }}
          onChange={setHost}
          options={OTHER_HOST_LIST}
        />

        <div className="policy-details-container-footer">
          <Button type="primary" size="large" onClick={handleSave}>
            Save
          </Button>
        </div>
        {loading && <Loading loading={loading} />}
      </Card>
    </div>
  );
}
