import React from "react";
import { Controller } from "react-hook-form";
import InputWrapper from "../wrapper";
import { Select } from "antd";

const InputSelect = React.forwardRef(
  (
    {
      rhfKey,
      control,
      error,
      disabled,
      disableValidation,
      size,
      options,
      style,
      register,
      ...props
    },
    ref
  ) => {
    const input = ({ field = {} }) => (
      <InputWrapper {...props} error={error}>
        <Select
          className="custom-input"
          size="large"
          defaultValue={field.defaultValue}
          options={options}
          style={{ width: "100%", ...props.style }}
          {...field}
          {...props}
        />
      </InputWrapper>
    );

    if (control) {
      return (
        <Controller
          name={rhfKey}
          control={control}
          rules={disableValidation ? { validate: () => true } : undefined}
          render={(args) => input(args)}
        />
      );
    }
    return input({});
  }
);

export default InputSelect;
