import React, { useState } from "react";
import { Loading } from "@components";
import { Button } from "antd";
import "./style.scss";
import noDataImage from "@assets/images/no-data.png";
import { useAppState } from "@context";
import { post, put } from "@utils/axios";
import { useAuth0 } from "@auth0/auth0-react";

function OnboardingStart(props) {
  const { franchisorAdmin, setFranchisorAdmin, authSetting } = useAppState();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);

  async function handleSync() {
    const token = await getAccessTokenSilently();
    if (!franchisorAdmin?._id) {
      try {
        setLoading(true);
        const result = await post(
          `requirement`,
          { status: null },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          setFranchisorAdmin(result.data.data);
        }
        await put(
          `user/${authSetting.user._id}`,
          {
            requirementId: result.data.data._id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    const { handleNavigate } = props;
    handleNavigate();
  }

  return (
    <div className="onboarding-start-container">
      <img src={noDataImage} />
      <h3>Start by syncing your franchisees</h3>
      <h4>
        Click <b>Sync data</b> to upload your franchisees
      </h4>
      <Button onClick={handleSync} type="primary">
        Sync data
      </Button>
      <Loading loading={loading} />
    </div>
  );
}

export default OnboardingStart;
