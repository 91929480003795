import React, { useState, useEffect } from "react";
import {
  Input,
  Loading,
  FormMask,
  Textarea,
  ScreenMask,
} from "../../../components";
import { Checkbox, Switch, Radio, Button } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "../../../utils/axios";
import "./style.scss";
import { useAppState } from "@context";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
  SOURCE_ONBOARDING,
} from "../../../assets/const/form";

export default function WorkersCompForm(props) {
  const { data, btnLabel, source, onNext, readOnly = false } = props;
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();

  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const sourceData = source === SOURCE_ONBOARDING ? franchisorAdmin : data;

  const [formVal, setFormVal] = useState({
    workers_compensation_subrogration_waved:
      sourceData?.workers_compensation_subrogration_waved || false,
    workers_compensation_broker_note:
      sourceData?.workers_compensation_broker_note || "",
    workers_compensation_el_each_accident:
      sourceData?.workers_compensation_el_each_accident || "",
    workers_compensation_other_el_disease_each_employer:
      sourceData?.workers_compensation_other_el_disease_each_employer || "",
    workers_compensation_el_disease_policy_limit:
      sourceData?.workers_compensation_el_disease_policy_limit || "",
    workers_compensation_required:
      sourceData?.workers_compensation_required || false,
  });

  useEffect(() => {
    setFormVal({
      workers_compensation_subrogration_waved:
        sourceData?.workers_compensation_subrogration_waved || false,
      workers_compensation_broker_note:
        sourceData?.workers_compensation_broker_note || "",
      workers_compensation_el_each_accident:
        sourceData?.workers_compensation_el_each_accident || "",
      workers_compensation_other_el_disease_each_employer:
        sourceData?.workers_compensation_other_el_disease_each_employer || "",
      workers_compensation_el_disease_policy_limit:
        sourceData?.workers_compensation_el_disease_policy_limit || "",
      workers_compensation_required:
        sourceData?.workers_compensation_required || false,
    });
  }, [sourceData]);

  useEffect(() => {
    const { handleUpdateForm } = props;
    if (handleUpdateForm) {
      handleUpdateForm(formVal);
    }
  }, [formVal]);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleSubmit() {
    handleFormSubmit();
  }

  async function handleFormSubmit() {
    const { onUpdate } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      if (sourceData?._id) {
        const result = await put(
          `requirement/${sourceData._id}`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      } else {
        const result = await post(
          `requirement`,
          { ...formVal },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if (source === SOURCE_ONBOARDING) {
            setFranchisorAdmin(result.data.data);
          } else {
            onUpdate(result.data.data);
          }
        }
      }
      setLoading(false);
      if (source === SOURCE_ONBOARDING) {
        onNext();
      }
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="workers-comp-content">
      <div className="form-header">
        <div className="line-disable">
          <Switch
            checked={formVal.workers_compensation_required}
            onChange={(checked) =>
              handleChange("workers_compensation_required", checked)
            }
          />
          <span className="disable-title">Required</span>
        </div>
      </div>
      <div className="form-main">
        <div className="panel-left">
          <div className="checkbox-group-container">
            <h5 className="checkbox-group-title">WORKERS COMPENSATION</h5>
            <div className="checkbox-group-content">
              <Checkbox
                checked={formVal.workers_compensation_subrogration_waved}
                onChange={(evt) =>
                  handleChange(
                    "workers_compensation_subrogration_waved",
                    evt.target.checked
                  )
                }
              >
                Waiver Of Subrogation
              </Checkbox>
            </div>
          </div>
          <div className="line-item-container broker-report-container">
            <Textarea
              title="Notes from broker"
              type="text"
              placeholder="Please enter your text here."
              value={formVal.workers_compensation_broker_note}
              rows={10}
              onChange={(evt) =>
                handleChange(
                  "workers_compensation_broker_note",
                  evt.target.value
                )
              }
            />
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH ACCIDENT"
              placeholder="EACH ACCIDENT"
              value={formVal.workers_compensation_el_each_accident}
              onChange={(evt) =>
                handleChange(
                  "workers_compensation_el_each_accident",
                  evt.target.value
                )
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="EACH EMPLOYEE"
              subTitle="( Disease )"
              placeholder="EACH EMPLOYEE"
              value={
                formVal.workers_compensation_other_el_disease_each_employer
              }
              onChange={(evt) =>
                handleChange(
                  "workers_compensation_other_el_disease_each_employer",
                  evt.target.value
                )
              }
            />
          </div>
          <div className="line-item-container">
            <Input
              title="POLICY LIMIT "
              subTitle="( Disease )"
              placeholder="POLICY LIMIT"
              value={formVal.workers_compensation_el_disease_policy_limit}
              onChange={(evt) =>
                handleChange(
                  "workers_compensation_el_disease_policy_limit",
                  evt.target.value
                )
              }
            />
          </div>
        </div>
        {!formVal.workers_compensation_required && <FormMask />}
      </div>
      <div className="form-footer">
        {!readOnly && (
          <div className="button-container">
            <Button type="primary" onClick={handleSubmit}>
              {btnLabel || "Save"}
            </Button>
          </div>
        )}
      </div>
      <Loading loading={loading} />
      {readOnly && <ScreenMask />}
    </div>
  );
}
